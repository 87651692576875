/*
Template Name: Dason - Admin & Dashboard Template
Author: Themesbrand
Version: 1.3.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Icons Css File
*/
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=5.9.55");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=5.9.55") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=5.9.55") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=5.9.55") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=5.9.55") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-ab-testing::before {
  content: "\f01c9";
}

.mdi-abacus::before {
  content: "\f16e0";
}

.mdi-abjad-arabic::before {
  content: "\f1328";
}

.mdi-abjad-hebrew::before {
  content: "\f1329";
}

.mdi-abugida-devanagari::before {
  content: "\f132a";
}

.mdi-abugida-thai::before {
  content: "\f132b";
}

.mdi-access-point::before {
  content: "\f0003";
}

.mdi-access-point-check::before {
  content: "\f1538";
}

.mdi-access-point-minus::before {
  content: "\f1539";
}

.mdi-access-point-network::before {
  content: "\f0002";
}

.mdi-access-point-network-off::before {
  content: "\f0be1";
}

.mdi-access-point-off::before {
  content: "\f1511";
}

.mdi-access-point-plus::before {
  content: "\f153a";
}

.mdi-access-point-remove::before {
  content: "\f153b";
}

.mdi-account::before {
  content: "\f0004";
}

.mdi-account-alert::before {
  content: "\f0005";
}

.mdi-account-alert-outline::before {
  content: "\f0b50";
}

.mdi-account-arrow-left::before {
  content: "\f0b51";
}

.mdi-account-arrow-left-outline::before {
  content: "\f0b52";
}

.mdi-account-arrow-right::before {
  content: "\f0b53";
}

.mdi-account-arrow-right-outline::before {
  content: "\f0b54";
}

.mdi-account-box::before {
  content: "\f0006";
}

.mdi-account-box-multiple::before {
  content: "\f0934";
}

.mdi-account-box-multiple-outline::before {
  content: "\f100a";
}

.mdi-account-box-outline::before {
  content: "\f0007";
}

.mdi-account-cancel::before {
  content: "\f12df";
}

.mdi-account-cancel-outline::before {
  content: "\f12e0";
}

.mdi-account-cash::before {
  content: "\f1097";
}

.mdi-account-cash-outline::before {
  content: "\f1098";
}

.mdi-account-check::before {
  content: "\f0008";
}

.mdi-account-check-outline::before {
  content: "\f0be2";
}

.mdi-account-child::before {
  content: "\f0a89";
}

.mdi-account-child-circle::before {
  content: "\f0a8a";
}

.mdi-account-child-outline::before {
  content: "\f10c8";
}

.mdi-account-circle::before {
  content: "\f0009";
}

.mdi-account-circle-outline::before {
  content: "\f0b55";
}

.mdi-account-clock::before {
  content: "\f0b56";
}

.mdi-account-clock-outline::before {
  content: "\f0b57";
}

.mdi-account-cog::before {
  content: "\f1370";
}

.mdi-account-cog-outline::before {
  content: "\f1371";
}

.mdi-account-convert::before {
  content: "\f000a";
}

.mdi-account-convert-outline::before {
  content: "\f1301";
}

.mdi-account-cowboy-hat::before {
  content: "\f0e9b";
}

.mdi-account-details::before {
  content: "\f0631";
}

.mdi-account-details-outline::before {
  content: "\f1372";
}

.mdi-account-edit::before {
  content: "\f06bc";
}

.mdi-account-edit-outline::before {
  content: "\f0ffb";
}

.mdi-account-group::before {
  content: "\f0849";
}

.mdi-account-group-outline::before {
  content: "\f0b58";
}

.mdi-account-hard-hat::before {
  content: "\f05b5";
}

.mdi-account-heart::before {
  content: "\f0899";
}

.mdi-account-heart-outline::before {
  content: "\f0be3";
}

.mdi-account-key::before {
  content: "\f000b";
}

.mdi-account-key-outline::before {
  content: "\f0be4";
}

.mdi-account-lock::before {
  content: "\f115e";
}

.mdi-account-lock-outline::before {
  content: "\f115f";
}

.mdi-account-minus::before {
  content: "\f000d";
}

.mdi-account-minus-outline::before {
  content: "\f0aec";
}

.mdi-account-multiple::before {
  content: "\f000e";
}

.mdi-account-multiple-check::before {
  content: "\f08c5";
}

.mdi-account-multiple-check-outline::before {
  content: "\f11fe";
}

.mdi-account-multiple-minus::before {
  content: "\f05d3";
}

.mdi-account-multiple-minus-outline::before {
  content: "\f0be5";
}

.mdi-account-multiple-outline::before {
  content: "\f000f";
}

.mdi-account-multiple-plus::before {
  content: "\f0010";
}

.mdi-account-multiple-plus-outline::before {
  content: "\f0800";
}

.mdi-account-multiple-remove::before {
  content: "\f120a";
}

.mdi-account-multiple-remove-outline::before {
  content: "\f120b";
}

.mdi-account-music::before {
  content: "\f0803";
}

.mdi-account-music-outline::before {
  content: "\f0ce9";
}

.mdi-account-network::before {
  content: "\f0011";
}

.mdi-account-network-outline::before {
  content: "\f0be6";
}

.mdi-account-off::before {
  content: "\f0012";
}

.mdi-account-off-outline::before {
  content: "\f0be7";
}

.mdi-account-outline::before {
  content: "\f0013";
}

.mdi-account-plus::before {
  content: "\f0014";
}

.mdi-account-plus-outline::before {
  content: "\f0801";
}

.mdi-account-question::before {
  content: "\f0b59";
}

.mdi-account-question-outline::before {
  content: "\f0b5a";
}

.mdi-account-reactivate::before {
  content: "\f152b";
}

.mdi-account-reactivate-outline::before {
  content: "\f152c";
}

.mdi-account-remove::before {
  content: "\f0015";
}

.mdi-account-remove-outline::before {
  content: "\f0aed";
}

.mdi-account-search::before {
  content: "\f0016";
}

.mdi-account-search-outline::before {
  content: "\f0935";
}

.mdi-account-settings::before {
  content: "\f0630";
}

.mdi-account-settings-outline::before {
  content: "\f10c9";
}

.mdi-account-star::before {
  content: "\f0017";
}

.mdi-account-star-outline::before {
  content: "\f0be8";
}

.mdi-account-supervisor::before {
  content: "\f0a8b";
}

.mdi-account-supervisor-circle::before {
  content: "\f0a8c";
}

.mdi-account-supervisor-circle-outline::before {
  content: "\f14ec";
}

.mdi-account-supervisor-outline::before {
  content: "\f112d";
}

.mdi-account-switch::before {
  content: "\f0019";
}

.mdi-account-switch-outline::before {
  content: "\f04cb";
}

.mdi-account-tie::before {
  content: "\f0ce3";
}

.mdi-account-tie-outline::before {
  content: "\f10ca";
}

.mdi-account-tie-voice::before {
  content: "\f1308";
}

.mdi-account-tie-voice-off::before {
  content: "\f130a";
}

.mdi-account-tie-voice-off-outline::before {
  content: "\f130b";
}

.mdi-account-tie-voice-outline::before {
  content: "\f1309";
}

.mdi-account-voice::before {
  content: "\f05cb";
}

.mdi-adjust::before {
  content: "\f001a";
}

.mdi-adobe::before {
  content: "\f0936";
}

.mdi-adobe-acrobat::before {
  content: "\f0f9d";
}

.mdi-air-conditioner::before {
  content: "\f001b";
}

.mdi-air-filter::before {
  content: "\f0d43";
}

.mdi-air-horn::before {
  content: "\f0dac";
}

.mdi-air-humidifier::before {
  content: "\f1099";
}

.mdi-air-humidifier-off::before {
  content: "\f1466";
}

.mdi-air-purifier::before {
  content: "\f0d44";
}

.mdi-airbag::before {
  content: "\f0be9";
}

.mdi-airballoon::before {
  content: "\f001c";
}

.mdi-airballoon-outline::before {
  content: "\f100b";
}

.mdi-airplane::before {
  content: "\f001d";
}

.mdi-airplane-landing::before {
  content: "\f05d4";
}

.mdi-airplane-off::before {
  content: "\f001e";
}

.mdi-airplane-takeoff::before {
  content: "\f05d5";
}

.mdi-airport::before {
  content: "\f084b";
}

.mdi-alarm::before {
  content: "\f0020";
}

.mdi-alarm-bell::before {
  content: "\f078e";
}

.mdi-alarm-check::before {
  content: "\f0021";
}

.mdi-alarm-light::before {
  content: "\f078f";
}

.mdi-alarm-light-off::before {
  content: "\f171e";
}

.mdi-alarm-light-off-outline::before {
  content: "\f171f";
}

.mdi-alarm-light-outline::before {
  content: "\f0bea";
}

.mdi-alarm-multiple::before {
  content: "\f0022";
}

.mdi-alarm-note::before {
  content: "\f0e71";
}

.mdi-alarm-note-off::before {
  content: "\f0e72";
}

.mdi-alarm-off::before {
  content: "\f0023";
}

.mdi-alarm-panel::before {
  content: "\f15c4";
}

.mdi-alarm-panel-outline::before {
  content: "\f15c5";
}

.mdi-alarm-plus::before {
  content: "\f0024";
}

.mdi-alarm-snooze::before {
  content: "\f068e";
}

.mdi-album::before {
  content: "\f0025";
}

.mdi-alert::before {
  content: "\f0026";
}

.mdi-alert-box::before {
  content: "\f0027";
}

.mdi-alert-box-outline::before {
  content: "\f0ce4";
}

.mdi-alert-circle::before {
  content: "\f0028";
}

.mdi-alert-circle-check::before {
  content: "\f11ed";
}

.mdi-alert-circle-check-outline::before {
  content: "\f11ee";
}

.mdi-alert-circle-outline::before {
  content: "\f05d6";
}

.mdi-alert-decagram::before {
  content: "\f06bd";
}

.mdi-alert-decagram-outline::before {
  content: "\f0ce5";
}

.mdi-alert-minus::before {
  content: "\f14bb";
}

.mdi-alert-minus-outline::before {
  content: "\f14be";
}

.mdi-alert-octagon::before {
  content: "\f0029";
}

.mdi-alert-octagon-outline::before {
  content: "\f0ce6";
}

.mdi-alert-octagram::before {
  content: "\f0767";
}

.mdi-alert-octagram-outline::before {
  content: "\f0ce7";
}

.mdi-alert-outline::before {
  content: "\f002a";
}

.mdi-alert-plus::before {
  content: "\f14ba";
}

.mdi-alert-plus-outline::before {
  content: "\f14bd";
}

.mdi-alert-remove::before {
  content: "\f14bc";
}

.mdi-alert-remove-outline::before {
  content: "\f14bf";
}

.mdi-alert-rhombus::before {
  content: "\f11ce";
}

.mdi-alert-rhombus-outline::before {
  content: "\f11cf";
}

.mdi-alien::before {
  content: "\f089a";
}

.mdi-alien-outline::before {
  content: "\f10cb";
}

.mdi-align-horizontal-center::before {
  content: "\f11c3";
}

.mdi-align-horizontal-left::before {
  content: "\f11c2";
}

.mdi-align-horizontal-right::before {
  content: "\f11c4";
}

.mdi-align-vertical-bottom::before {
  content: "\f11c5";
}

.mdi-align-vertical-center::before {
  content: "\f11c6";
}

.mdi-align-vertical-top::before {
  content: "\f11c7";
}

.mdi-all-inclusive::before {
  content: "\f06be";
}

.mdi-allergy::before {
  content: "\f1258";
}

.mdi-alpha::before {
  content: "\f002b";
}

.mdi-alpha-a::before {
  content: "\f0aee";
}

.mdi-alpha-a-box::before {
  content: "\f0b08";
}

.mdi-alpha-a-box-outline::before {
  content: "\f0beb";
}

.mdi-alpha-a-circle::before {
  content: "\f0bec";
}

.mdi-alpha-a-circle-outline::before {
  content: "\f0bed";
}

.mdi-alpha-b::before {
  content: "\f0aef";
}

.mdi-alpha-b-box::before {
  content: "\f0b09";
}

.mdi-alpha-b-box-outline::before {
  content: "\f0bee";
}

.mdi-alpha-b-circle::before {
  content: "\f0bef";
}

.mdi-alpha-b-circle-outline::before {
  content: "\f0bf0";
}

.mdi-alpha-c::before {
  content: "\f0af0";
}

.mdi-alpha-c-box::before {
  content: "\f0b0a";
}

.mdi-alpha-c-box-outline::before {
  content: "\f0bf1";
}

.mdi-alpha-c-circle::before {
  content: "\f0bf2";
}

.mdi-alpha-c-circle-outline::before {
  content: "\f0bf3";
}

.mdi-alpha-d::before {
  content: "\f0af1";
}

.mdi-alpha-d-box::before {
  content: "\f0b0b";
}

.mdi-alpha-d-box-outline::before {
  content: "\f0bf4";
}

.mdi-alpha-d-circle::before {
  content: "\f0bf5";
}

.mdi-alpha-d-circle-outline::before {
  content: "\f0bf6";
}

.mdi-alpha-e::before {
  content: "\f0af2";
}

.mdi-alpha-e-box::before {
  content: "\f0b0c";
}

.mdi-alpha-e-box-outline::before {
  content: "\f0bf7";
}

.mdi-alpha-e-circle::before {
  content: "\f0bf8";
}

.mdi-alpha-e-circle-outline::before {
  content: "\f0bf9";
}

.mdi-alpha-f::before {
  content: "\f0af3";
}

.mdi-alpha-f-box::before {
  content: "\f0b0d";
}

.mdi-alpha-f-box-outline::before {
  content: "\f0bfa";
}

.mdi-alpha-f-circle::before {
  content: "\f0bfb";
}

.mdi-alpha-f-circle-outline::before {
  content: "\f0bfc";
}

.mdi-alpha-g::before {
  content: "\f0af4";
}

.mdi-alpha-g-box::before {
  content: "\f0b0e";
}

.mdi-alpha-g-box-outline::before {
  content: "\f0bfd";
}

.mdi-alpha-g-circle::before {
  content: "\f0bfe";
}

.mdi-alpha-g-circle-outline::before {
  content: "\f0bff";
}

.mdi-alpha-h::before {
  content: "\f0af5";
}

.mdi-alpha-h-box::before {
  content: "\f0b0f";
}

.mdi-alpha-h-box-outline::before {
  content: "\f0c00";
}

.mdi-alpha-h-circle::before {
  content: "\f0c01";
}

.mdi-alpha-h-circle-outline::before {
  content: "\f0c02";
}

.mdi-alpha-i::before {
  content: "\f0af6";
}

.mdi-alpha-i-box::before {
  content: "\f0b10";
}

.mdi-alpha-i-box-outline::before {
  content: "\f0c03";
}

.mdi-alpha-i-circle::before {
  content: "\f0c04";
}

.mdi-alpha-i-circle-outline::before {
  content: "\f0c05";
}

.mdi-alpha-j::before {
  content: "\f0af7";
}

.mdi-alpha-j-box::before {
  content: "\f0b11";
}

.mdi-alpha-j-box-outline::before {
  content: "\f0c06";
}

.mdi-alpha-j-circle::before {
  content: "\f0c07";
}

.mdi-alpha-j-circle-outline::before {
  content: "\f0c08";
}

.mdi-alpha-k::before {
  content: "\f0af8";
}

.mdi-alpha-k-box::before {
  content: "\f0b12";
}

.mdi-alpha-k-box-outline::before {
  content: "\f0c09";
}

.mdi-alpha-k-circle::before {
  content: "\f0c0a";
}

.mdi-alpha-k-circle-outline::before {
  content: "\f0c0b";
}

.mdi-alpha-l::before {
  content: "\f0af9";
}

.mdi-alpha-l-box::before {
  content: "\f0b13";
}

.mdi-alpha-l-box-outline::before {
  content: "\f0c0c";
}

.mdi-alpha-l-circle::before {
  content: "\f0c0d";
}

.mdi-alpha-l-circle-outline::before {
  content: "\f0c0e";
}

.mdi-alpha-m::before {
  content: "\f0afa";
}

.mdi-alpha-m-box::before {
  content: "\f0b14";
}

.mdi-alpha-m-box-outline::before {
  content: "\f0c0f";
}

.mdi-alpha-m-circle::before {
  content: "\f0c10";
}

.mdi-alpha-m-circle-outline::before {
  content: "\f0c11";
}

.mdi-alpha-n::before {
  content: "\f0afb";
}

.mdi-alpha-n-box::before {
  content: "\f0b15";
}

.mdi-alpha-n-box-outline::before {
  content: "\f0c12";
}

.mdi-alpha-n-circle::before {
  content: "\f0c13";
}

.mdi-alpha-n-circle-outline::before {
  content: "\f0c14";
}

.mdi-alpha-o::before {
  content: "\f0afc";
}

.mdi-alpha-o-box::before {
  content: "\f0b16";
}

.mdi-alpha-o-box-outline::before {
  content: "\f0c15";
}

.mdi-alpha-o-circle::before {
  content: "\f0c16";
}

.mdi-alpha-o-circle-outline::before {
  content: "\f0c17";
}

.mdi-alpha-p::before {
  content: "\f0afd";
}

.mdi-alpha-p-box::before {
  content: "\f0b17";
}

.mdi-alpha-p-box-outline::before {
  content: "\f0c18";
}

.mdi-alpha-p-circle::before {
  content: "\f0c19";
}

.mdi-alpha-p-circle-outline::before {
  content: "\f0c1a";
}

.mdi-alpha-q::before {
  content: "\f0afe";
}

.mdi-alpha-q-box::before {
  content: "\f0b18";
}

.mdi-alpha-q-box-outline::before {
  content: "\f0c1b";
}

.mdi-alpha-q-circle::before {
  content: "\f0c1c";
}

.mdi-alpha-q-circle-outline::before {
  content: "\f0c1d";
}

.mdi-alpha-r::before {
  content: "\f0aff";
}

.mdi-alpha-r-box::before {
  content: "\f0b19";
}

.mdi-alpha-r-box-outline::before {
  content: "\f0c1e";
}

.mdi-alpha-r-circle::before {
  content: "\f0c1f";
}

.mdi-alpha-r-circle-outline::before {
  content: "\f0c20";
}

.mdi-alpha-s::before {
  content: "\f0b00";
}

.mdi-alpha-s-box::before {
  content: "\f0b1a";
}

.mdi-alpha-s-box-outline::before {
  content: "\f0c21";
}

.mdi-alpha-s-circle::before {
  content: "\f0c22";
}

.mdi-alpha-s-circle-outline::before {
  content: "\f0c23";
}

.mdi-alpha-t::before {
  content: "\f0b01";
}

.mdi-alpha-t-box::before {
  content: "\f0b1b";
}

.mdi-alpha-t-box-outline::before {
  content: "\f0c24";
}

.mdi-alpha-t-circle::before {
  content: "\f0c25";
}

.mdi-alpha-t-circle-outline::before {
  content: "\f0c26";
}

.mdi-alpha-u::before {
  content: "\f0b02";
}

.mdi-alpha-u-box::before {
  content: "\f0b1c";
}

.mdi-alpha-u-box-outline::before {
  content: "\f0c27";
}

.mdi-alpha-u-circle::before {
  content: "\f0c28";
}

.mdi-alpha-u-circle-outline::before {
  content: "\f0c29";
}

.mdi-alpha-v::before {
  content: "\f0b03";
}

.mdi-alpha-v-box::before {
  content: "\f0b1d";
}

.mdi-alpha-v-box-outline::before {
  content: "\f0c2a";
}

.mdi-alpha-v-circle::before {
  content: "\f0c2b";
}

.mdi-alpha-v-circle-outline::before {
  content: "\f0c2c";
}

.mdi-alpha-w::before {
  content: "\f0b04";
}

.mdi-alpha-w-box::before {
  content: "\f0b1e";
}

.mdi-alpha-w-box-outline::before {
  content: "\f0c2d";
}

.mdi-alpha-w-circle::before {
  content: "\f0c2e";
}

.mdi-alpha-w-circle-outline::before {
  content: "\f0c2f";
}

.mdi-alpha-x::before {
  content: "\f0b05";
}

.mdi-alpha-x-box::before {
  content: "\f0b1f";
}

.mdi-alpha-x-box-outline::before {
  content: "\f0c30";
}

.mdi-alpha-x-circle::before {
  content: "\f0c31";
}

.mdi-alpha-x-circle-outline::before {
  content: "\f0c32";
}

.mdi-alpha-y::before {
  content: "\f0b06";
}

.mdi-alpha-y-box::before {
  content: "\f0b20";
}

.mdi-alpha-y-box-outline::before {
  content: "\f0c33";
}

.mdi-alpha-y-circle::before {
  content: "\f0c34";
}

.mdi-alpha-y-circle-outline::before {
  content: "\f0c35";
}

.mdi-alpha-z::before {
  content: "\f0b07";
}

.mdi-alpha-z-box::before {
  content: "\f0b21";
}

.mdi-alpha-z-box-outline::before {
  content: "\f0c36";
}

.mdi-alpha-z-circle::before {
  content: "\f0c37";
}

.mdi-alpha-z-circle-outline::before {
  content: "\f0c38";
}

.mdi-alphabet-aurebesh::before {
  content: "\f132c";
}

.mdi-alphabet-cyrillic::before {
  content: "\f132d";
}

.mdi-alphabet-greek::before {
  content: "\f132e";
}

.mdi-alphabet-latin::before {
  content: "\f132f";
}

.mdi-alphabet-piqad::before {
  content: "\f1330";
}

.mdi-alphabet-tengwar::before {
  content: "\f1337";
}

.mdi-alphabetical::before {
  content: "\f002c";
}

.mdi-alphabetical-off::before {
  content: "\f100c";
}

.mdi-alphabetical-variant::before {
  content: "\f100d";
}

.mdi-alphabetical-variant-off::before {
  content: "\f100e";
}

.mdi-altimeter::before {
  content: "\f05d7";
}

.mdi-amazon::before {
  content: "\f002d";
}

.mdi-amazon-alexa::before {
  content: "\f08c6";
}

.mdi-ambulance::before {
  content: "\f002f";
}

.mdi-ammunition::before {
  content: "\f0ce8";
}

.mdi-ampersand::before {
  content: "\f0a8d";
}

.mdi-amplifier::before {
  content: "\f0030";
}

.mdi-amplifier-off::before {
  content: "\f11b5";
}

.mdi-anchor::before {
  content: "\f0031";
}

.mdi-android::before {
  content: "\f0032";
}

.mdi-android-auto::before {
  content: "\f0a8e";
}

.mdi-android-debug-bridge::before {
  content: "\f0033";
}

.mdi-android-messages::before {
  content: "\f0d45";
}

.mdi-android-studio::before {
  content: "\f0034";
}

.mdi-angle-acute::before {
  content: "\f0937";
}

.mdi-angle-obtuse::before {
  content: "\f0938";
}

.mdi-angle-right::before {
  content: "\f0939";
}

.mdi-angular::before {
  content: "\f06b2";
}

.mdi-angularjs::before {
  content: "\f06bf";
}

.mdi-animation::before {
  content: "\f05d8";
}

.mdi-animation-outline::before {
  content: "\f0a8f";
}

.mdi-animation-play::before {
  content: "\f093a";
}

.mdi-animation-play-outline::before {
  content: "\f0a90";
}

.mdi-ansible::before {
  content: "\f109a";
}

.mdi-antenna::before {
  content: "\f1119";
}

.mdi-anvil::before {
  content: "\f089b";
}

.mdi-apache-kafka::before {
  content: "\f100f";
}

.mdi-api::before {
  content: "\f109b";
}

.mdi-api-off::before {
  content: "\f1257";
}

.mdi-apple::before {
  content: "\f0035";
}

.mdi-apple-airplay::before {
  content: "\f001f";
}

.mdi-apple-finder::before {
  content: "\f0036";
}

.mdi-apple-icloud::before {
  content: "\f0038";
}

.mdi-apple-ios::before {
  content: "\f0037";
}

.mdi-apple-keyboard-caps::before {
  content: "\f0632";
}

.mdi-apple-keyboard-command::before {
  content: "\f0633";
}

.mdi-apple-keyboard-control::before {
  content: "\f0634";
}

.mdi-apple-keyboard-option::before {
  content: "\f0635";
}

.mdi-apple-keyboard-shift::before {
  content: "\f0636";
}

.mdi-apple-safari::before {
  content: "\f0039";
}

.mdi-application::before {
  content: "\f0614";
}

.mdi-application-cog::before {
  content: "\f1577";
}

.mdi-application-export::before {
  content: "\f0dad";
}

.mdi-application-import::before {
  content: "\f0dae";
}

.mdi-application-settings::before {
  content: "\f1555";
}

.mdi-approximately-equal::before {
  content: "\f0f9e";
}

.mdi-approximately-equal-box::before {
  content: "\f0f9f";
}

.mdi-apps::before {
  content: "\f003b";
}

.mdi-apps-box::before {
  content: "\f0d46";
}

.mdi-arch::before {
  content: "\f08c7";
}

.mdi-archive::before {
  content: "\f003c";
}

.mdi-archive-alert::before {
  content: "\f14fd";
}

.mdi-archive-alert-outline::before {
  content: "\f14fe";
}

.mdi-archive-arrow-down::before {
  content: "\f1259";
}

.mdi-archive-arrow-down-outline::before {
  content: "\f125a";
}

.mdi-archive-arrow-up::before {
  content: "\f125b";
}

.mdi-archive-arrow-up-outline::before {
  content: "\f125c";
}

.mdi-archive-outline::before {
  content: "\f120e";
}

.mdi-arm-flex::before {
  content: "\f0fd7";
}

.mdi-arm-flex-outline::before {
  content: "\f0fd6";
}

.mdi-arrange-bring-forward::before {
  content: "\f003d";
}

.mdi-arrange-bring-to-front::before {
  content: "\f003e";
}

.mdi-arrange-send-backward::before {
  content: "\f003f";
}

.mdi-arrange-send-to-back::before {
  content: "\f0040";
}

.mdi-arrow-all::before {
  content: "\f0041";
}

.mdi-arrow-bottom-left::before {
  content: "\f0042";
}

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\f09b7";
}

.mdi-arrow-bottom-left-thick::before {
  content: "\f09b8";
}

.mdi-arrow-bottom-left-thin-circle-outline::before {
  content: "\f1596";
}

.mdi-arrow-bottom-right::before {
  content: "\f0043";
}

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\f09b9";
}

.mdi-arrow-bottom-right-thick::before {
  content: "\f09ba";
}

.mdi-arrow-bottom-right-thin-circle-outline::before {
  content: "\f1595";
}

.mdi-arrow-collapse::before {
  content: "\f0615";
}

.mdi-arrow-collapse-all::before {
  content: "\f0044";
}

.mdi-arrow-collapse-down::before {
  content: "\f0792";
}

.mdi-arrow-collapse-horizontal::before {
  content: "\f084c";
}

.mdi-arrow-collapse-left::before {
  content: "\f0793";
}

.mdi-arrow-collapse-right::before {
  content: "\f0794";
}

.mdi-arrow-collapse-up::before {
  content: "\f0795";
}

.mdi-arrow-collapse-vertical::before {
  content: "\f084d";
}

.mdi-arrow-decision::before {
  content: "\f09bb";
}

.mdi-arrow-decision-auto::before {
  content: "\f09bc";
}

.mdi-arrow-decision-auto-outline::before {
  content: "\f09bd";
}

.mdi-arrow-decision-outline::before {
  content: "\f09be";
}

.mdi-arrow-down::before {
  content: "\f0045";
}

.mdi-arrow-down-bold::before {
  content: "\f072e";
}

.mdi-arrow-down-bold-box::before {
  content: "\f072f";
}

.mdi-arrow-down-bold-box-outline::before {
  content: "\f0730";
}

.mdi-arrow-down-bold-circle::before {
  content: "\f0047";
}

.mdi-arrow-down-bold-circle-outline::before {
  content: "\f0048";
}

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\f0049";
}

.mdi-arrow-down-bold-outline::before {
  content: "\f09bf";
}

.mdi-arrow-down-box::before {
  content: "\f06c0";
}

.mdi-arrow-down-circle::before {
  content: "\f0cdb";
}

.mdi-arrow-down-circle-outline::before {
  content: "\f0cdc";
}

.mdi-arrow-down-drop-circle::before {
  content: "\f004a";
}

.mdi-arrow-down-drop-circle-outline::before {
  content: "\f004b";
}

.mdi-arrow-down-thick::before {
  content: "\f0046";
}

.mdi-arrow-down-thin-circle-outline::before {
  content: "\f1599";
}

.mdi-arrow-expand::before {
  content: "\f0616";
}

.mdi-arrow-expand-all::before {
  content: "\f004c";
}

.mdi-arrow-expand-down::before {
  content: "\f0796";
}

.mdi-arrow-expand-horizontal::before {
  content: "\f084e";
}

.mdi-arrow-expand-left::before {
  content: "\f0797";
}

.mdi-arrow-expand-right::before {
  content: "\f0798";
}

.mdi-arrow-expand-up::before {
  content: "\f0799";
}

.mdi-arrow-expand-vertical::before {
  content: "\f084f";
}

.mdi-arrow-horizontal-lock::before {
  content: "\f115b";
}

.mdi-arrow-left::before {
  content: "\f004d";
}

.mdi-arrow-left-bold::before {
  content: "\f0731";
}

.mdi-arrow-left-bold-box::before {
  content: "\f0732";
}

.mdi-arrow-left-bold-box-outline::before {
  content: "\f0733";
}

.mdi-arrow-left-bold-circle::before {
  content: "\f004f";
}

.mdi-arrow-left-bold-circle-outline::before {
  content: "\f0050";
}

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\f0051";
}

.mdi-arrow-left-bold-outline::before {
  content: "\f09c0";
}

.mdi-arrow-left-box::before {
  content: "\f06c1";
}

.mdi-arrow-left-circle::before {
  content: "\f0cdd";
}

.mdi-arrow-left-circle-outline::before {
  content: "\f0cde";
}

.mdi-arrow-left-drop-circle::before {
  content: "\f0052";
}

.mdi-arrow-left-drop-circle-outline::before {
  content: "\f0053";
}

.mdi-arrow-left-right::before {
  content: "\f0e73";
}

.mdi-arrow-left-right-bold::before {
  content: "\f0e74";
}

.mdi-arrow-left-right-bold-outline::before {
  content: "\f09c1";
}

.mdi-arrow-left-thick::before {
  content: "\f004e";
}

.mdi-arrow-left-thin-circle-outline::before {
  content: "\f159a";
}

.mdi-arrow-right::before {
  content: "\f0054";
}

.mdi-arrow-right-bold::before {
  content: "\f0734";
}

.mdi-arrow-right-bold-box::before {
  content: "\f0735";
}

.mdi-arrow-right-bold-box-outline::before {
  content: "\f0736";
}

.mdi-arrow-right-bold-circle::before {
  content: "\f0056";
}

.mdi-arrow-right-bold-circle-outline::before {
  content: "\f0057";
}

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\f0058";
}

.mdi-arrow-right-bold-outline::before {
  content: "\f09c2";
}

.mdi-arrow-right-box::before {
  content: "\f06c2";
}

.mdi-arrow-right-circle::before {
  content: "\f0cdf";
}

.mdi-arrow-right-circle-outline::before {
  content: "\f0ce0";
}

.mdi-arrow-right-drop-circle::before {
  content: "\f0059";
}

.mdi-arrow-right-drop-circle-outline::before {
  content: "\f005a";
}

.mdi-arrow-right-thick::before {
  content: "\f0055";
}

.mdi-arrow-right-thin-circle-outline::before {
  content: "\f1598";
}

.mdi-arrow-split-horizontal::before {
  content: "\f093b";
}

.mdi-arrow-split-vertical::before {
  content: "\f093c";
}

.mdi-arrow-top-left::before {
  content: "\f005b";
}

.mdi-arrow-top-left-bold-outline::before {
  content: "\f09c3";
}

.mdi-arrow-top-left-bottom-right::before {
  content: "\f0e75";
}

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\f0e76";
}

.mdi-arrow-top-left-thick::before {
  content: "\f09c4";
}

.mdi-arrow-top-left-thin-circle-outline::before {
  content: "\f1593";
}

.mdi-arrow-top-right::before {
  content: "\f005c";
}

.mdi-arrow-top-right-bold-outline::before {
  content: "\f09c5";
}

.mdi-arrow-top-right-bottom-left::before {
  content: "\f0e77";
}

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\f0e78";
}

.mdi-arrow-top-right-thick::before {
  content: "\f09c6";
}

.mdi-arrow-top-right-thin-circle-outline::before {
  content: "\f1594";
}

.mdi-arrow-up::before {
  content: "\f005d";
}

.mdi-arrow-up-bold::before {
  content: "\f0737";
}

.mdi-arrow-up-bold-box::before {
  content: "\f0738";
}

.mdi-arrow-up-bold-box-outline::before {
  content: "\f0739";
}

.mdi-arrow-up-bold-circle::before {
  content: "\f005f";
}

.mdi-arrow-up-bold-circle-outline::before {
  content: "\f0060";
}

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\f0061";
}

.mdi-arrow-up-bold-outline::before {
  content: "\f09c7";
}

.mdi-arrow-up-box::before {
  content: "\f06c3";
}

.mdi-arrow-up-circle::before {
  content: "\f0ce1";
}

.mdi-arrow-up-circle-outline::before {
  content: "\f0ce2";
}

.mdi-arrow-up-down::before {
  content: "\f0e79";
}

.mdi-arrow-up-down-bold::before {
  content: "\f0e7a";
}

.mdi-arrow-up-down-bold-outline::before {
  content: "\f09c8";
}

.mdi-arrow-up-drop-circle::before {
  content: "\f0062";
}

.mdi-arrow-up-drop-circle-outline::before {
  content: "\f0063";
}

.mdi-arrow-up-thick::before {
  content: "\f005e";
}

.mdi-arrow-up-thin-circle-outline::before {
  content: "\f1597";
}

.mdi-arrow-vertical-lock::before {
  content: "\f115c";
}

.mdi-artstation::before {
  content: "\f0b5b";
}

.mdi-aspect-ratio::before {
  content: "\f0a24";
}

.mdi-assistant::before {
  content: "\f0064";
}

.mdi-asterisk::before {
  content: "\f06c4";
}

.mdi-at::before {
  content: "\f0065";
}

.mdi-atlassian::before {
  content: "\f0804";
}

.mdi-atm::before {
  content: "\f0d47";
}

.mdi-atom::before {
  content: "\f0768";
}

.mdi-atom-variant::before {
  content: "\f0e7b";
}

.mdi-attachment::before {
  content: "\f0066";
}

.mdi-audio-video::before {
  content: "\f093d";
}

.mdi-audio-video-off::before {
  content: "\f11b6";
}

.mdi-augmented-reality::before {
  content: "\f0850";
}

.mdi-auto-download::before {
  content: "\f137e";
}

.mdi-auto-fix::before {
  content: "\f0068";
}

.mdi-auto-upload::before {
  content: "\f0069";
}

.mdi-autorenew::before {
  content: "\f006a";
}

.mdi-av-timer::before {
  content: "\f006b";
}

.mdi-aws::before {
  content: "\f0e0f";
}

.mdi-axe::before {
  content: "\f08c8";
}

.mdi-axis::before {
  content: "\f0d48";
}

.mdi-axis-arrow::before {
  content: "\f0d49";
}

.mdi-axis-arrow-info::before {
  content: "\f140e";
}

.mdi-axis-arrow-lock::before {
  content: "\f0d4a";
}

.mdi-axis-lock::before {
  content: "\f0d4b";
}

.mdi-axis-x-arrow::before {
  content: "\f0d4c";
}

.mdi-axis-x-arrow-lock::before {
  content: "\f0d4d";
}

.mdi-axis-x-rotate-clockwise::before {
  content: "\f0d4e";
}

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\f0d4f";
}

.mdi-axis-x-y-arrow-lock::before {
  content: "\f0d50";
}

.mdi-axis-y-arrow::before {
  content: "\f0d51";
}

.mdi-axis-y-arrow-lock::before {
  content: "\f0d52";
}

.mdi-axis-y-rotate-clockwise::before {
  content: "\f0d53";
}

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\f0d54";
}

.mdi-axis-z-arrow::before {
  content: "\f0d55";
}

.mdi-axis-z-arrow-lock::before {
  content: "\f0d56";
}

.mdi-axis-z-rotate-clockwise::before {
  content: "\f0d57";
}

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\f0d58";
}

.mdi-babel::before {
  content: "\f0a25";
}

.mdi-baby::before {
  content: "\f006c";
}

.mdi-baby-bottle::before {
  content: "\f0f39";
}

.mdi-baby-bottle-outline::before {
  content: "\f0f3a";
}

.mdi-baby-buggy::before {
  content: "\f13e0";
}

.mdi-baby-carriage::before {
  content: "\f068f";
}

.mdi-baby-carriage-off::before {
  content: "\f0fa0";
}

.mdi-baby-face::before {
  content: "\f0e7c";
}

.mdi-baby-face-outline::before {
  content: "\f0e7d";
}

.mdi-backburger::before {
  content: "\f006d";
}

.mdi-backspace::before {
  content: "\f006e";
}

.mdi-backspace-outline::before {
  content: "\f0b5c";
}

.mdi-backspace-reverse::before {
  content: "\f0e7e";
}

.mdi-backspace-reverse-outline::before {
  content: "\f0e7f";
}

.mdi-backup-restore::before {
  content: "\f006f";
}

.mdi-bacteria::before {
  content: "\f0ed5";
}

.mdi-bacteria-outline::before {
  content: "\f0ed6";
}

.mdi-badge-account::before {
  content: "\f0da7";
}

.mdi-badge-account-alert::before {
  content: "\f0da8";
}

.mdi-badge-account-alert-outline::before {
  content: "\f0da9";
}

.mdi-badge-account-horizontal::before {
  content: "\f0e0d";
}

.mdi-badge-account-horizontal-outline::before {
  content: "\f0e0e";
}

.mdi-badge-account-outline::before {
  content: "\f0daa";
}

.mdi-badminton::before {
  content: "\f0851";
}

.mdi-bag-carry-on::before {
  content: "\f0f3b";
}

.mdi-bag-carry-on-check::before {
  content: "\f0d65";
}

.mdi-bag-carry-on-off::before {
  content: "\f0f3c";
}

.mdi-bag-checked::before {
  content: "\f0f3d";
}

.mdi-bag-personal::before {
  content: "\f0e10";
}

.mdi-bag-personal-off::before {
  content: "\f0e11";
}

.mdi-bag-personal-off-outline::before {
  content: "\f0e12";
}

.mdi-bag-personal-outline::before {
  content: "\f0e13";
}

.mdi-bag-suitcase::before {
  content: "\f158b";
}

.mdi-bag-suitcase-off::before {
  content: "\f158d";
}

.mdi-bag-suitcase-off-outline::before {
  content: "\f158e";
}

.mdi-bag-suitcase-outline::before {
  content: "\f158c";
}

.mdi-baguette::before {
  content: "\f0f3e";
}

.mdi-balloon::before {
  content: "\f0a26";
}

.mdi-ballot::before {
  content: "\f09c9";
}

.mdi-ballot-outline::before {
  content: "\f09ca";
}

.mdi-ballot-recount::before {
  content: "\f0c39";
}

.mdi-ballot-recount-outline::before {
  content: "\f0c3a";
}

.mdi-bandage::before {
  content: "\f0daf";
}

.mdi-bandcamp::before {
  content: "\f0675";
}

.mdi-bank::before {
  content: "\f0070";
}

.mdi-bank-check::before {
  content: "\f1655";
}

.mdi-bank-minus::before {
  content: "\f0db0";
}

.mdi-bank-off::before {
  content: "\f1656";
}

.mdi-bank-off-outline::before {
  content: "\f1657";
}

.mdi-bank-outline::before {
  content: "\f0e80";
}

.mdi-bank-plus::before {
  content: "\f0db1";
}

.mdi-bank-remove::before {
  content: "\f0db2";
}

.mdi-bank-transfer::before {
  content: "\f0a27";
}

.mdi-bank-transfer-in::before {
  content: "\f0a28";
}

.mdi-bank-transfer-out::before {
  content: "\f0a29";
}

.mdi-barcode::before {
  content: "\f0071";
}

.mdi-barcode-off::before {
  content: "\f1236";
}

.mdi-barcode-scan::before {
  content: "\f0072";
}

.mdi-barley::before {
  content: "\f0073";
}

.mdi-barley-off::before {
  content: "\f0b5d";
}

.mdi-barn::before {
  content: "\f0b5e";
}

.mdi-barrel::before {
  content: "\f0074";
}

.mdi-baseball::before {
  content: "\f0852";
}

.mdi-baseball-bat::before {
  content: "\f0853";
}

.mdi-baseball-diamond::before {
  content: "\f15ec";
}

.mdi-baseball-diamond-outline::before {
  content: "\f15ed";
}

.mdi-bash::before {
  content: "\f1183";
}

.mdi-basket::before {
  content: "\f0076";
}

.mdi-basket-fill::before {
  content: "\f0077";
}

.mdi-basket-minus::before {
  content: "\f1523";
}

.mdi-basket-minus-outline::before {
  content: "\f1524";
}

.mdi-basket-off::before {
  content: "\f1525";
}

.mdi-basket-off-outline::before {
  content: "\f1526";
}

.mdi-basket-outline::before {
  content: "\f1181";
}

.mdi-basket-plus::before {
  content: "\f1527";
}

.mdi-basket-plus-outline::before {
  content: "\f1528";
}

.mdi-basket-remove::before {
  content: "\f1529";
}

.mdi-basket-remove-outline::before {
  content: "\f152a";
}

.mdi-basket-unfill::before {
  content: "\f0078";
}

.mdi-basketball::before {
  content: "\f0806";
}

.mdi-basketball-hoop::before {
  content: "\f0c3b";
}

.mdi-basketball-hoop-outline::before {
  content: "\f0c3c";
}

.mdi-bat::before {
  content: "\f0b5f";
}

.mdi-battery::before {
  content: "\f0079";
}

.mdi-battery-10::before {
  content: "\f007a";
}

.mdi-battery-10-bluetooth::before {
  content: "\f093e";
}

.mdi-battery-20::before {
  content: "\f007b";
}

.mdi-battery-20-bluetooth::before {
  content: "\f093f";
}

.mdi-battery-30::before {
  content: "\f007c";
}

.mdi-battery-30-bluetooth::before {
  content: "\f0940";
}

.mdi-battery-40::before {
  content: "\f007d";
}

.mdi-battery-40-bluetooth::before {
  content: "\f0941";
}

.mdi-battery-50::before {
  content: "\f007e";
}

.mdi-battery-50-bluetooth::before {
  content: "\f0942";
}

.mdi-battery-60::before {
  content: "\f007f";
}

.mdi-battery-60-bluetooth::before {
  content: "\f0943";
}

.mdi-battery-70::before {
  content: "\f0080";
}

.mdi-battery-70-bluetooth::before {
  content: "\f0944";
}

.mdi-battery-80::before {
  content: "\f0081";
}

.mdi-battery-80-bluetooth::before {
  content: "\f0945";
}

.mdi-battery-90::before {
  content: "\f0082";
}

.mdi-battery-90-bluetooth::before {
  content: "\f0946";
}

.mdi-battery-alert::before {
  content: "\f0083";
}

.mdi-battery-alert-bluetooth::before {
  content: "\f0947";
}

.mdi-battery-alert-variant::before {
  content: "\f10cc";
}

.mdi-battery-alert-variant-outline::before {
  content: "\f10cd";
}

.mdi-battery-bluetooth::before {
  content: "\f0948";
}

.mdi-battery-bluetooth-variant::before {
  content: "\f0949";
}

.mdi-battery-charging::before {
  content: "\f0084";
}

.mdi-battery-charging-10::before {
  content: "\f089c";
}

.mdi-battery-charging-100::before {
  content: "\f0085";
}

.mdi-battery-charging-20::before {
  content: "\f0086";
}

.mdi-battery-charging-30::before {
  content: "\f0087";
}

.mdi-battery-charging-40::before {
  content: "\f0088";
}

.mdi-battery-charging-50::before {
  content: "\f089d";
}

.mdi-battery-charging-60::before {
  content: "\f0089";
}

.mdi-battery-charging-70::before {
  content: "\f089e";
}

.mdi-battery-charging-80::before {
  content: "\f008a";
}

.mdi-battery-charging-90::before {
  content: "\f008b";
}

.mdi-battery-charging-high::before {
  content: "\f12a6";
}

.mdi-battery-charging-low::before {
  content: "\f12a4";
}

.mdi-battery-charging-medium::before {
  content: "\f12a5";
}

.mdi-battery-charging-outline::before {
  content: "\f089f";
}

.mdi-battery-charging-wireless::before {
  content: "\f0807";
}

.mdi-battery-charging-wireless-10::before {
  content: "\f0808";
}

.mdi-battery-charging-wireless-20::before {
  content: "\f0809";
}

.mdi-battery-charging-wireless-30::before {
  content: "\f080a";
}

.mdi-battery-charging-wireless-40::before {
  content: "\f080b";
}

.mdi-battery-charging-wireless-50::before {
  content: "\f080c";
}

.mdi-battery-charging-wireless-60::before {
  content: "\f080d";
}

.mdi-battery-charging-wireless-70::before {
  content: "\f080e";
}

.mdi-battery-charging-wireless-80::before {
  content: "\f080f";
}

.mdi-battery-charging-wireless-90::before {
  content: "\f0810";
}

.mdi-battery-charging-wireless-alert::before {
  content: "\f0811";
}

.mdi-battery-charging-wireless-outline::before {
  content: "\f0812";
}

.mdi-battery-heart::before {
  content: "\f120f";
}

.mdi-battery-heart-outline::before {
  content: "\f1210";
}

.mdi-battery-heart-variant::before {
  content: "\f1211";
}

.mdi-battery-high::before {
  content: "\f12a3";
}

.mdi-battery-low::before {
  content: "\f12a1";
}

.mdi-battery-medium::before {
  content: "\f12a2";
}

.mdi-battery-minus::before {
  content: "\f008c";
}

.mdi-battery-negative::before {
  content: "\f008d";
}

.mdi-battery-off::before {
  content: "\f125d";
}

.mdi-battery-off-outline::before {
  content: "\f125e";
}

.mdi-battery-outline::before {
  content: "\f008e";
}

.mdi-battery-plus::before {
  content: "\f008f";
}

.mdi-battery-positive::before {
  content: "\f0090";
}

.mdi-battery-unknown::before {
  content: "\f0091";
}

.mdi-battery-unknown-bluetooth::before {
  content: "\f094a";
}

.mdi-battlenet::before {
  content: "\f0b60";
}

.mdi-beach::before {
  content: "\f0092";
}

.mdi-beaker::before {
  content: "\f0cea";
}

.mdi-beaker-alert::before {
  content: "\f1229";
}

.mdi-beaker-alert-outline::before {
  content: "\f122a";
}

.mdi-beaker-check::before {
  content: "\f122b";
}

.mdi-beaker-check-outline::before {
  content: "\f122c";
}

.mdi-beaker-minus::before {
  content: "\f122d";
}

.mdi-beaker-minus-outline::before {
  content: "\f122e";
}

.mdi-beaker-outline::before {
  content: "\f0690";
}

.mdi-beaker-plus::before {
  content: "\f122f";
}

.mdi-beaker-plus-outline::before {
  content: "\f1230";
}

.mdi-beaker-question::before {
  content: "\f1231";
}

.mdi-beaker-question-outline::before {
  content: "\f1232";
}

.mdi-beaker-remove::before {
  content: "\f1233";
}

.mdi-beaker-remove-outline::before {
  content: "\f1234";
}

.mdi-bed::before {
  content: "\f02e3";
}

.mdi-bed-double::before {
  content: "\f0fd4";
}

.mdi-bed-double-outline::before {
  content: "\f0fd3";
}

.mdi-bed-empty::before {
  content: "\f08a0";
}

.mdi-bed-king::before {
  content: "\f0fd2";
}

.mdi-bed-king-outline::before {
  content: "\f0fd1";
}

.mdi-bed-outline::before {
  content: "\f0099";
}

.mdi-bed-queen::before {
  content: "\f0fd0";
}

.mdi-bed-queen-outline::before {
  content: "\f0fdb";
}

.mdi-bed-single::before {
  content: "\f106d";
}

.mdi-bed-single-outline::before {
  content: "\f106e";
}

.mdi-bee::before {
  content: "\f0fa1";
}

.mdi-bee-flower::before {
  content: "\f0fa2";
}

.mdi-beehive-off-outline::before {
  content: "\f13ed";
}

.mdi-beehive-outline::before {
  content: "\f10ce";
}

.mdi-beekeeper::before {
  content: "\f14e2";
}

.mdi-beer::before {
  content: "\f0098";
}

.mdi-beer-outline::before {
  content: "\f130c";
}

.mdi-bell::before {
  content: "\f009a";
}

.mdi-bell-alert::before {
  content: "\f0d59";
}

.mdi-bell-alert-outline::before {
  content: "\f0e81";
}

.mdi-bell-cancel::before {
  content: "\f13e7";
}

.mdi-bell-cancel-outline::before {
  content: "\f13e8";
}

.mdi-bell-check::before {
  content: "\f11e5";
}

.mdi-bell-check-outline::before {
  content: "\f11e6";
}

.mdi-bell-circle::before {
  content: "\f0d5a";
}

.mdi-bell-circle-outline::before {
  content: "\f0d5b";
}

.mdi-bell-minus::before {
  content: "\f13e9";
}

.mdi-bell-minus-outline::before {
  content: "\f13ea";
}

.mdi-bell-off::before {
  content: "\f009b";
}

.mdi-bell-off-outline::before {
  content: "\f0a91";
}

.mdi-bell-outline::before {
  content: "\f009c";
}

.mdi-bell-plus::before {
  content: "\f009d";
}

.mdi-bell-plus-outline::before {
  content: "\f0a92";
}

.mdi-bell-remove::before {
  content: "\f13eb";
}

.mdi-bell-remove-outline::before {
  content: "\f13ec";
}

.mdi-bell-ring::before {
  content: "\f009e";
}

.mdi-bell-ring-outline::before {
  content: "\f009f";
}

.mdi-bell-sleep::before {
  content: "\f00a0";
}

.mdi-bell-sleep-outline::before {
  content: "\f0a93";
}

.mdi-beta::before {
  content: "\f00a1";
}

.mdi-betamax::before {
  content: "\f09cb";
}

.mdi-biathlon::before {
  content: "\f0e14";
}

.mdi-bicycle::before {
  content: "\f109c";
}

.mdi-bicycle-basket::before {
  content: "\f1235";
}

.mdi-bicycle-electric::before {
  content: "\f15b4";
}

.mdi-bicycle-penny-farthing::before {
  content: "\f15e9";
}

.mdi-bike::before {
  content: "\f00a3";
}

.mdi-bike-fast::before {
  content: "\f111f";
}

.mdi-billboard::before {
  content: "\f1010";
}

.mdi-billiards::before {
  content: "\f0b61";
}

.mdi-billiards-rack::before {
  content: "\f0b62";
}

.mdi-binoculars::before {
  content: "\f00a5";
}

.mdi-bio::before {
  content: "\f00a6";
}

.mdi-biohazard::before {
  content: "\f00a7";
}

.mdi-bird::before {
  content: "\f15c6";
}

.mdi-bitbucket::before {
  content: "\f00a8";
}

.mdi-bitcoin::before {
  content: "\f0813";
}

.mdi-black-mesa::before {
  content: "\f00a9";
}

.mdi-blender::before {
  content: "\f0ceb";
}

.mdi-blender-software::before {
  content: "\f00ab";
}

.mdi-blinds::before {
  content: "\f00ac";
}

.mdi-blinds-open::before {
  content: "\f1011";
}

.mdi-block-helper::before {
  content: "\f00ad";
}

.mdi-blogger::before {
  content: "\f00ae";
}

.mdi-blood-bag::before {
  content: "\f0cec";
}

.mdi-bluetooth::before {
  content: "\f00af";
}

.mdi-bluetooth-audio::before {
  content: "\f00b0";
}

.mdi-bluetooth-connect::before {
  content: "\f00b1";
}

.mdi-bluetooth-off::before {
  content: "\f00b2";
}

.mdi-bluetooth-settings::before {
  content: "\f00b3";
}

.mdi-bluetooth-transfer::before {
  content: "\f00b4";
}

.mdi-blur::before {
  content: "\f00b5";
}

.mdi-blur-linear::before {
  content: "\f00b6";
}

.mdi-blur-off::before {
  content: "\f00b7";
}

.mdi-blur-radial::before {
  content: "\f00b8";
}

.mdi-bolnisi-cross::before {
  content: "\f0ced";
}

.mdi-bolt::before {
  content: "\f0db3";
}

.mdi-bomb::before {
  content: "\f0691";
}

.mdi-bomb-off::before {
  content: "\f06c5";
}

.mdi-bone::before {
  content: "\f00b9";
}

.mdi-book::before {
  content: "\f00ba";
}

.mdi-book-account::before {
  content: "\f13ad";
}

.mdi-book-account-outline::before {
  content: "\f13ae";
}

.mdi-book-alert::before {
  content: "\f167c";
}

.mdi-book-alert-outline::before {
  content: "\f167d";
}

.mdi-book-alphabet::before {
  content: "\f061d";
}

.mdi-book-arrow-down::before {
  content: "\f167e";
}

.mdi-book-arrow-down-outline::before {
  content: "\f167f";
}

.mdi-book-arrow-left::before {
  content: "\f1680";
}

.mdi-book-arrow-left-outline::before {
  content: "\f1681";
}

.mdi-book-arrow-right::before {
  content: "\f1682";
}

.mdi-book-arrow-right-outline::before {
  content: "\f1683";
}

.mdi-book-arrow-up::before {
  content: "\f1684";
}

.mdi-book-arrow-up-outline::before {
  content: "\f1685";
}

.mdi-book-cancel::before {
  content: "\f1686";
}

.mdi-book-cancel-outline::before {
  content: "\f1687";
}

.mdi-book-check::before {
  content: "\f14f3";
}

.mdi-book-check-outline::before {
  content: "\f14f4";
}

.mdi-book-clock::before {
  content: "\f1688";
}

.mdi-book-clock-outline::before {
  content: "\f1689";
}

.mdi-book-cog::before {
  content: "\f168a";
}

.mdi-book-cog-outline::before {
  content: "\f168b";
}

.mdi-book-cross::before {
  content: "\f00a2";
}

.mdi-book-edit::before {
  content: "\f168c";
}

.mdi-book-edit-outline::before {
  content: "\f168d";
}

.mdi-book-education::before {
  content: "\f16c9";
}

.mdi-book-education-outline::before {
  content: "\f16ca";
}

.mdi-book-information-variant::before {
  content: "\f106f";
}

.mdi-book-lock::before {
  content: "\f079a";
}

.mdi-book-lock-open::before {
  content: "\f079b";
}

.mdi-book-lock-open-outline::before {
  content: "\f168e";
}

.mdi-book-lock-outline::before {
  content: "\f168f";
}

.mdi-book-marker::before {
  content: "\f1690";
}

.mdi-book-marker-outline::before {
  content: "\f1691";
}

.mdi-book-minus::before {
  content: "\f05d9";
}

.mdi-book-minus-multiple::before {
  content: "\f0a94";
}

.mdi-book-minus-multiple-outline::before {
  content: "\f090b";
}

.mdi-book-minus-outline::before {
  content: "\f1692";
}

.mdi-book-multiple::before {
  content: "\f00bb";
}

.mdi-book-multiple-outline::before {
  content: "\f0436";
}

.mdi-book-music::before {
  content: "\f0067";
}

.mdi-book-music-outline::before {
  content: "\f1693";
}

.mdi-book-off::before {
  content: "\f1694";
}

.mdi-book-off-outline::before {
  content: "\f1695";
}

.mdi-book-open::before {
  content: "\f00bd";
}

.mdi-book-open-blank-variant::before {
  content: "\f00be";
}

.mdi-book-open-outline::before {
  content: "\f0b63";
}

.mdi-book-open-page-variant::before {
  content: "\f05da";
}

.mdi-book-open-page-variant-outline::before {
  content: "\f15d6";
}

.mdi-book-open-variant::before {
  content: "\f14f7";
}

.mdi-book-outline::before {
  content: "\f0b64";
}

.mdi-book-play::before {
  content: "\f0e82";
}

.mdi-book-play-outline::before {
  content: "\f0e83";
}

.mdi-book-plus::before {
  content: "\f05db";
}

.mdi-book-plus-multiple::before {
  content: "\f0a95";
}

.mdi-book-plus-multiple-outline::before {
  content: "\f0ade";
}

.mdi-book-plus-outline::before {
  content: "\f1696";
}

.mdi-book-refresh::before {
  content: "\f1697";
}

.mdi-book-refresh-outline::before {
  content: "\f1698";
}

.mdi-book-remove::before {
  content: "\f0a97";
}

.mdi-book-remove-multiple::before {
  content: "\f0a96";
}

.mdi-book-remove-multiple-outline::before {
  content: "\f04ca";
}

.mdi-book-remove-outline::before {
  content: "\f1699";
}

.mdi-book-search::before {
  content: "\f0e84";
}

.mdi-book-search-outline::before {
  content: "\f0e85";
}

.mdi-book-settings::before {
  content: "\f169a";
}

.mdi-book-settings-outline::before {
  content: "\f169b";
}

.mdi-book-sync::before {
  content: "\f169c";
}

.mdi-book-sync-outline::before {
  content: "\f16c8";
}

.mdi-book-variant::before {
  content: "\f00bf";
}

.mdi-book-variant-multiple::before {
  content: "\f00bc";
}

.mdi-bookmark::before {
  content: "\f00c0";
}

.mdi-bookmark-check::before {
  content: "\f00c1";
}

.mdi-bookmark-check-outline::before {
  content: "\f137b";
}

.mdi-bookmark-minus::before {
  content: "\f09cc";
}

.mdi-bookmark-minus-outline::before {
  content: "\f09cd";
}

.mdi-bookmark-multiple::before {
  content: "\f0e15";
}

.mdi-bookmark-multiple-outline::before {
  content: "\f0e16";
}

.mdi-bookmark-music::before {
  content: "\f00c2";
}

.mdi-bookmark-music-outline::before {
  content: "\f1379";
}

.mdi-bookmark-off::before {
  content: "\f09ce";
}

.mdi-bookmark-off-outline::before {
  content: "\f09cf";
}

.mdi-bookmark-outline::before {
  content: "\f00c3";
}

.mdi-bookmark-plus::before {
  content: "\f00c5";
}

.mdi-bookmark-plus-outline::before {
  content: "\f00c4";
}

.mdi-bookmark-remove::before {
  content: "\f00c6";
}

.mdi-bookmark-remove-outline::before {
  content: "\f137a";
}

.mdi-bookshelf::before {
  content: "\f125f";
}

.mdi-boom-gate::before {
  content: "\f0e86";
}

.mdi-boom-gate-alert::before {
  content: "\f0e87";
}

.mdi-boom-gate-alert-outline::before {
  content: "\f0e88";
}

.mdi-boom-gate-down::before {
  content: "\f0e89";
}

.mdi-boom-gate-down-outline::before {
  content: "\f0e8a";
}

.mdi-boom-gate-outline::before {
  content: "\f0e8b";
}

.mdi-boom-gate-up::before {
  content: "\f0e8c";
}

.mdi-boom-gate-up-outline::before {
  content: "\f0e8d";
}

.mdi-boombox::before {
  content: "\f05dc";
}

.mdi-boomerang::before {
  content: "\f10cf";
}

.mdi-bootstrap::before {
  content: "\f06c6";
}

.mdi-border-all::before {
  content: "\f00c7";
}

.mdi-border-all-variant::before {
  content: "\f08a1";
}

.mdi-border-bottom::before {
  content: "\f00c8";
}

.mdi-border-bottom-variant::before {
  content: "\f08a2";
}

.mdi-border-color::before {
  content: "\f00c9";
}

.mdi-border-horizontal::before {
  content: "\f00ca";
}

.mdi-border-inside::before {
  content: "\f00cb";
}

.mdi-border-left::before {
  content: "\f00cc";
}

.mdi-border-left-variant::before {
  content: "\f08a3";
}

.mdi-border-none::before {
  content: "\f00cd";
}

.mdi-border-none-variant::before {
  content: "\f08a4";
}

.mdi-border-outside::before {
  content: "\f00ce";
}

.mdi-border-right::before {
  content: "\f00cf";
}

.mdi-border-right-variant::before {
  content: "\f08a5";
}

.mdi-border-style::before {
  content: "\f00d0";
}

.mdi-border-top::before {
  content: "\f00d1";
}

.mdi-border-top-variant::before {
  content: "\f08a6";
}

.mdi-border-vertical::before {
  content: "\f00d2";
}

.mdi-bottle-soda::before {
  content: "\f1070";
}

.mdi-bottle-soda-classic::before {
  content: "\f1071";
}

.mdi-bottle-soda-classic-outline::before {
  content: "\f1363";
}

.mdi-bottle-soda-outline::before {
  content: "\f1072";
}

.mdi-bottle-tonic::before {
  content: "\f112e";
}

.mdi-bottle-tonic-outline::before {
  content: "\f112f";
}

.mdi-bottle-tonic-plus::before {
  content: "\f1130";
}

.mdi-bottle-tonic-plus-outline::before {
  content: "\f1131";
}

.mdi-bottle-tonic-skull::before {
  content: "\f1132";
}

.mdi-bottle-tonic-skull-outline::before {
  content: "\f1133";
}

.mdi-bottle-wine::before {
  content: "\f0854";
}

.mdi-bottle-wine-outline::before {
  content: "\f1310";
}

.mdi-bow-tie::before {
  content: "\f0678";
}

.mdi-bowl::before {
  content: "\f028e";
}

.mdi-bowl-mix::before {
  content: "\f0617";
}

.mdi-bowl-mix-outline::before {
  content: "\f02e4";
}

.mdi-bowl-outline::before {
  content: "\f02a9";
}

.mdi-bowling::before {
  content: "\f00d3";
}

.mdi-box::before {
  content: "\f00d4";
}

.mdi-box-cutter::before {
  content: "\f00d5";
}

.mdi-box-cutter-off::before {
  content: "\f0b4a";
}

.mdi-box-shadow::before {
  content: "\f0637";
}

.mdi-boxing-glove::before {
  content: "\f0b65";
}

.mdi-braille::before {
  content: "\f09d0";
}

.mdi-brain::before {
  content: "\f09d1";
}

.mdi-bread-slice::before {
  content: "\f0cee";
}

.mdi-bread-slice-outline::before {
  content: "\f0cef";
}

.mdi-bridge::before {
  content: "\f0618";
}

.mdi-briefcase::before {
  content: "\f00d6";
}

.mdi-briefcase-account::before {
  content: "\f0cf0";
}

.mdi-briefcase-account-outline::before {
  content: "\f0cf1";
}

.mdi-briefcase-check::before {
  content: "\f00d7";
}

.mdi-briefcase-check-outline::before {
  content: "\f131e";
}

.mdi-briefcase-clock::before {
  content: "\f10d0";
}

.mdi-briefcase-clock-outline::before {
  content: "\f10d1";
}

.mdi-briefcase-download::before {
  content: "\f00d8";
}

.mdi-briefcase-download-outline::before {
  content: "\f0c3d";
}

.mdi-briefcase-edit::before {
  content: "\f0a98";
}

.mdi-briefcase-edit-outline::before {
  content: "\f0c3e";
}

.mdi-briefcase-minus::before {
  content: "\f0a2a";
}

.mdi-briefcase-minus-outline::before {
  content: "\f0c3f";
}

.mdi-briefcase-off::before {
  content: "\f1658";
}

.mdi-briefcase-off-outline::before {
  content: "\f1659";
}

.mdi-briefcase-outline::before {
  content: "\f0814";
}

.mdi-briefcase-plus::before {
  content: "\f0a2b";
}

.mdi-briefcase-plus-outline::before {
  content: "\f0c40";
}

.mdi-briefcase-remove::before {
  content: "\f0a2c";
}

.mdi-briefcase-remove-outline::before {
  content: "\f0c41";
}

.mdi-briefcase-search::before {
  content: "\f0a2d";
}

.mdi-briefcase-search-outline::before {
  content: "\f0c42";
}

.mdi-briefcase-upload::before {
  content: "\f00d9";
}

.mdi-briefcase-upload-outline::before {
  content: "\f0c43";
}

.mdi-briefcase-variant::before {
  content: "\f1494";
}

.mdi-briefcase-variant-off::before {
  content: "\f165a";
}

.mdi-briefcase-variant-off-outline::before {
  content: "\f165b";
}

.mdi-briefcase-variant-outline::before {
  content: "\f1495";
}

.mdi-brightness-1::before {
  content: "\f00da";
}

.mdi-brightness-2::before {
  content: "\f00db";
}

.mdi-brightness-3::before {
  content: "\f00dc";
}

.mdi-brightness-4::before {
  content: "\f00dd";
}

.mdi-brightness-5::before {
  content: "\f00de";
}

.mdi-brightness-6::before {
  content: "\f00df";
}

.mdi-brightness-7::before {
  content: "\f00e0";
}

.mdi-brightness-auto::before {
  content: "\f00e1";
}

.mdi-brightness-percent::before {
  content: "\f0cf2";
}

.mdi-broadcast::before {
  content: "\f1720";
}

.mdi-broadcast-off::before {
  content: "\f1721";
}

.mdi-broom::before {
  content: "\f00e2";
}

.mdi-brush::before {
  content: "\f00e3";
}

.mdi-bucket::before {
  content: "\f1415";
}

.mdi-bucket-outline::before {
  content: "\f1416";
}

.mdi-buddhism::before {
  content: "\f094b";
}

.mdi-buffer::before {
  content: "\f0619";
}

.mdi-buffet::before {
  content: "\f0578";
}

.mdi-bug::before {
  content: "\f00e4";
}

.mdi-bug-check::before {
  content: "\f0a2e";
}

.mdi-bug-check-outline::before {
  content: "\f0a2f";
}

.mdi-bug-outline::before {
  content: "\f0a30";
}

.mdi-bugle::before {
  content: "\f0db4";
}

.mdi-bulldozer::before {
  content: "\f0b22";
}

.mdi-bullet::before {
  content: "\f0cf3";
}

.mdi-bulletin-board::before {
  content: "\f00e5";
}

.mdi-bullhorn::before {
  content: "\f00e6";
}

.mdi-bullhorn-outline::before {
  content: "\f0b23";
}

.mdi-bullseye::before {
  content: "\f05dd";
}

.mdi-bullseye-arrow::before {
  content: "\f08c9";
}

.mdi-bulma::before {
  content: "\f12e7";
}

.mdi-bunk-bed::before {
  content: "\f1302";
}

.mdi-bunk-bed-outline::before {
  content: "\f0097";
}

.mdi-bus::before {
  content: "\f00e7";
}

.mdi-bus-alert::before {
  content: "\f0a99";
}

.mdi-bus-articulated-end::before {
  content: "\f079c";
}

.mdi-bus-articulated-front::before {
  content: "\f079d";
}

.mdi-bus-clock::before {
  content: "\f08ca";
}

.mdi-bus-double-decker::before {
  content: "\f079e";
}

.mdi-bus-marker::before {
  content: "\f1212";
}

.mdi-bus-multiple::before {
  content: "\f0f3f";
}

.mdi-bus-school::before {
  content: "\f079f";
}

.mdi-bus-side::before {
  content: "\f07a0";
}

.mdi-bus-stop::before {
  content: "\f1012";
}

.mdi-bus-stop-covered::before {
  content: "\f1013";
}

.mdi-bus-stop-uncovered::before {
  content: "\f1014";
}

.mdi-butterfly::before {
  content: "\f1589";
}

.mdi-butterfly-outline::before {
  content: "\f158a";
}

.mdi-cable-data::before {
  content: "\f1394";
}

.mdi-cached::before {
  content: "\f00e8";
}

.mdi-cactus::before {
  content: "\f0db5";
}

.mdi-cake::before {
  content: "\f00e9";
}

.mdi-cake-layered::before {
  content: "\f00ea";
}

.mdi-cake-variant::before {
  content: "\f00eb";
}

.mdi-calculator::before {
  content: "\f00ec";
}

.mdi-calculator-variant::before {
  content: "\f0a9a";
}

.mdi-calculator-variant-outline::before {
  content: "\f15a6";
}

.mdi-calendar::before {
  content: "\f00ed";
}

.mdi-calendar-account::before {
  content: "\f0ed7";
}

.mdi-calendar-account-outline::before {
  content: "\f0ed8";
}

.mdi-calendar-alert::before {
  content: "\f0a31";
}

.mdi-calendar-arrow-left::before {
  content: "\f1134";
}

.mdi-calendar-arrow-right::before {
  content: "\f1135";
}

.mdi-calendar-blank::before {
  content: "\f00ee";
}

.mdi-calendar-blank-multiple::before {
  content: "\f1073";
}

.mdi-calendar-blank-outline::before {
  content: "\f0b66";
}

.mdi-calendar-check::before {
  content: "\f00ef";
}

.mdi-calendar-check-outline::before {
  content: "\f0c44";
}

.mdi-calendar-clock::before {
  content: "\f00f0";
}

.mdi-calendar-clock-outline::before {
  content: "\f16e1";
}

.mdi-calendar-cursor::before {
  content: "\f157b";
}

.mdi-calendar-edit::before {
  content: "\f08a7";
}

.mdi-calendar-end::before {
  content: "\f166c";
}

.mdi-calendar-export::before {
  content: "\f0b24";
}

.mdi-calendar-heart::before {
  content: "\f09d2";
}

.mdi-calendar-import::before {
  content: "\f0b25";
}

.mdi-calendar-lock::before {
  content: "\f1641";
}

.mdi-calendar-lock-outline::before {
  content: "\f1642";
}

.mdi-calendar-minus::before {
  content: "\f0d5c";
}

.mdi-calendar-month::before {
  content: "\f0e17";
}

.mdi-calendar-month-outline::before {
  content: "\f0e18";
}

.mdi-calendar-multiple::before {
  content: "\f00f1";
}

.mdi-calendar-multiple-check::before {
  content: "\f00f2";
}

.mdi-calendar-multiselect::before {
  content: "\f0a32";
}

.mdi-calendar-outline::before {
  content: "\f0b67";
}

.mdi-calendar-plus::before {
  content: "\f00f3";
}

.mdi-calendar-question::before {
  content: "\f0692";
}

.mdi-calendar-range::before {
  content: "\f0679";
}

.mdi-calendar-range-outline::before {
  content: "\f0b68";
}

.mdi-calendar-refresh::before {
  content: "\f01e1";
}

.mdi-calendar-refresh-outline::before {
  content: "\f0203";
}

.mdi-calendar-remove::before {
  content: "\f00f4";
}

.mdi-calendar-remove-outline::before {
  content: "\f0c45";
}

.mdi-calendar-search::before {
  content: "\f094c";
}

.mdi-calendar-star::before {
  content: "\f09d3";
}

.mdi-calendar-start::before {
  content: "\f166d";
}

.mdi-calendar-sync::before {
  content: "\f0e8e";
}

.mdi-calendar-sync-outline::before {
  content: "\f0e8f";
}

.mdi-calendar-text::before {
  content: "\f00f5";
}

.mdi-calendar-text-outline::before {
  content: "\f0c46";
}

.mdi-calendar-today::before {
  content: "\f00f6";
}

.mdi-calendar-week::before {
  content: "\f0a33";
}

.mdi-calendar-week-begin::before {
  content: "\f0a34";
}

.mdi-calendar-weekend::before {
  content: "\f0ed9";
}

.mdi-calendar-weekend-outline::before {
  content: "\f0eda";
}

.mdi-call-made::before {
  content: "\f00f7";
}

.mdi-call-merge::before {
  content: "\f00f8";
}

.mdi-call-missed::before {
  content: "\f00f9";
}

.mdi-call-received::before {
  content: "\f00fa";
}

.mdi-call-split::before {
  content: "\f00fb";
}

.mdi-camcorder::before {
  content: "\f00fc";
}

.mdi-camcorder-off::before {
  content: "\f00ff";
}

.mdi-camera::before {
  content: "\f0100";
}

.mdi-camera-account::before {
  content: "\f08cb";
}

.mdi-camera-burst::before {
  content: "\f0693";
}

.mdi-camera-control::before {
  content: "\f0b69";
}

.mdi-camera-enhance::before {
  content: "\f0101";
}

.mdi-camera-enhance-outline::before {
  content: "\f0b6a";
}

.mdi-camera-flip::before {
  content: "\f15d9";
}

.mdi-camera-flip-outline::before {
  content: "\f15da";
}

.mdi-camera-front::before {
  content: "\f0102";
}

.mdi-camera-front-variant::before {
  content: "\f0103";
}

.mdi-camera-gopro::before {
  content: "\f07a1";
}

.mdi-camera-image::before {
  content: "\f08cc";
}

.mdi-camera-iris::before {
  content: "\f0104";
}

.mdi-camera-metering-center::before {
  content: "\f07a2";
}

.mdi-camera-metering-matrix::before {
  content: "\f07a3";
}

.mdi-camera-metering-partial::before {
  content: "\f07a4";
}

.mdi-camera-metering-spot::before {
  content: "\f07a5";
}

.mdi-camera-off::before {
  content: "\f05df";
}

.mdi-camera-outline::before {
  content: "\f0d5d";
}

.mdi-camera-party-mode::before {
  content: "\f0105";
}

.mdi-camera-plus::before {
  content: "\f0edb";
}

.mdi-camera-plus-outline::before {
  content: "\f0edc";
}

.mdi-camera-rear::before {
  content: "\f0106";
}

.mdi-camera-rear-variant::before {
  content: "\f0107";
}

.mdi-camera-retake::before {
  content: "\f0e19";
}

.mdi-camera-retake-outline::before {
  content: "\f0e1a";
}

.mdi-camera-switch::before {
  content: "\f0108";
}

.mdi-camera-switch-outline::before {
  content: "\f084a";
}

.mdi-camera-timer::before {
  content: "\f0109";
}

.mdi-camera-wireless::before {
  content: "\f0db6";
}

.mdi-camera-wireless-outline::before {
  content: "\f0db7";
}

.mdi-campfire::before {
  content: "\f0edd";
}

.mdi-cancel::before {
  content: "\f073a";
}

.mdi-candle::before {
  content: "\f05e2";
}

.mdi-candycane::before {
  content: "\f010a";
}

.mdi-cannabis::before {
  content: "\f07a6";
}

.mdi-cannabis-off::before {
  content: "\f166e";
}

.mdi-caps-lock::before {
  content: "\f0a9b";
}

.mdi-car::before {
  content: "\f010b";
}

.mdi-car-2-plus::before {
  content: "\f1015";
}

.mdi-car-3-plus::before {
  content: "\f1016";
}

.mdi-car-arrow-left::before {
  content: "\f13b2";
}

.mdi-car-arrow-right::before {
  content: "\f13b3";
}

.mdi-car-back::before {
  content: "\f0e1b";
}

.mdi-car-battery::before {
  content: "\f010c";
}

.mdi-car-brake-abs::before {
  content: "\f0c47";
}

.mdi-car-brake-alert::before {
  content: "\f0c48";
}

.mdi-car-brake-hold::before {
  content: "\f0d5e";
}

.mdi-car-brake-parking::before {
  content: "\f0d5f";
}

.mdi-car-brake-retarder::before {
  content: "\f1017";
}

.mdi-car-child-seat::before {
  content: "\f0fa3";
}

.mdi-car-clutch::before {
  content: "\f1018";
}

.mdi-car-cog::before {
  content: "\f13cc";
}

.mdi-car-connected::before {
  content: "\f010d";
}

.mdi-car-convertible::before {
  content: "\f07a7";
}

.mdi-car-coolant-level::before {
  content: "\f1019";
}

.mdi-car-cruise-control::before {
  content: "\f0d60";
}

.mdi-car-defrost-front::before {
  content: "\f0d61";
}

.mdi-car-defrost-rear::before {
  content: "\f0d62";
}

.mdi-car-door::before {
  content: "\f0b6b";
}

.mdi-car-door-lock::before {
  content: "\f109d";
}

.mdi-car-electric::before {
  content: "\f0b6c";
}

.mdi-car-electric-outline::before {
  content: "\f15b5";
}

.mdi-car-emergency::before {
  content: "\f160f";
}

.mdi-car-esp::before {
  content: "\f0c49";
}

.mdi-car-estate::before {
  content: "\f07a8";
}

.mdi-car-hatchback::before {
  content: "\f07a9";
}

.mdi-car-info::before {
  content: "\f11be";
}

.mdi-car-key::before {
  content: "\f0b6d";
}

.mdi-car-lifted-pickup::before {
  content: "\f152d";
}

.mdi-car-light-dimmed::before {
  content: "\f0c4a";
}

.mdi-car-light-fog::before {
  content: "\f0c4b";
}

.mdi-car-light-high::before {
  content: "\f0c4c";
}

.mdi-car-limousine::before {
  content: "\f08cd";
}

.mdi-car-multiple::before {
  content: "\f0b6e";
}

.mdi-car-off::before {
  content: "\f0e1c";
}

.mdi-car-outline::before {
  content: "\f14ed";
}

.mdi-car-parking-lights::before {
  content: "\f0d63";
}

.mdi-car-pickup::before {
  content: "\f07aa";
}

.mdi-car-seat::before {
  content: "\f0fa4";
}

.mdi-car-seat-cooler::before {
  content: "\f0fa5";
}

.mdi-car-seat-heater::before {
  content: "\f0fa6";
}

.mdi-car-settings::before {
  content: "\f13cd";
}

.mdi-car-shift-pattern::before {
  content: "\f0f40";
}

.mdi-car-side::before {
  content: "\f07ab";
}

.mdi-car-sports::before {
  content: "\f07ac";
}

.mdi-car-tire-alert::before {
  content: "\f0c4d";
}

.mdi-car-traction-control::before {
  content: "\f0d64";
}

.mdi-car-turbocharger::before {
  content: "\f101a";
}

.mdi-car-wash::before {
  content: "\f010e";
}

.mdi-car-windshield::before {
  content: "\f101b";
}

.mdi-car-windshield-outline::before {
  content: "\f101c";
}

.mdi-carabiner::before {
  content: "\f14c0";
}

.mdi-caravan::before {
  content: "\f07ad";
}

.mdi-card::before {
  content: "\f0b6f";
}

.mdi-card-account-details::before {
  content: "\f05d2";
}

.mdi-card-account-details-outline::before {
  content: "\f0dab";
}

.mdi-card-account-details-star::before {
  content: "\f02a3";
}

.mdi-card-account-details-star-outline::before {
  content: "\f06db";
}

.mdi-card-account-mail::before {
  content: "\f018e";
}

.mdi-card-account-mail-outline::before {
  content: "\f0e98";
}

.mdi-card-account-phone::before {
  content: "\f0e99";
}

.mdi-card-account-phone-outline::before {
  content: "\f0e9a";
}

.mdi-card-bulleted::before {
  content: "\f0b70";
}

.mdi-card-bulleted-off::before {
  content: "\f0b71";
}

.mdi-card-bulleted-off-outline::before {
  content: "\f0b72";
}

.mdi-card-bulleted-outline::before {
  content: "\f0b73";
}

.mdi-card-bulleted-settings::before {
  content: "\f0b74";
}

.mdi-card-bulleted-settings-outline::before {
  content: "\f0b75";
}

.mdi-card-minus::before {
  content: "\f1600";
}

.mdi-card-minus-outline::before {
  content: "\f1601";
}

.mdi-card-off::before {
  content: "\f1602";
}

.mdi-card-off-outline::before {
  content: "\f1603";
}

.mdi-card-outline::before {
  content: "\f0b76";
}

.mdi-card-plus::before {
  content: "\f11ff";
}

.mdi-card-plus-outline::before {
  content: "\f1200";
}

.mdi-card-remove::before {
  content: "\f1604";
}

.mdi-card-remove-outline::before {
  content: "\f1605";
}

.mdi-card-search::before {
  content: "\f1074";
}

.mdi-card-search-outline::before {
  content: "\f1075";
}

.mdi-card-text::before {
  content: "\f0b77";
}

.mdi-card-text-outline::before {
  content: "\f0b78";
}

.mdi-cards::before {
  content: "\f0638";
}

.mdi-cards-club::before {
  content: "\f08ce";
}

.mdi-cards-diamond::before {
  content: "\f08cf";
}

.mdi-cards-diamond-outline::before {
  content: "\f101d";
}

.mdi-cards-heart::before {
  content: "\f08d0";
}

.mdi-cards-outline::before {
  content: "\f0639";
}

.mdi-cards-playing-outline::before {
  content: "\f063a";
}

.mdi-cards-spade::before {
  content: "\f08d1";
}

.mdi-cards-variant::before {
  content: "\f06c7";
}

.mdi-carrot::before {
  content: "\f010f";
}

.mdi-cart::before {
  content: "\f0110";
}

.mdi-cart-arrow-down::before {
  content: "\f0d66";
}

.mdi-cart-arrow-right::before {
  content: "\f0c4e";
}

.mdi-cart-arrow-up::before {
  content: "\f0d67";
}

.mdi-cart-check::before {
  content: "\f15ea";
}

.mdi-cart-minus::before {
  content: "\f0d68";
}

.mdi-cart-off::before {
  content: "\f066b";
}

.mdi-cart-outline::before {
  content: "\f0111";
}

.mdi-cart-plus::before {
  content: "\f0112";
}

.mdi-cart-remove::before {
  content: "\f0d69";
}

.mdi-cart-variant::before {
  content: "\f15eb";
}

.mdi-case-sensitive-alt::before {
  content: "\f0113";
}

.mdi-cash::before {
  content: "\f0114";
}

.mdi-cash-100::before {
  content: "\f0115";
}

.mdi-cash-check::before {
  content: "\f14ee";
}

.mdi-cash-lock::before {
  content: "\f14ea";
}

.mdi-cash-lock-open::before {
  content: "\f14eb";
}

.mdi-cash-marker::before {
  content: "\f0db8";
}

.mdi-cash-minus::before {
  content: "\f1260";
}

.mdi-cash-multiple::before {
  content: "\f0116";
}

.mdi-cash-plus::before {
  content: "\f1261";
}

.mdi-cash-refund::before {
  content: "\f0a9c";
}

.mdi-cash-register::before {
  content: "\f0cf4";
}

.mdi-cash-remove::before {
  content: "\f1262";
}

.mdi-cash-usd::before {
  content: "\f1176";
}

.mdi-cash-usd-outline::before {
  content: "\f0117";
}

.mdi-cassette::before {
  content: "\f09d4";
}

.mdi-cast::before {
  content: "\f0118";
}

.mdi-cast-audio::before {
  content: "\f101e";
}

.mdi-cast-connected::before {
  content: "\f0119";
}

.mdi-cast-education::before {
  content: "\f0e1d";
}

.mdi-cast-off::before {
  content: "\f078a";
}

.mdi-castle::before {
  content: "\f011a";
}

.mdi-cat::before {
  content: "\f011b";
}

.mdi-cctv::before {
  content: "\f07ae";
}

.mdi-ceiling-light::before {
  content: "\f0769";
}

.mdi-cellphone::before {
  content: "\f011c";
}

.mdi-cellphone-android::before {
  content: "\f011d";
}

.mdi-cellphone-arrow-down::before {
  content: "\f09d5";
}

.mdi-cellphone-basic::before {
  content: "\f011e";
}

.mdi-cellphone-charging::before {
  content: "\f1397";
}

.mdi-cellphone-cog::before {
  content: "\f0951";
}

.mdi-cellphone-dock::before {
  content: "\f011f";
}

.mdi-cellphone-erase::before {
  content: "\f094d";
}

.mdi-cellphone-information::before {
  content: "\f0f41";
}

.mdi-cellphone-iphone::before {
  content: "\f0120";
}

.mdi-cellphone-key::before {
  content: "\f094e";
}

.mdi-cellphone-link::before {
  content: "\f0121";
}

.mdi-cellphone-link-off::before {
  content: "\f0122";
}

.mdi-cellphone-lock::before {
  content: "\f094f";
}

.mdi-cellphone-message::before {
  content: "\f08d3";
}

.mdi-cellphone-message-off::before {
  content: "\f10d2";
}

.mdi-cellphone-nfc::before {
  content: "\f0e90";
}

.mdi-cellphone-nfc-off::before {
  content: "\f12d8";
}

.mdi-cellphone-off::before {
  content: "\f0950";
}

.mdi-cellphone-play::before {
  content: "\f101f";
}

.mdi-cellphone-screenshot::before {
  content: "\f0a35";
}

.mdi-cellphone-settings::before {
  content: "\f0123";
}

.mdi-cellphone-sound::before {
  content: "\f0952";
}

.mdi-cellphone-text::before {
  content: "\f08d2";
}

.mdi-cellphone-wireless::before {
  content: "\f0815";
}

.mdi-celtic-cross::before {
  content: "\f0cf5";
}

.mdi-centos::before {
  content: "\f111a";
}

.mdi-certificate::before {
  content: "\f0124";
}

.mdi-certificate-outline::before {
  content: "\f1188";
}

.mdi-chair-rolling::before {
  content: "\f0f48";
}

.mdi-chair-school::before {
  content: "\f0125";
}

.mdi-charity::before {
  content: "\f0c4f";
}

.mdi-chart-arc::before {
  content: "\f0126";
}

.mdi-chart-areaspline::before {
  content: "\f0127";
}

.mdi-chart-areaspline-variant::before {
  content: "\f0e91";
}

.mdi-chart-bar::before {
  content: "\f0128";
}

.mdi-chart-bar-stacked::before {
  content: "\f076a";
}

.mdi-chart-bell-curve::before {
  content: "\f0c50";
}

.mdi-chart-bell-curve-cumulative::before {
  content: "\f0fa7";
}

.mdi-chart-box::before {
  content: "\f154d";
}

.mdi-chart-box-outline::before {
  content: "\f154e";
}

.mdi-chart-box-plus-outline::before {
  content: "\f154f";
}

.mdi-chart-bubble::before {
  content: "\f05e3";
}

.mdi-chart-donut::before {
  content: "\f07af";
}

.mdi-chart-donut-variant::before {
  content: "\f07b0";
}

.mdi-chart-gantt::before {
  content: "\f066c";
}

.mdi-chart-histogram::before {
  content: "\f0129";
}

.mdi-chart-line::before {
  content: "\f012a";
}

.mdi-chart-line-stacked::before {
  content: "\f076b";
}

.mdi-chart-line-variant::before {
  content: "\f07b1";
}

.mdi-chart-multiline::before {
  content: "\f08d4";
}

.mdi-chart-multiple::before {
  content: "\f1213";
}

.mdi-chart-pie::before {
  content: "\f012b";
}

.mdi-chart-ppf::before {
  content: "\f1380";
}

.mdi-chart-sankey::before {
  content: "\f11df";
}

.mdi-chart-sankey-variant::before {
  content: "\f11e0";
}

.mdi-chart-scatter-plot::before {
  content: "\f0e92";
}

.mdi-chart-scatter-plot-hexbin::before {
  content: "\f066d";
}

.mdi-chart-timeline::before {
  content: "\f066e";
}

.mdi-chart-timeline-variant::before {
  content: "\f0e93";
}

.mdi-chart-timeline-variant-shimmer::before {
  content: "\f15b6";
}

.mdi-chart-tree::before {
  content: "\f0e94";
}

.mdi-chat::before {
  content: "\f0b79";
}

.mdi-chat-alert::before {
  content: "\f0b7a";
}

.mdi-chat-alert-outline::before {
  content: "\f12c9";
}

.mdi-chat-minus::before {
  content: "\f1410";
}

.mdi-chat-minus-outline::before {
  content: "\f1413";
}

.mdi-chat-outline::before {
  content: "\f0ede";
}

.mdi-chat-plus::before {
  content: "\f140f";
}

.mdi-chat-plus-outline::before {
  content: "\f1412";
}

.mdi-chat-processing::before {
  content: "\f0b7b";
}

.mdi-chat-processing-outline::before {
  content: "\f12ca";
}

.mdi-chat-question::before {
  content: "\f1738";
}

.mdi-chat-question-outline::before {
  content: "\f1739";
}

.mdi-chat-remove::before {
  content: "\f1411";
}

.mdi-chat-remove-outline::before {
  content: "\f1414";
}

.mdi-chat-sleep::before {
  content: "\f12d1";
}

.mdi-chat-sleep-outline::before {
  content: "\f12d2";
}

.mdi-check::before {
  content: "\f012c";
}

.mdi-check-all::before {
  content: "\f012d";
}

.mdi-check-bold::before {
  content: "\f0e1e";
}

.mdi-check-box-multiple-outline::before {
  content: "\f0c51";
}

.mdi-check-box-outline::before {
  content: "\f0c52";
}

.mdi-check-circle::before {
  content: "\f05e0";
}

.mdi-check-circle-outline::before {
  content: "\f05e1";
}

.mdi-check-decagram::before {
  content: "\f0791";
}

.mdi-check-decagram-outline::before {
  content: "\f1740";
}

.mdi-check-network::before {
  content: "\f0c53";
}

.mdi-check-network-outline::before {
  content: "\f0c54";
}

.mdi-check-outline::before {
  content: "\f0855";
}

.mdi-check-underline::before {
  content: "\f0e1f";
}

.mdi-check-underline-circle::before {
  content: "\f0e20";
}

.mdi-check-underline-circle-outline::before {
  content: "\f0e21";
}

.mdi-checkbook::before {
  content: "\f0a9d";
}

.mdi-checkbox-blank::before {
  content: "\f012e";
}

.mdi-checkbox-blank-circle::before {
  content: "\f012f";
}

.mdi-checkbox-blank-circle-outline::before {
  content: "\f0130";
}

.mdi-checkbox-blank-off::before {
  content: "\f12ec";
}

.mdi-checkbox-blank-off-outline::before {
  content: "\f12ed";
}

.mdi-checkbox-blank-outline::before {
  content: "\f0131";
}

.mdi-checkbox-intermediate::before {
  content: "\f0856";
}

.mdi-checkbox-marked::before {
  content: "\f0132";
}

.mdi-checkbox-marked-circle::before {
  content: "\f0133";
}

.mdi-checkbox-marked-circle-outline::before {
  content: "\f0134";
}

.mdi-checkbox-marked-outline::before {
  content: "\f0135";
}

.mdi-checkbox-multiple-blank::before {
  content: "\f0136";
}

.mdi-checkbox-multiple-blank-circle::before {
  content: "\f063b";
}

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\f063c";
}

.mdi-checkbox-multiple-blank-outline::before {
  content: "\f0137";
}

.mdi-checkbox-multiple-marked::before {
  content: "\f0138";
}

.mdi-checkbox-multiple-marked-circle::before {
  content: "\f063d";
}

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\f063e";
}

.mdi-checkbox-multiple-marked-outline::before {
  content: "\f0139";
}

.mdi-checkerboard::before {
  content: "\f013a";
}

.mdi-checkerboard-minus::before {
  content: "\f1202";
}

.mdi-checkerboard-plus::before {
  content: "\f1201";
}

.mdi-checkerboard-remove::before {
  content: "\f1203";
}

.mdi-cheese::before {
  content: "\f12b9";
}

.mdi-cheese-off::before {
  content: "\f13ee";
}

.mdi-chef-hat::before {
  content: "\f0b7c";
}

.mdi-chemical-weapon::before {
  content: "\f013b";
}

.mdi-chess-bishop::before {
  content: "\f085c";
}

.mdi-chess-king::before {
  content: "\f0857";
}

.mdi-chess-knight::before {
  content: "\f0858";
}

.mdi-chess-pawn::before {
  content: "\f0859";
}

.mdi-chess-queen::before {
  content: "\f085a";
}

.mdi-chess-rook::before {
  content: "\f085b";
}

.mdi-chevron-double-down::before {
  content: "\f013c";
}

.mdi-chevron-double-left::before {
  content: "\f013d";
}

.mdi-chevron-double-right::before {
  content: "\f013e";
}

.mdi-chevron-double-up::before {
  content: "\f013f";
}

.mdi-chevron-down::before {
  content: "\f0140";
}

.mdi-chevron-down-box::before {
  content: "\f09d6";
}

.mdi-chevron-down-box-outline::before {
  content: "\f09d7";
}

.mdi-chevron-down-circle::before {
  content: "\f0b26";
}

.mdi-chevron-down-circle-outline::before {
  content: "\f0b27";
}

.mdi-chevron-left::before {
  content: "\f0141";
}

.mdi-chevron-left-box::before {
  content: "\f09d8";
}

.mdi-chevron-left-box-outline::before {
  content: "\f09d9";
}

.mdi-chevron-left-circle::before {
  content: "\f0b28";
}

.mdi-chevron-left-circle-outline::before {
  content: "\f0b29";
}

.mdi-chevron-right::before {
  content: "\f0142";
}

.mdi-chevron-right-box::before {
  content: "\f09da";
}

.mdi-chevron-right-box-outline::before {
  content: "\f09db";
}

.mdi-chevron-right-circle::before {
  content: "\f0b2a";
}

.mdi-chevron-right-circle-outline::before {
  content: "\f0b2b";
}

.mdi-chevron-triple-down::before {
  content: "\f0db9";
}

.mdi-chevron-triple-left::before {
  content: "\f0dba";
}

.mdi-chevron-triple-right::before {
  content: "\f0dbb";
}

.mdi-chevron-triple-up::before {
  content: "\f0dbc";
}

.mdi-chevron-up::before {
  content: "\f0143";
}

.mdi-chevron-up-box::before {
  content: "\f09dc";
}

.mdi-chevron-up-box-outline::before {
  content: "\f09dd";
}

.mdi-chevron-up-circle::before {
  content: "\f0b2c";
}

.mdi-chevron-up-circle-outline::before {
  content: "\f0b2d";
}

.mdi-chili-hot::before {
  content: "\f07b2";
}

.mdi-chili-medium::before {
  content: "\f07b3";
}

.mdi-chili-mild::before {
  content: "\f07b4";
}

.mdi-chili-off::before {
  content: "\f1467";
}

.mdi-chip::before {
  content: "\f061a";
}

.mdi-christianity::before {
  content: "\f0953";
}

.mdi-christianity-outline::before {
  content: "\f0cf6";
}

.mdi-church::before {
  content: "\f0144";
}

.mdi-cigar::before {
  content: "\f1189";
}

.mdi-cigar-off::before {
  content: "\f141b";
}

.mdi-circle::before {
  content: "\f0765";
}

.mdi-circle-box::before {
  content: "\f15dc";
}

.mdi-circle-box-outline::before {
  content: "\f15dd";
}

.mdi-circle-double::before {
  content: "\f0e95";
}

.mdi-circle-edit-outline::before {
  content: "\f08d5";
}

.mdi-circle-expand::before {
  content: "\f0e96";
}

.mdi-circle-half::before {
  content: "\f1395";
}

.mdi-circle-half-full::before {
  content: "\f1396";
}

.mdi-circle-medium::before {
  content: "\f09de";
}

.mdi-circle-multiple::before {
  content: "\f0b38";
}

.mdi-circle-multiple-outline::before {
  content: "\f0695";
}

.mdi-circle-off-outline::before {
  content: "\f10d3";
}

.mdi-circle-outline::before {
  content: "\f0766";
}

.mdi-circle-slice-1::before {
  content: "\f0a9e";
}

.mdi-circle-slice-2::before {
  content: "\f0a9f";
}

.mdi-circle-slice-3::before {
  content: "\f0aa0";
}

.mdi-circle-slice-4::before {
  content: "\f0aa1";
}

.mdi-circle-slice-5::before {
  content: "\f0aa2";
}

.mdi-circle-slice-6::before {
  content: "\f0aa3";
}

.mdi-circle-slice-7::before {
  content: "\f0aa4";
}

.mdi-circle-slice-8::before {
  content: "\f0aa5";
}

.mdi-circle-small::before {
  content: "\f09df";
}

.mdi-circular-saw::before {
  content: "\f0e22";
}

.mdi-city::before {
  content: "\f0146";
}

.mdi-city-variant::before {
  content: "\f0a36";
}

.mdi-city-variant-outline::before {
  content: "\f0a37";
}

.mdi-clipboard::before {
  content: "\f0147";
}

.mdi-clipboard-account::before {
  content: "\f0148";
}

.mdi-clipboard-account-outline::before {
  content: "\f0c55";
}

.mdi-clipboard-alert::before {
  content: "\f0149";
}

.mdi-clipboard-alert-outline::before {
  content: "\f0cf7";
}

.mdi-clipboard-arrow-down::before {
  content: "\f014a";
}

.mdi-clipboard-arrow-down-outline::before {
  content: "\f0c56";
}

.mdi-clipboard-arrow-left::before {
  content: "\f014b";
}

.mdi-clipboard-arrow-left-outline::before {
  content: "\f0cf8";
}

.mdi-clipboard-arrow-right::before {
  content: "\f0cf9";
}

.mdi-clipboard-arrow-right-outline::before {
  content: "\f0cfa";
}

.mdi-clipboard-arrow-up::before {
  content: "\f0c57";
}

.mdi-clipboard-arrow-up-outline::before {
  content: "\f0c58";
}

.mdi-clipboard-check::before {
  content: "\f014e";
}

.mdi-clipboard-check-multiple::before {
  content: "\f1263";
}

.mdi-clipboard-check-multiple-outline::before {
  content: "\f1264";
}

.mdi-clipboard-check-outline::before {
  content: "\f08a8";
}

.mdi-clipboard-clock::before {
  content: "\f16e2";
}

.mdi-clipboard-clock-outline::before {
  content: "\f16e3";
}

.mdi-clipboard-edit::before {
  content: "\f14e5";
}

.mdi-clipboard-edit-outline::before {
  content: "\f14e6";
}

.mdi-clipboard-file::before {
  content: "\f1265";
}

.mdi-clipboard-file-outline::before {
  content: "\f1266";
}

.mdi-clipboard-flow::before {
  content: "\f06c8";
}

.mdi-clipboard-flow-outline::before {
  content: "\f1117";
}

.mdi-clipboard-list::before {
  content: "\f10d4";
}

.mdi-clipboard-list-outline::before {
  content: "\f10d5";
}

.mdi-clipboard-minus::before {
  content: "\f1618";
}

.mdi-clipboard-minus-outline::before {
  content: "\f1619";
}

.mdi-clipboard-multiple::before {
  content: "\f1267";
}

.mdi-clipboard-multiple-outline::before {
  content: "\f1268";
}

.mdi-clipboard-off::before {
  content: "\f161a";
}

.mdi-clipboard-off-outline::before {
  content: "\f161b";
}

.mdi-clipboard-outline::before {
  content: "\f014c";
}

.mdi-clipboard-play::before {
  content: "\f0c59";
}

.mdi-clipboard-play-multiple::before {
  content: "\f1269";
}

.mdi-clipboard-play-multiple-outline::before {
  content: "\f126a";
}

.mdi-clipboard-play-outline::before {
  content: "\f0c5a";
}

.mdi-clipboard-plus::before {
  content: "\f0751";
}

.mdi-clipboard-plus-outline::before {
  content: "\f131f";
}

.mdi-clipboard-pulse::before {
  content: "\f085d";
}

.mdi-clipboard-pulse-outline::before {
  content: "\f085e";
}

.mdi-clipboard-remove::before {
  content: "\f161c";
}

.mdi-clipboard-remove-outline::before {
  content: "\f161d";
}

.mdi-clipboard-search::before {
  content: "\f161e";
}

.mdi-clipboard-search-outline::before {
  content: "\f161f";
}

.mdi-clipboard-text::before {
  content: "\f014d";
}

.mdi-clipboard-text-multiple::before {
  content: "\f126b";
}

.mdi-clipboard-text-multiple-outline::before {
  content: "\f126c";
}

.mdi-clipboard-text-off::before {
  content: "\f1620";
}

.mdi-clipboard-text-off-outline::before {
  content: "\f1621";
}

.mdi-clipboard-text-outline::before {
  content: "\f0a38";
}

.mdi-clipboard-text-play::before {
  content: "\f0c5b";
}

.mdi-clipboard-text-play-outline::before {
  content: "\f0c5c";
}

.mdi-clipboard-text-search::before {
  content: "\f1622";
}

.mdi-clipboard-text-search-outline::before {
  content: "\f1623";
}

.mdi-clippy::before {
  content: "\f014f";
}

.mdi-clock::before {
  content: "\f0954";
}

.mdi-clock-alert::before {
  content: "\f0955";
}

.mdi-clock-alert-outline::before {
  content: "\f05ce";
}

.mdi-clock-check::before {
  content: "\f0fa8";
}

.mdi-clock-check-outline::before {
  content: "\f0fa9";
}

.mdi-clock-digital::before {
  content: "\f0e97";
}

.mdi-clock-end::before {
  content: "\f0151";
}

.mdi-clock-fast::before {
  content: "\f0152";
}

.mdi-clock-in::before {
  content: "\f0153";
}

.mdi-clock-out::before {
  content: "\f0154";
}

.mdi-clock-outline::before {
  content: "\f0150";
}

.mdi-clock-start::before {
  content: "\f0155";
}

.mdi-clock-time-eight::before {
  content: "\f1446";
}

.mdi-clock-time-eight-outline::before {
  content: "\f1452";
}

.mdi-clock-time-eleven::before {
  content: "\f1449";
}

.mdi-clock-time-eleven-outline::before {
  content: "\f1455";
}

.mdi-clock-time-five::before {
  content: "\f1443";
}

.mdi-clock-time-five-outline::before {
  content: "\f144f";
}

.mdi-clock-time-four::before {
  content: "\f1442";
}

.mdi-clock-time-four-outline::before {
  content: "\f144e";
}

.mdi-clock-time-nine::before {
  content: "\f1447";
}

.mdi-clock-time-nine-outline::before {
  content: "\f1453";
}

.mdi-clock-time-one::before {
  content: "\f143f";
}

.mdi-clock-time-one-outline::before {
  content: "\f144b";
}

.mdi-clock-time-seven::before {
  content: "\f1445";
}

.mdi-clock-time-seven-outline::before {
  content: "\f1451";
}

.mdi-clock-time-six::before {
  content: "\f1444";
}

.mdi-clock-time-six-outline::before {
  content: "\f1450";
}

.mdi-clock-time-ten::before {
  content: "\f1448";
}

.mdi-clock-time-ten-outline::before {
  content: "\f1454";
}

.mdi-clock-time-three::before {
  content: "\f1441";
}

.mdi-clock-time-three-outline::before {
  content: "\f144d";
}

.mdi-clock-time-twelve::before {
  content: "\f144a";
}

.mdi-clock-time-twelve-outline::before {
  content: "\f1456";
}

.mdi-clock-time-two::before {
  content: "\f1440";
}

.mdi-clock-time-two-outline::before {
  content: "\f144c";
}

.mdi-close::before {
  content: "\f0156";
}

.mdi-close-box::before {
  content: "\f0157";
}

.mdi-close-box-multiple::before {
  content: "\f0c5d";
}

.mdi-close-box-multiple-outline::before {
  content: "\f0c5e";
}

.mdi-close-box-outline::before {
  content: "\f0158";
}

.mdi-close-circle::before {
  content: "\f0159";
}

.mdi-close-circle-multiple::before {
  content: "\f062a";
}

.mdi-close-circle-multiple-outline::before {
  content: "\f0883";
}

.mdi-close-circle-outline::before {
  content: "\f015a";
}

.mdi-close-network::before {
  content: "\f015b";
}

.mdi-close-network-outline::before {
  content: "\f0c5f";
}

.mdi-close-octagon::before {
  content: "\f015c";
}

.mdi-close-octagon-outline::before {
  content: "\f015d";
}

.mdi-close-outline::before {
  content: "\f06c9";
}

.mdi-close-thick::before {
  content: "\f1398";
}

.mdi-closed-caption::before {
  content: "\f015e";
}

.mdi-closed-caption-outline::before {
  content: "\f0dbd";
}

.mdi-cloud::before {
  content: "\f015f";
}

.mdi-cloud-alert::before {
  content: "\f09e0";
}

.mdi-cloud-braces::before {
  content: "\f07b5";
}

.mdi-cloud-check::before {
  content: "\f0160";
}

.mdi-cloud-check-outline::before {
  content: "\f12cc";
}

.mdi-cloud-circle::before {
  content: "\f0161";
}

.mdi-cloud-download::before {
  content: "\f0162";
}

.mdi-cloud-download-outline::before {
  content: "\f0b7d";
}

.mdi-cloud-lock::before {
  content: "\f11f1";
}

.mdi-cloud-lock-outline::before {
  content: "\f11f2";
}

.mdi-cloud-off-outline::before {
  content: "\f0164";
}

.mdi-cloud-outline::before {
  content: "\f0163";
}

.mdi-cloud-print::before {
  content: "\f0165";
}

.mdi-cloud-print-outline::before {
  content: "\f0166";
}

.mdi-cloud-question::before {
  content: "\f0a39";
}

.mdi-cloud-refresh::before {
  content: "\f052a";
}

.mdi-cloud-search::before {
  content: "\f0956";
}

.mdi-cloud-search-outline::before {
  content: "\f0957";
}

.mdi-cloud-sync::before {
  content: "\f063f";
}

.mdi-cloud-sync-outline::before {
  content: "\f12d6";
}

.mdi-cloud-tags::before {
  content: "\f07b6";
}

.mdi-cloud-upload::before {
  content: "\f0167";
}

.mdi-cloud-upload-outline::before {
  content: "\f0b7e";
}

.mdi-clover::before {
  content: "\f0816";
}

.mdi-coach-lamp::before {
  content: "\f1020";
}

.mdi-coat-rack::before {
  content: "\f109e";
}

.mdi-code-array::before {
  content: "\f0168";
}

.mdi-code-braces::before {
  content: "\f0169";
}

.mdi-code-braces-box::before {
  content: "\f10d6";
}

.mdi-code-brackets::before {
  content: "\f016a";
}

.mdi-code-equal::before {
  content: "\f016b";
}

.mdi-code-greater-than::before {
  content: "\f016c";
}

.mdi-code-greater-than-or-equal::before {
  content: "\f016d";
}

.mdi-code-json::before {
  content: "\f0626";
}

.mdi-code-less-than::before {
  content: "\f016e";
}

.mdi-code-less-than-or-equal::before {
  content: "\f016f";
}

.mdi-code-not-equal::before {
  content: "\f0170";
}

.mdi-code-not-equal-variant::before {
  content: "\f0171";
}

.mdi-code-parentheses::before {
  content: "\f0172";
}

.mdi-code-parentheses-box::before {
  content: "\f10d7";
}

.mdi-code-string::before {
  content: "\f0173";
}

.mdi-code-tags::before {
  content: "\f0174";
}

.mdi-code-tags-check::before {
  content: "\f0694";
}

.mdi-codepen::before {
  content: "\f0175";
}

.mdi-coffee::before {
  content: "\f0176";
}

.mdi-coffee-maker::before {
  content: "\f109f";
}

.mdi-coffee-off::before {
  content: "\f0faa";
}

.mdi-coffee-off-outline::before {
  content: "\f0fab";
}

.mdi-coffee-outline::before {
  content: "\f06ca";
}

.mdi-coffee-to-go::before {
  content: "\f0177";
}

.mdi-coffee-to-go-outline::before {
  content: "\f130e";
}

.mdi-coffin::before {
  content: "\f0b7f";
}

.mdi-cog::before {
  content: "\f0493";
}

.mdi-cog-box::before {
  content: "\f0494";
}

.mdi-cog-clockwise::before {
  content: "\f11dd";
}

.mdi-cog-counterclockwise::before {
  content: "\f11de";
}

.mdi-cog-off::before {
  content: "\f13ce";
}

.mdi-cog-off-outline::before {
  content: "\f13cf";
}

.mdi-cog-outline::before {
  content: "\f08bb";
}

.mdi-cog-refresh::before {
  content: "\f145e";
}

.mdi-cog-refresh-outline::before {
  content: "\f145f";
}

.mdi-cog-sync::before {
  content: "\f1460";
}

.mdi-cog-sync-outline::before {
  content: "\f1461";
}

.mdi-cog-transfer::before {
  content: "\f105b";
}

.mdi-cog-transfer-outline::before {
  content: "\f105c";
}

.mdi-cogs::before {
  content: "\f08d6";
}

.mdi-collage::before {
  content: "\f0640";
}

.mdi-collapse-all::before {
  content: "\f0aa6";
}

.mdi-collapse-all-outline::before {
  content: "\f0aa7";
}

.mdi-color-helper::before {
  content: "\f0179";
}

.mdi-comma::before {
  content: "\f0e23";
}

.mdi-comma-box::before {
  content: "\f0e2b";
}

.mdi-comma-box-outline::before {
  content: "\f0e24";
}

.mdi-comma-circle::before {
  content: "\f0e25";
}

.mdi-comma-circle-outline::before {
  content: "\f0e26";
}

.mdi-comment::before {
  content: "\f017a";
}

.mdi-comment-account::before {
  content: "\f017b";
}

.mdi-comment-account-outline::before {
  content: "\f017c";
}

.mdi-comment-alert::before {
  content: "\f017d";
}

.mdi-comment-alert-outline::before {
  content: "\f017e";
}

.mdi-comment-arrow-left::before {
  content: "\f09e1";
}

.mdi-comment-arrow-left-outline::before {
  content: "\f09e2";
}

.mdi-comment-arrow-right::before {
  content: "\f09e3";
}

.mdi-comment-arrow-right-outline::before {
  content: "\f09e4";
}

.mdi-comment-bookmark::before {
  content: "\f15ae";
}

.mdi-comment-bookmark-outline::before {
  content: "\f15af";
}

.mdi-comment-check::before {
  content: "\f017f";
}

.mdi-comment-check-outline::before {
  content: "\f0180";
}

.mdi-comment-edit::before {
  content: "\f11bf";
}

.mdi-comment-edit-outline::before {
  content: "\f12c4";
}

.mdi-comment-eye::before {
  content: "\f0a3a";
}

.mdi-comment-eye-outline::before {
  content: "\f0a3b";
}

.mdi-comment-flash::before {
  content: "\f15b0";
}

.mdi-comment-flash-outline::before {
  content: "\f15b1";
}

.mdi-comment-minus::before {
  content: "\f15df";
}

.mdi-comment-minus-outline::before {
  content: "\f15e0";
}

.mdi-comment-multiple::before {
  content: "\f085f";
}

.mdi-comment-multiple-outline::before {
  content: "\f0181";
}

.mdi-comment-off::before {
  content: "\f15e1";
}

.mdi-comment-off-outline::before {
  content: "\f15e2";
}

.mdi-comment-outline::before {
  content: "\f0182";
}

.mdi-comment-plus::before {
  content: "\f09e5";
}

.mdi-comment-plus-outline::before {
  content: "\f0183";
}

.mdi-comment-processing::before {
  content: "\f0184";
}

.mdi-comment-processing-outline::before {
  content: "\f0185";
}

.mdi-comment-question::before {
  content: "\f0817";
}

.mdi-comment-question-outline::before {
  content: "\f0186";
}

.mdi-comment-quote::before {
  content: "\f1021";
}

.mdi-comment-quote-outline::before {
  content: "\f1022";
}

.mdi-comment-remove::before {
  content: "\f05de";
}

.mdi-comment-remove-outline::before {
  content: "\f0187";
}

.mdi-comment-search::before {
  content: "\f0a3c";
}

.mdi-comment-search-outline::before {
  content: "\f0a3d";
}

.mdi-comment-text::before {
  content: "\f0188";
}

.mdi-comment-text-multiple::before {
  content: "\f0860";
}

.mdi-comment-text-multiple-outline::before {
  content: "\f0861";
}

.mdi-comment-text-outline::before {
  content: "\f0189";
}

.mdi-compare::before {
  content: "\f018a";
}

.mdi-compare-horizontal::before {
  content: "\f1492";
}

.mdi-compare-vertical::before {
  content: "\f1493";
}

.mdi-compass::before {
  content: "\f018b";
}

.mdi-compass-off::before {
  content: "\f0b80";
}

.mdi-compass-off-outline::before {
  content: "\f0b81";
}

.mdi-compass-outline::before {
  content: "\f018c";
}

.mdi-compass-rose::before {
  content: "\f1382";
}

.mdi-concourse-ci::before {
  content: "\f10a0";
}

.mdi-connection::before {
  content: "\f1616";
}

.mdi-console::before {
  content: "\f018d";
}

.mdi-console-line::before {
  content: "\f07b7";
}

.mdi-console-network::before {
  content: "\f08a9";
}

.mdi-console-network-outline::before {
  content: "\f0c60";
}

.mdi-consolidate::before {
  content: "\f10d8";
}

.mdi-contactless-payment::before {
  content: "\f0d6a";
}

.mdi-contactless-payment-circle::before {
  content: "\f0321";
}

.mdi-contactless-payment-circle-outline::before {
  content: "\f0408";
}

.mdi-contacts::before {
  content: "\f06cb";
}

.mdi-contacts-outline::before {
  content: "\f05b8";
}

.mdi-contain::before {
  content: "\f0a3e";
}

.mdi-contain-end::before {
  content: "\f0a3f";
}

.mdi-contain-start::before {
  content: "\f0a40";
}

.mdi-content-copy::before {
  content: "\f018f";
}

.mdi-content-cut::before {
  content: "\f0190";
}

.mdi-content-duplicate::before {
  content: "\f0191";
}

.mdi-content-paste::before {
  content: "\f0192";
}

.mdi-content-save::before {
  content: "\f0193";
}

.mdi-content-save-alert::before {
  content: "\f0f42";
}

.mdi-content-save-alert-outline::before {
  content: "\f0f43";
}

.mdi-content-save-all::before {
  content: "\f0194";
}

.mdi-content-save-all-outline::before {
  content: "\f0f44";
}

.mdi-content-save-cog::before {
  content: "\f145b";
}

.mdi-content-save-cog-outline::before {
  content: "\f145c";
}

.mdi-content-save-edit::before {
  content: "\f0cfb";
}

.mdi-content-save-edit-outline::before {
  content: "\f0cfc";
}

.mdi-content-save-move::before {
  content: "\f0e27";
}

.mdi-content-save-move-outline::before {
  content: "\f0e28";
}

.mdi-content-save-off::before {
  content: "\f1643";
}

.mdi-content-save-off-outline::before {
  content: "\f1644";
}

.mdi-content-save-outline::before {
  content: "\f0818";
}

.mdi-content-save-settings::before {
  content: "\f061b";
}

.mdi-content-save-settings-outline::before {
  content: "\f0b2e";
}

.mdi-contrast::before {
  content: "\f0195";
}

.mdi-contrast-box::before {
  content: "\f0196";
}

.mdi-contrast-circle::before {
  content: "\f0197";
}

.mdi-controller-classic::before {
  content: "\f0b82";
}

.mdi-controller-classic-outline::before {
  content: "\f0b83";
}

.mdi-cookie::before {
  content: "\f0198";
}

.mdi-cookie-alert::before {
  content: "\f16d0";
}

.mdi-cookie-alert-outline::before {
  content: "\f16d1";
}

.mdi-cookie-check::before {
  content: "\f16d2";
}

.mdi-cookie-check-outline::before {
  content: "\f16d3";
}

.mdi-cookie-clock::before {
  content: "\f16e4";
}

.mdi-cookie-clock-outline::before {
  content: "\f16e5";
}

.mdi-cookie-cog::before {
  content: "\f16d4";
}

.mdi-cookie-cog-outline::before {
  content: "\f16d5";
}

.mdi-cookie-edit::before {
  content: "\f16e6";
}

.mdi-cookie-edit-outline::before {
  content: "\f16e7";
}

.mdi-cookie-lock::before {
  content: "\f16e8";
}

.mdi-cookie-lock-outline::before {
  content: "\f16e9";
}

.mdi-cookie-minus::before {
  content: "\f16da";
}

.mdi-cookie-minus-outline::before {
  content: "\f16db";
}

.mdi-cookie-off::before {
  content: "\f16ea";
}

.mdi-cookie-off-outline::before {
  content: "\f16eb";
}

.mdi-cookie-outline::before {
  content: "\f16de";
}

.mdi-cookie-plus::before {
  content: "\f16d6";
}

.mdi-cookie-plus-outline::before {
  content: "\f16d7";
}

.mdi-cookie-refresh::before {
  content: "\f16ec";
}

.mdi-cookie-refresh-outline::before {
  content: "\f16ed";
}

.mdi-cookie-remove::before {
  content: "\f16d8";
}

.mdi-cookie-remove-outline::before {
  content: "\f16d9";
}

.mdi-cookie-settings::before {
  content: "\f16dc";
}

.mdi-cookie-settings-outline::before {
  content: "\f16dd";
}

.mdi-coolant-temperature::before {
  content: "\f03c8";
}

.mdi-copyright::before {
  content: "\f05e6";
}

.mdi-cordova::before {
  content: "\f0958";
}

.mdi-corn::before {
  content: "\f07b8";
}

.mdi-corn-off::before {
  content: "\f13ef";
}

.mdi-cosine-wave::before {
  content: "\f1479";
}

.mdi-counter::before {
  content: "\f0199";
}

.mdi-cow::before {
  content: "\f019a";
}

.mdi-cpu-32-bit::before {
  content: "\f0edf";
}

.mdi-cpu-64-bit::before {
  content: "\f0ee0";
}

.mdi-crane::before {
  content: "\f0862";
}

.mdi-creation::before {
  content: "\f0674";
}

.mdi-creative-commons::before {
  content: "\f0d6b";
}

.mdi-credit-card::before {
  content: "\f0fef";
}

.mdi-credit-card-check::before {
  content: "\f13d0";
}

.mdi-credit-card-check-outline::before {
  content: "\f13d1";
}

.mdi-credit-card-clock::before {
  content: "\f0ee1";
}

.mdi-credit-card-clock-outline::before {
  content: "\f0ee2";
}

.mdi-credit-card-marker::before {
  content: "\f06a8";
}

.mdi-credit-card-marker-outline::before {
  content: "\f0dbe";
}

.mdi-credit-card-minus::before {
  content: "\f0fac";
}

.mdi-credit-card-minus-outline::before {
  content: "\f0fad";
}

.mdi-credit-card-multiple::before {
  content: "\f0ff0";
}

.mdi-credit-card-multiple-outline::before {
  content: "\f019c";
}

.mdi-credit-card-off::before {
  content: "\f0ff1";
}

.mdi-credit-card-off-outline::before {
  content: "\f05e4";
}

.mdi-credit-card-outline::before {
  content: "\f019b";
}

.mdi-credit-card-plus::before {
  content: "\f0ff2";
}

.mdi-credit-card-plus-outline::before {
  content: "\f0676";
}

.mdi-credit-card-refresh::before {
  content: "\f1645";
}

.mdi-credit-card-refresh-outline::before {
  content: "\f1646";
}

.mdi-credit-card-refund::before {
  content: "\f0ff3";
}

.mdi-credit-card-refund-outline::before {
  content: "\f0aa8";
}

.mdi-credit-card-remove::before {
  content: "\f0fae";
}

.mdi-credit-card-remove-outline::before {
  content: "\f0faf";
}

.mdi-credit-card-scan::before {
  content: "\f0ff4";
}

.mdi-credit-card-scan-outline::before {
  content: "\f019d";
}

.mdi-credit-card-search::before {
  content: "\f1647";
}

.mdi-credit-card-search-outline::before {
  content: "\f1648";
}

.mdi-credit-card-settings::before {
  content: "\f0ff5";
}

.mdi-credit-card-settings-outline::before {
  content: "\f08d7";
}

.mdi-credit-card-sync::before {
  content: "\f1649";
}

.mdi-credit-card-sync-outline::before {
  content: "\f164a";
}

.mdi-credit-card-wireless::before {
  content: "\f0802";
}

.mdi-credit-card-wireless-off::before {
  content: "\f057a";
}

.mdi-credit-card-wireless-off-outline::before {
  content: "\f057b";
}

.mdi-credit-card-wireless-outline::before {
  content: "\f0d6c";
}

.mdi-cricket::before {
  content: "\f0d6d";
}

.mdi-crop::before {
  content: "\f019e";
}

.mdi-crop-free::before {
  content: "\f019f";
}

.mdi-crop-landscape::before {
  content: "\f01a0";
}

.mdi-crop-portrait::before {
  content: "\f01a1";
}

.mdi-crop-rotate::before {
  content: "\f0696";
}

.mdi-crop-square::before {
  content: "\f01a2";
}

.mdi-crosshairs::before {
  content: "\f01a3";
}

.mdi-crosshairs-gps::before {
  content: "\f01a4";
}

.mdi-crosshairs-off::before {
  content: "\f0f45";
}

.mdi-crosshairs-question::before {
  content: "\f1136";
}

.mdi-crown::before {
  content: "\f01a5";
}

.mdi-crown-outline::before {
  content: "\f11d0";
}

.mdi-cryengine::before {
  content: "\f0959";
}

.mdi-crystal-ball::before {
  content: "\f0b2f";
}

.mdi-cube::before {
  content: "\f01a6";
}

.mdi-cube-off::before {
  content: "\f141c";
}

.mdi-cube-off-outline::before {
  content: "\f141d";
}

.mdi-cube-outline::before {
  content: "\f01a7";
}

.mdi-cube-scan::before {
  content: "\f0b84";
}

.mdi-cube-send::before {
  content: "\f01a8";
}

.mdi-cube-unfolded::before {
  content: "\f01a9";
}

.mdi-cup::before {
  content: "\f01aa";
}

.mdi-cup-off::before {
  content: "\f05e5";
}

.mdi-cup-off-outline::before {
  content: "\f137d";
}

.mdi-cup-outline::before {
  content: "\f130f";
}

.mdi-cup-water::before {
  content: "\f01ab";
}

.mdi-cupboard::before {
  content: "\f0f46";
}

.mdi-cupboard-outline::before {
  content: "\f0f47";
}

.mdi-cupcake::before {
  content: "\f095a";
}

.mdi-curling::before {
  content: "\f0863";
}

.mdi-currency-bdt::before {
  content: "\f0864";
}

.mdi-currency-brl::before {
  content: "\f0b85";
}

.mdi-currency-btc::before {
  content: "\f01ac";
}

.mdi-currency-cny::before {
  content: "\f07ba";
}

.mdi-currency-eth::before {
  content: "\f07bb";
}

.mdi-currency-eur::before {
  content: "\f01ad";
}

.mdi-currency-eur-off::before {
  content: "\f1315";
}

.mdi-currency-gbp::before {
  content: "\f01ae";
}

.mdi-currency-ils::before {
  content: "\f0c61";
}

.mdi-currency-inr::before {
  content: "\f01af";
}

.mdi-currency-jpy::before {
  content: "\f07bc";
}

.mdi-currency-krw::before {
  content: "\f07bd";
}

.mdi-currency-kzt::before {
  content: "\f0865";
}

.mdi-currency-mnt::before {
  content: "\f1512";
}

.mdi-currency-ngn::before {
  content: "\f01b0";
}

.mdi-currency-php::before {
  content: "\f09e6";
}

.mdi-currency-rial::before {
  content: "\f0e9c";
}

.mdi-currency-rub::before {
  content: "\f01b1";
}

.mdi-currency-sign::before {
  content: "\f07be";
}

.mdi-currency-try::before {
  content: "\f01b2";
}

.mdi-currency-twd::before {
  content: "\f07bf";
}

.mdi-currency-usd::before {
  content: "\f01c1";
}

.mdi-currency-usd-circle::before {
  content: "\f116b";
}

.mdi-currency-usd-circle-outline::before {
  content: "\f0178";
}

.mdi-currency-usd-off::before {
  content: "\f067a";
}

.mdi-current-ac::before {
  content: "\f1480";
}

.mdi-current-dc::before {
  content: "\f095c";
}

.mdi-cursor-default::before {
  content: "\f01c0";
}

.mdi-cursor-default-click::before {
  content: "\f0cfd";
}

.mdi-cursor-default-click-outline::before {
  content: "\f0cfe";
}

.mdi-cursor-default-gesture::before {
  content: "\f1127";
}

.mdi-cursor-default-gesture-outline::before {
  content: "\f1128";
}

.mdi-cursor-default-outline::before {
  content: "\f01bf";
}

.mdi-cursor-move::before {
  content: "\f01be";
}

.mdi-cursor-pointer::before {
  content: "\f01bd";
}

.mdi-cursor-text::before {
  content: "\f05e7";
}

.mdi-dance-ballroom::before {
  content: "\f15fb";
}

.mdi-dance-pole::before {
  content: "\f1578";
}

.mdi-data-matrix::before {
  content: "\f153c";
}

.mdi-data-matrix-edit::before {
  content: "\f153d";
}

.mdi-data-matrix-minus::before {
  content: "\f153e";
}

.mdi-data-matrix-plus::before {
  content: "\f153f";
}

.mdi-data-matrix-remove::before {
  content: "\f1540";
}

.mdi-data-matrix-scan::before {
  content: "\f1541";
}

.mdi-database::before {
  content: "\f01bc";
}

.mdi-database-alert::before {
  content: "\f163a";
}

.mdi-database-alert-outline::before {
  content: "\f1624";
}

.mdi-database-arrow-down::before {
  content: "\f163b";
}

.mdi-database-arrow-down-outline::before {
  content: "\f1625";
}

.mdi-database-arrow-left::before {
  content: "\f163c";
}

.mdi-database-arrow-left-outline::before {
  content: "\f1626";
}

.mdi-database-arrow-right::before {
  content: "\f163d";
}

.mdi-database-arrow-right-outline::before {
  content: "\f1627";
}

.mdi-database-arrow-up::before {
  content: "\f163e";
}

.mdi-database-arrow-up-outline::before {
  content: "\f1628";
}

.mdi-database-check::before {
  content: "\f0aa9";
}

.mdi-database-check-outline::before {
  content: "\f1629";
}

.mdi-database-clock::before {
  content: "\f163f";
}

.mdi-database-clock-outline::before {
  content: "\f162a";
}

.mdi-database-cog::before {
  content: "\f164b";
}

.mdi-database-cog-outline::before {
  content: "\f164c";
}

.mdi-database-edit::before {
  content: "\f0b86";
}

.mdi-database-edit-outline::before {
  content: "\f162b";
}

.mdi-database-export::before {
  content: "\f095e";
}

.mdi-database-export-outline::before {
  content: "\f162c";
}

.mdi-database-import::before {
  content: "\f095d";
}

.mdi-database-import-outline::before {
  content: "\f162d";
}

.mdi-database-lock::before {
  content: "\f0aaa";
}

.mdi-database-lock-outline::before {
  content: "\f162e";
}

.mdi-database-marker::before {
  content: "\f12f6";
}

.mdi-database-marker-outline::before {
  content: "\f162f";
}

.mdi-database-minus::before {
  content: "\f01bb";
}

.mdi-database-minus-outline::before {
  content: "\f1630";
}

.mdi-database-off::before {
  content: "\f1640";
}

.mdi-database-off-outline::before {
  content: "\f1631";
}

.mdi-database-outline::before {
  content: "\f1632";
}

.mdi-database-plus::before {
  content: "\f01ba";
}

.mdi-database-plus-outline::before {
  content: "\f1633";
}

.mdi-database-refresh::before {
  content: "\f05c2";
}

.mdi-database-refresh-outline::before {
  content: "\f1634";
}

.mdi-database-remove::before {
  content: "\f0d00";
}

.mdi-database-remove-outline::before {
  content: "\f1635";
}

.mdi-database-search::before {
  content: "\f0866";
}

.mdi-database-search-outline::before {
  content: "\f1636";
}

.mdi-database-settings::before {
  content: "\f0d01";
}

.mdi-database-settings-outline::before {
  content: "\f1637";
}

.mdi-database-sync::before {
  content: "\f0cff";
}

.mdi-database-sync-outline::before {
  content: "\f1638";
}

.mdi-death-star::before {
  content: "\f08d8";
}

.mdi-death-star-variant::before {
  content: "\f08d9";
}

.mdi-deathly-hallows::before {
  content: "\f0b87";
}

.mdi-debian::before {
  content: "\f08da";
}

.mdi-debug-step-into::before {
  content: "\f01b9";
}

.mdi-debug-step-out::before {
  content: "\f01b8";
}

.mdi-debug-step-over::before {
  content: "\f01b7";
}

.mdi-decagram::before {
  content: "\f076c";
}

.mdi-decagram-outline::before {
  content: "\f076d";
}

.mdi-decimal::before {
  content: "\f10a1";
}

.mdi-decimal-comma::before {
  content: "\f10a2";
}

.mdi-decimal-comma-decrease::before {
  content: "\f10a3";
}

.mdi-decimal-comma-increase::before {
  content: "\f10a4";
}

.mdi-decimal-decrease::before {
  content: "\f01b6";
}

.mdi-decimal-increase::before {
  content: "\f01b5";
}

.mdi-delete::before {
  content: "\f01b4";
}

.mdi-delete-alert::before {
  content: "\f10a5";
}

.mdi-delete-alert-outline::before {
  content: "\f10a6";
}

.mdi-delete-circle::before {
  content: "\f0683";
}

.mdi-delete-circle-outline::before {
  content: "\f0b88";
}

.mdi-delete-clock::before {
  content: "\f1556";
}

.mdi-delete-clock-outline::before {
  content: "\f1557";
}

.mdi-delete-empty::before {
  content: "\f06cc";
}

.mdi-delete-empty-outline::before {
  content: "\f0e9d";
}

.mdi-delete-forever::before {
  content: "\f05e8";
}

.mdi-delete-forever-outline::before {
  content: "\f0b89";
}

.mdi-delete-off::before {
  content: "\f10a7";
}

.mdi-delete-off-outline::before {
  content: "\f10a8";
}

.mdi-delete-outline::before {
  content: "\f09e7";
}

.mdi-delete-restore::before {
  content: "\f0819";
}

.mdi-delete-sweep::before {
  content: "\f05e9";
}

.mdi-delete-sweep-outline::before {
  content: "\f0c62";
}

.mdi-delete-variant::before {
  content: "\f01b3";
}

.mdi-delta::before {
  content: "\f01c2";
}

.mdi-desk::before {
  content: "\f1239";
}

.mdi-desk-lamp::before {
  content: "\f095f";
}

.mdi-deskphone::before {
  content: "\f01c3";
}

.mdi-desktop-classic::before {
  content: "\f07c0";
}

.mdi-desktop-mac::before {
  content: "\f01c4";
}

.mdi-desktop-mac-dashboard::before {
  content: "\f09e8";
}

.mdi-desktop-tower::before {
  content: "\f01c5";
}

.mdi-desktop-tower-monitor::before {
  content: "\f0aab";
}

.mdi-details::before {
  content: "\f01c6";
}

.mdi-dev-to::before {
  content: "\f0d6e";
}

.mdi-developer-board::before {
  content: "\f0697";
}

.mdi-deviantart::before {
  content: "\f01c7";
}

.mdi-devices::before {
  content: "\f0fb0";
}

.mdi-diabetes::before {
  content: "\f1126";
}

.mdi-dialpad::before {
  content: "\f061c";
}

.mdi-diameter::before {
  content: "\f0c63";
}

.mdi-diameter-outline::before {
  content: "\f0c64";
}

.mdi-diameter-variant::before {
  content: "\f0c65";
}

.mdi-diamond::before {
  content: "\f0b8a";
}

.mdi-diamond-outline::before {
  content: "\f0b8b";
}

.mdi-diamond-stone::before {
  content: "\f01c8";
}

.mdi-dice-1::before {
  content: "\f01ca";
}

.mdi-dice-1-outline::before {
  content: "\f114a";
}

.mdi-dice-2::before {
  content: "\f01cb";
}

.mdi-dice-2-outline::before {
  content: "\f114b";
}

.mdi-dice-3::before {
  content: "\f01cc";
}

.mdi-dice-3-outline::before {
  content: "\f114c";
}

.mdi-dice-4::before {
  content: "\f01cd";
}

.mdi-dice-4-outline::before {
  content: "\f114d";
}

.mdi-dice-5::before {
  content: "\f01ce";
}

.mdi-dice-5-outline::before {
  content: "\f114e";
}

.mdi-dice-6::before {
  content: "\f01cf";
}

.mdi-dice-6-outline::before {
  content: "\f114f";
}

.mdi-dice-d10::before {
  content: "\f1153";
}

.mdi-dice-d10-outline::before {
  content: "\f076f";
}

.mdi-dice-d12::before {
  content: "\f1154";
}

.mdi-dice-d12-outline::before {
  content: "\f0867";
}

.mdi-dice-d20::before {
  content: "\f1155";
}

.mdi-dice-d20-outline::before {
  content: "\f05ea";
}

.mdi-dice-d4::before {
  content: "\f1150";
}

.mdi-dice-d4-outline::before {
  content: "\f05eb";
}

.mdi-dice-d6::before {
  content: "\f1151";
}

.mdi-dice-d6-outline::before {
  content: "\f05ed";
}

.mdi-dice-d8::before {
  content: "\f1152";
}

.mdi-dice-d8-outline::before {
  content: "\f05ec";
}

.mdi-dice-multiple::before {
  content: "\f076e";
}

.mdi-dice-multiple-outline::before {
  content: "\f1156";
}

.mdi-digital-ocean::before {
  content: "\f1237";
}

.mdi-dip-switch::before {
  content: "\f07c1";
}

.mdi-directions::before {
  content: "\f01d0";
}

.mdi-directions-fork::before {
  content: "\f0641";
}

.mdi-disc::before {
  content: "\f05ee";
}

.mdi-disc-alert::before {
  content: "\f01d1";
}

.mdi-disc-player::before {
  content: "\f0960";
}

.mdi-discord::before {
  content: "\f066f";
}

.mdi-dishwasher::before {
  content: "\f0aac";
}

.mdi-dishwasher-alert::before {
  content: "\f11b8";
}

.mdi-dishwasher-off::before {
  content: "\f11b9";
}

.mdi-disqus::before {
  content: "\f01d2";
}

.mdi-distribute-horizontal-center::before {
  content: "\f11c9";
}

.mdi-distribute-horizontal-left::before {
  content: "\f11c8";
}

.mdi-distribute-horizontal-right::before {
  content: "\f11ca";
}

.mdi-distribute-vertical-bottom::before {
  content: "\f11cb";
}

.mdi-distribute-vertical-center::before {
  content: "\f11cc";
}

.mdi-distribute-vertical-top::before {
  content: "\f11cd";
}

.mdi-diving-flippers::before {
  content: "\f0dbf";
}

.mdi-diving-helmet::before {
  content: "\f0dc0";
}

.mdi-diving-scuba::before {
  content: "\f0dc1";
}

.mdi-diving-scuba-flag::before {
  content: "\f0dc2";
}

.mdi-diving-scuba-tank::before {
  content: "\f0dc3";
}

.mdi-diving-scuba-tank-multiple::before {
  content: "\f0dc4";
}

.mdi-diving-snorkel::before {
  content: "\f0dc5";
}

.mdi-division::before {
  content: "\f01d4";
}

.mdi-division-box::before {
  content: "\f01d5";
}

.mdi-dlna::before {
  content: "\f0a41";
}

.mdi-dna::before {
  content: "\f0684";
}

.mdi-dns::before {
  content: "\f01d6";
}

.mdi-dns-outline::before {
  content: "\f0b8c";
}

.mdi-do-not-disturb::before {
  content: "\f0698";
}

.mdi-do-not-disturb-off::before {
  content: "\f0699";
}

.mdi-dock-bottom::before {
  content: "\f10a9";
}

.mdi-dock-left::before {
  content: "\f10aa";
}

.mdi-dock-right::before {
  content: "\f10ab";
}

.mdi-dock-top::before {
  content: "\f1513";
}

.mdi-dock-window::before {
  content: "\f10ac";
}

.mdi-docker::before {
  content: "\f0868";
}

.mdi-doctor::before {
  content: "\f0a42";
}

.mdi-dog::before {
  content: "\f0a43";
}

.mdi-dog-service::before {
  content: "\f0aad";
}

.mdi-dog-side::before {
  content: "\f0a44";
}

.mdi-dog-side-off::before {
  content: "\f16ee";
}

.mdi-dolby::before {
  content: "\f06b3";
}

.mdi-dolly::before {
  content: "\f0e9e";
}

.mdi-domain::before {
  content: "\f01d7";
}

.mdi-domain-off::before {
  content: "\f0d6f";
}

.mdi-domain-plus::before {
  content: "\f10ad";
}

.mdi-domain-remove::before {
  content: "\f10ae";
}

.mdi-dome-light::before {
  content: "\f141e";
}

.mdi-domino-mask::before {
  content: "\f1023";
}

.mdi-donkey::before {
  content: "\f07c2";
}

.mdi-door::before {
  content: "\f081a";
}

.mdi-door-closed::before {
  content: "\f081b";
}

.mdi-door-closed-lock::before {
  content: "\f10af";
}

.mdi-door-open::before {
  content: "\f081c";
}

.mdi-doorbell::before {
  content: "\f12e6";
}

.mdi-doorbell-video::before {
  content: "\f0869";
}

.mdi-dot-net::before {
  content: "\f0aae";
}

.mdi-dots-grid::before {
  content: "\f15fc";
}

.mdi-dots-hexagon::before {
  content: "\f15ff";
}

.mdi-dots-horizontal::before {
  content: "\f01d8";
}

.mdi-dots-horizontal-circle::before {
  content: "\f07c3";
}

.mdi-dots-horizontal-circle-outline::before {
  content: "\f0b8d";
}

.mdi-dots-square::before {
  content: "\f15fd";
}

.mdi-dots-triangle::before {
  content: "\f15fe";
}

.mdi-dots-vertical::before {
  content: "\f01d9";
}

.mdi-dots-vertical-circle::before {
  content: "\f07c4";
}

.mdi-dots-vertical-circle-outline::before {
  content: "\f0b8e";
}

.mdi-douban::before {
  content: "\f069a";
}

.mdi-download::before {
  content: "\f01da";
}

.mdi-download-box::before {
  content: "\f1462";
}

.mdi-download-box-outline::before {
  content: "\f1463";
}

.mdi-download-circle::before {
  content: "\f1464";
}

.mdi-download-circle-outline::before {
  content: "\f1465";
}

.mdi-download-lock::before {
  content: "\f1320";
}

.mdi-download-lock-outline::before {
  content: "\f1321";
}

.mdi-download-multiple::before {
  content: "\f09e9";
}

.mdi-download-network::before {
  content: "\f06f4";
}

.mdi-download-network-outline::before {
  content: "\f0c66";
}

.mdi-download-off::before {
  content: "\f10b0";
}

.mdi-download-off-outline::before {
  content: "\f10b1";
}

.mdi-download-outline::before {
  content: "\f0b8f";
}

.mdi-drag::before {
  content: "\f01db";
}

.mdi-drag-horizontal::before {
  content: "\f01dc";
}

.mdi-drag-horizontal-variant::before {
  content: "\f12f0";
}

.mdi-drag-variant::before {
  content: "\f0b90";
}

.mdi-drag-vertical::before {
  content: "\f01dd";
}

.mdi-drag-vertical-variant::before {
  content: "\f12f1";
}

.mdi-drama-masks::before {
  content: "\f0d02";
}

.mdi-draw::before {
  content: "\f0f49";
}

.mdi-drawing::before {
  content: "\f01de";
}

.mdi-drawing-box::before {
  content: "\f01df";
}

.mdi-dresser::before {
  content: "\f0f4a";
}

.mdi-dresser-outline::before {
  content: "\f0f4b";
}

.mdi-drone::before {
  content: "\f01e2";
}

.mdi-dropbox::before {
  content: "\f01e3";
}

.mdi-drupal::before {
  content: "\f01e4";
}

.mdi-duck::before {
  content: "\f01e5";
}

.mdi-dumbbell::before {
  content: "\f01e6";
}

.mdi-dump-truck::before {
  content: "\f0c67";
}

.mdi-ear-hearing::before {
  content: "\f07c5";
}

.mdi-ear-hearing-off::before {
  content: "\f0a45";
}

.mdi-earth::before {
  content: "\f01e7";
}

.mdi-earth-arrow-right::before {
  content: "\f1311";
}

.mdi-earth-box::before {
  content: "\f06cd";
}

.mdi-earth-box-minus::before {
  content: "\f1407";
}

.mdi-earth-box-off::before {
  content: "\f06ce";
}

.mdi-earth-box-plus::before {
  content: "\f1406";
}

.mdi-earth-box-remove::before {
  content: "\f1408";
}

.mdi-earth-minus::before {
  content: "\f1404";
}

.mdi-earth-off::before {
  content: "\f01e8";
}

.mdi-earth-plus::before {
  content: "\f1403";
}

.mdi-earth-remove::before {
  content: "\f1405";
}

.mdi-egg::before {
  content: "\f0aaf";
}

.mdi-egg-easter::before {
  content: "\f0ab0";
}

.mdi-egg-off::before {
  content: "\f13f0";
}

.mdi-egg-off-outline::before {
  content: "\f13f1";
}

.mdi-egg-outline::before {
  content: "\f13f2";
}

.mdi-eiffel-tower::before {
  content: "\f156b";
}

.mdi-eight-track::before {
  content: "\f09ea";
}

.mdi-eject::before {
  content: "\f01ea";
}

.mdi-eject-outline::before {
  content: "\f0b91";
}

.mdi-electric-switch::before {
  content: "\f0e9f";
}

.mdi-electric-switch-closed::before {
  content: "\f10d9";
}

.mdi-electron-framework::before {
  content: "\f1024";
}

.mdi-elephant::before {
  content: "\f07c6";
}

.mdi-elevation-decline::before {
  content: "\f01eb";
}

.mdi-elevation-rise::before {
  content: "\f01ec";
}

.mdi-elevator::before {
  content: "\f01ed";
}

.mdi-elevator-down::before {
  content: "\f12c2";
}

.mdi-elevator-passenger::before {
  content: "\f1381";
}

.mdi-elevator-up::before {
  content: "\f12c1";
}

.mdi-ellipse::before {
  content: "\f0ea0";
}

.mdi-ellipse-outline::before {
  content: "\f0ea1";
}

.mdi-email::before {
  content: "\f01ee";
}

.mdi-email-alert::before {
  content: "\f06cf";
}

.mdi-email-alert-outline::before {
  content: "\f0d42";
}

.mdi-email-box::before {
  content: "\f0d03";
}

.mdi-email-check::before {
  content: "\f0ab1";
}

.mdi-email-check-outline::before {
  content: "\f0ab2";
}

.mdi-email-edit::before {
  content: "\f0ee3";
}

.mdi-email-edit-outline::before {
  content: "\f0ee4";
}

.mdi-email-lock::before {
  content: "\f01f1";
}

.mdi-email-mark-as-unread::before {
  content: "\f0b92";
}

.mdi-email-minus::before {
  content: "\f0ee5";
}

.mdi-email-minus-outline::before {
  content: "\f0ee6";
}

.mdi-email-multiple::before {
  content: "\f0ee7";
}

.mdi-email-multiple-outline::before {
  content: "\f0ee8";
}

.mdi-email-newsletter::before {
  content: "\f0fb1";
}

.mdi-email-off::before {
  content: "\f13e3";
}

.mdi-email-off-outline::before {
  content: "\f13e4";
}

.mdi-email-open::before {
  content: "\f01ef";
}

.mdi-email-open-multiple::before {
  content: "\f0ee9";
}

.mdi-email-open-multiple-outline::before {
  content: "\f0eea";
}

.mdi-email-open-outline::before {
  content: "\f05ef";
}

.mdi-email-outline::before {
  content: "\f01f0";
}

.mdi-email-plus::before {
  content: "\f09eb";
}

.mdi-email-plus-outline::before {
  content: "\f09ec";
}

.mdi-email-receive::before {
  content: "\f10da";
}

.mdi-email-receive-outline::before {
  content: "\f10db";
}

.mdi-email-remove::before {
  content: "\f1661";
}

.mdi-email-remove-outline::before {
  content: "\f1662";
}

.mdi-email-search::before {
  content: "\f0961";
}

.mdi-email-search-outline::before {
  content: "\f0962";
}

.mdi-email-send::before {
  content: "\f10dc";
}

.mdi-email-send-outline::before {
  content: "\f10dd";
}

.mdi-email-sync::before {
  content: "\f12c7";
}

.mdi-email-sync-outline::before {
  content: "\f12c8";
}

.mdi-email-variant::before {
  content: "\f05f0";
}

.mdi-ember::before {
  content: "\f0b30";
}

.mdi-emby::before {
  content: "\f06b4";
}

.mdi-emoticon::before {
  content: "\f0c68";
}

.mdi-emoticon-angry::before {
  content: "\f0c69";
}

.mdi-emoticon-angry-outline::before {
  content: "\f0c6a";
}

.mdi-emoticon-confused::before {
  content: "\f10de";
}

.mdi-emoticon-confused-outline::before {
  content: "\f10df";
}

.mdi-emoticon-cool::before {
  content: "\f0c6b";
}

.mdi-emoticon-cool-outline::before {
  content: "\f01f3";
}

.mdi-emoticon-cry::before {
  content: "\f0c6c";
}

.mdi-emoticon-cry-outline::before {
  content: "\f0c6d";
}

.mdi-emoticon-dead::before {
  content: "\f0c6e";
}

.mdi-emoticon-dead-outline::before {
  content: "\f069b";
}

.mdi-emoticon-devil::before {
  content: "\f0c6f";
}

.mdi-emoticon-devil-outline::before {
  content: "\f01f4";
}

.mdi-emoticon-excited::before {
  content: "\f0c70";
}

.mdi-emoticon-excited-outline::before {
  content: "\f069c";
}

.mdi-emoticon-frown::before {
  content: "\f0f4c";
}

.mdi-emoticon-frown-outline::before {
  content: "\f0f4d";
}

.mdi-emoticon-happy::before {
  content: "\f0c71";
}

.mdi-emoticon-happy-outline::before {
  content: "\f01f5";
}

.mdi-emoticon-kiss::before {
  content: "\f0c72";
}

.mdi-emoticon-kiss-outline::before {
  content: "\f0c73";
}

.mdi-emoticon-lol::before {
  content: "\f1214";
}

.mdi-emoticon-lol-outline::before {
  content: "\f1215";
}

.mdi-emoticon-neutral::before {
  content: "\f0c74";
}

.mdi-emoticon-neutral-outline::before {
  content: "\f01f6";
}

.mdi-emoticon-outline::before {
  content: "\f01f2";
}

.mdi-emoticon-poop::before {
  content: "\f01f7";
}

.mdi-emoticon-poop-outline::before {
  content: "\f0c75";
}

.mdi-emoticon-sad::before {
  content: "\f0c76";
}

.mdi-emoticon-sad-outline::before {
  content: "\f01f8";
}

.mdi-emoticon-sick::before {
  content: "\f157c";
}

.mdi-emoticon-sick-outline::before {
  content: "\f157d";
}

.mdi-emoticon-tongue::before {
  content: "\f01f9";
}

.mdi-emoticon-tongue-outline::before {
  content: "\f0c77";
}

.mdi-emoticon-wink::before {
  content: "\f0c78";
}

.mdi-emoticon-wink-outline::before {
  content: "\f0c79";
}

.mdi-engine::before {
  content: "\f01fa";
}

.mdi-engine-off::before {
  content: "\f0a46";
}

.mdi-engine-off-outline::before {
  content: "\f0a47";
}

.mdi-engine-outline::before {
  content: "\f01fb";
}

.mdi-epsilon::before {
  content: "\f10e0";
}

.mdi-equal::before {
  content: "\f01fc";
}

.mdi-equal-box::before {
  content: "\f01fd";
}

.mdi-equalizer::before {
  content: "\f0ea2";
}

.mdi-equalizer-outline::before {
  content: "\f0ea3";
}

.mdi-eraser::before {
  content: "\f01fe";
}

.mdi-eraser-variant::before {
  content: "\f0642";
}

.mdi-escalator::before {
  content: "\f01ff";
}

.mdi-escalator-box::before {
  content: "\f1399";
}

.mdi-escalator-down::before {
  content: "\f12c0";
}

.mdi-escalator-up::before {
  content: "\f12bf";
}

.mdi-eslint::before {
  content: "\f0c7a";
}

.mdi-et::before {
  content: "\f0ab3";
}

.mdi-ethereum::before {
  content: "\f086a";
}

.mdi-ethernet::before {
  content: "\f0200";
}

.mdi-ethernet-cable::before {
  content: "\f0201";
}

.mdi-ethernet-cable-off::before {
  content: "\f0202";
}

.mdi-ev-plug-ccs1::before {
  content: "\f1519";
}

.mdi-ev-plug-ccs2::before {
  content: "\f151a";
}

.mdi-ev-plug-chademo::before {
  content: "\f151b";
}

.mdi-ev-plug-tesla::before {
  content: "\f151c";
}

.mdi-ev-plug-type1::before {
  content: "\f151d";
}

.mdi-ev-plug-type2::before {
  content: "\f151e";
}

.mdi-ev-station::before {
  content: "\f05f1";
}

.mdi-evernote::before {
  content: "\f0204";
}

.mdi-excavator::before {
  content: "\f1025";
}

.mdi-exclamation::before {
  content: "\f0205";
}

.mdi-exclamation-thick::before {
  content: "\f1238";
}

.mdi-exit-run::before {
  content: "\f0a48";
}

.mdi-exit-to-app::before {
  content: "\f0206";
}

.mdi-expand-all::before {
  content: "\f0ab4";
}

.mdi-expand-all-outline::before {
  content: "\f0ab5";
}

.mdi-expansion-card::before {
  content: "\f08ae";
}

.mdi-expansion-card-variant::before {
  content: "\f0fb2";
}

.mdi-exponent::before {
  content: "\f0963";
}

.mdi-exponent-box::before {
  content: "\f0964";
}

.mdi-export::before {
  content: "\f0207";
}

.mdi-export-variant::before {
  content: "\f0b93";
}

.mdi-eye::before {
  content: "\f0208";
}

.mdi-eye-check::before {
  content: "\f0d04";
}

.mdi-eye-check-outline::before {
  content: "\f0d05";
}

.mdi-eye-circle::before {
  content: "\f0b94";
}

.mdi-eye-circle-outline::before {
  content: "\f0b95";
}

.mdi-eye-minus::before {
  content: "\f1026";
}

.mdi-eye-minus-outline::before {
  content: "\f1027";
}

.mdi-eye-off::before {
  content: "\f0209";
}

.mdi-eye-off-outline::before {
  content: "\f06d1";
}

.mdi-eye-outline::before {
  content: "\f06d0";
}

.mdi-eye-plus::before {
  content: "\f086b";
}

.mdi-eye-plus-outline::before {
  content: "\f086c";
}

.mdi-eye-remove::before {
  content: "\f15e3";
}

.mdi-eye-remove-outline::before {
  content: "\f15e4";
}

.mdi-eye-settings::before {
  content: "\f086d";
}

.mdi-eye-settings-outline::before {
  content: "\f086e";
}

.mdi-eyedropper::before {
  content: "\f020a";
}

.mdi-eyedropper-minus::before {
  content: "\f13dd";
}

.mdi-eyedropper-off::before {
  content: "\f13df";
}

.mdi-eyedropper-plus::before {
  content: "\f13dc";
}

.mdi-eyedropper-remove::before {
  content: "\f13de";
}

.mdi-eyedropper-variant::before {
  content: "\f020b";
}

.mdi-face::before {
  content: "\f0643";
}

.mdi-face-agent::before {
  content: "\f0d70";
}

.mdi-face-mask::before {
  content: "\f1586";
}

.mdi-face-mask-outline::before {
  content: "\f1587";
}

.mdi-face-outline::before {
  content: "\f0b96";
}

.mdi-face-profile::before {
  content: "\f0644";
}

.mdi-face-profile-woman::before {
  content: "\f1076";
}

.mdi-face-recognition::before {
  content: "\f0c7b";
}

.mdi-face-shimmer::before {
  content: "\f15cc";
}

.mdi-face-shimmer-outline::before {
  content: "\f15cd";
}

.mdi-face-woman::before {
  content: "\f1077";
}

.mdi-face-woman-outline::before {
  content: "\f1078";
}

.mdi-face-woman-shimmer::before {
  content: "\f15ce";
}

.mdi-face-woman-shimmer-outline::before {
  content: "\f15cf";
}

.mdi-facebook::before {
  content: "\f020c";
}

.mdi-facebook-gaming::before {
  content: "\f07dd";
}

.mdi-facebook-messenger::before {
  content: "\f020e";
}

.mdi-facebook-workplace::before {
  content: "\f0b31";
}

.mdi-factory::before {
  content: "\f020f";
}

.mdi-family-tree::before {
  content: "\f160e";
}

.mdi-fan::before {
  content: "\f0210";
}

.mdi-fan-alert::before {
  content: "\f146c";
}

.mdi-fan-auto::before {
  content: "\f171d";
}

.mdi-fan-chevron-down::before {
  content: "\f146d";
}

.mdi-fan-chevron-up::before {
  content: "\f146e";
}

.mdi-fan-minus::before {
  content: "\f1470";
}

.mdi-fan-off::before {
  content: "\f081d";
}

.mdi-fan-plus::before {
  content: "\f146f";
}

.mdi-fan-remove::before {
  content: "\f1471";
}

.mdi-fan-speed-1::before {
  content: "\f1472";
}

.mdi-fan-speed-2::before {
  content: "\f1473";
}

.mdi-fan-speed-3::before {
  content: "\f1474";
}

.mdi-fast-forward::before {
  content: "\f0211";
}

.mdi-fast-forward-10::before {
  content: "\f0d71";
}

.mdi-fast-forward-30::before {
  content: "\f0d06";
}

.mdi-fast-forward-5::before {
  content: "\f11f8";
}

.mdi-fast-forward-60::before {
  content: "\f160b";
}

.mdi-fast-forward-outline::before {
  content: "\f06d2";
}

.mdi-fax::before {
  content: "\f0212";
}

.mdi-feather::before {
  content: "\f06d3";
}

.mdi-feature-search::before {
  content: "\f0a49";
}

.mdi-feature-search-outline::before {
  content: "\f0a4a";
}

.mdi-fedora::before {
  content: "\f08db";
}

.mdi-fencing::before {
  content: "\f14c1";
}

.mdi-ferris-wheel::before {
  content: "\f0ea4";
}

.mdi-ferry::before {
  content: "\f0213";
}

.mdi-file::before {
  content: "\f0214";
}

.mdi-file-account::before {
  content: "\f073b";
}

.mdi-file-account-outline::before {
  content: "\f1028";
}

.mdi-file-alert::before {
  content: "\f0a4b";
}

.mdi-file-alert-outline::before {
  content: "\f0a4c";
}

.mdi-file-cabinet::before {
  content: "\f0ab6";
}

.mdi-file-cad::before {
  content: "\f0eeb";
}

.mdi-file-cad-box::before {
  content: "\f0eec";
}

.mdi-file-cancel::before {
  content: "\f0dc6";
}

.mdi-file-cancel-outline::before {
  content: "\f0dc7";
}

.mdi-file-certificate::before {
  content: "\f1186";
}

.mdi-file-certificate-outline::before {
  content: "\f1187";
}

.mdi-file-chart::before {
  content: "\f0215";
}

.mdi-file-chart-outline::before {
  content: "\f1029";
}

.mdi-file-check::before {
  content: "\f0216";
}

.mdi-file-check-outline::before {
  content: "\f0e29";
}

.mdi-file-clock::before {
  content: "\f12e1";
}

.mdi-file-clock-outline::before {
  content: "\f12e2";
}

.mdi-file-cloud::before {
  content: "\f0217";
}

.mdi-file-cloud-outline::before {
  content: "\f102a";
}

.mdi-file-code::before {
  content: "\f022e";
}

.mdi-file-code-outline::before {
  content: "\f102b";
}

.mdi-file-cog::before {
  content: "\f107b";
}

.mdi-file-cog-outline::before {
  content: "\f107c";
}

.mdi-file-compare::before {
  content: "\f08aa";
}

.mdi-file-delimited::before {
  content: "\f0218";
}

.mdi-file-delimited-outline::before {
  content: "\f0ea5";
}

.mdi-file-document::before {
  content: "\f0219";
}

.mdi-file-document-edit::before {
  content: "\f0dc8";
}

.mdi-file-document-edit-outline::before {
  content: "\f0dc9";
}

.mdi-file-document-multiple::before {
  content: "\f1517";
}

.mdi-file-document-multiple-outline::before {
  content: "\f1518";
}

.mdi-file-document-outline::before {
  content: "\f09ee";
}

.mdi-file-download::before {
  content: "\f0965";
}

.mdi-file-download-outline::before {
  content: "\f0966";
}

.mdi-file-edit::before {
  content: "\f11e7";
}

.mdi-file-edit-outline::before {
  content: "\f11e8";
}

.mdi-file-excel::before {
  content: "\f021b";
}

.mdi-file-excel-box::before {
  content: "\f021c";
}

.mdi-file-excel-box-outline::before {
  content: "\f102c";
}

.mdi-file-excel-outline::before {
  content: "\f102d";
}

.mdi-file-export::before {
  content: "\f021d";
}

.mdi-file-export-outline::before {
  content: "\f102e";
}

.mdi-file-eye::before {
  content: "\f0dca";
}

.mdi-file-eye-outline::before {
  content: "\f0dcb";
}

.mdi-file-find::before {
  content: "\f021e";
}

.mdi-file-find-outline::before {
  content: "\f0b97";
}

.mdi-file-hidden::before {
  content: "\f0613";
}

.mdi-file-image::before {
  content: "\f021f";
}

.mdi-file-image-outline::before {
  content: "\f0eb0";
}

.mdi-file-import::before {
  content: "\f0220";
}

.mdi-file-import-outline::before {
  content: "\f102f";
}

.mdi-file-key::before {
  content: "\f1184";
}

.mdi-file-key-outline::before {
  content: "\f1185";
}

.mdi-file-link::before {
  content: "\f1177";
}

.mdi-file-link-outline::before {
  content: "\f1178";
}

.mdi-file-lock::before {
  content: "\f0221";
}

.mdi-file-lock-outline::before {
  content: "\f1030";
}

.mdi-file-move::before {
  content: "\f0ab9";
}

.mdi-file-move-outline::before {
  content: "\f1031";
}

.mdi-file-multiple::before {
  content: "\f0222";
}

.mdi-file-multiple-outline::before {
  content: "\f1032";
}

.mdi-file-music::before {
  content: "\f0223";
}

.mdi-file-music-outline::before {
  content: "\f0e2a";
}

.mdi-file-outline::before {
  content: "\f0224";
}

.mdi-file-pdf::before {
  content: "\f0225";
}

.mdi-file-pdf-box::before {
  content: "\f0226";
}

.mdi-file-pdf-box-outline::before {
  content: "\f0fb3";
}

.mdi-file-pdf-outline::before {
  content: "\f0e2d";
}

.mdi-file-percent::before {
  content: "\f081e";
}

.mdi-file-percent-outline::before {
  content: "\f1033";
}

.mdi-file-phone::before {
  content: "\f1179";
}

.mdi-file-phone-outline::before {
  content: "\f117a";
}

.mdi-file-plus::before {
  content: "\f0752";
}

.mdi-file-plus-outline::before {
  content: "\f0eed";
}

.mdi-file-powerpoint::before {
  content: "\f0227";
}

.mdi-file-powerpoint-box::before {
  content: "\f0228";
}

.mdi-file-powerpoint-box-outline::before {
  content: "\f1034";
}

.mdi-file-powerpoint-outline::before {
  content: "\f1035";
}

.mdi-file-presentation-box::before {
  content: "\f0229";
}

.mdi-file-question::before {
  content: "\f086f";
}

.mdi-file-question-outline::before {
  content: "\f1036";
}

.mdi-file-refresh::before {
  content: "\f0918";
}

.mdi-file-refresh-outline::before {
  content: "\f0541";
}

.mdi-file-remove::before {
  content: "\f0b98";
}

.mdi-file-remove-outline::before {
  content: "\f1037";
}

.mdi-file-replace::before {
  content: "\f0b32";
}

.mdi-file-replace-outline::before {
  content: "\f0b33";
}

.mdi-file-restore::before {
  content: "\f0670";
}

.mdi-file-restore-outline::before {
  content: "\f1038";
}

.mdi-file-search::before {
  content: "\f0c7c";
}

.mdi-file-search-outline::before {
  content: "\f0c7d";
}

.mdi-file-send::before {
  content: "\f022a";
}

.mdi-file-send-outline::before {
  content: "\f1039";
}

.mdi-file-settings::before {
  content: "\f1079";
}

.mdi-file-settings-outline::before {
  content: "\f107a";
}

.mdi-file-star::before {
  content: "\f103a";
}

.mdi-file-star-outline::before {
  content: "\f103b";
}

.mdi-file-swap::before {
  content: "\f0fb4";
}

.mdi-file-swap-outline::before {
  content: "\f0fb5";
}

.mdi-file-sync::before {
  content: "\f1216";
}

.mdi-file-sync-outline::before {
  content: "\f1217";
}

.mdi-file-table::before {
  content: "\f0c7e";
}

.mdi-file-table-box::before {
  content: "\f10e1";
}

.mdi-file-table-box-multiple::before {
  content: "\f10e2";
}

.mdi-file-table-box-multiple-outline::before {
  content: "\f10e3";
}

.mdi-file-table-box-outline::before {
  content: "\f10e4";
}

.mdi-file-table-outline::before {
  content: "\f0c7f";
}

.mdi-file-tree::before {
  content: "\f0645";
}

.mdi-file-tree-outline::before {
  content: "\f13d2";
}

.mdi-file-undo::before {
  content: "\f08dc";
}

.mdi-file-undo-outline::before {
  content: "\f103c";
}

.mdi-file-upload::before {
  content: "\f0a4d";
}

.mdi-file-upload-outline::before {
  content: "\f0a4e";
}

.mdi-file-video::before {
  content: "\f022b";
}

.mdi-file-video-outline::before {
  content: "\f0e2c";
}

.mdi-file-word::before {
  content: "\f022c";
}

.mdi-file-word-box::before {
  content: "\f022d";
}

.mdi-file-word-box-outline::before {
  content: "\f103d";
}

.mdi-file-word-outline::before {
  content: "\f103e";
}

.mdi-film::before {
  content: "\f022f";
}

.mdi-filmstrip::before {
  content: "\f0230";
}

.mdi-filmstrip-box::before {
  content: "\f0332";
}

.mdi-filmstrip-box-multiple::before {
  content: "\f0d18";
}

.mdi-filmstrip-off::before {
  content: "\f0231";
}

.mdi-filter::before {
  content: "\f0232";
}

.mdi-filter-menu::before {
  content: "\f10e5";
}

.mdi-filter-menu-outline::before {
  content: "\f10e6";
}

.mdi-filter-minus::before {
  content: "\f0eee";
}

.mdi-filter-minus-outline::before {
  content: "\f0eef";
}

.mdi-filter-off::before {
  content: "\f14ef";
}

.mdi-filter-off-outline::before {
  content: "\f14f0";
}

.mdi-filter-outline::before {
  content: "\f0233";
}

.mdi-filter-plus::before {
  content: "\f0ef0";
}

.mdi-filter-plus-outline::before {
  content: "\f0ef1";
}

.mdi-filter-remove::before {
  content: "\f0234";
}

.mdi-filter-remove-outline::before {
  content: "\f0235";
}

.mdi-filter-variant::before {
  content: "\f0236";
}

.mdi-filter-variant-minus::before {
  content: "\f1112";
}

.mdi-filter-variant-plus::before {
  content: "\f1113";
}

.mdi-filter-variant-remove::before {
  content: "\f103f";
}

.mdi-finance::before {
  content: "\f081f";
}

.mdi-find-replace::before {
  content: "\f06d4";
}

.mdi-fingerprint::before {
  content: "\f0237";
}

.mdi-fingerprint-off::before {
  content: "\f0eb1";
}

.mdi-fire::before {
  content: "\f0238";
}

.mdi-fire-alert::before {
  content: "\f15d7";
}

.mdi-fire-extinguisher::before {
  content: "\f0ef2";
}

.mdi-fire-hydrant::before {
  content: "\f1137";
}

.mdi-fire-hydrant-alert::before {
  content: "\f1138";
}

.mdi-fire-hydrant-off::before {
  content: "\f1139";
}

.mdi-fire-off::before {
  content: "\f1722";
}

.mdi-fire-truck::before {
  content: "\f08ab";
}

.mdi-firebase::before {
  content: "\f0967";
}

.mdi-firefox::before {
  content: "\f0239";
}

.mdi-fireplace::before {
  content: "\f0e2e";
}

.mdi-fireplace-off::before {
  content: "\f0e2f";
}

.mdi-firework::before {
  content: "\f0e30";
}

.mdi-firework-off::before {
  content: "\f1723";
}

.mdi-fish::before {
  content: "\f023a";
}

.mdi-fish-off::before {
  content: "\f13f3";
}

.mdi-fishbowl::before {
  content: "\f0ef3";
}

.mdi-fishbowl-outline::before {
  content: "\f0ef4";
}

.mdi-fit-to-page::before {
  content: "\f0ef5";
}

.mdi-fit-to-page-outline::before {
  content: "\f0ef6";
}

.mdi-flag::before {
  content: "\f023b";
}

.mdi-flag-checkered::before {
  content: "\f023c";
}

.mdi-flag-minus::before {
  content: "\f0b99";
}

.mdi-flag-minus-outline::before {
  content: "\f10b2";
}

.mdi-flag-outline::before {
  content: "\f023d";
}

.mdi-flag-plus::before {
  content: "\f0b9a";
}

.mdi-flag-plus-outline::before {
  content: "\f10b3";
}

.mdi-flag-remove::before {
  content: "\f0b9b";
}

.mdi-flag-remove-outline::before {
  content: "\f10b4";
}

.mdi-flag-triangle::before {
  content: "\f023f";
}

.mdi-flag-variant::before {
  content: "\f0240";
}

.mdi-flag-variant-outline::before {
  content: "\f023e";
}

.mdi-flare::before {
  content: "\f0d72";
}

.mdi-flash::before {
  content: "\f0241";
}

.mdi-flash-alert::before {
  content: "\f0ef7";
}

.mdi-flash-alert-outline::before {
  content: "\f0ef8";
}

.mdi-flash-auto::before {
  content: "\f0242";
}

.mdi-flash-circle::before {
  content: "\f0820";
}

.mdi-flash-off::before {
  content: "\f0243";
}

.mdi-flash-outline::before {
  content: "\f06d5";
}

.mdi-flash-red-eye::before {
  content: "\f067b";
}

.mdi-flashlight::before {
  content: "\f0244";
}

.mdi-flashlight-off::before {
  content: "\f0245";
}

.mdi-flask::before {
  content: "\f0093";
}

.mdi-flask-empty::before {
  content: "\f0094";
}

.mdi-flask-empty-minus::before {
  content: "\f123a";
}

.mdi-flask-empty-minus-outline::before {
  content: "\f123b";
}

.mdi-flask-empty-off::before {
  content: "\f13f4";
}

.mdi-flask-empty-off-outline::before {
  content: "\f13f5";
}

.mdi-flask-empty-outline::before {
  content: "\f0095";
}

.mdi-flask-empty-plus::before {
  content: "\f123c";
}

.mdi-flask-empty-plus-outline::before {
  content: "\f123d";
}

.mdi-flask-empty-remove::before {
  content: "\f123e";
}

.mdi-flask-empty-remove-outline::before {
  content: "\f123f";
}

.mdi-flask-minus::before {
  content: "\f1240";
}

.mdi-flask-minus-outline::before {
  content: "\f1241";
}

.mdi-flask-off::before {
  content: "\f13f6";
}

.mdi-flask-off-outline::before {
  content: "\f13f7";
}

.mdi-flask-outline::before {
  content: "\f0096";
}

.mdi-flask-plus::before {
  content: "\f1242";
}

.mdi-flask-plus-outline::before {
  content: "\f1243";
}

.mdi-flask-remove::before {
  content: "\f1244";
}

.mdi-flask-remove-outline::before {
  content: "\f1245";
}

.mdi-flask-round-bottom::before {
  content: "\f124b";
}

.mdi-flask-round-bottom-empty::before {
  content: "\f124c";
}

.mdi-flask-round-bottom-empty-outline::before {
  content: "\f124d";
}

.mdi-flask-round-bottom-outline::before {
  content: "\f124e";
}

.mdi-fleur-de-lis::before {
  content: "\f1303";
}

.mdi-flip-horizontal::before {
  content: "\f10e7";
}

.mdi-flip-to-back::before {
  content: "\f0247";
}

.mdi-flip-to-front::before {
  content: "\f0248";
}

.mdi-flip-vertical::before {
  content: "\f10e8";
}

.mdi-floor-lamp::before {
  content: "\f08dd";
}

.mdi-floor-lamp-dual::before {
  content: "\f1040";
}

.mdi-floor-lamp-variant::before {
  content: "\f1041";
}

.mdi-floor-plan::before {
  content: "\f0821";
}

.mdi-floppy::before {
  content: "\f0249";
}

.mdi-floppy-variant::before {
  content: "\f09ef";
}

.mdi-flower::before {
  content: "\f024a";
}

.mdi-flower-outline::before {
  content: "\f09f0";
}

.mdi-flower-poppy::before {
  content: "\f0d08";
}

.mdi-flower-tulip::before {
  content: "\f09f1";
}

.mdi-flower-tulip-outline::before {
  content: "\f09f2";
}

.mdi-focus-auto::before {
  content: "\f0f4e";
}

.mdi-focus-field::before {
  content: "\f0f4f";
}

.mdi-focus-field-horizontal::before {
  content: "\f0f50";
}

.mdi-focus-field-vertical::before {
  content: "\f0f51";
}

.mdi-folder::before {
  content: "\f024b";
}

.mdi-folder-account::before {
  content: "\f024c";
}

.mdi-folder-account-outline::before {
  content: "\f0b9c";
}

.mdi-folder-alert::before {
  content: "\f0dcc";
}

.mdi-folder-alert-outline::before {
  content: "\f0dcd";
}

.mdi-folder-clock::before {
  content: "\f0aba";
}

.mdi-folder-clock-outline::before {
  content: "\f0abb";
}

.mdi-folder-cog::before {
  content: "\f107f";
}

.mdi-folder-cog-outline::before {
  content: "\f1080";
}

.mdi-folder-download::before {
  content: "\f024d";
}

.mdi-folder-download-outline::before {
  content: "\f10e9";
}

.mdi-folder-edit::before {
  content: "\f08de";
}

.mdi-folder-edit-outline::before {
  content: "\f0dce";
}

.mdi-folder-google-drive::before {
  content: "\f024e";
}

.mdi-folder-heart::before {
  content: "\f10ea";
}

.mdi-folder-heart-outline::before {
  content: "\f10eb";
}

.mdi-folder-home::before {
  content: "\f10b5";
}

.mdi-folder-home-outline::before {
  content: "\f10b6";
}

.mdi-folder-image::before {
  content: "\f024f";
}

.mdi-folder-information::before {
  content: "\f10b7";
}

.mdi-folder-information-outline::before {
  content: "\f10b8";
}

.mdi-folder-key::before {
  content: "\f08ac";
}

.mdi-folder-key-network::before {
  content: "\f08ad";
}

.mdi-folder-key-network-outline::before {
  content: "\f0c80";
}

.mdi-folder-key-outline::before {
  content: "\f10ec";
}

.mdi-folder-lock::before {
  content: "\f0250";
}

.mdi-folder-lock-open::before {
  content: "\f0251";
}

.mdi-folder-marker::before {
  content: "\f126d";
}

.mdi-folder-marker-outline::before {
  content: "\f126e";
}

.mdi-folder-move::before {
  content: "\f0252";
}

.mdi-folder-move-outline::before {
  content: "\f1246";
}

.mdi-folder-multiple::before {
  content: "\f0253";
}

.mdi-folder-multiple-image::before {
  content: "\f0254";
}

.mdi-folder-multiple-outline::before {
  content: "\f0255";
}

.mdi-folder-multiple-plus::before {
  content: "\f147e";
}

.mdi-folder-multiple-plus-outline::before {
  content: "\f147f";
}

.mdi-folder-music::before {
  content: "\f1359";
}

.mdi-folder-music-outline::before {
  content: "\f135a";
}

.mdi-folder-network::before {
  content: "\f0870";
}

.mdi-folder-network-outline::before {
  content: "\f0c81";
}

.mdi-folder-open::before {
  content: "\f0770";
}

.mdi-folder-open-outline::before {
  content: "\f0dcf";
}

.mdi-folder-outline::before {
  content: "\f0256";
}

.mdi-folder-plus::before {
  content: "\f0257";
}

.mdi-folder-plus-outline::before {
  content: "\f0b9d";
}

.mdi-folder-pound::before {
  content: "\f0d09";
}

.mdi-folder-pound-outline::before {
  content: "\f0d0a";
}

.mdi-folder-refresh::before {
  content: "\f0749";
}

.mdi-folder-refresh-outline::before {
  content: "\f0542";
}

.mdi-folder-remove::before {
  content: "\f0258";
}

.mdi-folder-remove-outline::before {
  content: "\f0b9e";
}

.mdi-folder-search::before {
  content: "\f0968";
}

.mdi-folder-search-outline::before {
  content: "\f0969";
}

.mdi-folder-settings::before {
  content: "\f107d";
}

.mdi-folder-settings-outline::before {
  content: "\f107e";
}

.mdi-folder-star::before {
  content: "\f069d";
}

.mdi-folder-star-multiple::before {
  content: "\f13d3";
}

.mdi-folder-star-multiple-outline::before {
  content: "\f13d4";
}

.mdi-folder-star-outline::before {
  content: "\f0b9f";
}

.mdi-folder-swap::before {
  content: "\f0fb6";
}

.mdi-folder-swap-outline::before {
  content: "\f0fb7";
}

.mdi-folder-sync::before {
  content: "\f0d0b";
}

.mdi-folder-sync-outline::before {
  content: "\f0d0c";
}

.mdi-folder-table::before {
  content: "\f12e3";
}

.mdi-folder-table-outline::before {
  content: "\f12e4";
}

.mdi-folder-text::before {
  content: "\f0c82";
}

.mdi-folder-text-outline::before {
  content: "\f0c83";
}

.mdi-folder-upload::before {
  content: "\f0259";
}

.mdi-folder-upload-outline::before {
  content: "\f10ed";
}

.mdi-folder-zip::before {
  content: "\f06eb";
}

.mdi-folder-zip-outline::before {
  content: "\f07b9";
}

.mdi-font-awesome::before {
  content: "\f003a";
}

.mdi-food::before {
  content: "\f025a";
}

.mdi-food-apple::before {
  content: "\f025b";
}

.mdi-food-apple-outline::before {
  content: "\f0c84";
}

.mdi-food-croissant::before {
  content: "\f07c8";
}

.mdi-food-drumstick::before {
  content: "\f141f";
}

.mdi-food-drumstick-off::before {
  content: "\f1468";
}

.mdi-food-drumstick-off-outline::before {
  content: "\f1469";
}

.mdi-food-drumstick-outline::before {
  content: "\f1420";
}

.mdi-food-fork-drink::before {
  content: "\f05f2";
}

.mdi-food-halal::before {
  content: "\f1572";
}

.mdi-food-kosher::before {
  content: "\f1573";
}

.mdi-food-off::before {
  content: "\f05f3";
}

.mdi-food-steak::before {
  content: "\f146a";
}

.mdi-food-steak-off::before {
  content: "\f146b";
}

.mdi-food-turkey::before {
  content: "\f171c";
}

.mdi-food-variant::before {
  content: "\f025c";
}

.mdi-food-variant-off::before {
  content: "\f13e5";
}

.mdi-foot-print::before {
  content: "\f0f52";
}

.mdi-football::before {
  content: "\f025d";
}

.mdi-football-australian::before {
  content: "\f025e";
}

.mdi-football-helmet::before {
  content: "\f025f";
}

.mdi-forklift::before {
  content: "\f07c9";
}

.mdi-form-dropdown::before {
  content: "\f1400";
}

.mdi-form-select::before {
  content: "\f1401";
}

.mdi-form-textarea::before {
  content: "\f1095";
}

.mdi-form-textbox::before {
  content: "\f060e";
}

.mdi-form-textbox-lock::before {
  content: "\f135d";
}

.mdi-form-textbox-password::before {
  content: "\f07f5";
}

.mdi-format-align-bottom::before {
  content: "\f0753";
}

.mdi-format-align-center::before {
  content: "\f0260";
}

.mdi-format-align-justify::before {
  content: "\f0261";
}

.mdi-format-align-left::before {
  content: "\f0262";
}

.mdi-format-align-middle::before {
  content: "\f0754";
}

.mdi-format-align-right::before {
  content: "\f0263";
}

.mdi-format-align-top::before {
  content: "\f0755";
}

.mdi-format-annotation-minus::before {
  content: "\f0abc";
}

.mdi-format-annotation-plus::before {
  content: "\f0646";
}

.mdi-format-bold::before {
  content: "\f0264";
}

.mdi-format-clear::before {
  content: "\f0265";
}

.mdi-format-color-fill::before {
  content: "\f0266";
}

.mdi-format-color-highlight::before {
  content: "\f0e31";
}

.mdi-format-color-marker-cancel::before {
  content: "\f1313";
}

.mdi-format-color-text::before {
  content: "\f069e";
}

.mdi-format-columns::before {
  content: "\f08df";
}

.mdi-format-float-center::before {
  content: "\f0267";
}

.mdi-format-float-left::before {
  content: "\f0268";
}

.mdi-format-float-none::before {
  content: "\f0269";
}

.mdi-format-float-right::before {
  content: "\f026a";
}

.mdi-format-font::before {
  content: "\f06d6";
}

.mdi-format-font-size-decrease::before {
  content: "\f09f3";
}

.mdi-format-font-size-increase::before {
  content: "\f09f4";
}

.mdi-format-header-1::before {
  content: "\f026b";
}

.mdi-format-header-2::before {
  content: "\f026c";
}

.mdi-format-header-3::before {
  content: "\f026d";
}

.mdi-format-header-4::before {
  content: "\f026e";
}

.mdi-format-header-5::before {
  content: "\f026f";
}

.mdi-format-header-6::before {
  content: "\f0270";
}

.mdi-format-header-decrease::before {
  content: "\f0271";
}

.mdi-format-header-equal::before {
  content: "\f0272";
}

.mdi-format-header-increase::before {
  content: "\f0273";
}

.mdi-format-header-pound::before {
  content: "\f0274";
}

.mdi-format-horizontal-align-center::before {
  content: "\f061e";
}

.mdi-format-horizontal-align-left::before {
  content: "\f061f";
}

.mdi-format-horizontal-align-right::before {
  content: "\f0620";
}

.mdi-format-indent-decrease::before {
  content: "\f0275";
}

.mdi-format-indent-increase::before {
  content: "\f0276";
}

.mdi-format-italic::before {
  content: "\f0277";
}

.mdi-format-letter-case::before {
  content: "\f0b34";
}

.mdi-format-letter-case-lower::before {
  content: "\f0b35";
}

.mdi-format-letter-case-upper::before {
  content: "\f0b36";
}

.mdi-format-letter-ends-with::before {
  content: "\f0fb8";
}

.mdi-format-letter-matches::before {
  content: "\f0fb9";
}

.mdi-format-letter-starts-with::before {
  content: "\f0fba";
}

.mdi-format-line-spacing::before {
  content: "\f0278";
}

.mdi-format-line-style::before {
  content: "\f05c8";
}

.mdi-format-line-weight::before {
  content: "\f05c9";
}

.mdi-format-list-bulleted::before {
  content: "\f0279";
}

.mdi-format-list-bulleted-square::before {
  content: "\f0dd0";
}

.mdi-format-list-bulleted-triangle::before {
  content: "\f0eb2";
}

.mdi-format-list-bulleted-type::before {
  content: "\f027a";
}

.mdi-format-list-checkbox::before {
  content: "\f096a";
}

.mdi-format-list-checks::before {
  content: "\f0756";
}

.mdi-format-list-numbered::before {
  content: "\f027b";
}

.mdi-format-list-numbered-rtl::before {
  content: "\f0d0d";
}

.mdi-format-list-text::before {
  content: "\f126f";
}

.mdi-format-overline::before {
  content: "\f0eb3";
}

.mdi-format-page-break::before {
  content: "\f06d7";
}

.mdi-format-paint::before {
  content: "\f027c";
}

.mdi-format-paragraph::before {
  content: "\f027d";
}

.mdi-format-pilcrow::before {
  content: "\f06d8";
}

.mdi-format-quote-close::before {
  content: "\f027e";
}

.mdi-format-quote-close-outline::before {
  content: "\f11a8";
}

.mdi-format-quote-open::before {
  content: "\f0757";
}

.mdi-format-quote-open-outline::before {
  content: "\f11a7";
}

.mdi-format-rotate-90::before {
  content: "\f06aa";
}

.mdi-format-section::before {
  content: "\f069f";
}

.mdi-format-size::before {
  content: "\f027f";
}

.mdi-format-strikethrough::before {
  content: "\f0280";
}

.mdi-format-strikethrough-variant::before {
  content: "\f0281";
}

.mdi-format-subscript::before {
  content: "\f0282";
}

.mdi-format-superscript::before {
  content: "\f0283";
}

.mdi-format-text::before {
  content: "\f0284";
}

.mdi-format-text-rotation-angle-down::before {
  content: "\f0fbb";
}

.mdi-format-text-rotation-angle-up::before {
  content: "\f0fbc";
}

.mdi-format-text-rotation-down::before {
  content: "\f0d73";
}

.mdi-format-text-rotation-down-vertical::before {
  content: "\f0fbd";
}

.mdi-format-text-rotation-none::before {
  content: "\f0d74";
}

.mdi-format-text-rotation-up::before {
  content: "\f0fbe";
}

.mdi-format-text-rotation-vertical::before {
  content: "\f0fbf";
}

.mdi-format-text-variant::before {
  content: "\f0e32";
}

.mdi-format-text-variant-outline::before {
  content: "\f150f";
}

.mdi-format-text-wrapping-clip::before {
  content: "\f0d0e";
}

.mdi-format-text-wrapping-overflow::before {
  content: "\f0d0f";
}

.mdi-format-text-wrapping-wrap::before {
  content: "\f0d10";
}

.mdi-format-textbox::before {
  content: "\f0d11";
}

.mdi-format-textdirection-l-to-r::before {
  content: "\f0285";
}

.mdi-format-textdirection-r-to-l::before {
  content: "\f0286";
}

.mdi-format-title::before {
  content: "\f05f4";
}

.mdi-format-underline::before {
  content: "\f0287";
}

.mdi-format-vertical-align-bottom::before {
  content: "\f0621";
}

.mdi-format-vertical-align-center::before {
  content: "\f0622";
}

.mdi-format-vertical-align-top::before {
  content: "\f0623";
}

.mdi-format-wrap-inline::before {
  content: "\f0288";
}

.mdi-format-wrap-square::before {
  content: "\f0289";
}

.mdi-format-wrap-tight::before {
  content: "\f028a";
}

.mdi-format-wrap-top-bottom::before {
  content: "\f028b";
}

.mdi-forum::before {
  content: "\f028c";
}

.mdi-forum-outline::before {
  content: "\f0822";
}

.mdi-forward::before {
  content: "\f028d";
}

.mdi-forwardburger::before {
  content: "\f0d75";
}

.mdi-fountain::before {
  content: "\f096b";
}

.mdi-fountain-pen::before {
  content: "\f0d12";
}

.mdi-fountain-pen-tip::before {
  content: "\f0d13";
}

.mdi-freebsd::before {
  content: "\f08e0";
}

.mdi-frequently-asked-questions::before {
  content: "\f0eb4";
}

.mdi-fridge::before {
  content: "\f0290";
}

.mdi-fridge-alert::before {
  content: "\f11b1";
}

.mdi-fridge-alert-outline::before {
  content: "\f11b2";
}

.mdi-fridge-bottom::before {
  content: "\f0292";
}

.mdi-fridge-industrial::before {
  content: "\f15ee";
}

.mdi-fridge-industrial-alert::before {
  content: "\f15ef";
}

.mdi-fridge-industrial-alert-outline::before {
  content: "\f15f0";
}

.mdi-fridge-industrial-off::before {
  content: "\f15f1";
}

.mdi-fridge-industrial-off-outline::before {
  content: "\f15f2";
}

.mdi-fridge-industrial-outline::before {
  content: "\f15f3";
}

.mdi-fridge-off::before {
  content: "\f11af";
}

.mdi-fridge-off-outline::before {
  content: "\f11b0";
}

.mdi-fridge-outline::before {
  content: "\f028f";
}

.mdi-fridge-top::before {
  content: "\f0291";
}

.mdi-fridge-variant::before {
  content: "\f15f4";
}

.mdi-fridge-variant-alert::before {
  content: "\f15f5";
}

.mdi-fridge-variant-alert-outline::before {
  content: "\f15f6";
}

.mdi-fridge-variant-off::before {
  content: "\f15f7";
}

.mdi-fridge-variant-off-outline::before {
  content: "\f15f8";
}

.mdi-fridge-variant-outline::before {
  content: "\f15f9";
}

.mdi-fruit-cherries::before {
  content: "\f1042";
}

.mdi-fruit-cherries-off::before {
  content: "\f13f8";
}

.mdi-fruit-citrus::before {
  content: "\f1043";
}

.mdi-fruit-citrus-off::before {
  content: "\f13f9";
}

.mdi-fruit-grapes::before {
  content: "\f1044";
}

.mdi-fruit-grapes-outline::before {
  content: "\f1045";
}

.mdi-fruit-pineapple::before {
  content: "\f1046";
}

.mdi-fruit-watermelon::before {
  content: "\f1047";
}

.mdi-fuel::before {
  content: "\f07ca";
}

.mdi-fullscreen::before {
  content: "\f0293";
}

.mdi-fullscreen-exit::before {
  content: "\f0294";
}

.mdi-function::before {
  content: "\f0295";
}

.mdi-function-variant::before {
  content: "\f0871";
}

.mdi-furigana-horizontal::before {
  content: "\f1081";
}

.mdi-furigana-vertical::before {
  content: "\f1082";
}

.mdi-fuse::before {
  content: "\f0c85";
}

.mdi-fuse-alert::before {
  content: "\f142d";
}

.mdi-fuse-blade::before {
  content: "\f0c86";
}

.mdi-fuse-off::before {
  content: "\f142c";
}

.mdi-gamepad::before {
  content: "\f0296";
}

.mdi-gamepad-circle::before {
  content: "\f0e33";
}

.mdi-gamepad-circle-down::before {
  content: "\f0e34";
}

.mdi-gamepad-circle-left::before {
  content: "\f0e35";
}

.mdi-gamepad-circle-outline::before {
  content: "\f0e36";
}

.mdi-gamepad-circle-right::before {
  content: "\f0e37";
}

.mdi-gamepad-circle-up::before {
  content: "\f0e38";
}

.mdi-gamepad-down::before {
  content: "\f0e39";
}

.mdi-gamepad-left::before {
  content: "\f0e3a";
}

.mdi-gamepad-right::before {
  content: "\f0e3b";
}

.mdi-gamepad-round::before {
  content: "\f0e3c";
}

.mdi-gamepad-round-down::before {
  content: "\f0e3d";
}

.mdi-gamepad-round-left::before {
  content: "\f0e3e";
}

.mdi-gamepad-round-outline::before {
  content: "\f0e3f";
}

.mdi-gamepad-round-right::before {
  content: "\f0e40";
}

.mdi-gamepad-round-up::before {
  content: "\f0e41";
}

.mdi-gamepad-square::before {
  content: "\f0eb5";
}

.mdi-gamepad-square-outline::before {
  content: "\f0eb6";
}

.mdi-gamepad-up::before {
  content: "\f0e42";
}

.mdi-gamepad-variant::before {
  content: "\f0297";
}

.mdi-gamepad-variant-outline::before {
  content: "\f0eb7";
}

.mdi-gamma::before {
  content: "\f10ee";
}

.mdi-gantry-crane::before {
  content: "\f0dd1";
}

.mdi-garage::before {
  content: "\f06d9";
}

.mdi-garage-alert::before {
  content: "\f0872";
}

.mdi-garage-alert-variant::before {
  content: "\f12d5";
}

.mdi-garage-open::before {
  content: "\f06da";
}

.mdi-garage-open-variant::before {
  content: "\f12d4";
}

.mdi-garage-variant::before {
  content: "\f12d3";
}

.mdi-gas-cylinder::before {
  content: "\f0647";
}

.mdi-gas-station::before {
  content: "\f0298";
}

.mdi-gas-station-off::before {
  content: "\f1409";
}

.mdi-gas-station-off-outline::before {
  content: "\f140a";
}

.mdi-gas-station-outline::before {
  content: "\f0eb8";
}

.mdi-gate::before {
  content: "\f0299";
}

.mdi-gate-and::before {
  content: "\f08e1";
}

.mdi-gate-arrow-right::before {
  content: "\f1169";
}

.mdi-gate-nand::before {
  content: "\f08e2";
}

.mdi-gate-nor::before {
  content: "\f08e3";
}

.mdi-gate-not::before {
  content: "\f08e4";
}

.mdi-gate-open::before {
  content: "\f116a";
}

.mdi-gate-or::before {
  content: "\f08e5";
}

.mdi-gate-xnor::before {
  content: "\f08e6";
}

.mdi-gate-xor::before {
  content: "\f08e7";
}

.mdi-gatsby::before {
  content: "\f0e43";
}

.mdi-gauge::before {
  content: "\f029a";
}

.mdi-gauge-empty::before {
  content: "\f0873";
}

.mdi-gauge-full::before {
  content: "\f0874";
}

.mdi-gauge-low::before {
  content: "\f0875";
}

.mdi-gavel::before {
  content: "\f029b";
}

.mdi-gender-female::before {
  content: "\f029c";
}

.mdi-gender-male::before {
  content: "\f029d";
}

.mdi-gender-male-female::before {
  content: "\f029e";
}

.mdi-gender-male-female-variant::before {
  content: "\f113f";
}

.mdi-gender-non-binary::before {
  content: "\f1140";
}

.mdi-gender-transgender::before {
  content: "\f029f";
}

.mdi-gentoo::before {
  content: "\f08e8";
}

.mdi-gesture::before {
  content: "\f07cb";
}

.mdi-gesture-double-tap::before {
  content: "\f073c";
}

.mdi-gesture-pinch::before {
  content: "\f0abd";
}

.mdi-gesture-spread::before {
  content: "\f0abe";
}

.mdi-gesture-swipe::before {
  content: "\f0d76";
}

.mdi-gesture-swipe-down::before {
  content: "\f073d";
}

.mdi-gesture-swipe-horizontal::before {
  content: "\f0abf";
}

.mdi-gesture-swipe-left::before {
  content: "\f073e";
}

.mdi-gesture-swipe-right::before {
  content: "\f073f";
}

.mdi-gesture-swipe-up::before {
  content: "\f0740";
}

.mdi-gesture-swipe-vertical::before {
  content: "\f0ac0";
}

.mdi-gesture-tap::before {
  content: "\f0741";
}

.mdi-gesture-tap-box::before {
  content: "\f12a9";
}

.mdi-gesture-tap-button::before {
  content: "\f12a8";
}

.mdi-gesture-tap-hold::before {
  content: "\f0d77";
}

.mdi-gesture-two-double-tap::before {
  content: "\f0742";
}

.mdi-gesture-two-tap::before {
  content: "\f0743";
}

.mdi-ghost::before {
  content: "\f02a0";
}

.mdi-ghost-off::before {
  content: "\f09f5";
}

.mdi-ghost-off-outline::before {
  content: "\f165c";
}

.mdi-ghost-outline::before {
  content: "\f165d";
}

.mdi-gif::before {
  content: "\f0d78";
}

.mdi-gift::before {
  content: "\f0e44";
}

.mdi-gift-off::before {
  content: "\f16ef";
}

.mdi-gift-off-outline::before {
  content: "\f16f0";
}

.mdi-gift-open::before {
  content: "\f16f1";
}

.mdi-gift-open-outline::before {
  content: "\f16f2";
}

.mdi-gift-outline::before {
  content: "\f02a1";
}

.mdi-git::before {
  content: "\f02a2";
}

.mdi-github::before {
  content: "\f02a4";
}

.mdi-gitlab::before {
  content: "\f0ba0";
}

.mdi-glass-cocktail::before {
  content: "\f0356";
}

.mdi-glass-cocktail-off::before {
  content: "\f15e6";
}

.mdi-glass-flute::before {
  content: "\f02a5";
}

.mdi-glass-mug::before {
  content: "\f02a6";
}

.mdi-glass-mug-off::before {
  content: "\f15e7";
}

.mdi-glass-mug-variant::before {
  content: "\f1116";
}

.mdi-glass-mug-variant-off::before {
  content: "\f15e8";
}

.mdi-glass-pint-outline::before {
  content: "\f130d";
}

.mdi-glass-stange::before {
  content: "\f02a7";
}

.mdi-glass-tulip::before {
  content: "\f02a8";
}

.mdi-glass-wine::before {
  content: "\f0876";
}

.mdi-glasses::before {
  content: "\f02aa";
}

.mdi-globe-light::before {
  content: "\f12d7";
}

.mdi-globe-model::before {
  content: "\f08e9";
}

.mdi-gmail::before {
  content: "\f02ab";
}

.mdi-gnome::before {
  content: "\f02ac";
}

.mdi-go-kart::before {
  content: "\f0d79";
}

.mdi-go-kart-track::before {
  content: "\f0d7a";
}

.mdi-gog::before {
  content: "\f0ba1";
}

.mdi-gold::before {
  content: "\f124f";
}

.mdi-golf::before {
  content: "\f0823";
}

.mdi-golf-cart::before {
  content: "\f11a4";
}

.mdi-golf-tee::before {
  content: "\f1083";
}

.mdi-gondola::before {
  content: "\f0686";
}

.mdi-goodreads::before {
  content: "\f0d7b";
}

.mdi-google::before {
  content: "\f02ad";
}

.mdi-google-ads::before {
  content: "\f0c87";
}

.mdi-google-analytics::before {
  content: "\f07cc";
}

.mdi-google-assistant::before {
  content: "\f07cd";
}

.mdi-google-cardboard::before {
  content: "\f02ae";
}

.mdi-google-chrome::before {
  content: "\f02af";
}

.mdi-google-circles::before {
  content: "\f02b0";
}

.mdi-google-circles-communities::before {
  content: "\f02b1";
}

.mdi-google-circles-extended::before {
  content: "\f02b2";
}

.mdi-google-circles-group::before {
  content: "\f02b3";
}

.mdi-google-classroom::before {
  content: "\f02c0";
}

.mdi-google-cloud::before {
  content: "\f11f6";
}

.mdi-google-controller::before {
  content: "\f02b4";
}

.mdi-google-controller-off::before {
  content: "\f02b5";
}

.mdi-google-downasaur::before {
  content: "\f1362";
}

.mdi-google-drive::before {
  content: "\f02b6";
}

.mdi-google-earth::before {
  content: "\f02b7";
}

.mdi-google-fit::before {
  content: "\f096c";
}

.mdi-google-glass::before {
  content: "\f02b8";
}

.mdi-google-hangouts::before {
  content: "\f02c9";
}

.mdi-google-home::before {
  content: "\f0824";
}

.mdi-google-keep::before {
  content: "\f06dc";
}

.mdi-google-lens::before {
  content: "\f09f6";
}

.mdi-google-maps::before {
  content: "\f05f5";
}

.mdi-google-my-business::before {
  content: "\f1048";
}

.mdi-google-nearby::before {
  content: "\f02b9";
}

.mdi-google-photos::before {
  content: "\f06dd";
}

.mdi-google-play::before {
  content: "\f02bc";
}

.mdi-google-plus::before {
  content: "\f02bd";
}

.mdi-google-podcast::before {
  content: "\f0eb9";
}

.mdi-google-spreadsheet::before {
  content: "\f09f7";
}

.mdi-google-street-view::before {
  content: "\f0c88";
}

.mdi-google-translate::before {
  content: "\f02bf";
}

.mdi-gradient::before {
  content: "\f06a0";
}

.mdi-grain::before {
  content: "\f0d7c";
}

.mdi-graph::before {
  content: "\f1049";
}

.mdi-graph-outline::before {
  content: "\f104a";
}

.mdi-graphql::before {
  content: "\f0877";
}

.mdi-grass::before {
  content: "\f1510";
}

.mdi-grave-stone::before {
  content: "\f0ba2";
}

.mdi-grease-pencil::before {
  content: "\f0648";
}

.mdi-greater-than::before {
  content: "\f096d";
}

.mdi-greater-than-or-equal::before {
  content: "\f096e";
}

.mdi-grid::before {
  content: "\f02c1";
}

.mdi-grid-large::before {
  content: "\f0758";
}

.mdi-grid-off::before {
  content: "\f02c2";
}

.mdi-grill::before {
  content: "\f0e45";
}

.mdi-grill-outline::before {
  content: "\f118a";
}

.mdi-group::before {
  content: "\f02c3";
}

.mdi-guitar-acoustic::before {
  content: "\f0771";
}

.mdi-guitar-electric::before {
  content: "\f02c4";
}

.mdi-guitar-pick::before {
  content: "\f02c5";
}

.mdi-guitar-pick-outline::before {
  content: "\f02c6";
}

.mdi-guy-fawkes-mask::before {
  content: "\f0825";
}

.mdi-hail::before {
  content: "\f0ac1";
}

.mdi-hair-dryer::before {
  content: "\f10ef";
}

.mdi-hair-dryer-outline::before {
  content: "\f10f0";
}

.mdi-halloween::before {
  content: "\f0ba3";
}

.mdi-hamburger::before {
  content: "\f0685";
}

.mdi-hammer::before {
  content: "\f08ea";
}

.mdi-hammer-screwdriver::before {
  content: "\f1322";
}

.mdi-hammer-wrench::before {
  content: "\f1323";
}

.mdi-hand::before {
  content: "\f0a4f";
}

.mdi-hand-heart::before {
  content: "\f10f1";
}

.mdi-hand-heart-outline::before {
  content: "\f157e";
}

.mdi-hand-left::before {
  content: "\f0e46";
}

.mdi-hand-okay::before {
  content: "\f0a50";
}

.mdi-hand-peace::before {
  content: "\f0a51";
}

.mdi-hand-peace-variant::before {
  content: "\f0a52";
}

.mdi-hand-pointing-down::before {
  content: "\f0a53";
}

.mdi-hand-pointing-left::before {
  content: "\f0a54";
}

.mdi-hand-pointing-right::before {
  content: "\f02c7";
}

.mdi-hand-pointing-up::before {
  content: "\f0a55";
}

.mdi-hand-right::before {
  content: "\f0e47";
}

.mdi-hand-saw::before {
  content: "\f0e48";
}

.mdi-hand-wash::before {
  content: "\f157f";
}

.mdi-hand-wash-outline::before {
  content: "\f1580";
}

.mdi-hand-water::before {
  content: "\f139f";
}

.mdi-handball::before {
  content: "\f0f53";
}

.mdi-handcuffs::before {
  content: "\f113e";
}

.mdi-handshake::before {
  content: "\f1218";
}

.mdi-handshake-outline::before {
  content: "\f15a1";
}

.mdi-hanger::before {
  content: "\f02c8";
}

.mdi-hard-hat::before {
  content: "\f096f";
}

.mdi-harddisk::before {
  content: "\f02ca";
}

.mdi-harddisk-plus::before {
  content: "\f104b";
}

.mdi-harddisk-remove::before {
  content: "\f104c";
}

.mdi-hat-fedora::before {
  content: "\f0ba4";
}

.mdi-hazard-lights::before {
  content: "\f0c89";
}

.mdi-hdr::before {
  content: "\f0d7d";
}

.mdi-hdr-off::before {
  content: "\f0d7e";
}

.mdi-head::before {
  content: "\f135e";
}

.mdi-head-alert::before {
  content: "\f1338";
}

.mdi-head-alert-outline::before {
  content: "\f1339";
}

.mdi-head-check::before {
  content: "\f133a";
}

.mdi-head-check-outline::before {
  content: "\f133b";
}

.mdi-head-cog::before {
  content: "\f133c";
}

.mdi-head-cog-outline::before {
  content: "\f133d";
}

.mdi-head-dots-horizontal::before {
  content: "\f133e";
}

.mdi-head-dots-horizontal-outline::before {
  content: "\f133f";
}

.mdi-head-flash::before {
  content: "\f1340";
}

.mdi-head-flash-outline::before {
  content: "\f1341";
}

.mdi-head-heart::before {
  content: "\f1342";
}

.mdi-head-heart-outline::before {
  content: "\f1343";
}

.mdi-head-lightbulb::before {
  content: "\f1344";
}

.mdi-head-lightbulb-outline::before {
  content: "\f1345";
}

.mdi-head-minus::before {
  content: "\f1346";
}

.mdi-head-minus-outline::before {
  content: "\f1347";
}

.mdi-head-outline::before {
  content: "\f135f";
}

.mdi-head-plus::before {
  content: "\f1348";
}

.mdi-head-plus-outline::before {
  content: "\f1349";
}

.mdi-head-question::before {
  content: "\f134a";
}

.mdi-head-question-outline::before {
  content: "\f134b";
}

.mdi-head-remove::before {
  content: "\f134c";
}

.mdi-head-remove-outline::before {
  content: "\f134d";
}

.mdi-head-snowflake::before {
  content: "\f134e";
}

.mdi-head-snowflake-outline::before {
  content: "\f134f";
}

.mdi-head-sync::before {
  content: "\f1350";
}

.mdi-head-sync-outline::before {
  content: "\f1351";
}

.mdi-headphones::before {
  content: "\f02cb";
}

.mdi-headphones-bluetooth::before {
  content: "\f0970";
}

.mdi-headphones-box::before {
  content: "\f02cc";
}

.mdi-headphones-off::before {
  content: "\f07ce";
}

.mdi-headphones-settings::before {
  content: "\f02cd";
}

.mdi-headset::before {
  content: "\f02ce";
}

.mdi-headset-dock::before {
  content: "\f02cf";
}

.mdi-headset-off::before {
  content: "\f02d0";
}

.mdi-heart::before {
  content: "\f02d1";
}

.mdi-heart-box::before {
  content: "\f02d2";
}

.mdi-heart-box-outline::before {
  content: "\f02d3";
}

.mdi-heart-broken::before {
  content: "\f02d4";
}

.mdi-heart-broken-outline::before {
  content: "\f0d14";
}

.mdi-heart-circle::before {
  content: "\f0971";
}

.mdi-heart-circle-outline::before {
  content: "\f0972";
}

.mdi-heart-cog::before {
  content: "\f1663";
}

.mdi-heart-cog-outline::before {
  content: "\f1664";
}

.mdi-heart-flash::before {
  content: "\f0ef9";
}

.mdi-heart-half::before {
  content: "\f06df";
}

.mdi-heart-half-full::before {
  content: "\f06de";
}

.mdi-heart-half-outline::before {
  content: "\f06e0";
}

.mdi-heart-minus::before {
  content: "\f142f";
}

.mdi-heart-minus-outline::before {
  content: "\f1432";
}

.mdi-heart-multiple::before {
  content: "\f0a56";
}

.mdi-heart-multiple-outline::before {
  content: "\f0a57";
}

.mdi-heart-off::before {
  content: "\f0759";
}

.mdi-heart-off-outline::before {
  content: "\f1434";
}

.mdi-heart-outline::before {
  content: "\f02d5";
}

.mdi-heart-plus::before {
  content: "\f142e";
}

.mdi-heart-plus-outline::before {
  content: "\f1431";
}

.mdi-heart-pulse::before {
  content: "\f05f6";
}

.mdi-heart-remove::before {
  content: "\f1430";
}

.mdi-heart-remove-outline::before {
  content: "\f1433";
}

.mdi-heart-settings::before {
  content: "\f1665";
}

.mdi-heart-settings-outline::before {
  content: "\f1666";
}

.mdi-helicopter::before {
  content: "\f0ac2";
}

.mdi-help::before {
  content: "\f02d6";
}

.mdi-help-box::before {
  content: "\f078b";
}

.mdi-help-circle::before {
  content: "\f02d7";
}

.mdi-help-circle-outline::before {
  content: "\f0625";
}

.mdi-help-network::before {
  content: "\f06f5";
}

.mdi-help-network-outline::before {
  content: "\f0c8a";
}

.mdi-help-rhombus::before {
  content: "\f0ba5";
}

.mdi-help-rhombus-outline::before {
  content: "\f0ba6";
}

.mdi-hexadecimal::before {
  content: "\f12a7";
}

.mdi-hexagon::before {
  content: "\f02d8";
}

.mdi-hexagon-multiple::before {
  content: "\f06e1";
}

.mdi-hexagon-multiple-outline::before {
  content: "\f10f2";
}

.mdi-hexagon-outline::before {
  content: "\f02d9";
}

.mdi-hexagon-slice-1::before {
  content: "\f0ac3";
}

.mdi-hexagon-slice-2::before {
  content: "\f0ac4";
}

.mdi-hexagon-slice-3::before {
  content: "\f0ac5";
}

.mdi-hexagon-slice-4::before {
  content: "\f0ac6";
}

.mdi-hexagon-slice-5::before {
  content: "\f0ac7";
}

.mdi-hexagon-slice-6::before {
  content: "\f0ac8";
}

.mdi-hexagram::before {
  content: "\f0ac9";
}

.mdi-hexagram-outline::before {
  content: "\f0aca";
}

.mdi-high-definition::before {
  content: "\f07cf";
}

.mdi-high-definition-box::before {
  content: "\f0878";
}

.mdi-highway::before {
  content: "\f05f7";
}

.mdi-hiking::before {
  content: "\f0d7f";
}

.mdi-hinduism::before {
  content: "\f0973";
}

.mdi-history::before {
  content: "\f02da";
}

.mdi-hockey-puck::before {
  content: "\f0879";
}

.mdi-hockey-sticks::before {
  content: "\f087a";
}

.mdi-hololens::before {
  content: "\f02db";
}

.mdi-home::before {
  content: "\f02dc";
}

.mdi-home-account::before {
  content: "\f0826";
}

.mdi-home-alert::before {
  content: "\f087b";
}

.mdi-home-alert-outline::before {
  content: "\f15d0";
}

.mdi-home-analytics::before {
  content: "\f0eba";
}

.mdi-home-assistant::before {
  content: "\f07d0";
}

.mdi-home-automation::before {
  content: "\f07d1";
}

.mdi-home-circle::before {
  content: "\f07d2";
}

.mdi-home-circle-outline::before {
  content: "\f104d";
}

.mdi-home-city::before {
  content: "\f0d15";
}

.mdi-home-city-outline::before {
  content: "\f0d16";
}

.mdi-home-currency-usd::before {
  content: "\f08af";
}

.mdi-home-edit::before {
  content: "\f1159";
}

.mdi-home-edit-outline::before {
  content: "\f115a";
}

.mdi-home-export-outline::before {
  content: "\f0f9b";
}

.mdi-home-flood::before {
  content: "\f0efa";
}

.mdi-home-floor-0::before {
  content: "\f0dd2";
}

.mdi-home-floor-1::before {
  content: "\f0d80";
}

.mdi-home-floor-2::before {
  content: "\f0d81";
}

.mdi-home-floor-3::before {
  content: "\f0d82";
}

.mdi-home-floor-a::before {
  content: "\f0d83";
}

.mdi-home-floor-b::before {
  content: "\f0d84";
}

.mdi-home-floor-g::before {
  content: "\f0d85";
}

.mdi-home-floor-l::before {
  content: "\f0d86";
}

.mdi-home-floor-negative-1::before {
  content: "\f0dd3";
}

.mdi-home-group::before {
  content: "\f0dd4";
}

.mdi-home-heart::before {
  content: "\f0827";
}

.mdi-home-import-outline::before {
  content: "\f0f9c";
}

.mdi-home-lightbulb::before {
  content: "\f1251";
}

.mdi-home-lightbulb-outline::before {
  content: "\f1252";
}

.mdi-home-lock::before {
  content: "\f08eb";
}

.mdi-home-lock-open::before {
  content: "\f08ec";
}

.mdi-home-map-marker::before {
  content: "\f05f8";
}

.mdi-home-minus::before {
  content: "\f0974";
}

.mdi-home-minus-outline::before {
  content: "\f13d5";
}

.mdi-home-modern::before {
  content: "\f02dd";
}

.mdi-home-outline::before {
  content: "\f06a1";
}

.mdi-home-plus::before {
  content: "\f0975";
}

.mdi-home-plus-outline::before {
  content: "\f13d6";
}

.mdi-home-remove::before {
  content: "\f1247";
}

.mdi-home-remove-outline::before {
  content: "\f13d7";
}

.mdi-home-roof::before {
  content: "\f112b";
}

.mdi-home-search::before {
  content: "\f13b0";
}

.mdi-home-search-outline::before {
  content: "\f13b1";
}

.mdi-home-thermometer::before {
  content: "\f0f54";
}

.mdi-home-thermometer-outline::before {
  content: "\f0f55";
}

.mdi-home-variant::before {
  content: "\f02de";
}

.mdi-home-variant-outline::before {
  content: "\f0ba7";
}

.mdi-hook::before {
  content: "\f06e2";
}

.mdi-hook-off::before {
  content: "\f06e3";
}

.mdi-hops::before {
  content: "\f02df";
}

.mdi-horizontal-rotate-clockwise::before {
  content: "\f10f3";
}

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\f10f4";
}

.mdi-horse::before {
  content: "\f15bf";
}

.mdi-horse-human::before {
  content: "\f15c0";
}

.mdi-horse-variant::before {
  content: "\f15c1";
}

.mdi-horseshoe::before {
  content: "\f0a58";
}

.mdi-hospital::before {
  content: "\f0ff6";
}

.mdi-hospital-box::before {
  content: "\f02e0";
}

.mdi-hospital-box-outline::before {
  content: "\f0ff7";
}

.mdi-hospital-building::before {
  content: "\f02e1";
}

.mdi-hospital-marker::before {
  content: "\f02e2";
}

.mdi-hot-tub::before {
  content: "\f0828";
}

.mdi-hours-24::before {
  content: "\f1478";
}

.mdi-hubspot::before {
  content: "\f0d17";
}

.mdi-hulu::before {
  content: "\f0829";
}

.mdi-human::before {
  content: "\f02e6";
}

.mdi-human-baby-changing-table::before {
  content: "\f138b";
}

.mdi-human-cane::before {
  content: "\f1581";
}

.mdi-human-capacity-decrease::before {
  content: "\f159b";
}

.mdi-human-capacity-increase::before {
  content: "\f159c";
}

.mdi-human-child::before {
  content: "\f02e7";
}

.mdi-human-edit::before {
  content: "\f14e8";
}

.mdi-human-female::before {
  content: "\f0649";
}

.mdi-human-female-boy::before {
  content: "\f0a59";
}

.mdi-human-female-dance::before {
  content: "\f15c9";
}

.mdi-human-female-female::before {
  content: "\f0a5a";
}

.mdi-human-female-girl::before {
  content: "\f0a5b";
}

.mdi-human-greeting::before {
  content: "\f064a";
}

.mdi-human-greeting-proximity::before {
  content: "\f159d";
}

.mdi-human-handsdown::before {
  content: "\f064b";
}

.mdi-human-handsup::before {
  content: "\f064c";
}

.mdi-human-male::before {
  content: "\f064d";
}

.mdi-human-male-boy::before {
  content: "\f0a5c";
}

.mdi-human-male-child::before {
  content: "\f138c";
}

.mdi-human-male-female::before {
  content: "\f02e8";
}

.mdi-human-male-girl::before {
  content: "\f0a5d";
}

.mdi-human-male-height::before {
  content: "\f0efb";
}

.mdi-human-male-height-variant::before {
  content: "\f0efc";
}

.mdi-human-male-male::before {
  content: "\f0a5e";
}

.mdi-human-pregnant::before {
  content: "\f05cf";
}

.mdi-human-queue::before {
  content: "\f1571";
}

.mdi-human-scooter::before {
  content: "\f11e9";
}

.mdi-human-wheelchair::before {
  content: "\f138d";
}

.mdi-humble-bundle::before {
  content: "\f0744";
}

.mdi-hvac::before {
  content: "\f1352";
}

.mdi-hvac-off::before {
  content: "\f159e";
}

.mdi-hydraulic-oil-level::before {
  content: "\f1324";
}

.mdi-hydraulic-oil-temperature::before {
  content: "\f1325";
}

.mdi-hydro-power::before {
  content: "\f12e5";
}

.mdi-ice-cream::before {
  content: "\f082a";
}

.mdi-ice-cream-off::before {
  content: "\f0e52";
}

.mdi-ice-pop::before {
  content: "\f0efd";
}

.mdi-id-card::before {
  content: "\f0fc0";
}

.mdi-identifier::before {
  content: "\f0efe";
}

.mdi-ideogram-cjk::before {
  content: "\f1331";
}

.mdi-ideogram-cjk-variant::before {
  content: "\f1332";
}

.mdi-iframe::before {
  content: "\f0c8b";
}

.mdi-iframe-array::before {
  content: "\f10f5";
}

.mdi-iframe-array-outline::before {
  content: "\f10f6";
}

.mdi-iframe-braces::before {
  content: "\f10f7";
}

.mdi-iframe-braces-outline::before {
  content: "\f10f8";
}

.mdi-iframe-outline::before {
  content: "\f0c8c";
}

.mdi-iframe-parentheses::before {
  content: "\f10f9";
}

.mdi-iframe-parentheses-outline::before {
  content: "\f10fa";
}

.mdi-iframe-variable::before {
  content: "\f10fb";
}

.mdi-iframe-variable-outline::before {
  content: "\f10fc";
}

.mdi-image::before {
  content: "\f02e9";
}

.mdi-image-album::before {
  content: "\f02ea";
}

.mdi-image-area::before {
  content: "\f02eb";
}

.mdi-image-area-close::before {
  content: "\f02ec";
}

.mdi-image-auto-adjust::before {
  content: "\f0fc1";
}

.mdi-image-broken::before {
  content: "\f02ed";
}

.mdi-image-broken-variant::before {
  content: "\f02ee";
}

.mdi-image-edit::before {
  content: "\f11e3";
}

.mdi-image-edit-outline::before {
  content: "\f11e4";
}

.mdi-image-filter-black-white::before {
  content: "\f02f0";
}

.mdi-image-filter-center-focus::before {
  content: "\f02f1";
}

.mdi-image-filter-center-focus-strong::before {
  content: "\f0eff";
}

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\f0f00";
}

.mdi-image-filter-center-focus-weak::before {
  content: "\f02f2";
}

.mdi-image-filter-drama::before {
  content: "\f02f3";
}

.mdi-image-filter-frames::before {
  content: "\f02f4";
}

.mdi-image-filter-hdr::before {
  content: "\f02f5";
}

.mdi-image-filter-none::before {
  content: "\f02f6";
}

.mdi-image-filter-tilt-shift::before {
  content: "\f02f7";
}

.mdi-image-filter-vintage::before {
  content: "\f02f8";
}

.mdi-image-frame::before {
  content: "\f0e49";
}

.mdi-image-minus::before {
  content: "\f1419";
}

.mdi-image-move::before {
  content: "\f09f8";
}

.mdi-image-multiple::before {
  content: "\f02f9";
}

.mdi-image-multiple-outline::before {
  content: "\f02ef";
}

.mdi-image-off::before {
  content: "\f082b";
}

.mdi-image-off-outline::before {
  content: "\f11d1";
}

.mdi-image-outline::before {
  content: "\f0976";
}

.mdi-image-plus::before {
  content: "\f087c";
}

.mdi-image-remove::before {
  content: "\f1418";
}

.mdi-image-search::before {
  content: "\f0977";
}

.mdi-image-search-outline::before {
  content: "\f0978";
}

.mdi-image-size-select-actual::before {
  content: "\f0c8d";
}

.mdi-image-size-select-large::before {
  content: "\f0c8e";
}

.mdi-image-size-select-small::before {
  content: "\f0c8f";
}

.mdi-image-text::before {
  content: "\f160d";
}

.mdi-import::before {
  content: "\f02fa";
}

.mdi-inbox::before {
  content: "\f0687";
}

.mdi-inbox-arrow-down::before {
  content: "\f02fb";
}

.mdi-inbox-arrow-down-outline::before {
  content: "\f1270";
}

.mdi-inbox-arrow-up::before {
  content: "\f03d1";
}

.mdi-inbox-arrow-up-outline::before {
  content: "\f1271";
}

.mdi-inbox-full::before {
  content: "\f1272";
}

.mdi-inbox-full-outline::before {
  content: "\f1273";
}

.mdi-inbox-multiple::before {
  content: "\f08b0";
}

.mdi-inbox-multiple-outline::before {
  content: "\f0ba8";
}

.mdi-inbox-outline::before {
  content: "\f1274";
}

.mdi-inbox-remove::before {
  content: "\f159f";
}

.mdi-inbox-remove-outline::before {
  content: "\f15a0";
}

.mdi-incognito::before {
  content: "\f05f9";
}

.mdi-incognito-circle::before {
  content: "\f1421";
}

.mdi-incognito-circle-off::before {
  content: "\f1422";
}

.mdi-incognito-off::before {
  content: "\f0075";
}

.mdi-infinity::before {
  content: "\f06e4";
}

.mdi-information::before {
  content: "\f02fc";
}

.mdi-information-outline::before {
  content: "\f02fd";
}

.mdi-information-variant::before {
  content: "\f064e";
}

.mdi-instagram::before {
  content: "\f02fe";
}

.mdi-instrument-triangle::before {
  content: "\f104e";
}

.mdi-invert-colors::before {
  content: "\f0301";
}

.mdi-invert-colors-off::before {
  content: "\f0e4a";
}

.mdi-iobroker::before {
  content: "\f12e8";
}

.mdi-ip::before {
  content: "\f0a5f";
}

.mdi-ip-network::before {
  content: "\f0a60";
}

.mdi-ip-network-outline::before {
  content: "\f0c90";
}

.mdi-ipod::before {
  content: "\f0c91";
}

.mdi-islam::before {
  content: "\f0979";
}

.mdi-island::before {
  content: "\f104f";
}

.mdi-iv-bag::before {
  content: "\f10b9";
}

.mdi-jabber::before {
  content: "\f0dd5";
}

.mdi-jeepney::before {
  content: "\f0302";
}

.mdi-jellyfish::before {
  content: "\f0f01";
}

.mdi-jellyfish-outline::before {
  content: "\f0f02";
}

.mdi-jira::before {
  content: "\f0303";
}

.mdi-jquery::before {
  content: "\f087d";
}

.mdi-jsfiddle::before {
  content: "\f0304";
}

.mdi-judaism::before {
  content: "\f097a";
}

.mdi-jump-rope::before {
  content: "\f12ff";
}

.mdi-kabaddi::before {
  content: "\f0d87";
}

.mdi-kangaroo::before {
  content: "\f1558";
}

.mdi-karate::before {
  content: "\f082c";
}

.mdi-keg::before {
  content: "\f0305";
}

.mdi-kettle::before {
  content: "\f05fa";
}

.mdi-kettle-alert::before {
  content: "\f1317";
}

.mdi-kettle-alert-outline::before {
  content: "\f1318";
}

.mdi-kettle-off::before {
  content: "\f131b";
}

.mdi-kettle-off-outline::before {
  content: "\f131c";
}

.mdi-kettle-outline::before {
  content: "\f0f56";
}

.mdi-kettle-pour-over::before {
  content: "\f173c";
}

.mdi-kettle-steam::before {
  content: "\f1319";
}

.mdi-kettle-steam-outline::before {
  content: "\f131a";
}

.mdi-kettlebell::before {
  content: "\f1300";
}

.mdi-key::before {
  content: "\f0306";
}

.mdi-key-arrow-right::before {
  content: "\f1312";
}

.mdi-key-chain::before {
  content: "\f1574";
}

.mdi-key-chain-variant::before {
  content: "\f1575";
}

.mdi-key-change::before {
  content: "\f0307";
}

.mdi-key-link::before {
  content: "\f119f";
}

.mdi-key-minus::before {
  content: "\f0308";
}

.mdi-key-outline::before {
  content: "\f0dd6";
}

.mdi-key-plus::before {
  content: "\f0309";
}

.mdi-key-remove::before {
  content: "\f030a";
}

.mdi-key-star::before {
  content: "\f119e";
}

.mdi-key-variant::before {
  content: "\f030b";
}

.mdi-key-wireless::before {
  content: "\f0fc2";
}

.mdi-keyboard::before {
  content: "\f030c";
}

.mdi-keyboard-backspace::before {
  content: "\f030d";
}

.mdi-keyboard-caps::before {
  content: "\f030e";
}

.mdi-keyboard-close::before {
  content: "\f030f";
}

.mdi-keyboard-esc::before {
  content: "\f12b7";
}

.mdi-keyboard-f1::before {
  content: "\f12ab";
}

.mdi-keyboard-f10::before {
  content: "\f12b4";
}

.mdi-keyboard-f11::before {
  content: "\f12b5";
}

.mdi-keyboard-f12::before {
  content: "\f12b6";
}

.mdi-keyboard-f2::before {
  content: "\f12ac";
}

.mdi-keyboard-f3::before {
  content: "\f12ad";
}

.mdi-keyboard-f4::before {
  content: "\f12ae";
}

.mdi-keyboard-f5::before {
  content: "\f12af";
}

.mdi-keyboard-f6::before {
  content: "\f12b0";
}

.mdi-keyboard-f7::before {
  content: "\f12b1";
}

.mdi-keyboard-f8::before {
  content: "\f12b2";
}

.mdi-keyboard-f9::before {
  content: "\f12b3";
}

.mdi-keyboard-off::before {
  content: "\f0310";
}

.mdi-keyboard-off-outline::before {
  content: "\f0e4b";
}

.mdi-keyboard-outline::before {
  content: "\f097b";
}

.mdi-keyboard-return::before {
  content: "\f0311";
}

.mdi-keyboard-settings::before {
  content: "\f09f9";
}

.mdi-keyboard-settings-outline::before {
  content: "\f09fa";
}

.mdi-keyboard-space::before {
  content: "\f1050";
}

.mdi-keyboard-tab::before {
  content: "\f0312";
}

.mdi-keyboard-variant::before {
  content: "\f0313";
}

.mdi-khanda::before {
  content: "\f10fd";
}

.mdi-kickstarter::before {
  content: "\f0745";
}

.mdi-klingon::before {
  content: "\f135b";
}

.mdi-knife::before {
  content: "\f09fb";
}

.mdi-knife-military::before {
  content: "\f09fc";
}

.mdi-koala::before {
  content: "\f173f";
}

.mdi-kodi::before {
  content: "\f0314";
}

.mdi-kubernetes::before {
  content: "\f10fe";
}

.mdi-label::before {
  content: "\f0315";
}

.mdi-label-multiple::before {
  content: "\f1375";
}

.mdi-label-multiple-outline::before {
  content: "\f1376";
}

.mdi-label-off::before {
  content: "\f0acb";
}

.mdi-label-off-outline::before {
  content: "\f0acc";
}

.mdi-label-outline::before {
  content: "\f0316";
}

.mdi-label-percent::before {
  content: "\f12ea";
}

.mdi-label-percent-outline::before {
  content: "\f12eb";
}

.mdi-label-variant::before {
  content: "\f0acd";
}

.mdi-label-variant-outline::before {
  content: "\f0ace";
}

.mdi-ladder::before {
  content: "\f15a2";
}

.mdi-ladybug::before {
  content: "\f082d";
}

.mdi-lambda::before {
  content: "\f0627";
}

.mdi-lamp::before {
  content: "\f06b5";
}

.mdi-lamps::before {
  content: "\f1576";
}

.mdi-lan::before {
  content: "\f0317";
}

.mdi-lan-check::before {
  content: "\f12aa";
}

.mdi-lan-connect::before {
  content: "\f0318";
}

.mdi-lan-disconnect::before {
  content: "\f0319";
}

.mdi-lan-pending::before {
  content: "\f031a";
}

.mdi-language-c::before {
  content: "\f0671";
}

.mdi-language-cpp::before {
  content: "\f0672";
}

.mdi-language-csharp::before {
  content: "\f031b";
}

.mdi-language-css3::before {
  content: "\f031c";
}

.mdi-language-fortran::before {
  content: "\f121a";
}

.mdi-language-go::before {
  content: "\f07d3";
}

.mdi-language-haskell::before {
  content: "\f0c92";
}

.mdi-language-html5::before {
  content: "\f031d";
}

.mdi-language-java::before {
  content: "\f0b37";
}

.mdi-language-javascript::before {
  content: "\f031e";
}

.mdi-language-kotlin::before {
  content: "\f1219";
}

.mdi-language-lua::before {
  content: "\f08b1";
}

.mdi-language-markdown::before {
  content: "\f0354";
}

.mdi-language-markdown-outline::before {
  content: "\f0f5b";
}

.mdi-language-php::before {
  content: "\f031f";
}

.mdi-language-python::before {
  content: "\f0320";
}

.mdi-language-r::before {
  content: "\f07d4";
}

.mdi-language-ruby::before {
  content: "\f0d2d";
}

.mdi-language-ruby-on-rails::before {
  content: "\f0acf";
}

.mdi-language-rust::before {
  content: "\f1617";
}

.mdi-language-swift::before {
  content: "\f06e5";
}

.mdi-language-typescript::before {
  content: "\f06e6";
}

.mdi-language-xaml::before {
  content: "\f0673";
}

.mdi-laptop::before {
  content: "\f0322";
}

.mdi-laptop-chromebook::before {
  content: "\f0323";
}

.mdi-laptop-mac::before {
  content: "\f0324";
}

.mdi-laptop-off::before {
  content: "\f06e7";
}

.mdi-laptop-windows::before {
  content: "\f0325";
}

.mdi-laravel::before {
  content: "\f0ad0";
}

.mdi-laser-pointer::before {
  content: "\f1484";
}

.mdi-lasso::before {
  content: "\f0f03";
}

.mdi-lastpass::before {
  content: "\f0446";
}

.mdi-latitude::before {
  content: "\f0f57";
}

.mdi-launch::before {
  content: "\f0327";
}

.mdi-lava-lamp::before {
  content: "\f07d5";
}

.mdi-layers::before {
  content: "\f0328";
}

.mdi-layers-minus::before {
  content: "\f0e4c";
}

.mdi-layers-off::before {
  content: "\f0329";
}

.mdi-layers-off-outline::before {
  content: "\f09fd";
}

.mdi-layers-outline::before {
  content: "\f09fe";
}

.mdi-layers-plus::before {
  content: "\f0e4d";
}

.mdi-layers-remove::before {
  content: "\f0e4e";
}

.mdi-layers-search::before {
  content: "\f1206";
}

.mdi-layers-search-outline::before {
  content: "\f1207";
}

.mdi-layers-triple::before {
  content: "\f0f58";
}

.mdi-layers-triple-outline::before {
  content: "\f0f59";
}

.mdi-lead-pencil::before {
  content: "\f064f";
}

.mdi-leaf::before {
  content: "\f032a";
}

.mdi-leaf-maple::before {
  content: "\f0c93";
}

.mdi-leaf-maple-off::before {
  content: "\f12da";
}

.mdi-leaf-off::before {
  content: "\f12d9";
}

.mdi-leak::before {
  content: "\f0dd7";
}

.mdi-leak-off::before {
  content: "\f0dd8";
}

.mdi-led-off::before {
  content: "\f032b";
}

.mdi-led-on::before {
  content: "\f032c";
}

.mdi-led-outline::before {
  content: "\f032d";
}

.mdi-led-strip::before {
  content: "\f07d6";
}

.mdi-led-strip-variant::before {
  content: "\f1051";
}

.mdi-led-variant-off::before {
  content: "\f032e";
}

.mdi-led-variant-on::before {
  content: "\f032f";
}

.mdi-led-variant-outline::before {
  content: "\f0330";
}

.mdi-leek::before {
  content: "\f117d";
}

.mdi-less-than::before {
  content: "\f097c";
}

.mdi-less-than-or-equal::before {
  content: "\f097d";
}

.mdi-library::before {
  content: "\f0331";
}

.mdi-library-shelves::before {
  content: "\f0ba9";
}

.mdi-license::before {
  content: "\f0fc3";
}

.mdi-lifebuoy::before {
  content: "\f087e";
}

.mdi-light-switch::before {
  content: "\f097e";
}

.mdi-lightbulb::before {
  content: "\f0335";
}

.mdi-lightbulb-cfl::before {
  content: "\f1208";
}

.mdi-lightbulb-cfl-off::before {
  content: "\f1209";
}

.mdi-lightbulb-cfl-spiral::before {
  content: "\f1275";
}

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\f12c3";
}

.mdi-lightbulb-group::before {
  content: "\f1253";
}

.mdi-lightbulb-group-off::before {
  content: "\f12cd";
}

.mdi-lightbulb-group-off-outline::before {
  content: "\f12ce";
}

.mdi-lightbulb-group-outline::before {
  content: "\f1254";
}

.mdi-lightbulb-multiple::before {
  content: "\f1255";
}

.mdi-lightbulb-multiple-off::before {
  content: "\f12cf";
}

.mdi-lightbulb-multiple-off-outline::before {
  content: "\f12d0";
}

.mdi-lightbulb-multiple-outline::before {
  content: "\f1256";
}

.mdi-lightbulb-off::before {
  content: "\f0e4f";
}

.mdi-lightbulb-off-outline::before {
  content: "\f0e50";
}

.mdi-lightbulb-on::before {
  content: "\f06e8";
}

.mdi-lightbulb-on-outline::before {
  content: "\f06e9";
}

.mdi-lightbulb-outline::before {
  content: "\f0336";
}

.mdi-lighthouse::before {
  content: "\f09ff";
}

.mdi-lighthouse-on::before {
  content: "\f0a00";
}

.mdi-lightning-bolt::before {
  content: "\f140b";
}

.mdi-lightning-bolt-outline::before {
  content: "\f140c";
}

.mdi-lingerie::before {
  content: "\f1476";
}

.mdi-link::before {
  content: "\f0337";
}

.mdi-link-box::before {
  content: "\f0d1a";
}

.mdi-link-box-outline::before {
  content: "\f0d1b";
}

.mdi-link-box-variant::before {
  content: "\f0d1c";
}

.mdi-link-box-variant-outline::before {
  content: "\f0d1d";
}

.mdi-link-lock::before {
  content: "\f10ba";
}

.mdi-link-off::before {
  content: "\f0338";
}

.mdi-link-plus::before {
  content: "\f0c94";
}

.mdi-link-variant::before {
  content: "\f0339";
}

.mdi-link-variant-minus::before {
  content: "\f10ff";
}

.mdi-link-variant-off::before {
  content: "\f033a";
}

.mdi-link-variant-plus::before {
  content: "\f1100";
}

.mdi-link-variant-remove::before {
  content: "\f1101";
}

.mdi-linkedin::before {
  content: "\f033b";
}

.mdi-linux::before {
  content: "\f033d";
}

.mdi-linux-mint::before {
  content: "\f08ed";
}

.mdi-lipstick::before {
  content: "\f13b5";
}

.mdi-list-status::before {
  content: "\f15ab";
}

.mdi-litecoin::before {
  content: "\f0a61";
}

.mdi-loading::before {
  content: "\f0772";
}

.mdi-location-enter::before {
  content: "\f0fc4";
}

.mdi-location-exit::before {
  content: "\f0fc5";
}

.mdi-lock::before {
  content: "\f033e";
}

.mdi-lock-alert::before {
  content: "\f08ee";
}

.mdi-lock-alert-outline::before {
  content: "\f15d1";
}

.mdi-lock-check::before {
  content: "\f139a";
}

.mdi-lock-check-outline::before {
  content: "\f16a8";
}

.mdi-lock-clock::before {
  content: "\f097f";
}

.mdi-lock-minus::before {
  content: "\f16a9";
}

.mdi-lock-minus-outline::before {
  content: "\f16aa";
}

.mdi-lock-off::before {
  content: "\f1671";
}

.mdi-lock-off-outline::before {
  content: "\f1672";
}

.mdi-lock-open::before {
  content: "\f033f";
}

.mdi-lock-open-alert::before {
  content: "\f139b";
}

.mdi-lock-open-alert-outline::before {
  content: "\f15d2";
}

.mdi-lock-open-check::before {
  content: "\f139c";
}

.mdi-lock-open-check-outline::before {
  content: "\f16ab";
}

.mdi-lock-open-minus::before {
  content: "\f16ac";
}

.mdi-lock-open-minus-outline::before {
  content: "\f16ad";
}

.mdi-lock-open-outline::before {
  content: "\f0340";
}

.mdi-lock-open-plus::before {
  content: "\f16ae";
}

.mdi-lock-open-plus-outline::before {
  content: "\f16af";
}

.mdi-lock-open-remove::before {
  content: "\f16b0";
}

.mdi-lock-open-remove-outline::before {
  content: "\f16b1";
}

.mdi-lock-open-variant::before {
  content: "\f0fc6";
}

.mdi-lock-open-variant-outline::before {
  content: "\f0fc7";
}

.mdi-lock-outline::before {
  content: "\f0341";
}

.mdi-lock-pattern::before {
  content: "\f06ea";
}

.mdi-lock-plus::before {
  content: "\f05fb";
}

.mdi-lock-plus-outline::before {
  content: "\f16b2";
}

.mdi-lock-question::before {
  content: "\f08ef";
}

.mdi-lock-remove::before {
  content: "\f16b3";
}

.mdi-lock-remove-outline::before {
  content: "\f16b4";
}

.mdi-lock-reset::before {
  content: "\f0773";
}

.mdi-lock-smart::before {
  content: "\f08b2";
}

.mdi-locker::before {
  content: "\f07d7";
}

.mdi-locker-multiple::before {
  content: "\f07d8";
}

.mdi-login::before {
  content: "\f0342";
}

.mdi-login-variant::before {
  content: "\f05fc";
}

.mdi-logout::before {
  content: "\f0343";
}

.mdi-logout-variant::before {
  content: "\f05fd";
}

.mdi-longitude::before {
  content: "\f0f5a";
}

.mdi-looks::before {
  content: "\f0344";
}

.mdi-lotion::before {
  content: "\f1582";
}

.mdi-lotion-outline::before {
  content: "\f1583";
}

.mdi-lotion-plus::before {
  content: "\f1584";
}

.mdi-lotion-plus-outline::before {
  content: "\f1585";
}

.mdi-loupe::before {
  content: "\f0345";
}

.mdi-lumx::before {
  content: "\f0346";
}

.mdi-lungs::before {
  content: "\f1084";
}

.mdi-magnet::before {
  content: "\f0347";
}

.mdi-magnet-on::before {
  content: "\f0348";
}

.mdi-magnify::before {
  content: "\f0349";
}

.mdi-magnify-close::before {
  content: "\f0980";
}

.mdi-magnify-minus::before {
  content: "\f034a";
}

.mdi-magnify-minus-cursor::before {
  content: "\f0a62";
}

.mdi-magnify-minus-outline::before {
  content: "\f06ec";
}

.mdi-magnify-plus::before {
  content: "\f034b";
}

.mdi-magnify-plus-cursor::before {
  content: "\f0a63";
}

.mdi-magnify-plus-outline::before {
  content: "\f06ed";
}

.mdi-magnify-remove-cursor::before {
  content: "\f120c";
}

.mdi-magnify-remove-outline::before {
  content: "\f120d";
}

.mdi-magnify-scan::before {
  content: "\f1276";
}

.mdi-mail::before {
  content: "\f0ebb";
}

.mdi-mailbox::before {
  content: "\f06ee";
}

.mdi-mailbox-open::before {
  content: "\f0d88";
}

.mdi-mailbox-open-outline::before {
  content: "\f0d89";
}

.mdi-mailbox-open-up::before {
  content: "\f0d8a";
}

.mdi-mailbox-open-up-outline::before {
  content: "\f0d8b";
}

.mdi-mailbox-outline::before {
  content: "\f0d8c";
}

.mdi-mailbox-up::before {
  content: "\f0d8d";
}

.mdi-mailbox-up-outline::before {
  content: "\f0d8e";
}

.mdi-manjaro::before {
  content: "\f160a";
}

.mdi-map::before {
  content: "\f034d";
}

.mdi-map-check::before {
  content: "\f0ebc";
}

.mdi-map-check-outline::before {
  content: "\f0ebd";
}

.mdi-map-clock::before {
  content: "\f0d1e";
}

.mdi-map-clock-outline::before {
  content: "\f0d1f";
}

.mdi-map-legend::before {
  content: "\f0a01";
}

.mdi-map-marker::before {
  content: "\f034e";
}

.mdi-map-marker-alert::before {
  content: "\f0f05";
}

.mdi-map-marker-alert-outline::before {
  content: "\f0f06";
}

.mdi-map-marker-check::before {
  content: "\f0c95";
}

.mdi-map-marker-check-outline::before {
  content: "\f12fb";
}

.mdi-map-marker-circle::before {
  content: "\f034f";
}

.mdi-map-marker-distance::before {
  content: "\f08f0";
}

.mdi-map-marker-down::before {
  content: "\f1102";
}

.mdi-map-marker-left::before {
  content: "\f12db";
}

.mdi-map-marker-left-outline::before {
  content: "\f12dd";
}

.mdi-map-marker-minus::before {
  content: "\f0650";
}

.mdi-map-marker-minus-outline::before {
  content: "\f12f9";
}

.mdi-map-marker-multiple::before {
  content: "\f0350";
}

.mdi-map-marker-multiple-outline::before {
  content: "\f1277";
}

.mdi-map-marker-off::before {
  content: "\f0351";
}

.mdi-map-marker-off-outline::before {
  content: "\f12fd";
}

.mdi-map-marker-outline::before {
  content: "\f07d9";
}

.mdi-map-marker-path::before {
  content: "\f0d20";
}

.mdi-map-marker-plus::before {
  content: "\f0651";
}

.mdi-map-marker-plus-outline::before {
  content: "\f12f8";
}

.mdi-map-marker-question::before {
  content: "\f0f07";
}

.mdi-map-marker-question-outline::before {
  content: "\f0f08";
}

.mdi-map-marker-radius::before {
  content: "\f0352";
}

.mdi-map-marker-radius-outline::before {
  content: "\f12fc";
}

.mdi-map-marker-remove::before {
  content: "\f0f09";
}

.mdi-map-marker-remove-outline::before {
  content: "\f12fa";
}

.mdi-map-marker-remove-variant::before {
  content: "\f0f0a";
}

.mdi-map-marker-right::before {
  content: "\f12dc";
}

.mdi-map-marker-right-outline::before {
  content: "\f12de";
}

.mdi-map-marker-star::before {
  content: "\f1608";
}

.mdi-map-marker-star-outline::before {
  content: "\f1609";
}

.mdi-map-marker-up::before {
  content: "\f1103";
}

.mdi-map-minus::before {
  content: "\f0981";
}

.mdi-map-outline::before {
  content: "\f0982";
}

.mdi-map-plus::before {
  content: "\f0983";
}

.mdi-map-search::before {
  content: "\f0984";
}

.mdi-map-search-outline::before {
  content: "\f0985";
}

.mdi-mapbox::before {
  content: "\f0baa";
}

.mdi-margin::before {
  content: "\f0353";
}

.mdi-marker::before {
  content: "\f0652";
}

.mdi-marker-cancel::before {
  content: "\f0dd9";
}

.mdi-marker-check::before {
  content: "\f0355";
}

.mdi-mastodon::before {
  content: "\f0ad1";
}

.mdi-material-design::before {
  content: "\f0986";
}

.mdi-material-ui::before {
  content: "\f0357";
}

.mdi-math-compass::before {
  content: "\f0358";
}

.mdi-math-cos::before {
  content: "\f0c96";
}

.mdi-math-integral::before {
  content: "\f0fc8";
}

.mdi-math-integral-box::before {
  content: "\f0fc9";
}

.mdi-math-log::before {
  content: "\f1085";
}

.mdi-math-norm::before {
  content: "\f0fca";
}

.mdi-math-norm-box::before {
  content: "\f0fcb";
}

.mdi-math-sin::before {
  content: "\f0c97";
}

.mdi-math-tan::before {
  content: "\f0c98";
}

.mdi-matrix::before {
  content: "\f0628";
}

.mdi-medal::before {
  content: "\f0987";
}

.mdi-medal-outline::before {
  content: "\f1326";
}

.mdi-medical-bag::before {
  content: "\f06ef";
}

.mdi-meditation::before {
  content: "\f117b";
}

.mdi-memory::before {
  content: "\f035b";
}

.mdi-menu::before {
  content: "\f035c";
}

.mdi-menu-down::before {
  content: "\f035d";
}

.mdi-menu-down-outline::before {
  content: "\f06b6";
}

.mdi-menu-left::before {
  content: "\f035e";
}

.mdi-menu-left-outline::before {
  content: "\f0a02";
}

.mdi-menu-open::before {
  content: "\f0bab";
}

.mdi-menu-right::before {
  content: "\f035f";
}

.mdi-menu-right-outline::before {
  content: "\f0a03";
}

.mdi-menu-swap::before {
  content: "\f0a64";
}

.mdi-menu-swap-outline::before {
  content: "\f0a65";
}

.mdi-menu-up::before {
  content: "\f0360";
}

.mdi-menu-up-outline::before {
  content: "\f06b7";
}

.mdi-merge::before {
  content: "\f0f5c";
}

.mdi-message::before {
  content: "\f0361";
}

.mdi-message-alert::before {
  content: "\f0362";
}

.mdi-message-alert-outline::before {
  content: "\f0a04";
}

.mdi-message-arrow-left::before {
  content: "\f12f2";
}

.mdi-message-arrow-left-outline::before {
  content: "\f12f3";
}

.mdi-message-arrow-right::before {
  content: "\f12f4";
}

.mdi-message-arrow-right-outline::before {
  content: "\f12f5";
}

.mdi-message-bookmark::before {
  content: "\f15ac";
}

.mdi-message-bookmark-outline::before {
  content: "\f15ad";
}

.mdi-message-bulleted::before {
  content: "\f06a2";
}

.mdi-message-bulleted-off::before {
  content: "\f06a3";
}

.mdi-message-cog::before {
  content: "\f06f1";
}

.mdi-message-cog-outline::before {
  content: "\f1172";
}

.mdi-message-draw::before {
  content: "\f0363";
}

.mdi-message-flash::before {
  content: "\f15a9";
}

.mdi-message-flash-outline::before {
  content: "\f15aa";
}

.mdi-message-image::before {
  content: "\f0364";
}

.mdi-message-image-outline::before {
  content: "\f116c";
}

.mdi-message-lock::before {
  content: "\f0fcc";
}

.mdi-message-lock-outline::before {
  content: "\f116d";
}

.mdi-message-minus::before {
  content: "\f116e";
}

.mdi-message-minus-outline::before {
  content: "\f116f";
}

.mdi-message-off::before {
  content: "\f164d";
}

.mdi-message-off-outline::before {
  content: "\f164e";
}

.mdi-message-outline::before {
  content: "\f0365";
}

.mdi-message-plus::before {
  content: "\f0653";
}

.mdi-message-plus-outline::before {
  content: "\f10bb";
}

.mdi-message-processing::before {
  content: "\f0366";
}

.mdi-message-processing-outline::before {
  content: "\f1170";
}

.mdi-message-question::before {
  content: "\f173a";
}

.mdi-message-question-outline::before {
  content: "\f173b";
}

.mdi-message-reply::before {
  content: "\f0367";
}

.mdi-message-reply-outline::before {
  content: "\f173d";
}

.mdi-message-reply-text::before {
  content: "\f0368";
}

.mdi-message-reply-text-outline::before {
  content: "\f173e";
}

.mdi-message-settings::before {
  content: "\f06f0";
}

.mdi-message-settings-outline::before {
  content: "\f1171";
}

.mdi-message-text::before {
  content: "\f0369";
}

.mdi-message-text-clock::before {
  content: "\f1173";
}

.mdi-message-text-clock-outline::before {
  content: "\f1174";
}

.mdi-message-text-lock::before {
  content: "\f0fcd";
}

.mdi-message-text-lock-outline::before {
  content: "\f1175";
}

.mdi-message-text-outline::before {
  content: "\f036a";
}

.mdi-message-video::before {
  content: "\f036b";
}

.mdi-meteor::before {
  content: "\f0629";
}

.mdi-metronome::before {
  content: "\f07da";
}

.mdi-metronome-tick::before {
  content: "\f07db";
}

.mdi-micro-sd::before {
  content: "\f07dc";
}

.mdi-microphone::before {
  content: "\f036c";
}

.mdi-microphone-minus::before {
  content: "\f08b3";
}

.mdi-microphone-off::before {
  content: "\f036d";
}

.mdi-microphone-outline::before {
  content: "\f036e";
}

.mdi-microphone-plus::before {
  content: "\f08b4";
}

.mdi-microphone-settings::before {
  content: "\f036f";
}

.mdi-microphone-variant::before {
  content: "\f0370";
}

.mdi-microphone-variant-off::before {
  content: "\f0371";
}

.mdi-microscope::before {
  content: "\f0654";
}

.mdi-microsoft::before {
  content: "\f0372";
}

.mdi-microsoft-access::before {
  content: "\f138e";
}

.mdi-microsoft-azure::before {
  content: "\f0805";
}

.mdi-microsoft-azure-devops::before {
  content: "\f0fd5";
}

.mdi-microsoft-bing::before {
  content: "\f00a4";
}

.mdi-microsoft-dynamics-365::before {
  content: "\f0988";
}

.mdi-microsoft-edge::before {
  content: "\f01e9";
}

.mdi-microsoft-edge-legacy::before {
  content: "\f1250";
}

.mdi-microsoft-excel::before {
  content: "\f138f";
}

.mdi-microsoft-internet-explorer::before {
  content: "\f0300";
}

.mdi-microsoft-office::before {
  content: "\f03c6";
}

.mdi-microsoft-onedrive::before {
  content: "\f03ca";
}

.mdi-microsoft-onenote::before {
  content: "\f0747";
}

.mdi-microsoft-outlook::before {
  content: "\f0d22";
}

.mdi-microsoft-powerpoint::before {
  content: "\f1390";
}

.mdi-microsoft-sharepoint::before {
  content: "\f1391";
}

.mdi-microsoft-teams::before {
  content: "\f02bb";
}

.mdi-microsoft-visual-studio::before {
  content: "\f0610";
}

.mdi-microsoft-visual-studio-code::before {
  content: "\f0a1e";
}

.mdi-microsoft-windows::before {
  content: "\f05b3";
}

.mdi-microsoft-windows-classic::before {
  content: "\f0a21";
}

.mdi-microsoft-word::before {
  content: "\f1392";
}

.mdi-microsoft-xbox::before {
  content: "\f05b9";
}

.mdi-microsoft-xbox-controller::before {
  content: "\f05ba";
}

.mdi-microsoft-xbox-controller-battery-alert::before {
  content: "\f074b";
}

.mdi-microsoft-xbox-controller-battery-charging::before {
  content: "\f0a22";
}

.mdi-microsoft-xbox-controller-battery-empty::before {
  content: "\f074c";
}

.mdi-microsoft-xbox-controller-battery-full::before {
  content: "\f074d";
}

.mdi-microsoft-xbox-controller-battery-low::before {
  content: "\f074e";
}

.mdi-microsoft-xbox-controller-battery-medium::before {
  content: "\f074f";
}

.mdi-microsoft-xbox-controller-battery-unknown::before {
  content: "\f0750";
}

.mdi-microsoft-xbox-controller-menu::before {
  content: "\f0e6f";
}

.mdi-microsoft-xbox-controller-off::before {
  content: "\f05bb";
}

.mdi-microsoft-xbox-controller-view::before {
  content: "\f0e70";
}

.mdi-microsoft-yammer::before {
  content: "\f0789";
}

.mdi-microwave::before {
  content: "\f0c99";
}

.mdi-microwave-off::before {
  content: "\f1423";
}

.mdi-middleware::before {
  content: "\f0f5d";
}

.mdi-middleware-outline::before {
  content: "\f0f5e";
}

.mdi-midi::before {
  content: "\f08f1";
}

.mdi-midi-port::before {
  content: "\f08f2";
}

.mdi-mine::before {
  content: "\f0dda";
}

.mdi-minecraft::before {
  content: "\f0373";
}

.mdi-mini-sd::before {
  content: "\f0a05";
}

.mdi-minidisc::before {
  content: "\f0a06";
}

.mdi-minus::before {
  content: "\f0374";
}

.mdi-minus-box::before {
  content: "\f0375";
}

.mdi-minus-box-multiple::before {
  content: "\f1141";
}

.mdi-minus-box-multiple-outline::before {
  content: "\f1142";
}

.mdi-minus-box-outline::before {
  content: "\f06f2";
}

.mdi-minus-circle::before {
  content: "\f0376";
}

.mdi-minus-circle-multiple::before {
  content: "\f035a";
}

.mdi-minus-circle-multiple-outline::before {
  content: "\f0ad3";
}

.mdi-minus-circle-off::before {
  content: "\f1459";
}

.mdi-minus-circle-off-outline::before {
  content: "\f145a";
}

.mdi-minus-circle-outline::before {
  content: "\f0377";
}

.mdi-minus-network::before {
  content: "\f0378";
}

.mdi-minus-network-outline::before {
  content: "\f0c9a";
}

.mdi-minus-thick::before {
  content: "\f1639";
}

.mdi-mirror::before {
  content: "\f11fd";
}

.mdi-mixed-martial-arts::before {
  content: "\f0d8f";
}

.mdi-mixed-reality::before {
  content: "\f087f";
}

.mdi-molecule::before {
  content: "\f0bac";
}

.mdi-molecule-co::before {
  content: "\f12fe";
}

.mdi-molecule-co2::before {
  content: "\f07e4";
}

.mdi-monitor::before {
  content: "\f0379";
}

.mdi-monitor-cellphone::before {
  content: "\f0989";
}

.mdi-monitor-cellphone-star::before {
  content: "\f098a";
}

.mdi-monitor-clean::before {
  content: "\f1104";
}

.mdi-monitor-dashboard::before {
  content: "\f0a07";
}

.mdi-monitor-edit::before {
  content: "\f12c6";
}

.mdi-monitor-eye::before {
  content: "\f13b4";
}

.mdi-monitor-lock::before {
  content: "\f0ddb";
}

.mdi-monitor-multiple::before {
  content: "\f037a";
}

.mdi-monitor-off::before {
  content: "\f0d90";
}

.mdi-monitor-screenshot::before {
  content: "\f0e51";
}

.mdi-monitor-share::before {
  content: "\f1483";
}

.mdi-monitor-speaker::before {
  content: "\f0f5f";
}

.mdi-monitor-speaker-off::before {
  content: "\f0f60";
}

.mdi-monitor-star::before {
  content: "\f0ddc";
}

.mdi-moon-first-quarter::before {
  content: "\f0f61";
}

.mdi-moon-full::before {
  content: "\f0f62";
}

.mdi-moon-last-quarter::before {
  content: "\f0f63";
}

.mdi-moon-new::before {
  content: "\f0f64";
}

.mdi-moon-waning-crescent::before {
  content: "\f0f65";
}

.mdi-moon-waning-gibbous::before {
  content: "\f0f66";
}

.mdi-moon-waxing-crescent::before {
  content: "\f0f67";
}

.mdi-moon-waxing-gibbous::before {
  content: "\f0f68";
}

.mdi-moped::before {
  content: "\f1086";
}

.mdi-moped-electric::before {
  content: "\f15b7";
}

.mdi-moped-electric-outline::before {
  content: "\f15b8";
}

.mdi-moped-outline::before {
  content: "\f15b9";
}

.mdi-more::before {
  content: "\f037b";
}

.mdi-mother-heart::before {
  content: "\f1314";
}

.mdi-mother-nurse::before {
  content: "\f0d21";
}

.mdi-motion::before {
  content: "\f15b2";
}

.mdi-motion-outline::before {
  content: "\f15b3";
}

.mdi-motion-pause::before {
  content: "\f1590";
}

.mdi-motion-pause-outline::before {
  content: "\f1592";
}

.mdi-motion-play::before {
  content: "\f158f";
}

.mdi-motion-play-outline::before {
  content: "\f1591";
}

.mdi-motion-sensor::before {
  content: "\f0d91";
}

.mdi-motion-sensor-off::before {
  content: "\f1435";
}

.mdi-motorbike::before {
  content: "\f037c";
}

.mdi-motorbike-electric::before {
  content: "\f15ba";
}

.mdi-mouse::before {
  content: "\f037d";
}

.mdi-mouse-bluetooth::before {
  content: "\f098b";
}

.mdi-mouse-move-down::before {
  content: "\f1550";
}

.mdi-mouse-move-up::before {
  content: "\f1551";
}

.mdi-mouse-move-vertical::before {
  content: "\f1552";
}

.mdi-mouse-off::before {
  content: "\f037e";
}

.mdi-mouse-variant::before {
  content: "\f037f";
}

.mdi-mouse-variant-off::before {
  content: "\f0380";
}

.mdi-move-resize::before {
  content: "\f0655";
}

.mdi-move-resize-variant::before {
  content: "\f0656";
}

.mdi-movie::before {
  content: "\f0381";
}

.mdi-movie-check::before {
  content: "\f16f3";
}

.mdi-movie-check-outline::before {
  content: "\f16f4";
}

.mdi-movie-cog::before {
  content: "\f16f5";
}

.mdi-movie-cog-outline::before {
  content: "\f16f6";
}

.mdi-movie-edit::before {
  content: "\f1122";
}

.mdi-movie-edit-outline::before {
  content: "\f1123";
}

.mdi-movie-filter::before {
  content: "\f1124";
}

.mdi-movie-filter-outline::before {
  content: "\f1125";
}

.mdi-movie-minus::before {
  content: "\f16f7";
}

.mdi-movie-minus-outline::before {
  content: "\f16f8";
}

.mdi-movie-off::before {
  content: "\f16f9";
}

.mdi-movie-off-outline::before {
  content: "\f16fa";
}

.mdi-movie-open::before {
  content: "\f0fce";
}

.mdi-movie-open-check::before {
  content: "\f16fb";
}

.mdi-movie-open-check-outline::before {
  content: "\f16fc";
}

.mdi-movie-open-cog::before {
  content: "\f16fd";
}

.mdi-movie-open-cog-outline::before {
  content: "\f16fe";
}

.mdi-movie-open-edit::before {
  content: "\f16ff";
}

.mdi-movie-open-edit-outline::before {
  content: "\f1700";
}

.mdi-movie-open-minus::before {
  content: "\f1701";
}

.mdi-movie-open-minus-outline::before {
  content: "\f1702";
}

.mdi-movie-open-off::before {
  content: "\f1703";
}

.mdi-movie-open-off-outline::before {
  content: "\f1704";
}

.mdi-movie-open-outline::before {
  content: "\f0fcf";
}

.mdi-movie-open-play::before {
  content: "\f1705";
}

.mdi-movie-open-play-outline::before {
  content: "\f1706";
}

.mdi-movie-open-plus::before {
  content: "\f1707";
}

.mdi-movie-open-plus-outline::before {
  content: "\f1708";
}

.mdi-movie-open-remove::before {
  content: "\f1709";
}

.mdi-movie-open-remove-outline::before {
  content: "\f170a";
}

.mdi-movie-open-settings::before {
  content: "\f170b";
}

.mdi-movie-open-settings-outline::before {
  content: "\f170c";
}

.mdi-movie-open-star::before {
  content: "\f170d";
}

.mdi-movie-open-star-outline::before {
  content: "\f170e";
}

.mdi-movie-outline::before {
  content: "\f0ddd";
}

.mdi-movie-play::before {
  content: "\f170f";
}

.mdi-movie-play-outline::before {
  content: "\f1710";
}

.mdi-movie-plus::before {
  content: "\f1711";
}

.mdi-movie-plus-outline::before {
  content: "\f1712";
}

.mdi-movie-remove::before {
  content: "\f1713";
}

.mdi-movie-remove-outline::before {
  content: "\f1714";
}

.mdi-movie-roll::before {
  content: "\f07de";
}

.mdi-movie-search::before {
  content: "\f11d2";
}

.mdi-movie-search-outline::before {
  content: "\f11d3";
}

.mdi-movie-settings::before {
  content: "\f1715";
}

.mdi-movie-settings-outline::before {
  content: "\f1716";
}

.mdi-movie-star::before {
  content: "\f1717";
}

.mdi-movie-star-outline::before {
  content: "\f1718";
}

.mdi-mower::before {
  content: "\f166f";
}

.mdi-mower-bag::before {
  content: "\f1670";
}

.mdi-muffin::before {
  content: "\f098c";
}

.mdi-multiplication::before {
  content: "\f0382";
}

.mdi-multiplication-box::before {
  content: "\f0383";
}

.mdi-mushroom::before {
  content: "\f07df";
}

.mdi-mushroom-off::before {
  content: "\f13fa";
}

.mdi-mushroom-off-outline::before {
  content: "\f13fb";
}

.mdi-mushroom-outline::before {
  content: "\f07e0";
}

.mdi-music::before {
  content: "\f075a";
}

.mdi-music-accidental-double-flat::before {
  content: "\f0f69";
}

.mdi-music-accidental-double-sharp::before {
  content: "\f0f6a";
}

.mdi-music-accidental-flat::before {
  content: "\f0f6b";
}

.mdi-music-accidental-natural::before {
  content: "\f0f6c";
}

.mdi-music-accidental-sharp::before {
  content: "\f0f6d";
}

.mdi-music-box::before {
  content: "\f0384";
}

.mdi-music-box-multiple::before {
  content: "\f0333";
}

.mdi-music-box-multiple-outline::before {
  content: "\f0f04";
}

.mdi-music-box-outline::before {
  content: "\f0385";
}

.mdi-music-circle::before {
  content: "\f0386";
}

.mdi-music-circle-outline::before {
  content: "\f0ad4";
}

.mdi-music-clef-alto::before {
  content: "\f0f6e";
}

.mdi-music-clef-bass::before {
  content: "\f0f6f";
}

.mdi-music-clef-treble::before {
  content: "\f0f70";
}

.mdi-music-note::before {
  content: "\f0387";
}

.mdi-music-note-bluetooth::before {
  content: "\f05fe";
}

.mdi-music-note-bluetooth-off::before {
  content: "\f05ff";
}

.mdi-music-note-eighth::before {
  content: "\f0388";
}

.mdi-music-note-eighth-dotted::before {
  content: "\f0f71";
}

.mdi-music-note-half::before {
  content: "\f0389";
}

.mdi-music-note-half-dotted::before {
  content: "\f0f72";
}

.mdi-music-note-off::before {
  content: "\f038a";
}

.mdi-music-note-off-outline::before {
  content: "\f0f73";
}

.mdi-music-note-outline::before {
  content: "\f0f74";
}

.mdi-music-note-plus::before {
  content: "\f0dde";
}

.mdi-music-note-quarter::before {
  content: "\f038b";
}

.mdi-music-note-quarter-dotted::before {
  content: "\f0f75";
}

.mdi-music-note-sixteenth::before {
  content: "\f038c";
}

.mdi-music-note-sixteenth-dotted::before {
  content: "\f0f76";
}

.mdi-music-note-whole::before {
  content: "\f038d";
}

.mdi-music-note-whole-dotted::before {
  content: "\f0f77";
}

.mdi-music-off::before {
  content: "\f075b";
}

.mdi-music-rest-eighth::before {
  content: "\f0f78";
}

.mdi-music-rest-half::before {
  content: "\f0f79";
}

.mdi-music-rest-quarter::before {
  content: "\f0f7a";
}

.mdi-music-rest-sixteenth::before {
  content: "\f0f7b";
}

.mdi-music-rest-whole::before {
  content: "\f0f7c";
}

.mdi-mustache::before {
  content: "\f15de";
}

.mdi-nail::before {
  content: "\f0ddf";
}

.mdi-nas::before {
  content: "\f08f3";
}

.mdi-nativescript::before {
  content: "\f0880";
}

.mdi-nature::before {
  content: "\f038e";
}

.mdi-nature-people::before {
  content: "\f038f";
}

.mdi-navigation::before {
  content: "\f0390";
}

.mdi-navigation-outline::before {
  content: "\f1607";
}

.mdi-near-me::before {
  content: "\f05cd";
}

.mdi-necklace::before {
  content: "\f0f0b";
}

.mdi-needle::before {
  content: "\f0391";
}

.mdi-netflix::before {
  content: "\f0746";
}

.mdi-network::before {
  content: "\f06f3";
}

.mdi-network-off::before {
  content: "\f0c9b";
}

.mdi-network-off-outline::before {
  content: "\f0c9c";
}

.mdi-network-outline::before {
  content: "\f0c9d";
}

.mdi-network-strength-1::before {
  content: "\f08f4";
}

.mdi-network-strength-1-alert::before {
  content: "\f08f5";
}

.mdi-network-strength-2::before {
  content: "\f08f6";
}

.mdi-network-strength-2-alert::before {
  content: "\f08f7";
}

.mdi-network-strength-3::before {
  content: "\f08f8";
}

.mdi-network-strength-3-alert::before {
  content: "\f08f9";
}

.mdi-network-strength-4::before {
  content: "\f08fa";
}

.mdi-network-strength-4-alert::before {
  content: "\f08fb";
}

.mdi-network-strength-off::before {
  content: "\f08fc";
}

.mdi-network-strength-off-outline::before {
  content: "\f08fd";
}

.mdi-network-strength-outline::before {
  content: "\f08fe";
}

.mdi-new-box::before {
  content: "\f0394";
}

.mdi-newspaper::before {
  content: "\f0395";
}

.mdi-newspaper-minus::before {
  content: "\f0f0c";
}

.mdi-newspaper-plus::before {
  content: "\f0f0d";
}

.mdi-newspaper-variant::before {
  content: "\f1001";
}

.mdi-newspaper-variant-multiple::before {
  content: "\f1002";
}

.mdi-newspaper-variant-multiple-outline::before {
  content: "\f1003";
}

.mdi-newspaper-variant-outline::before {
  content: "\f1004";
}

.mdi-nfc::before {
  content: "\f0396";
}

.mdi-nfc-search-variant::before {
  content: "\f0e53";
}

.mdi-nfc-tap::before {
  content: "\f0397";
}

.mdi-nfc-variant::before {
  content: "\f0398";
}

.mdi-nfc-variant-off::before {
  content: "\f0e54";
}

.mdi-ninja::before {
  content: "\f0774";
}

.mdi-nintendo-game-boy::before {
  content: "\f1393";
}

.mdi-nintendo-switch::before {
  content: "\f07e1";
}

.mdi-nintendo-wii::before {
  content: "\f05ab";
}

.mdi-nintendo-wiiu::before {
  content: "\f072d";
}

.mdi-nix::before {
  content: "\f1105";
}

.mdi-nodejs::before {
  content: "\f0399";
}

.mdi-noodles::before {
  content: "\f117e";
}

.mdi-not-equal::before {
  content: "\f098d";
}

.mdi-not-equal-variant::before {
  content: "\f098e";
}

.mdi-note::before {
  content: "\f039a";
}

.mdi-note-minus::before {
  content: "\f164f";
}

.mdi-note-minus-outline::before {
  content: "\f1650";
}

.mdi-note-multiple::before {
  content: "\f06b8";
}

.mdi-note-multiple-outline::before {
  content: "\f06b9";
}

.mdi-note-outline::before {
  content: "\f039b";
}

.mdi-note-plus::before {
  content: "\f039c";
}

.mdi-note-plus-outline::before {
  content: "\f039d";
}

.mdi-note-remove::before {
  content: "\f1651";
}

.mdi-note-remove-outline::before {
  content: "\f1652";
}

.mdi-note-search::before {
  content: "\f1653";
}

.mdi-note-search-outline::before {
  content: "\f1654";
}

.mdi-note-text::before {
  content: "\f039e";
}

.mdi-note-text-outline::before {
  content: "\f11d7";
}

.mdi-notebook::before {
  content: "\f082e";
}

.mdi-notebook-check::before {
  content: "\f14f5";
}

.mdi-notebook-check-outline::before {
  content: "\f14f6";
}

.mdi-notebook-edit::before {
  content: "\f14e7";
}

.mdi-notebook-edit-outline::before {
  content: "\f14e9";
}

.mdi-notebook-minus::before {
  content: "\f1610";
}

.mdi-notebook-minus-outline::before {
  content: "\f1611";
}

.mdi-notebook-multiple::before {
  content: "\f0e55";
}

.mdi-notebook-outline::before {
  content: "\f0ebf";
}

.mdi-notebook-plus::before {
  content: "\f1612";
}

.mdi-notebook-plus-outline::before {
  content: "\f1613";
}

.mdi-notebook-remove::before {
  content: "\f1614";
}

.mdi-notebook-remove-outline::before {
  content: "\f1615";
}

.mdi-notification-clear-all::before {
  content: "\f039f";
}

.mdi-npm::before {
  content: "\f06f7";
}

.mdi-nuke::before {
  content: "\f06a4";
}

.mdi-null::before {
  content: "\f07e2";
}

.mdi-numeric::before {
  content: "\f03a0";
}

.mdi-numeric-0::before {
  content: "\f0b39";
}

.mdi-numeric-0-box::before {
  content: "\f03a1";
}

.mdi-numeric-0-box-multiple::before {
  content: "\f0f0e";
}

.mdi-numeric-0-box-multiple-outline::before {
  content: "\f03a2";
}

.mdi-numeric-0-box-outline::before {
  content: "\f03a3";
}

.mdi-numeric-0-circle::before {
  content: "\f0c9e";
}

.mdi-numeric-0-circle-outline::before {
  content: "\f0c9f";
}

.mdi-numeric-1::before {
  content: "\f0b3a";
}

.mdi-numeric-1-box::before {
  content: "\f03a4";
}

.mdi-numeric-1-box-multiple::before {
  content: "\f0f0f";
}

.mdi-numeric-1-box-multiple-outline::before {
  content: "\f03a5";
}

.mdi-numeric-1-box-outline::before {
  content: "\f03a6";
}

.mdi-numeric-1-circle::before {
  content: "\f0ca0";
}

.mdi-numeric-1-circle-outline::before {
  content: "\f0ca1";
}

.mdi-numeric-10::before {
  content: "\f0fe9";
}

.mdi-numeric-10-box::before {
  content: "\f0f7d";
}

.mdi-numeric-10-box-multiple::before {
  content: "\f0fea";
}

.mdi-numeric-10-box-multiple-outline::before {
  content: "\f0feb";
}

.mdi-numeric-10-box-outline::before {
  content: "\f0f7e";
}

.mdi-numeric-10-circle::before {
  content: "\f0fec";
}

.mdi-numeric-10-circle-outline::before {
  content: "\f0fed";
}

.mdi-numeric-2::before {
  content: "\f0b3b";
}

.mdi-numeric-2-box::before {
  content: "\f03a7";
}

.mdi-numeric-2-box-multiple::before {
  content: "\f0f10";
}

.mdi-numeric-2-box-multiple-outline::before {
  content: "\f03a8";
}

.mdi-numeric-2-box-outline::before {
  content: "\f03a9";
}

.mdi-numeric-2-circle::before {
  content: "\f0ca2";
}

.mdi-numeric-2-circle-outline::before {
  content: "\f0ca3";
}

.mdi-numeric-3::before {
  content: "\f0b3c";
}

.mdi-numeric-3-box::before {
  content: "\f03aa";
}

.mdi-numeric-3-box-multiple::before {
  content: "\f0f11";
}

.mdi-numeric-3-box-multiple-outline::before {
  content: "\f03ab";
}

.mdi-numeric-3-box-outline::before {
  content: "\f03ac";
}

.mdi-numeric-3-circle::before {
  content: "\f0ca4";
}

.mdi-numeric-3-circle-outline::before {
  content: "\f0ca5";
}

.mdi-numeric-4::before {
  content: "\f0b3d";
}

.mdi-numeric-4-box::before {
  content: "\f03ad";
}

.mdi-numeric-4-box-multiple::before {
  content: "\f0f12";
}

.mdi-numeric-4-box-multiple-outline::before {
  content: "\f03b2";
}

.mdi-numeric-4-box-outline::before {
  content: "\f03ae";
}

.mdi-numeric-4-circle::before {
  content: "\f0ca6";
}

.mdi-numeric-4-circle-outline::before {
  content: "\f0ca7";
}

.mdi-numeric-5::before {
  content: "\f0b3e";
}

.mdi-numeric-5-box::before {
  content: "\f03b1";
}

.mdi-numeric-5-box-multiple::before {
  content: "\f0f13";
}

.mdi-numeric-5-box-multiple-outline::before {
  content: "\f03af";
}

.mdi-numeric-5-box-outline::before {
  content: "\f03b0";
}

.mdi-numeric-5-circle::before {
  content: "\f0ca8";
}

.mdi-numeric-5-circle-outline::before {
  content: "\f0ca9";
}

.mdi-numeric-6::before {
  content: "\f0b3f";
}

.mdi-numeric-6-box::before {
  content: "\f03b3";
}

.mdi-numeric-6-box-multiple::before {
  content: "\f0f14";
}

.mdi-numeric-6-box-multiple-outline::before {
  content: "\f03b4";
}

.mdi-numeric-6-box-outline::before {
  content: "\f03b5";
}

.mdi-numeric-6-circle::before {
  content: "\f0caa";
}

.mdi-numeric-6-circle-outline::before {
  content: "\f0cab";
}

.mdi-numeric-7::before {
  content: "\f0b40";
}

.mdi-numeric-7-box::before {
  content: "\f03b6";
}

.mdi-numeric-7-box-multiple::before {
  content: "\f0f15";
}

.mdi-numeric-7-box-multiple-outline::before {
  content: "\f03b7";
}

.mdi-numeric-7-box-outline::before {
  content: "\f03b8";
}

.mdi-numeric-7-circle::before {
  content: "\f0cac";
}

.mdi-numeric-7-circle-outline::before {
  content: "\f0cad";
}

.mdi-numeric-8::before {
  content: "\f0b41";
}

.mdi-numeric-8-box::before {
  content: "\f03b9";
}

.mdi-numeric-8-box-multiple::before {
  content: "\f0f16";
}

.mdi-numeric-8-box-multiple-outline::before {
  content: "\f03ba";
}

.mdi-numeric-8-box-outline::before {
  content: "\f03bb";
}

.mdi-numeric-8-circle::before {
  content: "\f0cae";
}

.mdi-numeric-8-circle-outline::before {
  content: "\f0caf";
}

.mdi-numeric-9::before {
  content: "\f0b42";
}

.mdi-numeric-9-box::before {
  content: "\f03bc";
}

.mdi-numeric-9-box-multiple::before {
  content: "\f0f17";
}

.mdi-numeric-9-box-multiple-outline::before {
  content: "\f03bd";
}

.mdi-numeric-9-box-outline::before {
  content: "\f03be";
}

.mdi-numeric-9-circle::before {
  content: "\f0cb0";
}

.mdi-numeric-9-circle-outline::before {
  content: "\f0cb1";
}

.mdi-numeric-9-plus::before {
  content: "\f0fee";
}

.mdi-numeric-9-plus-box::before {
  content: "\f03bf";
}

.mdi-numeric-9-plus-box-multiple::before {
  content: "\f0f18";
}

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\f03c0";
}

.mdi-numeric-9-plus-box-outline::before {
  content: "\f03c1";
}

.mdi-numeric-9-plus-circle::before {
  content: "\f0cb2";
}

.mdi-numeric-9-plus-circle-outline::before {
  content: "\f0cb3";
}

.mdi-numeric-negative-1::before {
  content: "\f1052";
}

.mdi-numeric-positive-1::before {
  content: "\f15cb";
}

.mdi-nut::before {
  content: "\f06f8";
}

.mdi-nutrition::before {
  content: "\f03c2";
}

.mdi-nuxt::before {
  content: "\f1106";
}

.mdi-oar::before {
  content: "\f067c";
}

.mdi-ocarina::before {
  content: "\f0de0";
}

.mdi-oci::before {
  content: "\f12e9";
}

.mdi-ocr::before {
  content: "\f113a";
}

.mdi-octagon::before {
  content: "\f03c3";
}

.mdi-octagon-outline::before {
  content: "\f03c4";
}

.mdi-octagram::before {
  content: "\f06f9";
}

.mdi-octagram-outline::before {
  content: "\f0775";
}

.mdi-odnoklassniki::before {
  content: "\f03c5";
}

.mdi-offer::before {
  content: "\f121b";
}

.mdi-office-building::before {
  content: "\f0991";
}

.mdi-office-building-marker::before {
  content: "\f1520";
}

.mdi-office-building-marker-outline::before {
  content: "\f1521";
}

.mdi-office-building-outline::before {
  content: "\f151f";
}

.mdi-oil::before {
  content: "\f03c7";
}

.mdi-oil-lamp::before {
  content: "\f0f19";
}

.mdi-oil-level::before {
  content: "\f1053";
}

.mdi-oil-temperature::before {
  content: "\f0ff8";
}

.mdi-omega::before {
  content: "\f03c9";
}

.mdi-one-up::before {
  content: "\f0bad";
}

.mdi-onepassword::before {
  content: "\f0881";
}

.mdi-opacity::before {
  content: "\f05cc";
}

.mdi-open-in-app::before {
  content: "\f03cb";
}

.mdi-open-in-new::before {
  content: "\f03cc";
}

.mdi-open-source-initiative::before {
  content: "\f0bae";
}

.mdi-openid::before {
  content: "\f03cd";
}

.mdi-opera::before {
  content: "\f03ce";
}

.mdi-orbit::before {
  content: "\f0018";
}

.mdi-orbit-variant::before {
  content: "\f15db";
}

.mdi-order-alphabetical-ascending::before {
  content: "\f020d";
}

.mdi-order-alphabetical-descending::before {
  content: "\f0d07";
}

.mdi-order-bool-ascending::before {
  content: "\f02be";
}

.mdi-order-bool-ascending-variant::before {
  content: "\f098f";
}

.mdi-order-bool-descending::before {
  content: "\f1384";
}

.mdi-order-bool-descending-variant::before {
  content: "\f0990";
}

.mdi-order-numeric-ascending::before {
  content: "\f0545";
}

.mdi-order-numeric-descending::before {
  content: "\f0546";
}

.mdi-origin::before {
  content: "\f0b43";
}

.mdi-ornament::before {
  content: "\f03cf";
}

.mdi-ornament-variant::before {
  content: "\f03d0";
}

.mdi-outdoor-lamp::before {
  content: "\f1054";
}

.mdi-overscan::before {
  content: "\f1005";
}

.mdi-owl::before {
  content: "\f03d2";
}

.mdi-pac-man::before {
  content: "\f0baf";
}

.mdi-package::before {
  content: "\f03d3";
}

.mdi-package-down::before {
  content: "\f03d4";
}

.mdi-package-up::before {
  content: "\f03d5";
}

.mdi-package-variant::before {
  content: "\f03d6";
}

.mdi-package-variant-closed::before {
  content: "\f03d7";
}

.mdi-page-first::before {
  content: "\f0600";
}

.mdi-page-last::before {
  content: "\f0601";
}

.mdi-page-layout-body::before {
  content: "\f06fa";
}

.mdi-page-layout-footer::before {
  content: "\f06fb";
}

.mdi-page-layout-header::before {
  content: "\f06fc";
}

.mdi-page-layout-header-footer::before {
  content: "\f0f7f";
}

.mdi-page-layout-sidebar-left::before {
  content: "\f06fd";
}

.mdi-page-layout-sidebar-right::before {
  content: "\f06fe";
}

.mdi-page-next::before {
  content: "\f0bb0";
}

.mdi-page-next-outline::before {
  content: "\f0bb1";
}

.mdi-page-previous::before {
  content: "\f0bb2";
}

.mdi-page-previous-outline::before {
  content: "\f0bb3";
}

.mdi-pail::before {
  content: "\f1417";
}

.mdi-pail-minus::before {
  content: "\f1437";
}

.mdi-pail-minus-outline::before {
  content: "\f143c";
}

.mdi-pail-off::before {
  content: "\f1439";
}

.mdi-pail-off-outline::before {
  content: "\f143e";
}

.mdi-pail-outline::before {
  content: "\f143a";
}

.mdi-pail-plus::before {
  content: "\f1436";
}

.mdi-pail-plus-outline::before {
  content: "\f143b";
}

.mdi-pail-remove::before {
  content: "\f1438";
}

.mdi-pail-remove-outline::before {
  content: "\f143d";
}

.mdi-palette::before {
  content: "\f03d8";
}

.mdi-palette-advanced::before {
  content: "\f03d9";
}

.mdi-palette-outline::before {
  content: "\f0e0c";
}

.mdi-palette-swatch::before {
  content: "\f08b5";
}

.mdi-palette-swatch-outline::before {
  content: "\f135c";
}

.mdi-palm-tree::before {
  content: "\f1055";
}

.mdi-pan::before {
  content: "\f0bb4";
}

.mdi-pan-bottom-left::before {
  content: "\f0bb5";
}

.mdi-pan-bottom-right::before {
  content: "\f0bb6";
}

.mdi-pan-down::before {
  content: "\f0bb7";
}

.mdi-pan-horizontal::before {
  content: "\f0bb8";
}

.mdi-pan-left::before {
  content: "\f0bb9";
}

.mdi-pan-right::before {
  content: "\f0bba";
}

.mdi-pan-top-left::before {
  content: "\f0bbb";
}

.mdi-pan-top-right::before {
  content: "\f0bbc";
}

.mdi-pan-up::before {
  content: "\f0bbd";
}

.mdi-pan-vertical::before {
  content: "\f0bbe";
}

.mdi-panda::before {
  content: "\f03da";
}

.mdi-pandora::before {
  content: "\f03db";
}

.mdi-panorama::before {
  content: "\f03dc";
}

.mdi-panorama-fisheye::before {
  content: "\f03dd";
}

.mdi-panorama-horizontal::before {
  content: "\f03de";
}

.mdi-panorama-vertical::before {
  content: "\f03df";
}

.mdi-panorama-wide-angle::before {
  content: "\f03e0";
}

.mdi-paper-cut-vertical::before {
  content: "\f03e1";
}

.mdi-paper-roll::before {
  content: "\f1157";
}

.mdi-paper-roll-outline::before {
  content: "\f1158";
}

.mdi-paperclip::before {
  content: "\f03e2";
}

.mdi-parachute::before {
  content: "\f0cb4";
}

.mdi-parachute-outline::before {
  content: "\f0cb5";
}

.mdi-parking::before {
  content: "\f03e3";
}

.mdi-party-popper::before {
  content: "\f1056";
}

.mdi-passport::before {
  content: "\f07e3";
}

.mdi-passport-biometric::before {
  content: "\f0de1";
}

.mdi-pasta::before {
  content: "\f1160";
}

.mdi-patio-heater::before {
  content: "\f0f80";
}

.mdi-patreon::before {
  content: "\f0882";
}

.mdi-pause::before {
  content: "\f03e4";
}

.mdi-pause-circle::before {
  content: "\f03e5";
}

.mdi-pause-circle-outline::before {
  content: "\f03e6";
}

.mdi-pause-octagon::before {
  content: "\f03e7";
}

.mdi-pause-octagon-outline::before {
  content: "\f03e8";
}

.mdi-paw::before {
  content: "\f03e9";
}

.mdi-paw-off::before {
  content: "\f0657";
}

.mdi-paw-off-outline::before {
  content: "\f1676";
}

.mdi-paw-outline::before {
  content: "\f1675";
}

.mdi-pdf-box::before {
  content: "\f0e56";
}

.mdi-peace::before {
  content: "\f0884";
}

.mdi-peanut::before {
  content: "\f0ffc";
}

.mdi-peanut-off::before {
  content: "\f0ffd";
}

.mdi-peanut-off-outline::before {
  content: "\f0fff";
}

.mdi-peanut-outline::before {
  content: "\f0ffe";
}

.mdi-pen::before {
  content: "\f03ea";
}

.mdi-pen-lock::before {
  content: "\f0de2";
}

.mdi-pen-minus::before {
  content: "\f0de3";
}

.mdi-pen-off::before {
  content: "\f0de4";
}

.mdi-pen-plus::before {
  content: "\f0de5";
}

.mdi-pen-remove::before {
  content: "\f0de6";
}

.mdi-pencil::before {
  content: "\f03eb";
}

.mdi-pencil-box::before {
  content: "\f03ec";
}

.mdi-pencil-box-multiple::before {
  content: "\f1144";
}

.mdi-pencil-box-multiple-outline::before {
  content: "\f1145";
}

.mdi-pencil-box-outline::before {
  content: "\f03ed";
}

.mdi-pencil-circle::before {
  content: "\f06ff";
}

.mdi-pencil-circle-outline::before {
  content: "\f0776";
}

.mdi-pencil-lock::before {
  content: "\f03ee";
}

.mdi-pencil-lock-outline::before {
  content: "\f0de7";
}

.mdi-pencil-minus::before {
  content: "\f0de8";
}

.mdi-pencil-minus-outline::before {
  content: "\f0de9";
}

.mdi-pencil-off::before {
  content: "\f03ef";
}

.mdi-pencil-off-outline::before {
  content: "\f0dea";
}

.mdi-pencil-outline::before {
  content: "\f0cb6";
}

.mdi-pencil-plus::before {
  content: "\f0deb";
}

.mdi-pencil-plus-outline::before {
  content: "\f0dec";
}

.mdi-pencil-remove::before {
  content: "\f0ded";
}

.mdi-pencil-remove-outline::before {
  content: "\f0dee";
}

.mdi-pencil-ruler::before {
  content: "\f1353";
}

.mdi-penguin::before {
  content: "\f0ec0";
}

.mdi-pentagon::before {
  content: "\f0701";
}

.mdi-pentagon-outline::before {
  content: "\f0700";
}

.mdi-pentagram::before {
  content: "\f1667";
}

.mdi-percent::before {
  content: "\f03f0";
}

.mdi-percent-outline::before {
  content: "\f1278";
}

.mdi-periodic-table::before {
  content: "\f08b6";
}

.mdi-perspective-less::before {
  content: "\f0d23";
}

.mdi-perspective-more::before {
  content: "\f0d24";
}

.mdi-pharmacy::before {
  content: "\f03f1";
}

.mdi-phone::before {
  content: "\f03f2";
}

.mdi-phone-alert::before {
  content: "\f0f1a";
}

.mdi-phone-alert-outline::before {
  content: "\f118e";
}

.mdi-phone-bluetooth::before {
  content: "\f03f3";
}

.mdi-phone-bluetooth-outline::before {
  content: "\f118f";
}

.mdi-phone-cancel::before {
  content: "\f10bc";
}

.mdi-phone-cancel-outline::before {
  content: "\f1190";
}

.mdi-phone-check::before {
  content: "\f11a9";
}

.mdi-phone-check-outline::before {
  content: "\f11aa";
}

.mdi-phone-classic::before {
  content: "\f0602";
}

.mdi-phone-classic-off::before {
  content: "\f1279";
}

.mdi-phone-dial::before {
  content: "\f1559";
}

.mdi-phone-dial-outline::before {
  content: "\f155a";
}

.mdi-phone-forward::before {
  content: "\f03f4";
}

.mdi-phone-forward-outline::before {
  content: "\f1191";
}

.mdi-phone-hangup::before {
  content: "\f03f5";
}

.mdi-phone-hangup-outline::before {
  content: "\f1192";
}

.mdi-phone-in-talk::before {
  content: "\f03f6";
}

.mdi-phone-in-talk-outline::before {
  content: "\f1182";
}

.mdi-phone-incoming::before {
  content: "\f03f7";
}

.mdi-phone-incoming-outline::before {
  content: "\f1193";
}

.mdi-phone-lock::before {
  content: "\f03f8";
}

.mdi-phone-lock-outline::before {
  content: "\f1194";
}

.mdi-phone-log::before {
  content: "\f03f9";
}

.mdi-phone-log-outline::before {
  content: "\f1195";
}

.mdi-phone-message::before {
  content: "\f1196";
}

.mdi-phone-message-outline::before {
  content: "\f1197";
}

.mdi-phone-minus::before {
  content: "\f0658";
}

.mdi-phone-minus-outline::before {
  content: "\f1198";
}

.mdi-phone-missed::before {
  content: "\f03fa";
}

.mdi-phone-missed-outline::before {
  content: "\f11a5";
}

.mdi-phone-off::before {
  content: "\f0def";
}

.mdi-phone-off-outline::before {
  content: "\f11a6";
}

.mdi-phone-outgoing::before {
  content: "\f03fb";
}

.mdi-phone-outgoing-outline::before {
  content: "\f1199";
}

.mdi-phone-outline::before {
  content: "\f0df0";
}

.mdi-phone-paused::before {
  content: "\f03fc";
}

.mdi-phone-paused-outline::before {
  content: "\f119a";
}

.mdi-phone-plus::before {
  content: "\f0659";
}

.mdi-phone-plus-outline::before {
  content: "\f119b";
}

.mdi-phone-remove::before {
  content: "\f152f";
}

.mdi-phone-remove-outline::before {
  content: "\f1530";
}

.mdi-phone-return::before {
  content: "\f082f";
}

.mdi-phone-return-outline::before {
  content: "\f119c";
}

.mdi-phone-ring::before {
  content: "\f11ab";
}

.mdi-phone-ring-outline::before {
  content: "\f11ac";
}

.mdi-phone-rotate-landscape::before {
  content: "\f0885";
}

.mdi-phone-rotate-portrait::before {
  content: "\f0886";
}

.mdi-phone-settings::before {
  content: "\f03fd";
}

.mdi-phone-settings-outline::before {
  content: "\f119d";
}

.mdi-phone-voip::before {
  content: "\f03fe";
}

.mdi-pi::before {
  content: "\f03ff";
}

.mdi-pi-box::before {
  content: "\f0400";
}

.mdi-pi-hole::before {
  content: "\f0df1";
}

.mdi-piano::before {
  content: "\f067d";
}

.mdi-pickaxe::before {
  content: "\f08b7";
}

.mdi-picture-in-picture-bottom-right::before {
  content: "\f0e57";
}

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\f0e58";
}

.mdi-picture-in-picture-top-right::before {
  content: "\f0e59";
}

.mdi-picture-in-picture-top-right-outline::before {
  content: "\f0e5a";
}

.mdi-pier::before {
  content: "\f0887";
}

.mdi-pier-crane::before {
  content: "\f0888";
}

.mdi-pig::before {
  content: "\f0401";
}

.mdi-pig-variant::before {
  content: "\f1006";
}

.mdi-pig-variant-outline::before {
  content: "\f1678";
}

.mdi-piggy-bank::before {
  content: "\f1007";
}

.mdi-piggy-bank-outline::before {
  content: "\f1679";
}

.mdi-pill::before {
  content: "\f0402";
}

.mdi-pillar::before {
  content: "\f0702";
}

.mdi-pin::before {
  content: "\f0403";
}

.mdi-pin-off::before {
  content: "\f0404";
}

.mdi-pin-off-outline::before {
  content: "\f0930";
}

.mdi-pin-outline::before {
  content: "\f0931";
}

.mdi-pine-tree::before {
  content: "\f0405";
}

.mdi-pine-tree-box::before {
  content: "\f0406";
}

.mdi-pine-tree-fire::before {
  content: "\f141a";
}

.mdi-pinterest::before {
  content: "\f0407";
}

.mdi-pinwheel::before {
  content: "\f0ad5";
}

.mdi-pinwheel-outline::before {
  content: "\f0ad6";
}

.mdi-pipe::before {
  content: "\f07e5";
}

.mdi-pipe-disconnected::before {
  content: "\f07e6";
}

.mdi-pipe-leak::before {
  content: "\f0889";
}

.mdi-pipe-wrench::before {
  content: "\f1354";
}

.mdi-pirate::before {
  content: "\f0a08";
}

.mdi-pistol::before {
  content: "\f0703";
}

.mdi-piston::before {
  content: "\f088a";
}

.mdi-pitchfork::before {
  content: "\f1553";
}

.mdi-pizza::before {
  content: "\f0409";
}

.mdi-play::before {
  content: "\f040a";
}

.mdi-play-box::before {
  content: "\f127a";
}

.mdi-play-box-multiple::before {
  content: "\f0d19";
}

.mdi-play-box-multiple-outline::before {
  content: "\f13e6";
}

.mdi-play-box-outline::before {
  content: "\f040b";
}

.mdi-play-circle::before {
  content: "\f040c";
}

.mdi-play-circle-outline::before {
  content: "\f040d";
}

.mdi-play-network::before {
  content: "\f088b";
}

.mdi-play-network-outline::before {
  content: "\f0cb7";
}

.mdi-play-outline::before {
  content: "\f0f1b";
}

.mdi-play-pause::before {
  content: "\f040e";
}

.mdi-play-protected-content::before {
  content: "\f040f";
}

.mdi-play-speed::before {
  content: "\f08ff";
}

.mdi-playlist-check::before {
  content: "\f05c7";
}

.mdi-playlist-edit::before {
  content: "\f0900";
}

.mdi-playlist-minus::before {
  content: "\f0410";
}

.mdi-playlist-music::before {
  content: "\f0cb8";
}

.mdi-playlist-music-outline::before {
  content: "\f0cb9";
}

.mdi-playlist-play::before {
  content: "\f0411";
}

.mdi-playlist-plus::before {
  content: "\f0412";
}

.mdi-playlist-remove::before {
  content: "\f0413";
}

.mdi-playlist-star::before {
  content: "\f0df2";
}

.mdi-plex::before {
  content: "\f06ba";
}

.mdi-plus::before {
  content: "\f0415";
}

.mdi-plus-box::before {
  content: "\f0416";
}

.mdi-plus-box-multiple::before {
  content: "\f0334";
}

.mdi-plus-box-multiple-outline::before {
  content: "\f1143";
}

.mdi-plus-box-outline::before {
  content: "\f0704";
}

.mdi-plus-circle::before {
  content: "\f0417";
}

.mdi-plus-circle-multiple::before {
  content: "\f034c";
}

.mdi-plus-circle-multiple-outline::before {
  content: "\f0418";
}

.mdi-plus-circle-outline::before {
  content: "\f0419";
}

.mdi-plus-minus::before {
  content: "\f0992";
}

.mdi-plus-minus-box::before {
  content: "\f0993";
}

.mdi-plus-minus-variant::before {
  content: "\f14c9";
}

.mdi-plus-network::before {
  content: "\f041a";
}

.mdi-plus-network-outline::before {
  content: "\f0cba";
}

.mdi-plus-one::before {
  content: "\f041b";
}

.mdi-plus-outline::before {
  content: "\f0705";
}

.mdi-plus-thick::before {
  content: "\f11ec";
}

.mdi-podcast::before {
  content: "\f0994";
}

.mdi-podium::before {
  content: "\f0d25";
}

.mdi-podium-bronze::before {
  content: "\f0d26";
}

.mdi-podium-gold::before {
  content: "\f0d27";
}

.mdi-podium-silver::before {
  content: "\f0d28";
}

.mdi-point-of-sale::before {
  content: "\f0d92";
}

.mdi-pokeball::before {
  content: "\f041d";
}

.mdi-pokemon-go::before {
  content: "\f0a09";
}

.mdi-poker-chip::before {
  content: "\f0830";
}

.mdi-polaroid::before {
  content: "\f041e";
}

.mdi-police-badge::before {
  content: "\f1167";
}

.mdi-police-badge-outline::before {
  content: "\f1168";
}

.mdi-poll::before {
  content: "\f041f";
}

.mdi-poll-box::before {
  content: "\f0420";
}

.mdi-poll-box-outline::before {
  content: "\f127b";
}

.mdi-polo::before {
  content: "\f14c3";
}

.mdi-polymer::before {
  content: "\f0421";
}

.mdi-pool::before {
  content: "\f0606";
}

.mdi-popcorn::before {
  content: "\f0422";
}

.mdi-post::before {
  content: "\f1008";
}

.mdi-post-outline::before {
  content: "\f1009";
}

.mdi-postage-stamp::before {
  content: "\f0cbb";
}

.mdi-pot::before {
  content: "\f02e5";
}

.mdi-pot-mix::before {
  content: "\f065b";
}

.mdi-pot-mix-outline::before {
  content: "\f0677";
}

.mdi-pot-outline::before {
  content: "\f02ff";
}

.mdi-pot-steam::before {
  content: "\f065a";
}

.mdi-pot-steam-outline::before {
  content: "\f0326";
}

.mdi-pound::before {
  content: "\f0423";
}

.mdi-pound-box::before {
  content: "\f0424";
}

.mdi-pound-box-outline::before {
  content: "\f117f";
}

.mdi-power::before {
  content: "\f0425";
}

.mdi-power-cycle::before {
  content: "\f0901";
}

.mdi-power-off::before {
  content: "\f0902";
}

.mdi-power-on::before {
  content: "\f0903";
}

.mdi-power-plug::before {
  content: "\f06a5";
}

.mdi-power-plug-off::before {
  content: "\f06a6";
}

.mdi-power-plug-off-outline::before {
  content: "\f1424";
}

.mdi-power-plug-outline::before {
  content: "\f1425";
}

.mdi-power-settings::before {
  content: "\f0426";
}

.mdi-power-sleep::before {
  content: "\f0904";
}

.mdi-power-socket::before {
  content: "\f0427";
}

.mdi-power-socket-au::before {
  content: "\f0905";
}

.mdi-power-socket-de::before {
  content: "\f1107";
}

.mdi-power-socket-eu::before {
  content: "\f07e7";
}

.mdi-power-socket-fr::before {
  content: "\f1108";
}

.mdi-power-socket-it::before {
  content: "\f14ff";
}

.mdi-power-socket-jp::before {
  content: "\f1109";
}

.mdi-power-socket-uk::before {
  content: "\f07e8";
}

.mdi-power-socket-us::before {
  content: "\f07e9";
}

.mdi-power-standby::before {
  content: "\f0906";
}

.mdi-powershell::before {
  content: "\f0a0a";
}

.mdi-prescription::before {
  content: "\f0706";
}

.mdi-presentation::before {
  content: "\f0428";
}

.mdi-presentation-play::before {
  content: "\f0429";
}

.mdi-pretzel::before {
  content: "\f1562";
}

.mdi-printer::before {
  content: "\f042a";
}

.mdi-printer-3d::before {
  content: "\f042b";
}

.mdi-printer-3d-nozzle::before {
  content: "\f0e5b";
}

.mdi-printer-3d-nozzle-alert::before {
  content: "\f11c0";
}

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\f11c1";
}

.mdi-printer-3d-nozzle-outline::before {
  content: "\f0e5c";
}

.mdi-printer-alert::before {
  content: "\f042c";
}

.mdi-printer-check::before {
  content: "\f1146";
}

.mdi-printer-eye::before {
  content: "\f1458";
}

.mdi-printer-off::before {
  content: "\f0e5d";
}

.mdi-printer-pos::before {
  content: "\f1057";
}

.mdi-printer-search::before {
  content: "\f1457";
}

.mdi-printer-settings::before {
  content: "\f0707";
}

.mdi-printer-wireless::before {
  content: "\f0a0b";
}

.mdi-priority-high::before {
  content: "\f0603";
}

.mdi-priority-low::before {
  content: "\f0604";
}

.mdi-professional-hexagon::before {
  content: "\f042d";
}

.mdi-progress-alert::before {
  content: "\f0cbc";
}

.mdi-progress-check::before {
  content: "\f0995";
}

.mdi-progress-clock::before {
  content: "\f0996";
}

.mdi-progress-close::before {
  content: "\f110a";
}

.mdi-progress-download::before {
  content: "\f0997";
}

.mdi-progress-question::before {
  content: "\f1522";
}

.mdi-progress-upload::before {
  content: "\f0998";
}

.mdi-progress-wrench::before {
  content: "\f0cbd";
}

.mdi-projector::before {
  content: "\f042e";
}

.mdi-projector-screen::before {
  content: "\f042f";
}

.mdi-projector-screen-outline::before {
  content: "\f1724";
}

.mdi-propane-tank::before {
  content: "\f1357";
}

.mdi-propane-tank-outline::before {
  content: "\f1358";
}

.mdi-protocol::before {
  content: "\f0fd8";
}

.mdi-publish::before {
  content: "\f06a7";
}

.mdi-pulse::before {
  content: "\f0430";
}

.mdi-pump::before {
  content: "\f1402";
}

.mdi-pumpkin::before {
  content: "\f0bbf";
}

.mdi-purse::before {
  content: "\f0f1c";
}

.mdi-purse-outline::before {
  content: "\f0f1d";
}

.mdi-puzzle::before {
  content: "\f0431";
}

.mdi-puzzle-check::before {
  content: "\f1426";
}

.mdi-puzzle-check-outline::before {
  content: "\f1427";
}

.mdi-puzzle-edit::before {
  content: "\f14d3";
}

.mdi-puzzle-edit-outline::before {
  content: "\f14d9";
}

.mdi-puzzle-heart::before {
  content: "\f14d4";
}

.mdi-puzzle-heart-outline::before {
  content: "\f14da";
}

.mdi-puzzle-minus::before {
  content: "\f14d1";
}

.mdi-puzzle-minus-outline::before {
  content: "\f14d7";
}

.mdi-puzzle-outline::before {
  content: "\f0a66";
}

.mdi-puzzle-plus::before {
  content: "\f14d0";
}

.mdi-puzzle-plus-outline::before {
  content: "\f14d6";
}

.mdi-puzzle-remove::before {
  content: "\f14d2";
}

.mdi-puzzle-remove-outline::before {
  content: "\f14d8";
}

.mdi-puzzle-star::before {
  content: "\f14d5";
}

.mdi-puzzle-star-outline::before {
  content: "\f14db";
}

.mdi-qi::before {
  content: "\f0999";
}

.mdi-qqchat::before {
  content: "\f0605";
}

.mdi-qrcode::before {
  content: "\f0432";
}

.mdi-qrcode-edit::before {
  content: "\f08b8";
}

.mdi-qrcode-minus::before {
  content: "\f118c";
}

.mdi-qrcode-plus::before {
  content: "\f118b";
}

.mdi-qrcode-remove::before {
  content: "\f118d";
}

.mdi-qrcode-scan::before {
  content: "\f0433";
}

.mdi-quadcopter::before {
  content: "\f0434";
}

.mdi-quality-high::before {
  content: "\f0435";
}

.mdi-quality-low::before {
  content: "\f0a0c";
}

.mdi-quality-medium::before {
  content: "\f0a0d";
}

.mdi-quora::before {
  content: "\f0d29";
}

.mdi-rabbit::before {
  content: "\f0907";
}

.mdi-racing-helmet::before {
  content: "\f0d93";
}

.mdi-racquetball::before {
  content: "\f0d94";
}

.mdi-radar::before {
  content: "\f0437";
}

.mdi-radiator::before {
  content: "\f0438";
}

.mdi-radiator-disabled::before {
  content: "\f0ad7";
}

.mdi-radiator-off::before {
  content: "\f0ad8";
}

.mdi-radio::before {
  content: "\f0439";
}

.mdi-radio-am::before {
  content: "\f0cbe";
}

.mdi-radio-fm::before {
  content: "\f0cbf";
}

.mdi-radio-handheld::before {
  content: "\f043a";
}

.mdi-radio-off::before {
  content: "\f121c";
}

.mdi-radio-tower::before {
  content: "\f043b";
}

.mdi-radioactive::before {
  content: "\f043c";
}

.mdi-radioactive-off::before {
  content: "\f0ec1";
}

.mdi-radiobox-blank::before {
  content: "\f043d";
}

.mdi-radiobox-marked::before {
  content: "\f043e";
}

.mdi-radiology-box::before {
  content: "\f14c5";
}

.mdi-radiology-box-outline::before {
  content: "\f14c6";
}

.mdi-radius::before {
  content: "\f0cc0";
}

.mdi-radius-outline::before {
  content: "\f0cc1";
}

.mdi-railroad-light::before {
  content: "\f0f1e";
}

.mdi-rake::before {
  content: "\f1544";
}

.mdi-raspberry-pi::before {
  content: "\f043f";
}

.mdi-ray-end::before {
  content: "\f0440";
}

.mdi-ray-end-arrow::before {
  content: "\f0441";
}

.mdi-ray-start::before {
  content: "\f0442";
}

.mdi-ray-start-arrow::before {
  content: "\f0443";
}

.mdi-ray-start-end::before {
  content: "\f0444";
}

.mdi-ray-start-vertex-end::before {
  content: "\f15d8";
}

.mdi-ray-vertex::before {
  content: "\f0445";
}

.mdi-react::before {
  content: "\f0708";
}

.mdi-read::before {
  content: "\f0447";
}

.mdi-receipt::before {
  content: "\f0449";
}

.mdi-record::before {
  content: "\f044a";
}

.mdi-record-circle::before {
  content: "\f0ec2";
}

.mdi-record-circle-outline::before {
  content: "\f0ec3";
}

.mdi-record-player::before {
  content: "\f099a";
}

.mdi-record-rec::before {
  content: "\f044b";
}

.mdi-rectangle::before {
  content: "\f0e5e";
}

.mdi-rectangle-outline::before {
  content: "\f0e5f";
}

.mdi-recycle::before {
  content: "\f044c";
}

.mdi-recycle-variant::before {
  content: "\f139d";
}

.mdi-reddit::before {
  content: "\f044d";
}

.mdi-redhat::before {
  content: "\f111b";
}

.mdi-redo::before {
  content: "\f044e";
}

.mdi-redo-variant::before {
  content: "\f044f";
}

.mdi-reflect-horizontal::before {
  content: "\f0a0e";
}

.mdi-reflect-vertical::before {
  content: "\f0a0f";
}

.mdi-refresh::before {
  content: "\f0450";
}

.mdi-refresh-circle::before {
  content: "\f1377";
}

.mdi-regex::before {
  content: "\f0451";
}

.mdi-registered-trademark::before {
  content: "\f0a67";
}

.mdi-reiterate::before {
  content: "\f1588";
}

.mdi-relation-many-to-many::before {
  content: "\f1496";
}

.mdi-relation-many-to-one::before {
  content: "\f1497";
}

.mdi-relation-many-to-one-or-many::before {
  content: "\f1498";
}

.mdi-relation-many-to-only-one::before {
  content: "\f1499";
}

.mdi-relation-many-to-zero-or-many::before {
  content: "\f149a";
}

.mdi-relation-many-to-zero-or-one::before {
  content: "\f149b";
}

.mdi-relation-one-or-many-to-many::before {
  content: "\f149c";
}

.mdi-relation-one-or-many-to-one::before {
  content: "\f149d";
}

.mdi-relation-one-or-many-to-one-or-many::before {
  content: "\f149e";
}

.mdi-relation-one-or-many-to-only-one::before {
  content: "\f149f";
}

.mdi-relation-one-or-many-to-zero-or-many::before {
  content: "\f14a0";
}

.mdi-relation-one-or-many-to-zero-or-one::before {
  content: "\f14a1";
}

.mdi-relation-one-to-many::before {
  content: "\f14a2";
}

.mdi-relation-one-to-one::before {
  content: "\f14a3";
}

.mdi-relation-one-to-one-or-many::before {
  content: "\f14a4";
}

.mdi-relation-one-to-only-one::before {
  content: "\f14a5";
}

.mdi-relation-one-to-zero-or-many::before {
  content: "\f14a6";
}

.mdi-relation-one-to-zero-or-one::before {
  content: "\f14a7";
}

.mdi-relation-only-one-to-many::before {
  content: "\f14a8";
}

.mdi-relation-only-one-to-one::before {
  content: "\f14a9";
}

.mdi-relation-only-one-to-one-or-many::before {
  content: "\f14aa";
}

.mdi-relation-only-one-to-only-one::before {
  content: "\f14ab";
}

.mdi-relation-only-one-to-zero-or-many::before {
  content: "\f14ac";
}

.mdi-relation-only-one-to-zero-or-one::before {
  content: "\f14ad";
}

.mdi-relation-zero-or-many-to-many::before {
  content: "\f14ae";
}

.mdi-relation-zero-or-many-to-one::before {
  content: "\f14af";
}

.mdi-relation-zero-or-many-to-one-or-many::before {
  content: "\f14b0";
}

.mdi-relation-zero-or-many-to-only-one::before {
  content: "\f14b1";
}

.mdi-relation-zero-or-many-to-zero-or-many::before {
  content: "\f14b2";
}

.mdi-relation-zero-or-many-to-zero-or-one::before {
  content: "\f14b3";
}

.mdi-relation-zero-or-one-to-many::before {
  content: "\f14b4";
}

.mdi-relation-zero-or-one-to-one::before {
  content: "\f14b5";
}

.mdi-relation-zero-or-one-to-one-or-many::before {
  content: "\f14b6";
}

.mdi-relation-zero-or-one-to-only-one::before {
  content: "\f14b7";
}

.mdi-relation-zero-or-one-to-zero-or-many::before {
  content: "\f14b8";
}

.mdi-relation-zero-or-one-to-zero-or-one::before {
  content: "\f14b9";
}

.mdi-relative-scale::before {
  content: "\f0452";
}

.mdi-reload::before {
  content: "\f0453";
}

.mdi-reload-alert::before {
  content: "\f110b";
}

.mdi-reminder::before {
  content: "\f088c";
}

.mdi-remote::before {
  content: "\f0454";
}

.mdi-remote-desktop::before {
  content: "\f08b9";
}

.mdi-remote-off::before {
  content: "\f0ec4";
}

.mdi-remote-tv::before {
  content: "\f0ec5";
}

.mdi-remote-tv-off::before {
  content: "\f0ec6";
}

.mdi-rename-box::before {
  content: "\f0455";
}

.mdi-reorder-horizontal::before {
  content: "\f0688";
}

.mdi-reorder-vertical::before {
  content: "\f0689";
}

.mdi-repeat::before {
  content: "\f0456";
}

.mdi-repeat-off::before {
  content: "\f0457";
}

.mdi-repeat-once::before {
  content: "\f0458";
}

.mdi-replay::before {
  content: "\f0459";
}

.mdi-reply::before {
  content: "\f045a";
}

.mdi-reply-all::before {
  content: "\f045b";
}

.mdi-reply-all-outline::before {
  content: "\f0f1f";
}

.mdi-reply-circle::before {
  content: "\f11ae";
}

.mdi-reply-outline::before {
  content: "\f0f20";
}

.mdi-reproduction::before {
  content: "\f045c";
}

.mdi-resistor::before {
  content: "\f0b44";
}

.mdi-resistor-nodes::before {
  content: "\f0b45";
}

.mdi-resize::before {
  content: "\f0a68";
}

.mdi-resize-bottom-right::before {
  content: "\f045d";
}

.mdi-responsive::before {
  content: "\f045e";
}

.mdi-restart::before {
  content: "\f0709";
}

.mdi-restart-alert::before {
  content: "\f110c";
}

.mdi-restart-off::before {
  content: "\f0d95";
}

.mdi-restore::before {
  content: "\f099b";
}

.mdi-restore-alert::before {
  content: "\f110d";
}

.mdi-rewind::before {
  content: "\f045f";
}

.mdi-rewind-10::before {
  content: "\f0d2a";
}

.mdi-rewind-30::before {
  content: "\f0d96";
}

.mdi-rewind-5::before {
  content: "\f11f9";
}

.mdi-rewind-60::before {
  content: "\f160c";
}

.mdi-rewind-outline::before {
  content: "\f070a";
}

.mdi-rhombus::before {
  content: "\f070b";
}

.mdi-rhombus-medium::before {
  content: "\f0a10";
}

.mdi-rhombus-medium-outline::before {
  content: "\f14dc";
}

.mdi-rhombus-outline::before {
  content: "\f070c";
}

.mdi-rhombus-split::before {
  content: "\f0a11";
}

.mdi-rhombus-split-outline::before {
  content: "\f14dd";
}

.mdi-ribbon::before {
  content: "\f0460";
}

.mdi-rice::before {
  content: "\f07ea";
}

.mdi-rickshaw::before {
  content: "\f15bb";
}

.mdi-rickshaw-electric::before {
  content: "\f15bc";
}

.mdi-ring::before {
  content: "\f07eb";
}

.mdi-rivet::before {
  content: "\f0e60";
}

.mdi-road::before {
  content: "\f0461";
}

.mdi-road-variant::before {
  content: "\f0462";
}

.mdi-robber::before {
  content: "\f1058";
}

.mdi-robot::before {
  content: "\f06a9";
}

.mdi-robot-angry::before {
  content: "\f169d";
}

.mdi-robot-angry-outline::before {
  content: "\f169e";
}

.mdi-robot-confused::before {
  content: "\f169f";
}

.mdi-robot-confused-outline::before {
  content: "\f16a0";
}

.mdi-robot-dead::before {
  content: "\f16a1";
}

.mdi-robot-dead-outline::before {
  content: "\f16a2";
}

.mdi-robot-excited::before {
  content: "\f16a3";
}

.mdi-robot-excited-outline::before {
  content: "\f16a4";
}

.mdi-robot-happy::before {
  content: "\f1719";
}

.mdi-robot-happy-outline::before {
  content: "\f171a";
}

.mdi-robot-industrial::before {
  content: "\f0b46";
}

.mdi-robot-love::before {
  content: "\f16a5";
}

.mdi-robot-love-outline::before {
  content: "\f16a6";
}

.mdi-robot-mower::before {
  content: "\f11f7";
}

.mdi-robot-mower-outline::before {
  content: "\f11f3";
}

.mdi-robot-off::before {
  content: "\f16a7";
}

.mdi-robot-off-outline::before {
  content: "\f167b";
}

.mdi-robot-outline::before {
  content: "\f167a";
}

.mdi-robot-vacuum::before {
  content: "\f070d";
}

.mdi-robot-vacuum-variant::before {
  content: "\f0908";
}

.mdi-rocket::before {
  content: "\f0463";
}

.mdi-rocket-launch::before {
  content: "\f14de";
}

.mdi-rocket-launch-outline::before {
  content: "\f14df";
}

.mdi-rocket-outline::before {
  content: "\f13af";
}

.mdi-rodent::before {
  content: "\f1327";
}

.mdi-roller-skate::before {
  content: "\f0d2b";
}

.mdi-roller-skate-off::before {
  content: "\f0145";
}

.mdi-rollerblade::before {
  content: "\f0d2c";
}

.mdi-rollerblade-off::before {
  content: "\f002e";
}

.mdi-rollupjs::before {
  content: "\f0bc0";
}

.mdi-roman-numeral-1::before {
  content: "\f1088";
}

.mdi-roman-numeral-10::before {
  content: "\f1091";
}

.mdi-roman-numeral-2::before {
  content: "\f1089";
}

.mdi-roman-numeral-3::before {
  content: "\f108a";
}

.mdi-roman-numeral-4::before {
  content: "\f108b";
}

.mdi-roman-numeral-5::before {
  content: "\f108c";
}

.mdi-roman-numeral-6::before {
  content: "\f108d";
}

.mdi-roman-numeral-7::before {
  content: "\f108e";
}

.mdi-roman-numeral-8::before {
  content: "\f108f";
}

.mdi-roman-numeral-9::before {
  content: "\f1090";
}

.mdi-room-service::before {
  content: "\f088d";
}

.mdi-room-service-outline::before {
  content: "\f0d97";
}

.mdi-rotate-3d::before {
  content: "\f0ec7";
}

.mdi-rotate-3d-variant::before {
  content: "\f0464";
}

.mdi-rotate-left::before {
  content: "\f0465";
}

.mdi-rotate-left-variant::before {
  content: "\f0466";
}

.mdi-rotate-orbit::before {
  content: "\f0d98";
}

.mdi-rotate-right::before {
  content: "\f0467";
}

.mdi-rotate-right-variant::before {
  content: "\f0468";
}

.mdi-rounded-corner::before {
  content: "\f0607";
}

.mdi-router::before {
  content: "\f11e2";
}

.mdi-router-network::before {
  content: "\f1087";
}

.mdi-router-wireless::before {
  content: "\f0469";
}

.mdi-router-wireless-off::before {
  content: "\f15a3";
}

.mdi-router-wireless-settings::before {
  content: "\f0a69";
}

.mdi-routes::before {
  content: "\f046a";
}

.mdi-routes-clock::before {
  content: "\f1059";
}

.mdi-rowing::before {
  content: "\f0608";
}

.mdi-rss::before {
  content: "\f046b";
}

.mdi-rss-box::before {
  content: "\f046c";
}

.mdi-rss-off::before {
  content: "\f0f21";
}

.mdi-rug::before {
  content: "\f1475";
}

.mdi-rugby::before {
  content: "\f0d99";
}

.mdi-ruler::before {
  content: "\f046d";
}

.mdi-ruler-square::before {
  content: "\f0cc2";
}

.mdi-ruler-square-compass::before {
  content: "\f0ebe";
}

.mdi-run::before {
  content: "\f070e";
}

.mdi-run-fast::before {
  content: "\f046e";
}

.mdi-rv-truck::before {
  content: "\f11d4";
}

.mdi-sack::before {
  content: "\f0d2e";
}

.mdi-sack-percent::before {
  content: "\f0d2f";
}

.mdi-safe::before {
  content: "\f0a6a";
}

.mdi-safe-square::before {
  content: "\f127c";
}

.mdi-safe-square-outline::before {
  content: "\f127d";
}

.mdi-safety-goggles::before {
  content: "\f0d30";
}

.mdi-sail-boat::before {
  content: "\f0ec8";
}

.mdi-sale::before {
  content: "\f046f";
}

.mdi-salesforce::before {
  content: "\f088e";
}

.mdi-sass::before {
  content: "\f07ec";
}

.mdi-satellite::before {
  content: "\f0470";
}

.mdi-satellite-uplink::before {
  content: "\f0909";
}

.mdi-satellite-variant::before {
  content: "\f0471";
}

.mdi-sausage::before {
  content: "\f08ba";
}

.mdi-saw-blade::before {
  content: "\f0e61";
}

.mdi-sawtooth-wave::before {
  content: "\f147a";
}

.mdi-saxophone::before {
  content: "\f0609";
}

.mdi-scale::before {
  content: "\f0472";
}

.mdi-scale-balance::before {
  content: "\f05d1";
}

.mdi-scale-bathroom::before {
  content: "\f0473";
}

.mdi-scale-off::before {
  content: "\f105a";
}

.mdi-scan-helper::before {
  content: "\f13d8";
}

.mdi-scanner::before {
  content: "\f06ab";
}

.mdi-scanner-off::before {
  content: "\f090a";
}

.mdi-scatter-plot::before {
  content: "\f0ec9";
}

.mdi-scatter-plot-outline::before {
  content: "\f0eca";
}

.mdi-school::before {
  content: "\f0474";
}

.mdi-school-outline::before {
  content: "\f1180";
}

.mdi-scissors-cutting::before {
  content: "\f0a6b";
}

.mdi-scooter::before {
  content: "\f15bd";
}

.mdi-scooter-electric::before {
  content: "\f15be";
}

.mdi-scoreboard::before {
  content: "\f127e";
}

.mdi-scoreboard-outline::before {
  content: "\f127f";
}

.mdi-screen-rotation::before {
  content: "\f0475";
}

.mdi-screen-rotation-lock::before {
  content: "\f0478";
}

.mdi-screw-flat-top::before {
  content: "\f0df3";
}

.mdi-screw-lag::before {
  content: "\f0df4";
}

.mdi-screw-machine-flat-top::before {
  content: "\f0df5";
}

.mdi-screw-machine-round-top::before {
  content: "\f0df6";
}

.mdi-screw-round-top::before {
  content: "\f0df7";
}

.mdi-screwdriver::before {
  content: "\f0476";
}

.mdi-script::before {
  content: "\f0bc1";
}

.mdi-script-outline::before {
  content: "\f0477";
}

.mdi-script-text::before {
  content: "\f0bc2";
}

.mdi-script-text-key::before {
  content: "\f1725";
}

.mdi-script-text-key-outline::before {
  content: "\f1726";
}

.mdi-script-text-outline::before {
  content: "\f0bc3";
}

.mdi-script-text-play::before {
  content: "\f1727";
}

.mdi-script-text-play-outline::before {
  content: "\f1728";
}

.mdi-sd::before {
  content: "\f0479";
}

.mdi-seal::before {
  content: "\f047a";
}

.mdi-seal-variant::before {
  content: "\f0fd9";
}

.mdi-search-web::before {
  content: "\f070f";
}

.mdi-seat::before {
  content: "\f0cc3";
}

.mdi-seat-flat::before {
  content: "\f047b";
}

.mdi-seat-flat-angled::before {
  content: "\f047c";
}

.mdi-seat-individual-suite::before {
  content: "\f047d";
}

.mdi-seat-legroom-extra::before {
  content: "\f047e";
}

.mdi-seat-legroom-normal::before {
  content: "\f047f";
}

.mdi-seat-legroom-reduced::before {
  content: "\f0480";
}

.mdi-seat-outline::before {
  content: "\f0cc4";
}

.mdi-seat-passenger::before {
  content: "\f1249";
}

.mdi-seat-recline-extra::before {
  content: "\f0481";
}

.mdi-seat-recline-normal::before {
  content: "\f0482";
}

.mdi-seatbelt::before {
  content: "\f0cc5";
}

.mdi-security::before {
  content: "\f0483";
}

.mdi-security-network::before {
  content: "\f0484";
}

.mdi-seed::before {
  content: "\f0e62";
}

.mdi-seed-off::before {
  content: "\f13fd";
}

.mdi-seed-off-outline::before {
  content: "\f13fe";
}

.mdi-seed-outline::before {
  content: "\f0e63";
}

.mdi-seesaw::before {
  content: "\f15a4";
}

.mdi-segment::before {
  content: "\f0ecb";
}

.mdi-select::before {
  content: "\f0485";
}

.mdi-select-all::before {
  content: "\f0486";
}

.mdi-select-color::before {
  content: "\f0d31";
}

.mdi-select-compare::before {
  content: "\f0ad9";
}

.mdi-select-drag::before {
  content: "\f0a6c";
}

.mdi-select-group::before {
  content: "\f0f82";
}

.mdi-select-inverse::before {
  content: "\f0487";
}

.mdi-select-marker::before {
  content: "\f1280";
}

.mdi-select-multiple::before {
  content: "\f1281";
}

.mdi-select-multiple-marker::before {
  content: "\f1282";
}

.mdi-select-off::before {
  content: "\f0488";
}

.mdi-select-place::before {
  content: "\f0fda";
}

.mdi-select-search::before {
  content: "\f1204";
}

.mdi-selection::before {
  content: "\f0489";
}

.mdi-selection-drag::before {
  content: "\f0a6d";
}

.mdi-selection-ellipse::before {
  content: "\f0d32";
}

.mdi-selection-ellipse-arrow-inside::before {
  content: "\f0f22";
}

.mdi-selection-marker::before {
  content: "\f1283";
}

.mdi-selection-multiple::before {
  content: "\f1285";
}

.mdi-selection-multiple-marker::before {
  content: "\f1284";
}

.mdi-selection-off::before {
  content: "\f0777";
}

.mdi-selection-search::before {
  content: "\f1205";
}

.mdi-semantic-web::before {
  content: "\f1316";
}

.mdi-send::before {
  content: "\f048a";
}

.mdi-send-check::before {
  content: "\f1161";
}

.mdi-send-check-outline::before {
  content: "\f1162";
}

.mdi-send-circle::before {
  content: "\f0df8";
}

.mdi-send-circle-outline::before {
  content: "\f0df9";
}

.mdi-send-clock::before {
  content: "\f1163";
}

.mdi-send-clock-outline::before {
  content: "\f1164";
}

.mdi-send-lock::before {
  content: "\f07ed";
}

.mdi-send-lock-outline::before {
  content: "\f1166";
}

.mdi-send-outline::before {
  content: "\f1165";
}

.mdi-serial-port::before {
  content: "\f065c";
}

.mdi-server::before {
  content: "\f048b";
}

.mdi-server-minus::before {
  content: "\f048c";
}

.mdi-server-network::before {
  content: "\f048d";
}

.mdi-server-network-off::before {
  content: "\f048e";
}

.mdi-server-off::before {
  content: "\f048f";
}

.mdi-server-plus::before {
  content: "\f0490";
}

.mdi-server-remove::before {
  content: "\f0491";
}

.mdi-server-security::before {
  content: "\f0492";
}

.mdi-set-all::before {
  content: "\f0778";
}

.mdi-set-center::before {
  content: "\f0779";
}

.mdi-set-center-right::before {
  content: "\f077a";
}

.mdi-set-left::before {
  content: "\f077b";
}

.mdi-set-left-center::before {
  content: "\f077c";
}

.mdi-set-left-right::before {
  content: "\f077d";
}

.mdi-set-merge::before {
  content: "\f14e0";
}

.mdi-set-none::before {
  content: "\f077e";
}

.mdi-set-right::before {
  content: "\f077f";
}

.mdi-set-split::before {
  content: "\f14e1";
}

.mdi-set-square::before {
  content: "\f145d";
}

.mdi-set-top-box::before {
  content: "\f099f";
}

.mdi-settings-helper::before {
  content: "\f0a6e";
}

.mdi-shaker::before {
  content: "\f110e";
}

.mdi-shaker-outline::before {
  content: "\f110f";
}

.mdi-shape::before {
  content: "\f0831";
}

.mdi-shape-circle-plus::before {
  content: "\f065d";
}

.mdi-shape-outline::before {
  content: "\f0832";
}

.mdi-shape-oval-plus::before {
  content: "\f11fa";
}

.mdi-shape-plus::before {
  content: "\f0495";
}

.mdi-shape-polygon-plus::before {
  content: "\f065e";
}

.mdi-shape-rectangle-plus::before {
  content: "\f065f";
}

.mdi-shape-square-plus::before {
  content: "\f0660";
}

.mdi-shape-square-rounded-plus::before {
  content: "\f14fa";
}

.mdi-share::before {
  content: "\f0496";
}

.mdi-share-all::before {
  content: "\f11f4";
}

.mdi-share-all-outline::before {
  content: "\f11f5";
}

.mdi-share-circle::before {
  content: "\f11ad";
}

.mdi-share-off::before {
  content: "\f0f23";
}

.mdi-share-off-outline::before {
  content: "\f0f24";
}

.mdi-share-outline::before {
  content: "\f0932";
}

.mdi-share-variant::before {
  content: "\f0497";
}

.mdi-share-variant-outline::before {
  content: "\f1514";
}

.mdi-shark-fin::before {
  content: "\f1673";
}

.mdi-shark-fin-outline::before {
  content: "\f1674";
}

.mdi-sheep::before {
  content: "\f0cc6";
}

.mdi-shield::before {
  content: "\f0498";
}

.mdi-shield-account::before {
  content: "\f088f";
}

.mdi-shield-account-outline::before {
  content: "\f0a12";
}

.mdi-shield-account-variant::before {
  content: "\f15a7";
}

.mdi-shield-account-variant-outline::before {
  content: "\f15a8";
}

.mdi-shield-airplane::before {
  content: "\f06bb";
}

.mdi-shield-airplane-outline::before {
  content: "\f0cc7";
}

.mdi-shield-alert::before {
  content: "\f0ecc";
}

.mdi-shield-alert-outline::before {
  content: "\f0ecd";
}

.mdi-shield-bug::before {
  content: "\f13da";
}

.mdi-shield-bug-outline::before {
  content: "\f13db";
}

.mdi-shield-car::before {
  content: "\f0f83";
}

.mdi-shield-check::before {
  content: "\f0565";
}

.mdi-shield-check-outline::before {
  content: "\f0cc8";
}

.mdi-shield-cross::before {
  content: "\f0cc9";
}

.mdi-shield-cross-outline::before {
  content: "\f0cca";
}

.mdi-shield-edit::before {
  content: "\f11a0";
}

.mdi-shield-edit-outline::before {
  content: "\f11a1";
}

.mdi-shield-half::before {
  content: "\f1360";
}

.mdi-shield-half-full::before {
  content: "\f0780";
}

.mdi-shield-home::before {
  content: "\f068a";
}

.mdi-shield-home-outline::before {
  content: "\f0ccb";
}

.mdi-shield-key::before {
  content: "\f0bc4";
}

.mdi-shield-key-outline::before {
  content: "\f0bc5";
}

.mdi-shield-link-variant::before {
  content: "\f0d33";
}

.mdi-shield-link-variant-outline::before {
  content: "\f0d34";
}

.mdi-shield-lock::before {
  content: "\f099d";
}

.mdi-shield-lock-outline::before {
  content: "\f0ccc";
}

.mdi-shield-off::before {
  content: "\f099e";
}

.mdi-shield-off-outline::before {
  content: "\f099c";
}

.mdi-shield-outline::before {
  content: "\f0499";
}

.mdi-shield-plus::before {
  content: "\f0ada";
}

.mdi-shield-plus-outline::before {
  content: "\f0adb";
}

.mdi-shield-refresh::before {
  content: "\f00aa";
}

.mdi-shield-refresh-outline::before {
  content: "\f01e0";
}

.mdi-shield-remove::before {
  content: "\f0adc";
}

.mdi-shield-remove-outline::before {
  content: "\f0add";
}

.mdi-shield-search::before {
  content: "\f0d9a";
}

.mdi-shield-star::before {
  content: "\f113b";
}

.mdi-shield-star-outline::before {
  content: "\f113c";
}

.mdi-shield-sun::before {
  content: "\f105d";
}

.mdi-shield-sun-outline::before {
  content: "\f105e";
}

.mdi-shield-sync::before {
  content: "\f11a2";
}

.mdi-shield-sync-outline::before {
  content: "\f11a3";
}

.mdi-ship-wheel::before {
  content: "\f0833";
}

.mdi-shoe-ballet::before {
  content: "\f15ca";
}

.mdi-shoe-cleat::before {
  content: "\f15c7";
}

.mdi-shoe-formal::before {
  content: "\f0b47";
}

.mdi-shoe-heel::before {
  content: "\f0b48";
}

.mdi-shoe-print::before {
  content: "\f0dfa";
}

.mdi-shoe-sneaker::before {
  content: "\f15c8";
}

.mdi-shopping::before {
  content: "\f049a";
}

.mdi-shopping-music::before {
  content: "\f049b";
}

.mdi-shopping-outline::before {
  content: "\f11d5";
}

.mdi-shopping-search::before {
  content: "\f0f84";
}

.mdi-shore::before {
  content: "\f14f9";
}

.mdi-shovel::before {
  content: "\f0710";
}

.mdi-shovel-off::before {
  content: "\f0711";
}

.mdi-shower::before {
  content: "\f09a0";
}

.mdi-shower-head::before {
  content: "\f09a1";
}

.mdi-shredder::before {
  content: "\f049c";
}

.mdi-shuffle::before {
  content: "\f049d";
}

.mdi-shuffle-disabled::before {
  content: "\f049e";
}

.mdi-shuffle-variant::before {
  content: "\f049f";
}

.mdi-shuriken::before {
  content: "\f137f";
}

.mdi-sigma::before {
  content: "\f04a0";
}

.mdi-sigma-lower::before {
  content: "\f062b";
}

.mdi-sign-caution::before {
  content: "\f04a1";
}

.mdi-sign-direction::before {
  content: "\f0781";
}

.mdi-sign-direction-minus::before {
  content: "\f1000";
}

.mdi-sign-direction-plus::before {
  content: "\f0fdc";
}

.mdi-sign-direction-remove::before {
  content: "\f0fdd";
}

.mdi-sign-pole::before {
  content: "\f14f8";
}

.mdi-sign-real-estate::before {
  content: "\f1118";
}

.mdi-sign-text::before {
  content: "\f0782";
}

.mdi-signal::before {
  content: "\f04a2";
}

.mdi-signal-2g::before {
  content: "\f0712";
}

.mdi-signal-3g::before {
  content: "\f0713";
}

.mdi-signal-4g::before {
  content: "\f0714";
}

.mdi-signal-5g::before {
  content: "\f0a6f";
}

.mdi-signal-cellular-1::before {
  content: "\f08bc";
}

.mdi-signal-cellular-2::before {
  content: "\f08bd";
}

.mdi-signal-cellular-3::before {
  content: "\f08be";
}

.mdi-signal-cellular-outline::before {
  content: "\f08bf";
}

.mdi-signal-distance-variant::before {
  content: "\f0e64";
}

.mdi-signal-hspa::before {
  content: "\f0715";
}

.mdi-signal-hspa-plus::before {
  content: "\f0716";
}

.mdi-signal-off::before {
  content: "\f0783";
}

.mdi-signal-variant::before {
  content: "\f060a";
}

.mdi-signature::before {
  content: "\f0dfb";
}

.mdi-signature-freehand::before {
  content: "\f0dfc";
}

.mdi-signature-image::before {
  content: "\f0dfd";
}

.mdi-signature-text::before {
  content: "\f0dfe";
}

.mdi-silo::before {
  content: "\f0b49";
}

.mdi-silverware::before {
  content: "\f04a3";
}

.mdi-silverware-clean::before {
  content: "\f0fde";
}

.mdi-silverware-fork::before {
  content: "\f04a4";
}

.mdi-silverware-fork-knife::before {
  content: "\f0a70";
}

.mdi-silverware-spoon::before {
  content: "\f04a5";
}

.mdi-silverware-variant::before {
  content: "\f04a6";
}

.mdi-sim::before {
  content: "\f04a7";
}

.mdi-sim-alert::before {
  content: "\f04a8";
}

.mdi-sim-alert-outline::before {
  content: "\f15d3";
}

.mdi-sim-off::before {
  content: "\f04a9";
}

.mdi-sim-off-outline::before {
  content: "\f15d4";
}

.mdi-sim-outline::before {
  content: "\f15d5";
}

.mdi-simple-icons::before {
  content: "\f131d";
}

.mdi-sina-weibo::before {
  content: "\f0adf";
}

.mdi-sine-wave::before {
  content: "\f095b";
}

.mdi-sitemap::before {
  content: "\f04aa";
}

.mdi-size-l::before {
  content: "\f13a6";
}

.mdi-size-m::before {
  content: "\f13a5";
}

.mdi-size-s::before {
  content: "\f13a4";
}

.mdi-size-xl::before {
  content: "\f13a7";
}

.mdi-size-xs::before {
  content: "\f13a3";
}

.mdi-size-xxl::before {
  content: "\f13a8";
}

.mdi-size-xxs::before {
  content: "\f13a2";
}

.mdi-size-xxxl::before {
  content: "\f13a9";
}

.mdi-skate::before {
  content: "\f0d35";
}

.mdi-skateboard::before {
  content: "\f14c2";
}

.mdi-skew-less::before {
  content: "\f0d36";
}

.mdi-skew-more::before {
  content: "\f0d37";
}

.mdi-ski::before {
  content: "\f1304";
}

.mdi-ski-cross-country::before {
  content: "\f1305";
}

.mdi-ski-water::before {
  content: "\f1306";
}

.mdi-skip-backward::before {
  content: "\f04ab";
}

.mdi-skip-backward-outline::before {
  content: "\f0f25";
}

.mdi-skip-forward::before {
  content: "\f04ac";
}

.mdi-skip-forward-outline::before {
  content: "\f0f26";
}

.mdi-skip-next::before {
  content: "\f04ad";
}

.mdi-skip-next-circle::before {
  content: "\f0661";
}

.mdi-skip-next-circle-outline::before {
  content: "\f0662";
}

.mdi-skip-next-outline::before {
  content: "\f0f27";
}

.mdi-skip-previous::before {
  content: "\f04ae";
}

.mdi-skip-previous-circle::before {
  content: "\f0663";
}

.mdi-skip-previous-circle-outline::before {
  content: "\f0664";
}

.mdi-skip-previous-outline::before {
  content: "\f0f28";
}

.mdi-skull::before {
  content: "\f068c";
}

.mdi-skull-crossbones::before {
  content: "\f0bc6";
}

.mdi-skull-crossbones-outline::before {
  content: "\f0bc7";
}

.mdi-skull-outline::before {
  content: "\f0bc8";
}

.mdi-skull-scan::before {
  content: "\f14c7";
}

.mdi-skull-scan-outline::before {
  content: "\f14c8";
}

.mdi-skype::before {
  content: "\f04af";
}

.mdi-skype-business::before {
  content: "\f04b0";
}

.mdi-slack::before {
  content: "\f04b1";
}

.mdi-slash-forward::before {
  content: "\f0fdf";
}

.mdi-slash-forward-box::before {
  content: "\f0fe0";
}

.mdi-sleep::before {
  content: "\f04b2";
}

.mdi-sleep-off::before {
  content: "\f04b3";
}

.mdi-slide::before {
  content: "\f15a5";
}

.mdi-slope-downhill::before {
  content: "\f0dff";
}

.mdi-slope-uphill::before {
  content: "\f0e00";
}

.mdi-slot-machine::before {
  content: "\f1114";
}

.mdi-slot-machine-outline::before {
  content: "\f1115";
}

.mdi-smart-card::before {
  content: "\f10bd";
}

.mdi-smart-card-outline::before {
  content: "\f10be";
}

.mdi-smart-card-reader::before {
  content: "\f10bf";
}

.mdi-smart-card-reader-outline::before {
  content: "\f10c0";
}

.mdi-smog::before {
  content: "\f0a71";
}

.mdi-smoke-detector::before {
  content: "\f0392";
}

.mdi-smoking::before {
  content: "\f04b4";
}

.mdi-smoking-off::before {
  content: "\f04b5";
}

.mdi-smoking-pipe::before {
  content: "\f140d";
}

.mdi-smoking-pipe-off::before {
  content: "\f1428";
}

.mdi-snail::before {
  content: "\f1677";
}

.mdi-snake::before {
  content: "\f150e";
}

.mdi-snapchat::before {
  content: "\f04b6";
}

.mdi-snowboard::before {
  content: "\f1307";
}

.mdi-snowflake::before {
  content: "\f0717";
}

.mdi-snowflake-alert::before {
  content: "\f0f29";
}

.mdi-snowflake-melt::before {
  content: "\f12cb";
}

.mdi-snowflake-off::before {
  content: "\f14e3";
}

.mdi-snowflake-variant::before {
  content: "\f0f2a";
}

.mdi-snowman::before {
  content: "\f04b7";
}

.mdi-soccer::before {
  content: "\f04b8";
}

.mdi-soccer-field::before {
  content: "\f0834";
}

.mdi-social-distance-2-meters::before {
  content: "\f1579";
}

.mdi-social-distance-6-feet::before {
  content: "\f157a";
}

.mdi-sofa::before {
  content: "\f04b9";
}

.mdi-sofa-outline::before {
  content: "\f156d";
}

.mdi-sofa-single::before {
  content: "\f156e";
}

.mdi-sofa-single-outline::before {
  content: "\f156f";
}

.mdi-solar-panel::before {
  content: "\f0d9b";
}

.mdi-solar-panel-large::before {
  content: "\f0d9c";
}

.mdi-solar-power::before {
  content: "\f0a72";
}

.mdi-soldering-iron::before {
  content: "\f1092";
}

.mdi-solid::before {
  content: "\f068d";
}

.mdi-sony-playstation::before {
  content: "\f0414";
}

.mdi-sort::before {
  content: "\f04ba";
}

.mdi-sort-alphabetical-ascending::before {
  content: "\f05bd";
}

.mdi-sort-alphabetical-ascending-variant::before {
  content: "\f1148";
}

.mdi-sort-alphabetical-descending::before {
  content: "\f05bf";
}

.mdi-sort-alphabetical-descending-variant::before {
  content: "\f1149";
}

.mdi-sort-alphabetical-variant::before {
  content: "\f04bb";
}

.mdi-sort-ascending::before {
  content: "\f04bc";
}

.mdi-sort-bool-ascending::before {
  content: "\f1385";
}

.mdi-sort-bool-ascending-variant::before {
  content: "\f1386";
}

.mdi-sort-bool-descending::before {
  content: "\f1387";
}

.mdi-sort-bool-descending-variant::before {
  content: "\f1388";
}

.mdi-sort-calendar-ascending::before {
  content: "\f1547";
}

.mdi-sort-calendar-descending::before {
  content: "\f1548";
}

.mdi-sort-clock-ascending::before {
  content: "\f1549";
}

.mdi-sort-clock-ascending-outline::before {
  content: "\f154a";
}

.mdi-sort-clock-descending::before {
  content: "\f154b";
}

.mdi-sort-clock-descending-outline::before {
  content: "\f154c";
}

.mdi-sort-descending::before {
  content: "\f04bd";
}

.mdi-sort-numeric-ascending::before {
  content: "\f1389";
}

.mdi-sort-numeric-ascending-variant::before {
  content: "\f090d";
}

.mdi-sort-numeric-descending::before {
  content: "\f138a";
}

.mdi-sort-numeric-descending-variant::before {
  content: "\f0ad2";
}

.mdi-sort-numeric-variant::before {
  content: "\f04be";
}

.mdi-sort-reverse-variant::before {
  content: "\f033c";
}

.mdi-sort-variant::before {
  content: "\f04bf";
}

.mdi-sort-variant-lock::before {
  content: "\f0ccd";
}

.mdi-sort-variant-lock-open::before {
  content: "\f0cce";
}

.mdi-sort-variant-remove::before {
  content: "\f1147";
}

.mdi-soundcloud::before {
  content: "\f04c0";
}

.mdi-source-branch::before {
  content: "\f062c";
}

.mdi-source-branch-check::before {
  content: "\f14cf";
}

.mdi-source-branch-minus::before {
  content: "\f14cb";
}

.mdi-source-branch-plus::before {
  content: "\f14ca";
}

.mdi-source-branch-refresh::before {
  content: "\f14cd";
}

.mdi-source-branch-remove::before {
  content: "\f14cc";
}

.mdi-source-branch-sync::before {
  content: "\f14ce";
}

.mdi-source-commit::before {
  content: "\f0718";
}

.mdi-source-commit-end::before {
  content: "\f0719";
}

.mdi-source-commit-end-local::before {
  content: "\f071a";
}

.mdi-source-commit-local::before {
  content: "\f071b";
}

.mdi-source-commit-next-local::before {
  content: "\f071c";
}

.mdi-source-commit-start::before {
  content: "\f071d";
}

.mdi-source-commit-start-next-local::before {
  content: "\f071e";
}

.mdi-source-fork::before {
  content: "\f04c1";
}

.mdi-source-merge::before {
  content: "\f062d";
}

.mdi-source-pull::before {
  content: "\f04c2";
}

.mdi-source-repository::before {
  content: "\f0ccf";
}

.mdi-source-repository-multiple::before {
  content: "\f0cd0";
}

.mdi-soy-sauce::before {
  content: "\f07ee";
}

.mdi-soy-sauce-off::before {
  content: "\f13fc";
}

.mdi-spa::before {
  content: "\f0cd1";
}

.mdi-spa-outline::before {
  content: "\f0cd2";
}

.mdi-space-invaders::before {
  content: "\f0bc9";
}

.mdi-space-station::before {
  content: "\f1383";
}

.mdi-spade::before {
  content: "\f0e65";
}

.mdi-sparkles::before {
  content: "\f1545";
}

.mdi-speaker::before {
  content: "\f04c3";
}

.mdi-speaker-bluetooth::before {
  content: "\f09a2";
}

.mdi-speaker-multiple::before {
  content: "\f0d38";
}

.mdi-speaker-off::before {
  content: "\f04c4";
}

.mdi-speaker-wireless::before {
  content: "\f071f";
}

.mdi-speedometer::before {
  content: "\f04c5";
}

.mdi-speedometer-medium::before {
  content: "\f0f85";
}

.mdi-speedometer-slow::before {
  content: "\f0f86";
}

.mdi-spellcheck::before {
  content: "\f04c6";
}

.mdi-spider::before {
  content: "\f11ea";
}

.mdi-spider-thread::before {
  content: "\f11eb";
}

.mdi-spider-web::before {
  content: "\f0bca";
}

.mdi-spirit-level::before {
  content: "\f14f1";
}

.mdi-spoon-sugar::before {
  content: "\f1429";
}

.mdi-spotify::before {
  content: "\f04c7";
}

.mdi-spotlight::before {
  content: "\f04c8";
}

.mdi-spotlight-beam::before {
  content: "\f04c9";
}

.mdi-spray::before {
  content: "\f0665";
}

.mdi-spray-bottle::before {
  content: "\f0ae0";
}

.mdi-sprinkler::before {
  content: "\f105f";
}

.mdi-sprinkler-variant::before {
  content: "\f1060";
}

.mdi-sprout::before {
  content: "\f0e66";
}

.mdi-sprout-outline::before {
  content: "\f0e67";
}

.mdi-square::before {
  content: "\f0764";
}

.mdi-square-circle::before {
  content: "\f1500";
}

.mdi-square-edit-outline::before {
  content: "\f090c";
}

.mdi-square-medium::before {
  content: "\f0a13";
}

.mdi-square-medium-outline::before {
  content: "\f0a14";
}

.mdi-square-off::before {
  content: "\f12ee";
}

.mdi-square-off-outline::before {
  content: "\f12ef";
}

.mdi-square-outline::before {
  content: "\f0763";
}

.mdi-square-root::before {
  content: "\f0784";
}

.mdi-square-root-box::before {
  content: "\f09a3";
}

.mdi-square-rounded::before {
  content: "\f14fb";
}

.mdi-square-rounded-outline::before {
  content: "\f14fc";
}

.mdi-square-small::before {
  content: "\f0a15";
}

.mdi-square-wave::before {
  content: "\f147b";
}

.mdi-squeegee::before {
  content: "\f0ae1";
}

.mdi-ssh::before {
  content: "\f08c0";
}

.mdi-stack-exchange::before {
  content: "\f060b";
}

.mdi-stack-overflow::before {
  content: "\f04cc";
}

.mdi-stackpath::before {
  content: "\f0359";
}

.mdi-stadium::before {
  content: "\f0ff9";
}

.mdi-stadium-variant::before {
  content: "\f0720";
}

.mdi-stairs::before {
  content: "\f04cd";
}

.mdi-stairs-box::before {
  content: "\f139e";
}

.mdi-stairs-down::before {
  content: "\f12be";
}

.mdi-stairs-up::before {
  content: "\f12bd";
}

.mdi-stamper::before {
  content: "\f0d39";
}

.mdi-standard-definition::before {
  content: "\f07ef";
}

.mdi-star::before {
  content: "\f04ce";
}

.mdi-star-box::before {
  content: "\f0a73";
}

.mdi-star-box-multiple::before {
  content: "\f1286";
}

.mdi-star-box-multiple-outline::before {
  content: "\f1287";
}

.mdi-star-box-outline::before {
  content: "\f0a74";
}

.mdi-star-check::before {
  content: "\f1566";
}

.mdi-star-check-outline::before {
  content: "\f156a";
}

.mdi-star-circle::before {
  content: "\f04cf";
}

.mdi-star-circle-outline::before {
  content: "\f09a4";
}

.mdi-star-cog::before {
  content: "\f1668";
}

.mdi-star-cog-outline::before {
  content: "\f1669";
}

.mdi-star-face::before {
  content: "\f09a5";
}

.mdi-star-four-points::before {
  content: "\f0ae2";
}

.mdi-star-four-points-outline::before {
  content: "\f0ae3";
}

.mdi-star-half::before {
  content: "\f0246";
}

.mdi-star-half-full::before {
  content: "\f04d0";
}

.mdi-star-minus::before {
  content: "\f1564";
}

.mdi-star-minus-outline::before {
  content: "\f1568";
}

.mdi-star-off::before {
  content: "\f04d1";
}

.mdi-star-off-outline::before {
  content: "\f155b";
}

.mdi-star-outline::before {
  content: "\f04d2";
}

.mdi-star-plus::before {
  content: "\f1563";
}

.mdi-star-plus-outline::before {
  content: "\f1567";
}

.mdi-star-remove::before {
  content: "\f1565";
}

.mdi-star-remove-outline::before {
  content: "\f1569";
}

.mdi-star-settings::before {
  content: "\f166a";
}

.mdi-star-settings-outline::before {
  content: "\f166b";
}

.mdi-star-shooting::before {
  content: "\f1741";
}

.mdi-star-shooting-outline::before {
  content: "\f1742";
}

.mdi-star-three-points::before {
  content: "\f0ae4";
}

.mdi-star-three-points-outline::before {
  content: "\f0ae5";
}

.mdi-state-machine::before {
  content: "\f11ef";
}

.mdi-steam::before {
  content: "\f04d3";
}

.mdi-steering::before {
  content: "\f04d4";
}

.mdi-steering-off::before {
  content: "\f090e";
}

.mdi-step-backward::before {
  content: "\f04d5";
}

.mdi-step-backward-2::before {
  content: "\f04d6";
}

.mdi-step-forward::before {
  content: "\f04d7";
}

.mdi-step-forward-2::before {
  content: "\f04d8";
}

.mdi-stethoscope::before {
  content: "\f04d9";
}

.mdi-sticker::before {
  content: "\f1364";
}

.mdi-sticker-alert::before {
  content: "\f1365";
}

.mdi-sticker-alert-outline::before {
  content: "\f1366";
}

.mdi-sticker-check::before {
  content: "\f1367";
}

.mdi-sticker-check-outline::before {
  content: "\f1368";
}

.mdi-sticker-circle-outline::before {
  content: "\f05d0";
}

.mdi-sticker-emoji::before {
  content: "\f0785";
}

.mdi-sticker-minus::before {
  content: "\f1369";
}

.mdi-sticker-minus-outline::before {
  content: "\f136a";
}

.mdi-sticker-outline::before {
  content: "\f136b";
}

.mdi-sticker-plus::before {
  content: "\f136c";
}

.mdi-sticker-plus-outline::before {
  content: "\f136d";
}

.mdi-sticker-remove::before {
  content: "\f136e";
}

.mdi-sticker-remove-outline::before {
  content: "\f136f";
}

.mdi-stocking::before {
  content: "\f04da";
}

.mdi-stomach::before {
  content: "\f1093";
}

.mdi-stop::before {
  content: "\f04db";
}

.mdi-stop-circle::before {
  content: "\f0666";
}

.mdi-stop-circle-outline::before {
  content: "\f0667";
}

.mdi-store::before {
  content: "\f04dc";
}

.mdi-store-24-hour::before {
  content: "\f04dd";
}

.mdi-store-minus::before {
  content: "\f165e";
}

.mdi-store-outline::before {
  content: "\f1361";
}

.mdi-store-plus::before {
  content: "\f165f";
}

.mdi-store-remove::before {
  content: "\f1660";
}

.mdi-storefront::before {
  content: "\f07c7";
}

.mdi-storefront-outline::before {
  content: "\f10c1";
}

.mdi-stove::before {
  content: "\f04de";
}

.mdi-strategy::before {
  content: "\f11d6";
}

.mdi-stretch-to-page::before {
  content: "\f0f2b";
}

.mdi-stretch-to-page-outline::before {
  content: "\f0f2c";
}

.mdi-string-lights::before {
  content: "\f12ba";
}

.mdi-string-lights-off::before {
  content: "\f12bb";
}

.mdi-subdirectory-arrow-left::before {
  content: "\f060c";
}

.mdi-subdirectory-arrow-right::before {
  content: "\f060d";
}

.mdi-submarine::before {
  content: "\f156c";
}

.mdi-subtitles::before {
  content: "\f0a16";
}

.mdi-subtitles-outline::before {
  content: "\f0a17";
}

.mdi-subway::before {
  content: "\f06ac";
}

.mdi-subway-alert-variant::before {
  content: "\f0d9d";
}

.mdi-subway-variant::before {
  content: "\f04df";
}

.mdi-summit::before {
  content: "\f0786";
}

.mdi-sunglasses::before {
  content: "\f04e0";
}

.mdi-surround-sound::before {
  content: "\f05c5";
}

.mdi-surround-sound-2-0::before {
  content: "\f07f0";
}

.mdi-surround-sound-2-1::before {
  content: "\f1729";
}

.mdi-surround-sound-3-1::before {
  content: "\f07f1";
}

.mdi-surround-sound-5-1::before {
  content: "\f07f2";
}

.mdi-surround-sound-5-1-2::before {
  content: "\f172a";
}

.mdi-surround-sound-7-1::before {
  content: "\f07f3";
}

.mdi-svg::before {
  content: "\f0721";
}

.mdi-swap-horizontal::before {
  content: "\f04e1";
}

.mdi-swap-horizontal-bold::before {
  content: "\f0bcd";
}

.mdi-swap-horizontal-circle::before {
  content: "\f0fe1";
}

.mdi-swap-horizontal-circle-outline::before {
  content: "\f0fe2";
}

.mdi-swap-horizontal-variant::before {
  content: "\f08c1";
}

.mdi-swap-vertical::before {
  content: "\f04e2";
}

.mdi-swap-vertical-bold::before {
  content: "\f0bce";
}

.mdi-swap-vertical-circle::before {
  content: "\f0fe3";
}

.mdi-swap-vertical-circle-outline::before {
  content: "\f0fe4";
}

.mdi-swap-vertical-variant::before {
  content: "\f08c2";
}

.mdi-swim::before {
  content: "\f04e3";
}

.mdi-switch::before {
  content: "\f04e4";
}

.mdi-sword::before {
  content: "\f04e5";
}

.mdi-sword-cross::before {
  content: "\f0787";
}

.mdi-syllabary-hangul::before {
  content: "\f1333";
}

.mdi-syllabary-hiragana::before {
  content: "\f1334";
}

.mdi-syllabary-katakana::before {
  content: "\f1335";
}

.mdi-syllabary-katakana-halfwidth::before {
  content: "\f1336";
}

.mdi-symbol::before {
  content: "\f1501";
}

.mdi-symfony::before {
  content: "\f0ae6";
}

.mdi-sync::before {
  content: "\f04e6";
}

.mdi-sync-alert::before {
  content: "\f04e7";
}

.mdi-sync-circle::before {
  content: "\f1378";
}

.mdi-sync-off::before {
  content: "\f04e8";
}

.mdi-tab::before {
  content: "\f04e9";
}

.mdi-tab-minus::before {
  content: "\f0b4b";
}

.mdi-tab-plus::before {
  content: "\f075c";
}

.mdi-tab-remove::before {
  content: "\f0b4c";
}

.mdi-tab-unselected::before {
  content: "\f04ea";
}

.mdi-table::before {
  content: "\f04eb";
}

.mdi-table-account::before {
  content: "\f13b9";
}

.mdi-table-alert::before {
  content: "\f13ba";
}

.mdi-table-arrow-down::before {
  content: "\f13bb";
}

.mdi-table-arrow-left::before {
  content: "\f13bc";
}

.mdi-table-arrow-right::before {
  content: "\f13bd";
}

.mdi-table-arrow-up::before {
  content: "\f13be";
}

.mdi-table-border::before {
  content: "\f0a18";
}

.mdi-table-cancel::before {
  content: "\f13bf";
}

.mdi-table-chair::before {
  content: "\f1061";
}

.mdi-table-check::before {
  content: "\f13c0";
}

.mdi-table-clock::before {
  content: "\f13c1";
}

.mdi-table-cog::before {
  content: "\f13c2";
}

.mdi-table-column::before {
  content: "\f0835";
}

.mdi-table-column-plus-after::before {
  content: "\f04ec";
}

.mdi-table-column-plus-before::before {
  content: "\f04ed";
}

.mdi-table-column-remove::before {
  content: "\f04ee";
}

.mdi-table-column-width::before {
  content: "\f04ef";
}

.mdi-table-edit::before {
  content: "\f04f0";
}

.mdi-table-eye::before {
  content: "\f1094";
}

.mdi-table-eye-off::before {
  content: "\f13c3";
}

.mdi-table-furniture::before {
  content: "\f05bc";
}

.mdi-table-headers-eye::before {
  content: "\f121d";
}

.mdi-table-headers-eye-off::before {
  content: "\f121e";
}

.mdi-table-heart::before {
  content: "\f13c4";
}

.mdi-table-key::before {
  content: "\f13c5";
}

.mdi-table-large::before {
  content: "\f04f1";
}

.mdi-table-large-plus::before {
  content: "\f0f87";
}

.mdi-table-large-remove::before {
  content: "\f0f88";
}

.mdi-table-lock::before {
  content: "\f13c6";
}

.mdi-table-merge-cells::before {
  content: "\f09a6";
}

.mdi-table-minus::before {
  content: "\f13c7";
}

.mdi-table-multiple::before {
  content: "\f13c8";
}

.mdi-table-network::before {
  content: "\f13c9";
}

.mdi-table-of-contents::before {
  content: "\f0836";
}

.mdi-table-off::before {
  content: "\f13ca";
}

.mdi-table-picnic::before {
  content: "\f1743";
}

.mdi-table-plus::before {
  content: "\f0a75";
}

.mdi-table-refresh::before {
  content: "\f13a0";
}

.mdi-table-remove::before {
  content: "\f0a76";
}

.mdi-table-row::before {
  content: "\f0837";
}

.mdi-table-row-height::before {
  content: "\f04f2";
}

.mdi-table-row-plus-after::before {
  content: "\f04f3";
}

.mdi-table-row-plus-before::before {
  content: "\f04f4";
}

.mdi-table-row-remove::before {
  content: "\f04f5";
}

.mdi-table-search::before {
  content: "\f090f";
}

.mdi-table-settings::before {
  content: "\f0838";
}

.mdi-table-split-cell::before {
  content: "\f142a";
}

.mdi-table-star::before {
  content: "\f13cb";
}

.mdi-table-sync::before {
  content: "\f13a1";
}

.mdi-table-tennis::before {
  content: "\f0e68";
}

.mdi-tablet::before {
  content: "\f04f6";
}

.mdi-tablet-android::before {
  content: "\f04f7";
}

.mdi-tablet-cellphone::before {
  content: "\f09a7";
}

.mdi-tablet-dashboard::before {
  content: "\f0ece";
}

.mdi-tablet-ipad::before {
  content: "\f04f8";
}

.mdi-taco::before {
  content: "\f0762";
}

.mdi-tag::before {
  content: "\f04f9";
}

.mdi-tag-arrow-down::before {
  content: "\f172b";
}

.mdi-tag-arrow-down-outline::before {
  content: "\f172c";
}

.mdi-tag-arrow-left::before {
  content: "\f172d";
}

.mdi-tag-arrow-left-outline::before {
  content: "\f172e";
}

.mdi-tag-arrow-right::before {
  content: "\f172f";
}

.mdi-tag-arrow-right-outline::before {
  content: "\f1730";
}

.mdi-tag-arrow-up::before {
  content: "\f1731";
}

.mdi-tag-arrow-up-outline::before {
  content: "\f1732";
}

.mdi-tag-faces::before {
  content: "\f04fa";
}

.mdi-tag-heart::before {
  content: "\f068b";
}

.mdi-tag-heart-outline::before {
  content: "\f0bcf";
}

.mdi-tag-minus::before {
  content: "\f0910";
}

.mdi-tag-minus-outline::before {
  content: "\f121f";
}

.mdi-tag-multiple::before {
  content: "\f04fb";
}

.mdi-tag-multiple-outline::before {
  content: "\f12f7";
}

.mdi-tag-off::before {
  content: "\f1220";
}

.mdi-tag-off-outline::before {
  content: "\f1221";
}

.mdi-tag-outline::before {
  content: "\f04fc";
}

.mdi-tag-plus::before {
  content: "\f0722";
}

.mdi-tag-plus-outline::before {
  content: "\f1222";
}

.mdi-tag-remove::before {
  content: "\f0723";
}

.mdi-tag-remove-outline::before {
  content: "\f1223";
}

.mdi-tag-text::before {
  content: "\f1224";
}

.mdi-tag-text-outline::before {
  content: "\f04fd";
}

.mdi-tailwind::before {
  content: "\f13ff";
}

.mdi-tank::before {
  content: "\f0d3a";
}

.mdi-tanker-truck::before {
  content: "\f0fe5";
}

.mdi-tape-drive::before {
  content: "\f16df";
}

.mdi-tape-measure::before {
  content: "\f0b4d";
}

.mdi-target::before {
  content: "\f04fe";
}

.mdi-target-account::before {
  content: "\f0bd0";
}

.mdi-target-variant::before {
  content: "\f0a77";
}

.mdi-taxi::before {
  content: "\f04ff";
}

.mdi-tea::before {
  content: "\f0d9e";
}

.mdi-tea-outline::before {
  content: "\f0d9f";
}

.mdi-teach::before {
  content: "\f0890";
}

.mdi-teamviewer::before {
  content: "\f0500";
}

.mdi-telegram::before {
  content: "\f0501";
}

.mdi-telescope::before {
  content: "\f0b4e";
}

.mdi-television::before {
  content: "\f0502";
}

.mdi-television-ambient-light::before {
  content: "\f1356";
}

.mdi-television-box::before {
  content: "\f0839";
}

.mdi-television-classic::before {
  content: "\f07f4";
}

.mdi-television-classic-off::before {
  content: "\f083a";
}

.mdi-television-clean::before {
  content: "\f1110";
}

.mdi-television-guide::before {
  content: "\f0503";
}

.mdi-television-off::before {
  content: "\f083b";
}

.mdi-television-pause::before {
  content: "\f0f89";
}

.mdi-television-play::before {
  content: "\f0ecf";
}

.mdi-television-stop::before {
  content: "\f0f8a";
}

.mdi-temperature-celsius::before {
  content: "\f0504";
}

.mdi-temperature-fahrenheit::before {
  content: "\f0505";
}

.mdi-temperature-kelvin::before {
  content: "\f0506";
}

.mdi-tennis::before {
  content: "\f0da0";
}

.mdi-tennis-ball::before {
  content: "\f0507";
}

.mdi-tent::before {
  content: "\f0508";
}

.mdi-terraform::before {
  content: "\f1062";
}

.mdi-terrain::before {
  content: "\f0509";
}

.mdi-test-tube::before {
  content: "\f0668";
}

.mdi-test-tube-empty::before {
  content: "\f0911";
}

.mdi-test-tube-off::before {
  content: "\f0912";
}

.mdi-text::before {
  content: "\f09a8";
}

.mdi-text-account::before {
  content: "\f1570";
}

.mdi-text-box::before {
  content: "\f021a";
}

.mdi-text-box-check::before {
  content: "\f0ea6";
}

.mdi-text-box-check-outline::before {
  content: "\f0ea7";
}

.mdi-text-box-minus::before {
  content: "\f0ea8";
}

.mdi-text-box-minus-outline::before {
  content: "\f0ea9";
}

.mdi-text-box-multiple::before {
  content: "\f0ab7";
}

.mdi-text-box-multiple-outline::before {
  content: "\f0ab8";
}

.mdi-text-box-outline::before {
  content: "\f09ed";
}

.mdi-text-box-plus::before {
  content: "\f0eaa";
}

.mdi-text-box-plus-outline::before {
  content: "\f0eab";
}

.mdi-text-box-remove::before {
  content: "\f0eac";
}

.mdi-text-box-remove-outline::before {
  content: "\f0ead";
}

.mdi-text-box-search::before {
  content: "\f0eae";
}

.mdi-text-box-search-outline::before {
  content: "\f0eaf";
}

.mdi-text-recognition::before {
  content: "\f113d";
}

.mdi-text-search::before {
  content: "\f13b8";
}

.mdi-text-shadow::before {
  content: "\f0669";
}

.mdi-text-short::before {
  content: "\f09a9";
}

.mdi-text-subject::before {
  content: "\f09aa";
}

.mdi-text-to-speech::before {
  content: "\f050a";
}

.mdi-text-to-speech-off::before {
  content: "\f050b";
}

.mdi-texture::before {
  content: "\f050c";
}

.mdi-texture-box::before {
  content: "\f0fe6";
}

.mdi-theater::before {
  content: "\f050d";
}

.mdi-theme-light-dark::before {
  content: "\f050e";
}

.mdi-thermometer::before {
  content: "\f050f";
}

.mdi-thermometer-alert::before {
  content: "\f0e01";
}

.mdi-thermometer-chevron-down::before {
  content: "\f0e02";
}

.mdi-thermometer-chevron-up::before {
  content: "\f0e03";
}

.mdi-thermometer-high::before {
  content: "\f10c2";
}

.mdi-thermometer-lines::before {
  content: "\f0510";
}

.mdi-thermometer-low::before {
  content: "\f10c3";
}

.mdi-thermometer-minus::before {
  content: "\f0e04";
}

.mdi-thermometer-off::before {
  content: "\f1531";
}

.mdi-thermometer-plus::before {
  content: "\f0e05";
}

.mdi-thermostat::before {
  content: "\f0393";
}

.mdi-thermostat-box::before {
  content: "\f0891";
}

.mdi-thought-bubble::before {
  content: "\f07f6";
}

.mdi-thought-bubble-outline::before {
  content: "\f07f7";
}

.mdi-thumb-down::before {
  content: "\f0511";
}

.mdi-thumb-down-outline::before {
  content: "\f0512";
}

.mdi-thumb-up::before {
  content: "\f0513";
}

.mdi-thumb-up-outline::before {
  content: "\f0514";
}

.mdi-thumbs-up-down::before {
  content: "\f0515";
}

.mdi-ticket::before {
  content: "\f0516";
}

.mdi-ticket-account::before {
  content: "\f0517";
}

.mdi-ticket-confirmation::before {
  content: "\f0518";
}

.mdi-ticket-confirmation-outline::before {
  content: "\f13aa";
}

.mdi-ticket-outline::before {
  content: "\f0913";
}

.mdi-ticket-percent::before {
  content: "\f0724";
}

.mdi-ticket-percent-outline::before {
  content: "\f142b";
}

.mdi-tie::before {
  content: "\f0519";
}

.mdi-tilde::before {
  content: "\f0725";
}

.mdi-timelapse::before {
  content: "\f051a";
}

.mdi-timeline::before {
  content: "\f0bd1";
}

.mdi-timeline-alert::before {
  content: "\f0f95";
}

.mdi-timeline-alert-outline::before {
  content: "\f0f98";
}

.mdi-timeline-check::before {
  content: "\f1532";
}

.mdi-timeline-check-outline::before {
  content: "\f1533";
}

.mdi-timeline-clock::before {
  content: "\f11fb";
}

.mdi-timeline-clock-outline::before {
  content: "\f11fc";
}

.mdi-timeline-help::before {
  content: "\f0f99";
}

.mdi-timeline-help-outline::before {
  content: "\f0f9a";
}

.mdi-timeline-minus::before {
  content: "\f1534";
}

.mdi-timeline-minus-outline::before {
  content: "\f1535";
}

.mdi-timeline-outline::before {
  content: "\f0bd2";
}

.mdi-timeline-plus::before {
  content: "\f0f96";
}

.mdi-timeline-plus-outline::before {
  content: "\f0f97";
}

.mdi-timeline-remove::before {
  content: "\f1536";
}

.mdi-timeline-remove-outline::before {
  content: "\f1537";
}

.mdi-timeline-text::before {
  content: "\f0bd3";
}

.mdi-timeline-text-outline::before {
  content: "\f0bd4";
}

.mdi-timer::before {
  content: "\f13ab";
}

.mdi-timer-10::before {
  content: "\f051c";
}

.mdi-timer-3::before {
  content: "\f051d";
}

.mdi-timer-off::before {
  content: "\f13ac";
}

.mdi-timer-off-outline::before {
  content: "\f051e";
}

.mdi-timer-outline::before {
  content: "\f051b";
}

.mdi-timer-sand::before {
  content: "\f051f";
}

.mdi-timer-sand-empty::before {
  content: "\f06ad";
}

.mdi-timer-sand-full::before {
  content: "\f078c";
}

.mdi-timetable::before {
  content: "\f0520";
}

.mdi-toaster::before {
  content: "\f1063";
}

.mdi-toaster-off::before {
  content: "\f11b7";
}

.mdi-toaster-oven::before {
  content: "\f0cd3";
}

.mdi-toggle-switch::before {
  content: "\f0521";
}

.mdi-toggle-switch-off::before {
  content: "\f0522";
}

.mdi-toggle-switch-off-outline::before {
  content: "\f0a19";
}

.mdi-toggle-switch-outline::before {
  content: "\f0a1a";
}

.mdi-toilet::before {
  content: "\f09ab";
}

.mdi-toolbox::before {
  content: "\f09ac";
}

.mdi-toolbox-outline::before {
  content: "\f09ad";
}

.mdi-tools::before {
  content: "\f1064";
}

.mdi-tooltip::before {
  content: "\f0523";
}

.mdi-tooltip-account::before {
  content: "\f000c";
}

.mdi-tooltip-check::before {
  content: "\f155c";
}

.mdi-tooltip-check-outline::before {
  content: "\f155d";
}

.mdi-tooltip-edit::before {
  content: "\f0524";
}

.mdi-tooltip-edit-outline::before {
  content: "\f12c5";
}

.mdi-tooltip-image::before {
  content: "\f0525";
}

.mdi-tooltip-image-outline::before {
  content: "\f0bd5";
}

.mdi-tooltip-minus::before {
  content: "\f155e";
}

.mdi-tooltip-minus-outline::before {
  content: "\f155f";
}

.mdi-tooltip-outline::before {
  content: "\f0526";
}

.mdi-tooltip-plus::before {
  content: "\f0bd6";
}

.mdi-tooltip-plus-outline::before {
  content: "\f0527";
}

.mdi-tooltip-remove::before {
  content: "\f1560";
}

.mdi-tooltip-remove-outline::before {
  content: "\f1561";
}

.mdi-tooltip-text::before {
  content: "\f0528";
}

.mdi-tooltip-text-outline::before {
  content: "\f0bd7";
}

.mdi-tooth::before {
  content: "\f08c3";
}

.mdi-tooth-outline::before {
  content: "\f0529";
}

.mdi-toothbrush::before {
  content: "\f1129";
}

.mdi-toothbrush-electric::before {
  content: "\f112c";
}

.mdi-toothbrush-paste::before {
  content: "\f112a";
}

.mdi-torch::before {
  content: "\f1606";
}

.mdi-tortoise::before {
  content: "\f0d3b";
}

.mdi-toslink::before {
  content: "\f12b8";
}

.mdi-tournament::before {
  content: "\f09ae";
}

.mdi-tow-truck::before {
  content: "\f083c";
}

.mdi-tower-beach::before {
  content: "\f0681";
}

.mdi-tower-fire::before {
  content: "\f0682";
}

.mdi-toy-brick::before {
  content: "\f1288";
}

.mdi-toy-brick-marker::before {
  content: "\f1289";
}

.mdi-toy-brick-marker-outline::before {
  content: "\f128a";
}

.mdi-toy-brick-minus::before {
  content: "\f128b";
}

.mdi-toy-brick-minus-outline::before {
  content: "\f128c";
}

.mdi-toy-brick-outline::before {
  content: "\f128d";
}

.mdi-toy-brick-plus::before {
  content: "\f128e";
}

.mdi-toy-brick-plus-outline::before {
  content: "\f128f";
}

.mdi-toy-brick-remove::before {
  content: "\f1290";
}

.mdi-toy-brick-remove-outline::before {
  content: "\f1291";
}

.mdi-toy-brick-search::before {
  content: "\f1292";
}

.mdi-toy-brick-search-outline::before {
  content: "\f1293";
}

.mdi-track-light::before {
  content: "\f0914";
}

.mdi-trackpad::before {
  content: "\f07f8";
}

.mdi-trackpad-lock::before {
  content: "\f0933";
}

.mdi-tractor::before {
  content: "\f0892";
}

.mdi-tractor-variant::before {
  content: "\f14c4";
}

.mdi-trademark::before {
  content: "\f0a78";
}

.mdi-traffic-cone::before {
  content: "\f137c";
}

.mdi-traffic-light::before {
  content: "\f052b";
}

.mdi-train::before {
  content: "\f052c";
}

.mdi-train-car::before {
  content: "\f0bd8";
}

.mdi-train-car-passenger::before {
  content: "\f1733";
}

.mdi-train-car-passenger-door::before {
  content: "\f1734";
}

.mdi-train-car-passenger-door-open::before {
  content: "\f1735";
}

.mdi-train-car-passenger-variant::before {
  content: "\f1736";
}

.mdi-train-variant::before {
  content: "\f08c4";
}

.mdi-tram::before {
  content: "\f052d";
}

.mdi-tram-side::before {
  content: "\f0fe7";
}

.mdi-transcribe::before {
  content: "\f052e";
}

.mdi-transcribe-close::before {
  content: "\f052f";
}

.mdi-transfer::before {
  content: "\f1065";
}

.mdi-transfer-down::before {
  content: "\f0da1";
}

.mdi-transfer-left::before {
  content: "\f0da2";
}

.mdi-transfer-right::before {
  content: "\f0530";
}

.mdi-transfer-up::before {
  content: "\f0da3";
}

.mdi-transit-connection::before {
  content: "\f0d3c";
}

.mdi-transit-connection-horizontal::before {
  content: "\f1546";
}

.mdi-transit-connection-variant::before {
  content: "\f0d3d";
}

.mdi-transit-detour::before {
  content: "\f0f8b";
}

.mdi-transit-skip::before {
  content: "\f1515";
}

.mdi-transit-transfer::before {
  content: "\f06ae";
}

.mdi-transition::before {
  content: "\f0915";
}

.mdi-transition-masked::before {
  content: "\f0916";
}

.mdi-translate::before {
  content: "\f05ca";
}

.mdi-translate-off::before {
  content: "\f0e06";
}

.mdi-transmission-tower::before {
  content: "\f0d3e";
}

.mdi-trash-can::before {
  content: "\f0a79";
}

.mdi-trash-can-outline::before {
  content: "\f0a7a";
}

.mdi-tray::before {
  content: "\f1294";
}

.mdi-tray-alert::before {
  content: "\f1295";
}

.mdi-tray-full::before {
  content: "\f1296";
}

.mdi-tray-minus::before {
  content: "\f1297";
}

.mdi-tray-plus::before {
  content: "\f1298";
}

.mdi-tray-remove::before {
  content: "\f1299";
}

.mdi-treasure-chest::before {
  content: "\f0726";
}

.mdi-tree::before {
  content: "\f0531";
}

.mdi-tree-outline::before {
  content: "\f0e69";
}

.mdi-trello::before {
  content: "\f0532";
}

.mdi-trending-down::before {
  content: "\f0533";
}

.mdi-trending-neutral::before {
  content: "\f0534";
}

.mdi-trending-up::before {
  content: "\f0535";
}

.mdi-triangle::before {
  content: "\f0536";
}

.mdi-triangle-outline::before {
  content: "\f0537";
}

.mdi-triangle-wave::before {
  content: "\f147c";
}

.mdi-triforce::before {
  content: "\f0bd9";
}

.mdi-trophy::before {
  content: "\f0538";
}

.mdi-trophy-award::before {
  content: "\f0539";
}

.mdi-trophy-broken::before {
  content: "\f0da4";
}

.mdi-trophy-outline::before {
  content: "\f053a";
}

.mdi-trophy-variant::before {
  content: "\f053b";
}

.mdi-trophy-variant-outline::before {
  content: "\f053c";
}

.mdi-truck::before {
  content: "\f053d";
}

.mdi-truck-check::before {
  content: "\f0cd4";
}

.mdi-truck-check-outline::before {
  content: "\f129a";
}

.mdi-truck-delivery::before {
  content: "\f053e";
}

.mdi-truck-delivery-outline::before {
  content: "\f129b";
}

.mdi-truck-fast::before {
  content: "\f0788";
}

.mdi-truck-fast-outline::before {
  content: "\f129c";
}

.mdi-truck-outline::before {
  content: "\f129d";
}

.mdi-truck-trailer::before {
  content: "\f0727";
}

.mdi-trumpet::before {
  content: "\f1096";
}

.mdi-tshirt-crew::before {
  content: "\f0a7b";
}

.mdi-tshirt-crew-outline::before {
  content: "\f053f";
}

.mdi-tshirt-v::before {
  content: "\f0a7c";
}

.mdi-tshirt-v-outline::before {
  content: "\f0540";
}

.mdi-tumble-dryer::before {
  content: "\f0917";
}

.mdi-tumble-dryer-alert::before {
  content: "\f11ba";
}

.mdi-tumble-dryer-off::before {
  content: "\f11bb";
}

.mdi-tune::before {
  content: "\f062e";
}

.mdi-tune-variant::before {
  content: "\f1542";
}

.mdi-tune-vertical::before {
  content: "\f066a";
}

.mdi-tune-vertical-variant::before {
  content: "\f1543";
}

.mdi-turkey::before {
  content: "\f171b";
}

.mdi-turnstile::before {
  content: "\f0cd5";
}

.mdi-turnstile-outline::before {
  content: "\f0cd6";
}

.mdi-turtle::before {
  content: "\f0cd7";
}

.mdi-twitch::before {
  content: "\f0543";
}

.mdi-twitter::before {
  content: "\f0544";
}

.mdi-twitter-retweet::before {
  content: "\f0547";
}

.mdi-two-factor-authentication::before {
  content: "\f09af";
}

.mdi-typewriter::before {
  content: "\f0f2d";
}

.mdi-ubisoft::before {
  content: "\f0bda";
}

.mdi-ubuntu::before {
  content: "\f0548";
}

.mdi-ufo::before {
  content: "\f10c4";
}

.mdi-ufo-outline::before {
  content: "\f10c5";
}

.mdi-ultra-high-definition::before {
  content: "\f07f9";
}

.mdi-umbraco::before {
  content: "\f0549";
}

.mdi-umbrella::before {
  content: "\f054a";
}

.mdi-umbrella-closed::before {
  content: "\f09b0";
}

.mdi-umbrella-closed-outline::before {
  content: "\f13e2";
}

.mdi-umbrella-closed-variant::before {
  content: "\f13e1";
}

.mdi-umbrella-outline::before {
  content: "\f054b";
}

.mdi-undo::before {
  content: "\f054c";
}

.mdi-undo-variant::before {
  content: "\f054d";
}

.mdi-unfold-less-horizontal::before {
  content: "\f054e";
}

.mdi-unfold-less-vertical::before {
  content: "\f0760";
}

.mdi-unfold-more-horizontal::before {
  content: "\f054f";
}

.mdi-unfold-more-vertical::before {
  content: "\f0761";
}

.mdi-ungroup::before {
  content: "\f0550";
}

.mdi-unicode::before {
  content: "\f0ed0";
}

.mdi-unicorn::before {
  content: "\f15c2";
}

.mdi-unicorn-variant::before {
  content: "\f15c3";
}

.mdi-unicycle::before {
  content: "\f15e5";
}

.mdi-unity::before {
  content: "\f06af";
}

.mdi-unreal::before {
  content: "\f09b1";
}

.mdi-untappd::before {
  content: "\f0551";
}

.mdi-update::before {
  content: "\f06b0";
}

.mdi-upload::before {
  content: "\f0552";
}

.mdi-upload-lock::before {
  content: "\f1373";
}

.mdi-upload-lock-outline::before {
  content: "\f1374";
}

.mdi-upload-multiple::before {
  content: "\f083d";
}

.mdi-upload-network::before {
  content: "\f06f6";
}

.mdi-upload-network-outline::before {
  content: "\f0cd8";
}

.mdi-upload-off::before {
  content: "\f10c6";
}

.mdi-upload-off-outline::before {
  content: "\f10c7";
}

.mdi-upload-outline::before {
  content: "\f0e07";
}

.mdi-usb::before {
  content: "\f0553";
}

.mdi-usb-flash-drive::before {
  content: "\f129e";
}

.mdi-usb-flash-drive-outline::before {
  content: "\f129f";
}

.mdi-usb-port::before {
  content: "\f11f0";
}

.mdi-valve::before {
  content: "\f1066";
}

.mdi-valve-closed::before {
  content: "\f1067";
}

.mdi-valve-open::before {
  content: "\f1068";
}

.mdi-van-passenger::before {
  content: "\f07fa";
}

.mdi-van-utility::before {
  content: "\f07fb";
}

.mdi-vanish::before {
  content: "\f07fc";
}

.mdi-vanish-quarter::before {
  content: "\f1554";
}

.mdi-vanity-light::before {
  content: "\f11e1";
}

.mdi-variable::before {
  content: "\f0ae7";
}

.mdi-variable-box::before {
  content: "\f1111";
}

.mdi-vector-arrange-above::before {
  content: "\f0554";
}

.mdi-vector-arrange-below::before {
  content: "\f0555";
}

.mdi-vector-bezier::before {
  content: "\f0ae8";
}

.mdi-vector-circle::before {
  content: "\f0556";
}

.mdi-vector-circle-variant::before {
  content: "\f0557";
}

.mdi-vector-combine::before {
  content: "\f0558";
}

.mdi-vector-curve::before {
  content: "\f0559";
}

.mdi-vector-difference::before {
  content: "\f055a";
}

.mdi-vector-difference-ab::before {
  content: "\f055b";
}

.mdi-vector-difference-ba::before {
  content: "\f055c";
}

.mdi-vector-ellipse::before {
  content: "\f0893";
}

.mdi-vector-intersection::before {
  content: "\f055d";
}

.mdi-vector-line::before {
  content: "\f055e";
}

.mdi-vector-link::before {
  content: "\f0fe8";
}

.mdi-vector-point::before {
  content: "\f055f";
}

.mdi-vector-polygon::before {
  content: "\f0560";
}

.mdi-vector-polyline::before {
  content: "\f0561";
}

.mdi-vector-polyline-edit::before {
  content: "\f1225";
}

.mdi-vector-polyline-minus::before {
  content: "\f1226";
}

.mdi-vector-polyline-plus::before {
  content: "\f1227";
}

.mdi-vector-polyline-remove::before {
  content: "\f1228";
}

.mdi-vector-radius::before {
  content: "\f074a";
}

.mdi-vector-rectangle::before {
  content: "\f05c6";
}

.mdi-vector-selection::before {
  content: "\f0562";
}

.mdi-vector-square::before {
  content: "\f0001";
}

.mdi-vector-triangle::before {
  content: "\f0563";
}

.mdi-vector-union::before {
  content: "\f0564";
}

.mdi-vhs::before {
  content: "\f0a1b";
}

.mdi-vibrate::before {
  content: "\f0566";
}

.mdi-vibrate-off::before {
  content: "\f0cd9";
}

.mdi-video::before {
  content: "\f0567";
}

.mdi-video-3d::before {
  content: "\f07fd";
}

.mdi-video-3d-off::before {
  content: "\f13d9";
}

.mdi-video-3d-variant::before {
  content: "\f0ed1";
}

.mdi-video-4k-box::before {
  content: "\f083e";
}

.mdi-video-account::before {
  content: "\f0919";
}

.mdi-video-box::before {
  content: "\f00fd";
}

.mdi-video-box-off::before {
  content: "\f00fe";
}

.mdi-video-check::before {
  content: "\f1069";
}

.mdi-video-check-outline::before {
  content: "\f106a";
}

.mdi-video-high-definition::before {
  content: "\f152e";
}

.mdi-video-image::before {
  content: "\f091a";
}

.mdi-video-input-antenna::before {
  content: "\f083f";
}

.mdi-video-input-component::before {
  content: "\f0840";
}

.mdi-video-input-hdmi::before {
  content: "\f0841";
}

.mdi-video-input-scart::before {
  content: "\f0f8c";
}

.mdi-video-input-svideo::before {
  content: "\f0842";
}

.mdi-video-minus::before {
  content: "\f09b2";
}

.mdi-video-minus-outline::before {
  content: "\f02ba";
}

.mdi-video-off::before {
  content: "\f0568";
}

.mdi-video-off-outline::before {
  content: "\f0bdb";
}

.mdi-video-outline::before {
  content: "\f0bdc";
}

.mdi-video-plus::before {
  content: "\f09b3";
}

.mdi-video-plus-outline::before {
  content: "\f01d3";
}

.mdi-video-stabilization::before {
  content: "\f091b";
}

.mdi-video-switch::before {
  content: "\f0569";
}

.mdi-video-switch-outline::before {
  content: "\f0790";
}

.mdi-video-vintage::before {
  content: "\f0a1c";
}

.mdi-video-wireless::before {
  content: "\f0ed2";
}

.mdi-video-wireless-outline::before {
  content: "\f0ed3";
}

.mdi-view-agenda::before {
  content: "\f056a";
}

.mdi-view-agenda-outline::before {
  content: "\f11d8";
}

.mdi-view-array::before {
  content: "\f056b";
}

.mdi-view-array-outline::before {
  content: "\f1485";
}

.mdi-view-carousel::before {
  content: "\f056c";
}

.mdi-view-carousel-outline::before {
  content: "\f1486";
}

.mdi-view-column::before {
  content: "\f056d";
}

.mdi-view-column-outline::before {
  content: "\f1487";
}

.mdi-view-comfy::before {
  content: "\f0e6a";
}

.mdi-view-comfy-outline::before {
  content: "\f1488";
}

.mdi-view-compact::before {
  content: "\f0e6b";
}

.mdi-view-compact-outline::before {
  content: "\f0e6c";
}

.mdi-view-dashboard::before {
  content: "\f056e";
}

.mdi-view-dashboard-outline::before {
  content: "\f0a1d";
}

.mdi-view-dashboard-variant::before {
  content: "\f0843";
}

.mdi-view-dashboard-variant-outline::before {
  content: "\f1489";
}

.mdi-view-day::before {
  content: "\f056f";
}

.mdi-view-day-outline::before {
  content: "\f148a";
}

.mdi-view-grid::before {
  content: "\f0570";
}

.mdi-view-grid-outline::before {
  content: "\f11d9";
}

.mdi-view-grid-plus::before {
  content: "\f0f8d";
}

.mdi-view-grid-plus-outline::before {
  content: "\f11da";
}

.mdi-view-headline::before {
  content: "\f0571";
}

.mdi-view-list::before {
  content: "\f0572";
}

.mdi-view-list-outline::before {
  content: "\f148b";
}

.mdi-view-module::before {
  content: "\f0573";
}

.mdi-view-module-outline::before {
  content: "\f148c";
}

.mdi-view-parallel::before {
  content: "\f0728";
}

.mdi-view-parallel-outline::before {
  content: "\f148d";
}

.mdi-view-quilt::before {
  content: "\f0574";
}

.mdi-view-quilt-outline::before {
  content: "\f148e";
}

.mdi-view-sequential::before {
  content: "\f0729";
}

.mdi-view-sequential-outline::before {
  content: "\f148f";
}

.mdi-view-split-horizontal::before {
  content: "\f0bcb";
}

.mdi-view-split-vertical::before {
  content: "\f0bcc";
}

.mdi-view-stream::before {
  content: "\f0575";
}

.mdi-view-stream-outline::before {
  content: "\f1490";
}

.mdi-view-week::before {
  content: "\f0576";
}

.mdi-view-week-outline::before {
  content: "\f1491";
}

.mdi-vimeo::before {
  content: "\f0577";
}

.mdi-violin::before {
  content: "\f060f";
}

.mdi-virtual-reality::before {
  content: "\f0894";
}

.mdi-virus::before {
  content: "\f13b6";
}

.mdi-virus-outline::before {
  content: "\f13b7";
}

.mdi-vk::before {
  content: "\f0579";
}

.mdi-vlc::before {
  content: "\f057c";
}

.mdi-voice-off::before {
  content: "\f0ed4";
}

.mdi-voicemail::before {
  content: "\f057d";
}

.mdi-volleyball::before {
  content: "\f09b4";
}

.mdi-volume-high::before {
  content: "\f057e";
}

.mdi-volume-low::before {
  content: "\f057f";
}

.mdi-volume-medium::before {
  content: "\f0580";
}

.mdi-volume-minus::before {
  content: "\f075e";
}

.mdi-volume-mute::before {
  content: "\f075f";
}

.mdi-volume-off::before {
  content: "\f0581";
}

.mdi-volume-plus::before {
  content: "\f075d";
}

.mdi-volume-source::before {
  content: "\f1120";
}

.mdi-volume-variant-off::before {
  content: "\f0e08";
}

.mdi-volume-vibrate::before {
  content: "\f1121";
}

.mdi-vote::before {
  content: "\f0a1f";
}

.mdi-vote-outline::before {
  content: "\f0a20";
}

.mdi-vpn::before {
  content: "\f0582";
}

.mdi-vuejs::before {
  content: "\f0844";
}

.mdi-vuetify::before {
  content: "\f0e6d";
}

.mdi-walk::before {
  content: "\f0583";
}

.mdi-wall::before {
  content: "\f07fe";
}

.mdi-wall-sconce::before {
  content: "\f091c";
}

.mdi-wall-sconce-flat::before {
  content: "\f091d";
}

.mdi-wall-sconce-flat-variant::before {
  content: "\f041c";
}

.mdi-wall-sconce-round::before {
  content: "\f0748";
}

.mdi-wall-sconce-round-variant::before {
  content: "\f091e";
}

.mdi-wallet::before {
  content: "\f0584";
}

.mdi-wallet-giftcard::before {
  content: "\f0585";
}

.mdi-wallet-membership::before {
  content: "\f0586";
}

.mdi-wallet-outline::before {
  content: "\f0bdd";
}

.mdi-wallet-plus::before {
  content: "\f0f8e";
}

.mdi-wallet-plus-outline::before {
  content: "\f0f8f";
}

.mdi-wallet-travel::before {
  content: "\f0587";
}

.mdi-wallpaper::before {
  content: "\f0e09";
}

.mdi-wan::before {
  content: "\f0588";
}

.mdi-wardrobe::before {
  content: "\f0f90";
}

.mdi-wardrobe-outline::before {
  content: "\f0f91";
}

.mdi-warehouse::before {
  content: "\f0f81";
}

.mdi-washing-machine::before {
  content: "\f072a";
}

.mdi-washing-machine-alert::before {
  content: "\f11bc";
}

.mdi-washing-machine-off::before {
  content: "\f11bd";
}

.mdi-watch::before {
  content: "\f0589";
}

.mdi-watch-export::before {
  content: "\f058a";
}

.mdi-watch-export-variant::before {
  content: "\f0895";
}

.mdi-watch-import::before {
  content: "\f058b";
}

.mdi-watch-import-variant::before {
  content: "\f0896";
}

.mdi-watch-variant::before {
  content: "\f0897";
}

.mdi-watch-vibrate::before {
  content: "\f06b1";
}

.mdi-watch-vibrate-off::before {
  content: "\f0cda";
}

.mdi-water::before {
  content: "\f058c";
}

.mdi-water-alert::before {
  content: "\f1502";
}

.mdi-water-alert-outline::before {
  content: "\f1503";
}

.mdi-water-boiler::before {
  content: "\f0f92";
}

.mdi-water-boiler-alert::before {
  content: "\f11b3";
}

.mdi-water-boiler-off::before {
  content: "\f11b4";
}

.mdi-water-check::before {
  content: "\f1504";
}

.mdi-water-check-outline::before {
  content: "\f1505";
}

.mdi-water-minus::before {
  content: "\f1506";
}

.mdi-water-minus-outline::before {
  content: "\f1507";
}

.mdi-water-off::before {
  content: "\f058d";
}

.mdi-water-off-outline::before {
  content: "\f1508";
}

.mdi-water-outline::before {
  content: "\f0e0a";
}

.mdi-water-percent::before {
  content: "\f058e";
}

.mdi-water-percent-alert::before {
  content: "\f1509";
}

.mdi-water-plus::before {
  content: "\f150a";
}

.mdi-water-plus-outline::before {
  content: "\f150b";
}

.mdi-water-polo::before {
  content: "\f12a0";
}

.mdi-water-pump::before {
  content: "\f058f";
}

.mdi-water-pump-off::before {
  content: "\f0f93";
}

.mdi-water-remove::before {
  content: "\f150c";
}

.mdi-water-remove-outline::before {
  content: "\f150d";
}

.mdi-water-well::before {
  content: "\f106b";
}

.mdi-water-well-outline::before {
  content: "\f106c";
}

.mdi-watering-can::before {
  content: "\f1481";
}

.mdi-watering-can-outline::before {
  content: "\f1482";
}

.mdi-watermark::before {
  content: "\f0612";
}

.mdi-wave::before {
  content: "\f0f2e";
}

.mdi-waveform::before {
  content: "\f147d";
}

.mdi-waves::before {
  content: "\f078d";
}

.mdi-waze::before {
  content: "\f0bde";
}

.mdi-weather-cloudy::before {
  content: "\f0590";
}

.mdi-weather-cloudy-alert::before {
  content: "\f0f2f";
}

.mdi-weather-cloudy-arrow-right::before {
  content: "\f0e6e";
}

.mdi-weather-fog::before {
  content: "\f0591";
}

.mdi-weather-hail::before {
  content: "\f0592";
}

.mdi-weather-hazy::before {
  content: "\f0f30";
}

.mdi-weather-hurricane::before {
  content: "\f0898";
}

.mdi-weather-lightning::before {
  content: "\f0593";
}

.mdi-weather-lightning-rainy::before {
  content: "\f067e";
}

.mdi-weather-night::before {
  content: "\f0594";
}

.mdi-weather-night-partly-cloudy::before {
  content: "\f0f31";
}

.mdi-weather-partly-cloudy::before {
  content: "\f0595";
}

.mdi-weather-partly-lightning::before {
  content: "\f0f32";
}

.mdi-weather-partly-rainy::before {
  content: "\f0f33";
}

.mdi-weather-partly-snowy::before {
  content: "\f0f34";
}

.mdi-weather-partly-snowy-rainy::before {
  content: "\f0f35";
}

.mdi-weather-pouring::before {
  content: "\f0596";
}

.mdi-weather-rainy::before {
  content: "\f0597";
}

.mdi-weather-snowy::before {
  content: "\f0598";
}

.mdi-weather-snowy-heavy::before {
  content: "\f0f36";
}

.mdi-weather-snowy-rainy::before {
  content: "\f067f";
}

.mdi-weather-sunny::before {
  content: "\f0599";
}

.mdi-weather-sunny-alert::before {
  content: "\f0f37";
}

.mdi-weather-sunny-off::before {
  content: "\f14e4";
}

.mdi-weather-sunset::before {
  content: "\f059a";
}

.mdi-weather-sunset-down::before {
  content: "\f059b";
}

.mdi-weather-sunset-up::before {
  content: "\f059c";
}

.mdi-weather-tornado::before {
  content: "\f0f38";
}

.mdi-weather-windy::before {
  content: "\f059d";
}

.mdi-weather-windy-variant::before {
  content: "\f059e";
}

.mdi-web::before {
  content: "\f059f";
}

.mdi-web-box::before {
  content: "\f0f94";
}

.mdi-web-clock::before {
  content: "\f124a";
}

.mdi-webcam::before {
  content: "\f05a0";
}

.mdi-webcam-off::before {
  content: "\f1737";
}

.mdi-webhook::before {
  content: "\f062f";
}

.mdi-webpack::before {
  content: "\f072b";
}

.mdi-webrtc::before {
  content: "\f1248";
}

.mdi-wechat::before {
  content: "\f0611";
}

.mdi-weight::before {
  content: "\f05a1";
}

.mdi-weight-gram::before {
  content: "\f0d3f";
}

.mdi-weight-kilogram::before {
  content: "\f05a2";
}

.mdi-weight-lifter::before {
  content: "\f115d";
}

.mdi-weight-pound::before {
  content: "\f09b5";
}

.mdi-whatsapp::before {
  content: "\f05a3";
}

.mdi-wheel-barrow::before {
  content: "\f14f2";
}

.mdi-wheelchair-accessibility::before {
  content: "\f05a4";
}

.mdi-whistle::before {
  content: "\f09b6";
}

.mdi-whistle-outline::before {
  content: "\f12bc";
}

.mdi-white-balance-auto::before {
  content: "\f05a5";
}

.mdi-white-balance-incandescent::before {
  content: "\f05a6";
}

.mdi-white-balance-iridescent::before {
  content: "\f05a7";
}

.mdi-white-balance-sunny::before {
  content: "\f05a8";
}

.mdi-widgets::before {
  content: "\f072c";
}

.mdi-widgets-outline::before {
  content: "\f1355";
}

.mdi-wifi::before {
  content: "\f05a9";
}

.mdi-wifi-alert::before {
  content: "\f16b5";
}

.mdi-wifi-arrow-down::before {
  content: "\f16b6";
}

.mdi-wifi-arrow-left::before {
  content: "\f16b7";
}

.mdi-wifi-arrow-left-right::before {
  content: "\f16b8";
}

.mdi-wifi-arrow-right::before {
  content: "\f16b9";
}

.mdi-wifi-arrow-up::before {
  content: "\f16ba";
}

.mdi-wifi-arrow-up-down::before {
  content: "\f16bb";
}

.mdi-wifi-cancel::before {
  content: "\f16bc";
}

.mdi-wifi-check::before {
  content: "\f16bd";
}

.mdi-wifi-cog::before {
  content: "\f16be";
}

.mdi-wifi-lock::before {
  content: "\f16bf";
}

.mdi-wifi-lock-open::before {
  content: "\f16c0";
}

.mdi-wifi-marker::before {
  content: "\f16c1";
}

.mdi-wifi-minus::before {
  content: "\f16c2";
}

.mdi-wifi-off::before {
  content: "\f05aa";
}

.mdi-wifi-plus::before {
  content: "\f16c3";
}

.mdi-wifi-refresh::before {
  content: "\f16c4";
}

.mdi-wifi-remove::before {
  content: "\f16c5";
}

.mdi-wifi-settings::before {
  content: "\f16c6";
}

.mdi-wifi-star::before {
  content: "\f0e0b";
}

.mdi-wifi-strength-1::before {
  content: "\f091f";
}

.mdi-wifi-strength-1-alert::before {
  content: "\f0920";
}

.mdi-wifi-strength-1-lock::before {
  content: "\f0921";
}

.mdi-wifi-strength-1-lock-open::before {
  content: "\f16cb";
}

.mdi-wifi-strength-2::before {
  content: "\f0922";
}

.mdi-wifi-strength-2-alert::before {
  content: "\f0923";
}

.mdi-wifi-strength-2-lock::before {
  content: "\f0924";
}

.mdi-wifi-strength-2-lock-open::before {
  content: "\f16cc";
}

.mdi-wifi-strength-3::before {
  content: "\f0925";
}

.mdi-wifi-strength-3-alert::before {
  content: "\f0926";
}

.mdi-wifi-strength-3-lock::before {
  content: "\f0927";
}

.mdi-wifi-strength-3-lock-open::before {
  content: "\f16cd";
}

.mdi-wifi-strength-4::before {
  content: "\f0928";
}

.mdi-wifi-strength-4-alert::before {
  content: "\f0929";
}

.mdi-wifi-strength-4-lock::before {
  content: "\f092a";
}

.mdi-wifi-strength-4-lock-open::before {
  content: "\f16ce";
}

.mdi-wifi-strength-alert-outline::before {
  content: "\f092b";
}

.mdi-wifi-strength-lock-open-outline::before {
  content: "\f16cf";
}

.mdi-wifi-strength-lock-outline::before {
  content: "\f092c";
}

.mdi-wifi-strength-off::before {
  content: "\f092d";
}

.mdi-wifi-strength-off-outline::before {
  content: "\f092e";
}

.mdi-wifi-strength-outline::before {
  content: "\f092f";
}

.mdi-wifi-sync::before {
  content: "\f16c7";
}

.mdi-wikipedia::before {
  content: "\f05ac";
}

.mdi-wind-turbine::before {
  content: "\f0da5";
}

.mdi-window-close::before {
  content: "\f05ad";
}

.mdi-window-closed::before {
  content: "\f05ae";
}

.mdi-window-closed-variant::before {
  content: "\f11db";
}

.mdi-window-maximize::before {
  content: "\f05af";
}

.mdi-window-minimize::before {
  content: "\f05b0";
}

.mdi-window-open::before {
  content: "\f05b1";
}

.mdi-window-open-variant::before {
  content: "\f11dc";
}

.mdi-window-restore::before {
  content: "\f05b2";
}

.mdi-window-shutter::before {
  content: "\f111c";
}

.mdi-window-shutter-alert::before {
  content: "\f111d";
}

.mdi-window-shutter-open::before {
  content: "\f111e";
}

.mdi-windsock::before {
  content: "\f15fa";
}

.mdi-wiper::before {
  content: "\f0ae9";
}

.mdi-wiper-wash::before {
  content: "\f0da6";
}

.mdi-wizard-hat::before {
  content: "\f1477";
}

.mdi-wordpress::before {
  content: "\f05b4";
}

.mdi-wrap::before {
  content: "\f05b6";
}

.mdi-wrap-disabled::before {
  content: "\f0bdf";
}

.mdi-wrench::before {
  content: "\f05b7";
}

.mdi-wrench-outline::before {
  content: "\f0be0";
}

.mdi-xamarin::before {
  content: "\f0845";
}

.mdi-xamarin-outline::before {
  content: "\f0846";
}

.mdi-xing::before {
  content: "\f05be";
}

.mdi-xml::before {
  content: "\f05c0";
}

.mdi-xmpp::before {
  content: "\f07ff";
}

.mdi-y-combinator::before {
  content: "\f0624";
}

.mdi-yahoo::before {
  content: "\f0b4f";
}

.mdi-yeast::before {
  content: "\f05c1";
}

.mdi-yin-yang::before {
  content: "\f0680";
}

.mdi-yoga::before {
  content: "\f117c";
}

.mdi-youtube::before {
  content: "\f05c3";
}

.mdi-youtube-gaming::before {
  content: "\f0848";
}

.mdi-youtube-studio::before {
  content: "\f0847";
}

.mdi-youtube-subscription::before {
  content: "\f0d40";
}

.mdi-youtube-tv::before {
  content: "\f0448";
}

.mdi-yurt::before {
  content: "\f1516";
}

.mdi-z-wave::before {
  content: "\f0aea";
}

.mdi-zend::before {
  content: "\f0aeb";
}

.mdi-zigbee::before {
  content: "\f0d41";
}

.mdi-zip-box::before {
  content: "\f05c4";
}

.mdi-zip-box-outline::before {
  content: "\f0ffa";
}

.mdi-zip-disk::before {
  content: "\f0a23";
}

.mdi-zodiac-aquarius::before {
  content: "\f0a7d";
}

.mdi-zodiac-aries::before {
  content: "\f0a7e";
}

.mdi-zodiac-cancer::before {
  content: "\f0a7f";
}

.mdi-zodiac-capricorn::before {
  content: "\f0a80";
}

.mdi-zodiac-gemini::before {
  content: "\f0a81";
}

.mdi-zodiac-leo::before {
  content: "\f0a82";
}

.mdi-zodiac-libra::before {
  content: "\f0a83";
}

.mdi-zodiac-pisces::before {
  content: "\f0a84";
}

.mdi-zodiac-sagittarius::before {
  content: "\f0a85";
}

.mdi-zodiac-scorpio::before {
  content: "\f0a86";
}

.mdi-zodiac-taurus::before {
  content: "\f0a87";
}

.mdi-zodiac-virgo::before {
  content: "\f0a88";
}

.mdi-blank::before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@font-face {
  font-family: "boxicons";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/boxicons.eot");
  src: url("../fonts/boxicons.eot") format("embedded-opentype"), url("../fonts/boxicons.woff2") format("woff2"), url("../fonts/boxicons.woff") format("woff"), url("../fonts/boxicons.ttf") format("truetype"), url("../fonts/boxicons.svg?#boxicons") format("svg");
}
.bx {
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bx-ul {
  margin-left: 2em;
  padding-left: 0;
  list-style: none;
}

.bx-ul > li {
  position: relative;
}

.bx-ul .bx {
  font-size: inherit;
  line-height: inherit;
  position: absolute;
  left: -2em;
  width: 2em;
  text-align: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes burst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes burst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes flashing {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@keyframes flashing {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@keyframes fade-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@keyframes fade-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@keyframes fade-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%, 60%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.bx-spin {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.bx-spin-hover:hover {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.bx-tada {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.bx-tada-hover:hover {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.bx-flashing {
  -webkit-animation: flashing 1.5s infinite linear;
  animation: flashing 1.5s infinite linear;
}

.bx-flashing-hover:hover {
  -webkit-animation: flashing 1.5s infinite linear;
  animation: flashing 1.5s infinite linear;
}

.bx-burst {
  -webkit-animation: burst 1.5s infinite linear;
  animation: burst 1.5s infinite linear;
}

.bx-burst-hover:hover {
  -webkit-animation: burst 1.5s infinite linear;
  animation: burst 1.5s infinite linear;
}

.bx-fade-up {
  -webkit-animation: fade-up 1.5s infinite linear;
  animation: fade-up 1.5s infinite linear;
}

.bx-fade-up-hover:hover {
  -webkit-animation: fade-up 1.5s infinite linear;
  animation: fade-up 1.5s infinite linear;
}

.bx-fade-down {
  -webkit-animation: fade-down 1.5s infinite linear;
  animation: fade-down 1.5s infinite linear;
}

.bx-fade-down-hover:hover {
  -webkit-animation: fade-down 1.5s infinite linear;
  animation: fade-down 1.5s infinite linear;
}

.bx-fade-left {
  -webkit-animation: fade-left 1.5s infinite linear;
  animation: fade-left 1.5s infinite linear;
}

.bx-fade-left-hover:hover {
  -webkit-animation: fade-left 1.5s infinite linear;
  animation: fade-left 1.5s infinite linear;
}

.bx-fade-right {
  -webkit-animation: fade-right 1.5s infinite linear;
  animation: fade-right 1.5s infinite linear;
}

.bx-fade-right-hover:hover {
  -webkit-animation: fade-right 1.5s infinite linear;
  animation: fade-right 1.5s infinite linear;
}

.bx-xs {
  font-size: 1rem !important;
}

.bx-sm {
  font-size: 1.55rem !important;
}

.bx-md {
  font-size: 2.25rem !important;
}

.bx-fw {
  font-size: 1.2857142857em;
  line-height: 0.8em;
  width: 1.2857142857em;
  height: 0.8em;
  margin-top: -0.2em !important;
  vertical-align: middle;
}

.bx-lg {
  font-size: 3rem !important;
}

.bx-pull-left {
  float: left;
  margin-right: 0.3em !important;
}

.bx-pull-right {
  float: right;
  margin-left: 0.3em !important;
}

.bx-rotate-90 {
  transform: rotate(90deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.bx-rotate-180 {
  transform: rotate(180deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
}

.bx-rotate-270 {
  transform: rotate(270deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.bx-flip-horizontal {
  transform: scaleX(-1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
}

.bx-flip-vertical {
  transform: scaleY(-1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.bx-border {
  padding: 0.25em;
  border: 0.07em solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
}

.bx-border-circle {
  padding: 0.25em;
  border: 0.07em solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.bx-abacus:before {
  content: "\e900";
}

.bx-accessibility:before {
  content: "\e901";
}

.bx-add-to-queue:before {
  content: "\e902";
}

.bx-adjust:before {
  content: "\e903";
}

.bx-alarm:before {
  content: "\e904";
}

.bx-alarm-add:before {
  content: "\e905";
}

.bx-alarm-exclamation:before {
  content: "\e906";
}

.bx-alarm-off:before {
  content: "\e907";
}

.bx-alarm-snooze:before {
  content: "\e908";
}

.bx-album:before {
  content: "\e909";
}

.bx-align-justify:before {
  content: "\e90a";
}

.bx-align-left:before {
  content: "\e90b";
}

.bx-align-middle:before {
  content: "\e90c";
}

.bx-align-right:before {
  content: "\e90d";
}

.bx-analyse:before {
  content: "\e90e";
}

.bx-anchor:before {
  content: "\e90f";
}

.bx-angry:before {
  content: "\e910";
}

.bx-aperture:before {
  content: "\e911";
}

.bx-arch:before {
  content: "\e912";
}

.bx-archive:before {
  content: "\e913";
}

.bx-archive-in:before {
  content: "\e914";
}

.bx-archive-out:before {
  content: "\e915";
}

.bx-area:before {
  content: "\e916";
}

.bx-arrow-back:before {
  content: "\e917";
}

.bx-arrow-from-bottom:before {
  content: "\e918";
}

.bx-arrow-from-left:before {
  content: "\e919";
}

.bx-arrow-from-right:before {
  content: "\e91a";
}

.bx-arrow-from-top:before {
  content: "\e91b";
}

.bx-arrow-to-bottom:before {
  content: "\e91c";
}

.bx-arrow-to-left:before {
  content: "\e91d";
}

.bx-arrow-to-right:before {
  content: "\e91e";
}

.bx-arrow-to-top:before {
  content: "\e91f";
}

.bx-at:before {
  content: "\e920";
}

.bx-atom:before {
  content: "\e921";
}

.bx-award:before {
  content: "\e922";
}

.bx-badge:before {
  content: "\e923";
}

.bx-badge-check:before {
  content: "\e924";
}

.bx-ball:before {
  content: "\e925";
}

.bx-band-aid:before {
  content: "\e926";
}

.bx-bar-chart:before {
  content: "\e927";
}

.bx-bar-chart-alt:before {
  content: "\e928";
}

.bx-bar-chart-alt-2:before {
  content: "\e929";
}

.bx-bar-chart-square:before {
  content: "\e92a";
}

.bx-barcode:before {
  content: "\e92b";
}

.bx-barcode-reader:before {
  content: "\e92c";
}

.bx-baseball:before {
  content: "\e92d";
}

.bx-basket:before {
  content: "\e92e";
}

.bx-basketball:before {
  content: "\e92f";
}

.bx-bath:before {
  content: "\e930";
}

.bx-battery:before {
  content: "\e931";
}

.bx-bed:before {
  content: "\e932";
}

.bx-been-here:before {
  content: "\e933";
}

.bx-beer:before {
  content: "\e934";
}

.bx-bell:before {
  content: "\e935";
}

.bx-bell-minus:before {
  content: "\e936";
}

.bx-bell-off:before {
  content: "\e937";
}

.bx-bell-plus:before {
  content: "\e938";
}

.bx-bible:before {
  content: "\e939";
}

.bx-bitcoin:before {
  content: "\e93a";
}

.bx-blanket:before {
  content: "\e93b";
}

.bx-block:before {
  content: "\e93c";
}

.bx-bluetooth:before {
  content: "\e93d";
}

.bx-body:before {
  content: "\e93e";
}

.bx-bold:before {
  content: "\e93f";
}

.bx-bolt-circle:before {
  content: "\e940";
}

.bx-bomb:before {
  content: "\e941";
}

.bx-bone:before {
  content: "\e942";
}

.bx-bong:before {
  content: "\e943";
}

.bx-book:before {
  content: "\e944";
}

.bx-book-add:before {
  content: "\e945";
}

.bx-book-alt:before {
  content: "\e946";
}

.bx-book-bookmark:before {
  content: "\e947";
}

.bx-book-content:before {
  content: "\e948";
}

.bx-book-heart:before {
  content: "\e949";
}

.bx-bookmark:before {
  content: "\e94a";
}

.bx-bookmark-alt:before {
  content: "\e94b";
}

.bx-bookmark-alt-minus:before {
  content: "\e94c";
}

.bx-bookmark-alt-plus:before {
  content: "\e94d";
}

.bx-bookmark-heart:before {
  content: "\e94e";
}

.bx-bookmark-minus:before {
  content: "\e94f";
}

.bx-bookmark-plus:before {
  content: "\e950";
}

.bx-bookmarks:before {
  content: "\e951";
}

.bx-book-open:before {
  content: "\e952";
}

.bx-book-reader:before {
  content: "\e953";
}

.bx-border-all:before {
  content: "\e954";
}

.bx-border-bottom:before {
  content: "\e955";
}

.bx-border-inner:before {
  content: "\e956";
}

.bx-border-left:before {
  content: "\e957";
}

.bx-border-none:before {
  content: "\e958";
}

.bx-border-outer:before {
  content: "\e959";
}

.bx-border-radius:before {
  content: "\e95a";
}

.bx-border-right:before {
  content: "\e95b";
}

.bx-border-top:before {
  content: "\e95c";
}

.bx-bot:before {
  content: "\e95d";
}

.bx-bowling-ball:before {
  content: "\e95e";
}

.bx-box:before {
  content: "\e95f";
}

.bx-bracket:before {
  content: "\e960";
}

.bx-braille:before {
  content: "\e961";
}

.bx-brain:before {
  content: "\e962";
}

.bx-briefcase:before {
  content: "\e963";
}

.bx-briefcase-alt:before {
  content: "\e964";
}

.bx-briefcase-alt-2:before {
  content: "\e965";
}

.bx-brightness:before {
  content: "\e966";
}

.bx-brightness-half:before {
  content: "\e967";
}

.bx-broadcast:before {
  content: "\e968";
}

.bx-brush:before {
  content: "\e969";
}

.bx-brush-alt:before {
  content: "\e96a";
}

.bx-bug:before {
  content: "\e96b";
}

.bx-bug-alt:before {
  content: "\e96c";
}

.bx-building:before {
  content: "\e96d";
}

.bx-building-house:before {
  content: "\e96e";
}

.bx-buildings:before {
  content: "\e96f";
}

.bx-bulb:before {
  content: "\e970";
}

.bx-bullseye:before {
  content: "\e971";
}

.bx-buoy:before {
  content: "\e972";
}

.bx-bus:before {
  content: "\e973";
}

.bx-bus-school:before {
  content: "\e974";
}

.bx-cabinet:before {
  content: "\e975";
}

.bx-cake:before {
  content: "\e976";
}

.bx-calculator:before {
  content: "\e977";
}

.bx-calendar:before {
  content: "\e978";
}

.bx-calendar-alt:before {
  content: "\e979";
}

.bx-calendar-check:before {
  content: "\e97a";
}

.bx-calendar-edit:before {
  content: "\e97b";
}

.bx-calendar-event:before {
  content: "\e97c";
}

.bx-calendar-exclamation:before {
  content: "\e97d";
}

.bx-calendar-heart:before {
  content: "\e97e";
}

.bx-calendar-minus:before {
  content: "\e97f";
}

.bx-calendar-plus:before {
  content: "\e980";
}

.bx-calendar-star:before {
  content: "\e981";
}

.bx-calendar-week:before {
  content: "\e982";
}

.bx-calendar-x:before {
  content: "\e983";
}

.bx-camera:before {
  content: "\e984";
}

.bx-camera-home:before {
  content: "\e985";
}

.bx-camera-movie:before {
  content: "\e986";
}

.bx-camera-off:before {
  content: "\e987";
}

.bx-capsule:before {
  content: "\e988";
}

.bx-captions:before {
  content: "\e989";
}

.bx-car:before {
  content: "\e98a";
}

.bx-card:before {
  content: "\e98b";
}

.bx-caret-down:before {
  content: "\e98c";
}

.bx-caret-down-circle:before {
  content: "\e98d";
}

.bx-caret-down-square:before {
  content: "\e98e";
}

.bx-caret-left:before {
  content: "\e98f";
}

.bx-caret-left-circle:before {
  content: "\e990";
}

.bx-caret-left-square:before {
  content: "\e991";
}

.bx-caret-right:before {
  content: "\e992";
}

.bx-caret-right-circle:before {
  content: "\e993";
}

.bx-caret-right-square:before {
  content: "\e994";
}

.bx-caret-up:before {
  content: "\e995";
}

.bx-caret-up-circle:before {
  content: "\e996";
}

.bx-caret-up-square:before {
  content: "\e997";
}

.bx-carousel:before {
  content: "\e998";
}

.bx-cart:before {
  content: "\e999";
}

.bx-cart-alt:before {
  content: "\e99a";
}

.bx-cast:before {
  content: "\e99b";
}

.bx-category:before {
  content: "\e99c";
}

.bx-category-alt:before {
  content: "\e99d";
}

.bx-cctv:before {
  content: "\e99e";
}

.bx-certification:before {
  content: "\e99f";
}

.bx-chair:before {
  content: "\e9a0";
}

.bx-chalkboard:before {
  content: "\e9a1";
}

.bx-chart:before {
  content: "\e9a2";
}

.bx-chat:before {
  content: "\e9a3";
}

.bx-check:before {
  content: "\e9a4";
}

.bx-checkbox:before {
  content: "\e9a5";
}

.bx-checkbox-checked:before {
  content: "\e9a6";
}

.bx-checkbox-square:before {
  content: "\e9a7";
}

.bx-check-circle:before {
  content: "\e9a8";
}

.bx-check-double:before {
  content: "\e9a9";
}

.bx-check-shield:before {
  content: "\e9aa";
}

.bx-check-square:before {
  content: "\e9ab";
}

.bx-chevron-down:before {
  content: "\e9ac";
}

.bx-chevron-down-circle:before {
  content: "\e9ad";
}

.bx-chevron-down-square:before {
  content: "\e9ae";
}

.bx-chevron-left:before {
  content: "\e9af";
}

.bx-chevron-left-circle:before {
  content: "\e9b0";
}

.bx-chevron-left-square:before {
  content: "\e9b1";
}

.bx-chevron-right:before {
  content: "\e9b2";
}

.bx-chevron-right-circle:before {
  content: "\e9b3";
}

.bx-chevron-right-square:before {
  content: "\e9b4";
}

.bx-chevrons-down:before {
  content: "\e9b5";
}

.bx-chevrons-left:before {
  content: "\e9b6";
}

.bx-chevrons-right:before {
  content: "\e9b7";
}

.bx-chevrons-up:before {
  content: "\e9b8";
}

.bx-chevron-up:before {
  content: "\e9b9";
}

.bx-chevron-up-circle:before {
  content: "\e9ba";
}

.bx-chevron-up-square:before {
  content: "\e9bb";
}

.bx-chip:before {
  content: "\e9bc";
}

.bx-church:before {
  content: "\e9bd";
}

.bx-circle:before {
  content: "\e9be";
}

.bx-clinic:before {
  content: "\e9bf";
}

.bx-clipboard:before {
  content: "\e9c0";
}

.bx-closet:before {
  content: "\e9c1";
}

.bx-cloud:before {
  content: "\e9c2";
}

.bx-cloud-download:before {
  content: "\e9c3";
}

.bx-cloud-drizzle:before {
  content: "\e9c4";
}

.bx-cloud-lightning:before {
  content: "\e9c5";
}

.bx-cloud-light-rain:before {
  content: "\e9c6";
}

.bx-cloud-rain:before {
  content: "\e9c7";
}

.bx-cloud-snow:before {
  content: "\e9c8";
}

.bx-cloud-upload:before {
  content: "\e9c9";
}

.bx-code:before {
  content: "\e9ca";
}

.bx-code-alt:before {
  content: "\e9cb";
}

.bx-code-block:before {
  content: "\e9cc";
}

.bx-code-curly:before {
  content: "\e9cd";
}

.bx-coffee:before {
  content: "\e9ce";
}

.bx-coffee-togo:before {
  content: "\e9cf";
}

.bx-cog:before {
  content: "\e9d0";
}

.bx-coin:before {
  content: "\e9d1";
}

.bx-coin-stack:before {
  content: "\e9d2";
}

.bx-collapse:before {
  content: "\e9d3";
}

.bx-collection:before {
  content: "\e9d4";
}

.bx-color-fill:before {
  content: "\e9d5";
}

.bx-columns:before {
  content: "\e9d6";
}

.bx-command:before {
  content: "\e9d7";
}

.bx-comment:before {
  content: "\e9d8";
}

.bx-comment-add:before {
  content: "\e9d9";
}

.bx-comment-check:before {
  content: "\e9da";
}

.bx-comment-detail:before {
  content: "\e9db";
}

.bx-comment-dots:before {
  content: "\e9dc";
}

.bx-comment-edit:before {
  content: "\e9dd";
}

.bx-comment-error:before {
  content: "\e9de";
}

.bx-comment-minus:before {
  content: "\e9df";
}

.bx-comment-x:before {
  content: "\e9e0";
}

.bx-compass:before {
  content: "\e9e1";
}

.bx-confused:before {
  content: "\e9e2";
}

.bx-conversation:before {
  content: "\e9e3";
}

.bx-cookie:before {
  content: "\e9e4";
}

.bx-cool:before {
  content: "\e9e5";
}

.bx-copy:before {
  content: "\e9e6";
}

.bx-copy-alt:before {
  content: "\e9e7";
}

.bx-copyright:before {
  content: "\e9e8";
}

.bx-credit-card:before {
  content: "\e9e9";
}

.bx-credit-card-alt:before {
  content: "\e9ea";
}

.bx-credit-card-front:before {
  content: "\e9eb";
}

.bx-crop:before {
  content: "\e9ec";
}

.bx-crosshair:before {
  content: "\e9ed";
}

.bx-crown:before {
  content: "\e9ee";
}

.bx-cube:before {
  content: "\e9ef";
}

.bx-cube-alt:before {
  content: "\e9f0";
}

.bx-cuboid:before {
  content: "\e9f1";
}

.bx-current-location:before {
  content: "\e9f2";
}

.bx-customize:before {
  content: "\e9f3";
}

.bx-cut:before {
  content: "\e9f4";
}

.bx-cycling:before {
  content: "\e9f5";
}

.bx-cylinder:before {
  content: "\e9f6";
}

.bx-data:before {
  content: "\e9f7";
}

.bx-desktop:before {
  content: "\e9f8";
}

.bx-detail:before {
  content: "\e9f9";
}

.bx-devices:before {
  content: "\e9fa";
}

.bx-dialpad:before {
  content: "\e9fb";
}

.bx-dialpad-alt:before {
  content: "\e9fc";
}

.bx-diamond:before {
  content: "\e9fd";
}

.bx-dice-1:before {
  content: "\e9fe";
}

.bx-dice-2:before {
  content: "\e9ff";
}

.bx-dice-3:before {
  content: "\ea00";
}

.bx-dice-4:before {
  content: "\ea01";
}

.bx-dice-5:before {
  content: "\ea02";
}

.bx-dice-6:before {
  content: "\ea03";
}

.bx-directions:before {
  content: "\ea04";
}

.bx-disc:before {
  content: "\ea05";
}

.bx-dish:before {
  content: "\ea06";
}

.bx-dislike:before {
  content: "\ea07";
}

.bx-dizzy:before {
  content: "\ea08";
}

.bx-dna:before {
  content: "\ea09";
}

.bx-dock-bottom:before {
  content: "\ea0a";
}

.bx-dock-left:before {
  content: "\ea0b";
}

.bx-dock-right:before {
  content: "\ea0c";
}

.bx-dock-top:before {
  content: "\ea0d";
}

.bx-dollar:before {
  content: "\ea0e";
}

.bx-dollar-circle:before {
  content: "\ea0f";
}

.bx-donate-blood:before {
  content: "\ea10";
}

.bx-donate-heart:before {
  content: "\ea11";
}

.bx-door-open:before {
  content: "\ea12";
}

.bx-dots-horizontal:before {
  content: "\ea13";
}

.bx-dots-horizontal-rounded:before {
  content: "\ea14";
}

.bx-dots-vertical:before {
  content: "\ea15";
}

.bx-dots-vertical-rounded:before {
  content: "\ea16";
}

.bx-doughnut-chart:before {
  content: "\ea17";
}

.bx-down-arrow:before {
  content: "\ea18";
}

.bx-down-arrow-alt:before {
  content: "\ea19";
}

.bx-down-arrow-circle:before {
  content: "\ea1a";
}

.bx-download:before {
  content: "\ea1b";
}

.bx-downvote:before {
  content: "\ea1c";
}

.bx-drink:before {
  content: "\ea1d";
}

.bx-droplet:before {
  content: "\ea1e";
}

.bx-dumbbell:before {
  content: "\ea1f";
}

.bx-duplicate:before {
  content: "\ea20";
}

.bx-edit:before {
  content: "\ea21";
}

.bx-edit-alt:before {
  content: "\ea22";
}

.bx-envelope:before {
  content: "\ea23";
}

.bx-envelope-open:before {
  content: "\ea24";
}

.bx-equalizer:before {
  content: "\ea25";
}

.bx-eraser:before {
  content: "\ea26";
}

.bx-error:before {
  content: "\ea27";
}

.bx-error-alt:before {
  content: "\ea28";
}

.bx-error-circle:before {
  content: "\ea29";
}

.bx-euro:before {
  content: "\ea2a";
}

.bx-exclude:before {
  content: "\ea2b";
}

.bx-exit:before {
  content: "\ea2c";
}

.bx-exit-fullscreen:before {
  content: "\ea2d";
}

.bx-expand:before {
  content: "\ea2e";
}

.bx-expand-alt:before {
  content: "\ea2f";
}

.bx-export:before {
  content: "\ea30";
}

.bx-extension:before {
  content: "\ea31";
}

.bx-face:before {
  content: "\ea32";
}

.bx-fast-forward:before {
  content: "\ea33";
}

.bx-fast-forward-circle:before {
  content: "\ea34";
}

.bx-female:before {
  content: "\ea35";
}

.bx-female-sign:before {
  content: "\ea36";
}

.bx-file:before {
  content: "\ea37";
}

.bx-file-blank:before {
  content: "\ea38";
}

.bx-file-find:before {
  content: "\ea39";
}

.bx-film:before {
  content: "\ea3a";
}

.bx-filter:before {
  content: "\ea3b";
}

.bx-filter-alt:before {
  content: "\ea3c";
}

.bx-fingerprint:before {
  content: "\ea3d";
}

.bx-first-aid:before {
  content: "\ea3e";
}

.bx-first-page:before {
  content: "\ea3f";
}

.bx-flag:before {
  content: "\ea40";
}

.bx-folder:before {
  content: "\ea41";
}

.bx-folder-minus:before {
  content: "\ea42";
}

.bx-folder-open:before {
  content: "\ea43";
}

.bx-folder-plus:before {
  content: "\ea44";
}

.bx-font:before {
  content: "\ea45";
}

.bx-font-color:before {
  content: "\ea46";
}

.bx-font-family:before {
  content: "\ea47";
}

.bx-font-size:before {
  content: "\ea48";
}

.bx-food-menu:before {
  content: "\ea49";
}

.bx-food-tag:before {
  content: "\ea4a";
}

.bx-football:before {
  content: "\ea4b";
}

.bx-fridge:before {
  content: "\ea4c";
}

.bx-fullscreen:before {
  content: "\ea4d";
}

.bx-game:before {
  content: "\ea4e";
}

.bx-gas-pump:before {
  content: "\ea4f";
}

.bx-ghost:before {
  content: "\ea50";
}

.bx-gift:before {
  content: "\ea51";
}

.bx-git-branch:before {
  content: "\ea52";
}

.bx-git-commit:before {
  content: "\ea53";
}

.bx-git-compare:before {
  content: "\ea54";
}

.bx-git-merge:before {
  content: "\ea55";
}

.bx-git-pull-request:before {
  content: "\ea56";
}

.bx-git-repo-forked:before {
  content: "\ea57";
}

.bx-glasses:before {
  content: "\ea58";
}

.bx-glasses-alt:before {
  content: "\ea59";
}

.bx-globe:before {
  content: "\ea5a";
}

.bx-globe-alt:before {
  content: "\ea5b";
}

.bx-grid:before {
  content: "\ea5c";
}

.bx-grid-alt:before {
  content: "\ea5d";
}

.bx-grid-horizontal:before {
  content: "\ea5e";
}

.bx-grid-small:before {
  content: "\ea5f";
}

.bx-grid-vertical:before {
  content: "\ea60";
}

.bx-group:before {
  content: "\ea61";
}

.bx-handicap:before {
  content: "\ea62";
}

.bx-happy:before {
  content: "\ea63";
}

.bx-happy-alt:before {
  content: "\ea64";
}

.bx-happy-beaming:before {
  content: "\ea65";
}

.bx-happy-heart-eyes:before {
  content: "\ea66";
}

.bx-hash:before {
  content: "\ea67";
}

.bx-hdd:before {
  content: "\ea68";
}

.bx-heading:before {
  content: "\ea69";
}

.bx-headphone:before {
  content: "\ea6a";
}

.bx-health:before {
  content: "\ea6b";
}

.bx-heart:before {
  content: "\ea6c";
}

.bx-heart-circle:before {
  content: "\ea6d";
}

.bx-heart-square:before {
  content: "\ea6e";
}

.bx-help-circle:before {
  content: "\ea6f";
}

.bx-hide:before {
  content: "\ea70";
}

.bx-highlight:before {
  content: "\ea71";
}

.bx-history:before {
  content: "\ea72";
}

.bx-hive:before {
  content: "\ea73";
}

.bx-home:before {
  content: "\ea74";
}

.bx-home-alt:before {
  content: "\ea75";
}

.bx-home-circle:before {
  content: "\ea76";
}

.bx-home-heart:before {
  content: "\ea77";
}

.bx-home-smile:before {
  content: "\ea78";
}

.bx-horizontal-center:before {
  content: "\ea79";
}

.bx-hotel:before {
  content: "\ea7a";
}

.bx-hourglass:before {
  content: "\ea7b";
}

.bx-id-card:before {
  content: "\ea7c";
}

.bx-image:before {
  content: "\ea7d";
}

.bx-image-add:before {
  content: "\ea7e";
}

.bx-image-alt:before {
  content: "\ea7f";
}

.bx-images:before {
  content: "\ea80";
}

.bx-import:before {
  content: "\ea81";
}

.bx-infinite:before {
  content: "\ea82";
}

.bx-info-circle:before {
  content: "\ea83";
}

.bx-info-square:before {
  content: "\ea84";
}

.bx-intersect:before {
  content: "\ea85";
}

.bx-italic:before {
  content: "\ea86";
}

.bx-joystick:before {
  content: "\ea87";
}

.bx-joystick-alt:before {
  content: "\ea88";
}

.bx-joystick-button:before {
  content: "\ea89";
}

.bx-key:before {
  content: "\ea8a";
}

.bx-label:before {
  content: "\ea8b";
}

.bx-landscape:before {
  content: "\ea8c";
}

.bx-laptop:before {
  content: "\ea8d";
}

.bx-last-page:before {
  content: "\ea8e";
}

.bx-laugh:before {
  content: "\ea8f";
}

.bx-layer:before {
  content: "\ea90";
}

.bx-layer-minus:before {
  content: "\ea91";
}

.bx-layer-plus:before {
  content: "\ea92";
}

.bx-layout:before {
  content: "\ea93";
}

.bx-left-arrow:before {
  content: "\ea94";
}

.bx-left-arrow-alt:before {
  content: "\ea95";
}

.bx-left-arrow-circle:before {
  content: "\ea96";
}

.bx-left-down-arrow-circle:before {
  content: "\ea97";
}

.bx-left-indent:before {
  content: "\ea98";
}

.bx-left-top-arrow-circle:before {
  content: "\ea99";
}

.bx-library:before {
  content: "\ea9a";
}

.bx-like:before {
  content: "\ea9b";
}

.bx-line-chart:before {
  content: "\ea9c";
}

.bx-line-chart-down:before {
  content: "\ea9d";
}

.bx-link:before {
  content: "\ea9e";
}

.bx-link-alt:before {
  content: "\ea9f";
}

.bx-link-external:before {
  content: "\eaa0";
}

.bx-lira:before {
  content: "\eaa1";
}

.bx-list-check:before {
  content: "\eaa2";
}

.bx-list-minus:before {
  content: "\eaa3";
}

.bx-list-ol:before {
  content: "\eaa4";
}

.bx-list-plus:before {
  content: "\eaa5";
}

.bx-list-ul:before {
  content: "\eaa6";
}

.bx-loader:before {
  content: "\eaa7";
}

.bx-loader-alt:before {
  content: "\eaa8";
}

.bx-loader-circle:before {
  content: "\eaa9";
}

.bx-location-plus:before {
  content: "\eaaa";
}

.bx-lock:before {
  content: "\eaab";
}

.bx-lock-alt:before {
  content: "\eaac";
}

.bx-lock-open:before {
  content: "\eaad";
}

.bx-lock-open-alt:before {
  content: "\eaae";
}

.bx-log-in:before {
  content: "\eaaf";
}

.bx-log-in-circle:before {
  content: "\eab0";
}

.bx-log-out:before {
  content: "\eab1";
}

.bx-log-out-circle:before {
  content: "\eab2";
}

.bx-low-vision:before {
  content: "\eab3";
}

.bx-magnet:before {
  content: "\eab4";
}

.bx-mail-send:before {
  content: "\eab5";
}

.bx-male:before {
  content: "\eab6";
}

.bx-male-sign:before {
  content: "\eab7";
}

.bx-map:before {
  content: "\eab8";
}

.bx-map-alt:before {
  content: "\eab9";
}

.bx-map-pin:before {
  content: "\eaba";
}

.bx-mask:before {
  content: "\eabb";
}

.bx-medal:before {
  content: "\eabc";
}

.bx-meh:before {
  content: "\eabd";
}

.bx-meh-alt:before {
  content: "\eabe";
}

.bx-meh-blank:before {
  content: "\eabf";
}

.bx-memory-card:before {
  content: "\eac0";
}

.bx-menu:before {
  content: "\eac1";
}

.bx-menu-alt-left:before {
  content: "\eac2";
}

.bx-menu-alt-right:before {
  content: "\eac3";
}

.bx-merge:before {
  content: "\eac4";
}

.bx-message:before {
  content: "\eac5";
}

.bx-message-add:before {
  content: "\eac6";
}

.bx-message-alt:before {
  content: "\eac7";
}

.bx-message-alt-add:before {
  content: "\eac8";
}

.bx-message-alt-check:before {
  content: "\eac9";
}

.bx-message-alt-detail:before {
  content: "\eaca";
}

.bx-message-alt-dots:before {
  content: "\eacb";
}

.bx-message-alt-edit:before {
  content: "\eacc";
}

.bx-message-alt-error:before {
  content: "\eacd";
}

.bx-message-alt-minus:before {
  content: "\eace";
}

.bx-message-alt-x:before {
  content: "\eacf";
}

.bx-message-check:before {
  content: "\ead0";
}

.bx-message-detail:before {
  content: "\ead1";
}

.bx-message-dots:before {
  content: "\ead2";
}

.bx-message-edit:before {
  content: "\ead3";
}

.bx-message-error:before {
  content: "\ead4";
}

.bx-message-minus:before {
  content: "\ead5";
}

.bx-message-rounded:before {
  content: "\ead6";
}

.bx-message-rounded-add:before {
  content: "\ead7";
}

.bx-message-rounded-check:before {
  content: "\ead8";
}

.bx-message-rounded-detail:before {
  content: "\ead9";
}

.bx-message-rounded-dots:before {
  content: "\eada";
}

.bx-message-rounded-edit:before {
  content: "\eadb";
}

.bx-message-rounded-error:before {
  content: "\eadc";
}

.bx-message-rounded-minus:before {
  content: "\eadd";
}

.bx-message-rounded-x:before {
  content: "\eade";
}

.bx-message-square:before {
  content: "\eadf";
}

.bx-message-square-add:before {
  content: "\eae0";
}

.bx-message-square-check:before {
  content: "\eae1";
}

.bx-message-square-detail:before {
  content: "\eae2";
}

.bx-message-square-dots:before {
  content: "\eae3";
}

.bx-message-square-edit:before {
  content: "\eae4";
}

.bx-message-square-error:before {
  content: "\eae5";
}

.bx-message-square-minus:before {
  content: "\eae6";
}

.bx-message-square-x:before {
  content: "\eae7";
}

.bx-message-x:before {
  content: "\eae8";
}

.bx-meteor:before {
  content: "\eae9";
}

.bx-microchip:before {
  content: "\eaea";
}

.bx-microphone:before {
  content: "\eaeb";
}

.bx-microphone-off:before {
  content: "\eaec";
}

.bx-minus:before {
  content: "\eaed";
}

.bx-minus-back:before {
  content: "\eaee";
}

.bx-minus-circle:before {
  content: "\eaef";
}

.bx-minus-front:before {
  content: "\eaf0";
}

.bx-mobile:before {
  content: "\eaf1";
}

.bx-mobile-alt:before {
  content: "\eaf2";
}

.bx-mobile-landscape:before {
  content: "\eaf3";
}

.bx-mobile-vibration:before {
  content: "\eaf4";
}

.bx-money:before {
  content: "\eaf5";
}

.bx-moon:before {
  content: "\eaf6";
}

.bx-mouse:before {
  content: "\eaf7";
}

.bx-mouse-alt:before {
  content: "\eaf8";
}

.bx-move:before {
  content: "\eaf9";
}

.bx-move-horizontal:before {
  content: "\eafa";
}

.bx-move-vertical:before {
  content: "\eafb";
}

.bx-movie:before {
  content: "\eafc";
}

.bx-movie-play:before {
  content: "\eafd";
}

.bx-music:before {
  content: "\eafe";
}

.bx-navigation:before {
  content: "\eaff";
}

.bx-network-chart:before {
  content: "\eb00";
}

.bx-news:before {
  content: "\eb01";
}

.bx-no-entry:before {
  content: "\eb02";
}

.bx-note:before {
  content: "\eb03";
}

.bx-notepad:before {
  content: "\eb04";
}

.bx-notification:before {
  content: "\eb05";
}

.bx-notification-off:before {
  content: "\eb06";
}

.bx-outline:before {
  content: "\eb07";
}

.bx-package:before {
  content: "\eb08";
}

.bx-paint:before {
  content: "\eb09";
}

.bx-paint-roll:before {
  content: "\eb0a";
}

.bx-palette:before {
  content: "\eb0b";
}

.bx-paperclip:before {
  content: "\eb0c";
}

.bx-paper-plane:before {
  content: "\eb0d";
}

.bx-paragraph:before {
  content: "\eb0e";
}

.bx-paste:before {
  content: "\eb0f";
}

.bx-pause:before {
  content: "\eb10";
}

.bx-pause-circle:before {
  content: "\eb11";
}

.bx-pen:before {
  content: "\eb12";
}

.bx-pencil:before {
  content: "\eb13";
}

.bx-phone:before {
  content: "\eb14";
}

.bx-phone-call:before {
  content: "\eb15";
}

.bx-phone-incoming:before {
  content: "\eb16";
}

.bx-phone-outgoing:before {
  content: "\eb17";
}

.bx-photo-album:before {
  content: "\eb18";
}

.bx-pie-chart:before {
  content: "\eb19";
}

.bx-pie-chart-alt:before {
  content: "\eb1a";
}

.bx-pie-chart-alt-2:before {
  content: "\eb1b";
}

.bx-pin:before {
  content: "\eb1c";
}

.bx-planet:before {
  content: "\eb1d";
}

.bx-play:before {
  content: "\eb1e";
}

.bx-play-circle:before {
  content: "\eb1f";
}

.bx-plug:before {
  content: "\eb20";
}

.bx-plus:before {
  content: "\eb21";
}

.bx-plus-circle:before {
  content: "\eb22";
}

.bx-plus-medical:before {
  content: "\eb23";
}

.bx-pointer:before {
  content: "\eb24";
}

.bx-poll:before {
  content: "\eb25";
}

.bx-polygon:before {
  content: "\eb26";
}

.bx-pound:before {
  content: "\eb27";
}

.bx-power-off:before {
  content: "\eb28";
}

.bx-printer:before {
  content: "\eb29";
}

.bx-pulse:before {
  content: "\eb2a";
}

.bx-purchase-tag:before {
  content: "\eb2b";
}

.bx-purchase-tag-alt:before {
  content: "\eb2c";
}

.bx-pyramid:before {
  content: "\eb2d";
}

.bx-question-mark:before {
  content: "\eb2e";
}

.bx-radar:before {
  content: "\eb2f";
}

.bx-radio:before {
  content: "\eb30";
}

.bx-radio-circle:before {
  content: "\eb31";
}

.bx-radio-circle-marked:before {
  content: "\eb32";
}

.bx-receipt:before {
  content: "\eb33";
}

.bx-rectangle:before {
  content: "\eb34";
}

.bx-recycle:before {
  content: "\eb35";
}

.bx-redo:before {
  content: "\eb36";
}

.bx-refresh:before {
  content: "\eb37";
}

.bx-rename:before {
  content: "\eb38";
}

.bx-repeat:before {
  content: "\eb39";
}

.bx-reply:before {
  content: "\eb3a";
}

.bx-reply-all:before {
  content: "\eb3b";
}

.bx-repost:before {
  content: "\eb3c";
}

.bx-reset:before {
  content: "\eb3d";
}

.bx-restaurant:before {
  content: "\eb3e";
}

.bx-revision:before {
  content: "\eb3f";
}

.bx-rewind:before {
  content: "\eb40";
}

.bx-rewind-circle:before {
  content: "\eb41";
}

.bx-right-arrow:before {
  content: "\eb42";
}

.bx-right-arrow-alt:before {
  content: "\eb43";
}

.bx-right-arrow-circle:before {
  content: "\eb44";
}

.bx-right-down-arrow-circle:before {
  content: "\eb45";
}

.bx-right-indent:before {
  content: "\eb46";
}

.bx-right-top-arrow-circle:before {
  content: "\eb47";
}

.bx-rocket:before {
  content: "\eb48";
}

.bx-rotate-left:before {
  content: "\eb49";
}

.bx-rotate-right:before {
  content: "\eb4a";
}

.bx-rss:before {
  content: "\eb4b";
}

.bx-ruble:before {
  content: "\eb4c";
}

.bx-ruler:before {
  content: "\eb4d";
}

.bx-run:before {
  content: "\eb4e";
}

.bx-rupee:before {
  content: "\eb4f";
}

.bx-sad:before {
  content: "\eb50";
}

.bx-save:before {
  content: "\eb51";
}

.bx-scan:before {
  content: "\eb52";
}

.bx-screenshot:before {
  content: "\eb53";
}

.bx-search:before {
  content: "\eb54";
}

.bx-search-alt:before {
  content: "\eb55";
}

.bx-search-alt-2:before {
  content: "\eb56";
}

.bx-selection:before {
  content: "\eb57";
}

.bx-select-multiple:before {
  content: "\eb58";
}

.bx-send:before {
  content: "\eb59";
}

.bx-server:before {
  content: "\eb5a";
}

.bx-shape-circle:before {
  content: "\eb5b";
}

.bx-shape-polygon:before {
  content: "\eb5c";
}

.bx-shape-square:before {
  content: "\eb5d";
}

.bx-shape-triangle:before {
  content: "\eb5e";
}

.bx-share:before {
  content: "\eb5f";
}

.bx-share-alt:before {
  content: "\eb60";
}

.bx-shekel:before {
  content: "\eb61";
}

.bx-shield:before {
  content: "\eb62";
}

.bx-shield-alt:before {
  content: "\eb63";
}

.bx-shield-alt-2:before {
  content: "\eb64";
}

.bx-shield-quarter:before {
  content: "\eb65";
}

.bx-shield-x:before {
  content: "\eb66";
}

.bx-shocked:before {
  content: "\eb67";
}

.bx-shopping-bag:before {
  content: "\eb68";
}

.bx-show:before {
  content: "\eb69";
}

.bx-show-alt:before {
  content: "\eb6a";
}

.bx-shuffle:before {
  content: "\eb6b";
}

.bx-sidebar:before {
  content: "\eb6c";
}

.bx-sitemap:before {
  content: "\eb6d";
}

.bx-skip-next:before {
  content: "\eb6e";
}

.bx-skip-next-circle:before {
  content: "\eb6f";
}

.bx-skip-previous:before {
  content: "\eb70";
}

.bx-skip-previous-circle:before {
  content: "\eb71";
}

.bx-sleepy:before {
  content: "\eb72";
}

.bx-slider:before {
  content: "\eb73";
}

.bx-slider-alt:before {
  content: "\eb74";
}

.bx-slideshow:before {
  content: "\eb75";
}

.bx-smile:before {
  content: "\eb76";
}

.bx-sort:before {
  content: "\eb77";
}

.bx-sort-alt-2:before {
  content: "\eb78";
}

.bx-sort-a-z:before {
  content: "\eb79";
}

.bx-sort-down:before {
  content: "\eb7a";
}

.bx-sort-up:before {
  content: "\eb7b";
}

.bx-sort-z-a:before {
  content: "\eb7c";
}

.bx-spa:before {
  content: "\eb7d";
}

.bx-space-bar:before {
  content: "\eb7e";
}

.bx-spray-can:before {
  content: "\eb7f";
}

.bx-spreadsheet:before {
  content: "\eb80";
}

.bx-square:before {
  content: "\eb81";
}

.bx-square-rounded:before {
  content: "\eb82";
}

.bx-star:before {
  content: "\eb83";
}

.bx-station:before {
  content: "\eb84";
}

.bx-stats:before {
  content: "\eb85";
}

.bx-sticker:before {
  content: "\eb86";
}

.bx-stop:before {
  content: "\eb87";
}

.bx-stop-circle:before {
  content: "\eb88";
}

.bx-stopwatch:before {
  content: "\eb89";
}

.bx-store:before {
  content: "\eb8a";
}

.bx-store-alt:before {
  content: "\eb8b";
}

.bx-street-view:before {
  content: "\eb8c";
}

.bx-strikethrough:before {
  content: "\eb8d";
}

.bx-subdirectory-left:before {
  content: "\eb8e";
}

.bx-subdirectory-right:before {
  content: "\eb8f";
}

.bx-sun:before {
  content: "\eb90";
}

.bx-support:before {
  content: "\eb91";
}

.bx-swim:before {
  content: "\eb92";
}

.bx-sync:before {
  content: "\eb93";
}

.bx-tab:before {
  content: "\eb94";
}

.bx-table:before {
  content: "\eb95";
}

.bx-tachometer:before {
  content: "\eb96";
}

.bx-tag:before {
  content: "\eb97";
}

.bx-tag-alt:before {
  content: "\eb98";
}

.bx-target-lock:before {
  content: "\eb99";
}

.bx-task:before {
  content: "\eb9a";
}

.bx-task-x:before {
  content: "\eb9b";
}

.bx-taxi:before {
  content: "\eb9c";
}

.bx-tennis-ball:before {
  content: "\eb9d";
}

.bx-terminal:before {
  content: "\eb9e";
}

.bx-test-tube:before {
  content: "\eb9f";
}

.bx-text:before {
  content: "\eba0";
}

.bx-time:before {
  content: "\eba1";
}

.bx-time-five:before {
  content: "\eba2";
}

.bx-timer:before {
  content: "\eba3";
}

.bx-tired:before {
  content: "\eba4";
}

.bx-toggle-left:before {
  content: "\eba5";
}

.bx-toggle-right:before {
  content: "\eba6";
}

.bx-tone:before {
  content: "\eba7";
}

.bx-traffic-cone:before {
  content: "\eba8";
}

.bx-train:before {
  content: "\eba9";
}

.bx-transfer:before {
  content: "\ebaa";
}

.bx-transfer-alt:before {
  content: "\ebab";
}

.bx-trash:before {
  content: "\ebac";
}

.bx-trash-alt:before {
  content: "\ebad";
}

.bx-trending-down:before {
  content: "\ebae";
}

.bx-trending-up:before {
  content: "\ebaf";
}

.bx-trim:before {
  content: "\ebb0";
}

.bx-trip:before {
  content: "\ebb1";
}

.bx-trophy:before {
  content: "\ebb2";
}

.bx-tv:before {
  content: "\ebb3";
}

.bx-underline:before {
  content: "\ebb4";
}

.bx-undo:before {
  content: "\ebb5";
}

.bx-unite:before {
  content: "\ebb6";
}

.bx-unlink:before {
  content: "\ebb7";
}

.bx-up-arrow:before {
  content: "\ebb8";
}

.bx-up-arrow-alt:before {
  content: "\ebb9";
}

.bx-up-arrow-circle:before {
  content: "\ebba";
}

.bx-upload:before {
  content: "\ebbb";
}

.bx-upside-down:before {
  content: "\ebbc";
}

.bx-upvote:before {
  content: "\ebbd";
}

.bx-usb:before {
  content: "\ebbe";
}

.bx-user:before {
  content: "\ebbf";
}

.bx-user-check:before {
  content: "\ebc0";
}

.bx-user-circle:before {
  content: "\ebc1";
}

.bx-user-minus:before {
  content: "\ebc2";
}

.bx-user-pin:before {
  content: "\ebc3";
}

.bx-user-plus:before {
  content: "\ebc4";
}

.bx-user-voice:before {
  content: "\ebc5";
}

.bx-user-x:before {
  content: "\ebc6";
}

.bx-vector:before {
  content: "\ebc7";
}

.bx-vertical-center:before {
  content: "\ebc8";
}

.bx-vial:before {
  content: "\ebc9";
}

.bx-video:before {
  content: "\ebca";
}

.bx-video-off:before {
  content: "\ebcb";
}

.bx-video-plus:before {
  content: "\ebcc";
}

.bx-video-recording:before {
  content: "\ebcd";
}

.bx-voicemail:before {
  content: "\ebce";
}

.bx-volume:before {
  content: "\ebcf";
}

.bx-volume-full:before {
  content: "\ebd0";
}

.bx-volume-low:before {
  content: "\ebd1";
}

.bx-volume-mute:before {
  content: "\ebd2";
}

.bx-walk:before {
  content: "\ebd3";
}

.bx-wallet:before {
  content: "\ebd4";
}

.bx-wallet-alt:before {
  content: "\ebd5";
}

.bx-water:before {
  content: "\ebd6";
}

.bx-webcam:before {
  content: "\ebd7";
}

.bx-wifi:before {
  content: "\ebd8";
}

.bx-wifi-0:before {
  content: "\ebd9";
}

.bx-wifi-1:before {
  content: "\ebda";
}

.bx-wifi-2:before {
  content: "\ebdb";
}

.bx-wifi-off:before {
  content: "\ebdc";
}

.bx-wind:before {
  content: "\ebdd";
}

.bx-window:before {
  content: "\ebde";
}

.bx-window-alt:before {
  content: "\ebdf";
}

.bx-window-close:before {
  content: "\ebe0";
}

.bx-window-open:before {
  content: "\ebe1";
}

.bx-windows:before {
  content: "\ebe2";
}

.bx-wine:before {
  content: "\ebe3";
}

.bx-wink-smile:before {
  content: "\ebe4";
}

.bx-wink-tongue:before {
  content: "\ebe5";
}

.bx-won:before {
  content: "\ebe6";
}

.bx-world:before {
  content: "\ebe7";
}

.bx-wrench:before {
  content: "\ebe8";
}

.bx-x:before {
  content: "\ebe9";
}

.bx-x-circle:before {
  content: "\ebea";
}

.bx-yen:before {
  content: "\ebeb";
}

.bx-zoom-in:before {
  content: "\ebec";
}

.bx-zoom-out:before {
  content: "\ebed";
}

.bxs-add-to-queue:before {
  content: "\ebee";
}

.bxs-adjust:before {
  content: "\ebef";
}

.bxs-adjust-alt:before {
  content: "\ebf0";
}

.bxs-alarm:before {
  content: "\ebf1";
}

.bxs-alarm-add:before {
  content: "\ebf2";
}

.bxs-alarm-exclamation:before {
  content: "\ebf3";
}

.bxs-alarm-off:before {
  content: "\ebf4";
}

.bxs-alarm-snooze:before {
  content: "\ebf5";
}

.bxs-album:before {
  content: "\ebf6";
}

.bxs-ambulance:before {
  content: "\ebf7";
}

.bxs-analyse:before {
  content: "\ebf8";
}

.bxs-angry:before {
  content: "\ebf9";
}

.bxs-arch:before {
  content: "\ebfa";
}

.bxs-archive:before {
  content: "\ebfb";
}

.bxs-archive-in:before {
  content: "\ebfc";
}

.bxs-archive-out:before {
  content: "\ebfd";
}

.bxs-area:before {
  content: "\ebfe";
}

.bxs-arrow-from-bottom:before {
  content: "\ebff";
}

.bxs-arrow-from-left:before {
  content: "\ec00";
}

.bxs-arrow-from-right:before {
  content: "\ec01";
}

.bxs-arrow-from-top:before {
  content: "\ec02";
}

.bxs-arrow-to-bottom:before {
  content: "\ec03";
}

.bxs-arrow-to-left:before {
  content: "\ec04";
}

.bxs-arrow-to-right:before {
  content: "\ec05";
}

.bxs-arrow-to-top:before {
  content: "\ec06";
}

.bxs-award:before {
  content: "\ec07";
}

.bxs-baby-carriage:before {
  content: "\ec08";
}

.bxs-backpack:before {
  content: "\ec09";
}

.bxs-badge:before {
  content: "\ec0a";
}

.bxs-badge-check:before {
  content: "\ec0b";
}

.bxs-badge-dollar:before {
  content: "\ec0c";
}

.bxs-ball:before {
  content: "\ec0d";
}

.bxs-band-aid:before {
  content: "\ec0e";
}

.bxs-bank:before {
  content: "\ec0f";
}

.bxs-bar-chart-alt-2:before {
  content: "\ec10";
}

.bxs-bar-chart-square:before {
  content: "\ec11";
}

.bxs-barcode:before {
  content: "\ec12";
}

.bxs-baseball:before {
  content: "\ec13";
}

.bxs-basket:before {
  content: "\ec14";
}

.bxs-basketball:before {
  content: "\ec15";
}

.bxs-bath:before {
  content: "\ec16";
}

.bxs-battery:before {
  content: "\ec17";
}

.bxs-battery-charging:before {
  content: "\ec18";
}

.bxs-battery-full:before {
  content: "\ec19";
}

.bxs-battery-low:before {
  content: "\ec1a";
}

.bxs-bed:before {
  content: "\ec1b";
}

.bxs-been-here:before {
  content: "\ec1c";
}

.bxs-beer:before {
  content: "\ec1d";
}

.bxs-bell:before {
  content: "\ec1e";
}

.bxs-bell-minus:before {
  content: "\ec1f";
}

.bxs-bell-off:before {
  content: "\ec20";
}

.bxs-bell-plus:before {
  content: "\ec21";
}

.bxs-bell-ring:before {
  content: "\ec22";
}

.bxs-bible:before {
  content: "\ec23";
}

.bxs-binoculars:before {
  content: "\ec24";
}

.bxs-blanket:before {
  content: "\ec25";
}

.bxs-bolt:before {
  content: "\ec26";
}

.bxs-bolt-circle:before {
  content: "\ec27";
}

.bxs-bomb:before {
  content: "\ec28";
}

.bxs-bone:before {
  content: "\ec29";
}

.bxs-bong:before {
  content: "\ec2a";
}

.bxs-book:before {
  content: "\ec2b";
}

.bxs-book-add:before {
  content: "\ec2c";
}

.bxs-book-alt:before {
  content: "\ec2d";
}

.bxs-book-bookmark:before {
  content: "\ec2e";
}

.bxs-book-content:before {
  content: "\ec2f";
}

.bxs-book-heart:before {
  content: "\ec30";
}

.bxs-bookmark:before {
  content: "\ec31";
}

.bxs-bookmark-alt:before {
  content: "\ec32";
}

.bxs-bookmark-alt-minus:before {
  content: "\ec33";
}

.bxs-bookmark-alt-plus:before {
  content: "\ec34";
}

.bxs-bookmark-heart:before {
  content: "\ec35";
}

.bxs-bookmark-minus:before {
  content: "\ec36";
}

.bxs-bookmark-plus:before {
  content: "\ec37";
}

.bxs-bookmarks:before {
  content: "\ec38";
}

.bxs-bookmark-star:before {
  content: "\ec39";
}

.bxs-book-open:before {
  content: "\ec3a";
}

.bxs-book-reader:before {
  content: "\ec3b";
}

.bxs-bot:before {
  content: "\ec3c";
}

.bxs-bowling-ball:before {
  content: "\ec3d";
}

.bxs-box:before {
  content: "\ec3e";
}

.bxs-brain:before {
  content: "\ec3f";
}

.bxs-briefcase:before {
  content: "\ec40";
}

.bxs-briefcase-alt:before {
  content: "\ec41";
}

.bxs-briefcase-alt-2:before {
  content: "\ec42";
}

.bxs-brightness:before {
  content: "\ec43";
}

.bxs-brightness-half:before {
  content: "\ec44";
}

.bxs-brush:before {
  content: "\ec45";
}

.bxs-brush-alt:before {
  content: "\ec46";
}

.bxs-bug:before {
  content: "\ec47";
}

.bxs-bug-alt:before {
  content: "\ec48";
}

.bxs-building:before {
  content: "\ec49";
}

.bxs-building-house:before {
  content: "\ec4a";
}

.bxs-buildings:before {
  content: "\ec4b";
}

.bxs-bulb:before {
  content: "\ec4c";
}

.bxs-bullseye:before {
  content: "\ec4d";
}

.bxs-buoy:before {
  content: "\ec4e";
}

.bxs-bus:before {
  content: "\ec4f";
}

.bxs-business:before {
  content: "\ec50";
}

.bxs-bus-school:before {
  content: "\ec51";
}

.bxs-cabinet:before {
  content: "\ec52";
}

.bxs-cake:before {
  content: "\ec53";
}

.bxs-calculator:before {
  content: "\ec54";
}

.bxs-calendar:before {
  content: "\ec55";
}

.bxs-calendar-alt:before {
  content: "\ec56";
}

.bxs-calendar-check:before {
  content: "\ec57";
}

.bxs-calendar-edit:before {
  content: "\ec58";
}

.bxs-calendar-event:before {
  content: "\ec59";
}

.bxs-calendar-exclamation:before {
  content: "\ec5a";
}

.bxs-calendar-heart:before {
  content: "\ec5b";
}

.bxs-calendar-minus:before {
  content: "\ec5c";
}

.bxs-calendar-plus:before {
  content: "\ec5d";
}

.bxs-calendar-star:before {
  content: "\ec5e";
}

.bxs-calendar-week:before {
  content: "\ec5f";
}

.bxs-calendar-x:before {
  content: "\ec60";
}

.bxs-camera:before {
  content: "\ec61";
}

.bxs-camera-home:before {
  content: "\ec62";
}

.bxs-camera-movie:before {
  content: "\ec63";
}

.bxs-camera-off:before {
  content: "\ec64";
}

.bxs-camera-plus:before {
  content: "\ec65";
}

.bxs-capsule:before {
  content: "\ec66";
}

.bxs-captions:before {
  content: "\ec67";
}

.bxs-car:before {
  content: "\ec68";
}

.bxs-car-battery:before {
  content: "\ec69";
}

.bxs-car-crash:before {
  content: "\ec6a";
}

.bxs-card:before {
  content: "\ec6b";
}

.bxs-caret-down-circle:before {
  content: "\ec6c";
}

.bxs-caret-down-square:before {
  content: "\ec6d";
}

.bxs-caret-left-circle:before {
  content: "\ec6e";
}

.bxs-caret-left-square:before {
  content: "\ec6f";
}

.bxs-caret-right-circle:before {
  content: "\ec70";
}

.bxs-caret-right-square:before {
  content: "\ec71";
}

.bxs-caret-up-circle:before {
  content: "\ec72";
}

.bxs-caret-up-square:before {
  content: "\ec73";
}

.bxs-car-garage:before {
  content: "\ec74";
}

.bxs-car-mechanic:before {
  content: "\ec75";
}

.bxs-carousel:before {
  content: "\ec76";
}

.bxs-cart:before {
  content: "\ec77";
}

.bxs-cart-add:before {
  content: "\ec78";
}

.bxs-cart-alt:before {
  content: "\ec79";
}

.bxs-cart-download:before {
  content: "\ec7a";
}

.bxs-car-wash:before {
  content: "\ec7b";
}

.bxs-category:before {
  content: "\ec7c";
}

.bxs-category-alt:before {
  content: "\ec7d";
}

.bxs-cctv:before {
  content: "\ec7e";
}

.bxs-certification:before {
  content: "\ec7f";
}

.bxs-chalkboard:before {
  content: "\ec80";
}

.bxs-chart:before {
  content: "\ec81";
}

.bxs-chat:before {
  content: "\ec82";
}

.bxs-checkbox:before {
  content: "\ec83";
}

.bxs-checkbox-checked:before {
  content: "\ec84";
}

.bxs-check-circle:before {
  content: "\ec85";
}

.bxs-check-shield:before {
  content: "\ec86";
}

.bxs-check-square:before {
  content: "\ec87";
}

.bxs-chess:before {
  content: "\ec88";
}

.bxs-chevron-down:before {
  content: "\ec89";
}

.bxs-chevron-down-circle:before {
  content: "\ec8a";
}

.bxs-chevron-down-square:before {
  content: "\ec8b";
}

.bxs-chevron-left:before {
  content: "\ec8c";
}

.bxs-chevron-left-circle:before {
  content: "\ec8d";
}

.bxs-chevron-left-square:before {
  content: "\ec8e";
}

.bxs-chevron-right:before {
  content: "\ec8f";
}

.bxs-chevron-right-circle:before {
  content: "\ec90";
}

.bxs-chevron-right-square:before {
  content: "\ec91";
}

.bxs-chevrons-down:before {
  content: "\ec92";
}

.bxs-chevrons-left:before {
  content: "\ec93";
}

.bxs-chevrons-right:before {
  content: "\ec94";
}

.bxs-chevrons-up:before {
  content: "\ec95";
}

.bxs-chevron-up:before {
  content: "\ec96";
}

.bxs-chevron-up-circle:before {
  content: "\ec97";
}

.bxs-chevron-up-square:before {
  content: "\ec98";
}

.bxs-chip:before {
  content: "\ec99";
}

.bxs-church:before {
  content: "\ec9a";
}

.bxs-circle:before {
  content: "\ec9b";
}

.bxs-city:before {
  content: "\ec9c";
}

.bxs-clinic:before {
  content: "\ec9d";
}

.bxs-cloud:before {
  content: "\ec9e";
}

.bxs-cloud-download:before {
  content: "\ec9f";
}

.bxs-cloud-lightning:before {
  content: "\eca0";
}

.bxs-cloud-rain:before {
  content: "\eca1";
}

.bxs-cloud-upload:before {
  content: "\eca2";
}

.bxs-coffee:before {
  content: "\eca3";
}

.bxs-coffee-alt:before {
  content: "\eca4";
}

.bxs-coffee-togo:before {
  content: "\eca5";
}

.bxs-cog:before {
  content: "\eca6";
}

.bxs-coin:before {
  content: "\eca7";
}

.bxs-coin-stack:before {
  content: "\eca8";
}

.bxs-collection:before {
  content: "\eca9";
}

.bxs-color-fill:before {
  content: "\ecaa";
}

.bxs-comment:before {
  content: "\ecab";
}

.bxs-comment-add:before {
  content: "\ecac";
}

.bxs-comment-check:before {
  content: "\ecad";
}

.bxs-comment-detail:before {
  content: "\ecae";
}

.bxs-comment-dots:before {
  content: "\ecaf";
}

.bxs-comment-edit:before {
  content: "\ecb0";
}

.bxs-comment-error:before {
  content: "\ecb1";
}

.bxs-comment-minus:before {
  content: "\ecb2";
}

.bxs-comment-x:before {
  content: "\ecb3";
}

.bxs-compass:before {
  content: "\ecb4";
}

.bxs-component:before {
  content: "\ecb5";
}

.bxs-confused:before {
  content: "\ecb6";
}

.bxs-contact:before {
  content: "\ecb7";
}

.bxs-conversation:before {
  content: "\ecb8";
}

.bxs-cookie:before {
  content: "\ecb9";
}

.bxs-cool:before {
  content: "\ecba";
}

.bxs-copy:before {
  content: "\ecbb";
}

.bxs-copy-alt:before {
  content: "\ecbc";
}

.bxs-copyright:before {
  content: "\ecbd";
}

.bxs-coupon:before {
  content: "\ecbe";
}

.bxs-credit-card:before {
  content: "\ecbf";
}

.bxs-credit-card-alt:before {
  content: "\ecc0";
}

.bxs-credit-card-front:before {
  content: "\ecc1";
}

.bxs-crop:before {
  content: "\ecc2";
}

.bxs-crown:before {
  content: "\ecc3";
}

.bxs-cube:before {
  content: "\ecc4";
}

.bxs-cube-alt:before {
  content: "\ecc5";
}

.bxs-cuboid:before {
  content: "\ecc6";
}

.bxs-customize:before {
  content: "\ecc7";
}

.bxs-cylinder:before {
  content: "\ecc8";
}

.bxs-dashboard:before {
  content: "\ecc9";
}

.bxs-data:before {
  content: "\ecca";
}

.bxs-detail:before {
  content: "\eccb";
}

.bxs-devices:before {
  content: "\eccc";
}

.bxs-diamond:before {
  content: "\eccd";
}

.bxs-dice-1:before {
  content: "\ecce";
}

.bxs-dice-2:before {
  content: "\eccf";
}

.bxs-dice-3:before {
  content: "\ecd0";
}

.bxs-dice-4:before {
  content: "\ecd1";
}

.bxs-dice-5:before {
  content: "\ecd2";
}

.bxs-dice-6:before {
  content: "\ecd3";
}

.bxs-direction-left:before {
  content: "\ecd4";
}

.bxs-direction-right:before {
  content: "\ecd5";
}

.bxs-directions:before {
  content: "\ecd6";
}

.bxs-disc:before {
  content: "\ecd7";
}

.bxs-discount:before {
  content: "\ecd8";
}

.bxs-dish:before {
  content: "\ecd9";
}

.bxs-dislike:before {
  content: "\ecda";
}

.bxs-dizzy:before {
  content: "\ecdb";
}

.bxs-dock-bottom:before {
  content: "\ecdc";
}

.bxs-dock-left:before {
  content: "\ecdd";
}

.bxs-dock-right:before {
  content: "\ecde";
}

.bxs-dock-top:before {
  content: "\ecdf";
}

.bxs-dollar-circle:before {
  content: "\ece0";
}

.bxs-donate-blood:before {
  content: "\ece1";
}

.bxs-donate-heart:before {
  content: "\ece2";
}

.bxs-door-open:before {
  content: "\ece3";
}

.bxs-doughnut-chart:before {
  content: "\ece4";
}

.bxs-down-arrow:before {
  content: "\ece5";
}

.bxs-down-arrow-alt:before {
  content: "\ece6";
}

.bxs-down-arrow-circle:before {
  content: "\ece7";
}

.bxs-down-arrow-square:before {
  content: "\ece8";
}

.bxs-download:before {
  content: "\ece9";
}

.bxs-downvote:before {
  content: "\ecea";
}

.bxs-drink:before {
  content: "\eceb";
}

.bxs-droplet:before {
  content: "\ecec";
}

.bxs-droplet-half:before {
  content: "\eced";
}

.bxs-dryer:before {
  content: "\ecee";
}

.bxs-duplicate:before {
  content: "\ecef";
}

.bxs-edit:before {
  content: "\ecf0";
}

.bxs-edit-alt:before {
  content: "\ecf1";
}

.bxs-edit-location:before {
  content: "\ecf2";
}

.bxs-eject:before {
  content: "\ecf3";
}

.bxs-envelope:before {
  content: "\ecf4";
}

.bxs-envelope-open:before {
  content: "\ecf5";
}

.bxs-eraser:before {
  content: "\ecf6";
}

.bxs-error:before {
  content: "\ecf7";
}

.bxs-error-alt:before {
  content: "\ecf8";
}

.bxs-error-circle:before {
  content: "\ecf9";
}

.bxs-ev-station:before {
  content: "\ecfa";
}

.bxs-exit:before {
  content: "\ecfb";
}

.bxs-extension:before {
  content: "\ecfc";
}

.bxs-eyedropper:before {
  content: "\ecfd";
}

.bxs-face:before {
  content: "\ecfe";
}

.bxs-face-mask:before {
  content: "\ecff";
}

.bxs-factory:before {
  content: "\ed00";
}

.bxs-fast-forward-circle:before {
  content: "\ed01";
}

.bxs-file:before {
  content: "\ed02";
}

.bxs-file-archive:before {
  content: "\ed03";
}

.bxs-file-blank:before {
  content: "\ed04";
}

.bxs-file-css:before {
  content: "\ed05";
}

.bxs-file-doc:before {
  content: "\ed06";
}

.bxs-file-export:before {
  content: "\ed07";
}

.bxs-file-find:before {
  content: "\ed08";
}

.bxs-file-gif:before {
  content: "\ed09";
}

.bxs-file-html:before {
  content: "\ed0a";
}

.bxs-file-image:before {
  content: "\ed0b";
}

.bxs-file-import:before {
  content: "\ed0c";
}

.bxs-file-jpg:before {
  content: "\ed0d";
}

.bxs-file-js:before {
  content: "\ed0e";
}

.bxs-file-json:before {
  content: "\ed0f";
}

.bxs-file-md:before {
  content: "\ed10";
}

.bxs-file-pdf:before {
  content: "\ed11";
}

.bxs-file-plus:before {
  content: "\ed12";
}

.bxs-file-png:before {
  content: "\ed13";
}

.bxs-file-txt:before {
  content: "\ed14";
}

.bxs-film:before {
  content: "\ed15";
}

.bxs-filter-alt:before {
  content: "\ed16";
}

.bxs-first-aid:before {
  content: "\ed17";
}

.bxs-flag:before {
  content: "\ed18";
}

.bxs-flag-alt:before {
  content: "\ed19";
}

.bxs-flag-checkered:before {
  content: "\ed1a";
}

.bxs-flame:before {
  content: "\ed1b";
}

.bxs-flask:before {
  content: "\ed1c";
}

.bxs-florist:before {
  content: "\ed1d";
}

.bxs-folder:before {
  content: "\ed1e";
}

.bxs-folder-minus:before {
  content: "\ed1f";
}

.bxs-folder-open:before {
  content: "\ed20";
}

.bxs-folder-plus:before {
  content: "\ed21";
}

.bxs-food-menu:before {
  content: "\ed22";
}

.bxs-fridge:before {
  content: "\ed23";
}

.bxs-game:before {
  content: "\ed24";
}

.bxs-gas-pump:before {
  content: "\ed25";
}

.bxs-ghost:before {
  content: "\ed26";
}

.bxs-gift:before {
  content: "\ed27";
}

.bxs-graduation:before {
  content: "\ed28";
}

.bxs-grid:before {
  content: "\ed29";
}

.bxs-grid-alt:before {
  content: "\ed2a";
}

.bxs-group:before {
  content: "\ed2b";
}

.bxs-guitar-amp:before {
  content: "\ed2c";
}

.bxs-hand-down:before {
  content: "\ed2d";
}

.bxs-hand-left:before {
  content: "\ed2e";
}

.bxs-hand-right:before {
  content: "\ed2f";
}

.bxs-hand-up:before {
  content: "\ed30";
}

.bxs-happy:before {
  content: "\ed31";
}

.bxs-happy-alt:before {
  content: "\ed32";
}

.bxs-happy-beaming:before {
  content: "\ed33";
}

.bxs-happy-heart-eyes:before {
  content: "\ed34";
}

.bxs-hdd:before {
  content: "\ed35";
}

.bxs-heart:before {
  content: "\ed36";
}

.bxs-heart-circle:before {
  content: "\ed37";
}

.bxs-heart-square:before {
  content: "\ed38";
}

.bxs-help-circle:before {
  content: "\ed39";
}

.bxs-hide:before {
  content: "\ed3a";
}

.bxs-home:before {
  content: "\ed3b";
}

.bxs-home-circle:before {
  content: "\ed3c";
}

.bxs-home-heart:before {
  content: "\ed3d";
}

.bxs-home-smile:before {
  content: "\ed3e";
}

.bxs-hot:before {
  content: "\ed3f";
}

.bxs-hotel:before {
  content: "\ed40";
}

.bxs-hourglass:before {
  content: "\ed41";
}

.bxs-hourglass-bottom:before {
  content: "\ed42";
}

.bxs-hourglass-top:before {
  content: "\ed43";
}

.bxs-id-card:before {
  content: "\ed44";
}

.bxs-image:before {
  content: "\ed45";
}

.bxs-image-add:before {
  content: "\ed46";
}

.bxs-image-alt:before {
  content: "\ed47";
}

.bxs-inbox:before {
  content: "\ed48";
}

.bxs-info-circle:before {
  content: "\ed49";
}

.bxs-info-square:before {
  content: "\ed4a";
}

.bxs-institution:before {
  content: "\ed4b";
}

.bxs-joystick:before {
  content: "\ed4c";
}

.bxs-joystick-alt:before {
  content: "\ed4d";
}

.bxs-joystick-button:before {
  content: "\ed4e";
}

.bxs-key:before {
  content: "\ed4f";
}

.bxs-keyboard:before {
  content: "\ed50";
}

.bxs-label:before {
  content: "\ed51";
}

.bxs-landmark:before {
  content: "\ed52";
}

.bxs-landscape:before {
  content: "\ed53";
}

.bxs-laugh:before {
  content: "\ed54";
}

.bxs-layer:before {
  content: "\ed55";
}

.bxs-layer-minus:before {
  content: "\ed56";
}

.bxs-layer-plus:before {
  content: "\ed57";
}

.bxs-layout:before {
  content: "\ed58";
}

.bxs-left-arrow:before {
  content: "\ed59";
}

.bxs-left-arrow-alt:before {
  content: "\ed5a";
}

.bxs-left-arrow-circle:before {
  content: "\ed5b";
}

.bxs-left-arrow-square:before {
  content: "\ed5c";
}

.bxs-left-down-arrow-circle:before {
  content: "\ed5d";
}

.bxs-left-top-arrow-circle:before {
  content: "\ed5e";
}

.bxs-like:before {
  content: "\ed5f";
}

.bxs-location-plus:before {
  content: "\ed60";
}

.bxs-lock:before {
  content: "\ed61";
}

.bxs-lock-alt:before {
  content: "\ed62";
}

.bxs-lock-open:before {
  content: "\ed63";
}

.bxs-lock-open-alt:before {
  content: "\ed64";
}

.bxs-log-in:before {
  content: "\ed65";
}

.bxs-log-in-circle:before {
  content: "\ed66";
}

.bxs-log-out:before {
  content: "\ed67";
}

.bxs-log-out-circle:before {
  content: "\ed68";
}

.bxs-low-vision:before {
  content: "\ed69";
}

.bxs-magic-wand:before {
  content: "\ed6a";
}

.bxs-magnet:before {
  content: "\ed6b";
}

.bxs-map:before {
  content: "\ed6c";
}

.bxs-map-alt:before {
  content: "\ed6d";
}

.bxs-map-pin:before {
  content: "\ed6e";
}

.bxs-mask:before {
  content: "\ed6f";
}

.bxs-medal:before {
  content: "\ed70";
}

.bxs-megaphone:before {
  content: "\ed71";
}

.bxs-meh:before {
  content: "\ed72";
}

.bxs-meh-alt:before {
  content: "\ed73";
}

.bxs-meh-blank:before {
  content: "\ed74";
}

.bxs-memory-card:before {
  content: "\ed75";
}

.bxs-message:before {
  content: "\ed76";
}

.bxs-message-add:before {
  content: "\ed77";
}

.bxs-message-alt:before {
  content: "\ed78";
}

.bxs-message-alt-add:before {
  content: "\ed79";
}

.bxs-message-alt-check:before {
  content: "\ed7a";
}

.bxs-message-alt-detail:before {
  content: "\ed7b";
}

.bxs-message-alt-dots:before {
  content: "\ed7c";
}

.bxs-message-alt-edit:before {
  content: "\ed7d";
}

.bxs-message-alt-error:before {
  content: "\ed7e";
}

.bxs-message-alt-minus:before {
  content: "\ed7f";
}

.bxs-message-alt-x:before {
  content: "\ed80";
}

.bxs-message-check:before {
  content: "\ed81";
}

.bxs-message-detail:before {
  content: "\ed82";
}

.bxs-message-dots:before {
  content: "\ed83";
}

.bxs-message-edit:before {
  content: "\ed84";
}

.bxs-message-error:before {
  content: "\ed85";
}

.bxs-message-minus:before {
  content: "\ed86";
}

.bxs-message-rounded:before {
  content: "\ed87";
}

.bxs-message-rounded-add:before {
  content: "\ed88";
}

.bxs-message-rounded-check:before {
  content: "\ed89";
}

.bxs-message-rounded-detail:before {
  content: "\ed8a";
}

.bxs-message-rounded-dots:before {
  content: "\ed8b";
}

.bxs-message-rounded-edit:before {
  content: "\ed8c";
}

.bxs-message-rounded-error:before {
  content: "\ed8d";
}

.bxs-message-rounded-minus:before {
  content: "\ed8e";
}

.bxs-message-rounded-x:before {
  content: "\ed8f";
}

.bxs-message-square:before {
  content: "\ed90";
}

.bxs-message-square-add:before {
  content: "\ed91";
}

.bxs-message-square-check:before {
  content: "\ed92";
}

.bxs-message-square-detail:before {
  content: "\ed93";
}

.bxs-message-square-dots:before {
  content: "\ed94";
}

.bxs-message-square-edit:before {
  content: "\ed95";
}

.bxs-message-square-error:before {
  content: "\ed96";
}

.bxs-message-square-minus:before {
  content: "\ed97";
}

.bxs-message-square-x:before {
  content: "\ed98";
}

.bxs-message-x:before {
  content: "\ed99";
}

.bxs-meteor:before {
  content: "\ed9a";
}

.bxs-microchip:before {
  content: "\ed9b";
}

.bxs-microphone:before {
  content: "\ed9c";
}

.bxs-microphone-alt:before {
  content: "\ed9d";
}

.bxs-microphone-off:before {
  content: "\ed9e";
}

.bxs-minus-circle:before {
  content: "\ed9f";
}

.bxs-minus-square:before {
  content: "\eda0";
}

.bxs-mobile:before {
  content: "\eda1";
}

.bxs-mobile-vibration:before {
  content: "\eda2";
}

.bxs-moon:before {
  content: "\eda3";
}

.bxs-mouse:before {
  content: "\eda4";
}

.bxs-mouse-alt:before {
  content: "\eda5";
}

.bxs-movie:before {
  content: "\eda6";
}

.bxs-movie-play:before {
  content: "\eda7";
}

.bxs-music:before {
  content: "\eda8";
}

.bxs-navigation:before {
  content: "\eda9";
}

.bxs-network-chart:before {
  content: "\edaa";
}

.bxs-news:before {
  content: "\edab";
}

.bxs-no-entry:before {
  content: "\edac";
}

.bxs-note:before {
  content: "\edad";
}

.bxs-notepad:before {
  content: "\edae";
}

.bxs-notification:before {
  content: "\edaf";
}

.bxs-notification-off:before {
  content: "\edb0";
}

.bxs-offer:before {
  content: "\edb1";
}

.bxs-package:before {
  content: "\edb2";
}

.bxs-paint:before {
  content: "\edb3";
}

.bxs-paint-roll:before {
  content: "\edb4";
}

.bxs-palette:before {
  content: "\edb5";
}

.bxs-paper-plane:before {
  content: "\edb6";
}

.bxs-parking:before {
  content: "\edb7";
}

.bxs-paste:before {
  content: "\edb8";
}

.bxs-pen:before {
  content: "\edb9";
}

.bxs-pencil:before {
  content: "\edba";
}

.bxs-phone:before {
  content: "\edbb";
}

.bxs-phone-call:before {
  content: "\edbc";
}

.bxs-phone-incoming:before {
  content: "\edbd";
}

.bxs-phone-outgoing:before {
  content: "\edbe";
}

.bxs-photo-album:before {
  content: "\edbf";
}

.bxs-piano:before {
  content: "\edc0";
}

.bxs-pie-chart:before {
  content: "\edc1";
}

.bxs-pie-chart-alt:before {
  content: "\edc2";
}

.bxs-pie-chart-alt-2:before {
  content: "\edc3";
}

.bxs-pin:before {
  content: "\edc4";
}

.bxs-pizza:before {
  content: "\edc5";
}

.bxs-plane:before {
  content: "\edc6";
}

.bxs-plane-alt:before {
  content: "\edc7";
}

.bxs-plane-land:before {
  content: "\edc8";
}

.bxs-planet:before {
  content: "\edc9";
}

.bxs-plane-take-off:before {
  content: "\edca";
}

.bxs-playlist:before {
  content: "\edcb";
}

.bxs-plug:before {
  content: "\edcc";
}

.bxs-plus-circle:before {
  content: "\edcd";
}

.bxs-plus-square:before {
  content: "\edce";
}

.bxs-pointer:before {
  content: "\edcf";
}

.bxs-polygon:before {
  content: "\edd0";
}

.bxs-printer:before {
  content: "\edd1";
}

.bxs-purchase-tag:before {
  content: "\edd2";
}

.bxs-purchase-tag-alt:before {
  content: "\edd3";
}

.bxs-pyramid:before {
  content: "\edd4";
}

.bxs-quote-alt-left:before {
  content: "\edd5";
}

.bxs-quote-alt-right:before {
  content: "\edd6";
}

.bxs-quote-left:before {
  content: "\edd7";
}

.bxs-quote-right:before {
  content: "\edd8";
}

.bxs-quote-single-left:before {
  content: "\edd9";
}

.bxs-quote-single-right:before {
  content: "\edda";
}

.bxs-radiation:before {
  content: "\eddb";
}

.bxs-radio:before {
  content: "\eddc";
}

.bxs-receipt:before {
  content: "\eddd";
}

.bxs-rectangle:before {
  content: "\edde";
}

.bxs-rename:before {
  content: "\eddf";
}

.bxs-report:before {
  content: "\ede0";
}

.bxs-rewind-circle:before {
  content: "\ede1";
}

.bxs-right-arrow:before {
  content: "\ede2";
}

.bxs-right-arrow-alt:before {
  content: "\ede3";
}

.bxs-right-arrow-circle:before {
  content: "\ede4";
}

.bxs-right-arrow-square:before {
  content: "\ede5";
}

.bxs-right-down-arrow-circle:before {
  content: "\ede6";
}

.bxs-right-top-arrow-circle:before {
  content: "\ede7";
}

.bxs-rocket:before {
  content: "\ede8";
}

.bxs-ruler:before {
  content: "\ede9";
}

.bxs-sad:before {
  content: "\edea";
}

.bxs-save:before {
  content: "\edeb";
}

.bxs-school:before {
  content: "\edec";
}

.bxs-search:before {
  content: "\eded";
}

.bxs-search-alt-2:before {
  content: "\edee";
}

.bxs-select-multiple:before {
  content: "\edef";
}

.bxs-send:before {
  content: "\edf0";
}

.bxs-server:before {
  content: "\edf1";
}

.bxs-shapes:before {
  content: "\edf2";
}

.bxs-share:before {
  content: "\edf3";
}

.bxs-share-alt:before {
  content: "\edf4";
}

.bxs-shield:before {
  content: "\edf5";
}

.bxs-shield-alt-2:before {
  content: "\edf6";
}

.bxs-shield-x:before {
  content: "\edf7";
}

.bxs-ship:before {
  content: "\edf8";
}

.bxs-shocked:before {
  content: "\edf9";
}

.bxs-shopping-bag:before {
  content: "\edfa";
}

.bxs-shopping-bag-alt:before {
  content: "\edfb";
}

.bxs-shopping-bags:before {
  content: "\edfc";
}

.bxs-show:before {
  content: "\edfd";
}

.bxs-skip-next-circle:before {
  content: "\edfe";
}

.bxs-skip-previous-circle:before {
  content: "\edff";
}

.bxs-skull:before {
  content: "\ee00";
}

.bxs-sleepy:before {
  content: "\ee01";
}

.bxs-slideshow:before {
  content: "\ee02";
}

.bxs-smile:before {
  content: "\ee03";
}

.bxs-sort-alt:before {
  content: "\ee04";
}

.bxs-spa:before {
  content: "\ee05";
}

.bxs-spray-can:before {
  content: "\ee06";
}

.bxs-spreadsheet:before {
  content: "\ee07";
}

.bxs-square:before {
  content: "\ee08";
}

.bxs-square-rounded:before {
  content: "\ee09";
}

.bxs-star:before {
  content: "\ee0a";
}

.bxs-star-half:before {
  content: "\ee0b";
}

.bxs-sticker:before {
  content: "\ee0c";
}

.bxs-stopwatch:before {
  content: "\ee0d";
}

.bxs-store:before {
  content: "\ee0e";
}

.bxs-store-alt:before {
  content: "\ee0f";
}

.bxs-sun:before {
  content: "\ee10";
}

.bxs-tachometer:before {
  content: "\ee11";
}

.bxs-tag:before {
  content: "\ee12";
}

.bxs-tag-alt:before {
  content: "\ee13";
}

.bxs-tag-x:before {
  content: "\ee14";
}

.bxs-taxi:before {
  content: "\ee15";
}

.bxs-tennis-ball:before {
  content: "\ee16";
}

.bxs-terminal:before {
  content: "\ee17";
}

.bxs-thermometer:before {
  content: "\ee18";
}

.bxs-time:before {
  content: "\ee19";
}

.bxs-time-five:before {
  content: "\ee1a";
}

.bxs-timer:before {
  content: "\ee1b";
}

.bxs-tired:before {
  content: "\ee1c";
}

.bxs-toggle-left:before {
  content: "\ee1d";
}

.bxs-toggle-right:before {
  content: "\ee1e";
}

.bxs-tone:before {
  content: "\ee1f";
}

.bxs-torch:before {
  content: "\ee20";
}

.bxs-to-top:before {
  content: "\ee21";
}

.bxs-traffic:before {
  content: "\ee22";
}

.bxs-traffic-barrier:before {
  content: "\ee23";
}

.bxs-traffic-cone:before {
  content: "\ee24";
}

.bxs-train:before {
  content: "\ee25";
}

.bxs-trash:before {
  content: "\ee26";
}

.bxs-trash-alt:before {
  content: "\ee27";
}

.bxs-tree:before {
  content: "\ee28";
}

.bxs-trophy:before {
  content: "\ee29";
}

.bxs-truck:before {
  content: "\ee2a";
}

.bxs-t-shirt:before {
  content: "\ee2b";
}

.bxs-tv:before {
  content: "\ee2c";
}

.bxs-up-arrow:before {
  content: "\ee2d";
}

.bxs-up-arrow-alt:before {
  content: "\ee2e";
}

.bxs-up-arrow-circle:before {
  content: "\ee2f";
}

.bxs-up-arrow-square:before {
  content: "\ee30";
}

.bxs-upside-down:before {
  content: "\ee31";
}

.bxs-upvote:before {
  content: "\ee32";
}

.bxs-user:before {
  content: "\ee33";
}

.bxs-user-account:before {
  content: "\ee34";
}

.bxs-user-badge:before {
  content: "\ee35";
}

.bxs-user-check:before {
  content: "\ee36";
}

.bxs-user-circle:before {
  content: "\ee37";
}

.bxs-user-detail:before {
  content: "\ee38";
}

.bxs-user-minus:before {
  content: "\ee39";
}

.bxs-user-pin:before {
  content: "\ee3a";
}

.bxs-user-plus:before {
  content: "\ee3b";
}

.bxs-user-rectangle:before {
  content: "\ee3c";
}

.bxs-user-voice:before {
  content: "\ee3d";
}

.bxs-user-x:before {
  content: "\ee3e";
}

.bxs-vector:before {
  content: "\ee3f";
}

.bxs-vial:before {
  content: "\ee40";
}

.bxs-video:before {
  content: "\ee41";
}

.bxs-video-off:before {
  content: "\ee42";
}

.bxs-video-plus:before {
  content: "\ee43";
}

.bxs-video-recording:before {
  content: "\ee44";
}

.bxs-videos:before {
  content: "\ee45";
}

.bxs-virus:before {
  content: "\ee46";
}

.bxs-virus-block:before {
  content: "\ee47";
}

.bxs-volume:before {
  content: "\ee48";
}

.bxs-volume-full:before {
  content: "\ee49";
}

.bxs-volume-low:before {
  content: "\ee4a";
}

.bxs-volume-mute:before {
  content: "\ee4b";
}

.bxs-wallet:before {
  content: "\ee4c";
}

.bxs-wallet-alt:before {
  content: "\ee4d";
}

.bxs-washer:before {
  content: "\ee4e";
}

.bxs-watch:before {
  content: "\ee4f";
}

.bxs-watch-alt:before {
  content: "\ee50";
}

.bxs-webcam:before {
  content: "\ee51";
}

.bxs-widget:before {
  content: "\ee52";
}

.bxs-window-alt:before {
  content: "\ee53";
}

.bxs-wine:before {
  content: "\ee54";
}

.bxs-wink-smile:before {
  content: "\ee55";
}

.bxs-wink-tongue:before {
  content: "\ee56";
}

.bxs-wrench:before {
  content: "\ee57";
}

.bxs-x-circle:before {
  content: "\ee58";
}

.bxs-x-square:before {
  content: "\ee59";
}

.bxs-yin-yang:before {
  content: "\ee5a";
}

.bxs-zap:before {
  content: "\ee5b";
}

.bxs-zoom-in:before {
  content: "\ee5c";
}

.bxs-zoom-out:before {
  content: "\ee5d";
}

.bxl-500px:before {
  content: "\ee5e";
}

.bxl-adobe:before {
  content: "\ee5f";
}

.bxl-airbnb:before {
  content: "\ee60";
}

.bxl-algolia:before {
  content: "\ee61";
}

.bxl-amazon:before {
  content: "\ee62";
}

.bxl-android:before {
  content: "\ee63";
}

.bxl-angular:before {
  content: "\ee64";
}

.bxl-apple:before {
  content: "\ee65";
}

.bxl-audible:before {
  content: "\ee66";
}

.bxl-baidu:before {
  content: "\ee67";
}

.bxl-behance:before {
  content: "\ee68";
}

.bxl-bing:before {
  content: "\ee69";
}

.bxl-bitcoin:before {
  content: "\ee6a";
}

.bxl-blender:before {
  content: "\ee6b";
}

.bxl-blogger:before {
  content: "\ee6c";
}

.bxl-bootstrap:before {
  content: "\ee6d";
}

.bxl-chrome:before {
  content: "\ee6e";
}

.bxl-codepen:before {
  content: "\ee6f";
}

.bxl-c-plus-plus:before {
  content: "\ee70";
}

.bxl-creative-commons:before {
  content: "\ee71";
}

.bxl-css3:before {
  content: "\ee72";
}

.bxl-dailymotion:before {
  content: "\ee73";
}

.bxl-deviantart:before {
  content: "\ee74";
}

.bxl-dev-to:before {
  content: "\ee75";
}

.bxl-digg:before {
  content: "\ee76";
}

.bxl-digitalocean:before {
  content: "\ee77";
}

.bxl-discord:before {
  content: "\ee78";
}

.bxl-discourse:before {
  content: "\ee79";
}

.bxl-django:before {
  content: "\ee7a";
}

.bxl-dribbble:before {
  content: "\ee7b";
}

.bxl-dropbox:before {
  content: "\ee7c";
}

.bxl-drupal:before {
  content: "\ee7d";
}

.bxl-ebay:before {
  content: "\ee7e";
}

.bxl-edge:before {
  content: "\ee7f";
}

.bxl-etsy:before {
  content: "\ee80";
}

.bxl-facebook:before {
  content: "\ee81";
}

.bxl-facebook-circle:before {
  content: "\ee82";
}

.bxl-facebook-square:before {
  content: "\ee83";
}

.bxl-figma:before {
  content: "\ee84";
}

.bxl-firebase:before {
  content: "\ee85";
}

.bxl-firefox:before {
  content: "\ee86";
}

.bxl-flickr:before {
  content: "\ee87";
}

.bxl-flickr-square:before {
  content: "\ee88";
}

.bxl-foursquare:before {
  content: "\ee89";
}

.bxl-git:before {
  content: "\ee8a";
}

.bxl-github:before {
  content: "\ee8b";
}

.bxl-gitlab:before {
  content: "\ee8c";
}

.bxl-google:before {
  content: "\ee8d";
}

.bxl-google-cloud:before {
  content: "\ee8e";
}

.bxl-google-plus:before {
  content: "\ee8f";
}

.bxl-google-plus-circle:before {
  content: "\ee90";
}

.bxl-html5:before {
  content: "\ee91";
}

.bxl-imdb:before {
  content: "\ee92";
}

.bxl-instagram:before {
  content: "\ee93";
}

.bxl-instagram-alt:before {
  content: "\ee94";
}

.bxl-internet-explorer:before {
  content: "\ee95";
}

.bxl-invision:before {
  content: "\ee96";
}

.bxl-javascript:before {
  content: "\ee97";
}

.bxl-joomla:before {
  content: "\ee98";
}

.bxl-jquery:before {
  content: "\ee99";
}

.bxl-jsfiddle:before {
  content: "\ee9a";
}

.bxl-kickstarter:before {
  content: "\ee9b";
}

.bxl-kubernetes:before {
  content: "\ee9c";
}

.bxl-less:before {
  content: "\ee9d";
}

.bxl-linkedin:before {
  content: "\ee9e";
}

.bxl-linkedin-square:before {
  content: "\ee9f";
}

.bxl-magento:before {
  content: "\eea0";
}

.bxl-mailchimp:before {
  content: "\eea1";
}

.bxl-markdown:before {
  content: "\eea2";
}

.bxl-mastercard:before {
  content: "\eea3";
}

.bxl-medium:before {
  content: "\eea4";
}

.bxl-medium-old:before {
  content: "\eea5";
}

.bxl-medium-square:before {
  content: "\eea6";
}

.bxl-messenger:before {
  content: "\eea7";
}

.bxl-microsoft:before {
  content: "\eea8";
}

.bxl-microsoft-teams:before {
  content: "\eea9";
}

.bxl-nodejs:before {
  content: "\eeaa";
}

.bxl-ok-ru:before {
  content: "\eeab";
}

.bxl-opera:before {
  content: "\eeac";
}

.bxl-patreon:before {
  content: "\eead";
}

.bxl-paypal:before {
  content: "\eeae";
}

.bxl-periscope:before {
  content: "\eeaf";
}

.bxl-pinterest:before {
  content: "\eeb0";
}

.bxl-pinterest-alt:before {
  content: "\eeb1";
}

.bxl-play-store:before {
  content: "\eeb2";
}

.bxl-pocket:before {
  content: "\eeb3";
}

.bxl-product-hunt:before {
  content: "\eeb4";
}

.bxl-python:before {
  content: "\eeb5";
}

.bxl-quora:before {
  content: "\eeb6";
}

.bxl-react:before {
  content: "\eeb7";
}

.bxl-redbubble:before {
  content: "\eeb8";
}

.bxl-reddit:before {
  content: "\eeb9";
}

.bxl-redux:before {
  content: "\eeba";
}

.bxl-sass:before {
  content: "\eebb";
}

.bxl-shopify:before {
  content: "\eebc";
}

.bxl-skype:before {
  content: "\eebd";
}

.bxl-slack:before {
  content: "\eebe";
}

.bxl-slack-old:before {
  content: "\eebf";
}

.bxl-snapchat:before {
  content: "\eec0";
}

.bxl-soundcloud:before {
  content: "\eec1";
}

.bxl-spotify:before {
  content: "\eec2";
}

.bxl-spring-boot:before {
  content: "\eec3";
}

.bxl-squarespace:before {
  content: "\eec4";
}

.bxl-stack-overflow:before {
  content: "\eec5";
}

.bxl-stripe:before {
  content: "\eec6";
}

.bxl-telegram:before {
  content: "\eec7";
}

.bxl-trello:before {
  content: "\eec8";
}

.bxl-tumblr:before {
  content: "\eec9";
}

.bxl-tux:before {
  content: "\eeca";
}

.bxl-twitch:before {
  content: "\eecb";
}

.bxl-twitter:before {
  content: "\eecc";
}

.bxl-unsplash:before {
  content: "\eecd";
}

.bxl-vimeo:before {
  content: "\eece";
}

.bxl-visa:before {
  content: "\eecf";
}

.bxl-vk:before {
  content: "\eed0";
}

.bxl-vuejs:before {
  content: "\eed1";
}

.bxl-whatsapp:before {
  content: "\eed2";
}

.bxl-whatsapp-square:before {
  content: "\eed3";
}

.bxl-wikipedia:before {
  content: "\eed4";
}

.bxl-windows:before {
  content: "\eed5";
}

.bxl-wix:before {
  content: "\eed6";
}

.bxl-wordpress:before {
  content: "\eed7";
}

.bxl-yahoo:before {
  content: "\eed8";
}

.bxl-yelp:before {
  content: "\eed9";
}

.bxl-youtube:before {
  content: "\eeda";
}

.bxl-zoom:before {
  content: "\eedb";
}

/*!
 * Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fa-sharp {
  font-family: "Font Awesome 6 Sharp";
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  -webkit-animation-name: fa-beat;
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  -webkit-animation-name: fa-bounce;
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  -webkit-animation-name: fa-fade;
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  -webkit-animation-name: fa-flip;
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  -webkit-animation-name: fa-shake;
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "0";
}

.fa-1::before {
  content: "1";
}

.fa-2::before {
  content: "2";
}

.fa-3::before {
  content: "3";
}

.fa-4::before {
  content: "4";
}

.fa-5::before {
  content: "5";
}

.fa-6::before {
  content: "6";
}

.fa-7::before {
  content: "7";
}

.fa-8::before {
  content: "8";
}

.fa-9::before {
  content: "9";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-arrows-to-circle::before {
  content: "\e4bd";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-bagel::before {
  content: "\e3d7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-at::before {
  content: "@";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-face-hand-yawn::before {
  content: "\e379";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-info::before {
  content: "\f129";
}

.fa-robot-astromech::before {
  content: "\e2d2";
}

.fa-ring-diamond::before {
  content: "\e5ab";
}

.fa-fondue-pot::before {
  content: "\e40d";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-face-hand-peeking::before {
  content: "\e481";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-explosion::before {
  content: "\e4e9";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-building-un::before {
  content: "\e4d9";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-anchor-circle-check::before {
  content: "\e4aa";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-rectangle-history::before {
  content: "\e4a2";
}

.fa-building-circle-arrow-right::before {
  content: "\e4d1";
}

.fa-volleyball::before {
  content: "\f45f";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-arrows-up-to-line::before {
  content: "\e4c2";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-face-icicles::before {
  content: "\e37c";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-films::before {
  content: "\e17a";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-face-glasses::before {
  content: "\e377";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-bridge-circle-check::before {
  content: "\e4c9";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-ski-boot::before {
  content: "\e3cc";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-h1::before {
  content: "\f313";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-message-bot::before {
  content: "\e3b8";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-bowl-scoop::before {
  content: "\e3de";
}

.fa-bowl-shaved-ice::before {
  content: "\e3de";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-football::before {
  content: "\f44e";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-user-vneck::before {
  content: "\e461";
}

.fa-school-circle-exclamation::before {
  content: "\e56c";
}

.fa-crop::before {
  content: "\f125";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-users-rectangle::before {
  content: "\e594";
}

.fa-people-roof::before {
  content: "\e537";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-people-line::before {
  content: "\e534";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-diagram-predecessor::before {
  content: "\e477";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-calendar-circle-user::before {
  content: "\e471";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-truck-plane::before {
  content: "\e58f";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-arrow-down-up-across-line::before {
  content: "\e4af";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-jar-wheat::before {
  content: "\e517";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-file-circle-exclamation::before {
  content: "\e4eb";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-merge::before {
  content: "\e526";
}

.fa-pager::before {
  content: "\f815";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-pan-frying::before {
  content: "\e42c";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-trees::before {
  content: "\f724";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-page::before {
  content: "\e428";
}

.fa-k::before {
  content: "K";
}

.fa-diagram-previous::before {
  content: "\e478";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-landmark-flag::before {
  content: "\e51c";
}

.fa-lips::before {
  content: "\f600";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-pencil-alt::before {
  content: "\f303";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-comments::before {
  content: "\f086";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-cloud-xmark::before {
  content: "\e35f";
}

.fa-vial-virus::before {
  content: "\e597";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-golf-flag-hole::before {
  content: "\e3ac";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-face-unamused::before {
  content: "\e39f";
}

.fa-sheet-plastic::before {
  content: "\e571";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-blog::before {
  content: "\f781";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-person-arrow-up-from-line::before {
  content: "\e539";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-fence::before {
  content: "\e303";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-face-awesome::before {
  content: "\e409";
}

.fa-gave-dandy::before {
  content: "\e409";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-face-smile-horns::before {
  content: "\e391";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-person-drowning::before {
  content: "\e545";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-w::before {
  content: "W";
}

.fa-code-pull-request-draft::before {
  content: "\e3fa";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-trowel-bricks::before {
  content: "\e58a";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-tent-arrow-left-right::before {
  content: "\e57f";
}

.fa-cart-circle-arrow-up::before {
  content: "\e3f0";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-sprinkler-ceiling::before {
  content: "\e44c";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-trillium::before {
  content: "\e588";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-badge::before {
  content: "\f335";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-album-collection-circle-plus::before {
  content: "\e48e";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-face-angry-horns::before {
  content: "\e368";
}

.fa-mars-and-venus-burst::before {
  content: "\e523";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-sun-plant-wilt::before {
  content: "\e57a";
}

.fa-toilets-portable::before {
  content: "\e584";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-hyphen::before {
  content: "-";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-user-chef::before {
  content: "\e3d2";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-magnifying-glass-arrow-right::before {
  content: "\e521";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-face-mask::before {
  content: "\e37f";
}

.fa-pickleball::before {
  content: "\e435";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-clover::before {
  content: "\e139";
}

.fa-meat::before {
  content: "\f814";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-house-fire::before {
  content: "\e50c";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-bird::before {
  content: "\e469";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-file-circle-question::before {
  content: "\e4ef";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-grill-fire::before {
  content: "\e5a4";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-people-group::before {
  content: "\e533";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-face-beam-hand-over-mouth::before {
  content: "\e47c";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-radar::before {
  content: "\e024";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-film::before {
  content: "\f008";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-people-robbery::before {
  content: "\e536";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-block::before {
  content: "\e46a";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-school-circle-xmark::before {
  content: "\e56d";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-face-frown-slight::before {
  content: "\e376";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-pipe-section::before {
  content: "\e438";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-memory::before {
  content: "\f538";
}

.fa-face-sleeping::before {
  content: "\e38d";
}

.fa-road-spikes::before {
  content: "\e568";
}

.fa-fire-burner::before {
  content: "\e4f1";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-flag::before {
  content: "\f024";
}

.fa-face-cowboy-hat::before {
  content: "\e36e";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-album::before {
  content: "\f89f";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-compress::before {
  content: "\f066";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-hands-holding-child::before {
  content: "\e4fa";
}

.fa-asterisk::before {
  content: "*";
}

.fa-key-skeleton-left-right::before {
  content: "\e3b4";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-train-tunnel::before {
  content: "\e454";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-face-anguished::before {
  content: "\e369";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-house-medical-circle-xmark::before {
  content: "\e513";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-clock-nine-thirty::before {
  content: "\e34d";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-paintbrush::before {
  content: "\f1fc";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-lock::before {
  content: "\f023";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-house-flood-water::before {
  content: "\e50e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-bridge-lock::before {
  content: "\e4cc";
}

.fa-crosshairs-simple::before {
  content: "\e59f";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-heart-circle-minus::before {
  content: "\e4ff";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-hourglass-half::before {
  content: "\f252";
}

.fa-hourglass-2::before {
  content: "\f252";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-person-rays::before {
  content: "\e54d";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-face-pleading::before {
  content: "\e386";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-flask-vial::before {
  content: "\e4f3";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-cucumber::before {
  content: "\e401";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-hand::before {
  content: "\f256";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-utensils-slash::before {
  content: "\e464";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-om::before {
  content: "\f679";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-face-shush::before {
  content: "\e38c";
}

.fa-worm::before {
  content: "\e599";
}

.fa-house-circle-xmark::before {
  content: "\e50b";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-calendar-circle-exclamation::before {
  content: "\e46e";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-face-saluting::before {
  content: "\e484";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-face-persevering::before {
  content: "\e385";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-popsicle::before {
  content: "\e43e";
}

.fa-command::before {
  content: "\e142";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-face-scream::before {
  content: "\e38b";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-lightbulb-cfl::before {
  content: "\e5a6";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-bridge-circle-xmark::before {
  content: "\e4cb";
}

.fa-period::before {
  content: ".";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-up-to-dotted-line::before {
  content: "\e457";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-clock-three-thirty::before {
  content: "\e357";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-face-relieved::before {
  content: "\e389";
}

.fa-road-circle-check::before {
  content: "\e564";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-face-zany::before {
  content: "\e3a4";
}

.fa-land-mine-on::before {
  content: "\e51b";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-salt-shaker::before {
  content: "\e446";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-i::before {
  content: "I";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-pills::before {
  content: "\f484";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-objects-align-bottom::before {
  content: "\e3bb";
}

.fa-v::before {
  content: "V";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-squid::before {
  content: "\e450";
}

.fa-leafy-green::before {
  content: "\e41d";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-staff-snake::before {
  content: "\e579";
}

.fa-rod-asclepius::before {
  content: "\e579";
}

.fa-rod-snake::before {
  content: "\e579";
}

.fa-staff-aesculapius::before {
  content: "\e579";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-apartment::before {
  content: "\e468";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-pepper::before {
  content: "\e432";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-wheat-awn-circle-exclamation::before {
  content: "\e598";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-road-barrier::before {
  content: "\e562";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-school::before {
  content: "\f549";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-bracket-round::before {
  content: "(";
}

.fa-parenthesis::before {
  content: "(";
}

.fa-joint::before {
  content: "\f595";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-q::before {
  content: "Q";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-g::before {
  content: "G";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-slot-machine::before {
  content: "\e3ce";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-donut::before {
  content: "\e406";
}

.fa-doughnut::before {
  content: "\e406";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-baguette::before {
  content: "\e3d8";
}

.fa-bowl-soft-serve::before {
  content: "\e46b";
}

.fa-face-holding-back-tears::before {
  content: "\e482";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-semicolon::before {
  content: ";";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-helmet-un::before {
  content: "\e503";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-sushi::before {
  content: "\e48a";
}

.fa-nigiri::before {
  content: "\e48a";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-option::before {
  content: "\e318";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-objects-align-top::before {
  content: "\e3c0";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-blank::before {
  content: "\f07b";
}

.fa-face-anxious-sweat::before {
  content: "\e36a";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-mug::before {
  content: "\f874";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-chart-simple::before {
  content: "\e473";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-vial::before {
  content: "\f492";
}

.fa-gauge::before {
  content: "\f624";
}

.fa-dashboard::before {
  content: "\f624";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-e::before {
  content: "E";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-h3::before {
  content: "\f315";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-bridge-circle-exclamation::before {
  content: "\e4ca";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-user::before {
  content: "\f007";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-comma::before {
  content: ",";
}

.fa-school-circle-check::before {
  content: "\e56b";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-light-emergency::before {
  content: "\e41f";
}

.fa-arrow-down-to-arc::before {
  content: "\e4ae";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-building-user::before {
  content: "\e4da";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-key::before {
  content: "\f084";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-tamale::before {
  content: "\e451";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-steak::before {
  content: "\f824";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-slash::before {
  content: "\f603";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-person-half-dress::before {
  content: "\e548";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-road-bridge::before {
  content: "\e563";
}

.fa-face-smile-tear::before {
  content: "\e393";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-c::before {
  content: "C";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-rectangle-history-circle-user::before {
  content: "\e4a4";
}

.fa-building-lock::before {
  content: "\e4d6";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-truck-bolt::before {
  content: "\e3d0";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-house-flag::before {
  content: "\e50d";
}

.fa-person-circle-minus::before {
  content: "\e540";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-face-weary::before {
  content: "\e3a1";
}

.fa-uniform-martial-arts::before {
  content: "\e3d1";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-diamond-exclamation::before {
  content: "\e405";
}

.fa-star::before {
  content: "\f005";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-cross::before {
  content: "\f654";
}

.fa-page-caret-down::before {
  content: "\e429";
}

.fa-file-caret-down::before {
  content: "\e429";
}

.fa-box::before {
  content: "\f466";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-clock-seven-thirty::before {
  content: "\e351";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-clock-four-thirty::before {
  content: "\e34b";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-peanuts::before {
  content: "\e431";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-mobile-retro::before {
  content: "\e527";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-bracket-curly-right::before {
  content: "}";
}

.fa-spider::before {
  content: "\f717";
}

.fa-clock-three::before {
  content: "\e356";
}

.fa-hands-bound::before {
  content: "\e4f9";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-pipe-smoking::before {
  content: "\e3c4";
}

.fa-face-astonished::before {
  content: "\e36b";
}

.fa-window::before {
  content: "\f40e";
}

.fa-plane-circle-exclamation::before {
  content: "\e556";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-file-lock::before {
  content: "\e3a6";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-goal-net::before {
  content: "\e3ab";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-slash::before {
  content: "\f715";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-server::before {
  content: "\f233";
}

.fa-virus-covid-slash::before {
  content: "\e4a9";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-shop-lock::before {
  content: "\e4a5";
}

.fa-family::before {
  content: "\e300";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-user-hair-buns::before {
  content: "\e3d3";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-hourglass-clock::before {
  content: "\e41b";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-building-wheat::before {
  content: "\e4db";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-person-breastfeeding::before {
  content: "\e53a";
}

.fa-apostrophe::before {
  content: "'";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-venus::before {
  content: "\f221";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clock-ten-thirty::before {
  content: "\e355";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-left-long-to-line::before {
  content: "\e41e";
}

.fa-crown::before {
  content: "\f521";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-xmarks-lines::before {
  content: "\e59a";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-books::before {
  content: "\f5db";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-block-question::before {
  content: "\e3dd";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-panel-fire::before {
  content: "\e42f";
}

.fa-binary-circle-check::before {
  content: "\e33c";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-objects-column::before {
  content: "\e3c1";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-l::before {
  content: "L";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-down-to-bracket::before {
  content: "\e4e7";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-stars::before {
  content: "\f762";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shirt-long-sleeve::before {
  content: "\e3c7";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-album-collection-circle-user::before {
  content: "\e48f";
}

.fa-candy::before {
  content: "\e3e7";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-flatbread::before {
  content: "\e40b";
}

.fa-heart-circle-plus::before {
  content: "\e500";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-city::before {
  content: "\f64f";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-clock-twelve::before {
  content: "\e358";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-headset::before {
  content: "\f590";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-road-circle-xmark::before {
  content: "\e566";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-taco::before {
  content: "\f826";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-house-circle-exclamation::before {
  content: "\e50a";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-rectangle-history-circle-plus::before {
  content: "\e4a3";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-binary-slash::before {
  content: "\e33e";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-bold::before {
  content: "\f032";
}

.fa-anchor-lock::before {
  content: "\e4ad";
}

.fa-building-ngo::before {
  content: "\e4d7";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-tilde::before {
  content: "~";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-face-downcast-sweat::before {
  content: "\e371";
}

.fa-memo-circle-info::before {
  content: "\e49a";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-gauge-circle-bolt::before {
  content: "\e496";
}

.fa-coin-front::before {
  content: "\e3fc";
}

.fa-file-slash::before {
  content: "\e3a7";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-paintbrush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paintbrush-alt::before {
  content: "\f5a9";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-building-circle-check::before {
  content: "\e4d2";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-right-long-to-line::before {
  content: "\e444";
}

.fa-person-chalkboard::before {
  content: "\e53d";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-hand-back-fist::before {
  content: "\f255";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-cloud-showers-water::before {
  content: "\e4e4";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-train::before {
  content: "\f238";
}

.fa-up-from-dotted-line::before {
  content: "\e456";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-face-exhaling::before {
  content: "\e480";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-00::before {
  content: "\e467";
}

.fa-crow::before {
  content: "\f520";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-sailboat::before {
  content: "\e445";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-bowl-scoops::before {
  content: "\e3df";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-custard::before {
  content: "\e403";
}

.fa-lacrosse-stick::before {
  content: "\e3b5";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-panel-ews::before {
  content: "\e42e";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-cloud-exclamation::before {
  content: "\e491";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-bucket::before {
  content: "\e4cf";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-image::before {
  content: "\f03e";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-square-ring::before {
  content: "\e44f";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-folder-closed::before {
  content: "\e185";
}

.fa-house-tsunami::before {
  content: "\e515";
}

.fa-square-nfi::before {
  content: "\e576";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-arrow-up-from-ground-water::before {
  content: "\e4b5";
}

.fa-bracket-square-right::before {
  content: "]";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-plate-wheat::before {
  content: "\e55a";
}

.fa-calendar-circle-minus::before {
  content: "\e46f";
}

.fa-chopsticks::before {
  content: "\e3f7";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-person-shelter::before {
  content: "\e54f";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-helicopter-symbol::before {
  content: "\e502";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-teddy-bear::before {
  content: "\e3cf";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-person-walking-dashed-line-arrow-right::before {
  content: "\e553";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-mask-snorkel::before {
  content: "\e3b7";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-square-person-confined::before {
  content: "\e577";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-tent-arrow-down-to-line::before {
  content: "\e57e";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-crystal-ball::before {
  content: "\e362";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-binary-lock::before {
  content: "\e33d";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-light-emergency-on::before {
  content: "\e420";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-location-pin-lock::before {
  content: "\e51f";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-clock-eight-thirty::before {
  content: "\e346";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-hill-avalanche::before {
  content: "\e507";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-integral::before {
  content: "\f667";
}

.fa-burst::before {
  content: "\e4dc";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-user-vneck-hair-long::before {
  content: "\e463";
}

.fa-square-a-lock::before {
  content: "\e44d";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-objects-align-right::before {
  content: "\e3bf";
}

.fa-arrows-turn-right::before {
  content: "\e4c0";
}

.fa-vest::before {
  content: "\e085";
}

.fa-pig::before {
  content: "\f706";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-ferry::before {
  content: "\e4ea";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-arrows-down-to-people::before {
  content: "\e4b9";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-clock-seven::before {
  content: "\e350";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-boxes-packing::before {
  content: "\e4c7";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-group-arrows-rotate::before {
  content: "\e4f6";
}

.fa-bowl-food::before {
  content: "\e4c6";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-text::before {
  content: "\f893";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-objects-align-center-vertical::before {
  content: "\e3bd";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-house-lock::before {
  content: "\e510";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-children::before {
  content: "\e4e1";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-lollipop::before {
  content: "\e424";
}

.fa-lollypop::before {
  content: "\e424";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-mattress-pillow::before {
  content: "\e525";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-face-confounded::before {
  content: "\e36c";
}

.fa-shield-halved::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-virus::before {
  content: "\e074";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-envelope-circle-check::before {
  content: "\e4e8";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-arrows-to-dot::before {
  content: "\e4be";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-hashtag-lock::before {
  content: "\e415";
}

.fa-clock-two-thirty::before {
  content: "\e35b";
}

.fa-archway::before {
  content: "\f557";
}

.fa-heart-circle-check::before {
  content: "\e4fd";
}

.fa-house-chimney-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-italic::before {
  content: "\f033";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-calendar-lines-pen::before {
  content: "\e472";
}

.fa-church::before {
  content: "\f51d";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-face-hushed::before {
  content: "\e37b";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-face-confused::before {
  content: "\e36d";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-z::before {
  content: "Z";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-road-lock::before {
  content: "\e567";
}

.fa-a::before {
  content: "A";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-p::before {
  content: "P";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-arrow-turn-down-right::before {
  content: "\e3d6";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-bread-slice-butter::before {
  content: "\e3e1";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-toilet-paper-check::before {
  content: "\e5b2";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-locust::before {
  content: "\e520";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-face-party::before {
  content: "\e383";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-person-dress-burst::before {
  content: "\e544";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-podium::before {
  content: "\f680";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-user-nurse-hair::before {
  content: "\e45d";
}

.fa-question::before {
  content: "?";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-toggle-large-on::before {
  content: "\e5b1";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-house-chimney-user::before {
  content: "\e065";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-train-track::before {
  content: "\e453";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-code::before {
  content: "\f121";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-building-circle-exclamation::before {
  content: "\e4d3";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-magnifying-glass-chart::before {
  content: "\e522";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-cubes-stacked::before {
  content: "\e4e6";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-virus-covid::before {
  content: "\e4a8";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-pie::before {
  content: "\f705";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-cloud-plus::before {
  content: "\e35e";
}

.fa-f::before {
  content: "F";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-road::before {
  content: "\f018";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-person-circle-plus::before {
  content: "\e541";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-clock-eight::before {
  content: "\e345";
}

.fa-sack-xmark::before {
  content: "\e56a";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-fish-fins::before {
  content: "\e4f2";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-building-flag::before {
  content: "\e4d5";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-face-disguise::before {
  content: "\e370";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-poop::before {
  content: "\f619";
}

.fa-object-exclude::before {
  content: "\e49c";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-location-pin::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-square-list::before {
  content: "\e489";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-starship::before {
  content: "\e039";
}

.fa-eject::before {
  content: "\f052";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-plane-circle-check::before {
  content: "\e555";
}

.fa-seal::before {
  content: "\e241";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-hexagon-vertical-nft::before {
  content: "\e505";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-mask-ventilator::before {
  content: "\e524";
}

.fa-signature-lock::before {
  content: "\e3ca";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-person-circle-question::before {
  content: "\e542";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-cup-straw::before {
  content: "\e363";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-h::before {
  content: "H";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-clock-nine::before {
  content: "\e34c";
}

.fa-tarp::before {
  content: "\e57b";
}

.fa-face-sleepy::before {
  content: "\e38e";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-arrows-to-eye::before {
  content: "\e4bf";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-plug-circle-bolt::before {
  content: "\e55b";
}

.fa-face-thermometer::before {
  content: "\e39a";
}

.fa-shirt-running::before {
  content: "\e3c8";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-face-nauseated::before {
  content: "\e381";
}

.fa-heart::before {
  content: "\f004";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-mars-and-venus::before {
  content: "\f224";
}

.fa-house-user::before {
  content: "\e1b0";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-house-crack::before {
  content: "\e3b1";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-arrow-left-long-to-line::before {
  content: "\e3d4";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-arrow-right-to-arc::before {
  content: "\e4b2";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-bottle-water::before {
  content: "\e4c5";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-gauge-circle-plus::before {
  content: "\e498";
}

.fa-folders::before {
  content: "\f660";
}

.fa-angel::before {
  content: "\f779";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-kitchen-set::before {
  content: "\e51a";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-r::before {
  content: "R";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-toilet-paper-xmark::before {
  content: "\e5b3";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-shutters::before {
  content: "\e449";
}

.fa-shield-dog::before {
  content: "\e573";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-house-chimney-heart::before {
  content: "\e1b2";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-money-bill-transfer::before {
  content: "\e528";
}

.fa-money-bill-trend-up::before {
  content: "\e529";
}

.fa-house-flood-water-circle-arrow-right::before {
  content: "\e50f";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-circle::before {
  content: "\f111";
}

.fa-cart-circle-exclamation::before {
  content: "\e3f2";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-basketball::before {
  content: "\f434";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-slider::before {
  content: "\e252";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-clock-one-thirty::before {
  content: "\e34f";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-users-rays::before {
  content: "\e593";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-octagon-check::before {
  content: "\e426";
}

.fa-flatbread-stuffed::before {
  content: "\e40c";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-cart-circle-plus::before {
  content: "\e3f3";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-pool-8-ball::before {
  content: "\e3c5";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-lock-hashtag::before {
  content: "\e423";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-bugs::before {
  content: "\e4d0";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-can-food::before {
  content: "\e3e6";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-arrow-right-from-arc::before {
  content: "\e4b1";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-face-hand-over-mouth::before {
  content: "\e378";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-house-water::before {
  content: "\f74f";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-object-subtract::before {
  content: "\e49e";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-bridge::before {
  content: "\e4c8";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-square-quarters::before {
  content: "\e44e";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-files::before {
  content: "\e178";
}

.fa-anchor-circle-exclamation::before {
  content: "\e4ab";
}

.fa-face-clouds::before {
  content: "\e47d";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-truck-field::before {
  content: "\e58d";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-cart-circle-check::before {
  content: "\e3f1";
}

.fa-clipboard-question::before {
  content: "\e4e3";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-user-tie-hair-long::before {
  content: "\e460";
}

.fa-file-circle-minus::before {
  content: "\e4ed";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-card-diamond::before {
  content: "\e3ea";
}

.fa-face-zipper::before {
  content: "\e3a5";
}

.fa-face-raised-eyebrow::before {
  content: "\e388";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-house-medical-flag::before {
  content: "\e514";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-chestnut::before {
  content: "\e3f6";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-boot-heeled::before {
  content: "\e33f";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-person-military-to-person::before {
  content: "\e54c";
}

.fa-file-shield::before {
  content: "\e4f0";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-pen::before {
  content: "\f304";
}

.fa-tower-observation::before {
  content: "\e586";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-pump::before {
  content: "\e442";
}

.fa-bus::before {
  content: "\f207";
}

.fa-heart-circle-xmark::before {
  content: "\e501";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-house-chimney::before {
  content: "\e3af";
}

.fa-home-lg::before {
  content: "\e3af";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-shirt-tank-top::before {
  content: "\e3c9";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-walker::before {
  content: "\f831";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-face-kiss-closed-eyes::before {
  content: "\e37d";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-face-tongue-money::before {
  content: "\e39d";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-plant-wilt::before {
  content: "\e5aa";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-album-circle-plus::before {
  content: "\e48c";
}

.fa-user-nurse-hair-long::before {
  content: "\e45e";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-grid-dividers::before {
  content: "\e3ad";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-face-pensive::before {
  content: "\e384";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location::before {
  content: "\f601";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-card-club::before {
  content: "\e3e9";
}

.fa-child-dress::before {
  content: "\e59c";
}

.fa-users-between-lines::before {
  content: "\e591";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-phone::before {
  content: "\f095";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-child-reaching::before {
  content: "\e59d";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-calendar-circle-plus::before {
  content: "\e470";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-house-blank::before {
  content: "\e487";
}

.fa-home-blank::before {
  content: "\e487";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-puzzle::before {
  content: "\e443";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-pipe-circle-check::before {
  content: "\e436";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-mosquito-net::before {
  content: "\e52c";
}

.fa-vent-damper::before {
  content: "\e465";
}

.fa-bridge-water::before {
  content: "\e4ce";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-square-kanban::before {
  content: "\e488";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-mouse-field::before {
  content: "\e5a8";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-car-side-bolt::before {
  content: "\e344";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-gauge-simple::before {
  content: "\f629";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-face-melting::before {
  content: "\e483";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-hose::before {
  content: "\e419";
}

.fa-clock-six::before {
  content: "\e352";
}

.fa-shirt::before {
  content: "\f553";
}

.fa-t-shirt::before {
  content: "\f553";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-divide::before {
  content: "\f529";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-person-circle-xmark::before {
  content: "\e543";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-cup-straw-swoosh::before {
  content: "\e364";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-j::before {
  content: "J";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-users-viewfinder::before {
  content: "\e595";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-tank-water::before {
  content: "\e452";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-gamepad-modern::before {
  content: "\e5a2";
}

.fa-gamepad-alt::before {
  content: "\e5a2";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-o::before {
  content: "O";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-clock-eleven-thirty::before {
  content: "\e348";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-otter::before {
  content: "\f700";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-face-fearful::before {
  content: "\e375";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-plane-lock::before {
  content: "\e558";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-comment::before {
  content: "\f075";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-cake::before {
  content: "\f1fd";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-face-tissue::before {
  content: "\e39c";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-arrow-right-to-city::before {
  content: "\e4b3";
}

.fa-lock-a::before {
  content: "\e422";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-h6::before {
  content: "\e413";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-circle-nodes::before {
  content: "\e4e2";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-rugby-ball::before {
  content: "\e3c6";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-truck-field-un::before {
  content: "\e58e";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-empty::before {
  content: "\f254";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-house-heart::before {
  content: "\f4c9";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-house-chimney-blank::before {
  content: "\e3b0";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-fishing-rod::before {
  content: "\e3a8";
}

.fa-hammer-crash::before {
  content: "\e414";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-square-virus::before {
  content: "\e578";
}

.fa-cart-arrow-up::before {
  content: "\e3ee";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-car-on::before {
  content: "\e4dd";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-water::before {
  content: "\f773";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-plate-utensils::before {
  content: "\e43b";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-hose-reel::before {
  content: "\e41a";
}

.fa-house-window::before {
  content: "\e3b3";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-person-cane::before {
  content: "\e53c";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-tent::before {
  content: "\e57d";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-plus-large::before {
  content: "\e59e";
}

.fa-money-bill-wheat::before {
  content: "\e52a";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-screenshot::before {
  content: "\e0da";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-reel::before {
  content: "\e238";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-tarp-droplet::before {
  content: "\e57c";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-house-medical-circle-check::before {
  content: "\e511";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-grate::before {
  content: "\e193";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-distribute-spacing-vertical::before {
  content: "\e366";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-sportsball::before {
  content: "\e44b";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-face-smile-upside-down::before {
  content: "\e395";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-dna::before {
  content: "\f471";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-bracket-round-right::before {
  content: ")";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-child-rifle::before {
  content: "\e4e0";
}

.fa-gif::before {
  content: "\e190";
}

.fa-chess::before {
  content: "\f439";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-plug-circle-check::before {
  content: "\e55c";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-face-smile-halo::before {
  content: "\e38f";
}

.fa-cart-circle-arrow-down::before {
  content: "\e3ef";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-return::before {
  content: "\e011";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-user-doctor-hair-long::before {
  content: "\e459";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-mosquito::before {
  content: "\e52b";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-person-military-rifle::before {
  content: "\e54b";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-vials::before {
  content: "\f493";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-distribute-spacing-horizontal::before {
  content: "\e365";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-moped::before {
  content: "\e3b9";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-face-swear::before {
  content: "\e399";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-plug-circle-plus::before {
  content: "\e55f";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-waveform-path::before {
  content: "\f8f2";
}

.fa-split::before {
  content: "\e254";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-cannister::before {
  content: "\f8b7";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-hexagon-vertical-nft-slanted::before {
  content: "\e506";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-u::before {
  content: "U";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-dash::before {
  content: "\e404";
}

.fa-minus-large::before {
  content: "\e404";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-four::before {
  content: "\f017";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-car-bolt::before {
  content: "\e341";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-pen-nib-slash::before {
  content: "\e4a1";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-overline::before {
  content: "\f876";
}

.fa-s::before {
  content: "S";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-cards::before {
  content: "\e3ed";
}

.fa-house-chimney-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-face-head-bandage::before {
  content: "\e37a";
}

.fa-sushi-roll::before {
  content: "\e48b";
}

.fa-maki-roll::before {
  content: "\e48b";
}

.fa-makizushi::before {
  content: "\e48b";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-car-mirrors::before {
  content: "\e343";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-mountain-city::before {
  content: "\e52e";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-honey-pot::before {
  content: "\e418";
}

.fa-olive::before {
  content: "\e316";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-people::before {
  content: "\e216";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-cent-sign::before {
  content: "\e3f5";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-pipe-collar::before {
  content: "\e437";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-laptop-file::before {
  content: "\e51d";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-diagram-next::before {
  content: "\e476";
}

.fa-person-rifle::before {
  content: "\e54e";
}

.fa-clock-five-thirty::before {
  content: "\e34a";
}

.fa-pipe-valve::before {
  content: "\e439";
}

.fa-arrow-up-from-arc::before {
  content: "\e4b4";
}

.fa-face-spiral-eyes::before {
  content: "\e485";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-house-medical-circle-exclamation::before {
  content: "\e512";
}

.fa-badminton::before {
  content: "\e33a";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-images::before {
  content: "\f302";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-user-hair::before {
  content: "\e45a";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-people-pulling::before {
  content: "\e535";
}

.fa-n::before {
  content: "N";
}

.fa-garage::before {
  content: "\e009";
}

.fa-cable-car::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-face-lying::before {
  content: "\e37e";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-building-circle-xmark::before {
  content: "\e4d4";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-clock-six-thirty::before {
  content: "\e353";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-arrows-down-to-line::before {
  content: "\e4b8";
}

.fa-download::before {
  content: "\f019";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-file-circle-check::before {
  content: "\e5a0";
}

.fa-glass::before {
  content: "\f804";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-code-pull-request-closed::before {
  content: "\e3f9";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-face-dotted::before {
  content: "\e47f";
}

.fa-face-worried::before {
  content: "\e3a3";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-heart-circle-exclamation::before {
  content: "\e4fe";
}

.fa-house::before {
  content: "\f015";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-alt::before {
  content: "\f015";
}

.fa-home-lg-alt::before {
  content: "\f015";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-car-circle-bolt::before {
  content: "\e342";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-flying-disc::before {
  content: "\e3a9";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-b::before {
  content: "B";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-pipe::before {
  content: "|";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-potato::before {
  content: "\e440";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-butter::before {
  content: "\e3e4";
}

.fa-blanket-fire::before {
  content: "\e3da";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-upload::before {
  content: "\f093";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-mound::before {
  content: "\e52d";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-toilet-portable::before {
  content: "\e583";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-shield-cat::before {
  content: "\e572";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-zap::before {
  content: "\f0e7";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-glass-water::before {
  content: "\e4f4";
}

.fa-oil-well::before {
  content: "\e532";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-mars::before {
  content: "\f222";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-plane-circle-xmark::before {
  content: "\e557";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-notes::before {
  content: "\e202";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-objects-align-center-horizontal::before {
  content: "\e3bc";
}

.fa-sun::before {
  content: "\f185";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-face-smile-hearts::before {
  content: "\e390";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-hockey-stick-puck::before {
  content: "\e3ae";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-bore-hole::before {
  content: "\e4c3";
}

.fa-industry::before {
  content: "\f275";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-grill::before {
  content: "\e5a3";
}

.fa-arrows-turn-to-dots::before {
  content: "\e4c1";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-less-than::before {
  content: "<";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-face-drooling::before {
  content: "\e372";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-car-tunnel::before {
  content: "\e4de";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-pan-food::before {
  content: "\e42b";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-anchor-circle-xmark::before {
  content: "\e4ac";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-person-through-window::before {
  content: "\e5a9";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-hands-holding-circle::before {
  content: "\e4fb";
}

.fa-bug::before {
  content: "\f188";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-hand-holding-hand::before {
  content: "\e4f7";
}

.fa-user-tie-hair::before {
  content: "\e45f";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-user-hair-mullet::before {
  content: "\e45c";
}

.fa-business-front::before {
  content: "\e45c";
}

.fa-party-back::before {
  content: "\e45c";
}

.fa-trian-balbot::before {
  content: "\e45c";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-cabin::before {
  content: "\e46d";
}

.fa-mountain-sun::before {
  content: "\e52f";
}

.fa-chart-simple-horizontal::before {
  content: "\e474";
}

.fa-arrows-left-right-to-line::before {
  content: "\e4ba";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-truck-droplet::before {
  content: "\e58c";
}

.fa-file-circle-xmark::before {
  content: "\e5a1";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-bed::before {
  content: "\f236";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-clock-two::before {
  content: "\e35a";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-candy-bar::before {
  content: "\e3e8";
}

.fa-chocolate-bar::before {
  content: "\e3e8";
}

.fa-xmark-large::before {
  content: "\e59b";
}

.fa-pinata::before {
  content: "\e3c3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-bowl-spoon::before {
  content: "\e3e0";
}

.fa-hexagon-check::before {
  content: "\e416";
}

.fa-plug-circle-xmark::before {
  content: "\e560";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-accent-grave::before {
  content: "`";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-crab::before {
  content: "\e3ff";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-greater-than::before {
  content: ">";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-pretzel::before {
  content: "\e441";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-user-robot-xmarks::before {
  content: "\e4a7";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-hexagon-image::before {
  content: "\e504";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-earth-america::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-person-burst::before {
  content: "\e53b";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-socks::before {
  content: "\f696";
}

.fa-face-sunglasses::before {
  content: "\e398";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-section::before {
  content: "\e447";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-alt::before {
  content: "\f0f8";
}

.fa-hospital-wide::before {
  content: "\f0f8";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-objects-align-left::before {
  content: "\e3be";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-face-smiling-hands::before {
  content: "\e396";
}

.fa-broccoli::before {
  content: "\e3e2";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-drum::before {
  content: "\f569";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-heart-circle-bolt::before {
  content: "\e4fc";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-grill-hot::before {
  content: "\e5a5";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-tree-city::before {
  content: "\e587";
}

.fa-play::before {
  content: "\f04b";
}

.fa-font::before {
  content: "\f031";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-burger-lettuce::before {
  content: "\e3e3";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-bracket-curly::before {
  content: "{";
}

.fa-bracket-curly-left::before {
  content: "{";
}

.fa-lobster::before {
  content: "\e421";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-colon::before {
  content: ":";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-person-circle-exclamation::before {
  content: "\e53f";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-club::before {
  content: "\f327";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-school-lock::before {
  content: "\e56f";
}

.fa-tower-cell::before {
  content: "\e585";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-ranking-star::before {
  content: "\e561";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-person-harassing::before {
  content: "\e549";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-brazilian-real-sign::before {
  content: "\e46c";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-down-from-dotted-line::before {
  content: "\e407";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-shrimp::before {
  content: "\e448";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-diagram-subtask::before {
  content: "\e479";
}

.fa-jug-detergent::before {
  content: "\e519";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-user-doctor-hair::before {
  content: "\e458";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-mushroom::before {
  content: "\e425";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-car-burst::before {
  content: "\f5e1";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-y::before {
  content: "Y";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-lacrosse-stick-ball::before {
  content: "\e3b6";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-fish::before {
  content: "\f578";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-waffle::before {
  content: "\e466";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-hexagon-exclamation::before {
  content: "\e417";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-object-union::before {
  content: "\e49f";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-arrow-right-long-to-line::before {
  content: "\e3d5";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-phone-intercom::before {
  content: "\e434";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-face-tongue-sweat::before {
  content: "\e39e";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-baseball::before {
  content: "\f433";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-jet-fighter-up::before {
  content: "\e518";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-user-vneck-hair::before {
  content: "\e462";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-plus::before {
  content: "+";
}

.fa-add::before {
  content: "+";
}

.fa-expand::before {
  content: "\f065";
}

.fa-computer::before {
  content: "\e4e5";
}

.fa-fort::before {
  content: "\e486";
}

.fa-cloud-check::before {
  content: "\e35c";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-face-smirking::before {
  content: "\e397";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-face-smile-tongue::before {
  content: "\e394";
}

.fa-cart-circle-xmark::before {
  content: "\e3f4";
}

.fa-building-shield::before {
  content: "\e4d8";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-users-line::before {
  content: "\e592";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-arrow-down-up-lock::before {
  content: "\e4b0";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-lines-leaning::before {
  content: "\e51e";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-bracket-square::before {
  content: "[";
}

.fa-bracket::before {
  content: "[";
}

.fa-bracket-left::before {
  content: "[";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-h2::before {
  content: "\f314";
}

.fa-equals::before {
  content: "=";
}

.fa-cake-slice::before {
  content: "\e3e5";
}

.fa-shortcake::before {
  content: "\e3e5";
}

.fa-peanut::before {
  content: "\e430";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-blender::before {
  content: "\f517";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-user-hair-long::before {
  content: "\e45b";
}

.fa-map::before {
  content: "\f279";
}

.fa-file-circle-info::before {
  content: "\e493";
}

.fa-face-disappointed::before {
  content: "\e36f";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-clock-eleven::before {
  content: "\e347";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-clock-ten::before {
  content: "\e354";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-face-eyes-xmarks::before {
  content: "\e374";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-store::before {
  content: "\f54e";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-plug-circle-minus::before {
  content: "\e55e";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-store-lock::before {
  content: "\e4a6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-school-flag::before {
  content: "\e56e";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-fill::before {
  content: "\f575";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-person-to-door::before {
  content: "\e433";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-clock-five::before {
  content: "\e349";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-frame::before {
  content: "\e495";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-face-expressionless::before {
  content: "\e373";
}

.fa-down-to-dotted-line::before {
  content: "\e408";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-cloud-minus::before {
  content: "\e35d";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-gauge-circle-minus::before {
  content: "\e497";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-percent::before {
  content: "%";
}

.fa-percentage::before {
  content: "%";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-glass-water-droplet::before {
  content: "\e4f5";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-coin-vertical::before {
  content: "\e3fd";
}

.fa-display::before {
  content: "\e163";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-signature-slash::before {
  content: "\e3cb";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-face-vomit::before {
  content: "\e3a0";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-circle-quarters::before {
  content: "\e3f8";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-robot::before {
  content: "\f544";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-arrow-up-right-dots::before {
  content: "\e4b7";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-transgender::before {
  content: "\f225";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-up-from-bracket::before {
  content: "\e590";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-card-spade::before {
  content: "\e3ec";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-person-falling-burst::before {
  content: "\e547";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-award::before {
  content: "\f559";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-pancakes::before {
  content: "\e42d";
}

.fa-album-circle-user::before {
  content: "\e48d";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-nesting-dolls::before {
  content: "\e3ba";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f132";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-binary::before {
  content: "\e33b";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-ditto::before {
  content: '"';
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-house-medical::before {
  content: "\e3b2";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-house-chimney-window::before {
  content: "\e00d";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-tent-arrow-turn-left::before {
  content: "\e580";
}

.fa-face-diagonal-mouth::before {
  content: "\e47e";
}

.fa-diagram-cells::before {
  content: "\e475";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-tents::before {
  content: "\e582";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-ampersand::before {
  content: "&";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-moon::before {
  content: "\f186";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-music::before {
  content: "\f001";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-clock-one::before {
  content: "\e34e";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-coin-blank::before {
  content: "\e3fb";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-shield-heart::before {
  content: "\e574";
}

.fa-card-heart::before {
  content: "\e3eb";
}

.fa-lightbulb-cfl-on::before {
  content: "\e5a7";
}

.fa-melon::before {
  content: "\e310";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-earth-oceania::before {
  content: "\e47b";
}

.fa-globe-oceania::before {
  content: "\e47b";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-face-pouting::before {
  content: "\e387";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-hashtag::before {
  content: "#";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-t::before {
  content: "T";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-vial-circle-check::before {
  content: "\e596";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-object-intersect::before {
  content: "\e49d";
}

.fa-person-arrow-down-to-line::before {
  content: "\e538";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-block-brick::before {
  content: "\e3db";
}

.fa-wall-brick::before {
  content: "\e3db";
}

.fa-fan::before {
  content: "\f863";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-person-walking-luggage::before {
  content: "\e554";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-haykal::before {
  content: "\f666";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-plug-circle-exclamation::before {
  content: "\e55d";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-person-walking-arrow-loop-left::before {
  content: "\e551";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-file-circle-plus::before {
  content: "\e494";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-function::before {
  content: "\f661";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-face-monocle::before {
  content: "\e380";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-tachometer-fast::before {
  content: "\f62a";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-desktop::before {
  content: "\f390";
}

.fa-desktop-alt::before {
  content: "\f390";
}

.fa-m::before {
  content: "M";
}

.fa-grip-dots-vertical::before {
  content: "\e411";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-soft-serve::before {
  content: "\e400";
}

.fa-creemee::before {
  content: "\e400";
}

.fa-h5::before {
  content: "\e412";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-book::before {
  content: "\f02d";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-check::before {
  content: "\f00c";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-house-circle-check::before {
  content: "\e509";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-diagram-successor::before {
  content: "\e47a";
}

.fa-truck-arrow-right::before {
  content: "\e58b";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-arrows-split-up-and-left::before {
  content: "\e4bc";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-shield-quartered::before {
  content: "\e575";
}

.fa-slash-forward::before {
  content: "/";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-pot-food::before {
  content: "\e43f";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-person-falling::before {
  content: "\e546";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-rug::before {
  content: "\e569";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-earth-europe::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-corner::before {
  content: "\e3fe";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-handcuffs::before {
  content: "\e4f8";
}

.fa-snake::before {
  content: "\f716";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-face-thinking::before {
  content: "\e39b";
}

.fa-turn-down-right::before {
  content: "\e455";
}

.fa-bottle-droplet::before {
  content: "\e4c4";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-hill-rockslide::before {
  content: "\e508";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-columns-3::before {
  content: "\e361";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-road-circle-exclamation::before {
  content: "\e565";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner::before {
  content: "\f488";
}

.fa-tire::before {
  content: "\f631";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-person-walking-arrow-right::before {
  content: "\e552";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-dice::before {
  content: "\f522";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-pompebled::before {
  content: "\e43d";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-onion::before {
  content: "\e427";
}

.fa-clock-twelve-thirty::before {
  content: "\e359";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-garlic::before {
  content: "\e40e";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-train-tram::before {
  content: "\e5b4";
}

.fa-bridge-suspension::before {
  content: "\e4cd";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-grip-dots::before {
  content: "\e410";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-falafel::before {
  content: "\e40a";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-jar::before {
  content: "\e516";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-hundred-points::before {
  content: "\e41c";
}

.fa-100::before {
  content: "\e41c";
}

.fa-paperclip-vertical::before {
  content: "\e3c2";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-location-pin-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-face-sad-sweat::before {
  content: "\e38a";
}

.fa-bug-slash::before {
  content: "\e490";
}

.fa-cupcake::before {
  content: "\e402";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-toggle-large-off::before {
  content: "\e5b0";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-boot::before {
  content: "\f782";
}

.fa-arrow-up-from-water-pump::before {
  content: "\e4b6";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-cards-blank::before {
  content: "\e4df";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-ski-boot-ski::before {
  content: "\e3cd";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-block-brick-fire::before {
  content: "\e3dc";
}

.fa-firewall::before {
  content: "\e3dc";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-plane::before {
  content: "\f072";
}

.fa-tent-arrows-down::before {
  content: "\e581";
}

.fa-exclamation::before {
  content: "!";
}

.fa-arrows-spin::before {
  content: "\e4bb";
}

.fa-face-smile-relaxed::before {
  content: "\e392";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-print::before {
  content: "\f02f";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-face-nose-steam::before {
  content: "\e382";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-dollar-sign::before {
  content: "$";
}

.fa-dollar::before {
  content: "$";
}

.fa-usd::before {
  content: "$";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-x::before {
  content: "X";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-person-military-pointing::before {
  content: "\e54a";
}

.fa-building-columns::before {
  content: "\f19c";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-museum::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-trowel::before {
  content: "\e589";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-d::before {
  content: "D";
}

.fa-stapler::before {
  content: "\e5af";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-face-woozy::before {
  content: "\e3a2";
}

.fa-cloud-question::before {
  content: "\e492";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-gallery-thumbnails::before {
  content: "\e3aa";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-eyes::before {
  content: "\e367";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-page-caret-up::before {
  content: "\e42a";
}

.fa-file-caret-up::before {
  content: "\e42a";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-comet::before {
  content: "\e003";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-plus-minus::before {
  content: "\e43c";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-person-circle-check::before {
  content: "\e53e";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-aws:before {
  content: "\f375";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-modx:before {
  content: "\f285";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-square-js:before {
  content: "\f3b9";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-unity:before {
  content: "\e049";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-vk:before {
  content: "\f189";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-square-reddit:before {
  content: "\f1a2";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-square-font-awesome:before {
  content: "\e5ad";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-square-instagram:before {
  content: "\e055";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-square-hacker-news:before {
  content: "\f3af";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-edge:before {
  content: "\f282";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-square-snapchat:before {
  content: "\f2ad";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-safari:before {
  content: "\f267";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-square-font-awesome-stroke:before {
  content: "\f35c";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-square-viadeo:before {
  content: "\f2aa";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-centos:before {
  content: "\f789";
}

.fa-adn:before {
  content: "\f170";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-square-dribbble:before {
  content: "\f397";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-node:before {
  content: "\f419";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-square-twitter:before {
  content: "\f081";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-golang:before {
  content: "\e40f";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-square-youtube:before {
  content: "\f431";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-rendact:before {
  content: "\f3e4";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-nfc-directional:before {
  content: "\e530";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-meta:before {
  content: "\e49b";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-hips:before {
  content: "\f452";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-discord:before {
  content: "\f392";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-square-steam:before {
  content: "\f1b7";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-square-vimeo:before {
  content: "\f194";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-flag:before {
  content: "\f2b4";
}

.fa-font-awesome-logo-full:before {
  content: "\f2b4";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-apple:before {
  content: "\f179";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-padlet:before {
  content: "\e4a0";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-square-github:before {
  content: "\f092";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-neos:before {
  content: "\f612";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-angular:before {
  content: "\f420";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envira:before {
  content: "\f299";
}

.fa-square-gitlab:before {
  content: "\e5ae";
}

.fa-gitlab-square:before {
  content: "\e5ae";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-square-odnoklassniki:before {
  content: "\f264";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-sith:before {
  content: "\f512";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-hashnode:before {
  content: "\e499";
}

.fa-react:before {
  content: "\f41b";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-gg:before {
  content: "\f260";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-square-pinterest:before {
  content: "\f0d3";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-xing:before {
  content: "\f168";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-less:before {
  content: "\f41d";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-nfc-symbol:before {
  content: "\e531";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-square-google-plus:before {
  content: "\f0d4";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-square-xing:before {
  content: "\f169";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-fly:before {
  content: "\f417";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-viber:before {
  content: "\f409";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-audible:before {
  content: "\f373";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-bilibili:before {
  content: "\e3d9";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-42-group:before {
  content: "\e080";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-square-pied-piper:before {
  content: "\e01e";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-square-facebook:before {
  content: "\f082";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-square-lastfm:before {
  content: "\f203";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-figma:before {
  content: "\f799";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-cmplid:before {
  content: "\e360";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-docker:before {
  content: "\f395";
}

.fa-screenpal:before {
  content: "\e570";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-wirsindhandwerk:before {
  content: "\e2d0";
}

.fa-wsh:before {
  content: "\e2d0";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-apper:before {
  content: "\f371";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ab";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-square-behance:before {
  content: "\f1b5";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-space-awesome:before {
  content: "\e5ac";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-square-git:before {
  content: "\f1d2";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-square-tumblr:before {
  content: "\f174";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-trello:before {
  content: "\f181";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-android:before {
  content: "\f17b";
}

.fa-bots:before {
  content: "\e340";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-uber:before {
  content: "\f402";
}

.fa-github:before {
  content: "\f09b";
}

.fa-php:before {
  content: "\f457";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f2c6";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-square-whatsapp:before {
  content: "\f40c";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f198";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-usb:before {
  content: "\f287";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f23a";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-sitrox:before {
  content: "\e44a";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-strava:before {
  content: "\f428";
}

.fa-ember:before {
  content: "\f423";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-zhihu:before {
  content: "\f63f";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-pix:before {
  content: "\e43a";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

:root, :host {
  --fa-style-family-duotone: "Font Awesome 6 Duotone";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-duotone-900.woff2") format("woff2"), url("../webfonts/fa-duotone-900.ttf") format("truetype");
}
.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal;
}

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
}

.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen readers do not read off random characters that represent icons */
.fad.fa-0::after, .fa-duotone.fa-0::after {
  content: "00";
}

.fad.fa-1::after, .fa-duotone.fa-1::after {
  content: "11";
}

.fad.fa-2::after, .fa-duotone.fa-2::after {
  content: "22";
}

.fad.fa-3::after, .fa-duotone.fa-3::after {
  content: "33";
}

.fad.fa-4::after, .fa-duotone.fa-4::after {
  content: "44";
}

.fad.fa-5::after, .fa-duotone.fa-5::after {
  content: "55";
}

.fad.fa-6::after, .fa-duotone.fa-6::after {
  content: "66";
}

.fad.fa-7::after, .fa-duotone.fa-7::after {
  content: "77";
}

.fad.fa-8::after, .fa-duotone.fa-8::after {
  content: "88";
}

.fad.fa-9::after, .fa-duotone.fa-9::after {
  content: "99";
}

.fad.fa-fill-drip::after, .fa-duotone.fa-fill-drip::after {
  content: "\f576\f576";
}

.fad.fa-arrows-to-circle::after, .fa-duotone.fa-arrows-to-circle::after {
  content: "\e4bd\e4bd";
}

.fad.fa-circle-chevron-right::after, .fa-duotone.fa-circle-chevron-right::after {
  content: "\f138\f138";
}

.fad.fa-chevron-circle-right::after, .fa-duotone.fa-chevron-circle-right::after {
  content: "\f138\f138";
}

.fad.fa-wagon-covered::after, .fa-duotone.fa-wagon-covered::after {
  content: "\f8ee\f8ee";
}

.fad.fa-line-height::after, .fa-duotone.fa-line-height::after {
  content: "\f871\f871";
}

.fad.fa-bagel::after, .fa-duotone.fa-bagel::after {
  content: "\e3d7\e3d7";
}

.fad.fa-transporter-7::after, .fa-duotone.fa-transporter-7::after {
  content: "\e2a8\e2a8";
}

.fad.fa-at::after, .fa-duotone.fa-at::after {
  content: "@@";
}

.fad.fa-rectangles-mixed::after, .fa-duotone.fa-rectangles-mixed::after {
  content: "\e323\e323";
}

.fad.fa-phone-arrow-up-right::after, .fa-duotone.fa-phone-arrow-up-right::after {
  content: "\e224\e224";
}

.fad.fa-phone-arrow-up::after, .fa-duotone.fa-phone-arrow-up::after {
  content: "\e224\e224";
}

.fad.fa-phone-outgoing::after, .fa-duotone.fa-phone-outgoing::after {
  content: "\e224\e224";
}

.fad.fa-trash-can::after, .fa-duotone.fa-trash-can::after {
  content: "\f2ed\f2ed";
}

.fad.fa-trash-alt::after, .fa-duotone.fa-trash-alt::after {
  content: "\f2ed\f2ed";
}

.fad.fa-circle-l::after, .fa-duotone.fa-circle-l::after {
  content: "\e114\e114";
}

.fad.fa-head-side-goggles::after, .fa-duotone.fa-head-side-goggles::after {
  content: "\f6ea\f6ea";
}

.fad.fa-head-vr::after, .fa-duotone.fa-head-vr::after {
  content: "\f6ea\f6ea";
}

.fad.fa-text-height::after, .fa-duotone.fa-text-height::after {
  content: "\f034\f034";
}

.fad.fa-user-xmark::after, .fa-duotone.fa-user-xmark::after {
  content: "\f235\f235";
}

.fad.fa-user-times::after, .fa-duotone.fa-user-times::after {
  content: "\f235\f235";
}

.fad.fa-face-hand-yawn::after, .fa-duotone.fa-face-hand-yawn::after {
  content: "\e379\e379";
}

.fad.fa-gauge-simple-min::after, .fa-duotone.fa-gauge-simple-min::after {
  content: "\f62d\f62d";
}

.fad.fa-tachometer-slowest::after, .fa-duotone.fa-tachometer-slowest::after {
  content: "\f62d\f62d";
}

.fad.fa-stethoscope::after, .fa-duotone.fa-stethoscope::after {
  content: "\f0f1\f0f1";
}

.fad.fa-coffin::after, .fa-duotone.fa-coffin::after {
  content: "\f6c6\f6c6";
}

.fad.fa-message::after, .fa-duotone.fa-message::after {
  content: "\f27a\f27a";
}

.fad.fa-comment-alt::after, .fa-duotone.fa-comment-alt::after {
  content: "\f27a\f27a";
}

.fad.fa-salad::after, .fa-duotone.fa-salad::after {
  content: "\f81e\f81e";
}

.fad.fa-bowl-salad::after, .fa-duotone.fa-bowl-salad::after {
  content: "\f81e\f81e";
}

.fad.fa-info::after, .fa-duotone.fa-info::after {
  content: "\f129\f129";
}

.fad.fa-robot-astromech::after, .fa-duotone.fa-robot-astromech::after {
  content: "\e2d2\e2d2";
}

.fad.fa-ring-diamond::after, .fa-duotone.fa-ring-diamond::after {
  content: "\e5ab\e5ab";
}

.fad.fa-fondue-pot::after, .fa-duotone.fa-fondue-pot::after {
  content: "\e40d\e40d";
}

.fad.fa-theta::after, .fa-duotone.fa-theta::after {
  content: "\f69e\f69e";
}

.fad.fa-face-hand-peeking::after, .fa-duotone.fa-face-hand-peeking::after {
  content: "\e481\e481";
}

.fad.fa-square-user::after, .fa-duotone.fa-square-user::after {
  content: "\e283\e283";
}

.fad.fa-down-left-and-up-right-to-center::after, .fa-duotone.fa-down-left-and-up-right-to-center::after {
  content: "\f422\f422";
}

.fad.fa-compress-alt::after, .fa-duotone.fa-compress-alt::after {
  content: "\f422\f422";
}

.fad.fa-explosion::after, .fa-duotone.fa-explosion::after {
  content: "\e4e9\e4e9";
}

.fad.fa-file-lines::after, .fa-duotone.fa-file-lines::after {
  content: "\f15c\f15c";
}

.fad.fa-file-alt::after, .fa-duotone.fa-file-alt::after {
  content: "\f15c\f15c";
}

.fad.fa-file-text::after, .fa-duotone.fa-file-text::after {
  content: "\f15c\f15c";
}

.fad.fa-wave-square::after, .fa-duotone.fa-wave-square::after {
  content: "\f83e\f83e";
}

.fad.fa-ring::after, .fa-duotone.fa-ring::after {
  content: "\f70b\f70b";
}

.fad.fa-building-un::after, .fa-duotone.fa-building-un::after {
  content: "\e4d9\e4d9";
}

.fad.fa-dice-three::after, .fa-duotone.fa-dice-three::after {
  content: "\f527\f527";
}

.fad.fa-tire-pressure-warning::after, .fa-duotone.fa-tire-pressure-warning::after {
  content: "\f633\f633";
}

.fad.fa-wifi-fair::after, .fa-duotone.fa-wifi-fair::after {
  content: "\f6ab\f6ab";
}

.fad.fa-wifi-2::after, .fa-duotone.fa-wifi-2::after {
  content: "\f6ab\f6ab";
}

.fad.fa-calendar-days::after, .fa-duotone.fa-calendar-days::after {
  content: "\f073\f073";
}

.fad.fa-calendar-alt::after, .fa-duotone.fa-calendar-alt::after {
  content: "\f073\f073";
}

.fad.fa-mp3-player::after, .fa-duotone.fa-mp3-player::after {
  content: "\f8ce\f8ce";
}

.fad.fa-anchor-circle-check::after, .fa-duotone.fa-anchor-circle-check::after {
  content: "\e4aa\e4aa";
}

.fad.fa-tally-4::after, .fa-duotone.fa-tally-4::after {
  content: "\e297\e297";
}

.fad.fa-rectangle-history::after, .fa-duotone.fa-rectangle-history::after {
  content: "\e4a2\e4a2";
}

.fad.fa-building-circle-arrow-right::after, .fa-duotone.fa-building-circle-arrow-right::after {
  content: "\e4d1\e4d1";
}

.fad.fa-volleyball::after, .fa-duotone.fa-volleyball::after {
  content: "\f45f\f45f";
}

.fad.fa-volleyball-ball::after, .fa-duotone.fa-volleyball-ball::after {
  content: "\f45f\f45f";
}

.fad.fa-sun-haze::after, .fa-duotone.fa-sun-haze::after {
  content: "\f765\f765";
}

.fad.fa-text-size::after, .fa-duotone.fa-text-size::after {
  content: "\f894\f894";
}

.fad.fa-ufo::after, .fa-duotone.fa-ufo::after {
  content: "\e047\e047";
}

.fad.fa-fork::after, .fa-duotone.fa-fork::after {
  content: "\f2e3\f2e3";
}

.fad.fa-utensil-fork::after, .fa-duotone.fa-utensil-fork::after {
  content: "\f2e3\f2e3";
}

.fad.fa-arrows-up-to-line::after, .fa-duotone.fa-arrows-up-to-line::after {
  content: "\e4c2\e4c2";
}

.fad.fa-mobile-signal::after, .fa-duotone.fa-mobile-signal::after {
  content: "\e1ef\e1ef";
}

.fad.fa-barcode-scan::after, .fa-duotone.fa-barcode-scan::after {
  content: "\f465\f465";
}

.fad.fa-sort-down::after, .fa-duotone.fa-sort-down::after {
  content: "\f0dd\f0dd";
}

.fad.fa-sort-desc::after, .fa-duotone.fa-sort-desc::after {
  content: "\f0dd\f0dd";
}

.fad.fa-folder-arrow-down::after, .fa-duotone.fa-folder-arrow-down::after {
  content: "\e053\e053";
}

.fad.fa-folder-download::after, .fa-duotone.fa-folder-download::after {
  content: "\e053\e053";
}

.fad.fa-circle-minus::after, .fa-duotone.fa-circle-minus::after {
  content: "\f056\f056";
}

.fad.fa-minus-circle::after, .fa-duotone.fa-minus-circle::after {
  content: "\f056\f056";
}

.fad.fa-face-icicles::after, .fa-duotone.fa-face-icicles::after {
  content: "\e37c\e37c";
}

.fad.fa-shovel::after, .fa-duotone.fa-shovel::after {
  content: "\f713\f713";
}

.fad.fa-door-open::after, .fa-duotone.fa-door-open::after {
  content: "\f52b\f52b";
}

.fad.fa-films::after, .fa-duotone.fa-films::after {
  content: "\e17a\e17a";
}

.fad.fa-right-from-bracket::after, .fa-duotone.fa-right-from-bracket::after {
  content: "\f2f5\f2f5";
}

.fad.fa-sign-out-alt::after, .fa-duotone.fa-sign-out-alt::after {
  content: "\f2f5\f2f5";
}

.fad.fa-face-glasses::after, .fa-duotone.fa-face-glasses::after {
  content: "\e377\e377";
}

.fad.fa-nfc::after, .fa-duotone.fa-nfc::after {
  content: "\e1f7\e1f7";
}

.fad.fa-atom::after, .fa-duotone.fa-atom::after {
  content: "\f5d2\f5d2";
}

.fad.fa-soap::after, .fa-duotone.fa-soap::after {
  content: "\e06e\e06e";
}

.fad.fa-icons::after, .fa-duotone.fa-icons::after {
  content: "\f86d\f86d";
}

.fad.fa-heart-music-camera-bolt::after, .fa-duotone.fa-heart-music-camera-bolt::after {
  content: "\f86d\f86d";
}

.fad.fa-microphone-lines-slash::after, .fa-duotone.fa-microphone-lines-slash::after {
  content: "\f539\f539";
}

.fad.fa-microphone-alt-slash::after, .fa-duotone.fa-microphone-alt-slash::after {
  content: "\f539\f539";
}

.fad.fa-closed-captioning-slash::after, .fa-duotone.fa-closed-captioning-slash::after {
  content: "\e135\e135";
}

.fad.fa-calculator-simple::after, .fa-duotone.fa-calculator-simple::after {
  content: "\f64c\f64c";
}

.fad.fa-calculator-alt::after, .fa-duotone.fa-calculator-alt::after {
  content: "\f64c\f64c";
}

.fad.fa-bridge-circle-check::after, .fa-duotone.fa-bridge-circle-check::after {
  content: "\e4c9\e4c9";
}

.fad.fa-sliders-up::after, .fa-duotone.fa-sliders-up::after {
  content: "\f3f1\f3f1";
}

.fad.fa-sliders-v::after, .fa-duotone.fa-sliders-v::after {
  content: "\f3f1\f3f1";
}

.fad.fa-location-minus::after, .fa-duotone.fa-location-minus::after {
  content: "\f609\f609";
}

.fad.fa-map-marker-minus::after, .fa-duotone.fa-map-marker-minus::after {
  content: "\f609\f609";
}

.fad.fa-pump-medical::after, .fa-duotone.fa-pump-medical::after {
  content: "\e06a\e06a";
}

.fad.fa-fingerprint::after, .fa-duotone.fa-fingerprint::after {
  content: "\f577\f577";
}

.fad.fa-ski-boot::after, .fa-duotone.fa-ski-boot::after {
  content: "\e3cc\e3cc";
}

.fad.fa-standard-definition::after, .fa-duotone.fa-standard-definition::after {
  content: "\e28a\e28a";
}

.fad.fa-rectangle-sd::after, .fa-duotone.fa-rectangle-sd::after {
  content: "\e28a\e28a";
}

.fad.fa-h1::after, .fa-duotone.fa-h1::after {
  content: "\f313\f313";
}

.fad.fa-hand-point-right::after, .fa-duotone.fa-hand-point-right::after {
  content: "\f0a4\f0a4";
}

.fad.fa-magnifying-glass-location::after, .fa-duotone.fa-magnifying-glass-location::after {
  content: "\f689\f689";
}

.fad.fa-search-location::after, .fa-duotone.fa-search-location::after {
  content: "\f689\f689";
}

.fad.fa-message-bot::after, .fa-duotone.fa-message-bot::after {
  content: "\e3b8\e3b8";
}

.fad.fa-forward-step::after, .fa-duotone.fa-forward-step::after {
  content: "\f051\f051";
}

.fad.fa-step-forward::after, .fa-duotone.fa-step-forward::after {
  content: "\f051\f051";
}

.fad.fa-face-smile-beam::after, .fa-duotone.fa-face-smile-beam::after {
  content: "\f5b8\f5b8";
}

.fad.fa-smile-beam::after, .fa-duotone.fa-smile-beam::after {
  content: "\f5b8\f5b8";
}

.fad.fa-light-ceiling::after, .fa-duotone.fa-light-ceiling::after {
  content: "\e016\e016";
}

.fad.fa-message-exclamation::after, .fa-duotone.fa-message-exclamation::after {
  content: "\f4a5\f4a5";
}

.fad.fa-comment-alt-exclamation::after, .fa-duotone.fa-comment-alt-exclamation::after {
  content: "\f4a5\f4a5";
}

.fad.fa-bowl-scoop::after, .fa-duotone.fa-bowl-scoop::after {
  content: "\e3de\e3de";
}

.fad.fa-bowl-shaved-ice::after, .fa-duotone.fa-bowl-shaved-ice::after {
  content: "\e3de\e3de";
}

.fad.fa-square-x::after, .fa-duotone.fa-square-x::after {
  content: "\e286\e286";
}

.fad.fa-utility-pole-double::after, .fa-duotone.fa-utility-pole-double::after {
  content: "\e2c4\e2c4";
}

.fad.fa-flag-checkered::after, .fa-duotone.fa-flag-checkered::after {
  content: "\f11e\f11e";
}

.fad.fa-chevrons-up::after, .fa-duotone.fa-chevrons-up::after {
  content: "\f325\f325";
}

.fad.fa-chevron-double-up::after, .fa-duotone.fa-chevron-double-up::after {
  content: "\f325\f325";
}

.fad.fa-football::after, .fa-duotone.fa-football::after {
  content: "\f44e\f44e";
}

.fad.fa-football-ball::after, .fa-duotone.fa-football-ball::after {
  content: "\f44e\f44e";
}

.fad.fa-user-vneck::after, .fa-duotone.fa-user-vneck::after {
  content: "\e461\e461";
}

.fad.fa-school-circle-exclamation::after, .fa-duotone.fa-school-circle-exclamation::after {
  content: "\e56c\e56c";
}

.fad.fa-crop::after, .fa-duotone.fa-crop::after {
  content: "\f125\f125";
}

.fad.fa-angles-down::after, .fa-duotone.fa-angles-down::after {
  content: "\f103\f103";
}

.fad.fa-angle-double-down::after, .fa-duotone.fa-angle-double-down::after {
  content: "\f103\f103";
}

.fad.fa-users-rectangle::after, .fa-duotone.fa-users-rectangle::after {
  content: "\e594\e594";
}

.fad.fa-people-roof::after, .fa-duotone.fa-people-roof::after {
  content: "\e537\e537";
}

.fad.fa-square-arrow-right::after, .fa-duotone.fa-square-arrow-right::after {
  content: "\f33b\f33b";
}

.fad.fa-arrow-square-right::after, .fa-duotone.fa-arrow-square-right::after {
  content: "\f33b\f33b";
}

.fad.fa-location-plus::after, .fa-duotone.fa-location-plus::after {
  content: "\f60a\f60a";
}

.fad.fa-map-marker-plus::after, .fa-duotone.fa-map-marker-plus::after {
  content: "\f60a\f60a";
}

.fad.fa-lightbulb-exclamation-on::after, .fa-duotone.fa-lightbulb-exclamation-on::after {
  content: "\e1ca\e1ca";
}

.fad.fa-people-line::after, .fa-duotone.fa-people-line::after {
  content: "\e534\e534";
}

.fad.fa-beer-mug-empty::after, .fa-duotone.fa-beer-mug-empty::after {
  content: "\f0fc\f0fc";
}

.fad.fa-beer::after, .fa-duotone.fa-beer::after {
  content: "\f0fc\f0fc";
}

.fad.fa-crate-empty::after, .fa-duotone.fa-crate-empty::after {
  content: "\e151\e151";
}

.fad.fa-diagram-predecessor::after, .fa-duotone.fa-diagram-predecessor::after {
  content: "\e477\e477";
}

.fad.fa-transporter::after, .fa-duotone.fa-transporter::after {
  content: "\e042\e042";
}

.fad.fa-calendar-circle-user::after, .fa-duotone.fa-calendar-circle-user::after {
  content: "\e471\e471";
}

.fad.fa-arrow-up-long::after, .fa-duotone.fa-arrow-up-long::after {
  content: "\f176\f176";
}

.fad.fa-long-arrow-up::after, .fa-duotone.fa-long-arrow-up::after {
  content: "\f176\f176";
}

.fad.fa-person-carry-box::after, .fa-duotone.fa-person-carry-box::after {
  content: "\f4cf\f4cf";
}

.fad.fa-person-carry::after, .fa-duotone.fa-person-carry::after {
  content: "\f4cf\f4cf";
}

.fad.fa-fire-flame-simple::after, .fa-duotone.fa-fire-flame-simple::after {
  content: "\f46a\f46a";
}

.fad.fa-burn::after, .fa-duotone.fa-burn::after {
  content: "\f46a\f46a";
}

.fad.fa-person::after, .fa-duotone.fa-person::after {
  content: "\f183\f183";
}

.fad.fa-male::after, .fa-duotone.fa-male::after {
  content: "\f183\f183";
}

.fad.fa-laptop::after, .fa-duotone.fa-laptop::after {
  content: "\f109\f109";
}

.fad.fa-file-csv::after, .fa-duotone.fa-file-csv::after {
  content: "\f6dd\f6dd";
}

.fad.fa-menorah::after, .fa-duotone.fa-menorah::after {
  content: "\f676\f676";
}

.fad.fa-union::after, .fa-duotone.fa-union::after {
  content: "\f6a2\f6a2";
}

.fad.fa-chevrons-left::after, .fa-duotone.fa-chevrons-left::after {
  content: "\f323\f323";
}

.fad.fa-chevron-double-left::after, .fa-duotone.fa-chevron-double-left::after {
  content: "\f323\f323";
}

.fad.fa-circle-heart::after, .fa-duotone.fa-circle-heart::after {
  content: "\f4c7\f4c7";
}

.fad.fa-heart-circle::after, .fa-duotone.fa-heart-circle::after {
  content: "\f4c7\f4c7";
}

.fad.fa-truck-plane::after, .fa-duotone.fa-truck-plane::after {
  content: "\e58f\e58f";
}

.fad.fa-record-vinyl::after, .fa-duotone.fa-record-vinyl::after {
  content: "\f8d9\f8d9";
}

.fad.fa-bring-forward::after, .fa-duotone.fa-bring-forward::after {
  content: "\f856\f856";
}

.fad.fa-square-p::after, .fa-duotone.fa-square-p::after {
  content: "\e279\e279";
}

.fad.fa-face-grin-stars::after, .fa-duotone.fa-face-grin-stars::after {
  content: "\f587\f587";
}

.fad.fa-grin-stars::after, .fa-duotone.fa-grin-stars::after {
  content: "\f587\f587";
}

.fad.fa-sigma::after, .fa-duotone.fa-sigma::after {
  content: "\f68b\f68b";
}

.fad.fa-camera-movie::after, .fa-duotone.fa-camera-movie::after {
  content: "\f8a9\f8a9";
}

.fad.fa-bong::after, .fa-duotone.fa-bong::after {
  content: "\f55c\f55c";
}

.fad.fa-clarinet::after, .fa-duotone.fa-clarinet::after {
  content: "\f8ad\f8ad";
}

.fad.fa-truck-flatbed::after, .fa-duotone.fa-truck-flatbed::after {
  content: "\e2b6\e2b6";
}

.fad.fa-spaghetti-monster-flying::after, .fa-duotone.fa-spaghetti-monster-flying::after {
  content: "\f67b\f67b";
}

.fad.fa-pastafarianism::after, .fa-duotone.fa-pastafarianism::after {
  content: "\f67b\f67b";
}

.fad.fa-arrow-down-up-across-line::after, .fa-duotone.fa-arrow-down-up-across-line::after {
  content: "\e4af\e4af";
}

.fad.fa-leaf-heart::after, .fa-duotone.fa-leaf-heart::after {
  content: "\f4cb\f4cb";
}

.fad.fa-house-building::after, .fa-duotone.fa-house-building::after {
  content: "\e1b1\e1b1";
}

.fad.fa-cheese-swiss::after, .fa-duotone.fa-cheese-swiss::after {
  content: "\f7f0\f7f0";
}

.fad.fa-spoon::after, .fa-duotone.fa-spoon::after {
  content: "\f2e5\f2e5";
}

.fad.fa-utensil-spoon::after, .fa-duotone.fa-utensil-spoon::after {
  content: "\f2e5\f2e5";
}

.fad.fa-jar-wheat::after, .fa-duotone.fa-jar-wheat::after {
  content: "\e517\e517";
}

.fad.fa-envelopes-bulk::after, .fa-duotone.fa-envelopes-bulk::after {
  content: "\f674\f674";
}

.fad.fa-mail-bulk::after, .fa-duotone.fa-mail-bulk::after {
  content: "\f674\f674";
}

.fad.fa-file-circle-exclamation::after, .fa-duotone.fa-file-circle-exclamation::after {
  content: "\e4eb\e4eb";
}

.fad.fa-bow-arrow::after, .fa-duotone.fa-bow-arrow::after {
  content: "\f6b9\f6b9";
}

.fad.fa-cart-xmark::after, .fa-duotone.fa-cart-xmark::after {
  content: "\e0dd\e0dd";
}

.fad.fa-hexagon-xmark::after, .fa-duotone.fa-hexagon-xmark::after {
  content: "\f2ee\f2ee";
}

.fad.fa-times-hexagon::after, .fa-duotone.fa-times-hexagon::after {
  content: "\f2ee\f2ee";
}

.fad.fa-xmark-hexagon::after, .fa-duotone.fa-xmark-hexagon::after {
  content: "\f2ee\f2ee";
}

.fad.fa-circle-h::after, .fa-duotone.fa-circle-h::after {
  content: "\f47e\f47e";
}

.fad.fa-hospital-symbol::after, .fa-duotone.fa-hospital-symbol::after {
  content: "\f47e\f47e";
}

.fad.fa-merge::after, .fa-duotone.fa-merge::after {
  content: "\e526\e526";
}

.fad.fa-pager::after, .fa-duotone.fa-pager::after {
  content: "\f815\f815";
}

.fad.fa-cart-minus::after, .fa-duotone.fa-cart-minus::after {
  content: "\e0db\e0db";
}

.fad.fa-address-book::after, .fa-duotone.fa-address-book::after {
  content: "\f2b9\f2b9";
}

.fad.fa-contact-book::after, .fa-duotone.fa-contact-book::after {
  content: "\f2b9\f2b9";
}

.fad.fa-pan-frying::after, .fa-duotone.fa-pan-frying::after {
  content: "\e42c\e42c";
}

.fad.fa-grid::after, .fa-duotone.fa-grid::after {
  content: "\e195\e195";
}

.fad.fa-grid-3::after, .fa-duotone.fa-grid-3::after {
  content: "\e195\e195";
}

.fad.fa-football-helmet::after, .fa-duotone.fa-football-helmet::after {
  content: "\f44f\f44f";
}

.fad.fa-hand-love::after, .fa-duotone.fa-hand-love::after {
  content: "\e1a5\e1a5";
}

.fad.fa-trees::after, .fa-duotone.fa-trees::after {
  content: "\f724\f724";
}

.fad.fa-strikethrough::after, .fa-duotone.fa-strikethrough::after {
  content: "\f0cc\f0cc";
}

.fad.fa-page::after, .fa-duotone.fa-page::after {
  content: "\e428\e428";
}

.fad.fa-k::after, .fa-duotone.fa-k::after {
  content: "KK";
}

.fad.fa-diagram-previous::after, .fa-duotone.fa-diagram-previous::after {
  content: "\e478\e478";
}

.fad.fa-gauge-min::after, .fa-duotone.fa-gauge-min::after {
  content: "\f628\f628";
}

.fad.fa-tachometer-alt-slowest::after, .fa-duotone.fa-tachometer-alt-slowest::after {
  content: "\f628\f628";
}

.fad.fa-folder-grid::after, .fa-duotone.fa-folder-grid::after {
  content: "\e188\e188";
}

.fad.fa-eggplant::after, .fa-duotone.fa-eggplant::after {
  content: "\e16c\e16c";
}

.fad.fa-ram::after, .fa-duotone.fa-ram::after {
  content: "\f70a\f70a";
}

.fad.fa-landmark-flag::after, .fa-duotone.fa-landmark-flag::after {
  content: "\e51c\e51c";
}

.fad.fa-lips::after, .fa-duotone.fa-lips::after {
  content: "\f600\f600";
}

.fad.fa-pencil::after, .fa-duotone.fa-pencil::after {
  content: "\f303\f303";
}

.fad.fa-pencil-alt::after, .fa-duotone.fa-pencil-alt::after {
  content: "\f303\f303";
}

.fad.fa-backward::after, .fa-duotone.fa-backward::after {
  content: "\f04a\f04a";
}

.fad.fa-caret-right::after, .fa-duotone.fa-caret-right::after {
  content: "\f0da\f0da";
}

.fad.fa-comments::after, .fa-duotone.fa-comments::after {
  content: "\f086\f086";
}

.fad.fa-paste::after, .fa-duotone.fa-paste::after {
  content: "\f0ea\f0ea";
}

.fad.fa-file-clipboard::after, .fa-duotone.fa-file-clipboard::after {
  content: "\f0ea\f0ea";
}

.fad.fa-desktop-arrow-down::after, .fa-duotone.fa-desktop-arrow-down::after {
  content: "\e155\e155";
}

.fad.fa-code-pull-request::after, .fa-duotone.fa-code-pull-request::after {
  content: "\e13c\e13c";
}

.fad.fa-pumpkin::after, .fa-duotone.fa-pumpkin::after {
  content: "\f707\f707";
}

.fad.fa-clipboard-list::after, .fa-duotone.fa-clipboard-list::after {
  content: "\f46d\f46d";
}

.fad.fa-pen-field::after, .fa-duotone.fa-pen-field::after {
  content: "\e211\e211";
}

.fad.fa-blueberries::after, .fa-duotone.fa-blueberries::after {
  content: "\e2e8\e2e8";
}

.fad.fa-truck-ramp-box::after, .fa-duotone.fa-truck-ramp-box::after {
  content: "\f4de\f4de";
}

.fad.fa-truck-loading::after, .fa-duotone.fa-truck-loading::after {
  content: "\f4de\f4de";
}

.fad.fa-note::after, .fa-duotone.fa-note::after {
  content: "\e1ff\e1ff";
}

.fad.fa-arrow-down-to-square::after, .fa-duotone.fa-arrow-down-to-square::after {
  content: "\e096\e096";
}

.fad.fa-user-check::after, .fa-duotone.fa-user-check::after {
  content: "\f4fc\f4fc";
}

.fad.fa-cloud-xmark::after, .fa-duotone.fa-cloud-xmark::after {
  content: "\e35f\e35f";
}

.fad.fa-vial-virus::after, .fa-duotone.fa-vial-virus::after {
  content: "\e597\e597";
}

.fad.fa-book-blank::after, .fa-duotone.fa-book-blank::after {
  content: "\f5d9\f5d9";
}

.fad.fa-book-alt::after, .fa-duotone.fa-book-alt::after {
  content: "\f5d9\f5d9";
}

.fad.fa-golf-flag-hole::after, .fa-duotone.fa-golf-flag-hole::after {
  content: "\e3ac\e3ac";
}

.fad.fa-message-arrow-down::after, .fa-duotone.fa-message-arrow-down::after {
  content: "\e1db\e1db";
}

.fad.fa-comment-alt-arrow-down::after, .fa-duotone.fa-comment-alt-arrow-down::after {
  content: "\e1db\e1db";
}

.fad.fa-face-unamused::after, .fa-duotone.fa-face-unamused::after {
  content: "\e39f\e39f";
}

.fad.fa-sheet-plastic::after, .fa-duotone.fa-sheet-plastic::after {
  content: "\e571\e571";
}

.fad.fa-circle-9::after, .fa-duotone.fa-circle-9::after {
  content: "\e0f6\e0f6";
}

.fad.fa-blog::after, .fa-duotone.fa-blog::after {
  content: "\f781\f781";
}

.fad.fa-user-ninja::after, .fa-duotone.fa-user-ninja::after {
  content: "\f504\f504";
}

.fad.fa-pencil-slash::after, .fa-duotone.fa-pencil-slash::after {
  content: "\e215\e215";
}

.fad.fa-bowling-pins::after, .fa-duotone.fa-bowling-pins::after {
  content: "\f437\f437";
}

.fad.fa-person-arrow-up-from-line::after, .fa-duotone.fa-person-arrow-up-from-line::after {
  content: "\e539\e539";
}

.fad.fa-down-right::after, .fa-duotone.fa-down-right::after {
  content: "\e16b\e16b";
}

.fad.fa-scroll-torah::after, .fa-duotone.fa-scroll-torah::after {
  content: "\f6a0\f6a0";
}

.fad.fa-torah::after, .fa-duotone.fa-torah::after {
  content: "\f6a0\f6a0";
}

.fad.fa-blinds-open::after, .fa-duotone.fa-blinds-open::after {
  content: "\f8fc\f8fc";
}

.fad.fa-fence::after, .fa-duotone.fa-fence::after {
  content: "\e303\e303";
}

.fad.fa-up::after, .fa-duotone.fa-up::after {
  content: "\f357\f357";
}

.fad.fa-arrow-alt-up::after, .fa-duotone.fa-arrow-alt-up::after {
  content: "\f357\f357";
}

.fad.fa-broom-ball::after, .fa-duotone.fa-broom-ball::after {
  content: "\f458\f458";
}

.fad.fa-quidditch::after, .fa-duotone.fa-quidditch::after {
  content: "\f458\f458";
}

.fad.fa-quidditch-broom-ball::after, .fa-duotone.fa-quidditch-broom-ball::after {
  content: "\f458\f458";
}

.fad.fa-drumstick::after, .fa-duotone.fa-drumstick::after {
  content: "\f6d6\f6d6";
}

.fad.fa-square-v::after, .fa-duotone.fa-square-v::after {
  content: "\e284\e284";
}

.fad.fa-face-awesome::after, .fa-duotone.fa-face-awesome::after {
  content: "\e409\e409";
}

.fad.fa-gave-dandy::after, .fa-duotone.fa-gave-dandy::after {
  content: "\e409\e409";
}

.fad.fa-dial-off::after, .fa-duotone.fa-dial-off::after {
  content: "\e162\e162";
}

.fad.fa-toggle-off::after, .fa-duotone.fa-toggle-off::after {
  content: "\f204\f204";
}

.fad.fa-face-smile-horns::after, .fa-duotone.fa-face-smile-horns::after {
  content: "\e391\e391";
}

.fad.fa-box-archive::after, .fa-duotone.fa-box-archive::after {
  content: "\f187\f187";
}

.fad.fa-archive::after, .fa-duotone.fa-archive::after {
  content: "\f187\f187";
}

.fad.fa-grapes::after, .fa-duotone.fa-grapes::after {
  content: "\e306\e306";
}

.fad.fa-person-drowning::after, .fa-duotone.fa-person-drowning::after {
  content: "\e545\e545";
}

.fad.fa-dial-max::after, .fa-duotone.fa-dial-max::after {
  content: "\e15e\e15e";
}

.fad.fa-circle-m::after, .fa-duotone.fa-circle-m::after {
  content: "\e115\e115";
}

.fad.fa-calendar-image::after, .fa-duotone.fa-calendar-image::after {
  content: "\e0d4\e0d4";
}

.fad.fa-circle-caret-down::after, .fa-duotone.fa-circle-caret-down::after {
  content: "\f32d\f32d";
}

.fad.fa-caret-circle-down::after, .fa-duotone.fa-caret-circle-down::after {
  content: "\f32d\f32d";
}

.fad.fa-arrow-down-9-1::after, .fa-duotone.fa-arrow-down-9-1::after {
  content: "\f886\f886";
}

.fad.fa-sort-numeric-desc::after, .fa-duotone.fa-sort-numeric-desc::after {
  content: "\f886\f886";
}

.fad.fa-sort-numeric-down-alt::after, .fa-duotone.fa-sort-numeric-down-alt::after {
  content: "\f886\f886";
}

.fad.fa-face-grin-tongue-squint::after, .fa-duotone.fa-face-grin-tongue-squint::after {
  content: "\f58a\f58a";
}

.fad.fa-grin-tongue-squint::after, .fa-duotone.fa-grin-tongue-squint::after {
  content: "\f58a\f58a";
}

.fad.fa-shish-kebab::after, .fa-duotone.fa-shish-kebab::after {
  content: "\f821\f821";
}

.fad.fa-spray-can::after, .fa-duotone.fa-spray-can::after {
  content: "\f5bd\f5bd";
}

.fad.fa-alarm-snooze::after, .fa-duotone.fa-alarm-snooze::after {
  content: "\f845\f845";
}

.fad.fa-scarecrow::after, .fa-duotone.fa-scarecrow::after {
  content: "\f70d\f70d";
}

.fad.fa-truck-monster::after, .fa-duotone.fa-truck-monster::after {
  content: "\f63b\f63b";
}

.fad.fa-gift-card::after, .fa-duotone.fa-gift-card::after {
  content: "\f663\f663";
}

.fad.fa-w::after, .fa-duotone.fa-w::after {
  content: "WW";
}

.fad.fa-code-pull-request-draft::after, .fa-duotone.fa-code-pull-request-draft::after {
  content: "\e3fa\e3fa";
}

.fad.fa-square-b::after, .fa-duotone.fa-square-b::after {
  content: "\e264\e264";
}

.fad.fa-elephant::after, .fa-duotone.fa-elephant::after {
  content: "\f6da\f6da";
}

.fad.fa-earth-africa::after, .fa-duotone.fa-earth-africa::after {
  content: "\f57c\f57c";
}

.fad.fa-globe-africa::after, .fa-duotone.fa-globe-africa::after {
  content: "\f57c\f57c";
}

.fad.fa-rainbow::after, .fa-duotone.fa-rainbow::after {
  content: "\f75b\f75b";
}

.fad.fa-circle-notch::after, .fa-duotone.fa-circle-notch::after {
  content: "\f1ce\f1ce";
}

.fad.fa-tablet-screen-button::after, .fa-duotone.fa-tablet-screen-button::after {
  content: "\f3fa\f3fa";
}

.fad.fa-tablet-alt::after, .fa-duotone.fa-tablet-alt::after {
  content: "\f3fa\f3fa";
}

.fad.fa-paw::after, .fa-duotone.fa-paw::after {
  content: "\f1b0\f1b0";
}

.fad.fa-message-question::after, .fa-duotone.fa-message-question::after {
  content: "\e1e3\e1e3";
}

.fad.fa-cloud::after, .fa-duotone.fa-cloud::after {
  content: "\f0c2\f0c2";
}

.fad.fa-trowel-bricks::after, .fa-duotone.fa-trowel-bricks::after {
  content: "\e58a\e58a";
}

.fad.fa-square-3::after, .fa-duotone.fa-square-3::after {
  content: "\e258\e258";
}

.fad.fa-face-flushed::after, .fa-duotone.fa-face-flushed::after {
  content: "\f579\f579";
}

.fad.fa-flushed::after, .fa-duotone.fa-flushed::after {
  content: "\f579\f579";
}

.fad.fa-hospital-user::after, .fa-duotone.fa-hospital-user::after {
  content: "\f80d\f80d";
}

.fad.fa-microwave::after, .fa-duotone.fa-microwave::after {
  content: "\e01b\e01b";
}

.fad.fa-tent-arrow-left-right::after, .fa-duotone.fa-tent-arrow-left-right::after {
  content: "\e57f\e57f";
}

.fad.fa-cart-circle-arrow-up::after, .fa-duotone.fa-cart-circle-arrow-up::after {
  content: "\e3f0\e3f0";
}

.fad.fa-trash-clock::after, .fa-duotone.fa-trash-clock::after {
  content: "\e2b0\e2b0";
}

.fad.fa-gavel::after, .fa-duotone.fa-gavel::after {
  content: "\f0e3\f0e3";
}

.fad.fa-legal::after, .fa-duotone.fa-legal::after {
  content: "\f0e3\f0e3";
}

.fad.fa-sprinkler-ceiling::after, .fa-duotone.fa-sprinkler-ceiling::after {
  content: "\e44c\e44c";
}

.fad.fa-browsers::after, .fa-duotone.fa-browsers::after {
  content: "\e0cb\e0cb";
}

.fad.fa-trillium::after, .fa-duotone.fa-trillium::after {
  content: "\e588\e588";
}

.fad.fa-music-slash::after, .fa-duotone.fa-music-slash::after {
  content: "\f8d1\f8d1";
}

.fad.fa-truck-ramp::after, .fa-duotone.fa-truck-ramp::after {
  content: "\f4e0\f4e0";
}

.fad.fa-binoculars::after, .fa-duotone.fa-binoculars::after {
  content: "\f1e5\f1e5";
}

.fad.fa-microphone-slash::after, .fa-duotone.fa-microphone-slash::after {
  content: "\f131\f131";
}

.fad.fa-box-tissue::after, .fa-duotone.fa-box-tissue::after {
  content: "\e05b\e05b";
}

.fad.fa-circle-c::after, .fa-duotone.fa-circle-c::after {
  content: "\e101\e101";
}

.fad.fa-star-christmas::after, .fa-duotone.fa-star-christmas::after {
  content: "\f7d4\f7d4";
}

.fad.fa-chart-bullet::after, .fa-duotone.fa-chart-bullet::after {
  content: "\e0e1\e0e1";
}

.fad.fa-motorcycle::after, .fa-duotone.fa-motorcycle::after {
  content: "\f21c\f21c";
}

.fad.fa-tree-christmas::after, .fa-duotone.fa-tree-christmas::after {
  content: "\f7db\f7db";
}

.fad.fa-tire-flat::after, .fa-duotone.fa-tire-flat::after {
  content: "\f632\f632";
}

.fad.fa-sunglasses::after, .fa-duotone.fa-sunglasses::after {
  content: "\f892\f892";
}

.fad.fa-badge::after, .fa-duotone.fa-badge::after {
  content: "\f335\f335";
}

.fad.fa-message-pen::after, .fa-duotone.fa-message-pen::after {
  content: "\f4a4\f4a4";
}

.fad.fa-comment-alt-edit::after, .fa-duotone.fa-comment-alt-edit::after {
  content: "\f4a4\f4a4";
}

.fad.fa-message-edit::after, .fa-duotone.fa-message-edit::after {
  content: "\f4a4\f4a4";
}

.fad.fa-bell-concierge::after, .fa-duotone.fa-bell-concierge::after {
  content: "\f562\f562";
}

.fad.fa-concierge-bell::after, .fa-duotone.fa-concierge-bell::after {
  content: "\f562\f562";
}

.fad.fa-pen-ruler::after, .fa-duotone.fa-pen-ruler::after {
  content: "\f5ae\f5ae";
}

.fad.fa-pencil-ruler::after, .fa-duotone.fa-pencil-ruler::after {
  content: "\f5ae\f5ae";
}

.fad.fa-chess-rook-piece::after, .fa-duotone.fa-chess-rook-piece::after {
  content: "\f448\f448";
}

.fad.fa-chess-rook-alt::after, .fa-duotone.fa-chess-rook-alt::after {
  content: "\f448\f448";
}

.fad.fa-square-root::after, .fa-duotone.fa-square-root::after {
  content: "\f697\f697";
}

.fad.fa-album-collection-circle-plus::after, .fa-duotone.fa-album-collection-circle-plus::after {
  content: "\e48e\e48e";
}

.fad.fa-people-arrows::after, .fa-duotone.fa-people-arrows::after {
  content: "\e068\e068";
}

.fad.fa-people-arrows-left-right::after, .fa-duotone.fa-people-arrows-left-right::after {
  content: "\e068\e068";
}

.fad.fa-face-angry-horns::after, .fa-duotone.fa-face-angry-horns::after {
  content: "\e368\e368";
}

.fad.fa-mars-and-venus-burst::after, .fa-duotone.fa-mars-and-venus-burst::after {
  content: "\e523\e523";
}

.fad.fa-tombstone::after, .fa-duotone.fa-tombstone::after {
  content: "\f720\f720";
}

.fad.fa-square-caret-right::after, .fa-duotone.fa-square-caret-right::after {
  content: "\f152\f152";
}

.fad.fa-caret-square-right::after, .fa-duotone.fa-caret-square-right::after {
  content: "\f152\f152";
}

.fad.fa-scissors::after, .fa-duotone.fa-scissors::after {
  content: "\f0c4\f0c4";
}

.fad.fa-cut::after, .fa-duotone.fa-cut::after {
  content: "\f0c4\f0c4";
}

.fad.fa-list-music::after, .fa-duotone.fa-list-music::after {
  content: "\f8c9\f8c9";
}

.fad.fa-sun-plant-wilt::after, .fa-duotone.fa-sun-plant-wilt::after {
  content: "\e57a\e57a";
}

.fad.fa-toilets-portable::after, .fa-duotone.fa-toilets-portable::after {
  content: "\e584\e584";
}

.fad.fa-hockey-puck::after, .fa-duotone.fa-hockey-puck::after {
  content: "\f453\f453";
}

.fad.fa-hyphen::after, .fa-duotone.fa-hyphen::after {
  content: "--";
}

.fad.fa-table::after, .fa-duotone.fa-table::after {
  content: "\f0ce\f0ce";
}

.fad.fa-user-chef::after, .fa-duotone.fa-user-chef::after {
  content: "\e3d2\e3d2";
}

.fad.fa-message-image::after, .fa-duotone.fa-message-image::after {
  content: "\e1e0\e1e0";
}

.fad.fa-comment-alt-image::after, .fa-duotone.fa-comment-alt-image::after {
  content: "\e1e0\e1e0";
}

.fad.fa-users-medical::after, .fa-duotone.fa-users-medical::after {
  content: "\f830\f830";
}

.fad.fa-sensor-triangle-exclamation::after, .fa-duotone.fa-sensor-triangle-exclamation::after {
  content: "\e029\e029";
}

.fad.fa-sensor-alert::after, .fa-duotone.fa-sensor-alert::after {
  content: "\e029\e029";
}

.fad.fa-magnifying-glass-arrow-right::after, .fa-duotone.fa-magnifying-glass-arrow-right::after {
  content: "\e521\e521";
}

.fad.fa-tachograph-digital::after, .fa-duotone.fa-tachograph-digital::after {
  content: "\f566\f566";
}

.fad.fa-digital-tachograph::after, .fa-duotone.fa-digital-tachograph::after {
  content: "\f566\f566";
}

.fad.fa-face-mask::after, .fa-duotone.fa-face-mask::after {
  content: "\e37f\e37f";
}

.fad.fa-pickleball::after, .fa-duotone.fa-pickleball::after {
  content: "\e435\e435";
}

.fad.fa-star-sharp-half::after, .fa-duotone.fa-star-sharp-half::after {
  content: "\e28c\e28c";
}

.fad.fa-users-slash::after, .fa-duotone.fa-users-slash::after {
  content: "\e073\e073";
}

.fad.fa-clover::after, .fa-duotone.fa-clover::after {
  content: "\e139\e139";
}

.fad.fa-meat::after, .fa-duotone.fa-meat::after {
  content: "\f814\f814";
}

.fad.fa-reply::after, .fa-duotone.fa-reply::after {
  content: "\f3e5\f3e5";
}

.fad.fa-mail-reply::after, .fa-duotone.fa-mail-reply::after {
  content: "\f3e5\f3e5";
}

.fad.fa-star-and-crescent::after, .fa-duotone.fa-star-and-crescent::after {
  content: "\f699\f699";
}

.fad.fa-empty-set::after, .fa-duotone.fa-empty-set::after {
  content: "\f656\f656";
}

.fad.fa-house-fire::after, .fa-duotone.fa-house-fire::after {
  content: "\e50c\e50c";
}

.fad.fa-square-minus::after, .fa-duotone.fa-square-minus::after {
  content: "\f146\f146";
}

.fad.fa-minus-square::after, .fa-duotone.fa-minus-square::after {
  content: "\f146\f146";
}

.fad.fa-helicopter::after, .fa-duotone.fa-helicopter::after {
  content: "\f533\f533";
}

.fad.fa-bird::after, .fa-duotone.fa-bird::after {
  content: "\e469\e469";
}

.fad.fa-compass::after, .fa-duotone.fa-compass::after {
  content: "\f14e\f14e";
}

.fad.fa-square-caret-down::after, .fa-duotone.fa-square-caret-down::after {
  content: "\f150\f150";
}

.fad.fa-caret-square-down::after, .fa-duotone.fa-caret-square-down::after {
  content: "\f150\f150";
}

.fad.fa-heart-half-stroke::after, .fa-duotone.fa-heart-half-stroke::after {
  content: "\e1ac\e1ac";
}

.fad.fa-heart-half-alt::after, .fa-duotone.fa-heart-half-alt::after {
  content: "\e1ac\e1ac";
}

.fad.fa-file-circle-question::after, .fa-duotone.fa-file-circle-question::after {
  content: "\e4ef\e4ef";
}

.fad.fa-laptop-code::after, .fa-duotone.fa-laptop-code::after {
  content: "\f5fc\f5fc";
}

.fad.fa-joystick::after, .fa-duotone.fa-joystick::after {
  content: "\f8c5\f8c5";
}

.fad.fa-grill-fire::after, .fa-duotone.fa-grill-fire::after {
  content: "\e5a4\e5a4";
}

.fad.fa-rectangle-vertical-history::after, .fa-duotone.fa-rectangle-vertical-history::after {
  content: "\e237\e237";
}

.fad.fa-swatchbook::after, .fa-duotone.fa-swatchbook::after {
  content: "\f5c3\f5c3";
}

.fad.fa-prescription-bottle::after, .fa-duotone.fa-prescription-bottle::after {
  content: "\f485\f485";
}

.fad.fa-bars::after, .fa-duotone.fa-bars::after {
  content: "\f0c9\f0c9";
}

.fad.fa-navicon::after, .fa-duotone.fa-navicon::after {
  content: "\f0c9\f0c9";
}

.fad.fa-keyboard-left::after, .fa-duotone.fa-keyboard-left::after {
  content: "\e1c3\e1c3";
}

.fad.fa-people-group::after, .fa-duotone.fa-people-group::after {
  content: "\e533\e533";
}

.fad.fa-hourglass-end::after, .fa-duotone.fa-hourglass-end::after {
  content: "\f253\f253";
}

.fad.fa-hourglass-3::after, .fa-duotone.fa-hourglass-3::after {
  content: "\f253\f253";
}

.fad.fa-heart-crack::after, .fa-duotone.fa-heart-crack::after {
  content: "\f7a9\f7a9";
}

.fad.fa-heart-broken::after, .fa-duotone.fa-heart-broken::after {
  content: "\f7a9\f7a9";
}

.fad.fa-face-beam-hand-over-mouth::after, .fa-duotone.fa-face-beam-hand-over-mouth::after {
  content: "\e47c\e47c";
}

.fad.fa-droplet-percent::after, .fa-duotone.fa-droplet-percent::after {
  content: "\f750\f750";
}

.fad.fa-humidity::after, .fa-duotone.fa-humidity::after {
  content: "\f750\f750";
}

.fad.fa-square-up-right::after, .fa-duotone.fa-square-up-right::after {
  content: "\f360\f360";
}

.fad.fa-external-link-square-alt::after, .fa-duotone.fa-external-link-square-alt::after {
  content: "\f360\f360";
}

.fad.fa-face-kiss-beam::after, .fa-duotone.fa-face-kiss-beam::after {
  content: "\f597\f597";
}

.fad.fa-kiss-beam::after, .fa-duotone.fa-kiss-beam::after {
  content: "\f597\f597";
}

.fad.fa-corn::after, .fa-duotone.fa-corn::after {
  content: "\f6c7\f6c7";
}

.fad.fa-roller-coaster::after, .fa-duotone.fa-roller-coaster::after {
  content: "\e324\e324";
}

.fad.fa-photo-film-music::after, .fa-duotone.fa-photo-film-music::after {
  content: "\e228\e228";
}

.fad.fa-radar::after, .fa-duotone.fa-radar::after {
  content: "\e024\e024";
}

.fad.fa-sickle::after, .fa-duotone.fa-sickle::after {
  content: "\f822\f822";
}

.fad.fa-film::after, .fa-duotone.fa-film::after {
  content: "\f008\f008";
}

.fad.fa-coconut::after, .fa-duotone.fa-coconut::after {
  content: "\e2f6\e2f6";
}

.fad.fa-ruler-horizontal::after, .fa-duotone.fa-ruler-horizontal::after {
  content: "\f547\f547";
}

.fad.fa-shield-cross::after, .fa-duotone.fa-shield-cross::after {
  content: "\f712\f712";
}

.fad.fa-cassette-tape::after, .fa-duotone.fa-cassette-tape::after {
  content: "\f8ab\f8ab";
}

.fad.fa-square-terminal::after, .fa-duotone.fa-square-terminal::after {
  content: "\e32a\e32a";
}

.fad.fa-people-robbery::after, .fa-duotone.fa-people-robbery::after {
  content: "\e536\e536";
}

.fad.fa-lightbulb::after, .fa-duotone.fa-lightbulb::after {
  content: "\f0eb\f0eb";
}

.fad.fa-caret-left::after, .fa-duotone.fa-caret-left::after {
  content: "\f0d9\f0d9";
}

.fad.fa-comment-middle::after, .fa-duotone.fa-comment-middle::after {
  content: "\e149\e149";
}

.fad.fa-trash-can-list::after, .fa-duotone.fa-trash-can-list::after {
  content: "\e2ab\e2ab";
}

.fad.fa-block::after, .fa-duotone.fa-block::after {
  content: "\e46a\e46a";
}

.fad.fa-circle-exclamation::after, .fa-duotone.fa-circle-exclamation::after {
  content: "\f06a\f06a";
}

.fad.fa-exclamation-circle::after, .fa-duotone.fa-exclamation-circle::after {
  content: "\f06a\f06a";
}

.fad.fa-school-circle-xmark::after, .fa-duotone.fa-school-circle-xmark::after {
  content: "\e56d\e56d";
}

.fad.fa-arrow-right-from-bracket::after, .fa-duotone.fa-arrow-right-from-bracket::after {
  content: "\f08b\f08b";
}

.fad.fa-sign-out::after, .fa-duotone.fa-sign-out::after {
  content: "\f08b\f08b";
}

.fad.fa-face-frown-slight::after, .fa-duotone.fa-face-frown-slight::after {
  content: "\e376\e376";
}

.fad.fa-circle-chevron-down::after, .fa-duotone.fa-circle-chevron-down::after {
  content: "\f13a\f13a";
}

.fad.fa-chevron-circle-down::after, .fa-duotone.fa-chevron-circle-down::after {
  content: "\f13a\f13a";
}

.fad.fa-sidebar-flip::after, .fa-duotone.fa-sidebar-flip::after {
  content: "\e24f\e24f";
}

.fad.fa-unlock-keyhole::after, .fa-duotone.fa-unlock-keyhole::after {
  content: "\f13e\f13e";
}

.fad.fa-unlock-alt::after, .fa-duotone.fa-unlock-alt::after {
  content: "\f13e\f13e";
}

.fad.fa-temperature-list::after, .fa-duotone.fa-temperature-list::after {
  content: "\e299\e299";
}

.fad.fa-cloud-showers-heavy::after, .fa-duotone.fa-cloud-showers-heavy::after {
  content: "\f740\f740";
}

.fad.fa-headphones-simple::after, .fa-duotone.fa-headphones-simple::after {
  content: "\f58f\f58f";
}

.fad.fa-headphones-alt::after, .fa-duotone.fa-headphones-alt::after {
  content: "\f58f\f58f";
}

.fad.fa-sitemap::after, .fa-duotone.fa-sitemap::after {
  content: "\f0e8\f0e8";
}

.fad.fa-pipe-section::after, .fa-duotone.fa-pipe-section::after {
  content: "\e438\e438";
}

.fad.fa-space-station-moon-construction::after, .fa-duotone.fa-space-station-moon-construction::after {
  content: "\e034\e034";
}

.fad.fa-space-station-moon-alt::after, .fa-duotone.fa-space-station-moon-alt::after {
  content: "\e034\e034";
}

.fad.fa-circle-dollar-to-slot::after, .fa-duotone.fa-circle-dollar-to-slot::after {
  content: "\f4b9\f4b9";
}

.fad.fa-donate::after, .fa-duotone.fa-donate::after {
  content: "\f4b9\f4b9";
}

.fad.fa-memory::after, .fa-duotone.fa-memory::after {
  content: "\f538\f538";
}

.fad.fa-face-sleeping::after, .fa-duotone.fa-face-sleeping::after {
  content: "\e38d\e38d";
}

.fad.fa-road-spikes::after, .fa-duotone.fa-road-spikes::after {
  content: "\e568\e568";
}

.fad.fa-fire-burner::after, .fa-duotone.fa-fire-burner::after {
  content: "\e4f1\e4f1";
}

.fad.fa-squirrel::after, .fa-duotone.fa-squirrel::after {
  content: "\f71a\f71a";
}

.fad.fa-arrow-up-to-line::after, .fa-duotone.fa-arrow-up-to-line::after {
  content: "\f341\f341";
}

.fad.fa-arrow-to-top::after, .fa-duotone.fa-arrow-to-top::after {
  content: "\f341\f341";
}

.fad.fa-flag::after, .fa-duotone.fa-flag::after {
  content: "\f024\f024";
}

.fad.fa-face-cowboy-hat::after, .fa-duotone.fa-face-cowboy-hat::after {
  content: "\e36e\e36e";
}

.fad.fa-hanukiah::after, .fa-duotone.fa-hanukiah::after {
  content: "\f6e6\f6e6";
}

.fad.fa-chart-scatter-3d::after, .fa-duotone.fa-chart-scatter-3d::after {
  content: "\e0e8\e0e8";
}

.fad.fa-square-code::after, .fa-duotone.fa-square-code::after {
  content: "\e267\e267";
}

.fad.fa-feather::after, .fa-duotone.fa-feather::after {
  content: "\f52d\f52d";
}

.fad.fa-volume-low::after, .fa-duotone.fa-volume-low::after {
  content: "\f027\f027";
}

.fad.fa-volume-down::after, .fa-duotone.fa-volume-down::after {
  content: "\f027\f027";
}

.fad.fa-xmark-to-slot::after, .fa-duotone.fa-xmark-to-slot::after {
  content: "\f771\f771";
}

.fad.fa-times-to-slot::after, .fa-duotone.fa-times-to-slot::after {
  content: "\f771\f771";
}

.fad.fa-vote-nay::after, .fa-duotone.fa-vote-nay::after {
  content: "\f771\f771";
}

.fad.fa-box-taped::after, .fa-duotone.fa-box-taped::after {
  content: "\f49a\f49a";
}

.fad.fa-box-alt::after, .fa-duotone.fa-box-alt::after {
  content: "\f49a\f49a";
}

.fad.fa-comment-slash::after, .fa-duotone.fa-comment-slash::after {
  content: "\f4b3\f4b3";
}

.fad.fa-swords::after, .fa-duotone.fa-swords::after {
  content: "\f71d\f71d";
}

.fad.fa-cloud-sun-rain::after, .fa-duotone.fa-cloud-sun-rain::after {
  content: "\f743\f743";
}

.fad.fa-album::after, .fa-duotone.fa-album::after {
  content: "\f89f\f89f";
}

.fad.fa-circle-n::after, .fa-duotone.fa-circle-n::after {
  content: "\e118\e118";
}

.fad.fa-compress::after, .fa-duotone.fa-compress::after {
  content: "\f066\f066";
}

.fad.fa-wheat-awn::after, .fa-duotone.fa-wheat-awn::after {
  content: "\e2cd\e2cd";
}

.fad.fa-wheat-alt::after, .fa-duotone.fa-wheat-alt::after {
  content: "\e2cd\e2cd";
}

.fad.fa-ankh::after, .fa-duotone.fa-ankh::after {
  content: "\f644\f644";
}

.fad.fa-hands-holding-child::after, .fa-duotone.fa-hands-holding-child::after {
  content: "\e4fa\e4fa";
}

.fad.fa-asterisk::after, .fa-duotone.fa-asterisk::after {
  content: "**";
}

.fad.fa-key-skeleton-left-right::after, .fa-duotone.fa-key-skeleton-left-right::after {
  content: "\e3b4\e3b4";
}

.fad.fa-comment-lines::after, .fa-duotone.fa-comment-lines::after {
  content: "\f4b0\f4b0";
}

.fad.fa-luchador-mask::after, .fa-duotone.fa-luchador-mask::after {
  content: "\f455\f455";
}

.fad.fa-luchador::after, .fa-duotone.fa-luchador::after {
  content: "\f455\f455";
}

.fad.fa-mask-luchador::after, .fa-duotone.fa-mask-luchador::after {
  content: "\f455\f455";
}

.fad.fa-square-check::after, .fa-duotone.fa-square-check::after {
  content: "\f14a\f14a";
}

.fad.fa-check-square::after, .fa-duotone.fa-check-square::after {
  content: "\f14a\f14a";
}

.fad.fa-shredder::after, .fa-duotone.fa-shredder::after {
  content: "\f68a\f68a";
}

.fad.fa-book-open-cover::after, .fa-duotone.fa-book-open-cover::after {
  content: "\e0c0\e0c0";
}

.fad.fa-book-open-alt::after, .fa-duotone.fa-book-open-alt::after {
  content: "\e0c0\e0c0";
}

.fad.fa-sandwich::after, .fa-duotone.fa-sandwich::after {
  content: "\f81f\f81f";
}

.fad.fa-peseta-sign::after, .fa-duotone.fa-peseta-sign::after {
  content: "\e221\e221";
}

.fad.fa-square-parking-slash::after, .fa-duotone.fa-square-parking-slash::after {
  content: "\f617\f617";
}

.fad.fa-parking-slash::after, .fa-duotone.fa-parking-slash::after {
  content: "\f617\f617";
}

.fad.fa-train-tunnel::after, .fa-duotone.fa-train-tunnel::after {
  content: "\e454\e454";
}

.fad.fa-heading::after, .fa-duotone.fa-heading::after {
  content: "\f1dc\f1dc";
}

.fad.fa-header::after, .fa-duotone.fa-header::after {
  content: "\f1dc\f1dc";
}

.fad.fa-ghost::after, .fa-duotone.fa-ghost::after {
  content: "\f6e2\f6e2";
}

.fad.fa-face-anguished::after, .fa-duotone.fa-face-anguished::after {
  content: "\e369\e369";
}

.fad.fa-hockey-sticks::after, .fa-duotone.fa-hockey-sticks::after {
  content: "\f454\f454";
}

.fad.fa-abacus::after, .fa-duotone.fa-abacus::after {
  content: "\f640\f640";
}

.fad.fa-film-simple::after, .fa-duotone.fa-film-simple::after {
  content: "\f3a0\f3a0";
}

.fad.fa-film-alt::after, .fa-duotone.fa-film-alt::after {
  content: "\f3a0\f3a0";
}

.fad.fa-list::after, .fa-duotone.fa-list::after {
  content: "\f03a\f03a";
}

.fad.fa-list-squares::after, .fa-duotone.fa-list-squares::after {
  content: "\f03a\f03a";
}

.fad.fa-tree-palm::after, .fa-duotone.fa-tree-palm::after {
  content: "\f82b\f82b";
}

.fad.fa-square-phone-flip::after, .fa-duotone.fa-square-phone-flip::after {
  content: "\f87b\f87b";
}

.fad.fa-phone-square-alt::after, .fa-duotone.fa-phone-square-alt::after {
  content: "\f87b\f87b";
}

.fad.fa-cart-plus::after, .fa-duotone.fa-cart-plus::after {
  content: "\f217\f217";
}

.fad.fa-gamepad::after, .fa-duotone.fa-gamepad::after {
  content: "\f11b\f11b";
}

.fad.fa-border-center-v::after, .fa-duotone.fa-border-center-v::after {
  content: "\f89d\f89d";
}

.fad.fa-circle-dot::after, .fa-duotone.fa-circle-dot::after {
  content: "\f192\f192";
}

.fad.fa-dot-circle::after, .fa-duotone.fa-dot-circle::after {
  content: "\f192\f192";
}

.fad.fa-clipboard-medical::after, .fa-duotone.fa-clipboard-medical::after {
  content: "\e133\e133";
}

.fad.fa-face-dizzy::after, .fa-duotone.fa-face-dizzy::after {
  content: "\f567\f567";
}

.fad.fa-dizzy::after, .fa-duotone.fa-dizzy::after {
  content: "\f567\f567";
}

.fad.fa-egg::after, .fa-duotone.fa-egg::after {
  content: "\f7fb\f7fb";
}

.fad.fa-up-to-line::after, .fa-duotone.fa-up-to-line::after {
  content: "\f34d\f34d";
}

.fad.fa-arrow-alt-to-top::after, .fa-duotone.fa-arrow-alt-to-top::after {
  content: "\f34d\f34d";
}

.fad.fa-house-medical-circle-xmark::after, .fa-duotone.fa-house-medical-circle-xmark::after {
  content: "\e513\e513";
}

.fad.fa-watch-fitness::after, .fa-duotone.fa-watch-fitness::after {
  content: "\f63e\f63e";
}

.fad.fa-clock-nine-thirty::after, .fa-duotone.fa-clock-nine-thirty::after {
  content: "\e34d\e34d";
}

.fad.fa-campground::after, .fa-duotone.fa-campground::after {
  content: "\f6bb\f6bb";
}

.fad.fa-folder-plus::after, .fa-duotone.fa-folder-plus::after {
  content: "\f65e\f65e";
}

.fad.fa-jug::after, .fa-duotone.fa-jug::after {
  content: "\f8c6\f8c6";
}

.fad.fa-futbol::after, .fa-duotone.fa-futbol::after {
  content: "\f1e3\f1e3";
}

.fad.fa-futbol-ball::after, .fa-duotone.fa-futbol-ball::after {
  content: "\f1e3\f1e3";
}

.fad.fa-soccer-ball::after, .fa-duotone.fa-soccer-ball::after {
  content: "\f1e3\f1e3";
}

.fad.fa-snow-blowing::after, .fa-duotone.fa-snow-blowing::after {
  content: "\f761\f761";
}

.fad.fa-paintbrush::after, .fa-duotone.fa-paintbrush::after {
  content: "\f1fc\f1fc";
}

.fad.fa-paint-brush::after, .fa-duotone.fa-paint-brush::after {
  content: "\f1fc\f1fc";
}

.fad.fa-lock::after, .fa-duotone.fa-lock::after {
  content: "\f023\f023";
}

.fad.fa-arrow-down-from-line::after, .fa-duotone.fa-arrow-down-from-line::after {
  content: "\f345\f345";
}

.fad.fa-arrow-from-top::after, .fa-duotone.fa-arrow-from-top::after {
  content: "\f345\f345";
}

.fad.fa-gas-pump::after, .fa-duotone.fa-gas-pump::after {
  content: "\f52f\f52f";
}

.fad.fa-signal-bars-slash::after, .fa-duotone.fa-signal-bars-slash::after {
  content: "\f694\f694";
}

.fad.fa-signal-alt-slash::after, .fa-duotone.fa-signal-alt-slash::after {
  content: "\f694\f694";
}

.fad.fa-monkey::after, .fa-duotone.fa-monkey::after {
  content: "\f6fb\f6fb";
}

.fad.fa-rectangle-pro::after, .fa-duotone.fa-rectangle-pro::after {
  content: "\e235\e235";
}

.fad.fa-pro::after, .fa-duotone.fa-pro::after {
  content: "\e235\e235";
}

.fad.fa-house-night::after, .fa-duotone.fa-house-night::after {
  content: "\e010\e010";
}

.fad.fa-hot-tub-person::after, .fa-duotone.fa-hot-tub-person::after {
  content: "\f593\f593";
}

.fad.fa-hot-tub::after, .fa-duotone.fa-hot-tub::after {
  content: "\f593\f593";
}

.fad.fa-blanket::after, .fa-duotone.fa-blanket::after {
  content: "\f498\f498";
}

.fad.fa-map-location::after, .fa-duotone.fa-map-location::after {
  content: "\f59f\f59f";
}

.fad.fa-map-marked::after, .fa-duotone.fa-map-marked::after {
  content: "\f59f\f59f";
}

.fad.fa-house-flood-water::after, .fa-duotone.fa-house-flood-water::after {
  content: "\e50e\e50e";
}

.fad.fa-comments-question-check::after, .fa-duotone.fa-comments-question-check::after {
  content: "\e14f\e14f";
}

.fad.fa-tree::after, .fa-duotone.fa-tree::after {
  content: "\f1bb\f1bb";
}

.fad.fa-arrows-cross::after, .fa-duotone.fa-arrows-cross::after {
  content: "\e0a2\e0a2";
}

.fad.fa-backpack::after, .fa-duotone.fa-backpack::after {
  content: "\f5d4\f5d4";
}

.fad.fa-square-small::after, .fa-duotone.fa-square-small::after {
  content: "\e27e\e27e";
}

.fad.fa-folder-arrow-up::after, .fa-duotone.fa-folder-arrow-up::after {
  content: "\e054\e054";
}

.fad.fa-folder-upload::after, .fa-duotone.fa-folder-upload::after {
  content: "\e054\e054";
}

.fad.fa-bridge-lock::after, .fa-duotone.fa-bridge-lock::after {
  content: "\e4cc\e4cc";
}

.fad.fa-crosshairs-simple::after, .fa-duotone.fa-crosshairs-simple::after {
  content: "\e59f\e59f";
}

.fad.fa-sack-dollar::after, .fa-duotone.fa-sack-dollar::after {
  content: "\f81d\f81d";
}

.fad.fa-pen-to-square::after, .fa-duotone.fa-pen-to-square::after {
  content: "\f044\f044";
}

.fad.fa-edit::after, .fa-duotone.fa-edit::after {
  content: "\f044\f044";
}

.fad.fa-square-sliders::after, .fa-duotone.fa-square-sliders::after {
  content: "\f3f0\f3f0";
}

.fad.fa-sliders-h-square::after, .fa-duotone.fa-sliders-h-square::after {
  content: "\f3f0\f3f0";
}

.fad.fa-car-side::after, .fa-duotone.fa-car-side::after {
  content: "\f5e4\f5e4";
}

.fad.fa-message-middle-top::after, .fa-duotone.fa-message-middle-top::after {
  content: "\e1e2\e1e2";
}

.fad.fa-comment-middle-top-alt::after, .fa-duotone.fa-comment-middle-top-alt::after {
  content: "\e1e2\e1e2";
}

.fad.fa-lightbulb-on::after, .fa-duotone.fa-lightbulb-on::after {
  content: "\f672\f672";
}

.fad.fa-knife::after, .fa-duotone.fa-knife::after {
  content: "\f2e4\f2e4";
}

.fad.fa-utensil-knife::after, .fa-duotone.fa-utensil-knife::after {
  content: "\f2e4\f2e4";
}

.fad.fa-share-nodes::after, .fa-duotone.fa-share-nodes::after {
  content: "\f1e0\f1e0";
}

.fad.fa-share-alt::after, .fa-duotone.fa-share-alt::after {
  content: "\f1e0\f1e0";
}

.fad.fa-wave-sine::after, .fa-duotone.fa-wave-sine::after {
  content: "\f899\f899";
}

.fad.fa-heart-circle-minus::after, .fa-duotone.fa-heart-circle-minus::after {
  content: "\e4ff\e4ff";
}

.fad.fa-circle-w::after, .fa-duotone.fa-circle-w::after {
  content: "\e12c\e12c";
}

.fad.fa-circle-calendar::after, .fa-duotone.fa-circle-calendar::after {
  content: "\e102\e102";
}

.fad.fa-calendar-circle::after, .fa-duotone.fa-calendar-circle::after {
  content: "\e102\e102";
}

.fad.fa-hourglass-half::after, .fa-duotone.fa-hourglass-half::after {
  content: "\f252\f252";
}

.fad.fa-hourglass-2::after, .fa-duotone.fa-hourglass-2::after {
  content: "\f252\f252";
}

.fad.fa-microscope::after, .fa-duotone.fa-microscope::after {
  content: "\f610\f610";
}

.fad.fa-sunset::after, .fa-duotone.fa-sunset::after {
  content: "\f767\f767";
}

.fad.fa-sink::after, .fa-duotone.fa-sink::after {
  content: "\e06d\e06d";
}

.fad.fa-calendar-exclamation::after, .fa-duotone.fa-calendar-exclamation::after {
  content: "\f334\f334";
}

.fad.fa-truck-container-empty::after, .fa-duotone.fa-truck-container-empty::after {
  content: "\e2b5\e2b5";
}

.fad.fa-hand-heart::after, .fa-duotone.fa-hand-heart::after {
  content: "\f4bc\f4bc";
}

.fad.fa-bag-shopping::after, .fa-duotone.fa-bag-shopping::after {
  content: "\f290\f290";
}

.fad.fa-shopping-bag::after, .fa-duotone.fa-shopping-bag::after {
  content: "\f290\f290";
}

.fad.fa-arrow-down-z-a::after, .fa-duotone.fa-arrow-down-z-a::after {
  content: "\f881\f881";
}

.fad.fa-sort-alpha-desc::after, .fa-duotone.fa-sort-alpha-desc::after {
  content: "\f881\f881";
}

.fad.fa-sort-alpha-down-alt::after, .fa-duotone.fa-sort-alpha-down-alt::after {
  content: "\f881\f881";
}

.fad.fa-mitten::after, .fa-duotone.fa-mitten::after {
  content: "\f7b5\f7b5";
}

.fad.fa-reply-clock::after, .fa-duotone.fa-reply-clock::after {
  content: "\e239\e239";
}

.fad.fa-reply-time::after, .fa-duotone.fa-reply-time::after {
  content: "\e239\e239";
}

.fad.fa-person-rays::after, .fa-duotone.fa-person-rays::after {
  content: "\e54d\e54d";
}

.fad.fa-right::after, .fa-duotone.fa-right::after {
  content: "\f356\f356";
}

.fad.fa-arrow-alt-right::after, .fa-duotone.fa-arrow-alt-right::after {
  content: "\f356\f356";
}

.fad.fa-circle-f::after, .fa-duotone.fa-circle-f::after {
  content: "\e10e\e10e";
}

.fad.fa-users::after, .fa-duotone.fa-users::after {
  content: "\f0c0\f0c0";
}

.fad.fa-face-pleading::after, .fa-duotone.fa-face-pleading::after {
  content: "\e386\e386";
}

.fad.fa-eye-slash::after, .fa-duotone.fa-eye-slash::after {
  content: "\f070\f070";
}

.fad.fa-flask-vial::after, .fa-duotone.fa-flask-vial::after {
  content: "\e4f3\e4f3";
}

.fad.fa-police-box::after, .fa-duotone.fa-police-box::after {
  content: "\e021\e021";
}

.fad.fa-cucumber::after, .fa-duotone.fa-cucumber::after {
  content: "\e401\e401";
}

.fad.fa-head-side-brain::after, .fa-duotone.fa-head-side-brain::after {
  content: "\f808\f808";
}

.fad.fa-hand::after, .fa-duotone.fa-hand::after {
  content: "\f256\f256";
}

.fad.fa-hand-paper::after, .fa-duotone.fa-hand-paper::after {
  content: "\f256\f256";
}

.fad.fa-person-biking-mountain::after, .fa-duotone.fa-person-biking-mountain::after {
  content: "\f84b\f84b";
}

.fad.fa-biking-mountain::after, .fa-duotone.fa-biking-mountain::after {
  content: "\f84b\f84b";
}

.fad.fa-utensils-slash::after, .fa-duotone.fa-utensils-slash::after {
  content: "\e464\e464";
}

.fad.fa-print-magnifying-glass::after, .fa-duotone.fa-print-magnifying-glass::after {
  content: "\f81a\f81a";
}

.fad.fa-print-search::after, .fa-duotone.fa-print-search::after {
  content: "\f81a\f81a";
}

.fad.fa-folder-bookmark::after, .fa-duotone.fa-folder-bookmark::after {
  content: "\e186\e186";
}

.fad.fa-om::after, .fa-duotone.fa-om::after {
  content: "\f679\f679";
}

.fad.fa-pi::after, .fa-duotone.fa-pi::after {
  content: "\f67e\f67e";
}

.fad.fa-flask-round-potion::after, .fa-duotone.fa-flask-round-potion::after {
  content: "\f6e1\f6e1";
}

.fad.fa-flask-potion::after, .fa-duotone.fa-flask-potion::after {
  content: "\f6e1\f6e1";
}

.fad.fa-face-shush::after, .fa-duotone.fa-face-shush::after {
  content: "\e38c\e38c";
}

.fad.fa-worm::after, .fa-duotone.fa-worm::after {
  content: "\e599\e599";
}

.fad.fa-house-circle-xmark::after, .fa-duotone.fa-house-circle-xmark::after {
  content: "\e50b\e50b";
}

.fad.fa-plug::after, .fa-duotone.fa-plug::after {
  content: "\f1e6\f1e6";
}

.fad.fa-calendar-circle-exclamation::after, .fa-duotone.fa-calendar-circle-exclamation::after {
  content: "\e46e\e46e";
}

.fad.fa-square-i::after, .fa-duotone.fa-square-i::after {
  content: "\e272\e272";
}

.fad.fa-chevron-up::after, .fa-duotone.fa-chevron-up::after {
  content: "\f077\f077";
}

.fad.fa-face-saluting::after, .fa-duotone.fa-face-saluting::after {
  content: "\e484\e484";
}

.fad.fa-gauge-simple-low::after, .fa-duotone.fa-gauge-simple-low::after {
  content: "\f62c\f62c";
}

.fad.fa-tachometer-slow::after, .fa-duotone.fa-tachometer-slow::after {
  content: "\f62c\f62c";
}

.fad.fa-face-persevering::after, .fa-duotone.fa-face-persevering::after {
  content: "\e385\e385";
}

.fad.fa-circle-camera::after, .fa-duotone.fa-circle-camera::after {
  content: "\e103\e103";
}

.fad.fa-camera-circle::after, .fa-duotone.fa-camera-circle::after {
  content: "\e103\e103";
}

.fad.fa-hand-spock::after, .fa-duotone.fa-hand-spock::after {
  content: "\f259\f259";
}

.fad.fa-spider-web::after, .fa-duotone.fa-spider-web::after {
  content: "\f719\f719";
}

.fad.fa-circle-microphone::after, .fa-duotone.fa-circle-microphone::after {
  content: "\e116\e116";
}

.fad.fa-microphone-circle::after, .fa-duotone.fa-microphone-circle::after {
  content: "\e116\e116";
}

.fad.fa-book-arrow-up::after, .fa-duotone.fa-book-arrow-up::after {
  content: "\e0ba\e0ba";
}

.fad.fa-popsicle::after, .fa-duotone.fa-popsicle::after {
  content: "\e43e\e43e";
}

.fad.fa-command::after, .fa-duotone.fa-command::after {
  content: "\e142\e142";
}

.fad.fa-blinds::after, .fa-duotone.fa-blinds::after {
  content: "\f8fb\f8fb";
}

.fad.fa-stopwatch::after, .fa-duotone.fa-stopwatch::after {
  content: "\f2f2\f2f2";
}

.fad.fa-saxophone::after, .fa-duotone.fa-saxophone::after {
  content: "\f8dc\f8dc";
}

.fad.fa-square-2::after, .fa-duotone.fa-square-2::after {
  content: "\e257\e257";
}

.fad.fa-field-hockey-stick-ball::after, .fa-duotone.fa-field-hockey-stick-ball::after {
  content: "\f44c\f44c";
}

.fad.fa-field-hockey::after, .fa-duotone.fa-field-hockey::after {
  content: "\f44c\f44c";
}

.fad.fa-arrow-up-square-triangle::after, .fa-duotone.fa-arrow-up-square-triangle::after {
  content: "\f88b\f88b";
}

.fad.fa-sort-shapes-up-alt::after, .fa-duotone.fa-sort-shapes-up-alt::after {
  content: "\f88b\f88b";
}

.fad.fa-face-scream::after, .fa-duotone.fa-face-scream::after {
  content: "\e38b\e38b";
}

.fad.fa-square-m::after, .fa-duotone.fa-square-m::after {
  content: "\e276\e276";
}

.fad.fa-camera-web::after, .fa-duotone.fa-camera-web::after {
  content: "\f832\f832";
}

.fad.fa-webcam::after, .fa-duotone.fa-webcam::after {
  content: "\f832\f832";
}

.fad.fa-comment-arrow-down::after, .fa-duotone.fa-comment-arrow-down::after {
  content: "\e143\e143";
}

.fad.fa-lightbulb-cfl::after, .fa-duotone.fa-lightbulb-cfl::after {
  content: "\e5a6\e5a6";
}

.fad.fa-window-frame-open::after, .fa-duotone.fa-window-frame-open::after {
  content: "\e050\e050";
}

.fad.fa-face-kiss::after, .fa-duotone.fa-face-kiss::after {
  content: "\f596\f596";
}

.fad.fa-kiss::after, .fa-duotone.fa-kiss::after {
  content: "\f596\f596";
}

.fad.fa-bridge-circle-xmark::after, .fa-duotone.fa-bridge-circle-xmark::after {
  content: "\e4cb\e4cb";
}

.fad.fa-period::after, .fa-duotone.fa-period::after {
  content: "..";
}

.fad.fa-face-grin-tongue::after, .fa-duotone.fa-face-grin-tongue::after {
  content: "\f589\f589";
}

.fad.fa-grin-tongue::after, .fa-duotone.fa-grin-tongue::after {
  content: "\f589\f589";
}

.fad.fa-up-to-dotted-line::after, .fa-duotone.fa-up-to-dotted-line::after {
  content: "\e457\e457";
}

.fad.fa-thought-bubble::after, .fa-duotone.fa-thought-bubble::after {
  content: "\e32e\e32e";
}

.fad.fa-raygun::after, .fa-duotone.fa-raygun::after {
  content: "\e025\e025";
}

.fad.fa-flute::after, .fa-duotone.fa-flute::after {
  content: "\f8b9\f8b9";
}

.fad.fa-acorn::after, .fa-duotone.fa-acorn::after {
  content: "\f6ae\f6ae";
}

.fad.fa-video-arrow-up-right::after, .fa-duotone.fa-video-arrow-up-right::after {
  content: "\e2c9\e2c9";
}

.fad.fa-grate-droplet::after, .fa-duotone.fa-grate-droplet::after {
  content: "\e194\e194";
}

.fad.fa-seal-exclamation::after, .fa-duotone.fa-seal-exclamation::after {
  content: "\e242\e242";
}

.fad.fa-chess-bishop::after, .fa-duotone.fa-chess-bishop::after {
  content: "\f43a\f43a";
}

.fad.fa-message-sms::after, .fa-duotone.fa-message-sms::after {
  content: "\e1e5\e1e5";
}

.fad.fa-coffee-beans::after, .fa-duotone.fa-coffee-beans::after {
  content: "\e13f\e13f";
}

.fad.fa-hat-witch::after, .fa-duotone.fa-hat-witch::after {
  content: "\f6e7\f6e7";
}

.fad.fa-face-grin-wink::after, .fa-duotone.fa-face-grin-wink::after {
  content: "\f58c\f58c";
}

.fad.fa-grin-wink::after, .fa-duotone.fa-grin-wink::after {
  content: "\f58c\f58c";
}

.fad.fa-clock-three-thirty::after, .fa-duotone.fa-clock-three-thirty::after {
  content: "\e357\e357";
}

.fad.fa-ear-deaf::after, .fa-duotone.fa-ear-deaf::after {
  content: "\f2a4\f2a4";
}

.fad.fa-deaf::after, .fa-duotone.fa-deaf::after {
  content: "\f2a4\f2a4";
}

.fad.fa-deafness::after, .fa-duotone.fa-deafness::after {
  content: "\f2a4\f2a4";
}

.fad.fa-hard-of-hearing::after, .fa-duotone.fa-hard-of-hearing::after {
  content: "\f2a4\f2a4";
}

.fad.fa-alarm-clock::after, .fa-duotone.fa-alarm-clock::after {
  content: "\f34e\f34e";
}

.fad.fa-eclipse::after, .fa-duotone.fa-eclipse::after {
  content: "\f749\f749";
}

.fad.fa-face-relieved::after, .fa-duotone.fa-face-relieved::after {
  content: "\e389\e389";
}

.fad.fa-road-circle-check::after, .fa-duotone.fa-road-circle-check::after {
  content: "\e564\e564";
}

.fad.fa-dice-five::after, .fa-duotone.fa-dice-five::after {
  content: "\f523\f523";
}

.fad.fa-octagon-minus::after, .fa-duotone.fa-octagon-minus::after {
  content: "\f308\f308";
}

.fad.fa-minus-octagon::after, .fa-duotone.fa-minus-octagon::after {
  content: "\f308\f308";
}

.fad.fa-square-rss::after, .fa-duotone.fa-square-rss::after {
  content: "\f143\f143";
}

.fad.fa-rss-square::after, .fa-duotone.fa-rss-square::after {
  content: "\f143\f143";
}

.fad.fa-face-zany::after, .fa-duotone.fa-face-zany::after {
  content: "\e3a4\e3a4";
}

.fad.fa-land-mine-on::after, .fa-duotone.fa-land-mine-on::after {
  content: "\e51b\e51b";
}

.fad.fa-square-arrow-up-left::after, .fa-duotone.fa-square-arrow-up-left::after {
  content: "\e263\e263";
}

.fad.fa-i-cursor::after, .fa-duotone.fa-i-cursor::after {
  content: "\f246\f246";
}

.fad.fa-salt-shaker::after, .fa-duotone.fa-salt-shaker::after {
  content: "\e446\e446";
}

.fad.fa-stamp::after, .fa-duotone.fa-stamp::after {
  content: "\f5bf\f5bf";
}

.fad.fa-file-plus::after, .fa-duotone.fa-file-plus::after {
  content: "\f319\f319";
}

.fad.fa-draw-square::after, .fa-duotone.fa-draw-square::after {
  content: "\f5ef\f5ef";
}

.fad.fa-toilet-paper-under-slash::after, .fa-duotone.fa-toilet-paper-under-slash::after {
  content: "\e2a1\e2a1";
}

.fad.fa-toilet-paper-reverse-slash::after, .fa-duotone.fa-toilet-paper-reverse-slash::after {
  content: "\e2a1\e2a1";
}

.fad.fa-stairs::after, .fa-duotone.fa-stairs::after {
  content: "\e289\e289";
}

.fad.fa-drone-front::after, .fa-duotone.fa-drone-front::after {
  content: "\f860\f860";
}

.fad.fa-drone-alt::after, .fa-duotone.fa-drone-alt::after {
  content: "\f860\f860";
}

.fad.fa-glass-empty::after, .fa-duotone.fa-glass-empty::after {
  content: "\e191\e191";
}

.fad.fa-dial-high::after, .fa-duotone.fa-dial-high::after {
  content: "\e15c\e15c";
}

.fad.fa-user-helmet-safety::after, .fa-duotone.fa-user-helmet-safety::after {
  content: "\f82c\f82c";
}

.fad.fa-user-construction::after, .fa-duotone.fa-user-construction::after {
  content: "\f82c\f82c";
}

.fad.fa-user-hard-hat::after, .fa-duotone.fa-user-hard-hat::after {
  content: "\f82c\f82c";
}

.fad.fa-i::after, .fa-duotone.fa-i::after {
  content: "II";
}

.fad.fa-hryvnia-sign::after, .fa-duotone.fa-hryvnia-sign::after {
  content: "\f6f2\f6f2";
}

.fad.fa-hryvnia::after, .fa-duotone.fa-hryvnia::after {
  content: "\f6f2\f6f2";
}

.fad.fa-arrow-down-left-and-arrow-up-right-to-center::after, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center::after {
  content: "\e092\e092";
}

.fad.fa-pills::after, .fa-duotone.fa-pills::after {
  content: "\f484\f484";
}

.fad.fa-face-grin-wide::after, .fa-duotone.fa-face-grin-wide::after {
  content: "\f581\f581";
}

.fad.fa-grin-alt::after, .fa-duotone.fa-grin-alt::after {
  content: "\f581\f581";
}

.fad.fa-tooth::after, .fa-duotone.fa-tooth::after {
  content: "\f5c9\f5c9";
}

.fad.fa-basketball-hoop::after, .fa-duotone.fa-basketball-hoop::after {
  content: "\f435\f435";
}

.fad.fa-objects-align-bottom::after, .fa-duotone.fa-objects-align-bottom::after {
  content: "\e3bb\e3bb";
}

.fad.fa-v::after, .fa-duotone.fa-v::after {
  content: "VV";
}

.fad.fa-sparkles::after, .fa-duotone.fa-sparkles::after {
  content: "\f890\f890";
}

.fad.fa-squid::after, .fa-duotone.fa-squid::after {
  content: "\e450\e450";
}

.fad.fa-leafy-green::after, .fa-duotone.fa-leafy-green::after {
  content: "\e41d\e41d";
}

.fad.fa-circle-arrow-up-right::after, .fa-duotone.fa-circle-arrow-up-right::after {
  content: "\e0fc\e0fc";
}

.fad.fa-calendars::after, .fa-duotone.fa-calendars::after {
  content: "\e0d7\e0d7";
}

.fad.fa-bangladeshi-taka-sign::after, .fa-duotone.fa-bangladeshi-taka-sign::after {
  content: "\e2e6\e2e6";
}

.fad.fa-bicycle::after, .fa-duotone.fa-bicycle::after {
  content: "\f206\f206";
}

.fad.fa-hammer-war::after, .fa-duotone.fa-hammer-war::after {
  content: "\f6e4\f6e4";
}

.fad.fa-circle-d::after, .fa-duotone.fa-circle-d::after {
  content: "\e104\e104";
}

.fad.fa-spider-black-widow::after, .fa-duotone.fa-spider-black-widow::after {
  content: "\f718\f718";
}

.fad.fa-staff-snake::after, .fa-duotone.fa-staff-snake::after {
  content: "\e579\e579";
}

.fad.fa-rod-asclepius::after, .fa-duotone.fa-rod-asclepius::after {
  content: "\e579\e579";
}

.fad.fa-rod-snake::after, .fa-duotone.fa-rod-snake::after {
  content: "\e579\e579";
}

.fad.fa-staff-aesculapius::after, .fa-duotone.fa-staff-aesculapius::after {
  content: "\e579\e579";
}

.fad.fa-pear::after, .fa-duotone.fa-pear::after {
  content: "\e20c\e20c";
}

.fad.fa-head-side-cough-slash::after, .fa-duotone.fa-head-side-cough-slash::after {
  content: "\e062\e062";
}

.fad.fa-triangle::after, .fa-duotone.fa-triangle::after {
  content: "\f2ec\f2ec";
}

.fad.fa-apartment::after, .fa-duotone.fa-apartment::after {
  content: "\e468\e468";
}

.fad.fa-truck-medical::after, .fa-duotone.fa-truck-medical::after {
  content: "\f0f9\f0f9";
}

.fad.fa-ambulance::after, .fa-duotone.fa-ambulance::after {
  content: "\f0f9\f0f9";
}

.fad.fa-pepper::after, .fa-duotone.fa-pepper::after {
  content: "\e432\e432";
}

.fad.fa-piano::after, .fa-duotone.fa-piano::after {
  content: "\f8d4\f8d4";
}

.fad.fa-gun-squirt::after, .fa-duotone.fa-gun-squirt::after {
  content: "\e19d\e19d";
}

.fad.fa-wheat-awn-circle-exclamation::after, .fa-duotone.fa-wheat-awn-circle-exclamation::after {
  content: "\e598\e598";
}

.fad.fa-snowman::after, .fa-duotone.fa-snowman::after {
  content: "\f7d0\f7d0";
}

.fad.fa-user-alien::after, .fa-duotone.fa-user-alien::after {
  content: "\e04a\e04a";
}

.fad.fa-shield-check::after, .fa-duotone.fa-shield-check::after {
  content: "\f2f7\f2f7";
}

.fad.fa-mortar-pestle::after, .fa-duotone.fa-mortar-pestle::after {
  content: "\f5a7\f5a7";
}

.fad.fa-road-barrier::after, .fa-duotone.fa-road-barrier::after {
  content: "\e562\e562";
}

.fad.fa-chart-candlestick::after, .fa-duotone.fa-chart-candlestick::after {
  content: "\e0e2\e0e2";
}

.fad.fa-briefcase-blank::after, .fa-duotone.fa-briefcase-blank::after {
  content: "\e0c8\e0c8";
}

.fad.fa-school::after, .fa-duotone.fa-school::after {
  content: "\f549\f549";
}

.fad.fa-igloo::after, .fa-duotone.fa-igloo::after {
  content: "\f7ae\f7ae";
}

.fad.fa-bracket-round::after, .fa-duotone.fa-bracket-round::after {
  content: "((";
}

.fad.fa-parenthesis::after, .fa-duotone.fa-parenthesis::after {
  content: "((";
}

.fad.fa-joint::after, .fa-duotone.fa-joint::after {
  content: "\f595\f595";
}

.fad.fa-horse-saddle::after, .fa-duotone.fa-horse-saddle::after {
  content: "\f8c3\f8c3";
}

.fad.fa-mug-marshmallows::after, .fa-duotone.fa-mug-marshmallows::after {
  content: "\f7b7\f7b7";
}

.fad.fa-filters::after, .fa-duotone.fa-filters::after {
  content: "\e17e\e17e";
}

.fad.fa-bell-on::after, .fa-duotone.fa-bell-on::after {
  content: "\f8fa\f8fa";
}

.fad.fa-angle-right::after, .fa-duotone.fa-angle-right::after {
  content: "\f105\f105";
}

.fad.fa-dial-med::after, .fa-duotone.fa-dial-med::after {
  content: "\e15f\e15f";
}

.fad.fa-horse::after, .fa-duotone.fa-horse::after {
  content: "\f6f0\f6f0";
}

.fad.fa-q::after, .fa-duotone.fa-q::after {
  content: "QQ";
}

.fad.fa-monitor-waveform::after, .fa-duotone.fa-monitor-waveform::after {
  content: "\f611\f611";
}

.fad.fa-monitor-heart-rate::after, .fa-duotone.fa-monitor-heart-rate::after {
  content: "\f611\f611";
}

.fad.fa-link-simple::after, .fa-duotone.fa-link-simple::after {
  content: "\e1cd\e1cd";
}

.fad.fa-whistle::after, .fa-duotone.fa-whistle::after {
  content: "\f460\f460";
}

.fad.fa-g::after, .fa-duotone.fa-g::after {
  content: "GG";
}

.fad.fa-wine-glass-crack::after, .fa-duotone.fa-wine-glass-crack::after {
  content: "\f4bb\f4bb";
}

.fad.fa-fragile::after, .fa-duotone.fa-fragile::after {
  content: "\f4bb\f4bb";
}

.fad.fa-slot-machine::after, .fa-duotone.fa-slot-machine::after {
  content: "\e3ce\e3ce";
}

.fad.fa-notes-medical::after, .fa-duotone.fa-notes-medical::after {
  content: "\f481\f481";
}

.fad.fa-car-wash::after, .fa-duotone.fa-car-wash::after {
  content: "\f5e6\f5e6";
}

.fad.fa-escalator::after, .fa-duotone.fa-escalator::after {
  content: "\e171\e171";
}

.fad.fa-comment-image::after, .fa-duotone.fa-comment-image::after {
  content: "\e148\e148";
}

.fad.fa-temperature-half::after, .fa-duotone.fa-temperature-half::after {
  content: "\f2c9\f2c9";
}

.fad.fa-temperature-2::after, .fa-duotone.fa-temperature-2::after {
  content: "\f2c9\f2c9";
}

.fad.fa-thermometer-2::after, .fa-duotone.fa-thermometer-2::after {
  content: "\f2c9\f2c9";
}

.fad.fa-thermometer-half::after, .fa-duotone.fa-thermometer-half::after {
  content: "\f2c9\f2c9";
}

.fad.fa-dong-sign::after, .fa-duotone.fa-dong-sign::after {
  content: "\e169\e169";
}

.fad.fa-donut::after, .fa-duotone.fa-donut::after {
  content: "\e406\e406";
}

.fad.fa-doughnut::after, .fa-duotone.fa-doughnut::after {
  content: "\e406\e406";
}

.fad.fa-capsules::after, .fa-duotone.fa-capsules::after {
  content: "\f46b\f46b";
}

.fad.fa-poo-storm::after, .fa-duotone.fa-poo-storm::after {
  content: "\f75a\f75a";
}

.fad.fa-poo-bolt::after, .fa-duotone.fa-poo-bolt::after {
  content: "\f75a\f75a";
}

.fad.fa-tally-1::after, .fa-duotone.fa-tally-1::after {
  content: "\e294\e294";
}

.fad.fa-face-frown-open::after, .fa-duotone.fa-face-frown-open::after {
  content: "\f57a\f57a";
}

.fad.fa-frown-open::after, .fa-duotone.fa-frown-open::after {
  content: "\f57a\f57a";
}

.fad.fa-square-dashed::after, .fa-duotone.fa-square-dashed::after {
  content: "\e269\e269";
}

.fad.fa-square-j::after, .fa-duotone.fa-square-j::after {
  content: "\e273\e273";
}

.fad.fa-hand-point-up::after, .fa-duotone.fa-hand-point-up::after {
  content: "\f0a6\f0a6";
}

.fad.fa-money-bill::after, .fa-duotone.fa-money-bill::after {
  content: "\f0d6\f0d6";
}

.fad.fa-arrow-up-big-small::after, .fa-duotone.fa-arrow-up-big-small::after {
  content: "\f88e\f88e";
}

.fad.fa-sort-size-up::after, .fa-duotone.fa-sort-size-up::after {
  content: "\f88e\f88e";
}

.fad.fa-barcode-read::after, .fa-duotone.fa-barcode-read::after {
  content: "\f464\f464";
}

.fad.fa-baguette::after, .fa-duotone.fa-baguette::after {
  content: "\e3d8\e3d8";
}

.fad.fa-bowl-soft-serve::after, .fa-duotone.fa-bowl-soft-serve::after {
  content: "\e46b\e46b";
}

.fad.fa-face-holding-back-tears::after, .fa-duotone.fa-face-holding-back-tears::after {
  content: "\e482\e482";
}

.fad.fa-square-up::after, .fa-duotone.fa-square-up::after {
  content: "\f353\f353";
}

.fad.fa-arrow-alt-square-up::after, .fa-duotone.fa-arrow-alt-square-up::after {
  content: "\f353\f353";
}

.fad.fa-train-subway-tunnel::after, .fa-duotone.fa-train-subway-tunnel::after {
  content: "\e2a3\e2a3";
}

.fad.fa-subway-tunnel::after, .fa-duotone.fa-subway-tunnel::after {
  content: "\e2a3\e2a3";
}

.fad.fa-square-exclamation::after, .fa-duotone.fa-square-exclamation::after {
  content: "\f321\f321";
}

.fad.fa-exclamation-square::after, .fa-duotone.fa-exclamation-square::after {
  content: "\f321\f321";
}

.fad.fa-semicolon::after, .fa-duotone.fa-semicolon::after {
  content: ";;";
}

.fad.fa-bookmark::after, .fa-duotone.fa-bookmark::after {
  content: "\f02e\f02e";
}

.fad.fa-fan-table::after, .fa-duotone.fa-fan-table::after {
  content: "\e004\e004";
}

.fad.fa-align-justify::after, .fa-duotone.fa-align-justify::after {
  content: "\f039\f039";
}

.fad.fa-battery-low::after, .fa-duotone.fa-battery-low::after {
  content: "\e0b1\e0b1";
}

.fad.fa-battery-1::after, .fa-duotone.fa-battery-1::after {
  content: "\e0b1\e0b1";
}

.fad.fa-credit-card-front::after, .fa-duotone.fa-credit-card-front::after {
  content: "\f38a\f38a";
}

.fad.fa-brain-arrow-curved-right::after, .fa-duotone.fa-brain-arrow-curved-right::after {
  content: "\f677\f677";
}

.fad.fa-mind-share::after, .fa-duotone.fa-mind-share::after {
  content: "\f677\f677";
}

.fad.fa-umbrella-beach::after, .fa-duotone.fa-umbrella-beach::after {
  content: "\f5ca\f5ca";
}

.fad.fa-helmet-un::after, .fa-duotone.fa-helmet-un::after {
  content: "\e503\e503";
}

.fad.fa-location-smile::after, .fa-duotone.fa-location-smile::after {
  content: "\f60d\f60d";
}

.fad.fa-map-marker-smile::after, .fa-duotone.fa-map-marker-smile::after {
  content: "\f60d\f60d";
}

.fad.fa-arrow-left-to-line::after, .fa-duotone.fa-arrow-left-to-line::after {
  content: "\f33e\f33e";
}

.fad.fa-arrow-to-left::after, .fa-duotone.fa-arrow-to-left::after {
  content: "\f33e\f33e";
}

.fad.fa-bullseye::after, .fa-duotone.fa-bullseye::after {
  content: "\f140\f140";
}

.fad.fa-sushi::after, .fa-duotone.fa-sushi::after {
  content: "\e48a\e48a";
}

.fad.fa-nigiri::after, .fa-duotone.fa-nigiri::after {
  content: "\e48a\e48a";
}

.fad.fa-message-captions::after, .fa-duotone.fa-message-captions::after {
  content: "\e1de\e1de";
}

.fad.fa-comment-alt-captions::after, .fa-duotone.fa-comment-alt-captions::after {
  content: "\e1de\e1de";
}

.fad.fa-trash-list::after, .fa-duotone.fa-trash-list::after {
  content: "\e2b1\e2b1";
}

.fad.fa-bacon::after, .fa-duotone.fa-bacon::after {
  content: "\f7e5\f7e5";
}

.fad.fa-option::after, .fa-duotone.fa-option::after {
  content: "\e318\e318";
}

.fad.fa-hand-point-down::after, .fa-duotone.fa-hand-point-down::after {
  content: "\f0a7\f0a7";
}

.fad.fa-arrow-up-from-bracket::after, .fa-duotone.fa-arrow-up-from-bracket::after {
  content: "\e09a\e09a";
}

.fad.fa-trash-plus::after, .fa-duotone.fa-trash-plus::after {
  content: "\e2b2\e2b2";
}

.fad.fa-objects-align-top::after, .fa-duotone.fa-objects-align-top::after {
  content: "\e3c0\e3c0";
}

.fad.fa-folder::after, .fa-duotone.fa-folder::after {
  content: "\f07b\f07b";
}

.fad.fa-folder-blank::after, .fa-duotone.fa-folder-blank::after {
  content: "\f07b\f07b";
}

.fad.fa-face-anxious-sweat::after, .fa-duotone.fa-face-anxious-sweat::after {
  content: "\e36a\e36a";
}

.fad.fa-credit-card-blank::after, .fa-duotone.fa-credit-card-blank::after {
  content: "\f389\f389";
}

.fad.fa-file-waveform::after, .fa-duotone.fa-file-waveform::after {
  content: "\f478\f478";
}

.fad.fa-file-medical-alt::after, .fa-duotone.fa-file-medical-alt::after {
  content: "\f478\f478";
}

.fad.fa-microchip-ai::after, .fa-duotone.fa-microchip-ai::after {
  content: "\e1ec\e1ec";
}

.fad.fa-mug::after, .fa-duotone.fa-mug::after {
  content: "\f874\f874";
}

.fad.fa-plane-up-slash::after, .fa-duotone.fa-plane-up-slash::after {
  content: "\e22e\e22e";
}

.fad.fa-radiation::after, .fa-duotone.fa-radiation::after {
  content: "\f7b9\f7b9";
}

.fad.fa-pen-circle::after, .fa-duotone.fa-pen-circle::after {
  content: "\e20e\e20e";
}

.fad.fa-chart-simple::after, .fa-duotone.fa-chart-simple::after {
  content: "\e473\e473";
}

.fad.fa-crutches::after, .fa-duotone.fa-crutches::after {
  content: "\f7f8\f7f8";
}

.fad.fa-circle-parking::after, .fa-duotone.fa-circle-parking::after {
  content: "\f615\f615";
}

.fad.fa-parking-circle::after, .fa-duotone.fa-parking-circle::after {
  content: "\f615\f615";
}

.fad.fa-mars-stroke::after, .fa-duotone.fa-mars-stroke::after {
  content: "\f229\f229";
}

.fad.fa-leaf-oak::after, .fa-duotone.fa-leaf-oak::after {
  content: "\f6f7\f6f7";
}

.fad.fa-square-bolt::after, .fa-duotone.fa-square-bolt::after {
  content: "\e265\e265";
}

.fad.fa-vial::after, .fa-duotone.fa-vial::after {
  content: "\f492\f492";
}

.fad.fa-gauge::after, .fa-duotone.fa-gauge::after {
  content: "\f624\f624";
}

.fad.fa-dashboard::after, .fa-duotone.fa-dashboard::after {
  content: "\f624\f624";
}

.fad.fa-gauge-med::after, .fa-duotone.fa-gauge-med::after {
  content: "\f624\f624";
}

.fad.fa-tachometer-alt-average::after, .fa-duotone.fa-tachometer-alt-average::after {
  content: "\f624\f624";
}

.fad.fa-wand-magic-sparkles::after, .fa-duotone.fa-wand-magic-sparkles::after {
  content: "\e2ca\e2ca";
}

.fad.fa-magic-wand-sparkles::after, .fa-duotone.fa-magic-wand-sparkles::after {
  content: "\e2ca\e2ca";
}

.fad.fa-lambda::after, .fa-duotone.fa-lambda::after {
  content: "\f66e\f66e";
}

.fad.fa-e::after, .fa-duotone.fa-e::after {
  content: "EE";
}

.fad.fa-pizza::after, .fa-duotone.fa-pizza::after {
  content: "\f817\f817";
}

.fad.fa-bowl-chopsticks-noodles::after, .fa-duotone.fa-bowl-chopsticks-noodles::after {
  content: "\e2ea\e2ea";
}

.fad.fa-h3::after, .fa-duotone.fa-h3::after {
  content: "\f315\f315";
}

.fad.fa-pen-clip::after, .fa-duotone.fa-pen-clip::after {
  content: "\f305\f305";
}

.fad.fa-pen-alt::after, .fa-duotone.fa-pen-alt::after {
  content: "\f305\f305";
}

.fad.fa-bridge-circle-exclamation::after, .fa-duotone.fa-bridge-circle-exclamation::after {
  content: "\e4ca\e4ca";
}

.fad.fa-badge-percent::after, .fa-duotone.fa-badge-percent::after {
  content: "\f646\f646";
}

.fad.fa-user::after, .fa-duotone.fa-user::after {
  content: "\f007\f007";
}

.fad.fa-sensor::after, .fa-duotone.fa-sensor::after {
  content: "\e028\e028";
}

.fad.fa-comma::after, .fa-duotone.fa-comma::after {
  content: ",,";
}

.fad.fa-school-circle-check::after, .fa-duotone.fa-school-circle-check::after {
  content: "\e56b\e56b";
}

.fad.fa-toilet-paper-under::after, .fa-duotone.fa-toilet-paper-under::after {
  content: "\e2a0\e2a0";
}

.fad.fa-toilet-paper-reverse::after, .fa-duotone.fa-toilet-paper-reverse::after {
  content: "\e2a0\e2a0";
}

.fad.fa-light-emergency::after, .fa-duotone.fa-light-emergency::after {
  content: "\e41f\e41f";
}

.fad.fa-arrow-down-to-arc::after, .fa-duotone.fa-arrow-down-to-arc::after {
  content: "\e4ae\e4ae";
}

.fad.fa-dumpster::after, .fa-duotone.fa-dumpster::after {
  content: "\f793\f793";
}

.fad.fa-van-shuttle::after, .fa-duotone.fa-van-shuttle::after {
  content: "\f5b6\f5b6";
}

.fad.fa-shuttle-van::after, .fa-duotone.fa-shuttle-van::after {
  content: "\f5b6\f5b6";
}

.fad.fa-building-user::after, .fa-duotone.fa-building-user::after {
  content: "\e4da\e4da";
}

.fad.fa-light-switch::after, .fa-duotone.fa-light-switch::after {
  content: "\e017\e017";
}

.fad.fa-square-caret-left::after, .fa-duotone.fa-square-caret-left::after {
  content: "\f191\f191";
}

.fad.fa-caret-square-left::after, .fa-duotone.fa-caret-square-left::after {
  content: "\f191\f191";
}

.fad.fa-highlighter::after, .fa-duotone.fa-highlighter::after {
  content: "\f591\f591";
}

.fad.fa-wave-pulse::after, .fa-duotone.fa-wave-pulse::after {
  content: "\f5f8\f5f8";
}

.fad.fa-heart-rate::after, .fa-duotone.fa-heart-rate::after {
  content: "\f5f8\f5f8";
}

.fad.fa-key::after, .fa-duotone.fa-key::after {
  content: "\f084\f084";
}

.fad.fa-hat-santa::after, .fa-duotone.fa-hat-santa::after {
  content: "\f7a7\f7a7";
}

.fad.fa-tamale::after, .fa-duotone.fa-tamale::after {
  content: "\e451\e451";
}

.fad.fa-box-check::after, .fa-duotone.fa-box-check::after {
  content: "\f467\f467";
}

.fad.fa-bullhorn::after, .fa-duotone.fa-bullhorn::after {
  content: "\f0a1\f0a1";
}

.fad.fa-steak::after, .fa-duotone.fa-steak::after {
  content: "\f824\f824";
}

.fad.fa-location-crosshairs-slash::after, .fa-duotone.fa-location-crosshairs-slash::after {
  content: "\f603\f603";
}

.fad.fa-location-slash::after, .fa-duotone.fa-location-slash::after {
  content: "\f603\f603";
}

.fad.fa-person-dolly::after, .fa-duotone.fa-person-dolly::after {
  content: "\f4d0\f4d0";
}

.fad.fa-globe::after, .fa-duotone.fa-globe::after {
  content: "\f0ac\f0ac";
}

.fad.fa-synagogue::after, .fa-duotone.fa-synagogue::after {
  content: "\f69b\f69b";
}

.fad.fa-file-chart-column::after, .fa-duotone.fa-file-chart-column::after {
  content: "\f659\f659";
}

.fad.fa-file-chart-line::after, .fa-duotone.fa-file-chart-line::after {
  content: "\f659\f659";
}

.fad.fa-person-half-dress::after, .fa-duotone.fa-person-half-dress::after {
  content: "\e548\e548";
}

.fad.fa-folder-image::after, .fa-duotone.fa-folder-image::after {
  content: "\e18a\e18a";
}

.fad.fa-calendar-pen::after, .fa-duotone.fa-calendar-pen::after {
  content: "\f333\f333";
}

.fad.fa-calendar-edit::after, .fa-duotone.fa-calendar-edit::after {
  content: "\f333\f333";
}

.fad.fa-road-bridge::after, .fa-duotone.fa-road-bridge::after {
  content: "\e563\e563";
}

.fad.fa-face-smile-tear::after, .fa-duotone.fa-face-smile-tear::after {
  content: "\e393\e393";
}

.fad.fa-message-plus::after, .fa-duotone.fa-message-plus::after {
  content: "\f4a8\f4a8";
}

.fad.fa-comment-alt-plus::after, .fa-duotone.fa-comment-alt-plus::after {
  content: "\f4a8\f4a8";
}

.fad.fa-location-arrow::after, .fa-duotone.fa-location-arrow::after {
  content: "\f124\f124";
}

.fad.fa-c::after, .fa-duotone.fa-c::after {
  content: "CC";
}

.fad.fa-tablet-button::after, .fa-duotone.fa-tablet-button::after {
  content: "\f10a\f10a";
}

.fad.fa-rectangle-history-circle-user::after, .fa-duotone.fa-rectangle-history-circle-user::after {
  content: "\e4a4\e4a4";
}

.fad.fa-building-lock::after, .fa-duotone.fa-building-lock::after {
  content: "\e4d6\e4d6";
}

.fad.fa-chart-line-up::after, .fa-duotone.fa-chart-line-up::after {
  content: "\e0e5\e0e5";
}

.fad.fa-mailbox::after, .fa-duotone.fa-mailbox::after {
  content: "\f813\f813";
}

.fad.fa-truck-bolt::after, .fa-duotone.fa-truck-bolt::after {
  content: "\e3d0\e3d0";
}

.fad.fa-pizza-slice::after, .fa-duotone.fa-pizza-slice::after {
  content: "\f818\f818";
}

.fad.fa-money-bill-wave::after, .fa-duotone.fa-money-bill-wave::after {
  content: "\f53a\f53a";
}

.fad.fa-chart-area::after, .fa-duotone.fa-chart-area::after {
  content: "\f1fe\f1fe";
}

.fad.fa-area-chart::after, .fa-duotone.fa-area-chart::after {
  content: "\f1fe\f1fe";
}

.fad.fa-house-flag::after, .fa-duotone.fa-house-flag::after {
  content: "\e50d\e50d";
}

.fad.fa-person-circle-minus::after, .fa-duotone.fa-person-circle-minus::after {
  content: "\e540\e540";
}

.fad.fa-scalpel::after, .fa-duotone.fa-scalpel::after {
  content: "\f61d\f61d";
}

.fad.fa-ban::after, .fa-duotone.fa-ban::after {
  content: "\f05e\f05e";
}

.fad.fa-cancel::after, .fa-duotone.fa-cancel::after {
  content: "\f05e\f05e";
}

.fad.fa-bell-exclamation::after, .fa-duotone.fa-bell-exclamation::after {
  content: "\f848\f848";
}

.fad.fa-circle-bookmark::after, .fa-duotone.fa-circle-bookmark::after {
  content: "\e100\e100";
}

.fad.fa-bookmark-circle::after, .fa-duotone.fa-bookmark-circle::after {
  content: "\e100\e100";
}

.fad.fa-egg-fried::after, .fa-duotone.fa-egg-fried::after {
  content: "\f7fc\f7fc";
}

.fad.fa-face-weary::after, .fa-duotone.fa-face-weary::after {
  content: "\e3a1\e3a1";
}

.fad.fa-uniform-martial-arts::after, .fa-duotone.fa-uniform-martial-arts::after {
  content: "\e3d1\e3d1";
}

.fad.fa-camera-rotate::after, .fa-duotone.fa-camera-rotate::after {
  content: "\e0d8\e0d8";
}

.fad.fa-sun-dust::after, .fa-duotone.fa-sun-dust::after {
  content: "\f764\f764";
}

.fad.fa-comment-text::after, .fa-duotone.fa-comment-text::after {
  content: "\e14d\e14d";
}

.fad.fa-spray-can-sparkles::after, .fa-duotone.fa-spray-can-sparkles::after {
  content: "\f5d0\f5d0";
}

.fad.fa-air-freshener::after, .fa-duotone.fa-air-freshener::after {
  content: "\f5d0\f5d0";
}

.fad.fa-signal-bars::after, .fa-duotone.fa-signal-bars::after {
  content: "\f690\f690";
}

.fad.fa-signal-alt::after, .fa-duotone.fa-signal-alt::after {
  content: "\f690\f690";
}

.fad.fa-signal-alt-4::after, .fa-duotone.fa-signal-alt-4::after {
  content: "\f690\f690";
}

.fad.fa-signal-bars-strong::after, .fa-duotone.fa-signal-bars-strong::after {
  content: "\f690\f690";
}

.fad.fa-diamond-exclamation::after, .fa-duotone.fa-diamond-exclamation::after {
  content: "\e405\e405";
}

.fad.fa-star::after, .fa-duotone.fa-star::after {
  content: "\f005\f005";
}

.fad.fa-dial-min::after, .fa-duotone.fa-dial-min::after {
  content: "\e161\e161";
}

.fad.fa-repeat::after, .fa-duotone.fa-repeat::after {
  content: "\f363\f363";
}

.fad.fa-cross::after, .fa-duotone.fa-cross::after {
  content: "\f654\f654";
}

.fad.fa-page-caret-down::after, .fa-duotone.fa-page-caret-down::after {
  content: "\e429\e429";
}

.fad.fa-file-caret-down::after, .fa-duotone.fa-file-caret-down::after {
  content: "\e429\e429";
}

.fad.fa-box::after, .fa-duotone.fa-box::after {
  content: "\f466\f466";
}

.fad.fa-venus-mars::after, .fa-duotone.fa-venus-mars::after {
  content: "\f228\f228";
}

.fad.fa-clock-seven-thirty::after, .fa-duotone.fa-clock-seven-thirty::after {
  content: "\e351\e351";
}

.fad.fa-arrow-pointer::after, .fa-duotone.fa-arrow-pointer::after {
  content: "\f245\f245";
}

.fad.fa-mouse-pointer::after, .fa-duotone.fa-mouse-pointer::after {
  content: "\f245\f245";
}

.fad.fa-clock-four-thirty::after, .fa-duotone.fa-clock-four-thirty::after {
  content: "\e34b\e34b";
}

.fad.fa-signal-bars-good::after, .fa-duotone.fa-signal-bars-good::after {
  content: "\f693\f693";
}

.fad.fa-signal-alt-3::after, .fa-duotone.fa-signal-alt-3::after {
  content: "\f693\f693";
}

.fad.fa-cactus::after, .fa-duotone.fa-cactus::after {
  content: "\f8a7\f8a7";
}

.fad.fa-maximize::after, .fa-duotone.fa-maximize::after {
  content: "\f31e\f31e";
}

.fad.fa-expand-arrows-alt::after, .fa-duotone.fa-expand-arrows-alt::after {
  content: "\f31e\f31e";
}

.fad.fa-charging-station::after, .fa-duotone.fa-charging-station::after {
  content: "\f5e7\f5e7";
}

.fad.fa-shapes::after, .fa-duotone.fa-shapes::after {
  content: "\f61f\f61f";
}

.fad.fa-triangle-circle-square::after, .fa-duotone.fa-triangle-circle-square::after {
  content: "\f61f\f61f";
}

.fad.fa-plane-tail::after, .fa-duotone.fa-plane-tail::after {
  content: "\e22c\e22c";
}

.fad.fa-gauge-simple-max::after, .fa-duotone.fa-gauge-simple-max::after {
  content: "\f62b\f62b";
}

.fad.fa-tachometer-fastest::after, .fa-duotone.fa-tachometer-fastest::after {
  content: "\f62b\f62b";
}

.fad.fa-circle-u::after, .fa-duotone.fa-circle-u::after {
  content: "\e127\e127";
}

.fad.fa-shield-slash::after, .fa-duotone.fa-shield-slash::after {
  content: "\e24b\e24b";
}

.fad.fa-square-phone-hangup::after, .fa-duotone.fa-square-phone-hangup::after {
  content: "\e27a\e27a";
}

.fad.fa-phone-square-down::after, .fa-duotone.fa-phone-square-down::after {
  content: "\e27a\e27a";
}

.fad.fa-arrow-up-left::after, .fa-duotone.fa-arrow-up-left::after {
  content: "\e09d\e09d";
}

.fad.fa-transporter-1::after, .fa-duotone.fa-transporter-1::after {
  content: "\e043\e043";
}

.fad.fa-peanuts::after, .fa-duotone.fa-peanuts::after {
  content: "\e431\e431";
}

.fad.fa-shuffle::after, .fa-duotone.fa-shuffle::after {
  content: "\f074\f074";
}

.fad.fa-random::after, .fa-duotone.fa-random::after {
  content: "\f074\f074";
}

.fad.fa-person-running::after, .fa-duotone.fa-person-running::after {
  content: "\f70c\f70c";
}

.fad.fa-running::after, .fa-duotone.fa-running::after {
  content: "\f70c\f70c";
}

.fad.fa-mobile-retro::after, .fa-duotone.fa-mobile-retro::after {
  content: "\e527\e527";
}

.fad.fa-grip-lines-vertical::after, .fa-duotone.fa-grip-lines-vertical::after {
  content: "\f7a5\f7a5";
}

.fad.fa-arrow-up-from-square::after, .fa-duotone.fa-arrow-up-from-square::after {
  content: "\e09c\e09c";
}

.fad.fa-file-dashed-line::after, .fa-duotone.fa-file-dashed-line::after {
  content: "\f877\f877";
}

.fad.fa-page-break::after, .fa-duotone.fa-page-break::after {
  content: "\f877\f877";
}

.fad.fa-bracket-curly-right::after, .fa-duotone.fa-bracket-curly-right::after {
  content: "}}";
}

.fad.fa-spider::after, .fa-duotone.fa-spider::after {
  content: "\f717\f717";
}

.fad.fa-clock-three::after, .fa-duotone.fa-clock-three::after {
  content: "\e356\e356";
}

.fad.fa-hands-bound::after, .fa-duotone.fa-hands-bound::after {
  content: "\e4f9\e4f9";
}

.fad.fa-scalpel-line-dashed::after, .fa-duotone.fa-scalpel-line-dashed::after {
  content: "\f61e\f61e";
}

.fad.fa-scalpel-path::after, .fa-duotone.fa-scalpel-path::after {
  content: "\f61e\f61e";
}

.fad.fa-file-invoice-dollar::after, .fa-duotone.fa-file-invoice-dollar::after {
  content: "\f571\f571";
}

.fad.fa-pipe-smoking::after, .fa-duotone.fa-pipe-smoking::after {
  content: "\e3c4\e3c4";
}

.fad.fa-face-astonished::after, .fa-duotone.fa-face-astonished::after {
  content: "\e36b\e36b";
}

.fad.fa-window::after, .fa-duotone.fa-window::after {
  content: "\f40e\f40e";
}

.fad.fa-plane-circle-exclamation::after, .fa-duotone.fa-plane-circle-exclamation::after {
  content: "\e556\e556";
}

.fad.fa-ear::after, .fa-duotone.fa-ear::after {
  content: "\f5f0\f5f0";
}

.fad.fa-file-lock::after, .fa-duotone.fa-file-lock::after {
  content: "\e3a6\e3a6";
}

.fad.fa-diagram-venn::after, .fa-duotone.fa-diagram-venn::after {
  content: "\e15a\e15a";
}

.fad.fa-x-ray::after, .fa-duotone.fa-x-ray::after {
  content: "\f497\f497";
}

.fad.fa-goal-net::after, .fa-duotone.fa-goal-net::after {
  content: "\e3ab\e3ab";
}

.fad.fa-coffin-cross::after, .fa-duotone.fa-coffin-cross::after {
  content: "\e051\e051";
}

.fad.fa-spell-check::after, .fa-duotone.fa-spell-check::after {
  content: "\f891\f891";
}

.fad.fa-location-xmark::after, .fa-duotone.fa-location-xmark::after {
  content: "\f60e\f60e";
}

.fad.fa-map-marker-times::after, .fa-duotone.fa-map-marker-times::after {
  content: "\f60e\f60e";
}

.fad.fa-map-marker-xmark::after, .fa-duotone.fa-map-marker-xmark::after {
  content: "\f60e\f60e";
}

.fad.fa-lasso::after, .fa-duotone.fa-lasso::after {
  content: "\f8c8\f8c8";
}

.fad.fa-slash::after, .fa-duotone.fa-slash::after {
  content: "\f715\f715";
}

.fad.fa-person-to-portal::after, .fa-duotone.fa-person-to-portal::after {
  content: "\e022\e022";
}

.fad.fa-portal-enter::after, .fa-duotone.fa-portal-enter::after {
  content: "\e022\e022";
}

.fad.fa-calendar-star::after, .fa-duotone.fa-calendar-star::after {
  content: "\f736\f736";
}

.fad.fa-computer-mouse::after, .fa-duotone.fa-computer-mouse::after {
  content: "\f8cc\f8cc";
}

.fad.fa-mouse::after, .fa-duotone.fa-mouse::after {
  content: "\f8cc\f8cc";
}

.fad.fa-arrow-right-to-bracket::after, .fa-duotone.fa-arrow-right-to-bracket::after {
  content: "\f090\f090";
}

.fad.fa-sign-in::after, .fa-duotone.fa-sign-in::after {
  content: "\f090\f090";
}

.fad.fa-pegasus::after, .fa-duotone.fa-pegasus::after {
  content: "\f703\f703";
}

.fad.fa-files-medical::after, .fa-duotone.fa-files-medical::after {
  content: "\f7fd\f7fd";
}

.fad.fa-nfc-lock::after, .fa-duotone.fa-nfc-lock::after {
  content: "\e1f8\e1f8";
}

.fad.fa-person-ski-lift::after, .fa-duotone.fa-person-ski-lift::after {
  content: "\f7c8\f7c8";
}

.fad.fa-ski-lift::after, .fa-duotone.fa-ski-lift::after {
  content: "\f7c8\f7c8";
}

.fad.fa-square-6::after, .fa-duotone.fa-square-6::after {
  content: "\e25b\e25b";
}

.fad.fa-shop-slash::after, .fa-duotone.fa-shop-slash::after {
  content: "\e070\e070";
}

.fad.fa-store-alt-slash::after, .fa-duotone.fa-store-alt-slash::after {
  content: "\e070\e070";
}

.fad.fa-wind-turbine::after, .fa-duotone.fa-wind-turbine::after {
  content: "\f89b\f89b";
}

.fad.fa-sliders-simple::after, .fa-duotone.fa-sliders-simple::after {
  content: "\e253\e253";
}

.fad.fa-badge-sheriff::after, .fa-duotone.fa-badge-sheriff::after {
  content: "\f8a2\f8a2";
}

.fad.fa-server::after, .fa-duotone.fa-server::after {
  content: "\f233\f233";
}

.fad.fa-virus-covid-slash::after, .fa-duotone.fa-virus-covid-slash::after {
  content: "\e4a9\e4a9";
}

.fad.fa-intersection::after, .fa-duotone.fa-intersection::after {
  content: "\f668\f668";
}

.fad.fa-shop-lock::after, .fa-duotone.fa-shop-lock::after {
  content: "\e4a5\e4a5";
}

.fad.fa-family::after, .fa-duotone.fa-family::after {
  content: "\e300\e300";
}

.fad.fa-hourglass-start::after, .fa-duotone.fa-hourglass-start::after {
  content: "\f251\f251";
}

.fad.fa-hourglass-1::after, .fa-duotone.fa-hourglass-1::after {
  content: "\f251\f251";
}

.fad.fa-user-hair-buns::after, .fa-duotone.fa-user-hair-buns::after {
  content: "\e3d3\e3d3";
}

.fad.fa-blender-phone::after, .fa-duotone.fa-blender-phone::after {
  content: "\f6b6\f6b6";
}

.fad.fa-hourglass-clock::after, .fa-duotone.fa-hourglass-clock::after {
  content: "\e41b\e41b";
}

.fad.fa-person-seat-reclined::after, .fa-duotone.fa-person-seat-reclined::after {
  content: "\e21f\e21f";
}

.fad.fa-paper-plane-top::after, .fa-duotone.fa-paper-plane-top::after {
  content: "\e20a\e20a";
}

.fad.fa-paper-plane-alt::after, .fa-duotone.fa-paper-plane-alt::after {
  content: "\e20a\e20a";
}

.fad.fa-send::after, .fa-duotone.fa-send::after {
  content: "\e20a\e20a";
}

.fad.fa-message-arrow-up::after, .fa-duotone.fa-message-arrow-up::after {
  content: "\e1dc\e1dc";
}

.fad.fa-comment-alt-arrow-up::after, .fa-duotone.fa-comment-alt-arrow-up::after {
  content: "\e1dc\e1dc";
}

.fad.fa-lightbulb-exclamation::after, .fa-duotone.fa-lightbulb-exclamation::after {
  content: "\f671\f671";
}

.fad.fa-layer-minus::after, .fa-duotone.fa-layer-minus::after {
  content: "\f5fe\f5fe";
}

.fad.fa-layer-group-minus::after, .fa-duotone.fa-layer-group-minus::after {
  content: "\f5fe\f5fe";
}

.fad.fa-circle-e::after, .fa-duotone.fa-circle-e::after {
  content: "\e109\e109";
}

.fad.fa-building-wheat::after, .fa-duotone.fa-building-wheat::after {
  content: "\e4db\e4db";
}

.fad.fa-gauge-max::after, .fa-duotone.fa-gauge-max::after {
  content: "\f626\f626";
}

.fad.fa-tachometer-alt-fastest::after, .fa-duotone.fa-tachometer-alt-fastest::after {
  content: "\f626\f626";
}

.fad.fa-person-breastfeeding::after, .fa-duotone.fa-person-breastfeeding::after {
  content: "\e53a\e53a";
}

.fad.fa-apostrophe::after, .fa-duotone.fa-apostrophe::after {
  content: "''";
}

.fad.fa-fire-hydrant::after, .fa-duotone.fa-fire-hydrant::after {
  content: "\e17f\e17f";
}

.fad.fa-right-to-bracket::after, .fa-duotone.fa-right-to-bracket::after {
  content: "\f2f6\f2f6";
}

.fad.fa-sign-in-alt::after, .fa-duotone.fa-sign-in-alt::after {
  content: "\f2f6\f2f6";
}

.fad.fa-video-plus::after, .fa-duotone.fa-video-plus::after {
  content: "\f4e1\f4e1";
}

.fad.fa-square-right::after, .fa-duotone.fa-square-right::after {
  content: "\f352\f352";
}

.fad.fa-arrow-alt-square-right::after, .fa-duotone.fa-arrow-alt-square-right::after {
  content: "\f352\f352";
}

.fad.fa-comment-smile::after, .fa-duotone.fa-comment-smile::after {
  content: "\f4b4\f4b4";
}

.fad.fa-venus::after, .fa-duotone.fa-venus::after {
  content: "\f221\f221";
}

.fad.fa-passport::after, .fa-duotone.fa-passport::after {
  content: "\f5ab\f5ab";
}

.fad.fa-inbox-in::after, .fa-duotone.fa-inbox-in::after {
  content: "\f310\f310";
}

.fad.fa-inbox-arrow-down::after, .fa-duotone.fa-inbox-arrow-down::after {
  content: "\f310\f310";
}

.fad.fa-heart-pulse::after, .fa-duotone.fa-heart-pulse::after {
  content: "\f21e\f21e";
}

.fad.fa-heartbeat::after, .fa-duotone.fa-heartbeat::after {
  content: "\f21e\f21e";
}

.fad.fa-circle-8::after, .fa-duotone.fa-circle-8::after {
  content: "\e0f5\e0f5";
}

.fad.fa-clouds-moon::after, .fa-duotone.fa-clouds-moon::after {
  content: "\f745\f745";
}

.fad.fa-clock-ten-thirty::after, .fa-duotone.fa-clock-ten-thirty::after {
  content: "\e355\e355";
}

.fad.fa-people-carry-box::after, .fa-duotone.fa-people-carry-box::after {
  content: "\f4ce\f4ce";
}

.fad.fa-people-carry::after, .fa-duotone.fa-people-carry::after {
  content: "\f4ce\f4ce";
}

.fad.fa-folder-user::after, .fa-duotone.fa-folder-user::after {
  content: "\e18e\e18e";
}

.fad.fa-trash-can-xmark::after, .fa-duotone.fa-trash-can-xmark::after {
  content: "\e2ae\e2ae";
}

.fad.fa-temperature-high::after, .fa-duotone.fa-temperature-high::after {
  content: "\f769\f769";
}

.fad.fa-microchip::after, .fa-duotone.fa-microchip::after {
  content: "\f2db\f2db";
}

.fad.fa-left-long-to-line::after, .fa-duotone.fa-left-long-to-line::after {
  content: "\e41e\e41e";
}

.fad.fa-crown::after, .fa-duotone.fa-crown::after {
  content: "\f521\f521";
}

.fad.fa-weight-hanging::after, .fa-duotone.fa-weight-hanging::after {
  content: "\f5cd\f5cd";
}

.fad.fa-xmarks-lines::after, .fa-duotone.fa-xmarks-lines::after {
  content: "\e59a\e59a";
}

.fad.fa-file-prescription::after, .fa-duotone.fa-file-prescription::after {
  content: "\f572\f572";
}

.fad.fa-calendar-range::after, .fa-duotone.fa-calendar-range::after {
  content: "\e0d6\e0d6";
}

.fad.fa-flower-daffodil::after, .fa-duotone.fa-flower-daffodil::after {
  content: "\f800\f800";
}

.fad.fa-hand-back-point-up::after, .fa-duotone.fa-hand-back-point-up::after {
  content: "\e1a2\e1a2";
}

.fad.fa-weight-scale::after, .fa-duotone.fa-weight-scale::after {
  content: "\f496\f496";
}

.fad.fa-weight::after, .fa-duotone.fa-weight::after {
  content: "\f496\f496";
}

.fad.fa-star-exclamation::after, .fa-duotone.fa-star-exclamation::after {
  content: "\f2f3\f2f3";
}

.fad.fa-books::after, .fa-duotone.fa-books::after {
  content: "\f5db\f5db";
}

.fad.fa-user-group::after, .fa-duotone.fa-user-group::after {
  content: "\f500\f500";
}

.fad.fa-user-friends::after, .fa-duotone.fa-user-friends::after {
  content: "\f500\f500";
}

.fad.fa-arrow-up-a-z::after, .fa-duotone.fa-arrow-up-a-z::after {
  content: "\f15e\f15e";
}

.fad.fa-sort-alpha-up::after, .fa-duotone.fa-sort-alpha-up::after {
  content: "\f15e\f15e";
}

.fad.fa-layer-plus::after, .fa-duotone.fa-layer-plus::after {
  content: "\f5ff\f5ff";
}

.fad.fa-layer-group-plus::after, .fa-duotone.fa-layer-group-plus::after {
  content: "\f5ff\f5ff";
}

.fad.fa-play-pause::after, .fa-duotone.fa-play-pause::after {
  content: "\e22f\e22f";
}

.fad.fa-block-question::after, .fa-duotone.fa-block-question::after {
  content: "\e3dd\e3dd";
}

.fad.fa-snooze::after, .fa-duotone.fa-snooze::after {
  content: "\f880\f880";
}

.fad.fa-zzz::after, .fa-duotone.fa-zzz::after {
  content: "\f880\f880";
}

.fad.fa-scanner-image::after, .fa-duotone.fa-scanner-image::after {
  content: "\f8f3\f8f3";
}

.fad.fa-tv-retro::after, .fa-duotone.fa-tv-retro::after {
  content: "\f401\f401";
}

.fad.fa-square-t::after, .fa-duotone.fa-square-t::after {
  content: "\e280\e280";
}

.fad.fa-farm::after, .fa-duotone.fa-farm::after {
  content: "\f864\f864";
}

.fad.fa-barn-silo::after, .fa-duotone.fa-barn-silo::after {
  content: "\f864\f864";
}

.fad.fa-chess-knight::after, .fa-duotone.fa-chess-knight::after {
  content: "\f441\f441";
}

.fad.fa-bars-sort::after, .fa-duotone.fa-bars-sort::after {
  content: "\e0ae\e0ae";
}

.fad.fa-pallet-boxes::after, .fa-duotone.fa-pallet-boxes::after {
  content: "\f483\f483";
}

.fad.fa-palette-boxes::after, .fa-duotone.fa-palette-boxes::after {
  content: "\f483\f483";
}

.fad.fa-pallet-alt::after, .fa-duotone.fa-pallet-alt::after {
  content: "\f483\f483";
}

.fad.fa-face-laugh-squint::after, .fa-duotone.fa-face-laugh-squint::after {
  content: "\f59b\f59b";
}

.fad.fa-laugh-squint::after, .fa-duotone.fa-laugh-squint::after {
  content: "\f59b\f59b";
}

.fad.fa-code-simple::after, .fa-duotone.fa-code-simple::after {
  content: "\e13d\e13d";
}

.fad.fa-bolt-slash::after, .fa-duotone.fa-bolt-slash::after {
  content: "\e0b8\e0b8";
}

.fad.fa-panel-fire::after, .fa-duotone.fa-panel-fire::after {
  content: "\e42f\e42f";
}

.fad.fa-binary-circle-check::after, .fa-duotone.fa-binary-circle-check::after {
  content: "\e33c\e33c";
}

.fad.fa-comment-minus::after, .fa-duotone.fa-comment-minus::after {
  content: "\f4b1\f4b1";
}

.fad.fa-burrito::after, .fa-duotone.fa-burrito::after {
  content: "\f7ed\f7ed";
}

.fad.fa-violin::after, .fa-duotone.fa-violin::after {
  content: "\f8ed\f8ed";
}

.fad.fa-objects-column::after, .fa-duotone.fa-objects-column::after {
  content: "\e3c1\e3c1";
}

.fad.fa-square-chevron-down::after, .fa-duotone.fa-square-chevron-down::after {
  content: "\f329\f329";
}

.fad.fa-chevron-square-down::after, .fa-duotone.fa-chevron-square-down::after {
  content: "\f329\f329";
}

.fad.fa-comment-plus::after, .fa-duotone.fa-comment-plus::after {
  content: "\f4b2\f4b2";
}

.fad.fa-triangle-instrument::after, .fa-duotone.fa-triangle-instrument::after {
  content: "\f8e2\f8e2";
}

.fad.fa-triangle-music::after, .fa-duotone.fa-triangle-music::after {
  content: "\f8e2\f8e2";
}

.fad.fa-wheelchair::after, .fa-duotone.fa-wheelchair::after {
  content: "\f193\f193";
}

.fad.fa-user-pilot-tie::after, .fa-duotone.fa-user-pilot-tie::after {
  content: "\e2c1\e2c1";
}

.fad.fa-piano-keyboard::after, .fa-duotone.fa-piano-keyboard::after {
  content: "\f8d5\f8d5";
}

.fad.fa-bed-empty::after, .fa-duotone.fa-bed-empty::after {
  content: "\f8f9\f8f9";
}

.fad.fa-circle-arrow-up::after, .fa-duotone.fa-circle-arrow-up::after {
  content: "\f0aa\f0aa";
}

.fad.fa-arrow-circle-up::after, .fa-duotone.fa-arrow-circle-up::after {
  content: "\f0aa\f0aa";
}

.fad.fa-toggle-on::after, .fa-duotone.fa-toggle-on::after {
  content: "\f205\f205";
}

.fad.fa-rectangle-vertical::after, .fa-duotone.fa-rectangle-vertical::after {
  content: "\f2fb\f2fb";
}

.fad.fa-rectangle-portrait::after, .fa-duotone.fa-rectangle-portrait::after {
  content: "\f2fb\f2fb";
}

.fad.fa-person-walking::after, .fa-duotone.fa-person-walking::after {
  content: "\f554\f554";
}

.fad.fa-walking::after, .fa-duotone.fa-walking::after {
  content: "\f554\f554";
}

.fad.fa-l::after, .fa-duotone.fa-l::after {
  content: "LL";
}

.fad.fa-signal-stream::after, .fa-duotone.fa-signal-stream::after {
  content: "\f8dd\f8dd";
}

.fad.fa-down-to-bracket::after, .fa-duotone.fa-down-to-bracket::after {
  content: "\e4e7\e4e7";
}

.fad.fa-circle-z::after, .fa-duotone.fa-circle-z::after {
  content: "\e130\e130";
}

.fad.fa-stars::after, .fa-duotone.fa-stars::after {
  content: "\f762\f762";
}

.fad.fa-fire::after, .fa-duotone.fa-fire::after {
  content: "\f06d\f06d";
}

.fad.fa-bed-pulse::after, .fa-duotone.fa-bed-pulse::after {
  content: "\f487\f487";
}

.fad.fa-procedures::after, .fa-duotone.fa-procedures::after {
  content: "\f487\f487";
}

.fad.fa-house-day::after, .fa-duotone.fa-house-day::after {
  content: "\e00e\e00e";
}

.fad.fa-shuttle-space::after, .fa-duotone.fa-shuttle-space::after {
  content: "\f197\f197";
}

.fad.fa-space-shuttle::after, .fa-duotone.fa-space-shuttle::after {
  content: "\f197\f197";
}

.fad.fa-shirt-long-sleeve::after, .fa-duotone.fa-shirt-long-sleeve::after {
  content: "\e3c7\e3c7";
}

.fad.fa-chart-pie-simple::after, .fa-duotone.fa-chart-pie-simple::after {
  content: "\f64e\f64e";
}

.fad.fa-chart-pie-alt::after, .fa-duotone.fa-chart-pie-alt::after {
  content: "\f64e\f64e";
}

.fad.fa-face-laugh::after, .fa-duotone.fa-face-laugh::after {
  content: "\f599\f599";
}

.fad.fa-laugh::after, .fa-duotone.fa-laugh::after {
  content: "\f599\f599";
}

.fad.fa-folder-open::after, .fa-duotone.fa-folder-open::after {
  content: "\f07c\f07c";
}

.fad.fa-album-collection-circle-user::after, .fa-duotone.fa-album-collection-circle-user::after {
  content: "\e48f\e48f";
}

.fad.fa-candy::after, .fa-duotone.fa-candy::after {
  content: "\e3e7\e3e7";
}

.fad.fa-bowl-hot::after, .fa-duotone.fa-bowl-hot::after {
  content: "\f823\f823";
}

.fad.fa-soup::after, .fa-duotone.fa-soup::after {
  content: "\f823\f823";
}

.fad.fa-flatbread::after, .fa-duotone.fa-flatbread::after {
  content: "\e40b\e40b";
}

.fad.fa-heart-circle-plus::after, .fa-duotone.fa-heart-circle-plus::after {
  content: "\e500\e500";
}

.fad.fa-code-fork::after, .fa-duotone.fa-code-fork::after {
  content: "\e13b\e13b";
}

.fad.fa-city::after, .fa-duotone.fa-city::after {
  content: "\f64f\f64f";
}

.fad.fa-signal-bars-weak::after, .fa-duotone.fa-signal-bars-weak::after {
  content: "\f691\f691";
}

.fad.fa-signal-alt-1::after, .fa-duotone.fa-signal-alt-1::after {
  content: "\f691\f691";
}

.fad.fa-microphone-lines::after, .fa-duotone.fa-microphone-lines::after {
  content: "\f3c9\f3c9";
}

.fad.fa-microphone-alt::after, .fa-duotone.fa-microphone-alt::after {
  content: "\f3c9\f3c9";
}

.fad.fa-clock-twelve::after, .fa-duotone.fa-clock-twelve::after {
  content: "\e358\e358";
}

.fad.fa-pepper-hot::after, .fa-duotone.fa-pepper-hot::after {
  content: "\f816\f816";
}

.fad.fa-citrus-slice::after, .fa-duotone.fa-citrus-slice::after {
  content: "\e2f5\e2f5";
}

.fad.fa-sheep::after, .fa-duotone.fa-sheep::after {
  content: "\f711\f711";
}

.fad.fa-unlock::after, .fa-duotone.fa-unlock::after {
  content: "\f09c\f09c";
}

.fad.fa-colon-sign::after, .fa-duotone.fa-colon-sign::after {
  content: "\e140\e140";
}

.fad.fa-headset::after, .fa-duotone.fa-headset::after {
  content: "\f590\f590";
}

.fad.fa-badger-honey::after, .fa-duotone.fa-badger-honey::after {
  content: "\f6b4\f6b4";
}

.fad.fa-h4::after, .fa-duotone.fa-h4::after {
  content: "\f86a\f86a";
}

.fad.fa-store-slash::after, .fa-duotone.fa-store-slash::after {
  content: "\e071\e071";
}

.fad.fa-road-circle-xmark::after, .fa-duotone.fa-road-circle-xmark::after {
  content: "\e566\e566";
}

.fad.fa-signal-slash::after, .fa-duotone.fa-signal-slash::after {
  content: "\f695\f695";
}

.fad.fa-user-minus::after, .fa-duotone.fa-user-minus::after {
  content: "\f503\f503";
}

.fad.fa-mars-stroke-up::after, .fa-duotone.fa-mars-stroke-up::after {
  content: "\f22a\f22a";
}

.fad.fa-mars-stroke-v::after, .fa-duotone.fa-mars-stroke-v::after {
  content: "\f22a\f22a";
}

.fad.fa-champagne-glasses::after, .fa-duotone.fa-champagne-glasses::after {
  content: "\f79f\f79f";
}

.fad.fa-glass-cheers::after, .fa-duotone.fa-glass-cheers::after {
  content: "\f79f\f79f";
}

.fad.fa-taco::after, .fa-duotone.fa-taco::after {
  content: "\f826\f826";
}

.fad.fa-hexagon-plus::after, .fa-duotone.fa-hexagon-plus::after {
  content: "\f300\f300";
}

.fad.fa-plus-hexagon::after, .fa-duotone.fa-plus-hexagon::after {
  content: "\f300\f300";
}

.fad.fa-clipboard::after, .fa-duotone.fa-clipboard::after {
  content: "\f328\f328";
}

.fad.fa-house-circle-exclamation::after, .fa-duotone.fa-house-circle-exclamation::after {
  content: "\e50a\e50a";
}

.fad.fa-file-arrow-up::after, .fa-duotone.fa-file-arrow-up::after {
  content: "\f574\f574";
}

.fad.fa-file-upload::after, .fa-duotone.fa-file-upload::after {
  content: "\f574\f574";
}

.fad.fa-wifi::after, .fa-duotone.fa-wifi::after {
  content: "\f1eb\f1eb";
}

.fad.fa-wifi-3::after, .fa-duotone.fa-wifi-3::after {
  content: "\f1eb\f1eb";
}

.fad.fa-wifi-strong::after, .fa-duotone.fa-wifi-strong::after {
  content: "\f1eb\f1eb";
}

.fad.fa-messages::after, .fa-duotone.fa-messages::after {
  content: "\f4b6\f4b6";
}

.fad.fa-comments-alt::after, .fa-duotone.fa-comments-alt::after {
  content: "\f4b6\f4b6";
}

.fad.fa-bath::after, .fa-duotone.fa-bath::after {
  content: "\f2cd\f2cd";
}

.fad.fa-bathtub::after, .fa-duotone.fa-bathtub::after {
  content: "\f2cd\f2cd";
}

.fad.fa-umbrella-simple::after, .fa-duotone.fa-umbrella-simple::after {
  content: "\e2bc\e2bc";
}

.fad.fa-umbrella-alt::after, .fa-duotone.fa-umbrella-alt::after {
  content: "\e2bc\e2bc";
}

.fad.fa-rectangle-history-circle-plus::after, .fa-duotone.fa-rectangle-history-circle-plus::after {
  content: "\e4a3\e4a3";
}

.fad.fa-underline::after, .fa-duotone.fa-underline::after {
  content: "\f0cd\f0cd";
}

.fad.fa-user-pen::after, .fa-duotone.fa-user-pen::after {
  content: "\f4ff\f4ff";
}

.fad.fa-user-edit::after, .fa-duotone.fa-user-edit::after {
  content: "\f4ff\f4ff";
}

.fad.fa-binary-slash::after, .fa-duotone.fa-binary-slash::after {
  content: "\e33e\e33e";
}

.fad.fa-square-o::after, .fa-duotone.fa-square-o::after {
  content: "\e278\e278";
}

.fad.fa-signature::after, .fa-duotone.fa-signature::after {
  content: "\f5b7\f5b7";
}

.fad.fa-stroopwafel::after, .fa-duotone.fa-stroopwafel::after {
  content: "\f551\f551";
}

.fad.fa-bold::after, .fa-duotone.fa-bold::after {
  content: "\f032\f032";
}

.fad.fa-anchor-lock::after, .fa-duotone.fa-anchor-lock::after {
  content: "\e4ad\e4ad";
}

.fad.fa-building-ngo::after, .fa-duotone.fa-building-ngo::after {
  content: "\e4d7\e4d7";
}

.fad.fa-transporter-3::after, .fa-duotone.fa-transporter-3::after {
  content: "\e045\e045";
}

.fad.fa-engine-warning::after, .fa-duotone.fa-engine-warning::after {
  content: "\f5f2\f5f2";
}

.fad.fa-engine-exclamation::after, .fa-duotone.fa-engine-exclamation::after {
  content: "\f5f2\f5f2";
}

.fad.fa-circle-down-right::after, .fa-duotone.fa-circle-down-right::after {
  content: "\e108\e108";
}

.fad.fa-square-k::after, .fa-duotone.fa-square-k::after {
  content: "\e274\e274";
}

.fad.fa-manat-sign::after, .fa-duotone.fa-manat-sign::after {
  content: "\e1d5\e1d5";
}

.fad.fa-money-check-pen::after, .fa-duotone.fa-money-check-pen::after {
  content: "\f872\f872";
}

.fad.fa-money-check-edit::after, .fa-duotone.fa-money-check-edit::after {
  content: "\f872\f872";
}

.fad.fa-not-equal::after, .fa-duotone.fa-not-equal::after {
  content: "\f53e\f53e";
}

.fad.fa-border-top-left::after, .fa-duotone.fa-border-top-left::after {
  content: "\f853\f853";
}

.fad.fa-border-style::after, .fa-duotone.fa-border-style::after {
  content: "\f853\f853";
}

.fad.fa-map-location-dot::after, .fa-duotone.fa-map-location-dot::after {
  content: "\f5a0\f5a0";
}

.fad.fa-map-marked-alt::after, .fa-duotone.fa-map-marked-alt::after {
  content: "\f5a0\f5a0";
}

.fad.fa-tilde::after, .fa-duotone.fa-tilde::after {
  content: "~~";
}

.fad.fa-jedi::after, .fa-duotone.fa-jedi::after {
  content: "\f669\f669";
}

.fad.fa-square-poll-vertical::after, .fa-duotone.fa-square-poll-vertical::after {
  content: "\f681\f681";
}

.fad.fa-poll::after, .fa-duotone.fa-poll::after {
  content: "\f681\f681";
}

.fad.fa-arrow-down-square-triangle::after, .fa-duotone.fa-arrow-down-square-triangle::after {
  content: "\f889\f889";
}

.fad.fa-sort-shapes-down-alt::after, .fa-duotone.fa-sort-shapes-down-alt::after {
  content: "\f889\f889";
}

.fad.fa-mug-hot::after, .fa-duotone.fa-mug-hot::after {
  content: "\f7b6\f7b6";
}

.fad.fa-dog-leashed::after, .fa-duotone.fa-dog-leashed::after {
  content: "\f6d4\f6d4";
}

.fad.fa-car-battery::after, .fa-duotone.fa-car-battery::after {
  content: "\f5df\f5df";
}

.fad.fa-battery-car::after, .fa-duotone.fa-battery-car::after {
  content: "\f5df\f5df";
}

.fad.fa-face-downcast-sweat::after, .fa-duotone.fa-face-downcast-sweat::after {
  content: "\e371\e371";
}

.fad.fa-memo-circle-info::after, .fa-duotone.fa-memo-circle-info::after {
  content: "\e49a\e49a";
}

.fad.fa-gift::after, .fa-duotone.fa-gift::after {
  content: "\f06b\f06b";
}

.fad.fa-dice-two::after, .fa-duotone.fa-dice-two::after {
  content: "\f528\f528";
}

.fad.fa-volume::after, .fa-duotone.fa-volume::after {
  content: "\f6a8\f6a8";
}

.fad.fa-volume-medium::after, .fa-duotone.fa-volume-medium::after {
  content: "\f6a8\f6a8";
}

.fad.fa-transporter-5::after, .fa-duotone.fa-transporter-5::after {
  content: "\e2a6\e2a6";
}

.fad.fa-gauge-circle-bolt::after, .fa-duotone.fa-gauge-circle-bolt::after {
  content: "\e496\e496";
}

.fad.fa-coin-front::after, .fa-duotone.fa-coin-front::after {
  content: "\e3fc\e3fc";
}

.fad.fa-file-slash::after, .fa-duotone.fa-file-slash::after {
  content: "\e3a7\e3a7";
}

.fad.fa-message-arrow-up-right::after, .fa-duotone.fa-message-arrow-up-right::after {
  content: "\e1dd\e1dd";
}

.fad.fa-treasure-chest::after, .fa-duotone.fa-treasure-chest::after {
  content: "\f723\f723";
}

.fad.fa-chess-queen::after, .fa-duotone.fa-chess-queen::after {
  content: "\f445\f445";
}

.fad.fa-paintbrush-fine::after, .fa-duotone.fa-paintbrush-fine::after {
  content: "\f5a9\f5a9";
}

.fad.fa-paint-brush-alt::after, .fa-duotone.fa-paint-brush-alt::after {
  content: "\f5a9\f5a9";
}

.fad.fa-paint-brush-fine::after, .fa-duotone.fa-paint-brush-fine::after {
  content: "\f5a9\f5a9";
}

.fad.fa-paintbrush-alt::after, .fa-duotone.fa-paintbrush-alt::after {
  content: "\f5a9\f5a9";
}

.fad.fa-glasses::after, .fa-duotone.fa-glasses::after {
  content: "\f530\f530";
}

.fad.fa-hood-cloak::after, .fa-duotone.fa-hood-cloak::after {
  content: "\f6ef\f6ef";
}

.fad.fa-square-quote::after, .fa-duotone.fa-square-quote::after {
  content: "\e329\e329";
}

.fad.fa-up-left::after, .fa-duotone.fa-up-left::after {
  content: "\e2bd\e2bd";
}

.fad.fa-bring-front::after, .fa-duotone.fa-bring-front::after {
  content: "\f857\f857";
}

.fad.fa-chess-board::after, .fa-duotone.fa-chess-board::after {
  content: "\f43c\f43c";
}

.fad.fa-burger-cheese::after, .fa-duotone.fa-burger-cheese::after {
  content: "\f7f1\f7f1";
}

.fad.fa-cheeseburger::after, .fa-duotone.fa-cheeseburger::after {
  content: "\f7f1\f7f1";
}

.fad.fa-building-circle-check::after, .fa-duotone.fa-building-circle-check::after {
  content: "\e4d2\e4d2";
}

.fad.fa-repeat-1::after, .fa-duotone.fa-repeat-1::after {
  content: "\f365\f365";
}

.fad.fa-arrow-down-to-line::after, .fa-duotone.fa-arrow-down-to-line::after {
  content: "\f33d\f33d";
}

.fad.fa-arrow-to-bottom::after, .fa-duotone.fa-arrow-to-bottom::after {
  content: "\f33d\f33d";
}

.fad.fa-grid-5::after, .fa-duotone.fa-grid-5::after {
  content: "\e199\e199";
}

.fad.fa-right-long-to-line::after, .fa-duotone.fa-right-long-to-line::after {
  content: "\e444\e444";
}

.fad.fa-person-chalkboard::after, .fa-duotone.fa-person-chalkboard::after {
  content: "\e53d\e53d";
}

.fad.fa-mars-stroke-right::after, .fa-duotone.fa-mars-stroke-right::after {
  content: "\f22b\f22b";
}

.fad.fa-mars-stroke-h::after, .fa-duotone.fa-mars-stroke-h::after {
  content: "\f22b\f22b";
}

.fad.fa-hand-back-fist::after, .fa-duotone.fa-hand-back-fist::after {
  content: "\f255\f255";
}

.fad.fa-hand-rock::after, .fa-duotone.fa-hand-rock::after {
  content: "\f255\f255";
}

.fad.fa-tally::after, .fa-duotone.fa-tally::after {
  content: "\f69c\f69c";
}

.fad.fa-tally-5::after, .fa-duotone.fa-tally-5::after {
  content: "\f69c\f69c";
}

.fad.fa-square-caret-up::after, .fa-duotone.fa-square-caret-up::after {
  content: "\f151\f151";
}

.fad.fa-caret-square-up::after, .fa-duotone.fa-caret-square-up::after {
  content: "\f151\f151";
}

.fad.fa-cloud-showers-water::after, .fa-duotone.fa-cloud-showers-water::after {
  content: "\e4e4\e4e4";
}

.fad.fa-chart-bar::after, .fa-duotone.fa-chart-bar::after {
  content: "\f080\f080";
}

.fad.fa-bar-chart::after, .fa-duotone.fa-bar-chart::after {
  content: "\f080\f080";
}

.fad.fa-hands-bubbles::after, .fa-duotone.fa-hands-bubbles::after {
  content: "\e05e\e05e";
}

.fad.fa-hands-wash::after, .fa-duotone.fa-hands-wash::after {
  content: "\e05e\e05e";
}

.fad.fa-less-than-equal::after, .fa-duotone.fa-less-than-equal::after {
  content: "\f537\f537";
}

.fad.fa-train::after, .fa-duotone.fa-train::after {
  content: "\f238\f238";
}

.fad.fa-up-from-dotted-line::after, .fa-duotone.fa-up-from-dotted-line::after {
  content: "\e456\e456";
}

.fad.fa-eye-low-vision::after, .fa-duotone.fa-eye-low-vision::after {
  content: "\f2a8\f2a8";
}

.fad.fa-low-vision::after, .fa-duotone.fa-low-vision::after {
  content: "\f2a8\f2a8";
}

.fad.fa-traffic-light-go::after, .fa-duotone.fa-traffic-light-go::after {
  content: "\f638\f638";
}

.fad.fa-face-exhaling::after, .fa-duotone.fa-face-exhaling::after {
  content: "\e480\e480";
}

.fad.fa-sensor-fire::after, .fa-duotone.fa-sensor-fire::after {
  content: "\e02a\e02a";
}

.fad.fa-user-unlock::after, .fa-duotone.fa-user-unlock::after {
  content: "\e058\e058";
}

.fad.fa-hexagon-divide::after, .fa-duotone.fa-hexagon-divide::after {
  content: "\e1ad\e1ad";
}

.fad.fa-00::after, .fa-duotone.fa-00::after {
  content: "\e467\e467";
}

.fad.fa-crow::after, .fa-duotone.fa-crow::after {
  content: "\f520\f520";
}

.fad.fa-cassette-betamax::after, .fa-duotone.fa-cassette-betamax::after {
  content: "\f8a4\f8a4";
}

.fad.fa-betamax::after, .fa-duotone.fa-betamax::after {
  content: "\f8a4\f8a4";
}

.fad.fa-sailboat::after, .fa-duotone.fa-sailboat::after {
  content: "\e445\e445";
}

.fad.fa-window-restore::after, .fa-duotone.fa-window-restore::after {
  content: "\f2d2\f2d2";
}

.fad.fa-nfc-magnifying-glass::after, .fa-duotone.fa-nfc-magnifying-glass::after {
  content: "\e1f9\e1f9";
}

.fad.fa-file-binary::after, .fa-duotone.fa-file-binary::after {
  content: "\e175\e175";
}

.fad.fa-circle-v::after, .fa-duotone.fa-circle-v::after {
  content: "\e12a\e12a";
}

.fad.fa-square-plus::after, .fa-duotone.fa-square-plus::after {
  content: "\f0fe\f0fe";
}

.fad.fa-plus-square::after, .fa-duotone.fa-plus-square::after {
  content: "\f0fe\f0fe";
}

.fad.fa-bowl-scoops::after, .fa-duotone.fa-bowl-scoops::after {
  content: "\e3df\e3df";
}

.fad.fa-mistletoe::after, .fa-duotone.fa-mistletoe::after {
  content: "\f7b4\f7b4";
}

.fad.fa-custard::after, .fa-duotone.fa-custard::after {
  content: "\e403\e403";
}

.fad.fa-lacrosse-stick::after, .fa-duotone.fa-lacrosse-stick::after {
  content: "\e3b5\e3b5";
}

.fad.fa-hockey-mask::after, .fa-duotone.fa-hockey-mask::after {
  content: "\f6ee\f6ee";
}

.fad.fa-sunrise::after, .fa-duotone.fa-sunrise::after {
  content: "\f766\f766";
}

.fad.fa-panel-ews::after, .fa-duotone.fa-panel-ews::after {
  content: "\e42e\e42e";
}

.fad.fa-torii-gate::after, .fa-duotone.fa-torii-gate::after {
  content: "\f6a1\f6a1";
}

.fad.fa-cloud-exclamation::after, .fa-duotone.fa-cloud-exclamation::after {
  content: "\e491\e491";
}

.fad.fa-message-lines::after, .fa-duotone.fa-message-lines::after {
  content: "\f4a6\f4a6";
}

.fad.fa-comment-alt-lines::after, .fa-duotone.fa-comment-alt-lines::after {
  content: "\f4a6\f4a6";
}

.fad.fa-frog::after, .fa-duotone.fa-frog::after {
  content: "\f52e\f52e";
}

.fad.fa-bucket::after, .fa-duotone.fa-bucket::after {
  content: "\e4cf\e4cf";
}

.fad.fa-floppy-disk-pen::after, .fa-duotone.fa-floppy-disk-pen::after {
  content: "\e182\e182";
}

.fad.fa-image::after, .fa-duotone.fa-image::after {
  content: "\f03e\f03e";
}

.fad.fa-window-frame::after, .fa-duotone.fa-window-frame::after {
  content: "\e04f\e04f";
}

.fad.fa-microphone::after, .fa-duotone.fa-microphone::after {
  content: "\f130\f130";
}

.fad.fa-cow::after, .fa-duotone.fa-cow::after {
  content: "\f6c8\f6c8";
}

.fad.fa-square-ring::after, .fa-duotone.fa-square-ring::after {
  content: "\e44f\e44f";
}

.fad.fa-down-from-line::after, .fa-duotone.fa-down-from-line::after {
  content: "\f349\f349";
}

.fad.fa-arrow-alt-from-top::after, .fa-duotone.fa-arrow-alt-from-top::after {
  content: "\f349\f349";
}

.fad.fa-caret-up::after, .fa-duotone.fa-caret-up::after {
  content: "\f0d8\f0d8";
}

.fad.fa-shield-xmark::after, .fa-duotone.fa-shield-xmark::after {
  content: "\e24c\e24c";
}

.fad.fa-shield-times::after, .fa-duotone.fa-shield-times::after {
  content: "\e24c\e24c";
}

.fad.fa-screwdriver::after, .fa-duotone.fa-screwdriver::after {
  content: "\f54a\f54a";
}

.fad.fa-circle-sort-down::after, .fa-duotone.fa-circle-sort-down::after {
  content: "\e031\e031";
}

.fad.fa-sort-circle-down::after, .fa-duotone.fa-sort-circle-down::after {
  content: "\e031\e031";
}

.fad.fa-folder-closed::after, .fa-duotone.fa-folder-closed::after {
  content: "\e185\e185";
}

.fad.fa-house-tsunami::after, .fa-duotone.fa-house-tsunami::after {
  content: "\e515\e515";
}

.fad.fa-square-nfi::after, .fa-duotone.fa-square-nfi::after {
  content: "\e576\e576";
}

.fad.fa-forklift::after, .fa-duotone.fa-forklift::after {
  content: "\f47a\f47a";
}

.fad.fa-arrow-up-from-ground-water::after, .fa-duotone.fa-arrow-up-from-ground-water::after {
  content: "\e4b5\e4b5";
}

.fad.fa-bracket-square-right::after, .fa-duotone.fa-bracket-square-right::after {
  content: "]]";
}

.fad.fa-martini-glass::after, .fa-duotone.fa-martini-glass::after {
  content: "\f57b\f57b";
}

.fad.fa-glass-martini-alt::after, .fa-duotone.fa-glass-martini-alt::after {
  content: "\f57b\f57b";
}

.fad.fa-rotate-left::after, .fa-duotone.fa-rotate-left::after {
  content: "\f2ea\f2ea";
}

.fad.fa-rotate-back::after, .fa-duotone.fa-rotate-back::after {
  content: "\f2ea\f2ea";
}

.fad.fa-rotate-backward::after, .fa-duotone.fa-rotate-backward::after {
  content: "\f2ea\f2ea";
}

.fad.fa-undo-alt::after, .fa-duotone.fa-undo-alt::after {
  content: "\f2ea\f2ea";
}

.fad.fa-table-columns::after, .fa-duotone.fa-table-columns::after {
  content: "\f0db\f0db";
}

.fad.fa-columns::after, .fa-duotone.fa-columns::after {
  content: "\f0db\f0db";
}

.fad.fa-square-a::after, .fa-duotone.fa-square-a::after {
  content: "\e25f\e25f";
}

.fad.fa-tick::after, .fa-duotone.fa-tick::after {
  content: "\e32f\e32f";
}

.fad.fa-lemon::after, .fa-duotone.fa-lemon::after {
  content: "\f094\f094";
}

.fad.fa-head-side-mask::after, .fa-duotone.fa-head-side-mask::after {
  content: "\e063\e063";
}

.fad.fa-handshake::after, .fa-duotone.fa-handshake::after {
  content: "\f2b5\f2b5";
}

.fad.fa-gem::after, .fa-duotone.fa-gem::after {
  content: "\f3a5\f3a5";
}

.fad.fa-dolly::after, .fa-duotone.fa-dolly::after {
  content: "\f472\f472";
}

.fad.fa-dolly-box::after, .fa-duotone.fa-dolly-box::after {
  content: "\f472\f472";
}

.fad.fa-smoking::after, .fa-duotone.fa-smoking::after {
  content: "\f48d\f48d";
}

.fad.fa-minimize::after, .fa-duotone.fa-minimize::after {
  content: "\f78c\f78c";
}

.fad.fa-compress-arrows-alt::after, .fa-duotone.fa-compress-arrows-alt::after {
  content: "\f78c\f78c";
}

.fad.fa-refrigerator::after, .fa-duotone.fa-refrigerator::after {
  content: "\e026\e026";
}

.fad.fa-monument::after, .fa-duotone.fa-monument::after {
  content: "\f5a6\f5a6";
}

.fad.fa-octagon-xmark::after, .fa-duotone.fa-octagon-xmark::after {
  content: "\f2f0\f2f0";
}

.fad.fa-times-octagon::after, .fa-duotone.fa-times-octagon::after {
  content: "\f2f0\f2f0";
}

.fad.fa-xmark-octagon::after, .fa-duotone.fa-xmark-octagon::after {
  content: "\f2f0\f2f0";
}

.fad.fa-align-slash::after, .fa-duotone.fa-align-slash::after {
  content: "\f846\f846";
}

.fad.fa-snowplow::after, .fa-duotone.fa-snowplow::after {
  content: "\f7d2\f7d2";
}

.fad.fa-angles-right::after, .fa-duotone.fa-angles-right::after {
  content: "\f101\f101";
}

.fad.fa-angle-double-right::after, .fa-duotone.fa-angle-double-right::after {
  content: "\f101\f101";
}

.fad.fa-truck-ramp-couch::after, .fa-duotone.fa-truck-ramp-couch::after {
  content: "\f4dd\f4dd";
}

.fad.fa-truck-couch::after, .fa-duotone.fa-truck-couch::after {
  content: "\f4dd\f4dd";
}

.fad.fa-cannabis::after, .fa-duotone.fa-cannabis::after {
  content: "\f55f\f55f";
}

.fad.fa-circle-play::after, .fa-duotone.fa-circle-play::after {
  content: "\f144\f144";
}

.fad.fa-play-circle::after, .fa-duotone.fa-play-circle::after {
  content: "\f144\f144";
}

.fad.fa-arrow-up-right-and-arrow-down-left-from-center::after, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center::after {
  content: "\e0a0\e0a0";
}

.fad.fa-tablets::after, .fa-duotone.fa-tablets::after {
  content: "\f490\f490";
}

.fad.fa-360-degrees::after, .fa-duotone.fa-360-degrees::after {
  content: "\e2dc\e2dc";
}

.fad.fa-ethernet::after, .fa-duotone.fa-ethernet::after {
  content: "\f796\f796";
}

.fad.fa-euro-sign::after, .fa-duotone.fa-euro-sign::after {
  content: "\f153\f153";
}

.fad.fa-eur::after, .fa-duotone.fa-eur::after {
  content: "\f153\f153";
}

.fad.fa-euro::after, .fa-duotone.fa-euro::after {
  content: "\f153\f153";
}

.fad.fa-chair::after, .fa-duotone.fa-chair::after {
  content: "\f6c0\f6c0";
}

.fad.fa-circle-check::after, .fa-duotone.fa-circle-check::after {
  content: "\f058\f058";
}

.fad.fa-check-circle::after, .fa-duotone.fa-check-circle::after {
  content: "\f058\f058";
}

.fad.fa-money-simple-from-bracket::after, .fa-duotone.fa-money-simple-from-bracket::after {
  content: "\e313\e313";
}

.fad.fa-bat::after, .fa-duotone.fa-bat::after {
  content: "\f6b5\f6b5";
}

.fad.fa-circle-stop::after, .fa-duotone.fa-circle-stop::after {
  content: "\f28d\f28d";
}

.fad.fa-stop-circle::after, .fa-duotone.fa-stop-circle::after {
  content: "\f28d\f28d";
}

.fad.fa-head-side-headphones::after, .fa-duotone.fa-head-side-headphones::after {
  content: "\f8c2\f8c2";
}

.fad.fa-phone-rotary::after, .fa-duotone.fa-phone-rotary::after {
  content: "\f8d3\f8d3";
}

.fad.fa-compass-drafting::after, .fa-duotone.fa-compass-drafting::after {
  content: "\f568\f568";
}

.fad.fa-drafting-compass::after, .fa-duotone.fa-drafting-compass::after {
  content: "\f568\f568";
}

.fad.fa-plate-wheat::after, .fa-duotone.fa-plate-wheat::after {
  content: "\e55a\e55a";
}

.fad.fa-calendar-circle-minus::after, .fa-duotone.fa-calendar-circle-minus::after {
  content: "\e46f\e46f";
}

.fad.fa-chopsticks::after, .fa-duotone.fa-chopsticks::after {
  content: "\e3f7\e3f7";
}

.fad.fa-car-wrench::after, .fa-duotone.fa-car-wrench::after {
  content: "\f5e3\f5e3";
}

.fad.fa-car-mechanic::after, .fa-duotone.fa-car-mechanic::after {
  content: "\f5e3\f5e3";
}

.fad.fa-icicles::after, .fa-duotone.fa-icicles::after {
  content: "\f7ad\f7ad";
}

.fad.fa-person-shelter::after, .fa-duotone.fa-person-shelter::after {
  content: "\e54f\e54f";
}

.fad.fa-neuter::after, .fa-duotone.fa-neuter::after {
  content: "\f22c\f22c";
}

.fad.fa-id-badge::after, .fa-duotone.fa-id-badge::after {
  content: "\f2c1\f2c1";
}

.fad.fa-kazoo::after, .fa-duotone.fa-kazoo::after {
  content: "\f8c7\f8c7";
}

.fad.fa-marker::after, .fa-duotone.fa-marker::after {
  content: "\f5a1\f5a1";
}

.fad.fa-face-laugh-beam::after, .fa-duotone.fa-face-laugh-beam::after {
  content: "\f59a\f59a";
}

.fad.fa-laugh-beam::after, .fa-duotone.fa-laugh-beam::after {
  content: "\f59a\f59a";
}

.fad.fa-square-arrow-down-left::after, .fa-duotone.fa-square-arrow-down-left::after {
  content: "\e261\e261";
}

.fad.fa-battery-bolt::after, .fa-duotone.fa-battery-bolt::after {
  content: "\f376\f376";
}

.fad.fa-tree-large::after, .fa-duotone.fa-tree-large::after {
  content: "\f7dd\f7dd";
}

.fad.fa-helicopter-symbol::after, .fa-duotone.fa-helicopter-symbol::after {
  content: "\e502\e502";
}

.fad.fa-aperture::after, .fa-duotone.fa-aperture::after {
  content: "\e2df\e2df";
}

.fad.fa-universal-access::after, .fa-duotone.fa-universal-access::after {
  content: "\f29a\f29a";
}

.fad.fa-file-magnifying-glass::after, .fa-duotone.fa-file-magnifying-glass::after {
  content: "\f865\f865";
}

.fad.fa-file-search::after, .fa-duotone.fa-file-search::after {
  content: "\f865\f865";
}

.fad.fa-up-right::after, .fa-duotone.fa-up-right::after {
  content: "\e2be\e2be";
}

.fad.fa-circle-chevron-up::after, .fa-duotone.fa-circle-chevron-up::after {
  content: "\f139\f139";
}

.fad.fa-chevron-circle-up::after, .fa-duotone.fa-chevron-circle-up::after {
  content: "\f139\f139";
}

.fad.fa-user-police::after, .fa-duotone.fa-user-police::after {
  content: "\e333\e333";
}

.fad.fa-lari-sign::after, .fa-duotone.fa-lari-sign::after {
  content: "\e1c8\e1c8";
}

.fad.fa-volcano::after, .fa-duotone.fa-volcano::after {
  content: "\f770\f770";
}

.fad.fa-teddy-bear::after, .fa-duotone.fa-teddy-bear::after {
  content: "\e3cf\e3cf";
}

.fad.fa-stocking::after, .fa-duotone.fa-stocking::after {
  content: "\f7d5\f7d5";
}

.fad.fa-person-walking-dashed-line-arrow-right::after, .fa-duotone.fa-person-walking-dashed-line-arrow-right::after {
  content: "\e553\e553";
}

.fad.fa-image-slash::after, .fa-duotone.fa-image-slash::after {
  content: "\e1b7\e1b7";
}

.fad.fa-mask-snorkel::after, .fa-duotone.fa-mask-snorkel::after {
  content: "\e3b7\e3b7";
}

.fad.fa-smoke::after, .fa-duotone.fa-smoke::after {
  content: "\f760\f760";
}

.fad.fa-sterling-sign::after, .fa-duotone.fa-sterling-sign::after {
  content: "\f154\f154";
}

.fad.fa-gbp::after, .fa-duotone.fa-gbp::after {
  content: "\f154\f154";
}

.fad.fa-pound-sign::after, .fa-duotone.fa-pound-sign::after {
  content: "\f154\f154";
}

.fad.fa-battery-exclamation::after, .fa-duotone.fa-battery-exclamation::after {
  content: "\e0b0\e0b0";
}

.fad.fa-viruses::after, .fa-duotone.fa-viruses::after {
  content: "\e076\e076";
}

.fad.fa-square-person-confined::after, .fa-duotone.fa-square-person-confined::after {
  content: "\e577\e577";
}

.fad.fa-user-tie::after, .fa-duotone.fa-user-tie::after {
  content: "\f508\f508";
}

.fad.fa-arrow-down-long::after, .fa-duotone.fa-arrow-down-long::after {
  content: "\f175\f175";
}

.fad.fa-long-arrow-down::after, .fa-duotone.fa-long-arrow-down::after {
  content: "\f175\f175";
}

.fad.fa-tent-arrow-down-to-line::after, .fa-duotone.fa-tent-arrow-down-to-line::after {
  content: "\e57e\e57e";
}

.fad.fa-certificate::after, .fa-duotone.fa-certificate::after {
  content: "\f0a3\f0a3";
}

.fad.fa-crystal-ball::after, .fa-duotone.fa-crystal-ball::after {
  content: "\e362\e362";
}

.fad.fa-reply-all::after, .fa-duotone.fa-reply-all::after {
  content: "\f122\f122";
}

.fad.fa-mail-reply-all::after, .fa-duotone.fa-mail-reply-all::after {
  content: "\f122\f122";
}

.fad.fa-suitcase::after, .fa-duotone.fa-suitcase::after {
  content: "\f0f2\f0f2";
}

.fad.fa-person-skating::after, .fa-duotone.fa-person-skating::after {
  content: "\f7c5\f7c5";
}

.fad.fa-skating::after, .fa-duotone.fa-skating::after {
  content: "\f7c5\f7c5";
}

.fad.fa-star-shooting::after, .fa-duotone.fa-star-shooting::after {
  content: "\e036\e036";
}

.fad.fa-binary-lock::after, .fa-duotone.fa-binary-lock::after {
  content: "\e33d\e33d";
}

.fad.fa-filter-circle-dollar::after, .fa-duotone.fa-filter-circle-dollar::after {
  content: "\f662\f662";
}

.fad.fa-funnel-dollar::after, .fa-duotone.fa-funnel-dollar::after {
  content: "\f662\f662";
}

.fad.fa-camera-retro::after, .fa-duotone.fa-camera-retro::after {
  content: "\f083\f083";
}

.fad.fa-circle-arrow-down::after, .fa-duotone.fa-circle-arrow-down::after {
  content: "\f0ab\f0ab";
}

.fad.fa-arrow-circle-down::after, .fa-duotone.fa-arrow-circle-down::after {
  content: "\f0ab\f0ab";
}

.fad.fa-comment-pen::after, .fa-duotone.fa-comment-pen::after {
  content: "\f4ae\f4ae";
}

.fad.fa-comment-edit::after, .fa-duotone.fa-comment-edit::after {
  content: "\f4ae\f4ae";
}

.fad.fa-file-import::after, .fa-duotone.fa-file-import::after {
  content: "\f56f\f56f";
}

.fad.fa-arrow-right-to-file::after, .fa-duotone.fa-arrow-right-to-file::after {
  content: "\f56f\f56f";
}

.fad.fa-banjo::after, .fa-duotone.fa-banjo::after {
  content: "\f8a3\f8a3";
}

.fad.fa-square-arrow-up-right::after, .fa-duotone.fa-square-arrow-up-right::after {
  content: "\f14c\f14c";
}

.fad.fa-external-link-square::after, .fa-duotone.fa-external-link-square::after {
  content: "\f14c\f14c";
}

.fad.fa-light-emergency-on::after, .fa-duotone.fa-light-emergency-on::after {
  content: "\e420\e420";
}

.fad.fa-kerning::after, .fa-duotone.fa-kerning::after {
  content: "\f86f\f86f";
}

.fad.fa-box-open::after, .fa-duotone.fa-box-open::after {
  content: "\f49e\f49e";
}

.fad.fa-square-f::after, .fa-duotone.fa-square-f::after {
  content: "\e270\e270";
}

.fad.fa-scroll::after, .fa-duotone.fa-scroll::after {
  content: "\f70e\f70e";
}

.fad.fa-spa::after, .fa-duotone.fa-spa::after {
  content: "\f5bb\f5bb";
}

.fad.fa-arrow-left-from-line::after, .fa-duotone.fa-arrow-left-from-line::after {
  content: "\f344\f344";
}

.fad.fa-arrow-from-right::after, .fa-duotone.fa-arrow-from-right::after {
  content: "\f344\f344";
}

.fad.fa-strawberry::after, .fa-duotone.fa-strawberry::after {
  content: "\e32b\e32b";
}

.fad.fa-location-pin-lock::after, .fa-duotone.fa-location-pin-lock::after {
  content: "\e51f\e51f";
}

.fad.fa-pause::after, .fa-duotone.fa-pause::after {
  content: "\f04c\f04c";
}

.fad.fa-clock-eight-thirty::after, .fa-duotone.fa-clock-eight-thirty::after {
  content: "\e346\e346";
}

.fad.fa-plane-engines::after, .fa-duotone.fa-plane-engines::after {
  content: "\f3de\f3de";
}

.fad.fa-plane-alt::after, .fa-duotone.fa-plane-alt::after {
  content: "\f3de\f3de";
}

.fad.fa-hill-avalanche::after, .fa-duotone.fa-hill-avalanche::after {
  content: "\e507\e507";
}

.fad.fa-temperature-empty::after, .fa-duotone.fa-temperature-empty::after {
  content: "\f2cb\f2cb";
}

.fad.fa-temperature-0::after, .fa-duotone.fa-temperature-0::after {
  content: "\f2cb\f2cb";
}

.fad.fa-thermometer-0::after, .fa-duotone.fa-thermometer-0::after {
  content: "\f2cb\f2cb";
}

.fad.fa-thermometer-empty::after, .fa-duotone.fa-thermometer-empty::after {
  content: "\f2cb\f2cb";
}

.fad.fa-bomb::after, .fa-duotone.fa-bomb::after {
  content: "\f1e2\f1e2";
}

.fad.fa-gauge-low::after, .fa-duotone.fa-gauge-low::after {
  content: "\f627\f627";
}

.fad.fa-tachometer-alt-slow::after, .fa-duotone.fa-tachometer-alt-slow::after {
  content: "\f627\f627";
}

.fad.fa-registered::after, .fa-duotone.fa-registered::after {
  content: "\f25d\f25d";
}

.fad.fa-trash-can-plus::after, .fa-duotone.fa-trash-can-plus::after {
  content: "\e2ac\e2ac";
}

.fad.fa-address-card::after, .fa-duotone.fa-address-card::after {
  content: "\f2bb\f2bb";
}

.fad.fa-contact-card::after, .fa-duotone.fa-contact-card::after {
  content: "\f2bb\f2bb";
}

.fad.fa-vcard::after, .fa-duotone.fa-vcard::after {
  content: "\f2bb\f2bb";
}

.fad.fa-scale-unbalanced-flip::after, .fa-duotone.fa-scale-unbalanced-flip::after {
  content: "\f516\f516";
}

.fad.fa-balance-scale-right::after, .fa-duotone.fa-balance-scale-right::after {
  content: "\f516\f516";
}

.fad.fa-globe-snow::after, .fa-duotone.fa-globe-snow::after {
  content: "\f7a3\f7a3";
}

.fad.fa-subscript::after, .fa-duotone.fa-subscript::after {
  content: "\f12c\f12c";
}

.fad.fa-diamond-turn-right::after, .fa-duotone.fa-diamond-turn-right::after {
  content: "\f5eb\f5eb";
}

.fad.fa-directions::after, .fa-duotone.fa-directions::after {
  content: "\f5eb\f5eb";
}

.fad.fa-integral::after, .fa-duotone.fa-integral::after {
  content: "\f667\f667";
}

.fad.fa-burst::after, .fa-duotone.fa-burst::after {
  content: "\e4dc\e4dc";
}

.fad.fa-house-laptop::after, .fa-duotone.fa-house-laptop::after {
  content: "\e066\e066";
}

.fad.fa-laptop-house::after, .fa-duotone.fa-laptop-house::after {
  content: "\e066\e066";
}

.fad.fa-face-tired::after, .fa-duotone.fa-face-tired::after {
  content: "\f5c8\f5c8";
}

.fad.fa-tired::after, .fa-duotone.fa-tired::after {
  content: "\f5c8\f5c8";
}

.fad.fa-money-bills::after, .fa-duotone.fa-money-bills::after {
  content: "\e1f3\e1f3";
}

.fad.fa-blinds-raised::after, .fa-duotone.fa-blinds-raised::after {
  content: "\f8fd\f8fd";
}

.fad.fa-smog::after, .fa-duotone.fa-smog::after {
  content: "\f75f\f75f";
}

.fad.fa-ufo-beam::after, .fa-duotone.fa-ufo-beam::after {
  content: "\e048\e048";
}

.fad.fa-circle-caret-up::after, .fa-duotone.fa-circle-caret-up::after {
  content: "\f331\f331";
}

.fad.fa-caret-circle-up::after, .fa-duotone.fa-caret-circle-up::after {
  content: "\f331\f331";
}

.fad.fa-user-vneck-hair-long::after, .fa-duotone.fa-user-vneck-hair-long::after {
  content: "\e463\e463";
}

.fad.fa-square-a-lock::after, .fa-duotone.fa-square-a-lock::after {
  content: "\e44d\e44d";
}

.fad.fa-crutch::after, .fa-duotone.fa-crutch::after {
  content: "\f7f7\f7f7";
}

.fad.fa-gas-pump-slash::after, .fa-duotone.fa-gas-pump-slash::after {
  content: "\f5f4\f5f4";
}

.fad.fa-cloud-arrow-up::after, .fa-duotone.fa-cloud-arrow-up::after {
  content: "\f0ee\f0ee";
}

.fad.fa-cloud-upload::after, .fa-duotone.fa-cloud-upload::after {
  content: "\f0ee\f0ee";
}

.fad.fa-cloud-upload-alt::after, .fa-duotone.fa-cloud-upload-alt::after {
  content: "\f0ee\f0ee";
}

.fad.fa-palette::after, .fa-duotone.fa-palette::after {
  content: "\f53f\f53f";
}

.fad.fa-transporter-4::after, .fa-duotone.fa-transporter-4::after {
  content: "\e2a5\e2a5";
}

.fad.fa-objects-align-right::after, .fa-duotone.fa-objects-align-right::after {
  content: "\e3bf\e3bf";
}

.fad.fa-arrows-turn-right::after, .fa-duotone.fa-arrows-turn-right::after {
  content: "\e4c0\e4c0";
}

.fad.fa-vest::after, .fa-duotone.fa-vest::after {
  content: "\e085\e085";
}

.fad.fa-pig::after, .fa-duotone.fa-pig::after {
  content: "\f706\f706";
}

.fad.fa-inbox-full::after, .fa-duotone.fa-inbox-full::after {
  content: "\e1ba\e1ba";
}

.fad.fa-circle-envelope::after, .fa-duotone.fa-circle-envelope::after {
  content: "\e10c\e10c";
}

.fad.fa-envelope-circle::after, .fa-duotone.fa-envelope-circle::after {
  content: "\e10c\e10c";
}

.fad.fa-triangle-person-digging::after, .fa-duotone.fa-triangle-person-digging::after {
  content: "\f85d\f85d";
}

.fad.fa-construction::after, .fa-duotone.fa-construction::after {
  content: "\f85d\f85d";
}

.fad.fa-ferry::after, .fa-duotone.fa-ferry::after {
  content: "\e4ea\e4ea";
}

.fad.fa-bullseye-arrow::after, .fa-duotone.fa-bullseye-arrow::after {
  content: "\f648\f648";
}

.fad.fa-arrows-down-to-people::after, .fa-duotone.fa-arrows-down-to-people::after {
  content: "\e4b9\e4b9";
}

.fad.fa-seedling::after, .fa-duotone.fa-seedling::after {
  content: "\f4d8\f4d8";
}

.fad.fa-sprout::after, .fa-duotone.fa-sprout::after {
  content: "\f4d8\f4d8";
}

.fad.fa-clock-seven::after, .fa-duotone.fa-clock-seven::after {
  content: "\e350\e350";
}

.fad.fa-left-right::after, .fa-duotone.fa-left-right::after {
  content: "\f337\f337";
}

.fad.fa-arrows-alt-h::after, .fa-duotone.fa-arrows-alt-h::after {
  content: "\f337\f337";
}

.fad.fa-boxes-packing::after, .fa-duotone.fa-boxes-packing::after {
  content: "\e4c7\e4c7";
}

.fad.fa-circle-arrow-left::after, .fa-duotone.fa-circle-arrow-left::after {
  content: "\f0a8\f0a8";
}

.fad.fa-arrow-circle-left::after, .fa-duotone.fa-arrow-circle-left::after {
  content: "\f0a8\f0a8";
}

.fad.fa-flashlight::after, .fa-duotone.fa-flashlight::after {
  content: "\f8b8\f8b8";
}

.fad.fa-group-arrows-rotate::after, .fa-duotone.fa-group-arrows-rotate::after {
  content: "\e4f6\e4f6";
}

.fad.fa-bowl-food::after, .fa-duotone.fa-bowl-food::after {
  content: "\e4c6\e4c6";
}

.fad.fa-square-9::after, .fa-duotone.fa-square-9::after {
  content: "\e25e\e25e";
}

.fad.fa-candy-cane::after, .fa-duotone.fa-candy-cane::after {
  content: "\f786\f786";
}

.fad.fa-arrow-down-wide-short::after, .fa-duotone.fa-arrow-down-wide-short::after {
  content: "\f160\f160";
}

.fad.fa-sort-amount-asc::after, .fa-duotone.fa-sort-amount-asc::after {
  content: "\f160\f160";
}

.fad.fa-sort-amount-down::after, .fa-duotone.fa-sort-amount-down::after {
  content: "\f160\f160";
}

.fad.fa-square-dollar::after, .fa-duotone.fa-square-dollar::after {
  content: "\f2e9\f2e9";
}

.fad.fa-dollar-square::after, .fa-duotone.fa-dollar-square::after {
  content: "\f2e9\f2e9";
}

.fad.fa-usd-square::after, .fa-duotone.fa-usd-square::after {
  content: "\f2e9\f2e9";
}

.fad.fa-hand-holding-seedling::after, .fa-duotone.fa-hand-holding-seedling::after {
  content: "\f4bf\f4bf";
}

.fad.fa-message-check::after, .fa-duotone.fa-message-check::after {
  content: "\f4a2\f4a2";
}

.fad.fa-comment-alt-check::after, .fa-duotone.fa-comment-alt-check::after {
  content: "\f4a2\f4a2";
}

.fad.fa-cloud-bolt::after, .fa-duotone.fa-cloud-bolt::after {
  content: "\f76c\f76c";
}

.fad.fa-thunderstorm::after, .fa-duotone.fa-thunderstorm::after {
  content: "\f76c\f76c";
}

.fad.fa-text-slash::after, .fa-duotone.fa-text-slash::after {
  content: "\f87d\f87d";
}

.fad.fa-remove-format::after, .fa-duotone.fa-remove-format::after {
  content: "\f87d\f87d";
}

.fad.fa-watch::after, .fa-duotone.fa-watch::after {
  content: "\f2e1\f2e1";
}

.fad.fa-circle-down-left::after, .fa-duotone.fa-circle-down-left::after {
  content: "\e107\e107";
}

.fad.fa-text::after, .fa-duotone.fa-text::after {
  content: "\f893\f893";
}

.fad.fa-projector::after, .fa-duotone.fa-projector::after {
  content: "\f8d6\f8d6";
}

.fad.fa-face-smile-wink::after, .fa-duotone.fa-face-smile-wink::after {
  content: "\f4da\f4da";
}

.fad.fa-smile-wink::after, .fa-duotone.fa-smile-wink::after {
  content: "\f4da\f4da";
}

.fad.fa-tombstone-blank::after, .fa-duotone.fa-tombstone-blank::after {
  content: "\f721\f721";
}

.fad.fa-tombstone-alt::after, .fa-duotone.fa-tombstone-alt::after {
  content: "\f721\f721";
}

.fad.fa-chess-king-piece::after, .fa-duotone.fa-chess-king-piece::after {
  content: "\f440\f440";
}

.fad.fa-chess-king-alt::after, .fa-duotone.fa-chess-king-alt::after {
  content: "\f440\f440";
}

.fad.fa-circle-6::after, .fa-duotone.fa-circle-6::after {
  content: "\e0f3\e0f3";
}

.fad.fa-left::after, .fa-duotone.fa-left::after {
  content: "\f355\f355";
}

.fad.fa-arrow-alt-left::after, .fa-duotone.fa-arrow-alt-left::after {
  content: "\f355\f355";
}

.fad.fa-file-word::after, .fa-duotone.fa-file-word::after {
  content: "\f1c2\f1c2";
}

.fad.fa-file-powerpoint::after, .fa-duotone.fa-file-powerpoint::after {
  content: "\f1c4\f1c4";
}

.fad.fa-square-down::after, .fa-duotone.fa-square-down::after {
  content: "\f350\f350";
}

.fad.fa-arrow-alt-square-down::after, .fa-duotone.fa-arrow-alt-square-down::after {
  content: "\f350\f350";
}

.fad.fa-objects-align-center-vertical::after, .fa-duotone.fa-objects-align-center-vertical::after {
  content: "\e3bd\e3bd";
}

.fad.fa-arrows-left-right::after, .fa-duotone.fa-arrows-left-right::after {
  content: "\f07e\f07e";
}

.fad.fa-arrows-h::after, .fa-duotone.fa-arrows-h::after {
  content: "\f07e\f07e";
}

.fad.fa-house-lock::after, .fa-duotone.fa-house-lock::after {
  content: "\e510\e510";
}

.fad.fa-cloud-arrow-down::after, .fa-duotone.fa-cloud-arrow-down::after {
  content: "\f0ed\f0ed";
}

.fad.fa-cloud-download::after, .fa-duotone.fa-cloud-download::after {
  content: "\f0ed\f0ed";
}

.fad.fa-cloud-download-alt::after, .fa-duotone.fa-cloud-download-alt::after {
  content: "\f0ed\f0ed";
}

.fad.fa-wreath::after, .fa-duotone.fa-wreath::after {
  content: "\f7e2\f7e2";
}

.fad.fa-children::after, .fa-duotone.fa-children::after {
  content: "\e4e1\e4e1";
}

.fad.fa-meter-droplet::after, .fa-duotone.fa-meter-droplet::after {
  content: "\e1ea\e1ea";
}

.fad.fa-chalkboard::after, .fa-duotone.fa-chalkboard::after {
  content: "\f51b\f51b";
}

.fad.fa-blackboard::after, .fa-duotone.fa-blackboard::after {
  content: "\f51b\f51b";
}

.fad.fa-user-large-slash::after, .fa-duotone.fa-user-large-slash::after {
  content: "\f4fa\f4fa";
}

.fad.fa-user-alt-slash::after, .fa-duotone.fa-user-alt-slash::after {
  content: "\f4fa\f4fa";
}

.fad.fa-signal-strong::after, .fa-duotone.fa-signal-strong::after {
  content: "\f68f\f68f";
}

.fad.fa-signal-4::after, .fa-duotone.fa-signal-4::after {
  content: "\f68f\f68f";
}

.fad.fa-lollipop::after, .fa-duotone.fa-lollipop::after {
  content: "\e424\e424";
}

.fad.fa-lollypop::after, .fa-duotone.fa-lollypop::after {
  content: "\e424\e424";
}

.fad.fa-list-tree::after, .fa-duotone.fa-list-tree::after {
  content: "\e1d2\e1d2";
}

.fad.fa-envelope-open::after, .fa-duotone.fa-envelope-open::after {
  content: "\f2b6\f2b6";
}

.fad.fa-draw-circle::after, .fa-duotone.fa-draw-circle::after {
  content: "\f5ed\f5ed";
}

.fad.fa-cat-space::after, .fa-duotone.fa-cat-space::after {
  content: "\e001\e001";
}

.fad.fa-handshake-simple-slash::after, .fa-duotone.fa-handshake-simple-slash::after {
  content: "\e05f\e05f";
}

.fad.fa-handshake-alt-slash::after, .fa-duotone.fa-handshake-alt-slash::after {
  content: "\e05f\e05f";
}

.fad.fa-rabbit-running::after, .fa-duotone.fa-rabbit-running::after {
  content: "\f709\f709";
}

.fad.fa-rabbit-fast::after, .fa-duotone.fa-rabbit-fast::after {
  content: "\f709\f709";
}

.fad.fa-memo-pad::after, .fa-duotone.fa-memo-pad::after {
  content: "\e1da\e1da";
}

.fad.fa-mattress-pillow::after, .fa-duotone.fa-mattress-pillow::after {
  content: "\e525\e525";
}

.fad.fa-alarm-plus::after, .fa-duotone.fa-alarm-plus::after {
  content: "\f844\f844";
}

.fad.fa-alicorn::after, .fa-duotone.fa-alicorn::after {
  content: "\f6b0\f6b0";
}

.fad.fa-comment-question::after, .fa-duotone.fa-comment-question::after {
  content: "\e14b\e14b";
}

.fad.fa-gingerbread-man::after, .fa-duotone.fa-gingerbread-man::after {
  content: "\f79d\f79d";
}

.fad.fa-guarani-sign::after, .fa-duotone.fa-guarani-sign::after {
  content: "\e19a\e19a";
}

.fad.fa-burger-fries::after, .fa-duotone.fa-burger-fries::after {
  content: "\e0cd\e0cd";
}

.fad.fa-mug-tea::after, .fa-duotone.fa-mug-tea::after {
  content: "\f875\f875";
}

.fad.fa-border-top::after, .fa-duotone.fa-border-top::after {
  content: "\f855\f855";
}

.fad.fa-arrows-rotate::after, .fa-duotone.fa-arrows-rotate::after {
  content: "\f021\f021";
}

.fad.fa-refresh::after, .fa-duotone.fa-refresh::after {
  content: "\f021\f021";
}

.fad.fa-sync::after, .fa-duotone.fa-sync::after {
  content: "\f021\f021";
}

.fad.fa-circle-book-open::after, .fa-duotone.fa-circle-book-open::after {
  content: "\e0ff\e0ff";
}

.fad.fa-book-circle::after, .fa-duotone.fa-book-circle::after {
  content: "\e0ff\e0ff";
}

.fad.fa-arrows-to-dotted-line::after, .fa-duotone.fa-arrows-to-dotted-line::after {
  content: "\e0a6\e0a6";
}

.fad.fa-fire-extinguisher::after, .fa-duotone.fa-fire-extinguisher::after {
  content: "\f134\f134";
}

.fad.fa-garage-open::after, .fa-duotone.fa-garage-open::after {
  content: "\e00b\e00b";
}

.fad.fa-shelves-empty::after, .fa-duotone.fa-shelves-empty::after {
  content: "\e246\e246";
}

.fad.fa-cruzeiro-sign::after, .fa-duotone.fa-cruzeiro-sign::after {
  content: "\e152\e152";
}

.fad.fa-watch-apple::after, .fa-duotone.fa-watch-apple::after {
  content: "\e2cb\e2cb";
}

.fad.fa-watch-calculator::after, .fa-duotone.fa-watch-calculator::after {
  content: "\f8f0\f8f0";
}

.fad.fa-list-dropdown::after, .fa-duotone.fa-list-dropdown::after {
  content: "\e1cf\e1cf";
}

.fad.fa-cabinet-filing::after, .fa-duotone.fa-cabinet-filing::after {
  content: "\f64b\f64b";
}

.fad.fa-burger-soda::after, .fa-duotone.fa-burger-soda::after {
  content: "\f858\f858";
}

.fad.fa-square-arrow-up::after, .fa-duotone.fa-square-arrow-up::after {
  content: "\f33c\f33c";
}

.fad.fa-arrow-square-up::after, .fa-duotone.fa-arrow-square-up::after {
  content: "\f33c\f33c";
}

.fad.fa-greater-than-equal::after, .fa-duotone.fa-greater-than-equal::after {
  content: "\f532\f532";
}

.fad.fa-pallet-box::after, .fa-duotone.fa-pallet-box::after {
  content: "\e208\e208";
}

.fad.fa-face-confounded::after, .fa-duotone.fa-face-confounded::after {
  content: "\e36c\e36c";
}

.fad.fa-shield-halved::after, .fa-duotone.fa-shield-halved::after {
  content: "\f3ed\f3ed";
}

.fad.fa-shield-alt::after, .fa-duotone.fa-shield-alt::after {
  content: "\f3ed\f3ed";
}

.fad.fa-truck-plow::after, .fa-duotone.fa-truck-plow::after {
  content: "\f7de\f7de";
}

.fad.fa-book-atlas::after, .fa-duotone.fa-book-atlas::after {
  content: "\f558\f558";
}

.fad.fa-atlas::after, .fa-duotone.fa-atlas::after {
  content: "\f558\f558";
}

.fad.fa-virus::after, .fa-duotone.fa-virus::after {
  content: "\e074\e074";
}

.fad.fa-comment-middle-top::after, .fa-duotone.fa-comment-middle-top::after {
  content: "\e14a\e14a";
}

.fad.fa-envelope-circle-check::after, .fa-duotone.fa-envelope-circle-check::after {
  content: "\e4e8\e4e8";
}

.fad.fa-layer-group::after, .fa-duotone.fa-layer-group::after {
  content: "\f5fd\f5fd";
}

.fad.fa-restroom-simple::after, .fa-duotone.fa-restroom-simple::after {
  content: "\e23a\e23a";
}

.fad.fa-arrows-to-dot::after, .fa-duotone.fa-arrows-to-dot::after {
  content: "\e4be\e4be";
}

.fad.fa-border-outer::after, .fa-duotone.fa-border-outer::after {
  content: "\f851\f851";
}

.fad.fa-hashtag-lock::after, .fa-duotone.fa-hashtag-lock::after {
  content: "\e415\e415";
}

.fad.fa-clock-two-thirty::after, .fa-duotone.fa-clock-two-thirty::after {
  content: "\e35b\e35b";
}

.fad.fa-archway::after, .fa-duotone.fa-archway::after {
  content: "\f557\f557";
}

.fad.fa-heart-circle-check::after, .fa-duotone.fa-heart-circle-check::after {
  content: "\e4fd\e4fd";
}

.fad.fa-house-chimney-crack::after, .fa-duotone.fa-house-chimney-crack::after {
  content: "\f6f1\f6f1";
}

.fad.fa-house-damage::after, .fa-duotone.fa-house-damage::after {
  content: "\f6f1\f6f1";
}

.fad.fa-file-zipper::after, .fa-duotone.fa-file-zipper::after {
  content: "\f1c6\f1c6";
}

.fad.fa-file-archive::after, .fa-duotone.fa-file-archive::after {
  content: "\f1c6\f1c6";
}

.fad.fa-heart-half::after, .fa-duotone.fa-heart-half::after {
  content: "\e1ab\e1ab";
}

.fad.fa-comment-check::after, .fa-duotone.fa-comment-check::after {
  content: "\f4ac\f4ac";
}

.fad.fa-square::after, .fa-duotone.fa-square::after {
  content: "\f0c8\f0c8";
}

.fad.fa-memo::after, .fa-duotone.fa-memo::after {
  content: "\e1d8\e1d8";
}

.fad.fa-martini-glass-empty::after, .fa-duotone.fa-martini-glass-empty::after {
  content: "\f000\f000";
}

.fad.fa-glass-martini::after, .fa-duotone.fa-glass-martini::after {
  content: "\f000\f000";
}

.fad.fa-couch::after, .fa-duotone.fa-couch::after {
  content: "\f4b8\f4b8";
}

.fad.fa-cedi-sign::after, .fa-duotone.fa-cedi-sign::after {
  content: "\e0df\e0df";
}

.fad.fa-italic::after, .fa-duotone.fa-italic::after {
  content: "\f033\f033";
}

.fad.fa-glass-citrus::after, .fa-duotone.fa-glass-citrus::after {
  content: "\f869\f869";
}

.fad.fa-calendar-lines-pen::after, .fa-duotone.fa-calendar-lines-pen::after {
  content: "\e472\e472";
}

.fad.fa-church::after, .fa-duotone.fa-church::after {
  content: "\f51d\f51d";
}

.fad.fa-person-snowmobiling::after, .fa-duotone.fa-person-snowmobiling::after {
  content: "\f7d1\f7d1";
}

.fad.fa-snowmobile::after, .fa-duotone.fa-snowmobile::after {
  content: "\f7d1\f7d1";
}

.fad.fa-face-hushed::after, .fa-duotone.fa-face-hushed::after {
  content: "\e37b\e37b";
}

.fad.fa-comments-dollar::after, .fa-duotone.fa-comments-dollar::after {
  content: "\f653\f653";
}

.fad.fa-link-simple-slash::after, .fa-duotone.fa-link-simple-slash::after {
  content: "\e1ce\e1ce";
}

.fad.fa-democrat::after, .fa-duotone.fa-democrat::after {
  content: "\f747\f747";
}

.fad.fa-face-confused::after, .fa-duotone.fa-face-confused::after {
  content: "\e36d\e36d";
}

.fad.fa-pinball::after, .fa-duotone.fa-pinball::after {
  content: "\e229\e229";
}

.fad.fa-z::after, .fa-duotone.fa-z::after {
  content: "ZZ";
}

.fad.fa-person-skiing::after, .fa-duotone.fa-person-skiing::after {
  content: "\f7c9\f7c9";
}

.fad.fa-skiing::after, .fa-duotone.fa-skiing::after {
  content: "\f7c9\f7c9";
}

.fad.fa-deer::after, .fa-duotone.fa-deer::after {
  content: "\f78e\f78e";
}

.fad.fa-input-pipe::after, .fa-duotone.fa-input-pipe::after {
  content: "\e1be\e1be";
}

.fad.fa-road-lock::after, .fa-duotone.fa-road-lock::after {
  content: "\e567\e567";
}

.fad.fa-a::after, .fa-duotone.fa-a::after {
  content: "AA";
}

.fad.fa-bookmark-slash::after, .fa-duotone.fa-bookmark-slash::after {
  content: "\e0c2\e0c2";
}

.fad.fa-temperature-arrow-down::after, .fa-duotone.fa-temperature-arrow-down::after {
  content: "\e03f\e03f";
}

.fad.fa-temperature-down::after, .fa-duotone.fa-temperature-down::after {
  content: "\e03f\e03f";
}

.fad.fa-mace::after, .fa-duotone.fa-mace::after {
  content: "\f6f8\f6f8";
}

.fad.fa-feather-pointed::after, .fa-duotone.fa-feather-pointed::after {
  content: "\f56b\f56b";
}

.fad.fa-feather-alt::after, .fa-duotone.fa-feather-alt::after {
  content: "\f56b\f56b";
}

.fad.fa-sausage::after, .fa-duotone.fa-sausage::after {
  content: "\f820\f820";
}

.fad.fa-trash-can-clock::after, .fa-duotone.fa-trash-can-clock::after {
  content: "\e2aa\e2aa";
}

.fad.fa-p::after, .fa-duotone.fa-p::after {
  content: "PP";
}

.fad.fa-snowflake::after, .fa-duotone.fa-snowflake::after {
  content: "\f2dc\f2dc";
}

.fad.fa-stomach::after, .fa-duotone.fa-stomach::after {
  content: "\f623\f623";
}

.fad.fa-newspaper::after, .fa-duotone.fa-newspaper::after {
  content: "\f1ea\f1ea";
}

.fad.fa-rectangle-ad::after, .fa-duotone.fa-rectangle-ad::after {
  content: "\f641\f641";
}

.fad.fa-ad::after, .fa-duotone.fa-ad::after {
  content: "\f641\f641";
}

.fad.fa-guitar-electric::after, .fa-duotone.fa-guitar-electric::after {
  content: "\f8be\f8be";
}

.fad.fa-arrow-turn-down-right::after, .fa-duotone.fa-arrow-turn-down-right::after {
  content: "\e3d6\e3d6";
}

.fad.fa-moon-cloud::after, .fa-duotone.fa-moon-cloud::after {
  content: "\f754\f754";
}

.fad.fa-bread-slice-butter::after, .fa-duotone.fa-bread-slice-butter::after {
  content: "\e3e1\e3e1";
}

.fad.fa-circle-arrow-right::after, .fa-duotone.fa-circle-arrow-right::after {
  content: "\f0a9\f0a9";
}

.fad.fa-arrow-circle-right::after, .fa-duotone.fa-arrow-circle-right::after {
  content: "\f0a9\f0a9";
}

.fad.fa-user-group-crown::after, .fa-duotone.fa-user-group-crown::after {
  content: "\f6a5\f6a5";
}

.fad.fa-users-crown::after, .fa-duotone.fa-users-crown::after {
  content: "\f6a5\f6a5";
}

.fad.fa-circle-i::after, .fa-duotone.fa-circle-i::after {
  content: "\e111\e111";
}

.fad.fa-toilet-paper-check::after, .fa-duotone.fa-toilet-paper-check::after {
  content: "\e5b2\e5b2";
}

.fad.fa-filter-circle-xmark::after, .fa-duotone.fa-filter-circle-xmark::after {
  content: "\e17b\e17b";
}

.fad.fa-locust::after, .fa-duotone.fa-locust::after {
  content: "\e520\e520";
}

.fad.fa-sort::after, .fa-duotone.fa-sort::after {
  content: "\f0dc\f0dc";
}

.fad.fa-unsorted::after, .fa-duotone.fa-unsorted::after {
  content: "\f0dc\f0dc";
}

.fad.fa-list-ol::after, .fa-duotone.fa-list-ol::after {
  content: "\f0cb\f0cb";
}

.fad.fa-list-1-2::after, .fa-duotone.fa-list-1-2::after {
  content: "\f0cb\f0cb";
}

.fad.fa-list-numeric::after, .fa-duotone.fa-list-numeric::after {
  content: "\f0cb\f0cb";
}

.fad.fa-chart-waterfall::after, .fa-duotone.fa-chart-waterfall::after {
  content: "\e0eb\e0eb";
}

.fad.fa-face-party::after, .fa-duotone.fa-face-party::after {
  content: "\e383\e383";
}

.fad.fa-kidneys::after, .fa-duotone.fa-kidneys::after {
  content: "\f5fb\f5fb";
}

.fad.fa-wifi-exclamation::after, .fa-duotone.fa-wifi-exclamation::after {
  content: "\e2cf\e2cf";
}

.fad.fa-chart-network::after, .fa-duotone.fa-chart-network::after {
  content: "\f78a\f78a";
}

.fad.fa-person-dress-burst::after, .fa-duotone.fa-person-dress-burst::after {
  content: "\e544\e544";
}

.fad.fa-dice-d4::after, .fa-duotone.fa-dice-d4::after {
  content: "\f6d0\f6d0";
}

.fad.fa-money-check-dollar::after, .fa-duotone.fa-money-check-dollar::after {
  content: "\f53d\f53d";
}

.fad.fa-money-check-alt::after, .fa-duotone.fa-money-check-alt::after {
  content: "\f53d\f53d";
}

.fad.fa-vector-square::after, .fa-duotone.fa-vector-square::after {
  content: "\f5cb\f5cb";
}

.fad.fa-bread-slice::after, .fa-duotone.fa-bread-slice::after {
  content: "\f7ec\f7ec";
}

.fad.fa-language::after, .fa-duotone.fa-language::after {
  content: "\f1ab\f1ab";
}

.fad.fa-wheat-awn-slash::after, .fa-duotone.fa-wheat-awn-slash::after {
  content: "\e338\e338";
}

.fad.fa-face-kiss-wink-heart::after, .fa-duotone.fa-face-kiss-wink-heart::after {
  content: "\f598\f598";
}

.fad.fa-kiss-wink-heart::after, .fa-duotone.fa-kiss-wink-heart::after {
  content: "\f598\f598";
}

.fad.fa-dagger::after, .fa-duotone.fa-dagger::after {
  content: "\f6cb\f6cb";
}

.fad.fa-podium::after, .fa-duotone.fa-podium::after {
  content: "\f680\f680";
}

.fad.fa-memo-circle-check::after, .fa-duotone.fa-memo-circle-check::after {
  content: "\e1d9\e1d9";
}

.fad.fa-route-highway::after, .fa-duotone.fa-route-highway::after {
  content: "\f61a\f61a";
}

.fad.fa-down-to-line::after, .fa-duotone.fa-down-to-line::after {
  content: "\f34a\f34a";
}

.fad.fa-arrow-alt-to-bottom::after, .fa-duotone.fa-arrow-alt-to-bottom::after {
  content: "\f34a\f34a";
}

.fad.fa-filter::after, .fa-duotone.fa-filter::after {
  content: "\f0b0\f0b0";
}

.fad.fa-square-g::after, .fa-duotone.fa-square-g::after {
  content: "\e271\e271";
}

.fad.fa-circle-phone::after, .fa-duotone.fa-circle-phone::after {
  content: "\e11b\e11b";
}

.fad.fa-phone-circle::after, .fa-duotone.fa-phone-circle::after {
  content: "\e11b\e11b";
}

.fad.fa-clipboard-prescription::after, .fa-duotone.fa-clipboard-prescription::after {
  content: "\f5e8\f5e8";
}

.fad.fa-user-nurse-hair::after, .fa-duotone.fa-user-nurse-hair::after {
  content: "\e45d\e45d";
}

.fad.fa-question::after, .fa-duotone.fa-question::after {
  content: "??";
}

.fad.fa-file-signature::after, .fa-duotone.fa-file-signature::after {
  content: "\f573\f573";
}

.fad.fa-toggle-large-on::after, .fa-duotone.fa-toggle-large-on::after {
  content: "\e5b1\e5b1";
}

.fad.fa-up-down-left-right::after, .fa-duotone.fa-up-down-left-right::after {
  content: "\f0b2\f0b2";
}

.fad.fa-arrows-alt::after, .fa-duotone.fa-arrows-alt::after {
  content: "\f0b2\f0b2";
}

.fad.fa-dryer-heat::after, .fa-duotone.fa-dryer-heat::after {
  content: "\f862\f862";
}

.fad.fa-dryer-alt::after, .fa-duotone.fa-dryer-alt::after {
  content: "\f862\f862";
}

.fad.fa-house-chimney-user::after, .fa-duotone.fa-house-chimney-user::after {
  content: "\e065\e065";
}

.fad.fa-hand-holding-heart::after, .fa-duotone.fa-hand-holding-heart::after {
  content: "\f4be\f4be";
}

.fad.fa-arrow-up-small-big::after, .fa-duotone.fa-arrow-up-small-big::after {
  content: "\f88f\f88f";
}

.fad.fa-sort-size-up-alt::after, .fa-duotone.fa-sort-size-up-alt::after {
  content: "\f88f\f88f";
}

.fad.fa-train-track::after, .fa-duotone.fa-train-track::after {
  content: "\e453\e453";
}

.fad.fa-puzzle-piece::after, .fa-duotone.fa-puzzle-piece::after {
  content: "\f12e\f12e";
}

.fad.fa-money-check::after, .fa-duotone.fa-money-check::after {
  content: "\f53c\f53c";
}

.fad.fa-star-half-stroke::after, .fa-duotone.fa-star-half-stroke::after {
  content: "\f5c0\f5c0";
}

.fad.fa-star-half-alt::after, .fa-duotone.fa-star-half-alt::after {
  content: "\f5c0\f5c0";
}

.fad.fa-file-exclamation::after, .fa-duotone.fa-file-exclamation::after {
  content: "\f31a\f31a";
}

.fad.fa-code::after, .fa-duotone.fa-code::after {
  content: "\f121\f121";
}

.fad.fa-whiskey-glass::after, .fa-duotone.fa-whiskey-glass::after {
  content: "\f7a0\f7a0";
}

.fad.fa-glass-whiskey::after, .fa-duotone.fa-glass-whiskey::after {
  content: "\f7a0\f7a0";
}

.fad.fa-moon-stars::after, .fa-duotone.fa-moon-stars::after {
  content: "\f755\f755";
}

.fad.fa-building-circle-exclamation::after, .fa-duotone.fa-building-circle-exclamation::after {
  content: "\e4d3\e4d3";
}

.fad.fa-clothes-hanger::after, .fa-duotone.fa-clothes-hanger::after {
  content: "\e136\e136";
}

.fad.fa-mobile-notch::after, .fa-duotone.fa-mobile-notch::after {
  content: "\e1ee\e1ee";
}

.fad.fa-mobile-iphone::after, .fa-duotone.fa-mobile-iphone::after {
  content: "\e1ee\e1ee";
}

.fad.fa-magnifying-glass-chart::after, .fa-duotone.fa-magnifying-glass-chart::after {
  content: "\e522\e522";
}

.fad.fa-arrow-up-right-from-square::after, .fa-duotone.fa-arrow-up-right-from-square::after {
  content: "\f08e\f08e";
}

.fad.fa-external-link::after, .fa-duotone.fa-external-link::after {
  content: "\f08e\f08e";
}

.fad.fa-cubes-stacked::after, .fa-duotone.fa-cubes-stacked::after {
  content: "\e4e6\e4e6";
}

.fad.fa-images-user::after, .fa-duotone.fa-images-user::after {
  content: "\e1b9\e1b9";
}

.fad.fa-won-sign::after, .fa-duotone.fa-won-sign::after {
  content: "\f159\f159";
}

.fad.fa-krw::after, .fa-duotone.fa-krw::after {
  content: "\f159\f159";
}

.fad.fa-won::after, .fa-duotone.fa-won::after {
  content: "\f159\f159";
}

.fad.fa-image-polaroid-user::after, .fa-duotone.fa-image-polaroid-user::after {
  content: "\e1b6\e1b6";
}

.fad.fa-virus-covid::after, .fa-duotone.fa-virus-covid::after {
  content: "\e4a8\e4a8";
}

.fad.fa-square-ellipsis::after, .fa-duotone.fa-square-ellipsis::after {
  content: "\e26e\e26e";
}

.fad.fa-pie::after, .fa-duotone.fa-pie::after {
  content: "\f705\f705";
}

.fad.fa-chess-knight-piece::after, .fa-duotone.fa-chess-knight-piece::after {
  content: "\f442\f442";
}

.fad.fa-chess-knight-alt::after, .fa-duotone.fa-chess-knight-alt::after {
  content: "\f442\f442";
}

.fad.fa-austral-sign::after, .fa-duotone.fa-austral-sign::after {
  content: "\e0a9\e0a9";
}

.fad.fa-cloud-plus::after, .fa-duotone.fa-cloud-plus::after {
  content: "\e35e\e35e";
}

.fad.fa-f::after, .fa-duotone.fa-f::after {
  content: "FF";
}

.fad.fa-leaf::after, .fa-duotone.fa-leaf::after {
  content: "\f06c\f06c";
}

.fad.fa-bed-bunk::after, .fa-duotone.fa-bed-bunk::after {
  content: "\f8f8\f8f8";
}

.fad.fa-road::after, .fa-duotone.fa-road::after {
  content: "\f018\f018";
}

.fad.fa-taxi::after, .fa-duotone.fa-taxi::after {
  content: "\f1ba\f1ba";
}

.fad.fa-cab::after, .fa-duotone.fa-cab::after {
  content: "\f1ba\f1ba";
}

.fad.fa-person-circle-plus::after, .fa-duotone.fa-person-circle-plus::after {
  content: "\e541\e541";
}

.fad.fa-chart-pie::after, .fa-duotone.fa-chart-pie::after {
  content: "\f200\f200";
}

.fad.fa-pie-chart::after, .fa-duotone.fa-pie-chart::after {
  content: "\f200\f200";
}

.fad.fa-bolt-lightning::after, .fa-duotone.fa-bolt-lightning::after {
  content: "\e0b7\e0b7";
}

.fad.fa-clock-eight::after, .fa-duotone.fa-clock-eight::after {
  content: "\e345\e345";
}

.fad.fa-sack-xmark::after, .fa-duotone.fa-sack-xmark::after {
  content: "\e56a\e56a";
}

.fad.fa-file-excel::after, .fa-duotone.fa-file-excel::after {
  content: "\f1c3\f1c3";
}

.fad.fa-file-contract::after, .fa-duotone.fa-file-contract::after {
  content: "\f56c\f56c";
}

.fad.fa-fish-fins::after, .fa-duotone.fa-fish-fins::after {
  content: "\e4f2\e4f2";
}

.fad.fa-circle-q::after, .fa-duotone.fa-circle-q::after {
  content: "\e11e\e11e";
}

.fad.fa-building-flag::after, .fa-duotone.fa-building-flag::after {
  content: "\e4d5\e4d5";
}

.fad.fa-face-grin-beam::after, .fa-duotone.fa-face-grin-beam::after {
  content: "\f582\f582";
}

.fad.fa-grin-beam::after, .fa-duotone.fa-grin-beam::after {
  content: "\f582\f582";
}

.fad.fa-object-ungroup::after, .fa-duotone.fa-object-ungroup::after {
  content: "\f248\f248";
}

.fad.fa-face-disguise::after, .fa-duotone.fa-face-disguise::after {
  content: "\e370\e370";
}

.fad.fa-circle-arrow-down-right::after, .fa-duotone.fa-circle-arrow-down-right::after {
  content: "\e0fa\e0fa";
}

.fad.fa-alien-8bit::after, .fa-duotone.fa-alien-8bit::after {
  content: "\f8f6\f8f6";
}

.fad.fa-alien-monster::after, .fa-duotone.fa-alien-monster::after {
  content: "\f8f6\f8f6";
}

.fad.fa-hand-point-ribbon::after, .fa-duotone.fa-hand-point-ribbon::after {
  content: "\e1a6\e1a6";
}

.fad.fa-poop::after, .fa-duotone.fa-poop::after {
  content: "\f619\f619";
}

.fad.fa-object-exclude::after, .fa-duotone.fa-object-exclude::after {
  content: "\e49c\e49c";
}

.fad.fa-telescope::after, .fa-duotone.fa-telescope::after {
  content: "\e03e\e03e";
}

.fad.fa-location-pin::after, .fa-duotone.fa-location-pin::after {
  content: "\f041\f041";
}

.fad.fa-map-marker::after, .fa-duotone.fa-map-marker::after {
  content: "\f041\f041";
}

.fad.fa-square-list::after, .fa-duotone.fa-square-list::after {
  content: "\e489\e489";
}

.fad.fa-kaaba::after, .fa-duotone.fa-kaaba::after {
  content: "\f66b\f66b";
}

.fad.fa-toilet-paper::after, .fa-duotone.fa-toilet-paper::after {
  content: "\f71e\f71e";
}

.fad.fa-helmet-safety::after, .fa-duotone.fa-helmet-safety::after {
  content: "\f807\f807";
}

.fad.fa-hard-hat::after, .fa-duotone.fa-hard-hat::after {
  content: "\f807\f807";
}

.fad.fa-hat-hard::after, .fa-duotone.fa-hat-hard::after {
  content: "\f807\f807";
}

.fad.fa-comment-code::after, .fa-duotone.fa-comment-code::after {
  content: "\e147\e147";
}

.fad.fa-sim-cards::after, .fa-duotone.fa-sim-cards::after {
  content: "\e251\e251";
}

.fad.fa-starship::after, .fa-duotone.fa-starship::after {
  content: "\e039\e039";
}

.fad.fa-eject::after, .fa-duotone.fa-eject::after {
  content: "\f052\f052";
}

.fad.fa-circle-right::after, .fa-duotone.fa-circle-right::after {
  content: "\f35a\f35a";
}

.fad.fa-arrow-alt-circle-right::after, .fa-duotone.fa-arrow-alt-circle-right::after {
  content: "\f35a\f35a";
}

.fad.fa-plane-circle-check::after, .fa-duotone.fa-plane-circle-check::after {
  content: "\e555\e555";
}

.fad.fa-seal::after, .fa-duotone.fa-seal::after {
  content: "\e241\e241";
}

.fad.fa-user-cowboy::after, .fa-duotone.fa-user-cowboy::after {
  content: "\f8ea\f8ea";
}

.fad.fa-hexagon-vertical-nft::after, .fa-duotone.fa-hexagon-vertical-nft::after {
  content: "\e505\e505";
}

.fad.fa-face-rolling-eyes::after, .fa-duotone.fa-face-rolling-eyes::after {
  content: "\f5a5\f5a5";
}

.fad.fa-meh-rolling-eyes::after, .fa-duotone.fa-meh-rolling-eyes::after {
  content: "\f5a5\f5a5";
}

.fad.fa-bread-loaf::after, .fa-duotone.fa-bread-loaf::after {
  content: "\f7eb\f7eb";
}

.fad.fa-rings-wedding::after, .fa-duotone.fa-rings-wedding::after {
  content: "\f81b\f81b";
}

.fad.fa-object-group::after, .fa-duotone.fa-object-group::after {
  content: "\f247\f247";
}

.fad.fa-french-fries::after, .fa-duotone.fa-french-fries::after {
  content: "\f803\f803";
}

.fad.fa-chart-line::after, .fa-duotone.fa-chart-line::after {
  content: "\f201\f201";
}

.fad.fa-line-chart::after, .fa-duotone.fa-line-chart::after {
  content: "\f201\f201";
}

.fad.fa-calendar-arrow-down::after, .fa-duotone.fa-calendar-arrow-down::after {
  content: "\e0d0\e0d0";
}

.fad.fa-calendar-download::after, .fa-duotone.fa-calendar-download::after {
  content: "\e0d0\e0d0";
}

.fad.fa-send-back::after, .fa-duotone.fa-send-back::after {
  content: "\f87e\f87e";
}

.fad.fa-mask-ventilator::after, .fa-duotone.fa-mask-ventilator::after {
  content: "\e524\e524";
}

.fad.fa-signature-lock::after, .fa-duotone.fa-signature-lock::after {
  content: "\e3ca\e3ca";
}

.fad.fa-arrow-right::after, .fa-duotone.fa-arrow-right::after {
  content: "\f061\f061";
}

.fad.fa-signs-post::after, .fa-duotone.fa-signs-post::after {
  content: "\f277\f277";
}

.fad.fa-map-signs::after, .fa-duotone.fa-map-signs::after {
  content: "\f277\f277";
}

.fad.fa-octagon-plus::after, .fa-duotone.fa-octagon-plus::after {
  content: "\f301\f301";
}

.fad.fa-plus-octagon::after, .fa-duotone.fa-plus-octagon::after {
  content: "\f301\f301";
}

.fad.fa-cash-register::after, .fa-duotone.fa-cash-register::after {
  content: "\f788\f788";
}

.fad.fa-person-circle-question::after, .fa-duotone.fa-person-circle-question::after {
  content: "\e542\e542";
}

.fad.fa-melon-slice::after, .fa-duotone.fa-melon-slice::after {
  content: "\e311\e311";
}

.fad.fa-space-station-moon::after, .fa-duotone.fa-space-station-moon::after {
  content: "\e033\e033";
}

.fad.fa-message-smile::after, .fa-duotone.fa-message-smile::after {
  content: "\f4aa\f4aa";
}

.fad.fa-comment-alt-smile::after, .fa-duotone.fa-comment-alt-smile::after {
  content: "\f4aa\f4aa";
}

.fad.fa-cup-straw::after, .fa-duotone.fa-cup-straw::after {
  content: "\e363\e363";
}

.fad.fa-left-from-line::after, .fa-duotone.fa-left-from-line::after {
  content: "\f348\f348";
}

.fad.fa-arrow-alt-from-right::after, .fa-duotone.fa-arrow-alt-from-right::after {
  content: "\f348\f348";
}

.fad.fa-h::after, .fa-duotone.fa-h::after {
  content: "HH";
}

.fad.fa-basket-shopping-simple::after, .fa-duotone.fa-basket-shopping-simple::after {
  content: "\e0af\e0af";
}

.fad.fa-shopping-basket-alt::after, .fa-duotone.fa-shopping-basket-alt::after {
  content: "\e0af\e0af";
}

.fad.fa-hands-holding-heart::after, .fa-duotone.fa-hands-holding-heart::after {
  content: "\f4c3\f4c3";
}

.fad.fa-hands-heart::after, .fa-duotone.fa-hands-heart::after {
  content: "\f4c3\f4c3";
}

.fad.fa-clock-nine::after, .fa-duotone.fa-clock-nine::after {
  content: "\e34c\e34c";
}

.fad.fa-tarp::after, .fa-duotone.fa-tarp::after {
  content: "\e57b\e57b";
}

.fad.fa-face-sleepy::after, .fa-duotone.fa-face-sleepy::after {
  content: "\e38e\e38e";
}

.fad.fa-hand-horns::after, .fa-duotone.fa-hand-horns::after {
  content: "\e1a9\e1a9";
}

.fad.fa-screwdriver-wrench::after, .fa-duotone.fa-screwdriver-wrench::after {
  content: "\f7d9\f7d9";
}

.fad.fa-tools::after, .fa-duotone.fa-tools::after {
  content: "\f7d9\f7d9";
}

.fad.fa-arrows-to-eye::after, .fa-duotone.fa-arrows-to-eye::after {
  content: "\e4bf\e4bf";
}

.fad.fa-circle-three-quarters::after, .fa-duotone.fa-circle-three-quarters::after {
  content: "\e125\e125";
}

.fad.fa-trophy-star::after, .fa-duotone.fa-trophy-star::after {
  content: "\f2eb\f2eb";
}

.fad.fa-trophy-alt::after, .fa-duotone.fa-trophy-alt::after {
  content: "\f2eb\f2eb";
}

.fad.fa-plug-circle-bolt::after, .fa-duotone.fa-plug-circle-bolt::after {
  content: "\e55b\e55b";
}

.fad.fa-face-thermometer::after, .fa-duotone.fa-face-thermometer::after {
  content: "\e39a\e39a";
}

.fad.fa-shirt-running::after, .fa-duotone.fa-shirt-running::after {
  content: "\e3c8\e3c8";
}

.fad.fa-book-circle-arrow-up::after, .fa-duotone.fa-book-circle-arrow-up::after {
  content: "\e0bd\e0bd";
}

.fad.fa-face-nauseated::after, .fa-duotone.fa-face-nauseated::after {
  content: "\e381\e381";
}

.fad.fa-heart::after, .fa-duotone.fa-heart::after {
  content: "\f004\f004";
}

.fad.fa-file-chart-pie::after, .fa-duotone.fa-file-chart-pie::after {
  content: "\f65a\f65a";
}

.fad.fa-mars-and-venus::after, .fa-duotone.fa-mars-and-venus::after {
  content: "\f224\f224";
}

.fad.fa-house-user::after, .fa-duotone.fa-house-user::after {
  content: "\e1b0\e1b0";
}

.fad.fa-home-user::after, .fa-duotone.fa-home-user::after {
  content: "\e1b0\e1b0";
}

.fad.fa-circle-arrow-down-left::after, .fa-duotone.fa-circle-arrow-down-left::after {
  content: "\e0f9\e0f9";
}

.fad.fa-dumpster-fire::after, .fa-duotone.fa-dumpster-fire::after {
  content: "\f794\f794";
}

.fad.fa-hexagon-minus::after, .fa-duotone.fa-hexagon-minus::after {
  content: "\f307\f307";
}

.fad.fa-minus-hexagon::after, .fa-duotone.fa-minus-hexagon::after {
  content: "\f307\f307";
}

.fad.fa-left-to-line::after, .fa-duotone.fa-left-to-line::after {
  content: "\f34b\f34b";
}

.fad.fa-arrow-alt-to-left::after, .fa-duotone.fa-arrow-alt-to-left::after {
  content: "\f34b\f34b";
}

.fad.fa-house-crack::after, .fa-duotone.fa-house-crack::after {
  content: "\e3b1\e3b1";
}

.fad.fa-paw-simple::after, .fa-duotone.fa-paw-simple::after {
  content: "\f701\f701";
}

.fad.fa-paw-alt::after, .fa-duotone.fa-paw-alt::after {
  content: "\f701\f701";
}

.fad.fa-arrow-left-long-to-line::after, .fa-duotone.fa-arrow-left-long-to-line::after {
  content: "\e3d4\e3d4";
}

.fad.fa-brackets-round::after, .fa-duotone.fa-brackets-round::after {
  content: "\e0c5\e0c5";
}

.fad.fa-parentheses::after, .fa-duotone.fa-parentheses::after {
  content: "\e0c5\e0c5";
}

.fad.fa-martini-glass-citrus::after, .fa-duotone.fa-martini-glass-citrus::after {
  content: "\f561\f561";
}

.fad.fa-cocktail::after, .fa-duotone.fa-cocktail::after {
  content: "\f561\f561";
}

.fad.fa-user-shakespeare::after, .fa-duotone.fa-user-shakespeare::after {
  content: "\e2c2\e2c2";
}

.fad.fa-arrow-right-to-arc::after, .fa-duotone.fa-arrow-right-to-arc::after {
  content: "\e4b2\e4b2";
}

.fad.fa-face-surprise::after, .fa-duotone.fa-face-surprise::after {
  content: "\f5c2\f5c2";
}

.fad.fa-surprise::after, .fa-duotone.fa-surprise::after {
  content: "\f5c2\f5c2";
}

.fad.fa-bottle-water::after, .fa-duotone.fa-bottle-water::after {
  content: "\e4c5\e4c5";
}

.fad.fa-circle-pause::after, .fa-duotone.fa-circle-pause::after {
  content: "\f28b\f28b";
}

.fad.fa-pause-circle::after, .fa-duotone.fa-pause-circle::after {
  content: "\f28b\f28b";
}

.fad.fa-gauge-circle-plus::after, .fa-duotone.fa-gauge-circle-plus::after {
  content: "\e498\e498";
}

.fad.fa-folders::after, .fa-duotone.fa-folders::after {
  content: "\f660\f660";
}

.fad.fa-angel::after, .fa-duotone.fa-angel::after {
  content: "\f779\f779";
}

.fad.fa-value-absolute::after, .fa-duotone.fa-value-absolute::after {
  content: "\f6a6\f6a6";
}

.fad.fa-rabbit::after, .fa-duotone.fa-rabbit::after {
  content: "\f708\f708";
}

.fad.fa-toilet-paper-slash::after, .fa-duotone.fa-toilet-paper-slash::after {
  content: "\e072\e072";
}

.fad.fa-apple-whole::after, .fa-duotone.fa-apple-whole::after {
  content: "\f5d1\f5d1";
}

.fad.fa-apple-alt::after, .fa-duotone.fa-apple-alt::after {
  content: "\f5d1\f5d1";
}

.fad.fa-kitchen-set::after, .fa-duotone.fa-kitchen-set::after {
  content: "\e51a\e51a";
}

.fad.fa-lock-keyhole::after, .fa-duotone.fa-lock-keyhole::after {
  content: "\f30d\f30d";
}

.fad.fa-lock-alt::after, .fa-duotone.fa-lock-alt::after {
  content: "\f30d\f30d";
}

.fad.fa-r::after, .fa-duotone.fa-r::after {
  content: "RR";
}

.fad.fa-temperature-quarter::after, .fa-duotone.fa-temperature-quarter::after {
  content: "\f2ca\f2ca";
}

.fad.fa-temperature-1::after, .fa-duotone.fa-temperature-1::after {
  content: "\f2ca\f2ca";
}

.fad.fa-thermometer-1::after, .fa-duotone.fa-thermometer-1::after {
  content: "\f2ca\f2ca";
}

.fad.fa-thermometer-quarter::after, .fa-duotone.fa-thermometer-quarter::after {
  content: "\f2ca\f2ca";
}

.fad.fa-square-info::after, .fa-duotone.fa-square-info::after {
  content: "\f30f\f30f";
}

.fad.fa-info-square::after, .fa-duotone.fa-info-square::after {
  content: "\f30f\f30f";
}

.fad.fa-wifi-slash::after, .fa-duotone.fa-wifi-slash::after {
  content: "\f6ac\f6ac";
}

.fad.fa-toilet-paper-xmark::after, .fa-duotone.fa-toilet-paper-xmark::after {
  content: "\e5b3\e5b3";
}

.fad.fa-hands-holding-dollar::after, .fa-duotone.fa-hands-holding-dollar::after {
  content: "\f4c5\f4c5";
}

.fad.fa-hands-usd::after, .fa-duotone.fa-hands-usd::after {
  content: "\f4c5\f4c5";
}

.fad.fa-cube::after, .fa-duotone.fa-cube::after {
  content: "\f1b2\f1b2";
}

.fad.fa-arrow-down-triangle-square::after, .fa-duotone.fa-arrow-down-triangle-square::after {
  content: "\f888\f888";
}

.fad.fa-sort-shapes-down::after, .fa-duotone.fa-sort-shapes-down::after {
  content: "\f888\f888";
}

.fad.fa-bitcoin-sign::after, .fa-duotone.fa-bitcoin-sign::after {
  content: "\e0b4\e0b4";
}

.fad.fa-shutters::after, .fa-duotone.fa-shutters::after {
  content: "\e449\e449";
}

.fad.fa-shield-dog::after, .fa-duotone.fa-shield-dog::after {
  content: "\e573\e573";
}

.fad.fa-solar-panel::after, .fa-duotone.fa-solar-panel::after {
  content: "\f5ba\f5ba";
}

.fad.fa-lock-open::after, .fa-duotone.fa-lock-open::after {
  content: "\f3c1\f3c1";
}

.fad.fa-table-tree::after, .fa-duotone.fa-table-tree::after {
  content: "\e293\e293";
}

.fad.fa-house-chimney-heart::after, .fa-duotone.fa-house-chimney-heart::after {
  content: "\e1b2\e1b2";
}

.fad.fa-tally-3::after, .fa-duotone.fa-tally-3::after {
  content: "\e296\e296";
}

.fad.fa-elevator::after, .fa-duotone.fa-elevator::after {
  content: "\e16d\e16d";
}

.fad.fa-money-bill-transfer::after, .fa-duotone.fa-money-bill-transfer::after {
  content: "\e528\e528";
}

.fad.fa-money-bill-trend-up::after, .fa-duotone.fa-money-bill-trend-up::after {
  content: "\e529\e529";
}

.fad.fa-house-flood-water-circle-arrow-right::after, .fa-duotone.fa-house-flood-water-circle-arrow-right::after {
  content: "\e50f\e50f";
}

.fad.fa-square-poll-horizontal::after, .fa-duotone.fa-square-poll-horizontal::after {
  content: "\f682\f682";
}

.fad.fa-poll-h::after, .fa-duotone.fa-poll-h::after {
  content: "\f682\f682";
}

.fad.fa-circle::after, .fa-duotone.fa-circle::after {
  content: "\f111\f111";
}

.fad.fa-cart-circle-exclamation::after, .fa-duotone.fa-cart-circle-exclamation::after {
  content: "\e3f2\e3f2";
}

.fad.fa-sword::after, .fa-duotone.fa-sword::after {
  content: "\f71c\f71c";
}

.fad.fa-backward-fast::after, .fa-duotone.fa-backward-fast::after {
  content: "\f049\f049";
}

.fad.fa-fast-backward::after, .fa-duotone.fa-fast-backward::after {
  content: "\f049\f049";
}

.fad.fa-recycle::after, .fa-duotone.fa-recycle::after {
  content: "\f1b8\f1b8";
}

.fad.fa-user-astronaut::after, .fa-duotone.fa-user-astronaut::after {
  content: "\f4fb\f4fb";
}

.fad.fa-plane-slash::after, .fa-duotone.fa-plane-slash::after {
  content: "\e069\e069";
}

.fad.fa-circle-dashed::after, .fa-duotone.fa-circle-dashed::after {
  content: "\e105\e105";
}

.fad.fa-trademark::after, .fa-duotone.fa-trademark::after {
  content: "\f25c\f25c";
}

.fad.fa-basketball::after, .fa-duotone.fa-basketball::after {
  content: "\f434\f434";
}

.fad.fa-basketball-ball::after, .fa-duotone.fa-basketball-ball::after {
  content: "\f434\f434";
}

.fad.fa-fork-knife::after, .fa-duotone.fa-fork-knife::after {
  content: "\f2e6\f2e6";
}

.fad.fa-utensils-alt::after, .fa-duotone.fa-utensils-alt::after {
  content: "\f2e6\f2e6";
}

.fad.fa-satellite-dish::after, .fa-duotone.fa-satellite-dish::after {
  content: "\f7c0\f7c0";
}

.fad.fa-badge-check::after, .fa-duotone.fa-badge-check::after {
  content: "\f336\f336";
}

.fad.fa-circle-up::after, .fa-duotone.fa-circle-up::after {
  content: "\f35b\f35b";
}

.fad.fa-arrow-alt-circle-up::after, .fa-duotone.fa-arrow-alt-circle-up::after {
  content: "\f35b\f35b";
}

.fad.fa-slider::after, .fa-duotone.fa-slider::after {
  content: "\e252\e252";
}

.fad.fa-mobile-screen-button::after, .fa-duotone.fa-mobile-screen-button::after {
  content: "\f3cd\f3cd";
}

.fad.fa-mobile-alt::after, .fa-duotone.fa-mobile-alt::after {
  content: "\f3cd\f3cd";
}

.fad.fa-clock-one-thirty::after, .fa-duotone.fa-clock-one-thirty::after {
  content: "\e34f\e34f";
}

.fad.fa-inbox-out::after, .fa-duotone.fa-inbox-out::after {
  content: "\f311\f311";
}

.fad.fa-inbox-arrow-up::after, .fa-duotone.fa-inbox-arrow-up::after {
  content: "\f311\f311";
}

.fad.fa-cloud-slash::after, .fa-duotone.fa-cloud-slash::after {
  content: "\e137\e137";
}

.fad.fa-volume-high::after, .fa-duotone.fa-volume-high::after {
  content: "\f028\f028";
}

.fad.fa-volume-up::after, .fa-duotone.fa-volume-up::after {
  content: "\f028\f028";
}

.fad.fa-users-rays::after, .fa-duotone.fa-users-rays::after {
  content: "\e593\e593";
}

.fad.fa-wallet::after, .fa-duotone.fa-wallet::after {
  content: "\f555\f555";
}

.fad.fa-octagon-check::after, .fa-duotone.fa-octagon-check::after {
  content: "\e426\e426";
}

.fad.fa-flatbread-stuffed::after, .fa-duotone.fa-flatbread-stuffed::after {
  content: "\e40c\e40c";
}

.fad.fa-clipboard-check::after, .fa-duotone.fa-clipboard-check::after {
  content: "\f46c\f46c";
}

.fad.fa-cart-circle-plus::after, .fa-duotone.fa-cart-circle-plus::after {
  content: "\e3f3\e3f3";
}

.fad.fa-truck-clock::after, .fa-duotone.fa-truck-clock::after {
  content: "\f48c\f48c";
}

.fad.fa-shipping-timed::after, .fa-duotone.fa-shipping-timed::after {
  content: "\f48c\f48c";
}

.fad.fa-pool-8-ball::after, .fa-duotone.fa-pool-8-ball::after {
  content: "\e3c5\e3c5";
}

.fad.fa-file-audio::after, .fa-duotone.fa-file-audio::after {
  content: "\f1c7\f1c7";
}

.fad.fa-turn-down-left::after, .fa-duotone.fa-turn-down-left::after {
  content: "\e331\e331";
}

.fad.fa-lock-hashtag::after, .fa-duotone.fa-lock-hashtag::after {
  content: "\e423\e423";
}

.fad.fa-chart-radar::after, .fa-duotone.fa-chart-radar::after {
  content: "\e0e7\e0e7";
}

.fad.fa-staff::after, .fa-duotone.fa-staff::after {
  content: "\f71b\f71b";
}

.fad.fa-burger::after, .fa-duotone.fa-burger::after {
  content: "\f805\f805";
}

.fad.fa-hamburger::after, .fa-duotone.fa-hamburger::after {
  content: "\f805\f805";
}

.fad.fa-utility-pole::after, .fa-duotone.fa-utility-pole::after {
  content: "\e2c3\e2c3";
}

.fad.fa-transporter-6::after, .fa-duotone.fa-transporter-6::after {
  content: "\e2a7\e2a7";
}

.fad.fa-wrench::after, .fa-duotone.fa-wrench::after {
  content: "\f0ad\f0ad";
}

.fad.fa-bugs::after, .fa-duotone.fa-bugs::after {
  content: "\e4d0\e4d0";
}

.fad.fa-vector-polygon::after, .fa-duotone.fa-vector-polygon::after {
  content: "\e2c7\e2c7";
}

.fad.fa-diagram-nested::after, .fa-duotone.fa-diagram-nested::after {
  content: "\e157\e157";
}

.fad.fa-rupee-sign::after, .fa-duotone.fa-rupee-sign::after {
  content: "\f156\f156";
}

.fad.fa-rupee::after, .fa-duotone.fa-rupee::after {
  content: "\f156\f156";
}

.fad.fa-file-image::after, .fa-duotone.fa-file-image::after {
  content: "\f1c5\f1c5";
}

.fad.fa-circle-question::after, .fa-duotone.fa-circle-question::after {
  content: "\f059\f059";
}

.fad.fa-question-circle::after, .fa-duotone.fa-question-circle::after {
  content: "\f059\f059";
}

.fad.fa-image-user::after, .fa-duotone.fa-image-user::after {
  content: "\e1b8\e1b8";
}

.fad.fa-plane-departure::after, .fa-duotone.fa-plane-departure::after {
  content: "\f5b0\f5b0";
}

.fad.fa-handshake-slash::after, .fa-duotone.fa-handshake-slash::after {
  content: "\e060\e060";
}

.fad.fa-book-bookmark::after, .fa-duotone.fa-book-bookmark::after {
  content: "\e0bb\e0bb";
}

.fad.fa-border-center-h::after, .fa-duotone.fa-border-center-h::after {
  content: "\f89c\f89c";
}

.fad.fa-can-food::after, .fa-duotone.fa-can-food::after {
  content: "\e3e6\e3e6";
}

.fad.fa-typewriter::after, .fa-duotone.fa-typewriter::after {
  content: "\f8e7\f8e7";
}

.fad.fa-arrow-right-from-arc::after, .fa-duotone.fa-arrow-right-from-arc::after {
  content: "\e4b1\e4b1";
}

.fad.fa-circle-k::after, .fa-duotone.fa-circle-k::after {
  content: "\e113\e113";
}

.fad.fa-face-hand-over-mouth::after, .fa-duotone.fa-face-hand-over-mouth::after {
  content: "\e378\e378";
}

.fad.fa-popcorn::after, .fa-duotone.fa-popcorn::after {
  content: "\f819\f819";
}

.fad.fa-house-water::after, .fa-duotone.fa-house-water::after {
  content: "\f74f\f74f";
}

.fad.fa-house-flood::after, .fa-duotone.fa-house-flood::after {
  content: "\f74f\f74f";
}

.fad.fa-object-subtract::after, .fa-duotone.fa-object-subtract::after {
  content: "\e49e\e49e";
}

.fad.fa-code-branch::after, .fa-duotone.fa-code-branch::after {
  content: "\f126\f126";
}

.fad.fa-warehouse-full::after, .fa-duotone.fa-warehouse-full::after {
  content: "\f495\f495";
}

.fad.fa-warehouse-alt::after, .fa-duotone.fa-warehouse-alt::after {
  content: "\f495\f495";
}

.fad.fa-hat-cowboy::after, .fa-duotone.fa-hat-cowboy::after {
  content: "\f8c0\f8c0";
}

.fad.fa-bridge::after, .fa-duotone.fa-bridge::after {
  content: "\e4c8\e4c8";
}

.fad.fa-phone-flip::after, .fa-duotone.fa-phone-flip::after {
  content: "\f879\f879";
}

.fad.fa-phone-alt::after, .fa-duotone.fa-phone-alt::after {
  content: "\f879\f879";
}

.fad.fa-arrow-down-from-dotted-line::after, .fa-duotone.fa-arrow-down-from-dotted-line::after {
  content: "\e090\e090";
}

.fad.fa-square-quarters::after, .fa-duotone.fa-square-quarters::after {
  content: "\e44e\e44e";
}

.fad.fa-truck-front::after, .fa-duotone.fa-truck-front::after {
  content: "\e2b7\e2b7";
}

.fad.fa-cat::after, .fa-duotone.fa-cat::after {
  content: "\f6be\f6be";
}

.fad.fa-trash-xmark::after, .fa-duotone.fa-trash-xmark::after {
  content: "\e2b4\e2b4";
}

.fad.fa-circle-caret-left::after, .fa-duotone.fa-circle-caret-left::after {
  content: "\f32e\f32e";
}

.fad.fa-caret-circle-left::after, .fa-duotone.fa-caret-circle-left::after {
  content: "\f32e\f32e";
}

.fad.fa-files::after, .fa-duotone.fa-files::after {
  content: "\e178\e178";
}

.fad.fa-anchor-circle-exclamation::after, .fa-duotone.fa-anchor-circle-exclamation::after {
  content: "\e4ab\e4ab";
}

.fad.fa-face-clouds::after, .fa-duotone.fa-face-clouds::after {
  content: "\e47d\e47d";
}

.fad.fa-user-crown::after, .fa-duotone.fa-user-crown::after {
  content: "\f6a4\f6a4";
}

.fad.fa-truck-field::after, .fa-duotone.fa-truck-field::after {
  content: "\e58d\e58d";
}

.fad.fa-route::after, .fa-duotone.fa-route::after {
  content: "\f4d7\f4d7";
}

.fad.fa-cart-circle-check::after, .fa-duotone.fa-cart-circle-check::after {
  content: "\e3f1\e3f1";
}

.fad.fa-clipboard-question::after, .fa-duotone.fa-clipboard-question::after {
  content: "\e4e3\e4e3";
}

.fad.fa-panorama::after, .fa-duotone.fa-panorama::after {
  content: "\e209\e209";
}

.fad.fa-comment-medical::after, .fa-duotone.fa-comment-medical::after {
  content: "\f7f5\f7f5";
}

.fad.fa-teeth-open::after, .fa-duotone.fa-teeth-open::after {
  content: "\f62f\f62f";
}

.fad.fa-user-tie-hair-long::after, .fa-duotone.fa-user-tie-hair-long::after {
  content: "\e460\e460";
}

.fad.fa-file-circle-minus::after, .fa-duotone.fa-file-circle-minus::after {
  content: "\e4ed\e4ed";
}

.fad.fa-head-side-medical::after, .fa-duotone.fa-head-side-medical::after {
  content: "\f809\f809";
}

.fad.fa-tags::after, .fa-duotone.fa-tags::after {
  content: "\f02c\f02c";
}

.fad.fa-wine-glass::after, .fa-duotone.fa-wine-glass::after {
  content: "\f4e3\f4e3";
}

.fad.fa-forward-fast::after, .fa-duotone.fa-forward-fast::after {
  content: "\f050\f050";
}

.fad.fa-fast-forward::after, .fa-duotone.fa-fast-forward::after {
  content: "\f050\f050";
}

.fad.fa-face-meh-blank::after, .fa-duotone.fa-face-meh-blank::after {
  content: "\f5a4\f5a4";
}

.fad.fa-meh-blank::after, .fa-duotone.fa-meh-blank::after {
  content: "\f5a4\f5a4";
}

.fad.fa-user-robot::after, .fa-duotone.fa-user-robot::after {
  content: "\e04b\e04b";
}

.fad.fa-square-parking::after, .fa-duotone.fa-square-parking::after {
  content: "\f540\f540";
}

.fad.fa-parking::after, .fa-duotone.fa-parking::after {
  content: "\f540\f540";
}

.fad.fa-card-diamond::after, .fa-duotone.fa-card-diamond::after {
  content: "\e3ea\e3ea";
}

.fad.fa-face-zipper::after, .fa-duotone.fa-face-zipper::after {
  content: "\e3a5\e3a5";
}

.fad.fa-face-raised-eyebrow::after, .fa-duotone.fa-face-raised-eyebrow::after {
  content: "\e388\e388";
}

.fad.fa-house-signal::after, .fa-duotone.fa-house-signal::after {
  content: "\e012\e012";
}

.fad.fa-square-chevron-up::after, .fa-duotone.fa-square-chevron-up::after {
  content: "\f32c\f32c";
}

.fad.fa-chevron-square-up::after, .fa-duotone.fa-chevron-square-up::after {
  content: "\f32c\f32c";
}

.fad.fa-bars-progress::after, .fa-duotone.fa-bars-progress::after {
  content: "\f828\f828";
}

.fad.fa-tasks-alt::after, .fa-duotone.fa-tasks-alt::after {
  content: "\f828\f828";
}

.fad.fa-faucet-drip::after, .fa-duotone.fa-faucet-drip::after {
  content: "\e006\e006";
}

.fad.fa-arrows-to-line::after, .fa-duotone.fa-arrows-to-line::after {
  content: "\e0a7\e0a7";
}

.fad.fa-dolphin::after, .fa-duotone.fa-dolphin::after {
  content: "\e168\e168";
}

.fad.fa-arrow-up-right::after, .fa-duotone.fa-arrow-up-right::after {
  content: "\e09f\e09f";
}

.fad.fa-circle-r::after, .fa-duotone.fa-circle-r::after {
  content: "\e120\e120";
}

.fad.fa-cart-flatbed::after, .fa-duotone.fa-cart-flatbed::after {
  content: "\f474\f474";
}

.fad.fa-dolly-flatbed::after, .fa-duotone.fa-dolly-flatbed::after {
  content: "\f474\f474";
}

.fad.fa-ban-smoking::after, .fa-duotone.fa-ban-smoking::after {
  content: "\f54d\f54d";
}

.fad.fa-smoking-ban::after, .fa-duotone.fa-smoking-ban::after {
  content: "\f54d\f54d";
}

.fad.fa-circle-sort-up::after, .fa-duotone.fa-circle-sort-up::after {
  content: "\e032\e032";
}

.fad.fa-sort-circle-up::after, .fa-duotone.fa-sort-circle-up::after {
  content: "\e032\e032";
}

.fad.fa-terminal::after, .fa-duotone.fa-terminal::after {
  content: "\f120\f120";
}

.fad.fa-mobile-button::after, .fa-duotone.fa-mobile-button::after {
  content: "\f10b\f10b";
}

.fad.fa-house-medical-flag::after, .fa-duotone.fa-house-medical-flag::after {
  content: "\e514\e514";
}

.fad.fa-basket-shopping::after, .fa-duotone.fa-basket-shopping::after {
  content: "\f291\f291";
}

.fad.fa-shopping-basket::after, .fa-duotone.fa-shopping-basket::after {
  content: "\f291\f291";
}

.fad.fa-tape::after, .fa-duotone.fa-tape::after {
  content: "\f4db\f4db";
}

.fad.fa-chestnut::after, .fa-duotone.fa-chestnut::after {
  content: "\e3f6\e3f6";
}

.fad.fa-bus-simple::after, .fa-duotone.fa-bus-simple::after {
  content: "\f55e\f55e";
}

.fad.fa-bus-alt::after, .fa-duotone.fa-bus-alt::after {
  content: "\f55e\f55e";
}

.fad.fa-eye::after, .fa-duotone.fa-eye::after {
  content: "\f06e\f06e";
}

.fad.fa-face-sad-cry::after, .fa-duotone.fa-face-sad-cry::after {
  content: "\f5b3\f5b3";
}

.fad.fa-sad-cry::after, .fa-duotone.fa-sad-cry::after {
  content: "\f5b3\f5b3";
}

.fad.fa-heat::after, .fa-duotone.fa-heat::after {
  content: "\e00c\e00c";
}

.fad.fa-ticket-airline::after, .fa-duotone.fa-ticket-airline::after {
  content: "\e29a\e29a";
}

.fad.fa-boot-heeled::after, .fa-duotone.fa-boot-heeled::after {
  content: "\e33f\e33f";
}

.fad.fa-arrows-minimize::after, .fa-duotone.fa-arrows-minimize::after {
  content: "\e0a5\e0a5";
}

.fad.fa-compress-arrows::after, .fa-duotone.fa-compress-arrows::after {
  content: "\e0a5\e0a5";
}

.fad.fa-audio-description::after, .fa-duotone.fa-audio-description::after {
  content: "\f29e\f29e";
}

.fad.fa-person-military-to-person::after, .fa-duotone.fa-person-military-to-person::after {
  content: "\e54c\e54c";
}

.fad.fa-file-shield::after, .fa-duotone.fa-file-shield::after {
  content: "\e4f0\e4f0";
}

.fad.fa-hexagon::after, .fa-duotone.fa-hexagon::after {
  content: "\f312\f312";
}

.fad.fa-manhole::after, .fa-duotone.fa-manhole::after {
  content: "\e1d6\e1d6";
}

.fad.fa-user-slash::after, .fa-duotone.fa-user-slash::after {
  content: "\f506\f506";
}

.fad.fa-pen::after, .fa-duotone.fa-pen::after {
  content: "\f304\f304";
}

.fad.fa-tower-observation::after, .fa-duotone.fa-tower-observation::after {
  content: "\e586\e586";
}

.fad.fa-floppy-disks::after, .fa-duotone.fa-floppy-disks::after {
  content: "\e183\e183";
}

.fad.fa-toilet-paper-blank-under::after, .fa-duotone.fa-toilet-paper-blank-under::after {
  content: "\e29f\e29f";
}

.fad.fa-toilet-paper-reverse-alt::after, .fa-duotone.fa-toilet-paper-reverse-alt::after {
  content: "\e29f\e29f";
}

.fad.fa-file-code::after, .fa-duotone.fa-file-code::after {
  content: "\f1c9\f1c9";
}

.fad.fa-signal::after, .fa-duotone.fa-signal::after {
  content: "\f012\f012";
}

.fad.fa-signal-5::after, .fa-duotone.fa-signal-5::after {
  content: "\f012\f012";
}

.fad.fa-signal-perfect::after, .fa-duotone.fa-signal-perfect::after {
  content: "\f012\f012";
}

.fad.fa-pump::after, .fa-duotone.fa-pump::after {
  content: "\e442\e442";
}

.fad.fa-bus::after, .fa-duotone.fa-bus::after {
  content: "\f207\f207";
}

.fad.fa-heart-circle-xmark::after, .fa-duotone.fa-heart-circle-xmark::after {
  content: "\e501\e501";
}

.fad.fa-arrow-up-left-from-circle::after, .fa-duotone.fa-arrow-up-left-from-circle::after {
  content: "\e09e\e09e";
}

.fad.fa-house-chimney::after, .fa-duotone.fa-house-chimney::after {
  content: "\e3af\e3af";
}

.fad.fa-home-lg::after, .fa-duotone.fa-home-lg::after {
  content: "\e3af\e3af";
}

.fad.fa-window-maximize::after, .fa-duotone.fa-window-maximize::after {
  content: "\f2d0\f2d0";
}

.fad.fa-dryer::after, .fa-duotone.fa-dryer::after {
  content: "\f861\f861";
}

.fad.fa-face-frown::after, .fa-duotone.fa-face-frown::after {
  content: "\f119\f119";
}

.fad.fa-frown::after, .fa-duotone.fa-frown::after {
  content: "\f119\f119";
}

.fad.fa-chess-bishop-piece::after, .fa-duotone.fa-chess-bishop-piece::after {
  content: "\f43b\f43b";
}

.fad.fa-chess-bishop-alt::after, .fa-duotone.fa-chess-bishop-alt::after {
  content: "\f43b\f43b";
}

.fad.fa-shirt-tank-top::after, .fa-duotone.fa-shirt-tank-top::after {
  content: "\e3c9\e3c9";
}

.fad.fa-diploma::after, .fa-duotone.fa-diploma::after {
  content: "\f5ea\f5ea";
}

.fad.fa-scroll-ribbon::after, .fa-duotone.fa-scroll-ribbon::after {
  content: "\f5ea\f5ea";
}

.fad.fa-screencast::after, .fa-duotone.fa-screencast::after {
  content: "\e23e\e23e";
}

.fad.fa-walker::after, .fa-duotone.fa-walker::after {
  content: "\f831\f831";
}

.fad.fa-prescription::after, .fa-duotone.fa-prescription::after {
  content: "\f5b1\f5b1";
}

.fad.fa-shop::after, .fa-duotone.fa-shop::after {
  content: "\f54f\f54f";
}

.fad.fa-store-alt::after, .fa-duotone.fa-store-alt::after {
  content: "\f54f\f54f";
}

.fad.fa-floppy-disk::after, .fa-duotone.fa-floppy-disk::after {
  content: "\f0c7\f0c7";
}

.fad.fa-save::after, .fa-duotone.fa-save::after {
  content: "\f0c7\f0c7";
}

.fad.fa-vihara::after, .fa-duotone.fa-vihara::after {
  content: "\f6a7\f6a7";
}

.fad.fa-face-kiss-closed-eyes::after, .fa-duotone.fa-face-kiss-closed-eyes::after {
  content: "\e37d\e37d";
}

.fad.fa-scale-unbalanced::after, .fa-duotone.fa-scale-unbalanced::after {
  content: "\f515\f515";
}

.fad.fa-balance-scale-left::after, .fa-duotone.fa-balance-scale-left::after {
  content: "\f515\f515";
}

.fad.fa-file-user::after, .fa-duotone.fa-file-user::after {
  content: "\f65c\f65c";
}

.fad.fa-user-police-tie::after, .fa-duotone.fa-user-police-tie::after {
  content: "\e334\e334";
}

.fad.fa-face-tongue-money::after, .fa-duotone.fa-face-tongue-money::after {
  content: "\e39d\e39d";
}

.fad.fa-tennis-ball::after, .fa-duotone.fa-tennis-ball::after {
  content: "\f45e\f45e";
}

.fad.fa-square-l::after, .fa-duotone.fa-square-l::after {
  content: "\e275\e275";
}

.fad.fa-sort-up::after, .fa-duotone.fa-sort-up::after {
  content: "\f0de\f0de";
}

.fad.fa-sort-asc::after, .fa-duotone.fa-sort-asc::after {
  content: "\f0de\f0de";
}

.fad.fa-calendar-arrow-up::after, .fa-duotone.fa-calendar-arrow-up::after {
  content: "\e0d1\e0d1";
}

.fad.fa-calendar-upload::after, .fa-duotone.fa-calendar-upload::after {
  content: "\e0d1\e0d1";
}

.fad.fa-comment-dots::after, .fa-duotone.fa-comment-dots::after {
  content: "\f4ad\f4ad";
}

.fad.fa-commenting::after, .fa-duotone.fa-commenting::after {
  content: "\f4ad\f4ad";
}

.fad.fa-plant-wilt::after, .fa-duotone.fa-plant-wilt::after {
  content: "\e5aa\e5aa";
}

.fad.fa-scarf::after, .fa-duotone.fa-scarf::after {
  content: "\f7c1\f7c1";
}

.fad.fa-album-circle-plus::after, .fa-duotone.fa-album-circle-plus::after {
  content: "\e48c\e48c";
}

.fad.fa-user-nurse-hair-long::after, .fa-duotone.fa-user-nurse-hair-long::after {
  content: "\e45e\e45e";
}

.fad.fa-diamond::after, .fa-duotone.fa-diamond::after {
  content: "\f219\f219";
}

.fad.fa-square-left::after, .fa-duotone.fa-square-left::after {
  content: "\f351\f351";
}

.fad.fa-arrow-alt-square-left::after, .fa-duotone.fa-arrow-alt-square-left::after {
  content: "\f351\f351";
}

.fad.fa-face-grin-squint::after, .fa-duotone.fa-face-grin-squint::after {
  content: "\f585\f585";
}

.fad.fa-grin-squint::after, .fa-duotone.fa-grin-squint::after {
  content: "\f585\f585";
}

.fad.fa-circle-ellipsis-vertical::after, .fa-duotone.fa-circle-ellipsis-vertical::after {
  content: "\e10b\e10b";
}

.fad.fa-hand-holding-dollar::after, .fa-duotone.fa-hand-holding-dollar::after {
  content: "\f4c0\f4c0";
}

.fad.fa-hand-holding-usd::after, .fa-duotone.fa-hand-holding-usd::after {
  content: "\f4c0\f4c0";
}

.fad.fa-grid-dividers::after, .fa-duotone.fa-grid-dividers::after {
  content: "\e3ad\e3ad";
}

.fad.fa-bacterium::after, .fa-duotone.fa-bacterium::after {
  content: "\e05a\e05a";
}

.fad.fa-hand-pointer::after, .fa-duotone.fa-hand-pointer::after {
  content: "\f25a\f25a";
}

.fad.fa-drum-steelpan::after, .fa-duotone.fa-drum-steelpan::after {
  content: "\f56a\f56a";
}

.fad.fa-hand-scissors::after, .fa-duotone.fa-hand-scissors::after {
  content: "\f257\f257";
}

.fad.fa-hands-praying::after, .fa-duotone.fa-hands-praying::after {
  content: "\f684\f684";
}

.fad.fa-praying-hands::after, .fa-duotone.fa-praying-hands::after {
  content: "\f684\f684";
}

.fad.fa-face-pensive::after, .fa-duotone.fa-face-pensive::after {
  content: "\e384\e384";
}

.fad.fa-user-music::after, .fa-duotone.fa-user-music::after {
  content: "\f8eb\f8eb";
}

.fad.fa-arrow-rotate-right::after, .fa-duotone.fa-arrow-rotate-right::after {
  content: "\f01e\f01e";
}

.fad.fa-arrow-right-rotate::after, .fa-duotone.fa-arrow-right-rotate::after {
  content: "\f01e\f01e";
}

.fad.fa-arrow-rotate-forward::after, .fa-duotone.fa-arrow-rotate-forward::after {
  content: "\f01e\f01e";
}

.fad.fa-redo::after, .fa-duotone.fa-redo::after {
  content: "\f01e\f01e";
}

.fad.fa-messages-dollar::after, .fa-duotone.fa-messages-dollar::after {
  content: "\f652\f652";
}

.fad.fa-comments-alt-dollar::after, .fa-duotone.fa-comments-alt-dollar::after {
  content: "\f652\f652";
}

.fad.fa-sensor-on::after, .fa-duotone.fa-sensor-on::after {
  content: "\e02b\e02b";
}

.fad.fa-balloon::after, .fa-duotone.fa-balloon::after {
  content: "\e2e3\e2e3";
}

.fad.fa-biohazard::after, .fa-duotone.fa-biohazard::after {
  content: "\f780\f780";
}

.fad.fa-chess-queen-piece::after, .fa-duotone.fa-chess-queen-piece::after {
  content: "\f446\f446";
}

.fad.fa-chess-queen-alt::after, .fa-duotone.fa-chess-queen-alt::after {
  content: "\f446\f446";
}

.fad.fa-location-crosshairs::after, .fa-duotone.fa-location-crosshairs::after {
  content: "\f601\f601";
}

.fad.fa-location::after, .fa-duotone.fa-location::after {
  content: "\f601\f601";
}

.fad.fa-mars-double::after, .fa-duotone.fa-mars-double::after {
  content: "\f227\f227";
}

.fad.fa-house-person-leave::after, .fa-duotone.fa-house-person-leave::after {
  content: "\e00f\e00f";
}

.fad.fa-house-leave::after, .fa-duotone.fa-house-leave::after {
  content: "\e00f\e00f";
}

.fad.fa-house-person-depart::after, .fa-duotone.fa-house-person-depart::after {
  content: "\e00f\e00f";
}

.fad.fa-ruler-triangle::after, .fa-duotone.fa-ruler-triangle::after {
  content: "\f61c\f61c";
}

.fad.fa-card-club::after, .fa-duotone.fa-card-club::after {
  content: "\e3e9\e3e9";
}

.fad.fa-child-dress::after, .fa-duotone.fa-child-dress::after {
  content: "\e59c\e59c";
}

.fad.fa-users-between-lines::after, .fa-duotone.fa-users-between-lines::after {
  content: "\e591\e591";
}

.fad.fa-lungs-virus::after, .fa-duotone.fa-lungs-virus::after {
  content: "\e067\e067";
}

.fad.fa-spinner-third::after, .fa-duotone.fa-spinner-third::after {
  content: "\f3f4\f3f4";
}

.fad.fa-face-grin-tears::after, .fa-duotone.fa-face-grin-tears::after {
  content: "\f588\f588";
}

.fad.fa-grin-tears::after, .fa-duotone.fa-grin-tears::after {
  content: "\f588\f588";
}

.fad.fa-phone::after, .fa-duotone.fa-phone::after {
  content: "\f095\f095";
}

.fad.fa-computer-mouse-scrollwheel::after, .fa-duotone.fa-computer-mouse-scrollwheel::after {
  content: "\f8cd\f8cd";
}

.fad.fa-mouse-alt::after, .fa-duotone.fa-mouse-alt::after {
  content: "\f8cd\f8cd";
}

.fad.fa-calendar-xmark::after, .fa-duotone.fa-calendar-xmark::after {
  content: "\f273\f273";
}

.fad.fa-calendar-times::after, .fa-duotone.fa-calendar-times::after {
  content: "\f273\f273";
}

.fad.fa-child-reaching::after, .fa-duotone.fa-child-reaching::after {
  content: "\e59d\e59d";
}

.fad.fa-table-layout::after, .fa-duotone.fa-table-layout::after {
  content: "\e290\e290";
}

.fad.fa-narwhal::after, .fa-duotone.fa-narwhal::after {
  content: "\f6fe\f6fe";
}

.fad.fa-ramp-loading::after, .fa-duotone.fa-ramp-loading::after {
  content: "\f4d4\f4d4";
}

.fad.fa-calendar-circle-plus::after, .fa-duotone.fa-calendar-circle-plus::after {
  content: "\e470\e470";
}

.fad.fa-toothbrush::after, .fa-duotone.fa-toothbrush::after {
  content: "\f635\f635";
}

.fad.fa-border-inner::after, .fa-duotone.fa-border-inner::after {
  content: "\f84e\f84e";
}

.fad.fa-paw-claws::after, .fa-duotone.fa-paw-claws::after {
  content: "\f702\f702";
}

.fad.fa-kiwi-fruit::after, .fa-duotone.fa-kiwi-fruit::after {
  content: "\e30c\e30c";
}

.fad.fa-traffic-light-slow::after, .fa-duotone.fa-traffic-light-slow::after {
  content: "\f639\f639";
}

.fad.fa-rectangle-code::after, .fa-duotone.fa-rectangle-code::after {
  content: "\e322\e322";
}

.fad.fa-head-side-virus::after, .fa-duotone.fa-head-side-virus::after {
  content: "\e064\e064";
}

.fad.fa-keyboard-brightness::after, .fa-duotone.fa-keyboard-brightness::after {
  content: "\e1c0\e1c0";
}

.fad.fa-books-medical::after, .fa-duotone.fa-books-medical::after {
  content: "\f7e8\f7e8";
}

.fad.fa-lightbulb-slash::after, .fa-duotone.fa-lightbulb-slash::after {
  content: "\f673\f673";
}

.fad.fa-house-blank::after, .fa-duotone.fa-house-blank::after {
  content: "\e487\e487";
}

.fad.fa-home-blank::after, .fa-duotone.fa-home-blank::after {
  content: "\e487\e487";
}

.fad.fa-square-5::after, .fa-duotone.fa-square-5::after {
  content: "\e25a\e25a";
}

.fad.fa-square-heart::after, .fa-duotone.fa-square-heart::after {
  content: "\f4c8\f4c8";
}

.fad.fa-heart-square::after, .fa-duotone.fa-heart-square::after {
  content: "\f4c8\f4c8";
}

.fad.fa-puzzle::after, .fa-duotone.fa-puzzle::after {
  content: "\e443\e443";
}

.fad.fa-user-gear::after, .fa-duotone.fa-user-gear::after {
  content: "\f4fe\f4fe";
}

.fad.fa-user-cog::after, .fa-duotone.fa-user-cog::after {
  content: "\f4fe\f4fe";
}

.fad.fa-pipe-circle-check::after, .fa-duotone.fa-pipe-circle-check::after {
  content: "\e436\e436";
}

.fad.fa-arrow-up-1-9::after, .fa-duotone.fa-arrow-up-1-9::after {
  content: "\f163\f163";
}

.fad.fa-sort-numeric-up::after, .fa-duotone.fa-sort-numeric-up::after {
  content: "\f163\f163";
}

.fad.fa-octagon-exclamation::after, .fa-duotone.fa-octagon-exclamation::after {
  content: "\e204\e204";
}

.fad.fa-dial-low::after, .fa-duotone.fa-dial-low::after {
  content: "\e15d\e15d";
}

.fad.fa-door-closed::after, .fa-duotone.fa-door-closed::after {
  content: "\f52a\f52a";
}

.fad.fa-laptop-mobile::after, .fa-duotone.fa-laptop-mobile::after {
  content: "\f87a\f87a";
}

.fad.fa-phone-laptop::after, .fa-duotone.fa-phone-laptop::after {
  content: "\f87a\f87a";
}

.fad.fa-conveyor-belt-boxes::after, .fa-duotone.fa-conveyor-belt-boxes::after {
  content: "\f46f\f46f";
}

.fad.fa-conveyor-belt-alt::after, .fa-duotone.fa-conveyor-belt-alt::after {
  content: "\f46f\f46f";
}

.fad.fa-shield-virus::after, .fa-duotone.fa-shield-virus::after {
  content: "\e06c\e06c";
}

.fad.fa-starfighter-twin-ion-engine-advanced::after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced::after {
  content: "\e28e\e28e";
}

.fad.fa-starfighter-alt-advanced::after, .fa-duotone.fa-starfighter-alt-advanced::after {
  content: "\e28e\e28e";
}

.fad.fa-dice-six::after, .fa-duotone.fa-dice-six::after {
  content: "\f526\f526";
}

.fad.fa-starfighter-twin-ion-engine::after, .fa-duotone.fa-starfighter-twin-ion-engine::after {
  content: "\e038\e038";
}

.fad.fa-starfighter-alt::after, .fa-duotone.fa-starfighter-alt::after {
  content: "\e038\e038";
}

.fad.fa-rocket-launch::after, .fa-duotone.fa-rocket-launch::after {
  content: "\e027\e027";
}

.fad.fa-mosquito-net::after, .fa-duotone.fa-mosquito-net::after {
  content: "\e52c\e52c";
}

.fad.fa-vent-damper::after, .fa-duotone.fa-vent-damper::after {
  content: "\e465\e465";
}

.fad.fa-bridge-water::after, .fa-duotone.fa-bridge-water::after {
  content: "\e4ce\e4ce";
}

.fad.fa-ban-bug::after, .fa-duotone.fa-ban-bug::after {
  content: "\f7f9\f7f9";
}

.fad.fa-debug::after, .fa-duotone.fa-debug::after {
  content: "\f7f9\f7f9";
}

.fad.fa-person-booth::after, .fa-duotone.fa-person-booth::after {
  content: "\f756\f756";
}

.fad.fa-text-width::after, .fa-duotone.fa-text-width::after {
  content: "\f035\f035";
}

.fad.fa-garage-car::after, .fa-duotone.fa-garage-car::after {
  content: "\e00a\e00a";
}

.fad.fa-square-kanban::after, .fa-duotone.fa-square-kanban::after {
  content: "\e488\e488";
}

.fad.fa-hat-wizard::after, .fa-duotone.fa-hat-wizard::after {
  content: "\f6e8\f6e8";
}

.fad.fa-pen-fancy::after, .fa-duotone.fa-pen-fancy::after {
  content: "\f5ac\f5ac";
}

.fad.fa-coffee-pot::after, .fa-duotone.fa-coffee-pot::after {
  content: "\e002\e002";
}

.fad.fa-mouse-field::after, .fa-duotone.fa-mouse-field::after {
  content: "\e5a8\e5a8";
}

.fad.fa-person-digging::after, .fa-duotone.fa-person-digging::after {
  content: "\f85e\f85e";
}

.fad.fa-digging::after, .fa-duotone.fa-digging::after {
  content: "\f85e\f85e";
}

.fad.fa-shower-down::after, .fa-duotone.fa-shower-down::after {
  content: "\e24d\e24d";
}

.fad.fa-shower-alt::after, .fa-duotone.fa-shower-alt::after {
  content: "\e24d\e24d";
}

.fad.fa-box-circle-check::after, .fa-duotone.fa-box-circle-check::after {
  content: "\e0c4\e0c4";
}

.fad.fa-brightness::after, .fa-duotone.fa-brightness::after {
  content: "\e0c9\e0c9";
}

.fad.fa-car-side-bolt::after, .fa-duotone.fa-car-side-bolt::after {
  content: "\e344\e344";
}

.fad.fa-ornament::after, .fa-duotone.fa-ornament::after {
  content: "\f7b8\f7b8";
}

.fad.fa-phone-arrow-down-left::after, .fa-duotone.fa-phone-arrow-down-left::after {
  content: "\e223\e223";
}

.fad.fa-phone-arrow-down::after, .fa-duotone.fa-phone-arrow-down::after {
  content: "\e223\e223";
}

.fad.fa-phone-incoming::after, .fa-duotone.fa-phone-incoming::after {
  content: "\e223\e223";
}

.fad.fa-cloud-word::after, .fa-duotone.fa-cloud-word::after {
  content: "\e138\e138";
}

.fad.fa-hand-fingers-crossed::after, .fa-duotone.fa-hand-fingers-crossed::after {
  content: "\e1a3\e1a3";
}

.fad.fa-trash::after, .fa-duotone.fa-trash::after {
  content: "\f1f8\f1f8";
}

.fad.fa-gauge-simple::after, .fa-duotone.fa-gauge-simple::after {
  content: "\f629\f629";
}

.fad.fa-gauge-simple-med::after, .fa-duotone.fa-gauge-simple-med::after {
  content: "\f629\f629";
}

.fad.fa-tachometer-average::after, .fa-duotone.fa-tachometer-average::after {
  content: "\f629\f629";
}

.fad.fa-arrow-down-small-big::after, .fa-duotone.fa-arrow-down-small-big::after {
  content: "\f88d\f88d";
}

.fad.fa-sort-size-down-alt::after, .fa-duotone.fa-sort-size-down-alt::after {
  content: "\f88d\f88d";
}

.fad.fa-book-medical::after, .fa-duotone.fa-book-medical::after {
  content: "\f7e6\f7e6";
}

.fad.fa-face-melting::after, .fa-duotone.fa-face-melting::after {
  content: "\e483\e483";
}

.fad.fa-poo::after, .fa-duotone.fa-poo::after {
  content: "\f2fe\f2fe";
}

.fad.fa-pen-clip-slash::after, .fa-duotone.fa-pen-clip-slash::after {
  content: "\e20f\e20f";
}

.fad.fa-pen-alt-slash::after, .fa-duotone.fa-pen-alt-slash::after {
  content: "\e20f\e20f";
}

.fad.fa-quote-right::after, .fa-duotone.fa-quote-right::after {
  content: "\f10e\f10e";
}

.fad.fa-quote-right-alt::after, .fa-duotone.fa-quote-right-alt::after {
  content: "\f10e\f10e";
}

.fad.fa-scroll-old::after, .fa-duotone.fa-scroll-old::after {
  content: "\f70f\f70f";
}

.fad.fa-guitars::after, .fa-duotone.fa-guitars::after {
  content: "\f8bf\f8bf";
}

.fad.fa-phone-xmark::after, .fa-duotone.fa-phone-xmark::after {
  content: "\e227\e227";
}

.fad.fa-hose::after, .fa-duotone.fa-hose::after {
  content: "\e419\e419";
}

.fad.fa-clock-six::after, .fa-duotone.fa-clock-six::after {
  content: "\e352\e352";
}

.fad.fa-shirt::after, .fa-duotone.fa-shirt::after {
  content: "\f553\f553";
}

.fad.fa-t-shirt::after, .fa-duotone.fa-t-shirt::after {
  content: "\f553\f553";
}

.fad.fa-tshirt::after, .fa-duotone.fa-tshirt::after {
  content: "\f553\f553";
}

.fad.fa-square-r::after, .fa-duotone.fa-square-r::after {
  content: "\e27c\e27c";
}

.fad.fa-cubes::after, .fa-duotone.fa-cubes::after {
  content: "\f1b3\f1b3";
}

.fad.fa-envelope-open-dollar::after, .fa-duotone.fa-envelope-open-dollar::after {
  content: "\f657\f657";
}

.fad.fa-divide::after, .fa-duotone.fa-divide::after {
  content: "\f529\f529";
}

.fad.fa-sun-cloud::after, .fa-duotone.fa-sun-cloud::after {
  content: "\f763\f763";
}

.fad.fa-lamp-floor::after, .fa-duotone.fa-lamp-floor::after {
  content: "\e015\e015";
}

.fad.fa-square-7::after, .fa-duotone.fa-square-7::after {
  content: "\e25c\e25c";
}

.fad.fa-tenge-sign::after, .fa-duotone.fa-tenge-sign::after {
  content: "\f7d7\f7d7";
}

.fad.fa-tenge::after, .fa-duotone.fa-tenge::after {
  content: "\f7d7\f7d7";
}

.fad.fa-headphones::after, .fa-duotone.fa-headphones::after {
  content: "\f025\f025";
}

.fad.fa-hands-holding::after, .fa-duotone.fa-hands-holding::after {
  content: "\f4c2\f4c2";
}

.fad.fa-campfire::after, .fa-duotone.fa-campfire::after {
  content: "\f6ba\f6ba";
}

.fad.fa-circle-ampersand::after, .fa-duotone.fa-circle-ampersand::after {
  content: "\e0f8\e0f8";
}

.fad.fa-snowflakes::after, .fa-duotone.fa-snowflakes::after {
  content: "\f7cf\f7cf";
}

.fad.fa-hands-clapping::after, .fa-duotone.fa-hands-clapping::after {
  content: "\e1a8\e1a8";
}

.fad.fa-republican::after, .fa-duotone.fa-republican::after {
  content: "\f75e\f75e";
}

.fad.fa-leaf-maple::after, .fa-duotone.fa-leaf-maple::after {
  content: "\f6f6\f6f6";
}

.fad.fa-arrow-left::after, .fa-duotone.fa-arrow-left::after {
  content: "\f060\f060";
}

.fad.fa-person-circle-xmark::after, .fa-duotone.fa-person-circle-xmark::after {
  content: "\e543\e543";
}

.fad.fa-ruler::after, .fa-duotone.fa-ruler::after {
  content: "\f545\f545";
}

.fad.fa-cup-straw-swoosh::after, .fa-duotone.fa-cup-straw-swoosh::after {
  content: "\e364\e364";
}

.fad.fa-temperature-sun::after, .fa-duotone.fa-temperature-sun::after {
  content: "\f76a\f76a";
}

.fad.fa-temperature-hot::after, .fa-duotone.fa-temperature-hot::after {
  content: "\f76a\f76a";
}

.fad.fa-align-left::after, .fa-duotone.fa-align-left::after {
  content: "\f036\f036";
}

.fad.fa-dice-d6::after, .fa-duotone.fa-dice-d6::after {
  content: "\f6d1\f6d1";
}

.fad.fa-restroom::after, .fa-duotone.fa-restroom::after {
  content: "\f7bd\f7bd";
}

.fad.fa-high-definition::after, .fa-duotone.fa-high-definition::after {
  content: "\e1ae\e1ae";
}

.fad.fa-rectangle-hd::after, .fa-duotone.fa-rectangle-hd::after {
  content: "\e1ae\e1ae";
}

.fad.fa-j::after, .fa-duotone.fa-j::after {
  content: "JJ";
}

.fad.fa-galaxy::after, .fa-duotone.fa-galaxy::after {
  content: "\e008\e008";
}

.fad.fa-users-viewfinder::after, .fa-duotone.fa-users-viewfinder::after {
  content: "\e595\e595";
}

.fad.fa-file-video::after, .fa-duotone.fa-file-video::after {
  content: "\f1c8\f1c8";
}

.fad.fa-cherries::after, .fa-duotone.fa-cherries::after {
  content: "\e0ec\e0ec";
}

.fad.fa-up-right-from-square::after, .fa-duotone.fa-up-right-from-square::after {
  content: "\f35d\f35d";
}

.fad.fa-external-link-alt::after, .fa-duotone.fa-external-link-alt::after {
  content: "\f35d\f35d";
}

.fad.fa-circle-sort::after, .fa-duotone.fa-circle-sort::after {
  content: "\e030\e030";
}

.fad.fa-sort-circle::after, .fa-duotone.fa-sort-circle::after {
  content: "\e030\e030";
}

.fad.fa-table-cells::after, .fa-duotone.fa-table-cells::after {
  content: "\f00a\f00a";
}

.fad.fa-th::after, .fa-duotone.fa-th::after {
  content: "\f00a\f00a";
}

.fad.fa-file-pdf::after, .fa-duotone.fa-file-pdf::after {
  content: "\f1c1\f1c1";
}

.fad.fa-siren::after, .fa-duotone.fa-siren::after {
  content: "\e02d\e02d";
}

.fad.fa-arrow-up-to-dotted-line::after, .fa-duotone.fa-arrow-up-to-dotted-line::after {
  content: "\e0a1\e0a1";
}

.fad.fa-image-landscape::after, .fa-duotone.fa-image-landscape::after {
  content: "\e1b5\e1b5";
}

.fad.fa-landscape::after, .fa-duotone.fa-landscape::after {
  content: "\e1b5\e1b5";
}

.fad.fa-tank-water::after, .fa-duotone.fa-tank-water::after {
  content: "\e452\e452";
}

.fad.fa-curling-stone::after, .fa-duotone.fa-curling-stone::after {
  content: "\f44a\f44a";
}

.fad.fa-curling::after, .fa-duotone.fa-curling::after {
  content: "\f44a\f44a";
}

.fad.fa-gamepad-modern::after, .fa-duotone.fa-gamepad-modern::after {
  content: "\e5a2\e5a2";
}

.fad.fa-gamepad-alt::after, .fa-duotone.fa-gamepad-alt::after {
  content: "\e5a2\e5a2";
}

.fad.fa-messages-question::after, .fa-duotone.fa-messages-question::after {
  content: "\e1e7\e1e7";
}

.fad.fa-book-bible::after, .fa-duotone.fa-book-bible::after {
  content: "\f647\f647";
}

.fad.fa-bible::after, .fa-duotone.fa-bible::after {
  content: "\f647\f647";
}

.fad.fa-o::after, .fa-duotone.fa-o::after {
  content: "OO";
}

.fad.fa-suitcase-medical::after, .fa-duotone.fa-suitcase-medical::after {
  content: "\f0fa\f0fa";
}

.fad.fa-medkit::after, .fa-duotone.fa-medkit::after {
  content: "\f0fa\f0fa";
}

.fad.fa-briefcase-arrow-right::after, .fa-duotone.fa-briefcase-arrow-right::after {
  content: "\e2f2\e2f2";
}

.fad.fa-expand-wide::after, .fa-duotone.fa-expand-wide::after {
  content: "\f320\f320";
}

.fad.fa-clock-eleven-thirty::after, .fa-duotone.fa-clock-eleven-thirty::after {
  content: "\e348\e348";
}

.fad.fa-rv::after, .fa-duotone.fa-rv::after {
  content: "\f7be\f7be";
}

.fad.fa-user-secret::after, .fa-duotone.fa-user-secret::after {
  content: "\f21b\f21b";
}

.fad.fa-otter::after, .fa-duotone.fa-otter::after {
  content: "\f700\f700";
}

.fad.fa-dreidel::after, .fa-duotone.fa-dreidel::after {
  content: "\f792\f792";
}

.fad.fa-person-dress::after, .fa-duotone.fa-person-dress::after {
  content: "\f182\f182";
}

.fad.fa-female::after, .fa-duotone.fa-female::after {
  content: "\f182\f182";
}

.fad.fa-comment-dollar::after, .fa-duotone.fa-comment-dollar::after {
  content: "\f651\f651";
}

.fad.fa-business-time::after, .fa-duotone.fa-business-time::after {
  content: "\f64a\f64a";
}

.fad.fa-briefcase-clock::after, .fa-duotone.fa-briefcase-clock::after {
  content: "\f64a\f64a";
}

.fad.fa-flower-tulip::after, .fa-duotone.fa-flower-tulip::after {
  content: "\f801\f801";
}

.fad.fa-people-pants-simple::after, .fa-duotone.fa-people-pants-simple::after {
  content: "\e21a\e21a";
}

.fad.fa-cloud-drizzle::after, .fa-duotone.fa-cloud-drizzle::after {
  content: "\f738\f738";
}

.fad.fa-table-cells-large::after, .fa-duotone.fa-table-cells-large::after {
  content: "\f009\f009";
}

.fad.fa-th-large::after, .fa-duotone.fa-th-large::after {
  content: "\f009\f009";
}

.fad.fa-book-tanakh::after, .fa-duotone.fa-book-tanakh::after {
  content: "\f827\f827";
}

.fad.fa-tanakh::after, .fa-duotone.fa-tanakh::after {
  content: "\f827\f827";
}

.fad.fa-solar-system::after, .fa-duotone.fa-solar-system::after {
  content: "\e02f\e02f";
}

.fad.fa-seal-question::after, .fa-duotone.fa-seal-question::after {
  content: "\e243\e243";
}

.fad.fa-phone-volume::after, .fa-duotone.fa-phone-volume::after {
  content: "\f2a0\f2a0";
}

.fad.fa-volume-control-phone::after, .fa-duotone.fa-volume-control-phone::after {
  content: "\f2a0\f2a0";
}

.fad.fa-disc-drive::after, .fa-duotone.fa-disc-drive::after {
  content: "\f8b5\f8b5";
}

.fad.fa-hat-cowboy-side::after, .fa-duotone.fa-hat-cowboy-side::after {
  content: "\f8c1\f8c1";
}

.fad.fa-table-rows::after, .fa-duotone.fa-table-rows::after {
  content: "\e292\e292";
}

.fad.fa-rows::after, .fa-duotone.fa-rows::after {
  content: "\e292\e292";
}

.fad.fa-location-exclamation::after, .fa-duotone.fa-location-exclamation::after {
  content: "\f608\f608";
}

.fad.fa-map-marker-exclamation::after, .fa-duotone.fa-map-marker-exclamation::after {
  content: "\f608\f608";
}

.fad.fa-face-fearful::after, .fa-duotone.fa-face-fearful::after {
  content: "\e375\e375";
}

.fad.fa-clipboard-user::after, .fa-duotone.fa-clipboard-user::after {
  content: "\f7f3\f7f3";
}

.fad.fa-bus-school::after, .fa-duotone.fa-bus-school::after {
  content: "\f5dd\f5dd";
}

.fad.fa-film-slash::after, .fa-duotone.fa-film-slash::after {
  content: "\e179\e179";
}

.fad.fa-square-arrow-down-right::after, .fa-duotone.fa-square-arrow-down-right::after {
  content: "\e262\e262";
}

.fad.fa-book-sparkles::after, .fa-duotone.fa-book-sparkles::after {
  content: "\f6b8\f6b8";
}

.fad.fa-book-spells::after, .fa-duotone.fa-book-spells::after {
  content: "\f6b8\f6b8";
}

.fad.fa-washing-machine::after, .fa-duotone.fa-washing-machine::after {
  content: "\f898\f898";
}

.fad.fa-washer::after, .fa-duotone.fa-washer::after {
  content: "\f898\f898";
}

.fad.fa-child::after, .fa-duotone.fa-child::after {
  content: "\f1ae\f1ae";
}

.fad.fa-lira-sign::after, .fa-duotone.fa-lira-sign::after {
  content: "\f195\f195";
}

.fad.fa-user-visor::after, .fa-duotone.fa-user-visor::after {
  content: "\e04c\e04c";
}

.fad.fa-file-plus-minus::after, .fa-duotone.fa-file-plus-minus::after {
  content: "\e177\e177";
}

.fad.fa-chess-clock-flip::after, .fa-duotone.fa-chess-clock-flip::after {
  content: "\f43e\f43e";
}

.fad.fa-chess-clock-alt::after, .fa-duotone.fa-chess-clock-alt::after {
  content: "\f43e\f43e";
}

.fad.fa-satellite::after, .fa-duotone.fa-satellite::after {
  content: "\f7bf\f7bf";
}

.fad.fa-plane-lock::after, .fa-duotone.fa-plane-lock::after {
  content: "\e558\e558";
}

.fad.fa-steering-wheel::after, .fa-duotone.fa-steering-wheel::after {
  content: "\f622\f622";
}

.fad.fa-tag::after, .fa-duotone.fa-tag::after {
  content: "\f02b\f02b";
}

.fad.fa-stretcher::after, .fa-duotone.fa-stretcher::after {
  content: "\f825\f825";
}

.fad.fa-book-section::after, .fa-duotone.fa-book-section::after {
  content: "\e0c1\e0c1";
}

.fad.fa-book-law::after, .fa-duotone.fa-book-law::after {
  content: "\e0c1\e0c1";
}

.fad.fa-inboxes::after, .fa-duotone.fa-inboxes::after {
  content: "\e1bb\e1bb";
}

.fad.fa-coffee-bean::after, .fa-duotone.fa-coffee-bean::after {
  content: "\e13e\e13e";
}

.fad.fa-brackets-curly::after, .fa-duotone.fa-brackets-curly::after {
  content: "\f7ea\f7ea";
}

.fad.fa-ellipsis-stroke-vertical::after, .fa-duotone.fa-ellipsis-stroke-vertical::after {
  content: "\f39c\f39c";
}

.fad.fa-ellipsis-v-alt::after, .fa-duotone.fa-ellipsis-v-alt::after {
  content: "\f39c\f39c";
}

.fad.fa-comment::after, .fa-duotone.fa-comment::after {
  content: "\f075\f075";
}

.fad.fa-square-1::after, .fa-duotone.fa-square-1::after {
  content: "\e256\e256";
}

.fad.fa-cake-candles::after, .fa-duotone.fa-cake-candles::after {
  content: "\f1fd\f1fd";
}

.fad.fa-birthday-cake::after, .fa-duotone.fa-birthday-cake::after {
  content: "\f1fd\f1fd";
}

.fad.fa-cake::after, .fa-duotone.fa-cake::after {
  content: "\f1fd\f1fd";
}

.fad.fa-head-side::after, .fa-duotone.fa-head-side::after {
  content: "\f6e9\f6e9";
}

.fad.fa-envelope::after, .fa-duotone.fa-envelope::after {
  content: "\f0e0\f0e0";
}

.fad.fa-dolly-empty::after, .fa-duotone.fa-dolly-empty::after {
  content: "\f473\f473";
}

.fad.fa-face-tissue::after, .fa-duotone.fa-face-tissue::after {
  content: "\e39c\e39c";
}

.fad.fa-angles-up::after, .fa-duotone.fa-angles-up::after {
  content: "\f102\f102";
}

.fad.fa-angle-double-up::after, .fa-duotone.fa-angle-double-up::after {
  content: "\f102\f102";
}

.fad.fa-paperclip::after, .fa-duotone.fa-paperclip::after {
  content: "\f0c6\f0c6";
}

.fad.fa-chart-line-down::after, .fa-duotone.fa-chart-line-down::after {
  content: "\f64d\f64d";
}

.fad.fa-arrow-right-to-city::after, .fa-duotone.fa-arrow-right-to-city::after {
  content: "\e4b3\e4b3";
}

.fad.fa-lock-a::after, .fa-duotone.fa-lock-a::after {
  content: "\e422\e422";
}

.fad.fa-ribbon::after, .fa-duotone.fa-ribbon::after {
  content: "\f4d6\f4d6";
}

.fad.fa-lungs::after, .fa-duotone.fa-lungs::after {
  content: "\f604\f604";
}

.fad.fa-person-pinball::after, .fa-duotone.fa-person-pinball::after {
  content: "\e21d\e21d";
}

.fad.fa-arrow-up-9-1::after, .fa-duotone.fa-arrow-up-9-1::after {
  content: "\f887\f887";
}

.fad.fa-sort-numeric-up-alt::after, .fa-duotone.fa-sort-numeric-up-alt::after {
  content: "\f887\f887";
}

.fad.fa-apple-core::after, .fa-duotone.fa-apple-core::after {
  content: "\e08f\e08f";
}

.fad.fa-circle-y::after, .fa-duotone.fa-circle-y::after {
  content: "\e12f\e12f";
}

.fad.fa-h6::after, .fa-duotone.fa-h6::after {
  content: "\e413\e413";
}

.fad.fa-litecoin-sign::after, .fa-duotone.fa-litecoin-sign::after {
  content: "\e1d3\e1d3";
}

.fad.fa-circle-small::after, .fa-duotone.fa-circle-small::after {
  content: "\e122\e122";
}

.fad.fa-border-none::after, .fa-duotone.fa-border-none::after {
  content: "\f850\f850";
}

.fad.fa-arrow-turn-down-left::after, .fa-duotone.fa-arrow-turn-down-left::after {
  content: "\e2e1\e2e1";
}

.fad.fa-circle-nodes::after, .fa-duotone.fa-circle-nodes::after {
  content: "\e4e2\e4e2";
}

.fad.fa-parachute-box::after, .fa-duotone.fa-parachute-box::after {
  content: "\f4cd\f4cd";
}

.fad.fa-message-medical::after, .fa-duotone.fa-message-medical::after {
  content: "\f7f4\f7f4";
}

.fad.fa-comment-alt-medical::after, .fa-duotone.fa-comment-alt-medical::after {
  content: "\f7f4\f7f4";
}

.fad.fa-rugby-ball::after, .fa-duotone.fa-rugby-ball::after {
  content: "\e3c6\e3c6";
}

.fad.fa-comment-music::after, .fa-duotone.fa-comment-music::after {
  content: "\f8b0\f8b0";
}

.fad.fa-indent::after, .fa-duotone.fa-indent::after {
  content: "\f03c\f03c";
}

.fad.fa-tree-deciduous::after, .fa-duotone.fa-tree-deciduous::after {
  content: "\f400\f400";
}

.fad.fa-tree-alt::after, .fa-duotone.fa-tree-alt::after {
  content: "\f400\f400";
}

.fad.fa-puzzle-piece-simple::after, .fa-duotone.fa-puzzle-piece-simple::after {
  content: "\e231\e231";
}

.fad.fa-puzzle-piece-alt::after, .fa-duotone.fa-puzzle-piece-alt::after {
  content: "\e231\e231";
}

.fad.fa-truck-field-un::after, .fa-duotone.fa-truck-field-un::after {
  content: "\e58e\e58e";
}

.fad.fa-nfc-trash::after, .fa-duotone.fa-nfc-trash::after {
  content: "\e1fd\e1fd";
}

.fad.fa-hourglass::after, .fa-duotone.fa-hourglass::after {
  content: "\f254\f254";
}

.fad.fa-hourglass-empty::after, .fa-duotone.fa-hourglass-empty::after {
  content: "\f254\f254";
}

.fad.fa-mountain::after, .fa-duotone.fa-mountain::after {
  content: "\f6fc\f6fc";
}

.fad.fa-file-xmark::after, .fa-duotone.fa-file-xmark::after {
  content: "\f317\f317";
}

.fad.fa-file-times::after, .fa-duotone.fa-file-times::after {
  content: "\f317\f317";
}

.fad.fa-house-heart::after, .fa-duotone.fa-house-heart::after {
  content: "\f4c9\f4c9";
}

.fad.fa-home-heart::after, .fa-duotone.fa-home-heart::after {
  content: "\f4c9\f4c9";
}

.fad.fa-house-chimney-blank::after, .fa-duotone.fa-house-chimney-blank::after {
  content: "\e3b0\e3b0";
}

.fad.fa-meter-bolt::after, .fa-duotone.fa-meter-bolt::after {
  content: "\e1e9\e1e9";
}

.fad.fa-user-doctor::after, .fa-duotone.fa-user-doctor::after {
  content: "\f0f0\f0f0";
}

.fad.fa-user-md::after, .fa-duotone.fa-user-md::after {
  content: "\f0f0\f0f0";
}

.fad.fa-slash-back::after, .fa-duotone.fa-slash-back::after {
  content: "\\\\";
}

.fad.fa-circle-info::after, .fa-duotone.fa-circle-info::after {
  content: "\f05a\f05a";
}

.fad.fa-info-circle::after, .fa-duotone.fa-info-circle::after {
  content: "\f05a\f05a";
}

.fad.fa-fishing-rod::after, .fa-duotone.fa-fishing-rod::after {
  content: "\e3a8\e3a8";
}

.fad.fa-hammer-crash::after, .fa-duotone.fa-hammer-crash::after {
  content: "\e414\e414";
}

.fad.fa-cloud-meatball::after, .fa-duotone.fa-cloud-meatball::after {
  content: "\f73b\f73b";
}

.fad.fa-camera-polaroid::after, .fa-duotone.fa-camera-polaroid::after {
  content: "\f8aa\f8aa";
}

.fad.fa-camera::after, .fa-duotone.fa-camera::after {
  content: "\f030\f030";
}

.fad.fa-camera-alt::after, .fa-duotone.fa-camera-alt::after {
  content: "\f030\f030";
}

.fad.fa-square-virus::after, .fa-duotone.fa-square-virus::after {
  content: "\e578\e578";
}

.fad.fa-cart-arrow-up::after, .fa-duotone.fa-cart-arrow-up::after {
  content: "\e3ee\e3ee";
}

.fad.fa-meteor::after, .fa-duotone.fa-meteor::after {
  content: "\f753\f753";
}

.fad.fa-car-on::after, .fa-duotone.fa-car-on::after {
  content: "\e4dd\e4dd";
}

.fad.fa-sleigh::after, .fa-duotone.fa-sleigh::after {
  content: "\f7cc\f7cc";
}

.fad.fa-arrow-down-1-9::after, .fa-duotone.fa-arrow-down-1-9::after {
  content: "\f162\f162";
}

.fad.fa-sort-numeric-asc::after, .fa-duotone.fa-sort-numeric-asc::after {
  content: "\f162\f162";
}

.fad.fa-sort-numeric-down::after, .fa-duotone.fa-sort-numeric-down::after {
  content: "\f162\f162";
}

.fad.fa-square-4::after, .fa-duotone.fa-square-4::after {
  content: "\e259\e259";
}

.fad.fa-hand-holding-droplet::after, .fa-duotone.fa-hand-holding-droplet::after {
  content: "\f4c1\f4c1";
}

.fad.fa-hand-holding-water::after, .fa-duotone.fa-hand-holding-water::after {
  content: "\f4c1\f4c1";
}

.fad.fa-waveform::after, .fa-duotone.fa-waveform::after {
  content: "\f8f1\f8f1";
}

.fad.fa-water::after, .fa-duotone.fa-water::after {
  content: "\f773\f773";
}

.fad.fa-star-sharp-half-stroke::after, .fa-duotone.fa-star-sharp-half-stroke::after {
  content: "\e28d\e28d";
}

.fad.fa-star-sharp-half-alt::after, .fa-duotone.fa-star-sharp-half-alt::after {
  content: "\e28d\e28d";
}

.fad.fa-nfc-signal::after, .fa-duotone.fa-nfc-signal::after {
  content: "\e1fb\e1fb";
}

.fad.fa-plane-prop::after, .fa-duotone.fa-plane-prop::after {
  content: "\e22b\e22b";
}

.fad.fa-calendar-check::after, .fa-duotone.fa-calendar-check::after {
  content: "\f274\f274";
}

.fad.fa-clock-desk::after, .fa-duotone.fa-clock-desk::after {
  content: "\e134\e134";
}

.fad.fa-calendar-clock::after, .fa-duotone.fa-calendar-clock::after {
  content: "\e0d2\e0d2";
}

.fad.fa-calendar-time::after, .fa-duotone.fa-calendar-time::after {
  content: "\e0d2\e0d2";
}

.fad.fa-braille::after, .fa-duotone.fa-braille::after {
  content: "\f2a1\f2a1";
}

.fad.fa-prescription-bottle-medical::after, .fa-duotone.fa-prescription-bottle-medical::after {
  content: "\f486\f486";
}

.fad.fa-prescription-bottle-alt::after, .fa-duotone.fa-prescription-bottle-alt::after {
  content: "\f486\f486";
}

.fad.fa-plate-utensils::after, .fa-duotone.fa-plate-utensils::after {
  content: "\e43b\e43b";
}

.fad.fa-family-pants::after, .fa-duotone.fa-family-pants::after {
  content: "\e302\e302";
}

.fad.fa-hose-reel::after, .fa-duotone.fa-hose-reel::after {
  content: "\e41a\e41a";
}

.fad.fa-house-window::after, .fa-duotone.fa-house-window::after {
  content: "\e3b3\e3b3";
}

.fad.fa-landmark::after, .fa-duotone.fa-landmark::after {
  content: "\f66f\f66f";
}

.fad.fa-truck::after, .fa-duotone.fa-truck::after {
  content: "\f0d1\f0d1";
}

.fad.fa-crosshairs::after, .fa-duotone.fa-crosshairs::after {
  content: "\f05b\f05b";
}

.fad.fa-cloud-rainbow::after, .fa-duotone.fa-cloud-rainbow::after {
  content: "\f73e\f73e";
}

.fad.fa-person-cane::after, .fa-duotone.fa-person-cane::after {
  content: "\e53c\e53c";
}

.fad.fa-alien::after, .fa-duotone.fa-alien::after {
  content: "\f8f5\f8f5";
}

.fad.fa-tent::after, .fa-duotone.fa-tent::after {
  content: "\e57d\e57d";
}

.fad.fa-vest-patches::after, .fa-duotone.fa-vest-patches::after {
  content: "\e086\e086";
}

.fad.fa-people-dress-simple::after, .fa-duotone.fa-people-dress-simple::after {
  content: "\e218\e218";
}

.fad.fa-check-double::after, .fa-duotone.fa-check-double::after {
  content: "\f560\f560";
}

.fad.fa-arrow-down-a-z::after, .fa-duotone.fa-arrow-down-a-z::after {
  content: "\f15d\f15d";
}

.fad.fa-sort-alpha-asc::after, .fa-duotone.fa-sort-alpha-asc::after {
  content: "\f15d\f15d";
}

.fad.fa-sort-alpha-down::after, .fa-duotone.fa-sort-alpha-down::after {
  content: "\f15d\f15d";
}

.fad.fa-bowling-ball-pin::after, .fa-duotone.fa-bowling-ball-pin::after {
  content: "\e0c3\e0c3";
}

.fad.fa-bell-school-slash::after, .fa-duotone.fa-bell-school-slash::after {
  content: "\f5d6\f5d6";
}

.fad.fa-plus-large::after, .fa-duotone.fa-plus-large::after {
  content: "\e59e\e59e";
}

.fad.fa-money-bill-wheat::after, .fa-duotone.fa-money-bill-wheat::after {
  content: "\e52a\e52a";
}

.fad.fa-camera-viewfinder::after, .fa-duotone.fa-camera-viewfinder::after {
  content: "\e0da\e0da";
}

.fad.fa-screenshot::after, .fa-duotone.fa-screenshot::after {
  content: "\e0da\e0da";
}

.fad.fa-message-music::after, .fa-duotone.fa-message-music::after {
  content: "\f8af\f8af";
}

.fad.fa-comment-alt-music::after, .fa-duotone.fa-comment-alt-music::after {
  content: "\f8af\f8af";
}

.fad.fa-car-building::after, .fa-duotone.fa-car-building::after {
  content: "\f859\f859";
}

.fad.fa-border-bottom-right::after, .fa-duotone.fa-border-bottom-right::after {
  content: "\f854\f854";
}

.fad.fa-border-style-alt::after, .fa-duotone.fa-border-style-alt::after {
  content: "\f854\f854";
}

.fad.fa-octagon::after, .fa-duotone.fa-octagon::after {
  content: "\f306\f306";
}

.fad.fa-comment-arrow-up-right::after, .fa-duotone.fa-comment-arrow-up-right::after {
  content: "\e145\e145";
}

.fad.fa-octagon-divide::after, .fa-duotone.fa-octagon-divide::after {
  content: "\e203\e203";
}

.fad.fa-cookie::after, .fa-duotone.fa-cookie::after {
  content: "\f563\f563";
}

.fad.fa-arrow-rotate-left::after, .fa-duotone.fa-arrow-rotate-left::after {
  content: "\f0e2\f0e2";
}

.fad.fa-arrow-left-rotate::after, .fa-duotone.fa-arrow-left-rotate::after {
  content: "\f0e2\f0e2";
}

.fad.fa-arrow-rotate-back::after, .fa-duotone.fa-arrow-rotate-back::after {
  content: "\f0e2\f0e2";
}

.fad.fa-arrow-rotate-backward::after, .fa-duotone.fa-arrow-rotate-backward::after {
  content: "\f0e2\f0e2";
}

.fad.fa-undo::after, .fa-duotone.fa-undo::after {
  content: "\f0e2\f0e2";
}

.fad.fa-tv-music::after, .fa-duotone.fa-tv-music::after {
  content: "\f8e6\f8e6";
}

.fad.fa-hard-drive::after, .fa-duotone.fa-hard-drive::after {
  content: "\f0a0\f0a0";
}

.fad.fa-hdd::after, .fa-duotone.fa-hdd::after {
  content: "\f0a0\f0a0";
}

.fad.fa-reel::after, .fa-duotone.fa-reel::after {
  content: "\e238\e238";
}

.fad.fa-face-grin-squint-tears::after, .fa-duotone.fa-face-grin-squint-tears::after {
  content: "\f586\f586";
}

.fad.fa-grin-squint-tears::after, .fa-duotone.fa-grin-squint-tears::after {
  content: "\f586\f586";
}

.fad.fa-dumbbell::after, .fa-duotone.fa-dumbbell::after {
  content: "\f44b\f44b";
}

.fad.fa-rectangle-list::after, .fa-duotone.fa-rectangle-list::after {
  content: "\f022\f022";
}

.fad.fa-list-alt::after, .fa-duotone.fa-list-alt::after {
  content: "\f022\f022";
}

.fad.fa-tarp-droplet::after, .fa-duotone.fa-tarp-droplet::after {
  content: "\e57c\e57c";
}

.fad.fa-alarm-exclamation::after, .fa-duotone.fa-alarm-exclamation::after {
  content: "\f843\f843";
}

.fad.fa-house-medical-circle-check::after, .fa-duotone.fa-house-medical-circle-check::after {
  content: "\e511\e511";
}

.fad.fa-traffic-cone::after, .fa-duotone.fa-traffic-cone::after {
  content: "\f636\f636";
}

.fad.fa-grate::after, .fa-duotone.fa-grate::after {
  content: "\e193\e193";
}

.fad.fa-arrow-down-right::after, .fa-duotone.fa-arrow-down-right::after {
  content: "\e093\e093";
}

.fad.fa-person-skiing-nordic::after, .fa-duotone.fa-person-skiing-nordic::after {
  content: "\f7ca\f7ca";
}

.fad.fa-skiing-nordic::after, .fa-duotone.fa-skiing-nordic::after {
  content: "\f7ca\f7ca";
}

.fad.fa-calendar-plus::after, .fa-duotone.fa-calendar-plus::after {
  content: "\f271\f271";
}

.fad.fa-person-from-portal::after, .fa-duotone.fa-person-from-portal::after {
  content: "\e023\e023";
}

.fad.fa-portal-exit::after, .fa-duotone.fa-portal-exit::after {
  content: "\e023\e023";
}

.fad.fa-plane-arrival::after, .fa-duotone.fa-plane-arrival::after {
  content: "\f5af\f5af";
}

.fad.fa-cowbell-circle-plus::after, .fa-duotone.fa-cowbell-circle-plus::after {
  content: "\f8b4\f8b4";
}

.fad.fa-cowbell-more::after, .fa-duotone.fa-cowbell-more::after {
  content: "\f8b4\f8b4";
}

.fad.fa-circle-left::after, .fa-duotone.fa-circle-left::after {
  content: "\f359\f359";
}

.fad.fa-arrow-alt-circle-left::after, .fa-duotone.fa-arrow-alt-circle-left::after {
  content: "\f359\f359";
}

.fad.fa-distribute-spacing-vertical::after, .fa-duotone.fa-distribute-spacing-vertical::after {
  content: "\e366\e366";
}

.fad.fa-signal-bars-fair::after, .fa-duotone.fa-signal-bars-fair::after {
  content: "\f692\f692";
}

.fad.fa-signal-alt-2::after, .fa-duotone.fa-signal-alt-2::after {
  content: "\f692\f692";
}

.fad.fa-sportsball::after, .fa-duotone.fa-sportsball::after {
  content: "\e44b\e44b";
}

.fad.fa-train-subway::after, .fa-duotone.fa-train-subway::after {
  content: "\f239\f239";
}

.fad.fa-subway::after, .fa-duotone.fa-subway::after {
  content: "\f239\f239";
}

.fad.fa-chart-gantt::after, .fa-duotone.fa-chart-gantt::after {
  content: "\e0e4\e0e4";
}

.fad.fa-face-smile-upside-down::after, .fa-duotone.fa-face-smile-upside-down::after {
  content: "\e395\e395";
}

.fad.fa-ball-pile::after, .fa-duotone.fa-ball-pile::after {
  content: "\f77e\f77e";
}

.fad.fa-badge-dollar::after, .fa-duotone.fa-badge-dollar::after {
  content: "\f645\f645";
}

.fad.fa-money-bills-simple::after, .fa-duotone.fa-money-bills-simple::after {
  content: "\e1f4\e1f4";
}

.fad.fa-money-bills-alt::after, .fa-duotone.fa-money-bills-alt::after {
  content: "\e1f4\e1f4";
}

.fad.fa-list-timeline::after, .fa-duotone.fa-list-timeline::after {
  content: "\e1d1\e1d1";
}

.fad.fa-indian-rupee-sign::after, .fa-duotone.fa-indian-rupee-sign::after {
  content: "\e1bc\e1bc";
}

.fad.fa-indian-rupee::after, .fa-duotone.fa-indian-rupee::after {
  content: "\e1bc\e1bc";
}

.fad.fa-inr::after, .fa-duotone.fa-inr::after {
  content: "\e1bc\e1bc";
}

.fad.fa-crop-simple::after, .fa-duotone.fa-crop-simple::after {
  content: "\f565\f565";
}

.fad.fa-crop-alt::after, .fa-duotone.fa-crop-alt::after {
  content: "\f565\f565";
}

.fad.fa-money-bill-1::after, .fa-duotone.fa-money-bill-1::after {
  content: "\f3d1\f3d1";
}

.fad.fa-money-bill-alt::after, .fa-duotone.fa-money-bill-alt::after {
  content: "\f3d1\f3d1";
}

.fad.fa-left-long::after, .fa-duotone.fa-left-long::after {
  content: "\f30a\f30a";
}

.fad.fa-long-arrow-alt-left::after, .fa-duotone.fa-long-arrow-alt-left::after {
  content: "\f30a\f30a";
}

.fad.fa-keyboard-down::after, .fa-duotone.fa-keyboard-down::after {
  content: "\e1c2\e1c2";
}

.fad.fa-circle-up-right::after, .fa-duotone.fa-circle-up-right::after {
  content: "\e129\e129";
}

.fad.fa-cloud-bolt-moon::after, .fa-duotone.fa-cloud-bolt-moon::after {
  content: "\f76d\f76d";
}

.fad.fa-thunderstorm-moon::after, .fa-duotone.fa-thunderstorm-moon::after {
  content: "\f76d\f76d";
}

.fad.fa-dna::after, .fa-duotone.fa-dna::after {
  content: "\f471\f471";
}

.fad.fa-virus-slash::after, .fa-duotone.fa-virus-slash::after {
  content: "\e075\e075";
}

.fad.fa-bracket-round-right::after, .fa-duotone.fa-bracket-round-right::after {
  content: "))";
}

.fad.fa-circle-5::after, .fa-duotone.fa-circle-5::after {
  content: "\e0f2\e0f2";
}

.fad.fa-minus::after, .fa-duotone.fa-minus::after {
  content: "\f068\f068";
}

.fad.fa-subtract::after, .fa-duotone.fa-subtract::after {
  content: "\f068\f068";
}

.fad.fa-fire-flame::after, .fa-duotone.fa-fire-flame::after {
  content: "\f6df\f6df";
}

.fad.fa-flame::after, .fa-duotone.fa-flame::after {
  content: "\f6df\f6df";
}

.fad.fa-right-to-line::after, .fa-duotone.fa-right-to-line::after {
  content: "\f34c\f34c";
}

.fad.fa-arrow-alt-to-right::after, .fa-duotone.fa-arrow-alt-to-right::after {
  content: "\f34c\f34c";
}

.fad.fa-child-rifle::after, .fa-duotone.fa-child-rifle::after {
  content: "\e4e0\e4e0";
}

.fad.fa-gif::after, .fa-duotone.fa-gif::after {
  content: "\e190\e190";
}

.fad.fa-chess::after, .fa-duotone.fa-chess::after {
  content: "\f439\f439";
}

.fad.fa-trash-slash::after, .fa-duotone.fa-trash-slash::after {
  content: "\e2b3\e2b3";
}

.fad.fa-arrow-left-long::after, .fa-duotone.fa-arrow-left-long::after {
  content: "\f177\f177";
}

.fad.fa-long-arrow-left::after, .fa-duotone.fa-long-arrow-left::after {
  content: "\f177\f177";
}

.fad.fa-plug-circle-check::after, .fa-duotone.fa-plug-circle-check::after {
  content: "\e55c\e55c";
}

.fad.fa-font-case::after, .fa-duotone.fa-font-case::after {
  content: "\f866\f866";
}

.fad.fa-street-view::after, .fa-duotone.fa-street-view::after {
  content: "\f21d\f21d";
}

.fad.fa-arrow-down-left::after, .fa-duotone.fa-arrow-down-left::after {
  content: "\e091\e091";
}

.fad.fa-franc-sign::after, .fa-duotone.fa-franc-sign::after {
  content: "\e18f\e18f";
}

.fad.fa-flask-round-poison::after, .fa-duotone.fa-flask-round-poison::after {
  content: "\f6e0\f6e0";
}

.fad.fa-flask-poison::after, .fa-duotone.fa-flask-poison::after {
  content: "\f6e0\f6e0";
}

.fad.fa-volume-off::after, .fa-duotone.fa-volume-off::after {
  content: "\f026\f026";
}

.fad.fa-book-circle-arrow-right::after, .fa-duotone.fa-book-circle-arrow-right::after {
  content: "\e0bc\e0bc";
}

.fad.fa-chart-user::after, .fa-duotone.fa-chart-user::after {
  content: "\f6a3\f6a3";
}

.fad.fa-user-chart::after, .fa-duotone.fa-user-chart::after {
  content: "\f6a3\f6a3";
}

.fad.fa-hands-asl-interpreting::after, .fa-duotone.fa-hands-asl-interpreting::after {
  content: "\f2a3\f2a3";
}

.fad.fa-american-sign-language-interpreting::after, .fa-duotone.fa-american-sign-language-interpreting::after {
  content: "\f2a3\f2a3";
}

.fad.fa-asl-interpreting::after, .fa-duotone.fa-asl-interpreting::after {
  content: "\f2a3\f2a3";
}

.fad.fa-hands-american-sign-language-interpreting::after, .fa-duotone.fa-hands-american-sign-language-interpreting::after {
  content: "\f2a3\f2a3";
}

.fad.fa-presentation-screen::after, .fa-duotone.fa-presentation-screen::after {
  content: "\f685\f685";
}

.fad.fa-presentation::after, .fa-duotone.fa-presentation::after {
  content: "\f685\f685";
}

.fad.fa-circle-bolt::after, .fa-duotone.fa-circle-bolt::after {
  content: "\e0fe\e0fe";
}

.fad.fa-face-smile-halo::after, .fa-duotone.fa-face-smile-halo::after {
  content: "\e38f\e38f";
}

.fad.fa-cart-circle-arrow-down::after, .fa-duotone.fa-cart-circle-arrow-down::after {
  content: "\e3ef\e3ef";
}

.fad.fa-house-person-return::after, .fa-duotone.fa-house-person-return::after {
  content: "\e011\e011";
}

.fad.fa-house-person-arrive::after, .fa-duotone.fa-house-person-arrive::after {
  content: "\e011\e011";
}

.fad.fa-house-return::after, .fa-duotone.fa-house-return::after {
  content: "\e011\e011";
}

.fad.fa-message-xmark::after, .fa-duotone.fa-message-xmark::after {
  content: "\f4ab\f4ab";
}

.fad.fa-comment-alt-times::after, .fa-duotone.fa-comment-alt-times::after {
  content: "\f4ab\f4ab";
}

.fad.fa-message-times::after, .fa-duotone.fa-message-times::after {
  content: "\f4ab\f4ab";
}

.fad.fa-file-certificate::after, .fa-duotone.fa-file-certificate::after {
  content: "\f5f3\f5f3";
}

.fad.fa-file-award::after, .fa-duotone.fa-file-award::after {
  content: "\f5f3\f5f3";
}

.fad.fa-user-doctor-hair-long::after, .fa-duotone.fa-user-doctor-hair-long::after {
  content: "\e459\e459";
}

.fad.fa-camera-security::after, .fa-duotone.fa-camera-security::after {
  content: "\f8fe\f8fe";
}

.fad.fa-camera-home::after, .fa-duotone.fa-camera-home::after {
  content: "\f8fe\f8fe";
}

.fad.fa-gear::after, .fa-duotone.fa-gear::after {
  content: "\f013\f013";
}

.fad.fa-cog::after, .fa-duotone.fa-cog::after {
  content: "\f013\f013";
}

.fad.fa-droplet-slash::after, .fa-duotone.fa-droplet-slash::after {
  content: "\f5c7\f5c7";
}

.fad.fa-tint-slash::after, .fa-duotone.fa-tint-slash::after {
  content: "\f5c7\f5c7";
}

.fad.fa-book-heart::after, .fa-duotone.fa-book-heart::after {
  content: "\f499\f499";
}

.fad.fa-mosque::after, .fa-duotone.fa-mosque::after {
  content: "\f678\f678";
}

.fad.fa-duck::after, .fa-duotone.fa-duck::after {
  content: "\f6d8\f6d8";
}

.fad.fa-mosquito::after, .fa-duotone.fa-mosquito::after {
  content: "\e52b\e52b";
}

.fad.fa-star-of-david::after, .fa-duotone.fa-star-of-david::after {
  content: "\f69a\f69a";
}

.fad.fa-flag-swallowtail::after, .fa-duotone.fa-flag-swallowtail::after {
  content: "\f74c\f74c";
}

.fad.fa-flag-alt::after, .fa-duotone.fa-flag-alt::after {
  content: "\f74c\f74c";
}

.fad.fa-person-military-rifle::after, .fa-duotone.fa-person-military-rifle::after {
  content: "\e54b\e54b";
}

.fad.fa-car-garage::after, .fa-duotone.fa-car-garage::after {
  content: "\f5e2\f5e2";
}

.fad.fa-cart-shopping::after, .fa-duotone.fa-cart-shopping::after {
  content: "\f07a\f07a";
}

.fad.fa-shopping-cart::after, .fa-duotone.fa-shopping-cart::after {
  content: "\f07a\f07a";
}

.fad.fa-book-font::after, .fa-duotone.fa-book-font::after {
  content: "\e0bf\e0bf";
}

.fad.fa-shield-plus::after, .fa-duotone.fa-shield-plus::after {
  content: "\e24a\e24a";
}

.fad.fa-vials::after, .fa-duotone.fa-vials::after {
  content: "\f493\f493";
}

.fad.fa-eye-dropper-full::after, .fa-duotone.fa-eye-dropper-full::after {
  content: "\e172\e172";
}

.fad.fa-distribute-spacing-horizontal::after, .fa-duotone.fa-distribute-spacing-horizontal::after {
  content: "\e365\e365";
}

.fad.fa-tablet-rugged::after, .fa-duotone.fa-tablet-rugged::after {
  content: "\f48f\f48f";
}

.fad.fa-temperature-snow::after, .fa-duotone.fa-temperature-snow::after {
  content: "\f768\f768";
}

.fad.fa-temperature-frigid::after, .fa-duotone.fa-temperature-frigid::after {
  content: "\f768\f768";
}

.fad.fa-moped::after, .fa-duotone.fa-moped::after {
  content: "\e3b9\e3b9";
}

.fad.fa-face-smile-plus::after, .fa-duotone.fa-face-smile-plus::after {
  content: "\f5b9\f5b9";
}

.fad.fa-smile-plus::after, .fa-duotone.fa-smile-plus::after {
  content: "\f5b9\f5b9";
}

.fad.fa-radio-tuner::after, .fa-duotone.fa-radio-tuner::after {
  content: "\f8d8\f8d8";
}

.fad.fa-radio-alt::after, .fa-duotone.fa-radio-alt::after {
  content: "\f8d8\f8d8";
}

.fad.fa-face-swear::after, .fa-duotone.fa-face-swear::after {
  content: "\e399\e399";
}

.fad.fa-water-arrow-down::after, .fa-duotone.fa-water-arrow-down::after {
  content: "\f774\f774";
}

.fad.fa-water-lower::after, .fa-duotone.fa-water-lower::after {
  content: "\f774\f774";
}

.fad.fa-scanner-touchscreen::after, .fa-duotone.fa-scanner-touchscreen::after {
  content: "\f48a\f48a";
}

.fad.fa-circle-7::after, .fa-duotone.fa-circle-7::after {
  content: "\e0f4\e0f4";
}

.fad.fa-plug-circle-plus::after, .fa-duotone.fa-plug-circle-plus::after {
  content: "\e55f\e55f";
}

.fad.fa-person-ski-jumping::after, .fa-duotone.fa-person-ski-jumping::after {
  content: "\f7c7\f7c7";
}

.fad.fa-ski-jump::after, .fa-duotone.fa-ski-jump::after {
  content: "\f7c7\f7c7";
}

.fad.fa-place-of-worship::after, .fa-duotone.fa-place-of-worship::after {
  content: "\f67f\f67f";
}

.fad.fa-water-arrow-up::after, .fa-duotone.fa-water-arrow-up::after {
  content: "\f775\f775";
}

.fad.fa-water-rise::after, .fa-duotone.fa-water-rise::after {
  content: "\f775\f775";
}

.fad.fa-waveform-lines::after, .fa-duotone.fa-waveform-lines::after {
  content: "\f8f2\f8f2";
}

.fad.fa-waveform-path::after, .fa-duotone.fa-waveform-path::after {
  content: "\f8f2\f8f2";
}

.fad.fa-split::after, .fa-duotone.fa-split::after {
  content: "\e254\e254";
}

.fad.fa-film-canister::after, .fa-duotone.fa-film-canister::after {
  content: "\f8b7\f8b7";
}

.fad.fa-film-cannister::after, .fa-duotone.fa-film-cannister::after {
  content: "\f8b7\f8b7";
}

.fad.fa-folder-xmark::after, .fa-duotone.fa-folder-xmark::after {
  content: "\f65f\f65f";
}

.fad.fa-folder-times::after, .fa-duotone.fa-folder-times::after {
  content: "\f65f\f65f";
}

.fad.fa-toilet-paper-blank::after, .fa-duotone.fa-toilet-paper-blank::after {
  content: "\f71f\f71f";
}

.fad.fa-toilet-paper-alt::after, .fa-duotone.fa-toilet-paper-alt::after {
  content: "\f71f\f71f";
}

.fad.fa-tablet-screen::after, .fa-duotone.fa-tablet-screen::after {
  content: "\f3fc\f3fc";
}

.fad.fa-tablet-android-alt::after, .fa-duotone.fa-tablet-android-alt::after {
  content: "\f3fc\f3fc";
}

.fad.fa-hexagon-vertical-nft-slanted::after, .fa-duotone.fa-hexagon-vertical-nft-slanted::after {
  content: "\e506\e506";
}

.fad.fa-folder-music::after, .fa-duotone.fa-folder-music::after {
  content: "\e18d\e18d";
}

.fad.fa-display-medical::after, .fa-duotone.fa-display-medical::after {
  content: "\e166\e166";
}

.fad.fa-desktop-medical::after, .fa-duotone.fa-desktop-medical::after {
  content: "\e166\e166";
}

.fad.fa-share-all::after, .fa-duotone.fa-share-all::after {
  content: "\f367\f367";
}

.fad.fa-peapod::after, .fa-duotone.fa-peapod::after {
  content: "\e31c\e31c";
}

.fad.fa-chess-clock::after, .fa-duotone.fa-chess-clock::after {
  content: "\f43d\f43d";
}

.fad.fa-axe::after, .fa-duotone.fa-axe::after {
  content: "\f6b2\f6b2";
}

.fad.fa-square-d::after, .fa-duotone.fa-square-d::after {
  content: "\e268\e268";
}

.fad.fa-grip-vertical::after, .fa-duotone.fa-grip-vertical::after {
  content: "\f58e\f58e";
}

.fad.fa-mobile-signal-out::after, .fa-duotone.fa-mobile-signal-out::after {
  content: "\e1f0\e1f0";
}

.fad.fa-arrow-turn-up::after, .fa-duotone.fa-arrow-turn-up::after {
  content: "\f148\f148";
}

.fad.fa-level-up::after, .fa-duotone.fa-level-up::after {
  content: "\f148\f148";
}

.fad.fa-u::after, .fa-duotone.fa-u::after {
  content: "UU";
}

.fad.fa-arrow-up-from-dotted-line::after, .fa-duotone.fa-arrow-up-from-dotted-line::after {
  content: "\e09b\e09b";
}

.fad.fa-square-root-variable::after, .fa-duotone.fa-square-root-variable::after {
  content: "\f698\f698";
}

.fad.fa-square-root-alt::after, .fa-duotone.fa-square-root-alt::after {
  content: "\f698\f698";
}

.fad.fa-light-switch-on::after, .fa-duotone.fa-light-switch-on::after {
  content: "\e019\e019";
}

.fad.fa-arrow-down-arrow-up::after, .fa-duotone.fa-arrow-down-arrow-up::after {
  content: "\f883\f883";
}

.fad.fa-sort-alt::after, .fa-duotone.fa-sort-alt::after {
  content: "\f883\f883";
}

.fad.fa-raindrops::after, .fa-duotone.fa-raindrops::after {
  content: "\f75c\f75c";
}

.fad.fa-dash::after, .fa-duotone.fa-dash::after {
  content: "\e404\e404";
}

.fad.fa-minus-large::after, .fa-duotone.fa-minus-large::after {
  content: "\e404\e404";
}

.fad.fa-clock::after, .fa-duotone.fa-clock::after {
  content: "\f017\f017";
}

.fad.fa-clock-four::after, .fa-duotone.fa-clock-four::after {
  content: "\f017\f017";
}

.fad.fa-input-numeric::after, .fa-duotone.fa-input-numeric::after {
  content: "\e1bd\e1bd";
}

.fad.fa-truck-tow::after, .fa-duotone.fa-truck-tow::after {
  content: "\e2b8\e2b8";
}

.fad.fa-backward-step::after, .fa-duotone.fa-backward-step::after {
  content: "\f048\f048";
}

.fad.fa-step-backward::after, .fa-duotone.fa-step-backward::after {
  content: "\f048\f048";
}

.fad.fa-pallet::after, .fa-duotone.fa-pallet::after {
  content: "\f482\f482";
}

.fad.fa-car-bolt::after, .fa-duotone.fa-car-bolt::after {
  content: "\e341\e341";
}

.fad.fa-arrows-maximize::after, .fa-duotone.fa-arrows-maximize::after {
  content: "\f31d\f31d";
}

.fad.fa-expand-arrows::after, .fa-duotone.fa-expand-arrows::after {
  content: "\f31d\f31d";
}

.fad.fa-faucet::after, .fa-duotone.fa-faucet::after {
  content: "\e005\e005";
}

.fad.fa-cloud-sleet::after, .fa-duotone.fa-cloud-sleet::after {
  content: "\f741\f741";
}

.fad.fa-lamp-street::after, .fa-duotone.fa-lamp-street::after {
  content: "\e1c5\e1c5";
}

.fad.fa-list-radio::after, .fa-duotone.fa-list-radio::after {
  content: "\e1d0\e1d0";
}

.fad.fa-pen-nib-slash::after, .fa-duotone.fa-pen-nib-slash::after {
  content: "\e4a1\e4a1";
}

.fad.fa-baseball-bat-ball::after, .fa-duotone.fa-baseball-bat-ball::after {
  content: "\f432\f432";
}

.fad.fa-square-up-left::after, .fa-duotone.fa-square-up-left::after {
  content: "\e282\e282";
}

.fad.fa-overline::after, .fa-duotone.fa-overline::after {
  content: "\f876\f876";
}

.fad.fa-s::after, .fa-duotone.fa-s::after {
  content: "SS";
}

.fad.fa-timeline::after, .fa-duotone.fa-timeline::after {
  content: "\e29c\e29c";
}

.fad.fa-keyboard::after, .fa-duotone.fa-keyboard::after {
  content: "\f11c\f11c";
}

.fad.fa-arrows-from-dotted-line::after, .fa-duotone.fa-arrows-from-dotted-line::after {
  content: "\e0a3\e0a3";
}

.fad.fa-usb-drive::after, .fa-duotone.fa-usb-drive::after {
  content: "\f8e9\f8e9";
}

.fad.fa-ballot::after, .fa-duotone.fa-ballot::after {
  content: "\f732\f732";
}

.fad.fa-caret-down::after, .fa-duotone.fa-caret-down::after {
  content: "\f0d7\f0d7";
}

.fad.fa-location-dot-slash::after, .fa-duotone.fa-location-dot-slash::after {
  content: "\f605\f605";
}

.fad.fa-map-marker-alt-slash::after, .fa-duotone.fa-map-marker-alt-slash::after {
  content: "\f605\f605";
}

.fad.fa-cards::after, .fa-duotone.fa-cards::after {
  content: "\e3ed\e3ed";
}

.fad.fa-house-chimney-medical::after, .fa-duotone.fa-house-chimney-medical::after {
  content: "\f7f2\f7f2";
}

.fad.fa-clinic-medical::after, .fa-duotone.fa-clinic-medical::after {
  content: "\f7f2\f7f2";
}

.fad.fa-boxing-glove::after, .fa-duotone.fa-boxing-glove::after {
  content: "\f438\f438";
}

.fad.fa-glove-boxing::after, .fa-duotone.fa-glove-boxing::after {
  content: "\f438\f438";
}

.fad.fa-temperature-three-quarters::after, .fa-duotone.fa-temperature-three-quarters::after {
  content: "\f2c8\f2c8";
}

.fad.fa-temperature-3::after, .fa-duotone.fa-temperature-3::after {
  content: "\f2c8\f2c8";
}

.fad.fa-thermometer-3::after, .fa-duotone.fa-thermometer-3::after {
  content: "\f2c8\f2c8";
}

.fad.fa-thermometer-three-quarters::after, .fa-duotone.fa-thermometer-three-quarters::after {
  content: "\f2c8\f2c8";
}

.fad.fa-bell-school::after, .fa-duotone.fa-bell-school::after {
  content: "\f5d5\f5d5";
}

.fad.fa-mobile-screen::after, .fa-duotone.fa-mobile-screen::after {
  content: "\f3cf\f3cf";
}

.fad.fa-mobile-android-alt::after, .fa-duotone.fa-mobile-android-alt::after {
  content: "\f3cf\f3cf";
}

.fad.fa-plane-up::after, .fa-duotone.fa-plane-up::after {
  content: "\e22d\e22d";
}

.fad.fa-folder-heart::after, .fa-duotone.fa-folder-heart::after {
  content: "\e189\e189";
}

.fad.fa-circle-location-arrow::after, .fa-duotone.fa-circle-location-arrow::after {
  content: "\f602\f602";
}

.fad.fa-location-circle::after, .fa-duotone.fa-location-circle::after {
  content: "\f602\f602";
}

.fad.fa-face-head-bandage::after, .fa-duotone.fa-face-head-bandage::after {
  content: "\e37a\e37a";
}

.fad.fa-sushi-roll::after, .fa-duotone.fa-sushi-roll::after {
  content: "\e48b\e48b";
}

.fad.fa-maki-roll::after, .fa-duotone.fa-maki-roll::after {
  content: "\e48b\e48b";
}

.fad.fa-makizushi::after, .fa-duotone.fa-makizushi::after {
  content: "\e48b\e48b";
}

.fad.fa-car-bump::after, .fa-duotone.fa-car-bump::after {
  content: "\f5e0\f5e0";
}

.fad.fa-piggy-bank::after, .fa-duotone.fa-piggy-bank::after {
  content: "\f4d3\f4d3";
}

.fad.fa-racquet::after, .fa-duotone.fa-racquet::after {
  content: "\f45a\f45a";
}

.fad.fa-car-mirrors::after, .fa-duotone.fa-car-mirrors::after {
  content: "\e343\e343";
}

.fad.fa-industry-windows::after, .fa-duotone.fa-industry-windows::after {
  content: "\f3b3\f3b3";
}

.fad.fa-industry-alt::after, .fa-duotone.fa-industry-alt::after {
  content: "\f3b3\f3b3";
}

.fad.fa-bolt-auto::after, .fa-duotone.fa-bolt-auto::after {
  content: "\e0b6\e0b6";
}

.fad.fa-battery-half::after, .fa-duotone.fa-battery-half::after {
  content: "\f242\f242";
}

.fad.fa-battery-3::after, .fa-duotone.fa-battery-3::after {
  content: "\f242\f242";
}

.fad.fa-flux-capacitor::after, .fa-duotone.fa-flux-capacitor::after {
  content: "\f8ba\f8ba";
}

.fad.fa-mountain-city::after, .fa-duotone.fa-mountain-city::after {
  content: "\e52e\e52e";
}

.fad.fa-coins::after, .fa-duotone.fa-coins::after {
  content: "\f51e\f51e";
}

.fad.fa-honey-pot::after, .fa-duotone.fa-honey-pot::after {
  content: "\e418\e418";
}

.fad.fa-olive::after, .fa-duotone.fa-olive::after {
  content: "\e316\e316";
}

.fad.fa-khanda::after, .fa-duotone.fa-khanda::after {
  content: "\f66d\f66d";
}

.fad.fa-filter-list::after, .fa-duotone.fa-filter-list::after {
  content: "\e17c\e17c";
}

.fad.fa-outlet::after, .fa-duotone.fa-outlet::after {
  content: "\e01c\e01c";
}

.fad.fa-sliders::after, .fa-duotone.fa-sliders::after {
  content: "\f1de\f1de";
}

.fad.fa-sliders-h::after, .fa-duotone.fa-sliders-h::after {
  content: "\f1de\f1de";
}

.fad.fa-cauldron::after, .fa-duotone.fa-cauldron::after {
  content: "\f6bf\f6bf";
}

.fad.fa-people::after, .fa-duotone.fa-people::after {
  content: "\e216\e216";
}

.fad.fa-folder-tree::after, .fa-duotone.fa-folder-tree::after {
  content: "\f802\f802";
}

.fad.fa-network-wired::after, .fa-duotone.fa-network-wired::after {
  content: "\f6ff\f6ff";
}

.fad.fa-croissant::after, .fa-duotone.fa-croissant::after {
  content: "\f7f6\f7f6";
}

.fad.fa-map-pin::after, .fa-duotone.fa-map-pin::after {
  content: "\f276\f276";
}

.fad.fa-hamsa::after, .fa-duotone.fa-hamsa::after {
  content: "\f665\f665";
}

.fad.fa-cent-sign::after, .fa-duotone.fa-cent-sign::after {
  content: "\e3f5\e3f5";
}

.fad.fa-swords-laser::after, .fa-duotone.fa-swords-laser::after {
  content: "\e03d\e03d";
}

.fad.fa-flask::after, .fa-duotone.fa-flask::after {
  content: "\f0c3\f0c3";
}

.fad.fa-person-pregnant::after, .fa-duotone.fa-person-pregnant::after {
  content: "\e31e\e31e";
}

.fad.fa-square-u::after, .fa-duotone.fa-square-u::after {
  content: "\e281\e281";
}

.fad.fa-wand-sparkles::after, .fa-duotone.fa-wand-sparkles::after {
  content: "\f72b\f72b";
}

.fad.fa-router::after, .fa-duotone.fa-router::after {
  content: "\f8da\f8da";
}

.fad.fa-ellipsis-vertical::after, .fa-duotone.fa-ellipsis-vertical::after {
  content: "\f142\f142";
}

.fad.fa-ellipsis-v::after, .fa-duotone.fa-ellipsis-v::after {
  content: "\f142\f142";
}

.fad.fa-sword-laser-alt::after, .fa-duotone.fa-sword-laser-alt::after {
  content: "\e03c\e03c";
}

.fad.fa-ticket::after, .fa-duotone.fa-ticket::after {
  content: "\f145\f145";
}

.fad.fa-power-off::after, .fa-duotone.fa-power-off::after {
  content: "\f011\f011";
}

.fad.fa-coin::after, .fa-duotone.fa-coin::after {
  content: "\f85c\f85c";
}

.fad.fa-laptop-slash::after, .fa-duotone.fa-laptop-slash::after {
  content: "\e1c7\e1c7";
}

.fad.fa-right-long::after, .fa-duotone.fa-right-long::after {
  content: "\f30b\f30b";
}

.fad.fa-long-arrow-alt-right::after, .fa-duotone.fa-long-arrow-alt-right::after {
  content: "\f30b\f30b";
}

.fad.fa-circle-b::after, .fa-duotone.fa-circle-b::after {
  content: "\e0fd\e0fd";
}

.fad.fa-person-dress-simple::after, .fa-duotone.fa-person-dress-simple::after {
  content: "\e21c\e21c";
}

.fad.fa-pipe-collar::after, .fa-duotone.fa-pipe-collar::after {
  content: "\e437\e437";
}

.fad.fa-lights-holiday::after, .fa-duotone.fa-lights-holiday::after {
  content: "\f7b2\f7b2";
}

.fad.fa-citrus::after, .fa-duotone.fa-citrus::after {
  content: "\e2f4\e2f4";
}

.fad.fa-flag-usa::after, .fa-duotone.fa-flag-usa::after {
  content: "\f74d\f74d";
}

.fad.fa-laptop-file::after, .fa-duotone.fa-laptop-file::after {
  content: "\e51d\e51d";
}

.fad.fa-tty::after, .fa-duotone.fa-tty::after {
  content: "\f1e4\f1e4";
}

.fad.fa-teletype::after, .fa-duotone.fa-teletype::after {
  content: "\f1e4\f1e4";
}

.fad.fa-chart-tree-map::after, .fa-duotone.fa-chart-tree-map::after {
  content: "\e0ea\e0ea";
}

.fad.fa-diagram-next::after, .fa-duotone.fa-diagram-next::after {
  content: "\e476\e476";
}

.fad.fa-person-rifle::after, .fa-duotone.fa-person-rifle::after {
  content: "\e54e\e54e";
}

.fad.fa-clock-five-thirty::after, .fa-duotone.fa-clock-five-thirty::after {
  content: "\e34a\e34a";
}

.fad.fa-pipe-valve::after, .fa-duotone.fa-pipe-valve::after {
  content: "\e439\e439";
}

.fad.fa-arrow-up-from-arc::after, .fa-duotone.fa-arrow-up-from-arc::after {
  content: "\e4b4\e4b4";
}

.fad.fa-face-spiral-eyes::after, .fa-duotone.fa-face-spiral-eyes::after {
  content: "\e485\e485";
}

.fad.fa-compress-wide::after, .fa-duotone.fa-compress-wide::after {
  content: "\f326\f326";
}

.fad.fa-circle-phone-hangup::after, .fa-duotone.fa-circle-phone-hangup::after {
  content: "\e11d\e11d";
}

.fad.fa-phone-circle-down::after, .fa-duotone.fa-phone-circle-down::after {
  content: "\e11d\e11d";
}

.fad.fa-house-medical-circle-exclamation::after, .fa-duotone.fa-house-medical-circle-exclamation::after {
  content: "\e512\e512";
}

.fad.fa-badminton::after, .fa-duotone.fa-badminton::after {
  content: "\e33a\e33a";
}

.fad.fa-closed-captioning::after, .fa-duotone.fa-closed-captioning::after {
  content: "\f20a\f20a";
}

.fad.fa-person-hiking::after, .fa-duotone.fa-person-hiking::after {
  content: "\f6ec\f6ec";
}

.fad.fa-hiking::after, .fa-duotone.fa-hiking::after {
  content: "\f6ec\f6ec";
}

.fad.fa-right-from-line::after, .fa-duotone.fa-right-from-line::after {
  content: "\f347\f347";
}

.fad.fa-arrow-alt-from-left::after, .fa-duotone.fa-arrow-alt-from-left::after {
  content: "\f347\f347";
}

.fad.fa-venus-double::after, .fa-duotone.fa-venus-double::after {
  content: "\f226\f226";
}

.fad.fa-images::after, .fa-duotone.fa-images::after {
  content: "\f302\f302";
}

.fad.fa-calculator::after, .fa-duotone.fa-calculator::after {
  content: "\f1ec\f1ec";
}

.fad.fa-shuttlecock::after, .fa-duotone.fa-shuttlecock::after {
  content: "\f45b\f45b";
}

.fad.fa-user-hair::after, .fa-duotone.fa-user-hair::after {
  content: "\e45a\e45a";
}

.fad.fa-eye-evil::after, .fa-duotone.fa-eye-evil::after {
  content: "\f6db\f6db";
}

.fad.fa-people-pulling::after, .fa-duotone.fa-people-pulling::after {
  content: "\e535\e535";
}

.fad.fa-n::after, .fa-duotone.fa-n::after {
  content: "NN";
}

.fad.fa-garage::after, .fa-duotone.fa-garage::after {
  content: "\e009\e009";
}

.fad.fa-cable-car::after, .fa-duotone.fa-cable-car::after {
  content: "\f7da\f7da";
}

.fad.fa-tram::after, .fa-duotone.fa-tram::after {
  content: "\f7da\f7da";
}

.fad.fa-shovel-snow::after, .fa-duotone.fa-shovel-snow::after {
  content: "\f7c3\f7c3";
}

.fad.fa-cloud-rain::after, .fa-duotone.fa-cloud-rain::after {
  content: "\f73d\f73d";
}

.fad.fa-face-lying::after, .fa-duotone.fa-face-lying::after {
  content: "\e37e\e37e";
}

.fad.fa-sprinkler::after, .fa-duotone.fa-sprinkler::after {
  content: "\e035\e035";
}

.fad.fa-building-circle-xmark::after, .fa-duotone.fa-building-circle-xmark::after {
  content: "\e4d4\e4d4";
}

.fad.fa-person-sledding::after, .fa-duotone.fa-person-sledding::after {
  content: "\f7cb\f7cb";
}

.fad.fa-sledding::after, .fa-duotone.fa-sledding::after {
  content: "\f7cb\f7cb";
}

.fad.fa-game-console-handheld::after, .fa-duotone.fa-game-console-handheld::after {
  content: "\f8bb\f8bb";
}

.fad.fa-ship::after, .fa-duotone.fa-ship::after {
  content: "\f21a\f21a";
}

.fad.fa-clock-six-thirty::after, .fa-duotone.fa-clock-six-thirty::after {
  content: "\e353\e353";
}

.fad.fa-battery-slash::after, .fa-duotone.fa-battery-slash::after {
  content: "\f377\f377";
}

.fad.fa-tugrik-sign::after, .fa-duotone.fa-tugrik-sign::after {
  content: "\e2ba\e2ba";
}

.fad.fa-arrows-down-to-line::after, .fa-duotone.fa-arrows-down-to-line::after {
  content: "\e4b8\e4b8";
}

.fad.fa-download::after, .fa-duotone.fa-download::after {
  content: "\f019\f019";
}

.fad.fa-shelves::after, .fa-duotone.fa-shelves::after {
  content: "\f480\f480";
}

.fad.fa-inventory::after, .fa-duotone.fa-inventory::after {
  content: "\f480\f480";
}

.fad.fa-cloud-snow::after, .fa-duotone.fa-cloud-snow::after {
  content: "\f742\f742";
}

.fad.fa-face-grin::after, .fa-duotone.fa-face-grin::after {
  content: "\f580\f580";
}

.fad.fa-grin::after, .fa-duotone.fa-grin::after {
  content: "\f580\f580";
}

.fad.fa-delete-left::after, .fa-duotone.fa-delete-left::after {
  content: "\f55a\f55a";
}

.fad.fa-backspace::after, .fa-duotone.fa-backspace::after {
  content: "\f55a\f55a";
}

.fad.fa-oven::after, .fa-duotone.fa-oven::after {
  content: "\e01d\e01d";
}

.fad.fa-eye-dropper::after, .fa-duotone.fa-eye-dropper::after {
  content: "\f1fb\f1fb";
}

.fad.fa-eye-dropper-empty::after, .fa-duotone.fa-eye-dropper-empty::after {
  content: "\f1fb\f1fb";
}

.fad.fa-eyedropper::after, .fa-duotone.fa-eyedropper::after {
  content: "\f1fb\f1fb";
}

.fad.fa-comment-captions::after, .fa-duotone.fa-comment-captions::after {
  content: "\e146\e146";
}

.fad.fa-comments-question::after, .fa-duotone.fa-comments-question::after {
  content: "\e14e\e14e";
}

.fad.fa-scribble::after, .fa-duotone.fa-scribble::after {
  content: "\e23f\e23f";
}

.fad.fa-rotate-exclamation::after, .fa-duotone.fa-rotate-exclamation::after {
  content: "\e23c\e23c";
}

.fad.fa-file-circle-check::after, .fa-duotone.fa-file-circle-check::after {
  content: "\e5a0\e5a0";
}

.fad.fa-glass::after, .fa-duotone.fa-glass::after {
  content: "\f804\f804";
}

.fad.fa-loader::after, .fa-duotone.fa-loader::after {
  content: "\e1d4\e1d4";
}

.fad.fa-forward::after, .fa-duotone.fa-forward::after {
  content: "\f04e\f04e";
}

.fad.fa-user-pilot::after, .fa-duotone.fa-user-pilot::after {
  content: "\e2c0\e2c0";
}

.fad.fa-mobile::after, .fa-duotone.fa-mobile::after {
  content: "\f3ce\f3ce";
}

.fad.fa-mobile-android::after, .fa-duotone.fa-mobile-android::after {
  content: "\f3ce\f3ce";
}

.fad.fa-mobile-phone::after, .fa-duotone.fa-mobile-phone::after {
  content: "\f3ce\f3ce";
}

.fad.fa-code-pull-request-closed::after, .fa-duotone.fa-code-pull-request-closed::after {
  content: "\e3f9\e3f9";
}

.fad.fa-face-meh::after, .fa-duotone.fa-face-meh::after {
  content: "\f11a\f11a";
}

.fad.fa-meh::after, .fa-duotone.fa-meh::after {
  content: "\f11a\f11a";
}

.fad.fa-align-center::after, .fa-duotone.fa-align-center::after {
  content: "\f037\f037";
}

.fad.fa-book-skull::after, .fa-duotone.fa-book-skull::after {
  content: "\f6b7\f6b7";
}

.fad.fa-book-dead::after, .fa-duotone.fa-book-dead::after {
  content: "\f6b7\f6b7";
}

.fad.fa-id-card::after, .fa-duotone.fa-id-card::after {
  content: "\f2c2\f2c2";
}

.fad.fa-drivers-license::after, .fa-duotone.fa-drivers-license::after {
  content: "\f2c2\f2c2";
}

.fad.fa-face-dotted::after, .fa-duotone.fa-face-dotted::after {
  content: "\e47f\e47f";
}

.fad.fa-face-worried::after, .fa-duotone.fa-face-worried::after {
  content: "\e3a3\e3a3";
}

.fad.fa-outdent::after, .fa-duotone.fa-outdent::after {
  content: "\f03b\f03b";
}

.fad.fa-dedent::after, .fa-duotone.fa-dedent::after {
  content: "\f03b\f03b";
}

.fad.fa-heart-circle-exclamation::after, .fa-duotone.fa-heart-circle-exclamation::after {
  content: "\e4fe\e4fe";
}

.fad.fa-house::after, .fa-duotone.fa-house::after {
  content: "\f015\f015";
}

.fad.fa-home::after, .fa-duotone.fa-home::after {
  content: "\f015\f015";
}

.fad.fa-home-alt::after, .fa-duotone.fa-home-alt::after {
  content: "\f015\f015";
}

.fad.fa-home-lg-alt::after, .fa-duotone.fa-home-lg-alt::after {
  content: "\f015\f015";
}

.fad.fa-vector-circle::after, .fa-duotone.fa-vector-circle::after {
  content: "\e2c6\e2c6";
}

.fad.fa-car-circle-bolt::after, .fa-duotone.fa-car-circle-bolt::after {
  content: "\e342\e342";
}

.fad.fa-calendar-week::after, .fa-duotone.fa-calendar-week::after {
  content: "\f784\f784";
}

.fad.fa-flying-disc::after, .fa-duotone.fa-flying-disc::after {
  content: "\e3a9\e3a9";
}

.fad.fa-laptop-medical::after, .fa-duotone.fa-laptop-medical::after {
  content: "\f812\f812";
}

.fad.fa-square-down-right::after, .fa-duotone.fa-square-down-right::after {
  content: "\e26c\e26c";
}

.fad.fa-b::after, .fa-duotone.fa-b::after {
  content: "BB";
}

.fad.fa-seat-airline::after, .fa-duotone.fa-seat-airline::after {
  content: "\e244\e244";
}

.fad.fa-moon-over-sun::after, .fa-duotone.fa-moon-over-sun::after {
  content: "\f74a\f74a";
}

.fad.fa-eclipse-alt::after, .fa-duotone.fa-eclipse-alt::after {
  content: "\f74a\f74a";
}

.fad.fa-pipe::after, .fa-duotone.fa-pipe::after {
  content: "||";
}

.fad.fa-file-medical::after, .fa-duotone.fa-file-medical::after {
  content: "\f477\f477";
}

.fad.fa-potato::after, .fa-duotone.fa-potato::after {
  content: "\e440\e440";
}

.fad.fa-dice-one::after, .fa-duotone.fa-dice-one::after {
  content: "\f525\f525";
}

.fad.fa-circle-a::after, .fa-duotone.fa-circle-a::after {
  content: "\e0f7\e0f7";
}

.fad.fa-helmet-battle::after, .fa-duotone.fa-helmet-battle::after {
  content: "\f6eb\f6eb";
}

.fad.fa-butter::after, .fa-duotone.fa-butter::after {
  content: "\e3e4\e3e4";
}

.fad.fa-blanket-fire::after, .fa-duotone.fa-blanket-fire::after {
  content: "\e3da\e3da";
}

.fad.fa-kiwi-bird::after, .fa-duotone.fa-kiwi-bird::after {
  content: "\f535\f535";
}

.fad.fa-castle::after, .fa-duotone.fa-castle::after {
  content: "\e0de\e0de";
}

.fad.fa-golf-club::after, .fa-duotone.fa-golf-club::after {
  content: "\f451\f451";
}

.fad.fa-arrow-right-arrow-left::after, .fa-duotone.fa-arrow-right-arrow-left::after {
  content: "\f0ec\f0ec";
}

.fad.fa-exchange::after, .fa-duotone.fa-exchange::after {
  content: "\f0ec\f0ec";
}

.fad.fa-rotate-right::after, .fa-duotone.fa-rotate-right::after {
  content: "\f2f9\f2f9";
}

.fad.fa-redo-alt::after, .fa-duotone.fa-redo-alt::after {
  content: "\f2f9\f2f9";
}

.fad.fa-rotate-forward::after, .fa-duotone.fa-rotate-forward::after {
  content: "\f2f9\f2f9";
}

.fad.fa-utensils::after, .fa-duotone.fa-utensils::after {
  content: "\f2e7\f2e7";
}

.fad.fa-cutlery::after, .fa-duotone.fa-cutlery::after {
  content: "\f2e7\f2e7";
}

.fad.fa-arrow-up-wide-short::after, .fa-duotone.fa-arrow-up-wide-short::after {
  content: "\f161\f161";
}

.fad.fa-sort-amount-up::after, .fa-duotone.fa-sort-amount-up::after {
  content: "\f161\f161";
}

.fad.fa-balloons::after, .fa-duotone.fa-balloons::after {
  content: "\e2e4\e2e4";
}

.fad.fa-mill-sign::after, .fa-duotone.fa-mill-sign::after {
  content: "\e1ed\e1ed";
}

.fad.fa-bowl-rice::after, .fa-duotone.fa-bowl-rice::after {
  content: "\e2eb\e2eb";
}

.fad.fa-timeline-arrow::after, .fa-duotone.fa-timeline-arrow::after {
  content: "\e29d\e29d";
}

.fad.fa-skull::after, .fa-duotone.fa-skull::after {
  content: "\f54c\f54c";
}

.fad.fa-game-board-simple::after, .fa-duotone.fa-game-board-simple::after {
  content: "\f868\f868";
}

.fad.fa-game-board-alt::after, .fa-duotone.fa-game-board-alt::after {
  content: "\f868\f868";
}

.fad.fa-circle-video::after, .fa-duotone.fa-circle-video::after {
  content: "\e12b\e12b";
}

.fad.fa-video-circle::after, .fa-duotone.fa-video-circle::after {
  content: "\e12b\e12b";
}

.fad.fa-chart-scatter-bubble::after, .fa-duotone.fa-chart-scatter-bubble::after {
  content: "\e0e9\e0e9";
}

.fad.fa-house-turret::after, .fa-duotone.fa-house-turret::after {
  content: "\e1b4\e1b4";
}

.fad.fa-banana::after, .fa-duotone.fa-banana::after {
  content: "\e2e5\e2e5";
}

.fad.fa-hand-holding-skull::after, .fa-duotone.fa-hand-holding-skull::after {
  content: "\e1a4\e1a4";
}

.fad.fa-people-dress::after, .fa-duotone.fa-people-dress::after {
  content: "\e217\e217";
}

.fad.fa-loveseat::after, .fa-duotone.fa-loveseat::after {
  content: "\f4cc\f4cc";
}

.fad.fa-couch-small::after, .fa-duotone.fa-couch-small::after {
  content: "\f4cc\f4cc";
}

.fad.fa-tower-broadcast::after, .fa-duotone.fa-tower-broadcast::after {
  content: "\f519\f519";
}

.fad.fa-broadcast-tower::after, .fa-duotone.fa-broadcast-tower::after {
  content: "\f519\f519";
}

.fad.fa-truck-pickup::after, .fa-duotone.fa-truck-pickup::after {
  content: "\f63c\f63c";
}

.fad.fa-block-quote::after, .fa-duotone.fa-block-quote::after {
  content: "\e0b5\e0b5";
}

.fad.fa-up-long::after, .fa-duotone.fa-up-long::after {
  content: "\f30c\f30c";
}

.fad.fa-long-arrow-alt-up::after, .fa-duotone.fa-long-arrow-alt-up::after {
  content: "\f30c\f30c";
}

.fad.fa-stop::after, .fa-duotone.fa-stop::after {
  content: "\f04d\f04d";
}

.fad.fa-code-merge::after, .fa-duotone.fa-code-merge::after {
  content: "\f387\f387";
}

.fad.fa-money-check-dollar-pen::after, .fa-duotone.fa-money-check-dollar-pen::after {
  content: "\f873\f873";
}

.fad.fa-money-check-edit-alt::after, .fa-duotone.fa-money-check-edit-alt::after {
  content: "\f873\f873";
}

.fad.fa-up-from-line::after, .fa-duotone.fa-up-from-line::after {
  content: "\f346\f346";
}

.fad.fa-arrow-alt-from-bottom::after, .fa-duotone.fa-arrow-alt-from-bottom::after {
  content: "\f346\f346";
}

.fad.fa-upload::after, .fa-duotone.fa-upload::after {
  content: "\f093\f093";
}

.fad.fa-hurricane::after, .fa-duotone.fa-hurricane::after {
  content: "\f751\f751";
}

.fad.fa-people-pants::after, .fa-duotone.fa-people-pants::after {
  content: "\e219\e219";
}

.fad.fa-mound::after, .fa-duotone.fa-mound::after {
  content: "\e52d\e52d";
}

.fad.fa-windsock::after, .fa-duotone.fa-windsock::after {
  content: "\f777\f777";
}

.fad.fa-circle-half::after, .fa-duotone.fa-circle-half::after {
  content: "\e110\e110";
}

.fad.fa-brake-warning::after, .fa-duotone.fa-brake-warning::after {
  content: "\e0c7\e0c7";
}

.fad.fa-toilet-portable::after, .fa-duotone.fa-toilet-portable::after {
  content: "\e583\e583";
}

.fad.fa-compact-disc::after, .fa-duotone.fa-compact-disc::after {
  content: "\f51f\f51f";
}

.fad.fa-file-arrow-down::after, .fa-duotone.fa-file-arrow-down::after {
  content: "\f56d\f56d";
}

.fad.fa-file-download::after, .fa-duotone.fa-file-download::after {
  content: "\f56d\f56d";
}

.fad.fa-saxophone-fire::after, .fa-duotone.fa-saxophone-fire::after {
  content: "\f8db\f8db";
}

.fad.fa-sax-hot::after, .fa-duotone.fa-sax-hot::after {
  content: "\f8db\f8db";
}

.fad.fa-camera-web-slash::after, .fa-duotone.fa-camera-web-slash::after {
  content: "\f833\f833";
}

.fad.fa-webcam-slash::after, .fa-duotone.fa-webcam-slash::after {
  content: "\f833\f833";
}

.fad.fa-folder-medical::after, .fa-duotone.fa-folder-medical::after {
  content: "\e18c\e18c";
}

.fad.fa-folder-gear::after, .fa-duotone.fa-folder-gear::after {
  content: "\e187\e187";
}

.fad.fa-folder-cog::after, .fa-duotone.fa-folder-cog::after {
  content: "\e187\e187";
}

.fad.fa-hand-wave::after, .fa-duotone.fa-hand-wave::after {
  content: "\e1a7\e1a7";
}

.fad.fa-arrow-up-arrow-down::after, .fa-duotone.fa-arrow-up-arrow-down::after {
  content: "\e099\e099";
}

.fad.fa-sort-up-down::after, .fa-duotone.fa-sort-up-down::after {
  content: "\e099\e099";
}

.fad.fa-caravan::after, .fa-duotone.fa-caravan::after {
  content: "\f8ff\f8ff";
}

.fad.fa-shield-cat::after, .fa-duotone.fa-shield-cat::after {
  content: "\e572\e572";
}

.fad.fa-message-slash::after, .fa-duotone.fa-message-slash::after {
  content: "\f4a9\f4a9";
}

.fad.fa-comment-alt-slash::after, .fa-duotone.fa-comment-alt-slash::after {
  content: "\f4a9\f4a9";
}

.fad.fa-bolt::after, .fa-duotone.fa-bolt::after {
  content: "\f0e7\f0e7";
}

.fad.fa-zap::after, .fa-duotone.fa-zap::after {
  content: "\f0e7\f0e7";
}

.fad.fa-trash-can-check::after, .fa-duotone.fa-trash-can-check::after {
  content: "\e2a9\e2a9";
}

.fad.fa-glass-water::after, .fa-duotone.fa-glass-water::after {
  content: "\e4f4\e4f4";
}

.fad.fa-oil-well::after, .fa-duotone.fa-oil-well::after {
  content: "\e532\e532";
}

.fad.fa-person-simple::after, .fa-duotone.fa-person-simple::after {
  content: "\e220\e220";
}

.fad.fa-vault::after, .fa-duotone.fa-vault::after {
  content: "\e2c5\e2c5";
}

.fad.fa-mars::after, .fa-duotone.fa-mars::after {
  content: "\f222\f222";
}

.fad.fa-toilet::after, .fa-duotone.fa-toilet::after {
  content: "\f7d8\f7d8";
}

.fad.fa-plane-circle-xmark::after, .fa-duotone.fa-plane-circle-xmark::after {
  content: "\e557\e557";
}

.fad.fa-yen-sign::after, .fa-duotone.fa-yen-sign::after {
  content: "\f157\f157";
}

.fad.fa-cny::after, .fa-duotone.fa-cny::after {
  content: "\f157\f157";
}

.fad.fa-jpy::after, .fa-duotone.fa-jpy::after {
  content: "\f157\f157";
}

.fad.fa-rmb::after, .fa-duotone.fa-rmb::after {
  content: "\f157\f157";
}

.fad.fa-yen::after, .fa-duotone.fa-yen::after {
  content: "\f157\f157";
}

.fad.fa-notes::after, .fa-duotone.fa-notes::after {
  content: "\e202\e202";
}

.fad.fa-ruble-sign::after, .fa-duotone.fa-ruble-sign::after {
  content: "\f158\f158";
}

.fad.fa-rouble::after, .fa-duotone.fa-rouble::after {
  content: "\f158\f158";
}

.fad.fa-rub::after, .fa-duotone.fa-rub::after {
  content: "\f158\f158";
}

.fad.fa-ruble::after, .fa-duotone.fa-ruble::after {
  content: "\f158\f158";
}

.fad.fa-trash-undo::after, .fa-duotone.fa-trash-undo::after {
  content: "\f895\f895";
}

.fad.fa-trash-arrow-turn-left::after, .fa-duotone.fa-trash-arrow-turn-left::after {
  content: "\f895\f895";
}

.fad.fa-champagne-glass::after, .fa-duotone.fa-champagne-glass::after {
  content: "\f79e\f79e";
}

.fad.fa-glass-champagne::after, .fa-duotone.fa-glass-champagne::after {
  content: "\f79e\f79e";
}

.fad.fa-objects-align-center-horizontal::after, .fa-duotone.fa-objects-align-center-horizontal::after {
  content: "\e3bc\e3bc";
}

.fad.fa-sun::after, .fa-duotone.fa-sun::after {
  content: "\f185\f185";
}

.fad.fa-trash-can-slash::after, .fa-duotone.fa-trash-can-slash::after {
  content: "\e2ad\e2ad";
}

.fad.fa-trash-alt-slash::after, .fa-duotone.fa-trash-alt-slash::after {
  content: "\e2ad\e2ad";
}

.fad.fa-screen-users::after, .fa-duotone.fa-screen-users::after {
  content: "\f63d\f63d";
}

.fad.fa-users-class::after, .fa-duotone.fa-users-class::after {
  content: "\f63d\f63d";
}

.fad.fa-guitar::after, .fa-duotone.fa-guitar::after {
  content: "\f7a6\f7a6";
}

.fad.fa-square-arrow-left::after, .fa-duotone.fa-square-arrow-left::after {
  content: "\f33a\f33a";
}

.fad.fa-arrow-square-left::after, .fa-duotone.fa-arrow-square-left::after {
  content: "\f33a\f33a";
}

.fad.fa-square-8::after, .fa-duotone.fa-square-8::after {
  content: "\e25d\e25d";
}

.fad.fa-face-smile-hearts::after, .fa-duotone.fa-face-smile-hearts::after {
  content: "\e390\e390";
}

.fad.fa-brackets-square::after, .fa-duotone.fa-brackets-square::after {
  content: "\f7e9\f7e9";
}

.fad.fa-brackets::after, .fa-duotone.fa-brackets::after {
  content: "\f7e9\f7e9";
}

.fad.fa-laptop-arrow-down::after, .fa-duotone.fa-laptop-arrow-down::after {
  content: "\e1c6\e1c6";
}

.fad.fa-hockey-stick-puck::after, .fa-duotone.fa-hockey-stick-puck::after {
  content: "\e3ae\e3ae";
}

.fad.fa-house-tree::after, .fa-duotone.fa-house-tree::after {
  content: "\e1b3\e1b3";
}

.fad.fa-signal-fair::after, .fa-duotone.fa-signal-fair::after {
  content: "\f68d\f68d";
}

.fad.fa-signal-2::after, .fa-duotone.fa-signal-2::after {
  content: "\f68d\f68d";
}

.fad.fa-face-laugh-wink::after, .fa-duotone.fa-face-laugh-wink::after {
  content: "\f59c\f59c";
}

.fad.fa-laugh-wink::after, .fa-duotone.fa-laugh-wink::after {
  content: "\f59c\f59c";
}

.fad.fa-circle-dollar::after, .fa-duotone.fa-circle-dollar::after {
  content: "\f2e8\f2e8";
}

.fad.fa-dollar-circle::after, .fa-duotone.fa-dollar-circle::after {
  content: "\f2e8\f2e8";
}

.fad.fa-usd-circle::after, .fa-duotone.fa-usd-circle::after {
  content: "\f2e8\f2e8";
}

.fad.fa-horse-head::after, .fa-duotone.fa-horse-head::after {
  content: "\f7ab\f7ab";
}

.fad.fa-arrows-repeat::after, .fa-duotone.fa-arrows-repeat::after {
  content: "\f364\f364";
}

.fad.fa-repeat-alt::after, .fa-duotone.fa-repeat-alt::after {
  content: "\f364\f364";
}

.fad.fa-bore-hole::after, .fa-duotone.fa-bore-hole::after {
  content: "\e4c3\e4c3";
}

.fad.fa-industry::after, .fa-duotone.fa-industry::after {
  content: "\f275\f275";
}

.fad.fa-image-polaroid::after, .fa-duotone.fa-image-polaroid::after {
  content: "\f8c4\f8c4";
}

.fad.fa-wave-triangle::after, .fa-duotone.fa-wave-triangle::after {
  content: "\f89a\f89a";
}

.fad.fa-circle-down::after, .fa-duotone.fa-circle-down::after {
  content: "\f358\f358";
}

.fad.fa-arrow-alt-circle-down::after, .fa-duotone.fa-arrow-alt-circle-down::after {
  content: "\f358\f358";
}

.fad.fa-grill::after, .fa-duotone.fa-grill::after {
  content: "\e5a3\e5a3";
}

.fad.fa-arrows-turn-to-dots::after, .fa-duotone.fa-arrows-turn-to-dots::after {
  content: "\e4c1\e4c1";
}

.fad.fa-chart-mixed::after, .fa-duotone.fa-chart-mixed::after {
  content: "\f643\f643";
}

.fad.fa-analytics::after, .fa-duotone.fa-analytics::after {
  content: "\f643\f643";
}

.fad.fa-florin-sign::after, .fa-duotone.fa-florin-sign::after {
  content: "\e184\e184";
}

.fad.fa-arrow-down-short-wide::after, .fa-duotone.fa-arrow-down-short-wide::after {
  content: "\f884\f884";
}

.fad.fa-sort-amount-desc::after, .fa-duotone.fa-sort-amount-desc::after {
  content: "\f884\f884";
}

.fad.fa-sort-amount-down-alt::after, .fa-duotone.fa-sort-amount-down-alt::after {
  content: "\f884\f884";
}

.fad.fa-less-than::after, .fa-duotone.fa-less-than::after {
  content: "<<";
}

.fad.fa-display-code::after, .fa-duotone.fa-display-code::after {
  content: "\e165\e165";
}

.fad.fa-desktop-code::after, .fa-duotone.fa-desktop-code::after {
  content: "\e165\e165";
}

.fad.fa-face-drooling::after, .fa-duotone.fa-face-drooling::after {
  content: "\e372\e372";
}

.fad.fa-oil-temperature::after, .fa-duotone.fa-oil-temperature::after {
  content: "\f614\f614";
}

.fad.fa-oil-temp::after, .fa-duotone.fa-oil-temp::after {
  content: "\f614\f614";
}

.fad.fa-square-question::after, .fa-duotone.fa-square-question::after {
  content: "\f2fd\f2fd";
}

.fad.fa-question-square::after, .fa-duotone.fa-question-square::after {
  content: "\f2fd\f2fd";
}

.fad.fa-air-conditioner::after, .fa-duotone.fa-air-conditioner::after {
  content: "\f8f4\f8f4";
}

.fad.fa-angle-down::after, .fa-duotone.fa-angle-down::after {
  content: "\f107\f107";
}

.fad.fa-mountains::after, .fa-duotone.fa-mountains::after {
  content: "\f6fd\f6fd";
}

.fad.fa-omega::after, .fa-duotone.fa-omega::after {
  content: "\f67a\f67a";
}

.fad.fa-car-tunnel::after, .fa-duotone.fa-car-tunnel::after {
  content: "\e4de\e4de";
}

.fad.fa-person-dolly-empty::after, .fa-duotone.fa-person-dolly-empty::after {
  content: "\f4d1\f4d1";
}

.fad.fa-pan-food::after, .fa-duotone.fa-pan-food::after {
  content: "\e42b\e42b";
}

.fad.fa-head-side-cough::after, .fa-duotone.fa-head-side-cough::after {
  content: "\e061\e061";
}

.fad.fa-grip-lines::after, .fa-duotone.fa-grip-lines::after {
  content: "\f7a4\f7a4";
}

.fad.fa-thumbs-down::after, .fa-duotone.fa-thumbs-down::after {
  content: "\f165\f165";
}

.fad.fa-user-lock::after, .fa-duotone.fa-user-lock::after {
  content: "\f502\f502";
}

.fad.fa-arrow-right-long::after, .fa-duotone.fa-arrow-right-long::after {
  content: "\f178\f178";
}

.fad.fa-long-arrow-right::after, .fa-duotone.fa-long-arrow-right::after {
  content: "\f178\f178";
}

.fad.fa-tickets-airline::after, .fa-duotone.fa-tickets-airline::after {
  content: "\e29b\e29b";
}

.fad.fa-anchor-circle-xmark::after, .fa-duotone.fa-anchor-circle-xmark::after {
  content: "\e4ac\e4ac";
}

.fad.fa-ellipsis::after, .fa-duotone.fa-ellipsis::after {
  content: "\f141\f141";
}

.fad.fa-ellipsis-h::after, .fa-duotone.fa-ellipsis-h::after {
  content: "\f141\f141";
}

.fad.fa-nfc-slash::after, .fa-duotone.fa-nfc-slash::after {
  content: "\e1fc\e1fc";
}

.fad.fa-chess-pawn::after, .fa-duotone.fa-chess-pawn::after {
  content: "\f443\f443";
}

.fad.fa-kit-medical::after, .fa-duotone.fa-kit-medical::after {
  content: "\f479\f479";
}

.fad.fa-first-aid::after, .fa-duotone.fa-first-aid::after {
  content: "\f479\f479";
}

.fad.fa-grid-2-plus::after, .fa-duotone.fa-grid-2-plus::after {
  content: "\e197\e197";
}

.fad.fa-bells::after, .fa-duotone.fa-bells::after {
  content: "\f77f\f77f";
}

.fad.fa-person-through-window::after, .fa-duotone.fa-person-through-window::after {
  content: "\e5a9\e5a9";
}

.fad.fa-toolbox::after, .fa-duotone.fa-toolbox::after {
  content: "\f552\f552";
}

.fad.fa-envelope-dot::after, .fa-duotone.fa-envelope-dot::after {
  content: "\e16f\e16f";
}

.fad.fa-envelope-badge::after, .fa-duotone.fa-envelope-badge::after {
  content: "\e16f\e16f";
}

.fad.fa-hands-holding-circle::after, .fa-duotone.fa-hands-holding-circle::after {
  content: "\e4fb\e4fb";
}

.fad.fa-bug::after, .fa-duotone.fa-bug::after {
  content: "\f188\f188";
}

.fad.fa-bowl-chopsticks::after, .fa-duotone.fa-bowl-chopsticks::after {
  content: "\e2e9\e2e9";
}

.fad.fa-credit-card::after, .fa-duotone.fa-credit-card::after {
  content: "\f09d\f09d";
}

.fad.fa-credit-card-alt::after, .fa-duotone.fa-credit-card-alt::after {
  content: "\f09d\f09d";
}

.fad.fa-circle-s::after, .fa-duotone.fa-circle-s::after {
  content: "\e121\e121";
}

.fad.fa-box-ballot::after, .fa-duotone.fa-box-ballot::after {
  content: "\f735\f735";
}

.fad.fa-car::after, .fa-duotone.fa-car::after {
  content: "\f1b9\f1b9";
}

.fad.fa-automobile::after, .fa-duotone.fa-automobile::after {
  content: "\f1b9\f1b9";
}

.fad.fa-hand-holding-hand::after, .fa-duotone.fa-hand-holding-hand::after {
  content: "\e4f7\e4f7";
}

.fad.fa-user-tie-hair::after, .fa-duotone.fa-user-tie-hair::after {
  content: "\e45f\e45f";
}

.fad.fa-podium-star::after, .fa-duotone.fa-podium-star::after {
  content: "\f758\f758";
}

.fad.fa-user-hair-mullet::after, .fa-duotone.fa-user-hair-mullet::after {
  content: "\e45c\e45c";
}

.fad.fa-business-front::after, .fa-duotone.fa-business-front::after {
  content: "\e45c\e45c";
}

.fad.fa-party-back::after, .fa-duotone.fa-party-back::after {
  content: "\e45c\e45c";
}

.fad.fa-trian-balbot::after, .fa-duotone.fa-trian-balbot::after {
  content: "\e45c\e45c";
}

.fad.fa-microphone-stand::after, .fa-duotone.fa-microphone-stand::after {
  content: "\f8cb\f8cb";
}

.fad.fa-book-open-reader::after, .fa-duotone.fa-book-open-reader::after {
  content: "\f5da\f5da";
}

.fad.fa-book-reader::after, .fa-duotone.fa-book-reader::after {
  content: "\f5da\f5da";
}

.fad.fa-family-dress::after, .fa-duotone.fa-family-dress::after {
  content: "\e301\e301";
}

.fad.fa-circle-x::after, .fa-duotone.fa-circle-x::after {
  content: "\e12e\e12e";
}

.fad.fa-cabin::after, .fa-duotone.fa-cabin::after {
  content: "\e46d\e46d";
}

.fad.fa-mountain-sun::after, .fa-duotone.fa-mountain-sun::after {
  content: "\e52f\e52f";
}

.fad.fa-chart-simple-horizontal::after, .fa-duotone.fa-chart-simple-horizontal::after {
  content: "\e474\e474";
}

.fad.fa-arrows-left-right-to-line::after, .fa-duotone.fa-arrows-left-right-to-line::after {
  content: "\e4ba\e4ba";
}

.fad.fa-hand-back-point-left::after, .fa-duotone.fa-hand-back-point-left::after {
  content: "\e19f\e19f";
}

.fad.fa-message-dots::after, .fa-duotone.fa-message-dots::after {
  content: "\f4a3\f4a3";
}

.fad.fa-comment-alt-dots::after, .fa-duotone.fa-comment-alt-dots::after {
  content: "\f4a3\f4a3";
}

.fad.fa-messaging::after, .fa-duotone.fa-messaging::after {
  content: "\f4a3\f4a3";
}

.fad.fa-file-heart::after, .fa-duotone.fa-file-heart::after {
  content: "\e176\e176";
}

.fad.fa-beer-mug::after, .fa-duotone.fa-beer-mug::after {
  content: "\e0b3\e0b3";
}

.fad.fa-beer-foam::after, .fa-duotone.fa-beer-foam::after {
  content: "\e0b3\e0b3";
}

.fad.fa-dice-d20::after, .fa-duotone.fa-dice-d20::after {
  content: "\f6cf\f6cf";
}

.fad.fa-drone::after, .fa-duotone.fa-drone::after {
  content: "\f85f\f85f";
}

.fad.fa-truck-droplet::after, .fa-duotone.fa-truck-droplet::after {
  content: "\e58c\e58c";
}

.fad.fa-file-circle-xmark::after, .fa-duotone.fa-file-circle-xmark::after {
  content: "\e5a1\e5a1";
}

.fad.fa-temperature-arrow-up::after, .fa-duotone.fa-temperature-arrow-up::after {
  content: "\e040\e040";
}

.fad.fa-temperature-up::after, .fa-duotone.fa-temperature-up::after {
  content: "\e040\e040";
}

.fad.fa-medal::after, .fa-duotone.fa-medal::after {
  content: "\f5a2\f5a2";
}

.fad.fa-bed::after, .fa-duotone.fa-bed::after {
  content: "\f236\f236";
}

.fad.fa-book-copy::after, .fa-duotone.fa-book-copy::after {
  content: "\e0be\e0be";
}

.fad.fa-square-h::after, .fa-duotone.fa-square-h::after {
  content: "\f0fd\f0fd";
}

.fad.fa-h-square::after, .fa-duotone.fa-h-square::after {
  content: "\f0fd\f0fd";
}

.fad.fa-square-c::after, .fa-duotone.fa-square-c::after {
  content: "\e266\e266";
}

.fad.fa-clock-two::after, .fa-duotone.fa-clock-two::after {
  content: "\e35a\e35a";
}

.fad.fa-square-ellipsis-vertical::after, .fa-duotone.fa-square-ellipsis-vertical::after {
  content: "\e26f\e26f";
}

.fad.fa-podcast::after, .fa-duotone.fa-podcast::after {
  content: "\f2ce\f2ce";
}

.fad.fa-bee::after, .fa-duotone.fa-bee::after {
  content: "\e0b2\e0b2";
}

.fad.fa-temperature-full::after, .fa-duotone.fa-temperature-full::after {
  content: "\f2c7\f2c7";
}

.fad.fa-temperature-4::after, .fa-duotone.fa-temperature-4::after {
  content: "\f2c7\f2c7";
}

.fad.fa-thermometer-4::after, .fa-duotone.fa-thermometer-4::after {
  content: "\f2c7\f2c7";
}

.fad.fa-thermometer-full::after, .fa-duotone.fa-thermometer-full::after {
  content: "\f2c7\f2c7";
}

.fad.fa-bell::after, .fa-duotone.fa-bell::after {
  content: "\f0f3\f0f3";
}

.fad.fa-candy-bar::after, .fa-duotone.fa-candy-bar::after {
  content: "\e3e8\e3e8";
}

.fad.fa-chocolate-bar::after, .fa-duotone.fa-chocolate-bar::after {
  content: "\e3e8\e3e8";
}

.fad.fa-xmark-large::after, .fa-duotone.fa-xmark-large::after {
  content: "\e59b\e59b";
}

.fad.fa-pinata::after, .fa-duotone.fa-pinata::after {
  content: "\e3c3\e3c3";
}

.fad.fa-arrows-from-line::after, .fa-duotone.fa-arrows-from-line::after {
  content: "\e0a4\e0a4";
}

.fad.fa-superscript::after, .fa-duotone.fa-superscript::after {
  content: "\f12b\f12b";
}

.fad.fa-bowl-spoon::after, .fa-duotone.fa-bowl-spoon::after {
  content: "\e3e0\e3e0";
}

.fad.fa-hexagon-check::after, .fa-duotone.fa-hexagon-check::after {
  content: "\e416\e416";
}

.fad.fa-plug-circle-xmark::after, .fa-duotone.fa-plug-circle-xmark::after {
  content: "\e560\e560";
}

.fad.fa-star-of-life::after, .fa-duotone.fa-star-of-life::after {
  content: "\f621\f621";
}

.fad.fa-phone-slash::after, .fa-duotone.fa-phone-slash::after {
  content: "\f3dd\f3dd";
}

.fad.fa-traffic-light-stop::after, .fa-duotone.fa-traffic-light-stop::after {
  content: "\f63a\f63a";
}

.fad.fa-paint-roller::after, .fa-duotone.fa-paint-roller::after {
  content: "\f5aa\f5aa";
}

.fad.fa-accent-grave::after, .fa-duotone.fa-accent-grave::after {
  content: "``";
}

.fad.fa-handshake-angle::after, .fa-duotone.fa-handshake-angle::after {
  content: "\f4c4\f4c4";
}

.fad.fa-hands-helping::after, .fa-duotone.fa-hands-helping::after {
  content: "\f4c4\f4c4";
}

.fad.fa-circle-0::after, .fa-duotone.fa-circle-0::after {
  content: "\e0ed\e0ed";
}

.fad.fa-dial-med-low::after, .fa-duotone.fa-dial-med-low::after {
  content: "\e160\e160";
}

.fad.fa-location-dot::after, .fa-duotone.fa-location-dot::after {
  content: "\f3c5\f3c5";
}

.fad.fa-map-marker-alt::after, .fa-duotone.fa-map-marker-alt::after {
  content: "\f3c5\f3c5";
}

.fad.fa-crab::after, .fa-duotone.fa-crab::after {
  content: "\e3ff\e3ff";
}

.fad.fa-box-open-full::after, .fa-duotone.fa-box-open-full::after {
  content: "\f49c\f49c";
}

.fad.fa-box-full::after, .fa-duotone.fa-box-full::after {
  content: "\f49c\f49c";
}

.fad.fa-file::after, .fa-duotone.fa-file::after {
  content: "\f15b\f15b";
}

.fad.fa-greater-than::after, .fa-duotone.fa-greater-than::after {
  content: ">>";
}

.fad.fa-quotes::after, .fa-duotone.fa-quotes::after {
  content: "\e234\e234";
}

.fad.fa-pretzel::after, .fa-duotone.fa-pretzel::after {
  content: "\e441\e441";
}

.fad.fa-person-swimming::after, .fa-duotone.fa-person-swimming::after {
  content: "\f5c4\f5c4";
}

.fad.fa-swimmer::after, .fa-duotone.fa-swimmer::after {
  content: "\f5c4\f5c4";
}

.fad.fa-arrow-down::after, .fa-duotone.fa-arrow-down::after {
  content: "\f063\f063";
}

.fad.fa-user-robot-xmarks::after, .fa-duotone.fa-user-robot-xmarks::after {
  content: "\e4a7\e4a7";
}

.fad.fa-message-quote::after, .fa-duotone.fa-message-quote::after {
  content: "\e1e4\e1e4";
}

.fad.fa-comment-alt-quote::after, .fa-duotone.fa-comment-alt-quote::after {
  content: "\e1e4\e1e4";
}

.fad.fa-candy-corn::after, .fa-duotone.fa-candy-corn::after {
  content: "\f6bd\f6bd";
}

.fad.fa-folder-magnifying-glass::after, .fa-duotone.fa-folder-magnifying-glass::after {
  content: "\e18b\e18b";
}

.fad.fa-folder-search::after, .fa-duotone.fa-folder-search::after {
  content: "\e18b\e18b";
}

.fad.fa-notebook::after, .fa-duotone.fa-notebook::after {
  content: "\e201\e201";
}

.fad.fa-droplet::after, .fa-duotone.fa-droplet::after {
  content: "\f043\f043";
}

.fad.fa-tint::after, .fa-duotone.fa-tint::after {
  content: "\f043\f043";
}

.fad.fa-bullseye-pointer::after, .fa-duotone.fa-bullseye-pointer::after {
  content: "\f649\f649";
}

.fad.fa-eraser::after, .fa-duotone.fa-eraser::after {
  content: "\f12d\f12d";
}

.fad.fa-hexagon-image::after, .fa-duotone.fa-hexagon-image::after {
  content: "\e504\e504";
}

.fad.fa-earth-americas::after, .fa-duotone.fa-earth-americas::after {
  content: "\f57d\f57d";
}

.fad.fa-earth::after, .fa-duotone.fa-earth::after {
  content: "\f57d\f57d";
}

.fad.fa-earth-america::after, .fa-duotone.fa-earth-america::after {
  content: "\f57d\f57d";
}

.fad.fa-globe-americas::after, .fa-duotone.fa-globe-americas::after {
  content: "\f57d\f57d";
}

.fad.fa-crate-apple::after, .fa-duotone.fa-crate-apple::after {
  content: "\f6b1\f6b1";
}

.fad.fa-apple-crate::after, .fa-duotone.fa-apple-crate::after {
  content: "\f6b1\f6b1";
}

.fad.fa-person-burst::after, .fa-duotone.fa-person-burst::after {
  content: "\e53b\e53b";
}

.fad.fa-game-board::after, .fa-duotone.fa-game-board::after {
  content: "\f867\f867";
}

.fad.fa-hat-chef::after, .fa-duotone.fa-hat-chef::after {
  content: "\f86b\f86b";
}

.fad.fa-hand-back-point-right::after, .fa-duotone.fa-hand-back-point-right::after {
  content: "\e1a1\e1a1";
}

.fad.fa-dove::after, .fa-duotone.fa-dove::after {
  content: "\f4ba\f4ba";
}

.fad.fa-battery-empty::after, .fa-duotone.fa-battery-empty::after {
  content: "\f244\f244";
}

.fad.fa-battery-0::after, .fa-duotone.fa-battery-0::after {
  content: "\f244\f244";
}

.fad.fa-grid-4::after, .fa-duotone.fa-grid-4::after {
  content: "\e198\e198";
}

.fad.fa-socks::after, .fa-duotone.fa-socks::after {
  content: "\f696\f696";
}

.fad.fa-face-sunglasses::after, .fa-duotone.fa-face-sunglasses::after {
  content: "\e398\e398";
}

.fad.fa-inbox::after, .fa-duotone.fa-inbox::after {
  content: "\f01c\f01c";
}

.fad.fa-square-0::after, .fa-duotone.fa-square-0::after {
  content: "\e255\e255";
}

.fad.fa-section::after, .fa-duotone.fa-section::after {
  content: "\e447\e447";
}

.fad.fa-square-this-way-up::after, .fa-duotone.fa-square-this-way-up::after {
  content: "\f49f\f49f";
}

.fad.fa-box-up::after, .fa-duotone.fa-box-up::after {
  content: "\f49f\f49f";
}

.fad.fa-gauge-high::after, .fa-duotone.fa-gauge-high::after {
  content: "\f625\f625";
}

.fad.fa-tachometer-alt::after, .fa-duotone.fa-tachometer-alt::after {
  content: "\f625\f625";
}

.fad.fa-tachometer-alt-fast::after, .fa-duotone.fa-tachometer-alt-fast::after {
  content: "\f625\f625";
}

.fad.fa-square-ampersand::after, .fa-duotone.fa-square-ampersand::after {
  content: "\e260\e260";
}

.fad.fa-envelope-open-text::after, .fa-duotone.fa-envelope-open-text::after {
  content: "\f658\f658";
}

.fad.fa-lamp-desk::after, .fa-duotone.fa-lamp-desk::after {
  content: "\e014\e014";
}

.fad.fa-hospital::after, .fa-duotone.fa-hospital::after {
  content: "\f0f8\f0f8";
}

.fad.fa-hospital-alt::after, .fa-duotone.fa-hospital-alt::after {
  content: "\f0f8\f0f8";
}

.fad.fa-hospital-wide::after, .fa-duotone.fa-hospital-wide::after {
  content: "\f0f8\f0f8";
}

.fad.fa-poll-people::after, .fa-duotone.fa-poll-people::after {
  content: "\f759\f759";
}

.fad.fa-whiskey-glass-ice::after, .fa-duotone.fa-whiskey-glass-ice::after {
  content: "\f7a1\f7a1";
}

.fad.fa-glass-whiskey-rocks::after, .fa-duotone.fa-glass-whiskey-rocks::after {
  content: "\f7a1\f7a1";
}

.fad.fa-wine-bottle::after, .fa-duotone.fa-wine-bottle::after {
  content: "\f72f\f72f";
}

.fad.fa-chess-rook::after, .fa-duotone.fa-chess-rook::after {
  content: "\f447\f447";
}

.fad.fa-user-bounty-hunter::after, .fa-duotone.fa-user-bounty-hunter::after {
  content: "\e2bf\e2bf";
}

.fad.fa-bars-staggered::after, .fa-duotone.fa-bars-staggered::after {
  content: "\f550\f550";
}

.fad.fa-reorder::after, .fa-duotone.fa-reorder::after {
  content: "\f550\f550";
}

.fad.fa-stream::after, .fa-duotone.fa-stream::after {
  content: "\f550\f550";
}

.fad.fa-diagram-sankey::after, .fa-duotone.fa-diagram-sankey::after {
  content: "\e158\e158";
}

.fad.fa-cloud-hail-mixed::after, .fa-duotone.fa-cloud-hail-mixed::after {
  content: "\f73a\f73a";
}

.fad.fa-circle-up-left::after, .fa-duotone.fa-circle-up-left::after {
  content: "\e128\e128";
}

.fad.fa-dharmachakra::after, .fa-duotone.fa-dharmachakra::after {
  content: "\f655\f655";
}

.fad.fa-objects-align-left::after, .fa-duotone.fa-objects-align-left::after {
  content: "\e3be\e3be";
}

.fad.fa-oil-can-drip::after, .fa-duotone.fa-oil-can-drip::after {
  content: "\e205\e205";
}

.fad.fa-face-smiling-hands::after, .fa-duotone.fa-face-smiling-hands::after {
  content: "\e396\e396";
}

.fad.fa-broccoli::after, .fa-duotone.fa-broccoli::after {
  content: "\e3e2\e3e2";
}

.fad.fa-route-interstate::after, .fa-duotone.fa-route-interstate::after {
  content: "\f61b\f61b";
}

.fad.fa-ear-muffs::after, .fa-duotone.fa-ear-muffs::after {
  content: "\f795\f795";
}

.fad.fa-hotdog::after, .fa-duotone.fa-hotdog::after {
  content: "\f80f\f80f";
}

.fad.fa-transporter-empty::after, .fa-duotone.fa-transporter-empty::after {
  content: "\e046\e046";
}

.fad.fa-person-walking-with-cane::after, .fa-duotone.fa-person-walking-with-cane::after {
  content: "\f29d\f29d";
}

.fad.fa-blind::after, .fa-duotone.fa-blind::after {
  content: "\f29d\f29d";
}

.fad.fa-angle-90::after, .fa-duotone.fa-angle-90::after {
  content: "\e08d\e08d";
}

.fad.fa-rectangle-terminal::after, .fa-duotone.fa-rectangle-terminal::after {
  content: "\e236\e236";
}

.fad.fa-kite::after, .fa-duotone.fa-kite::after {
  content: "\f6f4\f6f4";
}

.fad.fa-drum::after, .fa-duotone.fa-drum::after {
  content: "\f569\f569";
}

.fad.fa-scrubber::after, .fa-duotone.fa-scrubber::after {
  content: "\f2f8\f2f8";
}

.fad.fa-ice-cream::after, .fa-duotone.fa-ice-cream::after {
  content: "\f810\f810";
}

.fad.fa-heart-circle-bolt::after, .fa-duotone.fa-heart-circle-bolt::after {
  content: "\e4fc\e4fc";
}

.fad.fa-fish-bones::after, .fa-duotone.fa-fish-bones::after {
  content: "\e304\e304";
}

.fad.fa-deer-rudolph::after, .fa-duotone.fa-deer-rudolph::after {
  content: "\f78f\f78f";
}

.fad.fa-fax::after, .fa-duotone.fa-fax::after {
  content: "\f1ac\f1ac";
}

.fad.fa-paragraph::after, .fa-duotone.fa-paragraph::after {
  content: "\f1dd\f1dd";
}

.fad.fa-head-side-heart::after, .fa-duotone.fa-head-side-heart::after {
  content: "\e1aa\e1aa";
}

.fad.fa-square-e::after, .fa-duotone.fa-square-e::after {
  content: "\e26d\e26d";
}

.fad.fa-meter-fire::after, .fa-duotone.fa-meter-fire::after {
  content: "\e1eb\e1eb";
}

.fad.fa-cloud-hail::after, .fa-duotone.fa-cloud-hail::after {
  content: "\f739\f739";
}

.fad.fa-check-to-slot::after, .fa-duotone.fa-check-to-slot::after {
  content: "\f772\f772";
}

.fad.fa-vote-yea::after, .fa-duotone.fa-vote-yea::after {
  content: "\f772\f772";
}

.fad.fa-money-from-bracket::after, .fa-duotone.fa-money-from-bracket::after {
  content: "\e312\e312";
}

.fad.fa-star-half::after, .fa-duotone.fa-star-half::after {
  content: "\f089\f089";
}

.fad.fa-car-bus::after, .fa-duotone.fa-car-bus::after {
  content: "\f85a\f85a";
}

.fad.fa-speaker::after, .fa-duotone.fa-speaker::after {
  content: "\f8df\f8df";
}

.fad.fa-timer::after, .fa-duotone.fa-timer::after {
  content: "\e29e\e29e";
}

.fad.fa-boxes-stacked::after, .fa-duotone.fa-boxes-stacked::after {
  content: "\f468\f468";
}

.fad.fa-boxes::after, .fa-duotone.fa-boxes::after {
  content: "\f468\f468";
}

.fad.fa-boxes-alt::after, .fa-duotone.fa-boxes-alt::after {
  content: "\f468\f468";
}

.fad.fa-grill-hot::after, .fa-duotone.fa-grill-hot::after {
  content: "\e5a5\e5a5";
}

.fad.fa-ballot-check::after, .fa-duotone.fa-ballot-check::after {
  content: "\f733\f733";
}

.fad.fa-link::after, .fa-duotone.fa-link::after {
  content: "\f0c1\f0c1";
}

.fad.fa-chain::after, .fa-duotone.fa-chain::after {
  content: "\f0c1\f0c1";
}

.fad.fa-ear-listen::after, .fa-duotone.fa-ear-listen::after {
  content: "\f2a2\f2a2";
}

.fad.fa-assistive-listening-systems::after, .fa-duotone.fa-assistive-listening-systems::after {
  content: "\f2a2\f2a2";
}

.fad.fa-file-minus::after, .fa-duotone.fa-file-minus::after {
  content: "\f318\f318";
}

.fad.fa-tree-city::after, .fa-duotone.fa-tree-city::after {
  content: "\e587\e587";
}

.fad.fa-play::after, .fa-duotone.fa-play::after {
  content: "\f04b\f04b";
}

.fad.fa-font::after, .fa-duotone.fa-font::after {
  content: "\f031\f031";
}

.fad.fa-cup-togo::after, .fa-duotone.fa-cup-togo::after {
  content: "\f6c5\f6c5";
}

.fad.fa-coffee-togo::after, .fa-duotone.fa-coffee-togo::after {
  content: "\f6c5\f6c5";
}

.fad.fa-square-down-left::after, .fa-duotone.fa-square-down-left::after {
  content: "\e26b\e26b";
}

.fad.fa-burger-lettuce::after, .fa-duotone.fa-burger-lettuce::after {
  content: "\e3e3\e3e3";
}

.fad.fa-rupiah-sign::after, .fa-duotone.fa-rupiah-sign::after {
  content: "\e23d\e23d";
}

.fad.fa-magnifying-glass::after, .fa-duotone.fa-magnifying-glass::after {
  content: "\f002\f002";
}

.fad.fa-search::after, .fa-duotone.fa-search::after {
  content: "\f002\f002";
}

.fad.fa-table-tennis-paddle-ball::after, .fa-duotone.fa-table-tennis-paddle-ball::after {
  content: "\f45d\f45d";
}

.fad.fa-ping-pong-paddle-ball::after, .fa-duotone.fa-ping-pong-paddle-ball::after {
  content: "\f45d\f45d";
}

.fad.fa-table-tennis::after, .fa-duotone.fa-table-tennis::after {
  content: "\f45d\f45d";
}

.fad.fa-person-dots-from-line::after, .fa-duotone.fa-person-dots-from-line::after {
  content: "\f470\f470";
}

.fad.fa-diagnoses::after, .fa-duotone.fa-diagnoses::after {
  content: "\f470\f470";
}

.fad.fa-chevrons-down::after, .fa-duotone.fa-chevrons-down::after {
  content: "\f322\f322";
}

.fad.fa-chevron-double-down::after, .fa-duotone.fa-chevron-double-down::after {
  content: "\f322\f322";
}

.fad.fa-trash-can-arrow-up::after, .fa-duotone.fa-trash-can-arrow-up::after {
  content: "\f82a\f82a";
}

.fad.fa-trash-restore-alt::after, .fa-duotone.fa-trash-restore-alt::after {
  content: "\f82a\f82a";
}

.fad.fa-signal-good::after, .fa-duotone.fa-signal-good::after {
  content: "\f68e\f68e";
}

.fad.fa-signal-3::after, .fa-duotone.fa-signal-3::after {
  content: "\f68e\f68e";
}

.fad.fa-location-question::after, .fa-duotone.fa-location-question::after {
  content: "\f60b\f60b";
}

.fad.fa-map-marker-question::after, .fa-duotone.fa-map-marker-question::after {
  content: "\f60b\f60b";
}

.fad.fa-floppy-disk-circle-xmark::after, .fa-duotone.fa-floppy-disk-circle-xmark::after {
  content: "\e181\e181";
}

.fad.fa-floppy-disk-times::after, .fa-duotone.fa-floppy-disk-times::after {
  content: "\e181\e181";
}

.fad.fa-save-circle-xmark::after, .fa-duotone.fa-save-circle-xmark::after {
  content: "\e181\e181";
}

.fad.fa-save-times::after, .fa-duotone.fa-save-times::after {
  content: "\e181\e181";
}

.fad.fa-naira-sign::after, .fa-duotone.fa-naira-sign::after {
  content: "\e1f6\e1f6";
}

.fad.fa-peach::after, .fa-duotone.fa-peach::after {
  content: "\e20b\e20b";
}

.fad.fa-taxi-bus::after, .fa-duotone.fa-taxi-bus::after {
  content: "\e298\e298";
}

.fad.fa-bracket-curly::after, .fa-duotone.fa-bracket-curly::after {
  content: "{{";
}

.fad.fa-bracket-curly-left::after, .fa-duotone.fa-bracket-curly-left::after {
  content: "{{";
}

.fad.fa-lobster::after, .fa-duotone.fa-lobster::after {
  content: "\e421\e421";
}

.fad.fa-cart-flatbed-empty::after, .fa-duotone.fa-cart-flatbed-empty::after {
  content: "\f476\f476";
}

.fad.fa-dolly-flatbed-empty::after, .fa-duotone.fa-dolly-flatbed-empty::after {
  content: "\f476\f476";
}

.fad.fa-colon::after, .fa-duotone.fa-colon::after {
  content: "::";
}

.fad.fa-cart-arrow-down::after, .fa-duotone.fa-cart-arrow-down::after {
  content: "\f218\f218";
}

.fad.fa-wand::after, .fa-duotone.fa-wand::after {
  content: "\f72a\f72a";
}

.fad.fa-walkie-talkie::after, .fa-duotone.fa-walkie-talkie::after {
  content: "\f8ef\f8ef";
}

.fad.fa-file-pen::after, .fa-duotone.fa-file-pen::after {
  content: "\f31c\f31c";
}

.fad.fa-file-edit::after, .fa-duotone.fa-file-edit::after {
  content: "\f31c\f31c";
}

.fad.fa-receipt::after, .fa-duotone.fa-receipt::after {
  content: "\f543\f543";
}

.fad.fa-table-picnic::after, .fa-duotone.fa-table-picnic::after {
  content: "\e32d\e32d";
}

.fad.fa-square-pen::after, .fa-duotone.fa-square-pen::after {
  content: "\f14b\f14b";
}

.fad.fa-pen-square::after, .fa-duotone.fa-pen-square::after {
  content: "\f14b\f14b";
}

.fad.fa-pencil-square::after, .fa-duotone.fa-pencil-square::after {
  content: "\f14b\f14b";
}

.fad.fa-circle-microphone-lines::after, .fa-duotone.fa-circle-microphone-lines::after {
  content: "\e117\e117";
}

.fad.fa-microphone-circle-alt::after, .fa-duotone.fa-microphone-circle-alt::after {
  content: "\e117\e117";
}

.fad.fa-display-slash::after, .fa-duotone.fa-display-slash::after {
  content: "\e2fa\e2fa";
}

.fad.fa-desktop-slash::after, .fa-duotone.fa-desktop-slash::after {
  content: "\e2fa\e2fa";
}

.fad.fa-suitcase-rolling::after, .fa-duotone.fa-suitcase-rolling::after {
  content: "\f5c1\f5c1";
}

.fad.fa-person-circle-exclamation::after, .fa-duotone.fa-person-circle-exclamation::after {
  content: "\e53f\e53f";
}

.fad.fa-transporter-2::after, .fa-duotone.fa-transporter-2::after {
  content: "\e044\e044";
}

.fad.fa-hands-holding-diamond::after, .fa-duotone.fa-hands-holding-diamond::after {
  content: "\f47c\f47c";
}

.fad.fa-hand-receiving::after, .fa-duotone.fa-hand-receiving::after {
  content: "\f47c\f47c";
}

.fad.fa-money-bill-simple-wave::after, .fa-duotone.fa-money-bill-simple-wave::after {
  content: "\e1f2\e1f2";
}

.fad.fa-chevron-down::after, .fa-duotone.fa-chevron-down::after {
  content: "\f078\f078";
}

.fad.fa-battery-full::after, .fa-duotone.fa-battery-full::after {
  content: "\f240\f240";
}

.fad.fa-battery::after, .fa-duotone.fa-battery::after {
  content: "\f240\f240";
}

.fad.fa-battery-5::after, .fa-duotone.fa-battery-5::after {
  content: "\f240\f240";
}

.fad.fa-bell-plus::after, .fa-duotone.fa-bell-plus::after {
  content: "\f849\f849";
}

.fad.fa-book-arrow-right::after, .fa-duotone.fa-book-arrow-right::after {
  content: "\e0b9\e0b9";
}

.fad.fa-hospitals::after, .fa-duotone.fa-hospitals::after {
  content: "\f80e\f80e";
}

.fad.fa-club::after, .fa-duotone.fa-club::after {
  content: "\f327\f327";
}

.fad.fa-skull-crossbones::after, .fa-duotone.fa-skull-crossbones::after {
  content: "\f714\f714";
}

.fad.fa-droplet-degree::after, .fa-duotone.fa-droplet-degree::after {
  content: "\f748\f748";
}

.fad.fa-dewpoint::after, .fa-duotone.fa-dewpoint::after {
  content: "\f748\f748";
}

.fad.fa-code-compare::after, .fa-duotone.fa-code-compare::after {
  content: "\e13a\e13a";
}

.fad.fa-list-ul::after, .fa-duotone.fa-list-ul::after {
  content: "\f0ca\f0ca";
}

.fad.fa-list-dots::after, .fa-duotone.fa-list-dots::after {
  content: "\f0ca\f0ca";
}

.fad.fa-hand-holding-magic::after, .fa-duotone.fa-hand-holding-magic::after {
  content: "\f6e5\f6e5";
}

.fad.fa-watermelon-slice::after, .fa-duotone.fa-watermelon-slice::after {
  content: "\e337\e337";
}

.fad.fa-circle-ellipsis::after, .fa-duotone.fa-circle-ellipsis::after {
  content: "\e10a\e10a";
}

.fad.fa-school-lock::after, .fa-duotone.fa-school-lock::after {
  content: "\e56f\e56f";
}

.fad.fa-tower-cell::after, .fa-duotone.fa-tower-cell::after {
  content: "\e585\e585";
}

.fad.fa-sd-cards::after, .fa-duotone.fa-sd-cards::after {
  content: "\e240\e240";
}

.fad.fa-down-long::after, .fa-duotone.fa-down-long::after {
  content: "\f309\f309";
}

.fad.fa-long-arrow-alt-down::after, .fa-duotone.fa-long-arrow-alt-down::after {
  content: "\f309\f309";
}

.fad.fa-envelopes::after, .fa-duotone.fa-envelopes::after {
  content: "\e170\e170";
}

.fad.fa-phone-office::after, .fa-duotone.fa-phone-office::after {
  content: "\f67d\f67d";
}

.fad.fa-ranking-star::after, .fa-duotone.fa-ranking-star::after {
  content: "\e561\e561";
}

.fad.fa-chess-king::after, .fa-duotone.fa-chess-king::after {
  content: "\f43f\f43f";
}

.fad.fa-nfc-pen::after, .fa-duotone.fa-nfc-pen::after {
  content: "\e1fa\e1fa";
}

.fad.fa-person-harassing::after, .fa-duotone.fa-person-harassing::after {
  content: "\e549\e549";
}

.fad.fa-hat-winter::after, .fa-duotone.fa-hat-winter::after {
  content: "\f7a8\f7a8";
}

.fad.fa-brazilian-real-sign::after, .fa-duotone.fa-brazilian-real-sign::after {
  content: "\e46c\e46c";
}

.fad.fa-landmark-dome::after, .fa-duotone.fa-landmark-dome::after {
  content: "\f752\f752";
}

.fad.fa-landmark-alt::after, .fa-duotone.fa-landmark-alt::after {
  content: "\f752\f752";
}

.fad.fa-bone-break::after, .fa-duotone.fa-bone-break::after {
  content: "\f5d8\f5d8";
}

.fad.fa-arrow-up::after, .fa-duotone.fa-arrow-up::after {
  content: "\f062\f062";
}

.fad.fa-down-from-dotted-line::after, .fa-duotone.fa-down-from-dotted-line::after {
  content: "\e407\e407";
}

.fad.fa-tv::after, .fa-duotone.fa-tv::after {
  content: "\f26c\f26c";
}

.fad.fa-television::after, .fa-duotone.fa-television::after {
  content: "\f26c\f26c";
}

.fad.fa-tv-alt::after, .fa-duotone.fa-tv-alt::after {
  content: "\f26c\f26c";
}

.fad.fa-border-left::after, .fa-duotone.fa-border-left::after {
  content: "\f84f\f84f";
}

.fad.fa-circle-divide::after, .fa-duotone.fa-circle-divide::after {
  content: "\e106\e106";
}

.fad.fa-shrimp::after, .fa-duotone.fa-shrimp::after {
  content: "\e448\e448";
}

.fad.fa-list-check::after, .fa-duotone.fa-list-check::after {
  content: "\f0ae\f0ae";
}

.fad.fa-tasks::after, .fa-duotone.fa-tasks::after {
  content: "\f0ae\f0ae";
}

.fad.fa-diagram-subtask::after, .fa-duotone.fa-diagram-subtask::after {
  content: "\e479\e479";
}

.fad.fa-jug-detergent::after, .fa-duotone.fa-jug-detergent::after {
  content: "\e519\e519";
}

.fad.fa-circle-user::after, .fa-duotone.fa-circle-user::after {
  content: "\f2bd\f2bd";
}

.fad.fa-user-circle::after, .fa-duotone.fa-user-circle::after {
  content: "\f2bd\f2bd";
}

.fad.fa-square-y::after, .fa-duotone.fa-square-y::after {
  content: "\e287\e287";
}

.fad.fa-user-doctor-hair::after, .fa-duotone.fa-user-doctor-hair::after {
  content: "\e458\e458";
}

.fad.fa-planet-ringed::after, .fa-duotone.fa-planet-ringed::after {
  content: "\e020\e020";
}

.fad.fa-mushroom::after, .fa-duotone.fa-mushroom::after {
  content: "\e425\e425";
}

.fad.fa-user-shield::after, .fa-duotone.fa-user-shield::after {
  content: "\f505\f505";
}

.fad.fa-megaphone::after, .fa-duotone.fa-megaphone::after {
  content: "\f675\f675";
}

.fad.fa-circle-exclamation-check::after, .fa-duotone.fa-circle-exclamation-check::after {
  content: "\e10d\e10d";
}

.fad.fa-wind::after, .fa-duotone.fa-wind::after {
  content: "\f72e\f72e";
}

.fad.fa-box-dollar::after, .fa-duotone.fa-box-dollar::after {
  content: "\f4a0\f4a0";
}

.fad.fa-box-usd::after, .fa-duotone.fa-box-usd::after {
  content: "\f4a0\f4a0";
}

.fad.fa-car-burst::after, .fa-duotone.fa-car-burst::after {
  content: "\f5e1\f5e1";
}

.fad.fa-car-crash::after, .fa-duotone.fa-car-crash::after {
  content: "\f5e1\f5e1";
}

.fad.fa-y::after, .fa-duotone.fa-y::after {
  content: "YY";
}

.fad.fa-user-headset::after, .fa-duotone.fa-user-headset::after {
  content: "\f82d\f82d";
}

.fad.fa-arrows-retweet::after, .fa-duotone.fa-arrows-retweet::after {
  content: "\f361\f361";
}

.fad.fa-retweet-alt::after, .fa-duotone.fa-retweet-alt::after {
  content: "\f361\f361";
}

.fad.fa-person-snowboarding::after, .fa-duotone.fa-person-snowboarding::after {
  content: "\f7ce\f7ce";
}

.fad.fa-snowboarding::after, .fa-duotone.fa-snowboarding::after {
  content: "\f7ce\f7ce";
}

.fad.fa-square-chevron-right::after, .fa-duotone.fa-square-chevron-right::after {
  content: "\f32b\f32b";
}

.fad.fa-chevron-square-right::after, .fa-duotone.fa-chevron-square-right::after {
  content: "\f32b\f32b";
}

.fad.fa-lacrosse-stick-ball::after, .fa-duotone.fa-lacrosse-stick-ball::after {
  content: "\e3b6\e3b6";
}

.fad.fa-truck-fast::after, .fa-duotone.fa-truck-fast::after {
  content: "\f48b\f48b";
}

.fad.fa-shipping-fast::after, .fa-duotone.fa-shipping-fast::after {
  content: "\f48b\f48b";
}

.fad.fa-star-sharp::after, .fa-duotone.fa-star-sharp::after {
  content: "\e28b\e28b";
}

.fad.fa-circle-1::after, .fa-duotone.fa-circle-1::after {
  content: "\e0ee\e0ee";
}

.fad.fa-circle-star::after, .fa-duotone.fa-circle-star::after {
  content: "\e123\e123";
}

.fad.fa-star-circle::after, .fa-duotone.fa-star-circle::after {
  content: "\e123\e123";
}

.fad.fa-fish::after, .fa-duotone.fa-fish::after {
  content: "\f578\f578";
}

.fad.fa-cloud-fog::after, .fa-duotone.fa-cloud-fog::after {
  content: "\f74e\f74e";
}

.fad.fa-fog::after, .fa-duotone.fa-fog::after {
  content: "\f74e\f74e";
}

.fad.fa-waffle::after, .fa-duotone.fa-waffle::after {
  content: "\e466\e466";
}

.fad.fa-music-note::after, .fa-duotone.fa-music-note::after {
  content: "\f8cf\f8cf";
}

.fad.fa-music-alt::after, .fa-duotone.fa-music-alt::after {
  content: "\f8cf\f8cf";
}

.fad.fa-hexagon-exclamation::after, .fa-duotone.fa-hexagon-exclamation::after {
  content: "\e417\e417";
}

.fad.fa-cart-shopping-fast::after, .fa-duotone.fa-cart-shopping-fast::after {
  content: "\e0dc\e0dc";
}

.fad.fa-object-union::after, .fa-duotone.fa-object-union::after {
  content: "\e49f\e49f";
}

.fad.fa-user-graduate::after, .fa-duotone.fa-user-graduate::after {
  content: "\f501\f501";
}

.fad.fa-starfighter::after, .fa-duotone.fa-starfighter::after {
  content: "\e037\e037";
}

.fad.fa-circle-half-stroke::after, .fa-duotone.fa-circle-half-stroke::after {
  content: "\f042\f042";
}

.fad.fa-adjust::after, .fa-duotone.fa-adjust::after {
  content: "\f042\f042";
}

.fad.fa-arrow-right-long-to-line::after, .fa-duotone.fa-arrow-right-long-to-line::after {
  content: "\e3d5\e3d5";
}

.fad.fa-square-arrow-down::after, .fa-duotone.fa-square-arrow-down::after {
  content: "\f339\f339";
}

.fad.fa-arrow-square-down::after, .fa-duotone.fa-arrow-square-down::after {
  content: "\f339\f339";
}

.fad.fa-clapperboard::after, .fa-duotone.fa-clapperboard::after {
  content: "\e131\e131";
}

.fad.fa-square-chevron-left::after, .fa-duotone.fa-square-chevron-left::after {
  content: "\f32a\f32a";
}

.fad.fa-chevron-square-left::after, .fa-duotone.fa-chevron-square-left::after {
  content: "\f32a\f32a";
}

.fad.fa-phone-intercom::after, .fa-duotone.fa-phone-intercom::after {
  content: "\e434\e434";
}

.fad.fa-link-horizontal::after, .fa-duotone.fa-link-horizontal::after {
  content: "\e1cb\e1cb";
}

.fad.fa-chain-horizontal::after, .fa-duotone.fa-chain-horizontal::after {
  content: "\e1cb\e1cb";
}

.fad.fa-mango::after, .fa-duotone.fa-mango::after {
  content: "\e30f\e30f";
}

.fad.fa-music-note-slash::after, .fa-duotone.fa-music-note-slash::after {
  content: "\f8d0\f8d0";
}

.fad.fa-music-alt-slash::after, .fa-duotone.fa-music-alt-slash::after {
  content: "\f8d0\f8d0";
}

.fad.fa-circle-radiation::after, .fa-duotone.fa-circle-radiation::after {
  content: "\f7ba\f7ba";
}

.fad.fa-radiation-alt::after, .fa-duotone.fa-radiation-alt::after {
  content: "\f7ba\f7ba";
}

.fad.fa-face-tongue-sweat::after, .fa-duotone.fa-face-tongue-sweat::after {
  content: "\e39e\e39e";
}

.fad.fa-globe-stand::after, .fa-duotone.fa-globe-stand::after {
  content: "\f5f6\f5f6";
}

.fad.fa-baseball::after, .fa-duotone.fa-baseball::after {
  content: "\f433\f433";
}

.fad.fa-baseball-ball::after, .fa-duotone.fa-baseball-ball::after {
  content: "\f433\f433";
}

.fad.fa-circle-p::after, .fa-duotone.fa-circle-p::after {
  content: "\e11a\e11a";
}

.fad.fa-award-simple::after, .fa-duotone.fa-award-simple::after {
  content: "\e0ab\e0ab";
}

.fad.fa-jet-fighter-up::after, .fa-duotone.fa-jet-fighter-up::after {
  content: "\e518\e518";
}

.fad.fa-diagram-project::after, .fa-duotone.fa-diagram-project::after {
  content: "\f542\f542";
}

.fad.fa-project-diagram::after, .fa-duotone.fa-project-diagram::after {
  content: "\f542\f542";
}

.fad.fa-pedestal::after, .fa-duotone.fa-pedestal::after {
  content: "\e20d\e20d";
}

.fad.fa-chart-pyramid::after, .fa-duotone.fa-chart-pyramid::after {
  content: "\e0e6\e0e6";
}

.fad.fa-sidebar::after, .fa-duotone.fa-sidebar::after {
  content: "\e24e\e24e";
}

.fad.fa-snowman-head::after, .fa-duotone.fa-snowman-head::after {
  content: "\f79b\f79b";
}

.fad.fa-frosty-head::after, .fa-duotone.fa-frosty-head::after {
  content: "\f79b\f79b";
}

.fad.fa-copy::after, .fa-duotone.fa-copy::after {
  content: "\f0c5\f0c5";
}

.fad.fa-burger-glass::after, .fa-duotone.fa-burger-glass::after {
  content: "\e0ce\e0ce";
}

.fad.fa-volume-xmark::after, .fa-duotone.fa-volume-xmark::after {
  content: "\f6a9\f6a9";
}

.fad.fa-volume-mute::after, .fa-duotone.fa-volume-mute::after {
  content: "\f6a9\f6a9";
}

.fad.fa-volume-times::after, .fa-duotone.fa-volume-times::after {
  content: "\f6a9\f6a9";
}

.fad.fa-hand-sparkles::after, .fa-duotone.fa-hand-sparkles::after {
  content: "\e05d\e05d";
}

.fad.fa-bars-filter::after, .fa-duotone.fa-bars-filter::after {
  content: "\e0ad\e0ad";
}

.fad.fa-paintbrush-pencil::after, .fa-duotone.fa-paintbrush-pencil::after {
  content: "\e206\e206";
}

.fad.fa-party-bell::after, .fa-duotone.fa-party-bell::after {
  content: "\e31a\e31a";
}

.fad.fa-user-vneck-hair::after, .fa-duotone.fa-user-vneck-hair::after {
  content: "\e462\e462";
}

.fad.fa-jack-o-lantern::after, .fa-duotone.fa-jack-o-lantern::after {
  content: "\f30e\f30e";
}

.fad.fa-grip::after, .fa-duotone.fa-grip::after {
  content: "\f58d\f58d";
}

.fad.fa-grip-horizontal::after, .fa-duotone.fa-grip-horizontal::after {
  content: "\f58d\f58d";
}

.fad.fa-share-from-square::after, .fa-duotone.fa-share-from-square::after {
  content: "\f14d\f14d";
}

.fad.fa-share-square::after, .fa-duotone.fa-share-square::after {
  content: "\f14d\f14d";
}

.fad.fa-keynote::after, .fa-duotone.fa-keynote::after {
  content: "\f66c\f66c";
}

.fad.fa-gun::after, .fa-duotone.fa-gun::after {
  content: "\e19b\e19b";
}

.fad.fa-square-phone::after, .fa-duotone.fa-square-phone::after {
  content: "\f098\f098";
}

.fad.fa-phone-square::after, .fa-duotone.fa-phone-square::after {
  content: "\f098\f098";
}

.fad.fa-plus::after, .fa-duotone.fa-plus::after {
  content: "++";
}

.fad.fa-add::after, .fa-duotone.fa-add::after {
  content: "++";
}

.fad.fa-expand::after, .fa-duotone.fa-expand::after {
  content: "\f065\f065";
}

.fad.fa-computer::after, .fa-duotone.fa-computer::after {
  content: "\e4e5\e4e5";
}

.fad.fa-fort::after, .fa-duotone.fa-fort::after {
  content: "\e486\e486";
}

.fad.fa-cloud-check::after, .fa-duotone.fa-cloud-check::after {
  content: "\e35c\e35c";
}

.fad.fa-xmark::after, .fa-duotone.fa-xmark::after {
  content: "\f00d\f00d";
}

.fad.fa-close::after, .fa-duotone.fa-close::after {
  content: "\f00d\f00d";
}

.fad.fa-multiply::after, .fa-duotone.fa-multiply::after {
  content: "\f00d\f00d";
}

.fad.fa-remove::after, .fa-duotone.fa-remove::after {
  content: "\f00d\f00d";
}

.fad.fa-times::after, .fa-duotone.fa-times::after {
  content: "\f00d\f00d";
}

.fad.fa-face-smirking::after, .fa-duotone.fa-face-smirking::after {
  content: "\e397\e397";
}

.fad.fa-arrows-up-down-left-right::after, .fa-duotone.fa-arrows-up-down-left-right::after {
  content: "\f047\f047";
}

.fad.fa-arrows::after, .fa-duotone.fa-arrows::after {
  content: "\f047\f047";
}

.fad.fa-chalkboard-user::after, .fa-duotone.fa-chalkboard-user::after {
  content: "\f51c\f51c";
}

.fad.fa-chalkboard-teacher::after, .fa-duotone.fa-chalkboard-teacher::after {
  content: "\f51c\f51c";
}

.fad.fa-rhombus::after, .fa-duotone.fa-rhombus::after {
  content: "\e23b\e23b";
}

.fad.fa-claw-marks::after, .fa-duotone.fa-claw-marks::after {
  content: "\f6c2\f6c2";
}

.fad.fa-peso-sign::after, .fa-duotone.fa-peso-sign::after {
  content: "\e222\e222";
}

.fad.fa-face-smile-tongue::after, .fa-duotone.fa-face-smile-tongue::after {
  content: "\e394\e394";
}

.fad.fa-cart-circle-xmark::after, .fa-duotone.fa-cart-circle-xmark::after {
  content: "\e3f4\e3f4";
}

.fad.fa-building-shield::after, .fa-duotone.fa-building-shield::after {
  content: "\e4d8\e4d8";
}

.fad.fa-circle-phone-flip::after, .fa-duotone.fa-circle-phone-flip::after {
  content: "\e11c\e11c";
}

.fad.fa-phone-circle-alt::after, .fa-duotone.fa-phone-circle-alt::after {
  content: "\e11c\e11c";
}

.fad.fa-baby::after, .fa-duotone.fa-baby::after {
  content: "\f77c\f77c";
}

.fad.fa-users-line::after, .fa-duotone.fa-users-line::after {
  content: "\e592\e592";
}

.fad.fa-quote-left::after, .fa-duotone.fa-quote-left::after {
  content: "\f10d\f10d";
}

.fad.fa-quote-left-alt::after, .fa-duotone.fa-quote-left-alt::after {
  content: "\f10d\f10d";
}

.fad.fa-tractor::after, .fa-duotone.fa-tractor::after {
  content: "\f722\f722";
}

.fad.fa-key-skeleton::after, .fa-duotone.fa-key-skeleton::after {
  content: "\f6f3\f6f3";
}

.fad.fa-trash-arrow-up::after, .fa-duotone.fa-trash-arrow-up::after {
  content: "\f829\f829";
}

.fad.fa-trash-restore::after, .fa-duotone.fa-trash-restore::after {
  content: "\f829\f829";
}

.fad.fa-arrow-down-up-lock::after, .fa-duotone.fa-arrow-down-up-lock::after {
  content: "\e4b0\e4b0";
}

.fad.fa-arrow-down-to-bracket::after, .fa-duotone.fa-arrow-down-to-bracket::after {
  content: "\e094\e094";
}

.fad.fa-lines-leaning::after, .fa-duotone.fa-lines-leaning::after {
  content: "\e51e\e51e";
}

.fad.fa-square-q::after, .fa-duotone.fa-square-q::after {
  content: "\e27b\e27b";
}

.fad.fa-ruler-combined::after, .fa-duotone.fa-ruler-combined::after {
  content: "\f546\f546";
}

.fad.fa-symbols::after, .fa-duotone.fa-symbols::after {
  content: "\f86e\f86e";
}

.fad.fa-icons-alt::after, .fa-duotone.fa-icons-alt::after {
  content: "\f86e\f86e";
}

.fad.fa-copyright::after, .fa-duotone.fa-copyright::after {
  content: "\f1f9\f1f9";
}

.fad.fa-highlighter-line::after, .fa-duotone.fa-highlighter-line::after {
  content: "\e1af\e1af";
}

.fad.fa-bracket-square::after, .fa-duotone.fa-bracket-square::after {
  content: "[[";
}

.fad.fa-bracket::after, .fa-duotone.fa-bracket::after {
  content: "[[";
}

.fad.fa-bracket-left::after, .fa-duotone.fa-bracket-left::after {
  content: "[[";
}

.fad.fa-island-tropical::after, .fa-duotone.fa-island-tropical::after {
  content: "\f811\f811";
}

.fad.fa-island-tree-palm::after, .fa-duotone.fa-island-tree-palm::after {
  content: "\f811\f811";
}

.fad.fa-arrow-right-from-line::after, .fa-duotone.fa-arrow-right-from-line::after {
  content: "\f343\f343";
}

.fad.fa-arrow-from-left::after, .fa-duotone.fa-arrow-from-left::after {
  content: "\f343\f343";
}

.fad.fa-h2::after, .fa-duotone.fa-h2::after {
  content: "\f314\f314";
}

.fad.fa-equals::after, .fa-duotone.fa-equals::after {
  content: "==";
}

.fad.fa-cake-slice::after, .fa-duotone.fa-cake-slice::after {
  content: "\e3e5\e3e5";
}

.fad.fa-shortcake::after, .fa-duotone.fa-shortcake::after {
  content: "\e3e5\e3e5";
}

.fad.fa-peanut::after, .fa-duotone.fa-peanut::after {
  content: "\e430\e430";
}

.fad.fa-wrench-simple::after, .fa-duotone.fa-wrench-simple::after {
  content: "\e2d1\e2d1";
}

.fad.fa-blender::after, .fa-duotone.fa-blender::after {
  content: "\f517\f517";
}

.fad.fa-teeth::after, .fa-duotone.fa-teeth::after {
  content: "\f62e\f62e";
}

.fad.fa-tally-2::after, .fa-duotone.fa-tally-2::after {
  content: "\e295\e295";
}

.fad.fa-shekel-sign::after, .fa-duotone.fa-shekel-sign::after {
  content: "\f20b\f20b";
}

.fad.fa-ils::after, .fa-duotone.fa-ils::after {
  content: "\f20b\f20b";
}

.fad.fa-shekel::after, .fa-duotone.fa-shekel::after {
  content: "\f20b\f20b";
}

.fad.fa-sheqel::after, .fa-duotone.fa-sheqel::after {
  content: "\f20b\f20b";
}

.fad.fa-sheqel-sign::after, .fa-duotone.fa-sheqel-sign::after {
  content: "\f20b\f20b";
}

.fad.fa-cars::after, .fa-duotone.fa-cars::after {
  content: "\f85b\f85b";
}

.fad.fa-axe-battle::after, .fa-duotone.fa-axe-battle::after {
  content: "\f6b3\f6b3";
}

.fad.fa-user-hair-long::after, .fa-duotone.fa-user-hair-long::after {
  content: "\e45b\e45b";
}

.fad.fa-map::after, .fa-duotone.fa-map::after {
  content: "\f279\f279";
}

.fad.fa-file-circle-info::after, .fa-duotone.fa-file-circle-info::after {
  content: "\e493\e493";
}

.fad.fa-face-disappointed::after, .fa-duotone.fa-face-disappointed::after {
  content: "\e36f\e36f";
}

.fad.fa-lasso-sparkles::after, .fa-duotone.fa-lasso-sparkles::after {
  content: "\e1c9\e1c9";
}

.fad.fa-clock-eleven::after, .fa-duotone.fa-clock-eleven::after {
  content: "\e347\e347";
}

.fad.fa-rocket::after, .fa-duotone.fa-rocket::after {
  content: "\f135\f135";
}

.fad.fa-siren-on::after, .fa-duotone.fa-siren-on::after {
  content: "\e02e\e02e";
}

.fad.fa-clock-ten::after, .fa-duotone.fa-clock-ten::after {
  content: "\e354\e354";
}

.fad.fa-candle-holder::after, .fa-duotone.fa-candle-holder::after {
  content: "\f6bc\f6bc";
}

.fad.fa-video-arrow-down-left::after, .fa-duotone.fa-video-arrow-down-left::after {
  content: "\e2c8\e2c8";
}

.fad.fa-photo-film::after, .fa-duotone.fa-photo-film::after {
  content: "\f87c\f87c";
}

.fad.fa-photo-video::after, .fa-duotone.fa-photo-video::after {
  content: "\f87c\f87c";
}

.fad.fa-floppy-disk-circle-arrow-right::after, .fa-duotone.fa-floppy-disk-circle-arrow-right::after {
  content: "\e180\e180";
}

.fad.fa-save-circle-arrow-right::after, .fa-duotone.fa-save-circle-arrow-right::after {
  content: "\e180\e180";
}

.fad.fa-folder-minus::after, .fa-duotone.fa-folder-minus::after {
  content: "\f65d\f65d";
}

.fad.fa-planet-moon::after, .fa-duotone.fa-planet-moon::after {
  content: "\e01f\e01f";
}

.fad.fa-face-eyes-xmarks::after, .fa-duotone.fa-face-eyes-xmarks::after {
  content: "\e374\e374";
}

.fad.fa-chart-scatter::after, .fa-duotone.fa-chart-scatter::after {
  content: "\f7ee\f7ee";
}

.fad.fa-display-arrow-down::after, .fa-duotone.fa-display-arrow-down::after {
  content: "\e164\e164";
}

.fad.fa-store::after, .fa-duotone.fa-store::after {
  content: "\f54e\f54e";
}

.fad.fa-arrow-trend-up::after, .fa-duotone.fa-arrow-trend-up::after {
  content: "\e098\e098";
}

.fad.fa-plug-circle-minus::after, .fa-duotone.fa-plug-circle-minus::after {
  content: "\e55e\e55e";
}

.fad.fa-olive-branch::after, .fa-duotone.fa-olive-branch::after {
  content: "\e317\e317";
}

.fad.fa-angle::after, .fa-duotone.fa-angle::after {
  content: "\e08c\e08c";
}

.fad.fa-vacuum-robot::after, .fa-duotone.fa-vacuum-robot::after {
  content: "\e04e\e04e";
}

.fad.fa-sign-hanging::after, .fa-duotone.fa-sign-hanging::after {
  content: "\f4d9\f4d9";
}

.fad.fa-sign::after, .fa-duotone.fa-sign::after {
  content: "\f4d9\f4d9";
}

.fad.fa-square-divide::after, .fa-duotone.fa-square-divide::after {
  content: "\e26a\e26a";
}

.fad.fa-signal-stream-slash::after, .fa-duotone.fa-signal-stream-slash::after {
  content: "\e250\e250";
}

.fad.fa-bezier-curve::after, .fa-duotone.fa-bezier-curve::after {
  content: "\f55b\f55b";
}

.fad.fa-eye-dropper-half::after, .fa-duotone.fa-eye-dropper-half::after {
  content: "\e173\e173";
}

.fad.fa-store-lock::after, .fa-duotone.fa-store-lock::after {
  content: "\e4a6\e4a6";
}

.fad.fa-bell-slash::after, .fa-duotone.fa-bell-slash::after {
  content: "\f1f6\f1f6";
}

.fad.fa-cloud-bolt-sun::after, .fa-duotone.fa-cloud-bolt-sun::after {
  content: "\f76e\f76e";
}

.fad.fa-thunderstorm-sun::after, .fa-duotone.fa-thunderstorm-sun::after {
  content: "\f76e\f76e";
}

.fad.fa-camera-slash::after, .fa-duotone.fa-camera-slash::after {
  content: "\e0d9\e0d9";
}

.fad.fa-comment-quote::after, .fa-duotone.fa-comment-quote::after {
  content: "\e14c\e14c";
}

.fad.fa-tablet::after, .fa-duotone.fa-tablet::after {
  content: "\f3fb\f3fb";
}

.fad.fa-tablet-android::after, .fa-duotone.fa-tablet-android::after {
  content: "\f3fb\f3fb";
}

.fad.fa-school-flag::after, .fa-duotone.fa-school-flag::after {
  content: "\e56e\e56e";
}

.fad.fa-message-code::after, .fa-duotone.fa-message-code::after {
  content: "\e1df\e1df";
}

.fad.fa-glass-half::after, .fa-duotone.fa-glass-half::after {
  content: "\e192\e192";
}

.fad.fa-glass-half-empty::after, .fa-duotone.fa-glass-half-empty::after {
  content: "\e192\e192";
}

.fad.fa-glass-half-full::after, .fa-duotone.fa-glass-half-full::after {
  content: "\e192\e192";
}

.fad.fa-fill::after, .fa-duotone.fa-fill::after {
  content: "\f575\f575";
}

.fad.fa-message-minus::after, .fa-duotone.fa-message-minus::after {
  content: "\f4a7\f4a7";
}

.fad.fa-comment-alt-minus::after, .fa-duotone.fa-comment-alt-minus::after {
  content: "\f4a7\f4a7";
}

.fad.fa-angle-up::after, .fa-duotone.fa-angle-up::after {
  content: "\f106\f106";
}

.fad.fa-drumstick-bite::after, .fa-duotone.fa-drumstick-bite::after {
  content: "\f6d7\f6d7";
}

.fad.fa-link-horizontal-slash::after, .fa-duotone.fa-link-horizontal-slash::after {
  content: "\e1cc\e1cc";
}

.fad.fa-chain-horizontal-slash::after, .fa-duotone.fa-chain-horizontal-slash::after {
  content: "\e1cc\e1cc";
}

.fad.fa-holly-berry::after, .fa-duotone.fa-holly-berry::after {
  content: "\f7aa\f7aa";
}

.fad.fa-chevron-left::after, .fa-duotone.fa-chevron-left::after {
  content: "\f053\f053";
}

.fad.fa-bacteria::after, .fa-duotone.fa-bacteria::after {
  content: "\e059\e059";
}

.fad.fa-clouds::after, .fa-duotone.fa-clouds::after {
  content: "\f744\f744";
}

.fad.fa-money-bill-simple::after, .fa-duotone.fa-money-bill-simple::after {
  content: "\e1f1\e1f1";
}

.fad.fa-hand-lizard::after, .fa-duotone.fa-hand-lizard::after {
  content: "\f258\f258";
}

.fad.fa-table-pivot::after, .fa-duotone.fa-table-pivot::after {
  content: "\e291\e291";
}

.fad.fa-filter-slash::after, .fa-duotone.fa-filter-slash::after {
  content: "\e17d\e17d";
}

.fad.fa-trash-can-undo::after, .fa-duotone.fa-trash-can-undo::after {
  content: "\f896\f896";
}

.fad.fa-trash-can-arrow-turn-left::after, .fa-duotone.fa-trash-can-arrow-turn-left::after {
  content: "\f896\f896";
}

.fad.fa-trash-undo-alt::after, .fa-duotone.fa-trash-undo-alt::after {
  content: "\f896\f896";
}

.fad.fa-notdef::after, .fa-duotone.fa-notdef::after {
  content: "\e1fe\e1fe";
}

.fad.fa-disease::after, .fa-duotone.fa-disease::after {
  content: "\f7fa\f7fa";
}

.fad.fa-person-to-door::after, .fa-duotone.fa-person-to-door::after {
  content: "\e433\e433";
}

.fad.fa-turntable::after, .fa-duotone.fa-turntable::after {
  content: "\f8e4\f8e4";
}

.fad.fa-briefcase-medical::after, .fa-duotone.fa-briefcase-medical::after {
  content: "\f469\f469";
}

.fad.fa-genderless::after, .fa-duotone.fa-genderless::after {
  content: "\f22d\f22d";
}

.fad.fa-chevron-right::after, .fa-duotone.fa-chevron-right::after {
  content: "\f054\f054";
}

.fad.fa-signal-weak::after, .fa-duotone.fa-signal-weak::after {
  content: "\f68c\f68c";
}

.fad.fa-signal-1::after, .fa-duotone.fa-signal-1::after {
  content: "\f68c\f68c";
}

.fad.fa-clock-five::after, .fa-duotone.fa-clock-five::after {
  content: "\e349\e349";
}

.fad.fa-retweet::after, .fa-duotone.fa-retweet::after {
  content: "\f079\f079";
}

.fad.fa-car-rear::after, .fa-duotone.fa-car-rear::after {
  content: "\f5de\f5de";
}

.fad.fa-car-alt::after, .fa-duotone.fa-car-alt::after {
  content: "\f5de\f5de";
}

.fad.fa-pump-soap::after, .fa-duotone.fa-pump-soap::after {
  content: "\e06b\e06b";
}

.fad.fa-computer-classic::after, .fa-duotone.fa-computer-classic::after {
  content: "\f8b1\f8b1";
}

.fad.fa-frame::after, .fa-duotone.fa-frame::after {
  content: "\e495\e495";
}

.fad.fa-video-slash::after, .fa-duotone.fa-video-slash::after {
  content: "\f4e2\f4e2";
}

.fad.fa-battery-quarter::after, .fa-duotone.fa-battery-quarter::after {
  content: "\f243\f243";
}

.fad.fa-battery-2::after, .fa-duotone.fa-battery-2::after {
  content: "\f243\f243";
}

.fad.fa-ellipsis-stroke::after, .fa-duotone.fa-ellipsis-stroke::after {
  content: "\f39b\f39b";
}

.fad.fa-ellipsis-h-alt::after, .fa-duotone.fa-ellipsis-h-alt::after {
  content: "\f39b\f39b";
}

.fad.fa-radio::after, .fa-duotone.fa-radio::after {
  content: "\f8d7\f8d7";
}

.fad.fa-baby-carriage::after, .fa-duotone.fa-baby-carriage::after {
  content: "\f77d\f77d";
}

.fad.fa-carriage-baby::after, .fa-duotone.fa-carriage-baby::after {
  content: "\f77d\f77d";
}

.fad.fa-face-expressionless::after, .fa-duotone.fa-face-expressionless::after {
  content: "\e373\e373";
}

.fad.fa-down-to-dotted-line::after, .fa-duotone.fa-down-to-dotted-line::after {
  content: "\e408\e408";
}

.fad.fa-cloud-music::after, .fa-duotone.fa-cloud-music::after {
  content: "\f8ae\f8ae";
}

.fad.fa-traffic-light::after, .fa-duotone.fa-traffic-light::after {
  content: "\f637\f637";
}

.fad.fa-cloud-minus::after, .fa-duotone.fa-cloud-minus::after {
  content: "\e35d\e35d";
}

.fad.fa-thermometer::after, .fa-duotone.fa-thermometer::after {
  content: "\f491\f491";
}

.fad.fa-shield-minus::after, .fa-duotone.fa-shield-minus::after {
  content: "\e249\e249";
}

.fad.fa-vr-cardboard::after, .fa-duotone.fa-vr-cardboard::after {
  content: "\f729\f729";
}

.fad.fa-car-tilt::after, .fa-duotone.fa-car-tilt::after {
  content: "\f5e5\f5e5";
}

.fad.fa-gauge-circle-minus::after, .fa-duotone.fa-gauge-circle-minus::after {
  content: "\e497\e497";
}

.fad.fa-brightness-low::after, .fa-duotone.fa-brightness-low::after {
  content: "\e0ca\e0ca";
}

.fad.fa-hand-middle-finger::after, .fa-duotone.fa-hand-middle-finger::after {
  content: "\f806\f806";
}

.fad.fa-percent::after, .fa-duotone.fa-percent::after {
  content: "%%";
}

.fad.fa-percentage::after, .fa-duotone.fa-percentage::after {
  content: "%%";
}

.fad.fa-truck-moving::after, .fa-duotone.fa-truck-moving::after {
  content: "\f4df\f4df";
}

.fad.fa-glass-water-droplet::after, .fa-duotone.fa-glass-water-droplet::after {
  content: "\e4f5\e4f5";
}

.fad.fa-conveyor-belt::after, .fa-duotone.fa-conveyor-belt::after {
  content: "\f46e\f46e";
}

.fad.fa-location-check::after, .fa-duotone.fa-location-check::after {
  content: "\f606\f606";
}

.fad.fa-map-marker-check::after, .fa-duotone.fa-map-marker-check::after {
  content: "\f606\f606";
}

.fad.fa-coin-vertical::after, .fa-duotone.fa-coin-vertical::after {
  content: "\e3fd\e3fd";
}

.fad.fa-display::after, .fa-duotone.fa-display::after {
  content: "\e163\e163";
}

.fad.fa-person-sign::after, .fa-duotone.fa-person-sign::after {
  content: "\f757\f757";
}

.fad.fa-face-smile::after, .fa-duotone.fa-face-smile::after {
  content: "\f118\f118";
}

.fad.fa-smile::after, .fa-duotone.fa-smile::after {
  content: "\f118\f118";
}

.fad.fa-phone-hangup::after, .fa-duotone.fa-phone-hangup::after {
  content: "\e225\e225";
}

.fad.fa-signature-slash::after, .fa-duotone.fa-signature-slash::after {
  content: "\e3cb\e3cb";
}

.fad.fa-thumbtack::after, .fa-duotone.fa-thumbtack::after {
  content: "\f08d\f08d";
}

.fad.fa-thumb-tack::after, .fa-duotone.fa-thumb-tack::after {
  content: "\f08d\f08d";
}

.fad.fa-wheat-slash::after, .fa-duotone.fa-wheat-slash::after {
  content: "\e339\e339";
}

.fad.fa-trophy::after, .fa-duotone.fa-trophy::after {
  content: "\f091\f091";
}

.fad.fa-clouds-sun::after, .fa-duotone.fa-clouds-sun::after {
  content: "\f746\f746";
}

.fad.fa-person-praying::after, .fa-duotone.fa-person-praying::after {
  content: "\f683\f683";
}

.fad.fa-pray::after, .fa-duotone.fa-pray::after {
  content: "\f683\f683";
}

.fad.fa-hammer::after, .fa-duotone.fa-hammer::after {
  content: "\f6e3\f6e3";
}

.fad.fa-face-vomit::after, .fa-duotone.fa-face-vomit::after {
  content: "\e3a0\e3a0";
}

.fad.fa-speakers::after, .fa-duotone.fa-speakers::after {
  content: "\f8e0\f8e0";
}

.fad.fa-tty-answer::after, .fa-duotone.fa-tty-answer::after {
  content: "\e2b9\e2b9";
}

.fad.fa-teletype-answer::after, .fa-duotone.fa-teletype-answer::after {
  content: "\e2b9\e2b9";
}

.fad.fa-mug-tea-saucer::after, .fa-duotone.fa-mug-tea-saucer::after {
  content: "\e1f5\e1f5";
}

.fad.fa-diagram-lean-canvas::after, .fa-duotone.fa-diagram-lean-canvas::after {
  content: "\e156\e156";
}

.fad.fa-alt::after, .fa-duotone.fa-alt::after {
  content: "\e08a\e08a";
}

.fad.fa-dial::after, .fa-duotone.fa-dial::after {
  content: "\e15b\e15b";
}

.fad.fa-dial-med-high::after, .fa-duotone.fa-dial-med-high::after {
  content: "\e15b\e15b";
}

.fad.fa-hand-peace::after, .fa-duotone.fa-hand-peace::after {
  content: "\f25b\f25b";
}

.fad.fa-circle-trash::after, .fa-duotone.fa-circle-trash::after {
  content: "\e126\e126";
}

.fad.fa-trash-circle::after, .fa-duotone.fa-trash-circle::after {
  content: "\e126\e126";
}

.fad.fa-rotate::after, .fa-duotone.fa-rotate::after {
  content: "\f2f1\f2f1";
}

.fad.fa-sync-alt::after, .fa-duotone.fa-sync-alt::after {
  content: "\f2f1\f2f1";
}

.fad.fa-circle-quarters::after, .fa-duotone.fa-circle-quarters::after {
  content: "\e3f8\e3f8";
}

.fad.fa-spinner::after, .fa-duotone.fa-spinner::after {
  content: "\f110\f110";
}

.fad.fa-tower-control::after, .fa-duotone.fa-tower-control::after {
  content: "\e2a2\e2a2";
}

.fad.fa-arrow-up-triangle-square::after, .fa-duotone.fa-arrow-up-triangle-square::after {
  content: "\f88a\f88a";
}

.fad.fa-sort-shapes-up::after, .fa-duotone.fa-sort-shapes-up::after {
  content: "\f88a\f88a";
}

.fad.fa-whale::after, .fa-duotone.fa-whale::after {
  content: "\f72c\f72c";
}

.fad.fa-robot::after, .fa-duotone.fa-robot::after {
  content: "\f544\f544";
}

.fad.fa-peace::after, .fa-duotone.fa-peace::after {
  content: "\f67c\f67c";
}

.fad.fa-party-horn::after, .fa-duotone.fa-party-horn::after {
  content: "\e31b\e31b";
}

.fad.fa-gears::after, .fa-duotone.fa-gears::after {
  content: "\f085\f085";
}

.fad.fa-cogs::after, .fa-duotone.fa-cogs::after {
  content: "\f085\f085";
}

.fad.fa-sun-bright::after, .fa-duotone.fa-sun-bright::after {
  content: "\e28f\e28f";
}

.fad.fa-sun-alt::after, .fa-duotone.fa-sun-alt::after {
  content: "\e28f\e28f";
}

.fad.fa-warehouse::after, .fa-duotone.fa-warehouse::after {
  content: "\f494\f494";
}

.fad.fa-lock-keyhole-open::after, .fa-duotone.fa-lock-keyhole-open::after {
  content: "\f3c2\f3c2";
}

.fad.fa-lock-open-alt::after, .fa-duotone.fa-lock-open-alt::after {
  content: "\f3c2\f3c2";
}

.fad.fa-square-fragile::after, .fa-duotone.fa-square-fragile::after {
  content: "\f49b\f49b";
}

.fad.fa-box-fragile::after, .fa-duotone.fa-box-fragile::after {
  content: "\f49b\f49b";
}

.fad.fa-square-wine-glass-crack::after, .fa-duotone.fa-square-wine-glass-crack::after {
  content: "\f49b\f49b";
}

.fad.fa-arrow-up-right-dots::after, .fa-duotone.fa-arrow-up-right-dots::after {
  content: "\e4b7\e4b7";
}

.fad.fa-square-n::after, .fa-duotone.fa-square-n::after {
  content: "\e277\e277";
}

.fad.fa-splotch::after, .fa-duotone.fa-splotch::after {
  content: "\f5bc\f5bc";
}

.fad.fa-face-grin-hearts::after, .fa-duotone.fa-face-grin-hearts::after {
  content: "\f584\f584";
}

.fad.fa-grin-hearts::after, .fa-duotone.fa-grin-hearts::after {
  content: "\f584\f584";
}

.fad.fa-meter::after, .fa-duotone.fa-meter::after {
  content: "\e1e8\e1e8";
}

.fad.fa-mandolin::after, .fa-duotone.fa-mandolin::after {
  content: "\f6f9\f6f9";
}

.fad.fa-dice-four::after, .fa-duotone.fa-dice-four::after {
  content: "\f524\f524";
}

.fad.fa-sim-card::after, .fa-duotone.fa-sim-card::after {
  content: "\f7c4\f7c4";
}

.fad.fa-transgender::after, .fa-duotone.fa-transgender::after {
  content: "\f225\f225";
}

.fad.fa-transgender-alt::after, .fa-duotone.fa-transgender-alt::after {
  content: "\f225\f225";
}

.fad.fa-mercury::after, .fa-duotone.fa-mercury::after {
  content: "\f223\f223";
}

.fad.fa-up-from-bracket::after, .fa-duotone.fa-up-from-bracket::after {
  content: "\e590\e590";
}

.fad.fa-knife-kitchen::after, .fa-duotone.fa-knife-kitchen::after {
  content: "\f6f5\f6f5";
}

.fad.fa-border-right::after, .fa-duotone.fa-border-right::after {
  content: "\f852\f852";
}

.fad.fa-arrow-turn-down::after, .fa-duotone.fa-arrow-turn-down::after {
  content: "\f149\f149";
}

.fad.fa-level-down::after, .fa-duotone.fa-level-down::after {
  content: "\f149\f149";
}

.fad.fa-spade::after, .fa-duotone.fa-spade::after {
  content: "\f2f4\f2f4";
}

.fad.fa-card-spade::after, .fa-duotone.fa-card-spade::after {
  content: "\e3ec\e3ec";
}

.fad.fa-line-columns::after, .fa-duotone.fa-line-columns::after {
  content: "\f870\f870";
}

.fad.fa-arrow-right-to-line::after, .fa-duotone.fa-arrow-right-to-line::after {
  content: "\f340\f340";
}

.fad.fa-arrow-to-right::after, .fa-duotone.fa-arrow-to-right::after {
  content: "\f340\f340";
}

.fad.fa-person-falling-burst::after, .fa-duotone.fa-person-falling-burst::after {
  content: "\e547\e547";
}

.fad.fa-flag-pennant::after, .fa-duotone.fa-flag-pennant::after {
  content: "\f456\f456";
}

.fad.fa-pennant::after, .fa-duotone.fa-pennant::after {
  content: "\f456\f456";
}

.fad.fa-conveyor-belt-empty::after, .fa-duotone.fa-conveyor-belt-empty::after {
  content: "\e150\e150";
}

.fad.fa-award::after, .fa-duotone.fa-award::after {
  content: "\f559\f559";
}

.fad.fa-ticket-simple::after, .fa-duotone.fa-ticket-simple::after {
  content: "\f3ff\f3ff";
}

.fad.fa-ticket-alt::after, .fa-duotone.fa-ticket-alt::after {
  content: "\f3ff\f3ff";
}

.fad.fa-building::after, .fa-duotone.fa-building::after {
  content: "\f1ad\f1ad";
}

.fad.fa-angles-left::after, .fa-duotone.fa-angles-left::after {
  content: "\f100\f100";
}

.fad.fa-angle-double-left::after, .fa-duotone.fa-angle-double-left::after {
  content: "\f100\f100";
}

.fad.fa-camcorder::after, .fa-duotone.fa-camcorder::after {
  content: "\f8a8\f8a8";
}

.fad.fa-video-handheld::after, .fa-duotone.fa-video-handheld::after {
  content: "\f8a8\f8a8";
}

.fad.fa-pancakes::after, .fa-duotone.fa-pancakes::after {
  content: "\e42d\e42d";
}

.fad.fa-album-circle-user::after, .fa-duotone.fa-album-circle-user::after {
  content: "\e48d\e48d";
}

.fad.fa-qrcode::after, .fa-duotone.fa-qrcode::after {
  content: "\f029\f029";
}

.fad.fa-dice-d10::after, .fa-duotone.fa-dice-d10::after {
  content: "\f6cd\f6cd";
}

.fad.fa-fireplace::after, .fa-duotone.fa-fireplace::after {
  content: "\f79a\f79a";
}

.fad.fa-browser::after, .fa-duotone.fa-browser::after {
  content: "\f37e\f37e";
}

.fad.fa-pen-paintbrush::after, .fa-duotone.fa-pen-paintbrush::after {
  content: "\f618\f618";
}

.fad.fa-pencil-paintbrush::after, .fa-duotone.fa-pencil-paintbrush::after {
  content: "\f618\f618";
}

.fad.fa-fish-cooked::after, .fa-duotone.fa-fish-cooked::after {
  content: "\f7fe\f7fe";
}

.fad.fa-chair-office::after, .fa-duotone.fa-chair-office::after {
  content: "\f6c1\f6c1";
}

.fad.fa-nesting-dolls::after, .fa-duotone.fa-nesting-dolls::after {
  content: "\e3ba\e3ba";
}

.fad.fa-clock-rotate-left::after, .fa-duotone.fa-clock-rotate-left::after {
  content: "\f1da\f1da";
}

.fad.fa-history::after, .fa-duotone.fa-history::after {
  content: "\f1da\f1da";
}

.fad.fa-trumpet::after, .fa-duotone.fa-trumpet::after {
  content: "\f8e3\f8e3";
}

.fad.fa-face-grin-beam-sweat::after, .fa-duotone.fa-face-grin-beam-sweat::after {
  content: "\f583\f583";
}

.fad.fa-grin-beam-sweat::after, .fa-duotone.fa-grin-beam-sweat::after {
  content: "\f583\f583";
}

.fad.fa-fire-smoke::after, .fa-duotone.fa-fire-smoke::after {
  content: "\f74b\f74b";
}

.fad.fa-phone-missed::after, .fa-duotone.fa-phone-missed::after {
  content: "\e226\e226";
}

.fad.fa-file-export::after, .fa-duotone.fa-file-export::after {
  content: "\f56e\f56e";
}

.fad.fa-arrow-right-from-file::after, .fa-duotone.fa-arrow-right-from-file::after {
  content: "\f56e\f56e";
}

.fad.fa-shield::after, .fa-duotone.fa-shield::after {
  content: "\f132\f132";
}

.fad.fa-shield-blank::after, .fa-duotone.fa-shield-blank::after {
  content: "\f132\f132";
}

.fad.fa-arrow-up-short-wide::after, .fa-duotone.fa-arrow-up-short-wide::after {
  content: "\f885\f885";
}

.fad.fa-sort-amount-up-alt::after, .fa-duotone.fa-sort-amount-up-alt::after {
  content: "\f885\f885";
}

.fad.fa-arrows-repeat-1::after, .fa-duotone.fa-arrows-repeat-1::after {
  content: "\f366\f366";
}

.fad.fa-repeat-1-alt::after, .fa-duotone.fa-repeat-1-alt::after {
  content: "\f366\f366";
}

.fad.fa-gun-slash::after, .fa-duotone.fa-gun-slash::after {
  content: "\e19c\e19c";
}

.fad.fa-avocado::after, .fa-duotone.fa-avocado::after {
  content: "\e0aa\e0aa";
}

.fad.fa-binary::after, .fa-duotone.fa-binary::after {
  content: "\e33b\e33b";
}

.fad.fa-glasses-round::after, .fa-duotone.fa-glasses-round::after {
  content: "\f5f5\f5f5";
}

.fad.fa-glasses-alt::after, .fa-duotone.fa-glasses-alt::after {
  content: "\f5f5\f5f5";
}

.fad.fa-phone-plus::after, .fa-duotone.fa-phone-plus::after {
  content: "\f4d2\f4d2";
}

.fad.fa-ditto::after, .fa-duotone.fa-ditto::after {
  content: '""';
}

.fad.fa-person-seat::after, .fa-duotone.fa-person-seat::after {
  content: "\e21e\e21e";
}

.fad.fa-house-medical::after, .fa-duotone.fa-house-medical::after {
  content: "\e3b2\e3b2";
}

.fad.fa-golf-ball-tee::after, .fa-duotone.fa-golf-ball-tee::after {
  content: "\f450\f450";
}

.fad.fa-golf-ball::after, .fa-duotone.fa-golf-ball::after {
  content: "\f450\f450";
}

.fad.fa-circle-chevron-left::after, .fa-duotone.fa-circle-chevron-left::after {
  content: "\f137\f137";
}

.fad.fa-chevron-circle-left::after, .fa-duotone.fa-chevron-circle-left::after {
  content: "\f137\f137";
}

.fad.fa-house-chimney-window::after, .fa-duotone.fa-house-chimney-window::after {
  content: "\e00d\e00d";
}

.fad.fa-scythe::after, .fa-duotone.fa-scythe::after {
  content: "\f710\f710";
}

.fad.fa-pen-nib::after, .fa-duotone.fa-pen-nib::after {
  content: "\f5ad\f5ad";
}

.fad.fa-ban-parking::after, .fa-duotone.fa-ban-parking::after {
  content: "\f616\f616";
}

.fad.fa-parking-circle-slash::after, .fa-duotone.fa-parking-circle-slash::after {
  content: "\f616\f616";
}

.fad.fa-tent-arrow-turn-left::after, .fa-duotone.fa-tent-arrow-turn-left::after {
  content: "\e580\e580";
}

.fad.fa-face-diagonal-mouth::after, .fa-duotone.fa-face-diagonal-mouth::after {
  content: "\e47e\e47e";
}

.fad.fa-diagram-cells::after, .fa-duotone.fa-diagram-cells::after {
  content: "\e475\e475";
}

.fad.fa-cricket-bat-ball::after, .fa-duotone.fa-cricket-bat-ball::after {
  content: "\f449\f449";
}

.fad.fa-cricket::after, .fa-duotone.fa-cricket::after {
  content: "\f449\f449";
}

.fad.fa-tents::after, .fa-duotone.fa-tents::after {
  content: "\e582\e582";
}

.fad.fa-wand-magic::after, .fa-duotone.fa-wand-magic::after {
  content: "\f0d0\f0d0";
}

.fad.fa-magic::after, .fa-duotone.fa-magic::after {
  content: "\f0d0\f0d0";
}

.fad.fa-dog::after, .fa-duotone.fa-dog::after {
  content: "\f6d3\f6d3";
}

.fad.fa-pen-line::after, .fa-duotone.fa-pen-line::after {
  content: "\e212\e212";
}

.fad.fa-atom-simple::after, .fa-duotone.fa-atom-simple::after {
  content: "\f5d3\f5d3";
}

.fad.fa-atom-alt::after, .fa-duotone.fa-atom-alt::after {
  content: "\f5d3\f5d3";
}

.fad.fa-ampersand::after, .fa-duotone.fa-ampersand::after {
  content: "&&";
}

.fad.fa-carrot::after, .fa-duotone.fa-carrot::after {
  content: "\f787\f787";
}

.fad.fa-arrow-up-from-line::after, .fa-duotone.fa-arrow-up-from-line::after {
  content: "\f342\f342";
}

.fad.fa-arrow-from-bottom::after, .fa-duotone.fa-arrow-from-bottom::after {
  content: "\f342\f342";
}

.fad.fa-moon::after, .fa-duotone.fa-moon::after {
  content: "\f186\f186";
}

.fad.fa-pen-slash::after, .fa-duotone.fa-pen-slash::after {
  content: "\e213\e213";
}

.fad.fa-wine-glass-empty::after, .fa-duotone.fa-wine-glass-empty::after {
  content: "\f5ce\f5ce";
}

.fad.fa-wine-glass-alt::after, .fa-duotone.fa-wine-glass-alt::after {
  content: "\f5ce\f5ce";
}

.fad.fa-square-star::after, .fa-duotone.fa-square-star::after {
  content: "\e27f\e27f";
}

.fad.fa-cheese::after, .fa-duotone.fa-cheese::after {
  content: "\f7ef\f7ef";
}

.fad.fa-send-backward::after, .fa-duotone.fa-send-backward::after {
  content: "\f87f\f87f";
}

.fad.fa-yin-yang::after, .fa-duotone.fa-yin-yang::after {
  content: "\f6ad\f6ad";
}

.fad.fa-music::after, .fa-duotone.fa-music::after {
  content: "\f001\f001";
}

.fad.fa-compass-slash::after, .fa-duotone.fa-compass-slash::after {
  content: "\f5e9\f5e9";
}

.fad.fa-clock-one::after, .fa-duotone.fa-clock-one::after {
  content: "\e34e\e34e";
}

.fad.fa-file-music::after, .fa-duotone.fa-file-music::after {
  content: "\f8b6\f8b6";
}

.fad.fa-code-commit::after, .fa-duotone.fa-code-commit::after {
  content: "\f386\f386";
}

.fad.fa-temperature-low::after, .fa-duotone.fa-temperature-low::after {
  content: "\f76b\f76b";
}

.fad.fa-person-biking::after, .fa-duotone.fa-person-biking::after {
  content: "\f84a\f84a";
}

.fad.fa-biking::after, .fa-duotone.fa-biking::after {
  content: "\f84a\f84a";
}

.fad.fa-skeleton::after, .fa-duotone.fa-skeleton::after {
  content: "\f620\f620";
}

.fad.fa-circle-g::after, .fa-duotone.fa-circle-g::after {
  content: "\e10f\e10f";
}

.fad.fa-circle-arrow-up-left::after, .fa-duotone.fa-circle-arrow-up-left::after {
  content: "\e0fb\e0fb";
}

.fad.fa-coin-blank::after, .fa-duotone.fa-coin-blank::after {
  content: "\e3fb\e3fb";
}

.fad.fa-broom::after, .fa-duotone.fa-broom::after {
  content: "\f51a\f51a";
}

.fad.fa-vacuum::after, .fa-duotone.fa-vacuum::after {
  content: "\e04d\e04d";
}

.fad.fa-shield-heart::after, .fa-duotone.fa-shield-heart::after {
  content: "\e574\e574";
}

.fad.fa-card-heart::after, .fa-duotone.fa-card-heart::after {
  content: "\e3eb\e3eb";
}

.fad.fa-lightbulb-cfl-on::after, .fa-duotone.fa-lightbulb-cfl-on::after {
  content: "\e5a7\e5a7";
}

.fad.fa-melon::after, .fa-duotone.fa-melon::after {
  content: "\e310\e310";
}

.fad.fa-gopuram::after, .fa-duotone.fa-gopuram::after {
  content: "\f664\f664";
}

.fad.fa-earth-oceania::after, .fa-duotone.fa-earth-oceania::after {
  content: "\e47b\e47b";
}

.fad.fa-globe-oceania::after, .fa-duotone.fa-globe-oceania::after {
  content: "\e47b\e47b";
}

.fad.fa-container-storage::after, .fa-duotone.fa-container-storage::after {
  content: "\f4b7\f4b7";
}

.fad.fa-face-pouting::after, .fa-duotone.fa-face-pouting::after {
  content: "\e387\e387";
}

.fad.fa-square-xmark::after, .fa-duotone.fa-square-xmark::after {
  content: "\f2d3\f2d3";
}

.fad.fa-times-square::after, .fa-duotone.fa-times-square::after {
  content: "\f2d3\f2d3";
}

.fad.fa-xmark-square::after, .fa-duotone.fa-xmark-square::after {
  content: "\f2d3\f2d3";
}

.fad.fa-face-explode::after, .fa-duotone.fa-face-explode::after {
  content: "\e2fe\e2fe";
}

.fad.fa-exploding-head::after, .fa-duotone.fa-exploding-head::after {
  content: "\e2fe\e2fe";
}

.fad.fa-hashtag::after, .fa-duotone.fa-hashtag::after {
  content: "##";
}

.fad.fa-up-right-and-down-left-from-center::after, .fa-duotone.fa-up-right-and-down-left-from-center::after {
  content: "\f424\f424";
}

.fad.fa-expand-alt::after, .fa-duotone.fa-expand-alt::after {
  content: "\f424\f424";
}

.fad.fa-oil-can::after, .fa-duotone.fa-oil-can::after {
  content: "\f613\f613";
}

.fad.fa-t::after, .fa-duotone.fa-t::after {
  content: "TT";
}

.fad.fa-transformer-bolt::after, .fa-duotone.fa-transformer-bolt::after {
  content: "\e2a4\e2a4";
}

.fad.fa-hippo::after, .fa-duotone.fa-hippo::after {
  content: "\f6ed\f6ed";
}

.fad.fa-chart-column::after, .fa-duotone.fa-chart-column::after {
  content: "\e0e3\e0e3";
}

.fad.fa-cassette-vhs::after, .fa-duotone.fa-cassette-vhs::after {
  content: "\f8ec\f8ec";
}

.fad.fa-vhs::after, .fa-duotone.fa-vhs::after {
  content: "\f8ec\f8ec";
}

.fad.fa-infinity::after, .fa-duotone.fa-infinity::after {
  content: "\f534\f534";
}

.fad.fa-vial-circle-check::after, .fa-duotone.fa-vial-circle-check::after {
  content: "\e596\e596";
}

.fad.fa-chimney::after, .fa-duotone.fa-chimney::after {
  content: "\f78b\f78b";
}

.fad.fa-object-intersect::after, .fa-duotone.fa-object-intersect::after {
  content: "\e49d\e49d";
}

.fad.fa-person-arrow-down-to-line::after, .fa-duotone.fa-person-arrow-down-to-line::after {
  content: "\e538\e538";
}

.fad.fa-voicemail::after, .fa-duotone.fa-voicemail::after {
  content: "\f897\f897";
}

.fad.fa-block-brick::after, .fa-duotone.fa-block-brick::after {
  content: "\e3db\e3db";
}

.fad.fa-wall-brick::after, .fa-duotone.fa-wall-brick::after {
  content: "\e3db\e3db";
}

.fad.fa-fan::after, .fa-duotone.fa-fan::after {
  content: "\f863\f863";
}

.fad.fa-bags-shopping::after, .fa-duotone.fa-bags-shopping::after {
  content: "\f847\f847";
}

.fad.fa-paragraph-left::after, .fa-duotone.fa-paragraph-left::after {
  content: "\f878\f878";
}

.fad.fa-paragraph-rtl::after, .fa-duotone.fa-paragraph-rtl::after {
  content: "\f878\f878";
}

.fad.fa-person-walking-luggage::after, .fa-duotone.fa-person-walking-luggage::after {
  content: "\e554\e554";
}

.fad.fa-caravan-simple::after, .fa-duotone.fa-caravan-simple::after {
  content: "\e000\e000";
}

.fad.fa-caravan-alt::after, .fa-duotone.fa-caravan-alt::after {
  content: "\e000\e000";
}

.fad.fa-turtle::after, .fa-duotone.fa-turtle::after {
  content: "\f726\f726";
}

.fad.fa-up-down::after, .fa-duotone.fa-up-down::after {
  content: "\f338\f338";
}

.fad.fa-arrows-alt-v::after, .fa-duotone.fa-arrows-alt-v::after {
  content: "\f338\f338";
}

.fad.fa-cloud-moon-rain::after, .fa-duotone.fa-cloud-moon-rain::after {
  content: "\f73c\f73c";
}

.fad.fa-booth-curtain::after, .fa-duotone.fa-booth-curtain::after {
  content: "\f734\f734";
}

.fad.fa-calendar::after, .fa-duotone.fa-calendar::after {
  content: "\f133\f133";
}

.fad.fa-box-heart::after, .fa-duotone.fa-box-heart::after {
  content: "\f49d\f49d";
}

.fad.fa-trailer::after, .fa-duotone.fa-trailer::after {
  content: "\e041\e041";
}

.fad.fa-user-doctor-message::after, .fa-duotone.fa-user-doctor-message::after {
  content: "\f82e\f82e";
}

.fad.fa-user-md-chat::after, .fa-duotone.fa-user-md-chat::after {
  content: "\f82e\f82e";
}

.fad.fa-bahai::after, .fa-duotone.fa-bahai::after {
  content: "\f666\f666";
}

.fad.fa-haykal::after, .fa-duotone.fa-haykal::after {
  content: "\f666\f666";
}

.fad.fa-amp-guitar::after, .fa-duotone.fa-amp-guitar::after {
  content: "\f8a1\f8a1";
}

.fad.fa-sd-card::after, .fa-duotone.fa-sd-card::after {
  content: "\f7c2\f7c2";
}

.fad.fa-volume-slash::after, .fa-duotone.fa-volume-slash::after {
  content: "\f2e2\f2e2";
}

.fad.fa-border-bottom::after, .fa-duotone.fa-border-bottom::after {
  content: "\f84d\f84d";
}

.fad.fa-wifi-weak::after, .fa-duotone.fa-wifi-weak::after {
  content: "\f6aa\f6aa";
}

.fad.fa-wifi-1::after, .fa-duotone.fa-wifi-1::after {
  content: "\f6aa\f6aa";
}

.fad.fa-dragon::after, .fa-duotone.fa-dragon::after {
  content: "\f6d5\f6d5";
}

.fad.fa-shoe-prints::after, .fa-duotone.fa-shoe-prints::after {
  content: "\f54b\f54b";
}

.fad.fa-circle-plus::after, .fa-duotone.fa-circle-plus::after {
  content: "\f055\f055";
}

.fad.fa-plus-circle::after, .fa-duotone.fa-plus-circle::after {
  content: "\f055\f055";
}

.fad.fa-face-grin-tongue-wink::after, .fa-duotone.fa-face-grin-tongue-wink::after {
  content: "\f58b\f58b";
}

.fad.fa-grin-tongue-wink::after, .fa-duotone.fa-grin-tongue-wink::after {
  content: "\f58b\f58b";
}

.fad.fa-hand-holding::after, .fa-duotone.fa-hand-holding::after {
  content: "\f4bd\f4bd";
}

.fad.fa-plug-circle-exclamation::after, .fa-duotone.fa-plug-circle-exclamation::after {
  content: "\e55d\e55d";
}

.fad.fa-link-slash::after, .fa-duotone.fa-link-slash::after {
  content: "\f127\f127";
}

.fad.fa-chain-broken::after, .fa-duotone.fa-chain-broken::after {
  content: "\f127\f127";
}

.fad.fa-chain-slash::after, .fa-duotone.fa-chain-slash::after {
  content: "\f127\f127";
}

.fad.fa-unlink::after, .fa-duotone.fa-unlink::after {
  content: "\f127\f127";
}

.fad.fa-clone::after, .fa-duotone.fa-clone::after {
  content: "\f24d\f24d";
}

.fad.fa-person-walking-arrow-loop-left::after, .fa-duotone.fa-person-walking-arrow-loop-left::after {
  content: "\e551\e551";
}

.fad.fa-arrow-up-z-a::after, .fa-duotone.fa-arrow-up-z-a::after {
  content: "\f882\f882";
}

.fad.fa-sort-alpha-up-alt::after, .fa-duotone.fa-sort-alpha-up-alt::after {
  content: "\f882\f882";
}

.fad.fa-fire-flame-curved::after, .fa-duotone.fa-fire-flame-curved::after {
  content: "\f7e4\f7e4";
}

.fad.fa-fire-alt::after, .fa-duotone.fa-fire-alt::after {
  content: "\f7e4\f7e4";
}

.fad.fa-tornado::after, .fa-duotone.fa-tornado::after {
  content: "\f76f\f76f";
}

.fad.fa-file-circle-plus::after, .fa-duotone.fa-file-circle-plus::after {
  content: "\e494\e494";
}

.fad.fa-delete-right::after, .fa-duotone.fa-delete-right::after {
  content: "\e154\e154";
}

.fad.fa-book-quran::after, .fa-duotone.fa-book-quran::after {
  content: "\f687\f687";
}

.fad.fa-quran::after, .fa-duotone.fa-quran::after {
  content: "\f687\f687";
}

.fad.fa-circle-quarter::after, .fa-duotone.fa-circle-quarter::after {
  content: "\e11f\e11f";
}

.fad.fa-anchor::after, .fa-duotone.fa-anchor::after {
  content: "\f13d\f13d";
}

.fad.fa-border-all::after, .fa-duotone.fa-border-all::after {
  content: "\f84c\f84c";
}

.fad.fa-function::after, .fa-duotone.fa-function::after {
  content: "\f661\f661";
}

.fad.fa-face-angry::after, .fa-duotone.fa-face-angry::after {
  content: "\f556\f556";
}

.fad.fa-angry::after, .fa-duotone.fa-angry::after {
  content: "\f556\f556";
}

.fad.fa-people-simple::after, .fa-duotone.fa-people-simple::after {
  content: "\e21b\e21b";
}

.fad.fa-cookie-bite::after, .fa-duotone.fa-cookie-bite::after {
  content: "\f564\f564";
}

.fad.fa-arrow-trend-down::after, .fa-duotone.fa-arrow-trend-down::after {
  content: "\e097\e097";
}

.fad.fa-rss::after, .fa-duotone.fa-rss::after {
  content: "\f09e\f09e";
}

.fad.fa-feed::after, .fa-duotone.fa-feed::after {
  content: "\f09e\f09e";
}

.fad.fa-face-monocle::after, .fa-duotone.fa-face-monocle::after {
  content: "\e380\e380";
}

.fad.fa-draw-polygon::after, .fa-duotone.fa-draw-polygon::after {
  content: "\f5ee\f5ee";
}

.fad.fa-scale-balanced::after, .fa-duotone.fa-scale-balanced::after {
  content: "\f24e\f24e";
}

.fad.fa-balance-scale::after, .fa-duotone.fa-balance-scale::after {
  content: "\f24e\f24e";
}

.fad.fa-calendar-lines::after, .fa-duotone.fa-calendar-lines::after {
  content: "\e0d5\e0d5";
}

.fad.fa-calendar-note::after, .fa-duotone.fa-calendar-note::after {
  content: "\e0d5\e0d5";
}

.fad.fa-arrow-down-big-small::after, .fa-duotone.fa-arrow-down-big-small::after {
  content: "\f88c\f88c";
}

.fad.fa-sort-size-down::after, .fa-duotone.fa-sort-size-down::after {
  content: "\f88c\f88c";
}

.fad.fa-gauge-simple-high::after, .fa-duotone.fa-gauge-simple-high::after {
  content: "\f62a\f62a";
}

.fad.fa-tachometer::after, .fa-duotone.fa-tachometer::after {
  content: "\f62a\f62a";
}

.fad.fa-tachometer-fast::after, .fa-duotone.fa-tachometer-fast::after {
  content: "\f62a\f62a";
}

.fad.fa-do-not-enter::after, .fa-duotone.fa-do-not-enter::after {
  content: "\f5ec\f5ec";
}

.fad.fa-shower::after, .fa-duotone.fa-shower::after {
  content: "\f2cc\f2cc";
}

.fad.fa-dice-d8::after, .fa-duotone.fa-dice-d8::after {
  content: "\f6d2\f6d2";
}

.fad.fa-desktop::after, .fa-duotone.fa-desktop::after {
  content: "\f390\f390";
}

.fad.fa-desktop-alt::after, .fa-duotone.fa-desktop-alt::after {
  content: "\f390\f390";
}

.fad.fa-m::after, .fa-duotone.fa-m::after {
  content: "MM";
}

.fad.fa-grip-dots-vertical::after, .fa-duotone.fa-grip-dots-vertical::after {
  content: "\e411\e411";
}

.fad.fa-face-viewfinder::after, .fa-duotone.fa-face-viewfinder::after {
  content: "\e2ff\e2ff";
}

.fad.fa-soft-serve::after, .fa-duotone.fa-soft-serve::after {
  content: "\e400\e400";
}

.fad.fa-creemee::after, .fa-duotone.fa-creemee::after {
  content: "\e400\e400";
}

.fad.fa-h5::after, .fa-duotone.fa-h5::after {
  content: "\e412\e412";
}

.fad.fa-hand-back-point-down::after, .fa-duotone.fa-hand-back-point-down::after {
  content: "\e19e\e19e";
}

.fad.fa-table-list::after, .fa-duotone.fa-table-list::after {
  content: "\f00b\f00b";
}

.fad.fa-th-list::after, .fa-duotone.fa-th-list::after {
  content: "\f00b\f00b";
}

.fad.fa-comment-sms::after, .fa-duotone.fa-comment-sms::after {
  content: "\f7cd\f7cd";
}

.fad.fa-sms::after, .fa-duotone.fa-sms::after {
  content: "\f7cd\f7cd";
}

.fad.fa-rectangle::after, .fa-duotone.fa-rectangle::after {
  content: "\f2fa\f2fa";
}

.fad.fa-rectangle-landscape::after, .fa-duotone.fa-rectangle-landscape::after {
  content: "\f2fa\f2fa";
}

.fad.fa-clipboard-list-check::after, .fa-duotone.fa-clipboard-list-check::after {
  content: "\f737\f737";
}

.fad.fa-turkey::after, .fa-duotone.fa-turkey::after {
  content: "\f725\f725";
}

.fad.fa-book::after, .fa-duotone.fa-book::after {
  content: "\f02d\f02d";
}

.fad.fa-user-plus::after, .fa-duotone.fa-user-plus::after {
  content: "\f234\f234";
}

.fad.fa-ice-skate::after, .fa-duotone.fa-ice-skate::after {
  content: "\f7ac\f7ac";
}

.fad.fa-check::after, .fa-duotone.fa-check::after {
  content: "\f00c\f00c";
}

.fad.fa-battery-three-quarters::after, .fa-duotone.fa-battery-three-quarters::after {
  content: "\f241\f241";
}

.fad.fa-battery-4::after, .fa-duotone.fa-battery-4::after {
  content: "\f241\f241";
}

.fad.fa-tomato::after, .fa-duotone.fa-tomato::after {
  content: "\e330\e330";
}

.fad.fa-sword-laser::after, .fa-duotone.fa-sword-laser::after {
  content: "\e03b\e03b";
}

.fad.fa-house-circle-check::after, .fa-duotone.fa-house-circle-check::after {
  content: "\e509\e509";
}

.fad.fa-buildings::after, .fa-duotone.fa-buildings::after {
  content: "\e0cc\e0cc";
}

.fad.fa-angle-left::after, .fa-duotone.fa-angle-left::after {
  content: "\f104\f104";
}

.fad.fa-cart-flatbed-boxes::after, .fa-duotone.fa-cart-flatbed-boxes::after {
  content: "\f475\f475";
}

.fad.fa-dolly-flatbed-alt::after, .fa-duotone.fa-dolly-flatbed-alt::after {
  content: "\f475\f475";
}

.fad.fa-diagram-successor::after, .fa-duotone.fa-diagram-successor::after {
  content: "\e47a\e47a";
}

.fad.fa-truck-arrow-right::after, .fa-duotone.fa-truck-arrow-right::after {
  content: "\e58b\e58b";
}

.fad.fa-square-w::after, .fa-duotone.fa-square-w::after {
  content: "\e285\e285";
}

.fad.fa-arrows-split-up-and-left::after, .fa-duotone.fa-arrows-split-up-and-left::after {
  content: "\e4bc\e4bc";
}

.fad.fa-lamp::after, .fa-duotone.fa-lamp::after {
  content: "\f4ca\f4ca";
}

.fad.fa-airplay::after, .fa-duotone.fa-airplay::after {
  content: "\e089\e089";
}

.fad.fa-hand-fist::after, .fa-duotone.fa-hand-fist::after {
  content: "\f6de\f6de";
}

.fad.fa-fist-raised::after, .fa-duotone.fa-fist-raised::after {
  content: "\f6de\f6de";
}

.fad.fa-shield-quartered::after, .fa-duotone.fa-shield-quartered::after {
  content: "\e575\e575";
}

.fad.fa-slash-forward::after, .fa-duotone.fa-slash-forward::after {
  content: "//";
}

.fad.fa-location-pen::after, .fa-duotone.fa-location-pen::after {
  content: "\f607\f607";
}

.fad.fa-map-marker-edit::after, .fa-duotone.fa-map-marker-edit::after {
  content: "\f607\f607";
}

.fad.fa-cloud-moon::after, .fa-duotone.fa-cloud-moon::after {
  content: "\f6c3\f6c3";
}

.fad.fa-pot-food::after, .fa-duotone.fa-pot-food::after {
  content: "\e43f\e43f";
}

.fad.fa-briefcase::after, .fa-duotone.fa-briefcase::after {
  content: "\f0b1\f0b1";
}

.fad.fa-person-falling::after, .fa-duotone.fa-person-falling::after {
  content: "\e546\e546";
}

.fad.fa-image-portrait::after, .fa-duotone.fa-image-portrait::after {
  content: "\f3e0\f3e0";
}

.fad.fa-portrait::after, .fa-duotone.fa-portrait::after {
  content: "\f3e0\f3e0";
}

.fad.fa-user-tag::after, .fa-duotone.fa-user-tag::after {
  content: "\f507\f507";
}

.fad.fa-rug::after, .fa-duotone.fa-rug::after {
  content: "\e569\e569";
}

.fad.fa-print-slash::after, .fa-duotone.fa-print-slash::after {
  content: "\f686\f686";
}

.fad.fa-earth-europe::after, .fa-duotone.fa-earth-europe::after {
  content: "\f7a2\f7a2";
}

.fad.fa-globe-europe::after, .fa-duotone.fa-globe-europe::after {
  content: "\f7a2\f7a2";
}

.fad.fa-cart-flatbed-suitcase::after, .fa-duotone.fa-cart-flatbed-suitcase::after {
  content: "\f59d\f59d";
}

.fad.fa-luggage-cart::after, .fa-duotone.fa-luggage-cart::after {
  content: "\f59d\f59d";
}

.fad.fa-hand-back-point-ribbon::after, .fa-duotone.fa-hand-back-point-ribbon::after {
  content: "\e1a0\e1a0";
}

.fad.fa-rectangle-xmark::after, .fa-duotone.fa-rectangle-xmark::after {
  content: "\f410\f410";
}

.fad.fa-rectangle-times::after, .fa-duotone.fa-rectangle-times::after {
  content: "\f410\f410";
}

.fad.fa-times-rectangle::after, .fa-duotone.fa-times-rectangle::after {
  content: "\f410\f410";
}

.fad.fa-window-close::after, .fa-duotone.fa-window-close::after {
  content: "\f410\f410";
}

.fad.fa-tire-rugged::after, .fa-duotone.fa-tire-rugged::after {
  content: "\f634\f634";
}

.fad.fa-lightbulb-dollar::after, .fa-duotone.fa-lightbulb-dollar::after {
  content: "\f670\f670";
}

.fad.fa-cowbell::after, .fa-duotone.fa-cowbell::after {
  content: "\f8b3\f8b3";
}

.fad.fa-baht-sign::after, .fa-duotone.fa-baht-sign::after {
  content: "\e0ac\e0ac";
}

.fad.fa-corner::after, .fa-duotone.fa-corner::after {
  content: "\e3fe\e3fe";
}

.fad.fa-chevrons-right::after, .fa-duotone.fa-chevrons-right::after {
  content: "\f324\f324";
}

.fad.fa-chevron-double-right::after, .fa-duotone.fa-chevron-double-right::after {
  content: "\f324\f324";
}

.fad.fa-book-open::after, .fa-duotone.fa-book-open::after {
  content: "\f518\f518";
}

.fad.fa-book-journal-whills::after, .fa-duotone.fa-book-journal-whills::after {
  content: "\f66a\f66a";
}

.fad.fa-journal-whills::after, .fa-duotone.fa-journal-whills::after {
  content: "\f66a\f66a";
}

.fad.fa-inhaler::after, .fa-duotone.fa-inhaler::after {
  content: "\f5f9\f5f9";
}

.fad.fa-handcuffs::after, .fa-duotone.fa-handcuffs::after {
  content: "\e4f8\e4f8";
}

.fad.fa-snake::after, .fa-duotone.fa-snake::after {
  content: "\f716\f716";
}

.fad.fa-triangle-exclamation::after, .fa-duotone.fa-triangle-exclamation::after {
  content: "\f071\f071";
}

.fad.fa-exclamation-triangle::after, .fa-duotone.fa-exclamation-triangle::after {
  content: "\f071\f071";
}

.fad.fa-warning::after, .fa-duotone.fa-warning::after {
  content: "\f071\f071";
}

.fad.fa-note-medical::after, .fa-duotone.fa-note-medical::after {
  content: "\e200\e200";
}

.fad.fa-database::after, .fa-duotone.fa-database::after {
  content: "\f1c0\f1c0";
}

.fad.fa-down-left::after, .fa-duotone.fa-down-left::after {
  content: "\e16a\e16a";
}

.fad.fa-share::after, .fa-duotone.fa-share::after {
  content: "\f064\f064";
}

.fad.fa-arrow-turn-right::after, .fa-duotone.fa-arrow-turn-right::after {
  content: "\f064\f064";
}

.fad.fa-mail-forward::after, .fa-duotone.fa-mail-forward::after {
  content: "\f064\f064";
}

.fad.fa-face-thinking::after, .fa-duotone.fa-face-thinking::after {
  content: "\e39b\e39b";
}

.fad.fa-turn-down-right::after, .fa-duotone.fa-turn-down-right::after {
  content: "\e455\e455";
}

.fad.fa-bottle-droplet::after, .fa-duotone.fa-bottle-droplet::after {
  content: "\e4c4\e4c4";
}

.fad.fa-mask-face::after, .fa-duotone.fa-mask-face::after {
  content: "\e1d7\e1d7";
}

.fad.fa-hill-rockslide::after, .fa-duotone.fa-hill-rockslide::after {
  content: "\e508\e508";
}

.fad.fa-scanner-keyboard::after, .fa-duotone.fa-scanner-keyboard::after {
  content: "\f489\f489";
}

.fad.fa-circle-o::after, .fa-duotone.fa-circle-o::after {
  content: "\e119\e119";
}

.fad.fa-grid-horizontal::after, .fa-duotone.fa-grid-horizontal::after {
  content: "\e307\e307";
}

.fad.fa-message-dollar::after, .fa-duotone.fa-message-dollar::after {
  content: "\f650\f650";
}

.fad.fa-comment-alt-dollar::after, .fa-duotone.fa-comment-alt-dollar::after {
  content: "\f650\f650";
}

.fad.fa-right-left::after, .fa-duotone.fa-right-left::after {
  content: "\f362\f362";
}

.fad.fa-exchange-alt::after, .fa-duotone.fa-exchange-alt::after {
  content: "\f362\f362";
}

.fad.fa-columns-3::after, .fa-duotone.fa-columns-3::after {
  content: "\e361\e361";
}

.fad.fa-paper-plane::after, .fa-duotone.fa-paper-plane::after {
  content: "\f1d8\f1d8";
}

.fad.fa-road-circle-exclamation::after, .fa-duotone.fa-road-circle-exclamation::after {
  content: "\e565\e565";
}

.fad.fa-dungeon::after, .fa-duotone.fa-dungeon::after {
  content: "\f6d9\f6d9";
}

.fad.fa-hand-holding-box::after, .fa-duotone.fa-hand-holding-box::after {
  content: "\f47b\f47b";
}

.fad.fa-input-text::after, .fa-duotone.fa-input-text::after {
  content: "\e1bf\e1bf";
}

.fad.fa-window-flip::after, .fa-duotone.fa-window-flip::after {
  content: "\f40f\f40f";
}

.fad.fa-window-alt::after, .fa-duotone.fa-window-alt::after {
  content: "\f40f\f40f";
}

.fad.fa-align-right::after, .fa-duotone.fa-align-right::after {
  content: "\f038\f038";
}

.fad.fa-scanner-gun::after, .fa-duotone.fa-scanner-gun::after {
  content: "\f488\f488";
}

.fad.fa-scanner::after, .fa-duotone.fa-scanner::after {
  content: "\f488\f488";
}

.fad.fa-tire::after, .fa-duotone.fa-tire::after {
  content: "\f631\f631";
}

.fad.fa-engine::after, .fa-duotone.fa-engine::after {
  content: "\e16e\e16e";
}

.fad.fa-money-bill-1-wave::after, .fa-duotone.fa-money-bill-1-wave::after {
  content: "\f53b\f53b";
}

.fad.fa-money-bill-wave-alt::after, .fa-duotone.fa-money-bill-wave-alt::after {
  content: "\f53b\f53b";
}

.fad.fa-life-ring::after, .fa-duotone.fa-life-ring::after {
  content: "\f1cd\f1cd";
}

.fad.fa-hands::after, .fa-duotone.fa-hands::after {
  content: "\f2a7\f2a7";
}

.fad.fa-sign-language::after, .fa-duotone.fa-sign-language::after {
  content: "\f2a7\f2a7";
}

.fad.fa-signing::after, .fa-duotone.fa-signing::after {
  content: "\f2a7\f2a7";
}

.fad.fa-circle-caret-right::after, .fa-duotone.fa-circle-caret-right::after {
  content: "\f330\f330";
}

.fad.fa-caret-circle-right::after, .fa-duotone.fa-caret-circle-right::after {
  content: "\f330\f330";
}

.fad.fa-wheat::after, .fa-duotone.fa-wheat::after {
  content: "\f72d\f72d";
}

.fad.fa-file-spreadsheet::after, .fa-duotone.fa-file-spreadsheet::after {
  content: "\f65b\f65b";
}

.fad.fa-audio-description-slash::after, .fa-duotone.fa-audio-description-slash::after {
  content: "\e0a8\e0a8";
}

.fad.fa-calendar-day::after, .fa-duotone.fa-calendar-day::after {
  content: "\f783\f783";
}

.fad.fa-water-ladder::after, .fa-duotone.fa-water-ladder::after {
  content: "\f5c5\f5c5";
}

.fad.fa-ladder-water::after, .fa-duotone.fa-ladder-water::after {
  content: "\f5c5\f5c5";
}

.fad.fa-swimming-pool::after, .fa-duotone.fa-swimming-pool::after {
  content: "\f5c5\f5c5";
}

.fad.fa-arrows-up-down::after, .fa-duotone.fa-arrows-up-down::after {
  content: "\f07d\f07d";
}

.fad.fa-arrows-v::after, .fa-duotone.fa-arrows-v::after {
  content: "\f07d\f07d";
}

.fad.fa-chess-pawn-piece::after, .fa-duotone.fa-chess-pawn-piece::after {
  content: "\f444\f444";
}

.fad.fa-chess-pawn-alt::after, .fa-duotone.fa-chess-pawn-alt::after {
  content: "\f444\f444";
}

.fad.fa-face-grimace::after, .fa-duotone.fa-face-grimace::after {
  content: "\f57f\f57f";
}

.fad.fa-grimace::after, .fa-duotone.fa-grimace::after {
  content: "\f57f\f57f";
}

.fad.fa-wheelchair-move::after, .fa-duotone.fa-wheelchair-move::after {
  content: "\e2ce\e2ce";
}

.fad.fa-wheelchair-alt::after, .fa-duotone.fa-wheelchair-alt::after {
  content: "\e2ce\e2ce";
}

.fad.fa-turn-down::after, .fa-duotone.fa-turn-down::after {
  content: "\f3be\f3be";
}

.fad.fa-level-down-alt::after, .fa-duotone.fa-level-down-alt::after {
  content: "\f3be\f3be";
}

.fad.fa-square-s::after, .fa-duotone.fa-square-s::after {
  content: "\e27d\e27d";
}

.fad.fa-rectangle-barcode::after, .fa-duotone.fa-rectangle-barcode::after {
  content: "\f463\f463";
}

.fad.fa-barcode-alt::after, .fa-duotone.fa-barcode-alt::after {
  content: "\f463\f463";
}

.fad.fa-person-walking-arrow-right::after, .fa-duotone.fa-person-walking-arrow-right::after {
  content: "\e552\e552";
}

.fad.fa-square-envelope::after, .fa-duotone.fa-square-envelope::after {
  content: "\f199\f199";
}

.fad.fa-envelope-square::after, .fa-duotone.fa-envelope-square::after {
  content: "\f199\f199";
}

.fad.fa-dice::after, .fa-duotone.fa-dice::after {
  content: "\f522\f522";
}

.fad.fa-unicorn::after, .fa-duotone.fa-unicorn::after {
  content: "\f727\f727";
}

.fad.fa-bowling-ball::after, .fa-duotone.fa-bowling-ball::after {
  content: "\f436\f436";
}

.fad.fa-pompebled::after, .fa-duotone.fa-pompebled::after {
  content: "\e43d\e43d";
}

.fad.fa-brain::after, .fa-duotone.fa-brain::after {
  content: "\f5dc\f5dc";
}

.fad.fa-watch-smart::after, .fa-duotone.fa-watch-smart::after {
  content: "\e2cc\e2cc";
}

.fad.fa-book-user::after, .fa-duotone.fa-book-user::after {
  content: "\f7e7\f7e7";
}

.fad.fa-sensor-cloud::after, .fa-duotone.fa-sensor-cloud::after {
  content: "\e02c\e02c";
}

.fad.fa-sensor-smoke::after, .fa-duotone.fa-sensor-smoke::after {
  content: "\e02c\e02c";
}

.fad.fa-clapperboard-play::after, .fa-duotone.fa-clapperboard-play::after {
  content: "\e132\e132";
}

.fad.fa-bandage::after, .fa-duotone.fa-bandage::after {
  content: "\f462\f462";
}

.fad.fa-band-aid::after, .fa-duotone.fa-band-aid::after {
  content: "\f462\f462";
}

.fad.fa-calendar-minus::after, .fa-duotone.fa-calendar-minus::after {
  content: "\f272\f272";
}

.fad.fa-circle-xmark::after, .fa-duotone.fa-circle-xmark::after {
  content: "\f057\f057";
}

.fad.fa-times-circle::after, .fa-duotone.fa-times-circle::after {
  content: "\f057\f057";
}

.fad.fa-xmark-circle::after, .fa-duotone.fa-xmark-circle::after {
  content: "\f057\f057";
}

.fad.fa-circle-4::after, .fa-duotone.fa-circle-4::after {
  content: "\e0f1\e0f1";
}

.fad.fa-gifts::after, .fa-duotone.fa-gifts::after {
  content: "\f79c\f79c";
}

.fad.fa-album-collection::after, .fa-duotone.fa-album-collection::after {
  content: "\f8a0\f8a0";
}

.fad.fa-hotel::after, .fa-duotone.fa-hotel::after {
  content: "\f594\f594";
}

.fad.fa-earth-asia::after, .fa-duotone.fa-earth-asia::after {
  content: "\f57e\f57e";
}

.fad.fa-globe-asia::after, .fa-duotone.fa-globe-asia::after {
  content: "\f57e\f57e";
}

.fad.fa-id-card-clip::after, .fa-duotone.fa-id-card-clip::after {
  content: "\f47f\f47f";
}

.fad.fa-id-card-alt::after, .fa-duotone.fa-id-card-alt::after {
  content: "\f47f\f47f";
}

.fad.fa-magnifying-glass-plus::after, .fa-duotone.fa-magnifying-glass-plus::after {
  content: "\f00e\f00e";
}

.fad.fa-search-plus::after, .fa-duotone.fa-search-plus::after {
  content: "\f00e\f00e";
}

.fad.fa-thumbs-up::after, .fa-duotone.fa-thumbs-up::after {
  content: "\f164\f164";
}

.fad.fa-cloud-showers::after, .fa-duotone.fa-cloud-showers::after {
  content: "\f73f\f73f";
}

.fad.fa-user-clock::after, .fa-duotone.fa-user-clock::after {
  content: "\f4fd\f4fd";
}

.fad.fa-onion::after, .fa-duotone.fa-onion::after {
  content: "\e427\e427";
}

.fad.fa-clock-twelve-thirty::after, .fa-duotone.fa-clock-twelve-thirty::after {
  content: "\e359\e359";
}

.fad.fa-arrow-down-to-dotted-line::after, .fa-duotone.fa-arrow-down-to-dotted-line::after {
  content: "\e095\e095";
}

.fad.fa-hand-dots::after, .fa-duotone.fa-hand-dots::after {
  content: "\f461\f461";
}

.fad.fa-allergies::after, .fa-duotone.fa-allergies::after {
  content: "\f461\f461";
}

.fad.fa-file-invoice::after, .fa-duotone.fa-file-invoice::after {
  content: "\f570\f570";
}

.fad.fa-window-minimize::after, .fa-duotone.fa-window-minimize::after {
  content: "\f2d1\f2d1";
}

.fad.fa-rectangle-wide::after, .fa-duotone.fa-rectangle-wide::after {
  content: "\f2fc\f2fc";
}

.fad.fa-comment-arrow-up::after, .fa-duotone.fa-comment-arrow-up::after {
  content: "\e144\e144";
}

.fad.fa-garlic::after, .fa-duotone.fa-garlic::after {
  content: "\e40e\e40e";
}

.fad.fa-mug-saucer::after, .fa-duotone.fa-mug-saucer::after {
  content: "\f0f4\f0f4";
}

.fad.fa-coffee::after, .fa-duotone.fa-coffee::after {
  content: "\f0f4\f0f4";
}

.fad.fa-brush::after, .fa-duotone.fa-brush::after {
  content: "\f55d\f55d";
}

.fad.fa-tree-decorated::after, .fa-duotone.fa-tree-decorated::after {
  content: "\f7dc\f7dc";
}

.fad.fa-mask::after, .fa-duotone.fa-mask::after {
  content: "\f6fa\f6fa";
}

.fad.fa-calendar-heart::after, .fa-duotone.fa-calendar-heart::after {
  content: "\e0d3\e0d3";
}

.fad.fa-magnifying-glass-minus::after, .fa-duotone.fa-magnifying-glass-minus::after {
  content: "\f010\f010";
}

.fad.fa-search-minus::after, .fa-duotone.fa-search-minus::after {
  content: "\f010\f010";
}

.fad.fa-flower::after, .fa-duotone.fa-flower::after {
  content: "\f7ff\f7ff";
}

.fad.fa-ruler-vertical::after, .fa-duotone.fa-ruler-vertical::after {
  content: "\f548\f548";
}

.fad.fa-user-large::after, .fa-duotone.fa-user-large::after {
  content: "\f406\f406";
}

.fad.fa-user-alt::after, .fa-duotone.fa-user-alt::after {
  content: "\f406\f406";
}

.fad.fa-starship-freighter::after, .fa-duotone.fa-starship-freighter::after {
  content: "\e03a\e03a";
}

.fad.fa-train-tram::after, .fa-duotone.fa-train-tram::after {
  content: "\e5b4\e5b4";
}

.fad.fa-bridge-suspension::after, .fa-duotone.fa-bridge-suspension::after {
  content: "\e4cd\e4cd";
}

.fad.fa-trash-check::after, .fa-duotone.fa-trash-check::after {
  content: "\e2af\e2af";
}

.fad.fa-user-nurse::after, .fa-duotone.fa-user-nurse::after {
  content: "\f82f\f82f";
}

.fad.fa-boombox::after, .fa-duotone.fa-boombox::after {
  content: "\f8a5\f8a5";
}

.fad.fa-syringe::after, .fa-duotone.fa-syringe::after {
  content: "\f48e\f48e";
}

.fad.fa-cloud-sun::after, .fa-duotone.fa-cloud-sun::after {
  content: "\f6c4\f6c4";
}

.fad.fa-shield-exclamation::after, .fa-duotone.fa-shield-exclamation::after {
  content: "\e247\e247";
}

.fad.fa-stopwatch-20::after, .fa-duotone.fa-stopwatch-20::after {
  content: "\e06f\e06f";
}

.fad.fa-square-full::after, .fa-duotone.fa-square-full::after {
  content: "\f45c\f45c";
}

.fad.fa-grip-dots::after, .fa-duotone.fa-grip-dots::after {
  content: "\e410\e410";
}

.fad.fa-comment-exclamation::after, .fa-duotone.fa-comment-exclamation::after {
  content: "\f4af\f4af";
}

.fad.fa-pen-swirl::after, .fa-duotone.fa-pen-swirl::after {
  content: "\e214\e214";
}

.fad.fa-falafel::after, .fa-duotone.fa-falafel::after {
  content: "\e40a\e40a";
}

.fad.fa-circle-2::after, .fa-duotone.fa-circle-2::after {
  content: "\e0ef\e0ef";
}

.fad.fa-magnet::after, .fa-duotone.fa-magnet::after {
  content: "\f076\f076";
}

.fad.fa-jar::after, .fa-duotone.fa-jar::after {
  content: "\e516\e516";
}

.fad.fa-gramophone::after, .fa-duotone.fa-gramophone::after {
  content: "\f8bd\f8bd";
}

.fad.fa-dice-d12::after, .fa-duotone.fa-dice-d12::after {
  content: "\f6ce\f6ce";
}

.fad.fa-note-sticky::after, .fa-duotone.fa-note-sticky::after {
  content: "\f249\f249";
}

.fad.fa-sticky-note::after, .fa-duotone.fa-sticky-note::after {
  content: "\f249\f249";
}

.fad.fa-down::after, .fa-duotone.fa-down::after {
  content: "\f354\f354";
}

.fad.fa-arrow-alt-down::after, .fa-duotone.fa-arrow-alt-down::after {
  content: "\f354\f354";
}

.fad.fa-hundred-points::after, .fa-duotone.fa-hundred-points::after {
  content: "\e41c\e41c";
}

.fad.fa-100::after, .fa-duotone.fa-100::after {
  content: "\e41c\e41c";
}

.fad.fa-paperclip-vertical::after, .fa-duotone.fa-paperclip-vertical::after {
  content: "\e3c2\e3c2";
}

.fad.fa-wind-warning::after, .fa-duotone.fa-wind-warning::after {
  content: "\f776\f776";
}

.fad.fa-wind-circle-exclamation::after, .fa-duotone.fa-wind-circle-exclamation::after {
  content: "\f776\f776";
}

.fad.fa-location-pin-slash::after, .fa-duotone.fa-location-pin-slash::after {
  content: "\f60c\f60c";
}

.fad.fa-map-marker-slash::after, .fa-duotone.fa-map-marker-slash::after {
  content: "\f60c\f60c";
}

.fad.fa-face-sad-sweat::after, .fa-duotone.fa-face-sad-sweat::after {
  content: "\e38a\e38a";
}

.fad.fa-bug-slash::after, .fa-duotone.fa-bug-slash::after {
  content: "\e490\e490";
}

.fad.fa-cupcake::after, .fa-duotone.fa-cupcake::after {
  content: "\e402\e402";
}

.fad.fa-light-switch-off::after, .fa-duotone.fa-light-switch-off::after {
  content: "\e018\e018";
}

.fad.fa-toggle-large-off::after, .fa-duotone.fa-toggle-large-off::after {
  content: "\e5b0\e5b0";
}

.fad.fa-pen-fancy-slash::after, .fa-duotone.fa-pen-fancy-slash::after {
  content: "\e210\e210";
}

.fad.fa-truck-container::after, .fa-duotone.fa-truck-container::after {
  content: "\f4dc\f4dc";
}

.fad.fa-boot::after, .fa-duotone.fa-boot::after {
  content: "\f782\f782";
}

.fad.fa-arrow-up-from-water-pump::after, .fa-duotone.fa-arrow-up-from-water-pump::after {
  content: "\e4b6\e4b6";
}

.fad.fa-file-check::after, .fa-duotone.fa-file-check::after {
  content: "\f316\f316";
}

.fad.fa-bone::after, .fa-duotone.fa-bone::after {
  content: "\f5d7\f5d7";
}

.fad.fa-cards-blank::after, .fa-duotone.fa-cards-blank::after {
  content: "\e4df\e4df";
}

.fad.fa-circle-3::after, .fa-duotone.fa-circle-3::after {
  content: "\e0f0\e0f0";
}

.fad.fa-bench-tree::after, .fa-duotone.fa-bench-tree::after {
  content: "\e2e7\e2e7";
}

.fad.fa-keyboard-brightness-low::after, .fa-duotone.fa-keyboard-brightness-low::after {
  content: "\e1c1\e1c1";
}

.fad.fa-ski-boot-ski::after, .fa-duotone.fa-ski-boot-ski::after {
  content: "\e3cd\e3cd";
}

.fad.fa-brain-circuit::after, .fa-duotone.fa-brain-circuit::after {
  content: "\e0c6\e0c6";
}

.fad.fa-user-injured::after, .fa-duotone.fa-user-injured::after {
  content: "\f728\f728";
}

.fad.fa-block-brick-fire::after, .fa-duotone.fa-block-brick-fire::after {
  content: "\e3dc\e3dc";
}

.fad.fa-firewall::after, .fa-duotone.fa-firewall::after {
  content: "\e3dc\e3dc";
}

.fad.fa-face-sad-tear::after, .fa-duotone.fa-face-sad-tear::after {
  content: "\f5b4\f5b4";
}

.fad.fa-sad-tear::after, .fa-duotone.fa-sad-tear::after {
  content: "\f5b4\f5b4";
}

.fad.fa-plane::after, .fa-duotone.fa-plane::after {
  content: "\f072\f072";
}

.fad.fa-tent-arrows-down::after, .fa-duotone.fa-tent-arrows-down::after {
  content: "\e581\e581";
}

.fad.fa-exclamation::after, .fa-duotone.fa-exclamation::after {
  content: "!!";
}

.fad.fa-arrows-spin::after, .fa-duotone.fa-arrows-spin::after {
  content: "\e4bb\e4bb";
}

.fad.fa-face-smile-relaxed::after, .fa-duotone.fa-face-smile-relaxed::after {
  content: "\e392\e392";
}

.fad.fa-comment-xmark::after, .fa-duotone.fa-comment-xmark::after {
  content: "\f4b5\f4b5";
}

.fad.fa-comment-times::after, .fa-duotone.fa-comment-times::after {
  content: "\f4b5\f4b5";
}

.fad.fa-print::after, .fa-duotone.fa-print::after {
  content: "\f02f\f02f";
}

.fad.fa-turkish-lira-sign::after, .fa-duotone.fa-turkish-lira-sign::after {
  content: "\e2bb\e2bb";
}

.fad.fa-try::after, .fa-duotone.fa-try::after {
  content: "\e2bb\e2bb";
}

.fad.fa-turkish-lira::after, .fa-duotone.fa-turkish-lira::after {
  content: "\e2bb\e2bb";
}

.fad.fa-face-nose-steam::after, .fa-duotone.fa-face-nose-steam::after {
  content: "\e382\e382";
}

.fad.fa-circle-waveform-lines::after, .fa-duotone.fa-circle-waveform-lines::after {
  content: "\e12d\e12d";
}

.fad.fa-waveform-circle::after, .fa-duotone.fa-waveform-circle::after {
  content: "\e12d\e12d";
}

.fad.fa-dollar-sign::after, .fa-duotone.fa-dollar-sign::after {
  content: "$$";
}

.fad.fa-dollar::after, .fa-duotone.fa-dollar::after {
  content: "$$";
}

.fad.fa-usd::after, .fa-duotone.fa-usd::after {
  content: "$$";
}

.fad.fa-ferris-wheel::after, .fa-duotone.fa-ferris-wheel::after {
  content: "\e174\e174";
}

.fad.fa-computer-speaker::after, .fa-duotone.fa-computer-speaker::after {
  content: "\f8b2\f8b2";
}

.fad.fa-skull-cow::after, .fa-duotone.fa-skull-cow::after {
  content: "\f8de\f8de";
}

.fad.fa-x::after, .fa-duotone.fa-x::after {
  content: "XX";
}

.fad.fa-magnifying-glass-dollar::after, .fa-duotone.fa-magnifying-glass-dollar::after {
  content: "\f688\f688";
}

.fad.fa-search-dollar::after, .fa-duotone.fa-search-dollar::after {
  content: "\f688\f688";
}

.fad.fa-users-gear::after, .fa-duotone.fa-users-gear::after {
  content: "\f509\f509";
}

.fad.fa-users-cog::after, .fa-duotone.fa-users-cog::after {
  content: "\f509\f509";
}

.fad.fa-person-military-pointing::after, .fa-duotone.fa-person-military-pointing::after {
  content: "\e54a\e54a";
}

.fad.fa-building-columns::after, .fa-duotone.fa-building-columns::after {
  content: "\f19c\f19c";
}

.fad.fa-bank::after, .fa-duotone.fa-bank::after {
  content: "\f19c\f19c";
}

.fad.fa-institution::after, .fa-duotone.fa-institution::after {
  content: "\f19c\f19c";
}

.fad.fa-museum::after, .fa-duotone.fa-museum::after {
  content: "\f19c\f19c";
}

.fad.fa-university::after, .fa-duotone.fa-university::after {
  content: "\f19c\f19c";
}

.fad.fa-circle-t::after, .fa-duotone.fa-circle-t::after {
  content: "\e124\e124";
}

.fad.fa-sack::after, .fa-duotone.fa-sack::after {
  content: "\f81c\f81c";
}

.fad.fa-grid-2::after, .fa-duotone.fa-grid-2::after {
  content: "\e196\e196";
}

.fad.fa-camera-cctv::after, .fa-duotone.fa-camera-cctv::after {
  content: "\f8ac\f8ac";
}

.fad.fa-cctv::after, .fa-duotone.fa-cctv::after {
  content: "\f8ac\f8ac";
}

.fad.fa-umbrella::after, .fa-duotone.fa-umbrella::after {
  content: "\f0e9\f0e9";
}

.fad.fa-trowel::after, .fa-duotone.fa-trowel::after {
  content: "\e589\e589";
}

.fad.fa-horizontal-rule::after, .fa-duotone.fa-horizontal-rule::after {
  content: "\f86c\f86c";
}

.fad.fa-bed-front::after, .fa-duotone.fa-bed-front::after {
  content: "\f8f7\f8f7";
}

.fad.fa-bed-alt::after, .fa-duotone.fa-bed-alt::after {
  content: "\f8f7\f8f7";
}

.fad.fa-d::after, .fa-duotone.fa-d::after {
  content: "DD";
}

.fad.fa-stapler::after, .fa-duotone.fa-stapler::after {
  content: "\e5af\e5af";
}

.fad.fa-masks-theater::after, .fa-duotone.fa-masks-theater::after {
  content: "\f630\f630";
}

.fad.fa-theater-masks::after, .fa-duotone.fa-theater-masks::after {
  content: "\f630\f630";
}

.fad.fa-kip-sign::after, .fa-duotone.fa-kip-sign::after {
  content: "\e1c4\e1c4";
}

.fad.fa-face-woozy::after, .fa-duotone.fa-face-woozy::after {
  content: "\e3a2\e3a2";
}

.fad.fa-cloud-question::after, .fa-duotone.fa-cloud-question::after {
  content: "\e492\e492";
}

.fad.fa-pineapple::after, .fa-duotone.fa-pineapple::after {
  content: "\e31f\e31f";
}

.fad.fa-hand-point-left::after, .fa-duotone.fa-hand-point-left::after {
  content: "\f0a5\f0a5";
}

.fad.fa-gallery-thumbnails::after, .fa-duotone.fa-gallery-thumbnails::after {
  content: "\e3aa\e3aa";
}

.fad.fa-circle-j::after, .fa-duotone.fa-circle-j::after {
  content: "\e112\e112";
}

.fad.fa-eyes::after, .fa-duotone.fa-eyes::after {
  content: "\e367\e367";
}

.fad.fa-handshake-simple::after, .fa-duotone.fa-handshake-simple::after {
  content: "\f4c6\f4c6";
}

.fad.fa-handshake-alt::after, .fa-duotone.fa-handshake-alt::after {
  content: "\f4c6\f4c6";
}

.fad.fa-page-caret-up::after, .fa-duotone.fa-page-caret-up::after {
  content: "\e42a\e42a";
}

.fad.fa-file-caret-up::after, .fa-duotone.fa-file-caret-up::after {
  content: "\e42a\e42a";
}

.fad.fa-jet-fighter::after, .fa-duotone.fa-jet-fighter::after {
  content: "\f0fb\f0fb";
}

.fad.fa-fighter-jet::after, .fa-duotone.fa-fighter-jet::after {
  content: "\f0fb\f0fb";
}

.fad.fa-comet::after, .fa-duotone.fa-comet::after {
  content: "\e003\e003";
}

.fad.fa-square-share-nodes::after, .fa-duotone.fa-square-share-nodes::after {
  content: "\f1e1\f1e1";
}

.fad.fa-share-alt-square::after, .fa-duotone.fa-share-alt-square::after {
  content: "\f1e1\f1e1";
}

.fad.fa-shield-keyhole::after, .fa-duotone.fa-shield-keyhole::after {
  content: "\e248\e248";
}

.fad.fa-barcode::after, .fa-duotone.fa-barcode::after {
  content: "\f02a\f02a";
}

.fad.fa-plus-minus::after, .fa-duotone.fa-plus-minus::after {
  content: "\e43c\e43c";
}

.fad.fa-square-sliders-vertical::after, .fa-duotone.fa-square-sliders-vertical::after {
  content: "\f3f2\f3f2";
}

.fad.fa-sliders-v-square::after, .fa-duotone.fa-sliders-v-square::after {
  content: "\f3f2\f3f2";
}

.fad.fa-video::after, .fa-duotone.fa-video::after {
  content: "\f03d\f03d";
}

.fad.fa-video-camera::after, .fa-duotone.fa-video-camera::after {
  content: "\f03d\f03d";
}

.fad.fa-message-middle::after, .fa-duotone.fa-message-middle::after {
  content: "\e1e1\e1e1";
}

.fad.fa-comment-middle-alt::after, .fa-duotone.fa-comment-middle-alt::after {
  content: "\e1e1\e1e1";
}

.fad.fa-graduation-cap::after, .fa-duotone.fa-graduation-cap::after {
  content: "\f19d\f19d";
}

.fad.fa-mortar-board::after, .fa-duotone.fa-mortar-board::after {
  content: "\f19d\f19d";
}

.fad.fa-hand-holding-medical::after, .fa-duotone.fa-hand-holding-medical::after {
  content: "\e05c\e05c";
}

.fad.fa-person-circle-check::after, .fa-duotone.fa-person-circle-check::after {
  content: "\e53e\e53e";
}

.fad.fa-square-z::after, .fa-duotone.fa-square-z::after {
  content: "\e288\e288";
}

.fad.fa-message-text::after, .fa-duotone.fa-message-text::after {
  content: "\e1e6\e1e6";
}

.fad.fa-comment-alt-text::after, .fa-duotone.fa-comment-alt-text::after {
  content: "\e1e6\e1e6";
}

.fad.fa-turn-up::after, .fa-duotone.fa-turn-up::after {
  content: "\f3bf\f3bf";
}

.fad.fa-level-up-alt::after, .fa-duotone.fa-level-up-alt::after {
  content: "\f3bf\f3bf";
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-weight: 300;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-weight: 900;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-weight: 100;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-display: block;
  font-weight: 400;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 900;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 400;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 300;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype");
}
@font-face {
  font-family: "Font Awesome 5 Duotone";
  font-display: block;
  font-weight: 900;
  src: url("../fonts/fa-duotone-900.woff2") format("woff2"), url("../fonts/fa-duotone-900.ttf") format("truetype");
}
@font-face {
  font-family: "FontAwesome";
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
@font-face {
  font-family: "FontAwesome";
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}
@font-face {
  font-family: "FontAwesome";
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
  unicode-range: U+F003, U+F006, U+F014, U+F016-F017, U+F01A-F01B, U+F01D, U+F022, U+F03E, U+F044, U+F046, U+F05C-F05D, U+F06E, U+F070, U+F087-F088, U+F08A, U+F094, U+F096-F097, U+F09D, U+F0A0, U+F0A2, U+F0A4-F0A7, U+F0C5, U+F0C7, U+F0E5-F0E6, U+F0EB, U+F0F6-F0F8, U+F10C, U+F114-F115, U+F118-F11A, U+F11C-F11D, U+F133, U+F147, U+F14E, U+F150-F152, U+F185-F186, U+F18E, U+F190-F192, U+F196, U+F1C1-F1C9, U+F1D9, U+F1DB, U+F1E3, U+F1EA, U+F1F7, U+F1F9, U+F20A, U+F247-F248, U+F24A, U+F24D, U+F255-F25B, U+F25D, U+F271-F274, U+F278, U+F27B, U+F28C, U+F28E, U+F29C, U+F2B5, U+F2B7, U+F2BA, U+F2BC, U+F2BE, U+F2C0-F2C1, U+F2C3, U+F2D0, U+F2D2, U+F2D4, U+F2DC;
}
@font-face {
  font-family: "FontAwesome";
  font-display: block;
  src: url("../fonts/fa-v4compatibility.woff2") format("woff2"), url("../fonts/fa-v4compatibility.ttf") format("truetype");
  unicode-range: U+F041, U+F047, U+F065-F066, U+F07D-F07E, U+F080, U+F08B, U+F08E, U+F090, U+F09A, U+F0AC, U+F0AE, U+F0B2, U+F0D0, U+F0D6, U+F0E4, U+F0EC, U+F10A-F10B, U+F123, U+F13E, U+F148-F149, U+F14C, U+F156, U+F15E, U+F160-F161, U+F163, U+F175-F178, U+F195, U+F1F8, U+F219, U+F27A;
}
@font-face {
  font-family: "dripicons-v2";
  src: url("../fonts/dripicons-v2.eot");
  src: url("../fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../fonts/dripicons-v2.woff") format("woff"), url("../fonts/dripicons-v2.ttf") format("truetype"), url("../fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=dripicons-]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dripicons-alarm:before {
  content: "a";
}

.dripicons-align-center:before {
  content: "b";
}

.dripicons-align-justify:before {
  content: "c";
}

.dripicons-align-left:before {
  content: "d";
}

.dripicons-align-right:before {
  content: "e";
}

.dripicons-anchor:before {
  content: "f";
}

.dripicons-archive:before {
  content: "g";
}

.dripicons-arrow-down:before {
  content: "h";
}

.dripicons-arrow-left:before {
  content: "i";
}

.dripicons-arrow-right:before {
  content: "j";
}

.dripicons-arrow-thin-down:before {
  content: "k";
}

.dripicons-arrow-thin-left:before {
  content: "l";
}

.dripicons-arrow-thin-right:before {
  content: "m";
}

.dripicons-arrow-thin-up:before {
  content: "n";
}

.dripicons-arrow-up:before {
  content: "o";
}

.dripicons-article:before {
  content: "p";
}

.dripicons-backspace:before {
  content: "q";
}

.dripicons-basket:before {
  content: "r";
}

.dripicons-basketball:before {
  content: "s";
}

.dripicons-battery-empty:before {
  content: "t";
}

.dripicons-battery-full:before {
  content: "u";
}

.dripicons-battery-low:before {
  content: "v";
}

.dripicons-battery-medium:before {
  content: "w";
}

.dripicons-bell:before {
  content: "x";
}

.dripicons-blog:before {
  content: "y";
}

.dripicons-bluetooth:before {
  content: "z";
}

.dripicons-bold:before {
  content: "A";
}

.dripicons-bookmark:before {
  content: "B";
}

.dripicons-bookmarks:before {
  content: "C";
}

.dripicons-box:before {
  content: "D";
}

.dripicons-briefcase:before {
  content: "E";
}

.dripicons-brightness-low:before {
  content: "F";
}

.dripicons-brightness-max:before {
  content: "G";
}

.dripicons-brightness-medium:before {
  content: "H";
}

.dripicons-broadcast:before {
  content: "I";
}

.dripicons-browser:before {
  content: "J";
}

.dripicons-browser-upload:before {
  content: "K";
}

.dripicons-brush:before {
  content: "L";
}

.dripicons-calendar:before {
  content: "M";
}

.dripicons-camcorder:before {
  content: "N";
}

.dripicons-camera:before {
  content: "O";
}

.dripicons-card:before {
  content: "P";
}

.dripicons-cart:before {
  content: "Q";
}

.dripicons-checklist:before {
  content: "R";
}

.dripicons-checkmark:before {
  content: "S";
}

.dripicons-chevron-down:before {
  content: "T";
}

.dripicons-chevron-left:before {
  content: "U";
}

.dripicons-chevron-right:before {
  content: "V";
}

.dripicons-chevron-up:before {
  content: "W";
}

.dripicons-clipboard:before {
  content: "X";
}

.dripicons-clock:before {
  content: "Y";
}

.dripicons-clockwise:before {
  content: "Z";
}

.dripicons-cloud:before {
  content: "0";
}

.dripicons-cloud-download:before {
  content: "1";
}

.dripicons-cloud-upload:before {
  content: "2";
}

.dripicons-code:before {
  content: "3";
}

.dripicons-contract:before {
  content: "4";
}

.dripicons-contract-2:before {
  content: "5";
}

.dripicons-conversation:before {
  content: "6";
}

.dripicons-copy:before {
  content: "7";
}

.dripicons-crop:before {
  content: "8";
}

.dripicons-cross:before {
  content: "9";
}

.dripicons-crosshair:before {
  content: "!";
}

.dripicons-cutlery:before {
  content: '"';
}

.dripicons-device-desktop:before {
  content: "#";
}

.dripicons-device-mobile:before {
  content: "$";
}

.dripicons-device-tablet:before {
  content: "%";
}

.dripicons-direction:before {
  content: "&";
}

.dripicons-disc:before {
  content: "'";
}

.dripicons-document:before {
  content: "(";
}

.dripicons-document-delete:before {
  content: ")";
}

.dripicons-document-edit:before {
  content: "*";
}

.dripicons-document-new:before {
  content: "+";
}

.dripicons-document-remove:before {
  content: ",";
}

.dripicons-dot:before {
  content: "-";
}

.dripicons-dots-2:before {
  content: ".";
}

.dripicons-dots-3:before {
  content: "/";
}

.dripicons-download:before {
  content: ":";
}

.dripicons-duplicate:before {
  content: ";";
}

.dripicons-enter:before {
  content: "<";
}

.dripicons-exit:before {
  content: "=";
}

.dripicons-expand:before {
  content: ">";
}

.dripicons-expand-2:before {
  content: "?";
}

.dripicons-experiment:before {
  content: "@";
}

.dripicons-export:before {
  content: "[";
}

.dripicons-feed:before {
  content: "]";
}

.dripicons-flag:before {
  content: "^";
}

.dripicons-flashlight:before {
  content: "_";
}

.dripicons-folder:before {
  content: "`";
}

.dripicons-folder-open:before {
  content: "{";
}

.dripicons-forward:before {
  content: "|";
}

.dripicons-gaming:before {
  content: "}";
}

.dripicons-gear:before {
  content: "~";
}

.dripicons-graduation:before {
  content: "\\";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}